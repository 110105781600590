import React, { FC } from "react";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { ICommentActionError } from "@store/comments/types";

import s from "./CommentActionError.module.less";

import { AlertTriangleFilledIcon } from "@assets/index";

interface ICommentActionErrorProps {
  actionError?: ICommentActionError;
}

const CommentActionError: FC<ICommentActionErrorProps> = ({ actionError }) => {
  if (!actionError?.user_message && !actionError?.platform_message) return null;

  return (
    <Tooltip
      overlayInnerStyle={{ width: 400 }}
      title={
        <div>
          <div>{actionError?.user_message || ""}</div>
          <ul className={s.bbActionErrorTooltipList}>
            {actionError.details?.map(error_reason_detail => (
              <li key={error_reason_detail} className={s.bbActionErrorTooltipListItem}>
                {error_reason_detail}
              </li>
            ))}
          </ul>
          {actionError?.platform_message && (
            <div
              style={{
                borderTop: "1px solid white",
                paddingTop: 8,
                fontFamily: "Source Code Pro",
              }}
            >
              {actionError?.platform_message || ""}
            </div>
          )}
        </div>
      }
    >
      <span className={s.bbCommentActionErrorIcon}>
        <AlertTriangleFilledIcon />
      </span>
    </Tooltip>
  );
};

export default CommentActionError;
