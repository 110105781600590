import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export const useKeyPress = (keys: number, callback: () => void, node = null) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.ctrlKey && event.altKey && keys === event.keyCode) {
        callbackRef.current();
      }
    },
    [keys],
  );

  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);
    return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};

export const keyCodes = {
  a: 65,
  s: 83,
  h: 72,
  ArrowRight: 39,
  ArrowLeft: 37,
};
