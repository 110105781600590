import { Observable } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { Actions, IAdTargetingsProps } from "./types";
import { RootState } from "..";
import { ITrendingPlatformPostsProps, ITrendingPostProps } from "./types";

import {
  getAdTargetingsActionFailure,
  getAdTargetingsActionSuccess,
  getTrendingPostsActionFailure,
  getTrendingPostsActionSuccess,
} from "./actions";
import { GET_TRENDING_POSTS, GET_AD_TARGETINGS } from "./actionTypes";

import { insights } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const getTrendingPostsEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_TRENDING_POSTS)),
    switchMap(a => {
      return ajax
        .post<{ items: ITrendingPlatformPostsProps[] }>(
          `${insights}/most-commented-posts`,
          { filters: a.payload },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data =>
            getTrendingPostsActionSuccess(
              data.items.map((tpp: ITrendingPlatformPostsProps, index: number) => {
                const newTps = tpp.items.map((tp: ITrendingPostProps, indext: number) => {
                  return { ...tp, key: indext };
                });
                tpp.items = newTps;
                return { ...tpp, key: index };
              }),
            ),
          ),
          catchError(e => handleError(e, getTrendingPostsActionFailure)),
        );
    }),
  );

export const getAdtargetingsEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_AD_TARGETINGS)),
    switchMap(a => {
      return ajax
        .post<{ items: IAdTargetingsProps[] }>(
          `${insights}/top-targetings`,
          {
            filters: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(result => getAdTargetingsActionSuccess(result.items)),
          catchError(e => handleError(e, getAdTargetingsActionFailure)),
        );
    }),
  );
