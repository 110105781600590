import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect, useState } from "react";

import { Grid } from "antd";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import { AssetTabsWrapper } from "../../assetTabsWrapper/AssetTabsWrapper";

import {
  selectedAssetsSelector,
  postedOnboardingPlatformTypeAssetsSelector,
  postingOnboardingPlatformTypeAssetsSelector,
} from "@store/onboardingPlatformTypes/selectors";
import { postOnboardingPlatformTypeAssetsAction } from "@store/onboardingPlatformTypes/actions";

import s from "./Step3.module.less";
import footerStyle from "../footer/Footer.module.less";

import { IStep3Props } from "./Step3.type";

import { ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const Step3View: FC<IStep3Props> = ({ isAgencyView, onFinish }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [isWebhookEnabled, setIsWebhookEnabled] = useState(true);

  const postingAssets = useSelector(postingOnboardingPlatformTypeAssetsSelector);
  const postedAssets = useSelector(postedOnboardingPlatformTypeAssetsSelector);
  const allSelectedAssets = useSelector(selectedAssetsSelector);

  useEffect(() => {
    if (postedAssets) onFinish();
  }, [postedAssets, onFinish]);

  const onInternalFinish = () => {
    dispatch(postOnboardingPlatformTypeAssetsAction(allSelectedAssets, isWebhookEnabled));
  };

  const setInternalWHEnabled = (enabled: boolean) => {
    setIsWebhookEnabled(enabled);
  };

  return (
    <>
      <div
        className={cx(s.bbStep3, {
          [s.bbStep3Mobile]: !screens.lg,
        })}
      >
        <AssetTabsWrapper
          showDisclaimer
          title={
            isAgencyView ? t("pages:signup:agency:step3:title") : t("pages:signup:step3:title")
          }
          setWebhookEnabled={setInternalWHEnabled}
        />
      </div>
      <div className={cx(s.bbStep3Footer, footerStyle.bbStepFooter)}>
        <Button
          _size="lg"
          type="submit"
          onClick={onFinish}
          disabled={postingAssets}
          className={cx(footerStyle.bbStepFooterBackBtn, s.bbStep3FooterButtonLater)}
        >
          {t("pages:signup:step3:laterBtnLabel")}
        </Button>
        <Button
          _size="lg"
          _type="primary"
          type="submit"
          loading={postingAssets}
          onClick={onInternalFinish}
          disabled={!allSelectedAssets.length || postingAssets}
        >
          {t("generic:continue")}
          <ChevronRightIcon />
        </Button>
      </div>
    </>
  );
};

export default Step3View;
