import React, { FC } from "react";
import classNames from "classnames";
import { IPieChartUnit } from "../../sentimentDetails/SentimentDetails.type";
import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PieChartWidget.module.less";
import { PerformanceCardPill } from "../performanceCard/Pill";

interface IPieChartWidgetLegendProps {
  chartData: IPieChartUnit[];
  className?: string;
}

const PieChartWidgetLegend: FC<IPieChartWidgetLegendProps> = ({ chartData, className }) => (
  <div className={classNames(s.bbLegend, className)}>
    {chartData.map((unit, index) => (
      <div className={s.bbLegendItem} key={index}>
        <div className={s.bbLegendCircle} style={{ background: unit.color }}></div>
        <span className={s.bbLegendName}>{unit.name}</span>
        <span className={s.bbLegendValue}>{numberFormatter(unit.value)}</span>
        {unit.percentage_of_total !== undefined && (
          <span className={s.bbLegendPercentage}>({unit.percentage_of_total}%)</span>
        )}
        <PerformanceCardPill
          type={unit?.type || "total"}
          percentageValue={unit?.change_percentage || 0}
        />
      </div>
    ))}
  </div>
);

export default PieChartWidgetLegend;
