import React, { FC } from "react";
import { IPost } from "./Post.type";

import s from "./Post.module.less";

interface IPostInsightsProps {
  post?: IPost;
}

export const PostInsights: FC<IPostInsightsProps> = ({ post }) => {
  return (
    <div className={s.bbInsightsContainer}>
      {post?.insights?.map(insight => (
        <div className={s.bbInsight} key={insight.value}>
          <div className={s.bbInsightLabel}>{insight.label}</div>
          <div>{insight.value}</div>
        </div>
      ))}
    </div>
  );
};
