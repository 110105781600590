import React from "react";

import { PlatformType } from "@store/platform/types";

import {
  TwitterColorIcon,
  TiktokColorIcon,
  FacebookRoundColor2Icon,
} from "@bbdevcrew/bb_ui_kit_fe";
import {
  FacebookColorIcon,
  InstagramColorIcon,
  GoogleColorIcon,
  LinkedinColorIcon,
  TrustPilotColorIcon,
  GoogleBusinessIcon,
  FacebookRoundColorIcon,
  InstagramRoundColorIcon,
  TwitterRoundColorIcon,
  YoutubeColorIcon,
  LinkedinRoundColorIcon,
  InstagramRoundColor2Icon,
  TiktokRoundColor2Icon,
  InstagramBasicColorIcon,
} from "@assets/index";
import InstagramBasicColorDynamicIcon from "@assets/InstagramBasicColorDynamic";

export const PlatformTypeIconBasicColor = {
  facebook: FacebookColorIcon,
  instagram: InstagramBasicColorIcon,
  twitter: TwitterColorIcon,
  youtube: YoutubeColorIcon,
  google: GoogleColorIcon,
  tiktok: TiktokColorIcon,
  linkedin: LinkedinColorIcon,
  trustpilot: TrustPilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const PlatformTypeIcon = {
  facebook: FacebookColorIcon,
  instagram: InstagramColorIcon,
  twitter: TwitterColorIcon,
  youtube: YoutubeColorIcon,
  google: GoogleColorIcon,
  tiktok: TiktokColorIcon,
  linkedin: LinkedinColorIcon,
  trustpilot: TrustPilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const PlatformTypeIconRound = {
  facebook: FacebookRoundColorIcon,
  instagram: InstagramRoundColorIcon,
  twitter: TwitterRoundColorIcon,
  youtube: YoutubeColorIcon,
  google: GoogleColorIcon,
  tiktok: TiktokColorIcon,
  linkedin: LinkedinRoundColorIcon,
  trustpilot: TrustPilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const PlatformTypeIconRoundFilled = {
  facebook: FacebookRoundColor2Icon,
  instagram: InstagramRoundColor2Icon,
  twitter: TwitterRoundColorIcon,
  youtube: YoutubeColorIcon,
  google: GoogleColorIcon,
  tiktok: TiktokRoundColor2Icon,
  linkedin: LinkedinRoundColorIcon,
  trustpilot: TrustPilotColorIcon,
  google_business: GoogleBusinessIcon,
};

export const platformIconName = (name: string) => {
  if (name.includes("facebook")) return "facebook";
  else if (name.includes("youtube")) return "youtube";
  else if (name.includes("instagram")) return "instagram";
  else if (name.includes("twitter")) return "twitter";
  else if (name.includes("tiktok")) return "tiktok";
  else if (name.includes("linkedin")) return "linkedin";
  else if (name.includes("trustpilot")) return "trustpilot";
  else if (name === "google_business") return "google_business";
  else if (name.includes("google")) return "google";
  else return "facebook";
};

export const isYoutubePlatform = (platform: string) =>
  ["youtube", "youtube_organic", "youtube_ads"].includes(platform);

export const isGooglePlatform = (platform: string) =>
  ["google_ads", "youtube", "youtube_organic", "youtube_ads"].includes(platform);

export const isTwitterPlatform = (platform: string) =>
  ["twitter", "twitter_organic", "twitter_ads"].includes(platform);

export const isFacebookPlatform = (platform: string) =>
  ["facebook", "facebook_organic", "facebook_ads", "instagram", "instagram_organic"].includes(
    platform,
  );

export const isFacebookPlatformStrict = (platform: string) =>
  ["facebook", "facebook_organic", "facebook_ads"].includes(platform);

export const isInstagramPlatform = (platform: string) => ["instagram"].includes(platform);

export const isTiktokPlatform = (platform: string) =>
  ["tiktok", "tiktok_organic", "tiktok_ads", "tt_ads"].includes(platform);

export const isLinkedinPlatform = (platform: string) => ["linkedin"].includes(platform);

export const isTrustpilotPlatform = (platform: string) => ["trustpilot"].includes(platform);

export const isGoogleBusinessPlatform = (platform: string) =>
  ["google_business"].includes(platform);

export const getNormalizedTiktokPlatformId = (name: string) => {
  return name === "tt_ads" ? "tiktok_ads" : (name as PlatformType);
};

export const getPlatformIconBasicColor = (platformTypeId: string) => {
  const Icon =
    PlatformTypeIconBasicColor[platformTypeId as keyof typeof PlatformTypeIconBasicColor];

  // Using an instagram icon with a dynamic gradient id, DEV-13571
  if (platformTypeId === "instagram") return <InstagramBasicColorDynamicIcon />;

  return Icon ? <Icon /> : null;
};

export const getPlatformIcon = (platformTypeId: string) => {
  const Icon = PlatformTypeIcon[platformTypeId as keyof typeof PlatformTypeIcon];
  return Icon ? <Icon /> : null;
};

export const getPlatformIconRound = (platformTypeId: string) => {
  const Icon = PlatformTypeIconRound[platformTypeId as keyof typeof PlatformTypeIconRound];
  return Icon ? <Icon /> : null;
};

export const getPlatformIconRoundFilled = (platformTypeId: string) => {
  const Icon =
    PlatformTypeIconRoundFilled[platformTypeId as keyof typeof PlatformTypeIconRoundFilled];
  return Icon ? <Icon /> : null;
};
