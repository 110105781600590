import { RootState } from "..";

export const fromTiktok = (state: RootState) => state.tiktokApp;

export const getTiktokCookiesSelector = (state: RootState) => fromTiktok(state).tiktokCookies;
export const getTiktokSessionSelector = (state: RootState) => fromTiktok(state).tiktokSession;
export const getTiktokActionSelector = (state: RootState) => fromTiktok(state).tiktokAction;
export const getTiktokSignupTokenSelector = (state: RootState) =>
  fromTiktok(state).tiktokSignupToken;
export const getTiktokSigninTokenSelector = (state: RootState) =>
  fromTiktok(state).tiktokSigninToken;
export const getTiktokAccessTokenSelector = (state: RootState) =>
  fromTiktok(state).tiktokAccessToken;
export const getTiktokUsernameSelector = (state: RootState) => fromTiktok(state).tiktokUsername;

export const tiktokConnectingSelector = (state: RootState) => fromTiktok(state).tiktokConnecting;
export const tiktokConnectedSelector = (state: RootState) => fromTiktok(state).tiktokConnected;
export const tiktokConnectedSelectorFail = (state: RootState) =>
  fromTiktok(state).tiktokConnectedFail;

export const tiktokSigningUpSelector = (state: RootState) => fromTiktok(state).tiktokSigningUp;
export const tiktokSignedUpSelector = (state: RootState) => fromTiktok(state).tiktokSignedUp;
export const tiktokSignUpSelectorFail = (state: RootState) => fromTiktok(state).tiktokSignupFail;

export const tiktokPricingTableSelector = (state: RootState) =>
  fromTiktok(state).tiktokPricingTableItems;
