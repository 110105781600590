export const GET_ASSET_GROUP = "ASSETS/GET_ASSET_GROUP";
export const GET_ASSET_GROUP_SUCCESS = "ASSETS/GET_ASSET_GROUP_SUCCESS";
export const GET_ASSET_GROUP_FAILURE = "ASSETS/GET_ASSET_GROUP_FAILURE";

export const CREATE_ASSET_GROUP = "ASSETS/CREATE_ASSET_GROUP";
export const CREATE_ASSET_GROUP_SUCCESS = "ASSETS/CREATE_ASSET_GROUP_SUCCESS";
export const CREATE_ASSET_GROUP_FAILURE = "ASSETS/CREATE_ASSET_GROUP_FAILURE";

export const UPDATE_ASSET_GROUP = "ASSETS/UPDATE_ASSET_GROUP";
export const UPDATE_ASSET_GROUP_SUCCESS = "ASSETS/UPDATE_ASSET_GROUP_SUCCESS";
export const UPDATE_ASSET_GROUP_FAILURE = "ASSETS/UPDATE_ASSET_GROUP_FAILURE";

export const DELETE_ASSET_GROUP = "ASSETS/DELETE_ASSET_GROUP";
export const DELETE_ASSET_GROUP_SUCCESS = "ASSETS/DELETE_ASSET_GROUP_SUCCESS";
export const DELETE_ASSET_GROUP_FAILURE = "ASSETS/DELETE_ASSET_GROUP_FAILURE";
