import { createReducer, ActionType } from "typesafe-actions";
import { ICheckoutState } from "./types";
import { getCheckoutUrlActionSuccess, getBillingUrlActionSuccess } from "./actions";
import {
  GET_CHECKOUT_URL,
  GET_CHECKOUT_URL_FAILURE,
  GET_CHECKOUT_URL_SUCCESS,
  GET_BILLING_URL,
  GET_BILLING_URL_FAILURE,
  GET_BILLING_URL_SUCCESS,
} from "./actionTypes";

const initialState = {
  billing_url: "",
  checkout_url: "",
  fetchingCheckoutUrl: false,
  fetchedCheckoutUrl: false,
  fetchedCheckoutUrlFail: false,
  fetchingBillingUrl: false,
  fetchedBillingUrl: false,
  fetchedBillingUrlFail: false,
};

export const checkoutReducer = createReducer<ICheckoutState>(initialState, {
  [GET_CHECKOUT_URL]: (state: ICheckoutState) => ({
    ...state,
    fetchingCheckoutUrl: true,
    fetchedCheckoutUrl: false,
    fetchedCheckoutUrlFail: false,
  }),
  [GET_CHECKOUT_URL_SUCCESS]: (
    state: ICheckoutState,
    action: ActionType<typeof getCheckoutUrlActionSuccess>,
  ) => ({
    ...state,
    checkout_url: action.payload,
    fetchingCheckoutUrl: false,
    fetchedCheckoutUrl: true,
    fetchedCheckoutUrlFail: false,
  }),
  [GET_CHECKOUT_URL_FAILURE]: (state: ICheckoutState) => ({
    ...state,
    fetchingCheckoutUrl: false,
    fetchedCheckoutUrl: false,
    fetchedCheckoutUrlFail: true,
  }),
  [GET_BILLING_URL]: (state: ICheckoutState) => ({
    ...state,
    fetchingBillingUrl: true,
    fetchedBillingUrl: false,
    fetchedBillingUrlFail: false,
  }),
  [GET_BILLING_URL_SUCCESS]: (
    state: ICheckoutState,
    action: ActionType<typeof getBillingUrlActionSuccess>,
  ) => ({
    ...state,
    billing_url: action.payload,
    fetchingBillingUrl: false,
    fetchedBillingUrl: true,
    fetchedBillingUrlFail: false,
  }),
  [GET_BILLING_URL_FAILURE]: (state: ICheckoutState) => ({
    ...state,
    fetchingBillingUrl: false,
    fetchedBillingUrl: false,
    fetchedBillingUrlFail: true,
  }),
});
