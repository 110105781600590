import { ajax } from "rxjs/ajax";
import { Observable, switchMap, forkJoin, of } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { mergeMap, map, filter, catchError } from "rxjs/operators";

import { Actions, IDashboard, IDashboardFollowers, IDashboardImpressions } from "./types";
import { RootState } from "..";

import {
  postDashboardActionSuccess,
  postDashboardActionFailure,
  getDashboardCSVActionFailure,
  getDashboardFollowersActionSuccess,
  getDashboardFollowersActionFailure,
  getDashboardImpressionsActionSuccess,
  getDashboardImpressionsActionFailure,
} from "./actions";
import {
  GET_DASHBOARD,
  GET_DASHBOARD_CSV,
  GET_DASHBOARD_FOLLOWERS,
  GET_DASHBOARD_IMPRESSIONS,
} from "./actionTypes";

import { dashboard, dashboardCSV, profilesFollowers, profilesImpressions } from "@utils/paths";
import { downloadFile } from "@utils/downloadFile";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

const DASHBOARD_BATCH_SIZE = 3;

export const getDashboardDataEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD)),
    mergeMap(action => {
      const { components, ...restPayload } = action.payload;

      const batches = components.reduce((acc, curr, i) => {
        const batchIndex = Math.floor(i / DASHBOARD_BATCH_SIZE);
        if (!acc[batchIndex]) {
          acc[batchIndex] = [];
        }
        acc[batchIndex].push(curr);
        return acc;
      }, [] as string[][]);

      const requests = batches.map(batchComponents =>
        ajax
          .post<{ data: IDashboard }>(
            `${dashboard}`,
            {
              ...restPayload,
              components: batchComponents,
            },
            getHeaders({
              Authorization: state$.value.auth.session.accessToken.jwtToken,
            }),
          )
          .pipe(
            map(response => response.response.data),
            catchError(error => of({ error })),
          ),
      );

      return forkJoin(requests).pipe(
        map(results => {
          const hasError = results.some(result => "error" in result);
          if (hasError) {
            return postDashboardActionFailure();
          }

          const mergedData = results.reduce(
            (acc, curr) => ({
              ...acc,
              ...curr,
            }),
            {},
          );

          return postDashboardActionSuccess(mergedData as IDashboard);
        }),
        catchError(() => of(postDashboardActionFailure())),
      );
    }),
  );

export const getCsvEpic = (action$: Observable<Actions>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD_CSV)),
    switchMap(({ payload: { filters, report_type } }) =>
      ajax<string>({
        method: "POST",
        url: dashboardCSV,
        body: JSON.stringify({
          component: "explorer_agent_performance",
          report_type,
          filters,
        }),
        headers: getHeaders({
          Authorization: state$.value.auth.session.accessToken.jwtToken,
        }),
        responseType: "text",
      }).pipe(
        map(e => e.response),
        map(data => {
          downloadFile(
            `${
              report_type === "detailed"
                ? "agent-performance-detailed-last-5000-messages"
                : "agent-performance-summary"
            }.csv`,
            data,
          );
        }),
        catchError(async e => {
          handleError(e, getDashboardCSVActionFailure);
        }),
      ),
    ),
  );

export const getDashboardFollowersDataEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD_FOLLOWERS)),
    switchMap(a => {
      return ajax
        .post<IDashboardFollowers>(
          profilesFollowers,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getDashboardFollowersActionSuccess(data)),
          catchError(e => handleError(e, getDashboardFollowersActionFailure)),
        );
    }),
  );

export const getDashboardImpressionsDataEpic = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_DASHBOARD_IMPRESSIONS)),
    switchMap(a => {
      return ajax
        .post<IDashboardImpressions>(
          profilesImpressions,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getDashboardImpressionsActionSuccess(data)),
          catchError(e => handleError(e, getDashboardImpressionsActionFailure)),
        );
    }),
  );
