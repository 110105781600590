import React from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Col } from "antd";
import { Help } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./PostsTable.module.less";

import { IMetricAggregation } from "@store/posts/types";

interface IPostsTablePrefix {
  data?: IMetricAggregation;
  type: "totals" | "averages";
}

const PostsTablePrefix = React.memo(({ data, type }: IPostsTablePrefix) => {
  const { t } = useTranslation();

  const nssScore = data?.comment_metrics?.nss_score;

  return (
    <div className={classNames(s.bbPostsTableRow, s.bbPostsTablePrefix)}>
      <Col span={3} className={classNames(s.bbPostsTablePrefixTitle, s.bbColumnSticky)}>
        {t(`components:posts:table:prefix:${type}Title`)}
        <Help
          iconType="info"
          iconClassName={s.bbPostsTablePrefixHelpIcon}
          tooltipProps={{
            title: t(`components:posts:table:prefix:${type}Tooltip`),
            style: { width: 160 },
          }}
        />
      </Col>
      <Col className={classNames(s.bbColumn, s.bbColumnEmpty)} span={1.25}>
        —
      </Col>
      <Col className={classNames(s.bbColumn, s.bbColumnEmpty)} span={1}>
        —
      </Col>
      <Col
        className={classNames(s.bbColumn, {
          [s.bbColumnEmpty]: type === "totals",
        })}
        span={1}
      >
        {type === "totals" ? (
          "—"
        ) : (
          <div
            className={classNames(
              s.bbNssScore,
              s[`bbNssScore-${!!nssScore ? (nssScore > 0 ? "positive" : "negative") : "neutral"}`],
            )}
          >
            {(nssScore || 0) + "%"}
          </div>
        )}
      </Col>
      <Col
        className={classNames(s.bbColumn, {
          [s.bbColumnEmpty]: type === "totals",
        })}
        span={1.25}
      >
        {type === "totals"
          ? "—"
          : data?.post_metrics?.engagement_rate_impressions
            ? parseFloat(Number(data?.post_metrics?.engagement_rate_impressions).toFixed(4)) + "%"
            : "0%"}
      </Col>
      <Col
        className={classNames(s.bbColumn, {
          [s.bbColumnEmpty]: type === "totals",
        })}
        span={1.25}
      >
        {type === "totals"
          ? "—"
          : data?.post_metrics?.engagement_rate
            ? parseFloat(Number(data?.post_metrics?.engagement_rate).toFixed(4)) + "%"
            : "0%"}
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.comment_metrics.total_comments}
      </Col>
      <Col className={s.bbColumn} span={1.25}>
        {data?.post_metrics?.total_reactions}
      </Col>
      <Col className={s.bbColumn} span={1.25}>
        {data?.post_metrics?.total_views}
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.post_metrics?.total_reaches}
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.post_metrics?.total_shares}
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.comment_metrics.positive_comments || 0}
        <span className={s.bbWhiteChip}>
          {data?.comment_metrics.positive_comments_percent || 0}%
        </span>
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.comment_metrics.negative_comments || 0}
        <span className={s.bbWhiteChip}>
          {data?.comment_metrics.negative_comments_percent || 0}%
        </span>
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.comment_metrics.neutral_comments || 0}
        <span className={s.bbWhiteChip}>
          {data?.comment_metrics.neutral_comments_percent || 0}%
        </span>
      </Col>
      <Col className={s.bbColumn} span={1}>
        {data?.comment_metrics.user_tags_comments || 0}
        <span className={s.bbWhiteChip}>
          {data?.comment_metrics.user_tags_comments_percent || 0}%
        </span>
      </Col>
      <Col className={s.bbColumn} span={1.25}>
        {data?.comment_metrics.hidden_comments || 0}
        <span className={s.bbWhiteChip}>{data?.comment_metrics.hidden_comments_percent || 0}%</span>
      </Col>
      <Col className={s.bbColumn} span={1.25}>
        {data?.comment_metrics.brand_comments || 0}
        <span className={s.bbWhiteChip}>{data?.comment_metrics.brand_comments_percent || 0}%</span>
      </Col>
      <Col className={classNames(s.bbColumn, s.bbColumnEmpty)} span={1}>
        —
      </Col>
      <Col className={classNames(s.bbColumn, s.bbColumnEmpty)} span={1}>
        —
      </Col>
      <Col className={classNames(s.bbColumn, s.bbColumnEmpty)} span={1.25}>
        —
      </Col>
    </div>
  );
});

PostsTablePrefix.displayName = "PostsTablePrefix";

export default PostsTablePrefix;
