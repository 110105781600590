export const GET_ASSETS = "ASSETS/GET_ASSETS";
export const GET_ASSETS_SUCCESS = "ASSETS/GET_ASSETS_SUCCESS";
export const GET_ASSETS_FAILURE = "ASSETS/GET_ASSETS_FAILURE";

export const CONFIGURE_ASSET_MONITORING = "ASSETS/CONFIGURE_ASSET_MONITORING";
export const CONFIGURE_ASSET_MONITORING_SUCCESS = "ASSETS/CONFIGURE_ASSET_MONITORING_SUCCESS";
export const CONFIGURE_ASSET_MONITORING_FAILURE = "ASSETS/CONFIGURE_ASSET_MONITORING_FAILURE";

export const DELETE_ASSET = "ASSETS/DELETE_ASSET";
export const DELETE_ASSET_SUCCESS = "ASSETS/DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "ASSETS/DELETE_ASSET_FAILURE";

export const PATCH_RECONNECT_ASSETS = "ASSETS/PATCH_RECONNECT_ASSETS";
export const PATCH_RECONNECT_ASSETS_SUCCESS = "ASSETS/PATCH_RECONNECT_ASSETS_SUCCESS";
export const PATCH_RECONNECT_ASSETS_FAILURE = "ASSETS/PATCH_RECONNECT_ASSETS_FAILURE";
