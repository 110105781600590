import { action } from "typesafe-actions";
import { IStep1CallbackData } from "@components/login/signupSteps/step1/Step1.type";
import { IFormStep2 } from "@components/login/signupSteps/step2/Step2.type";
import { SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL } from "./actionTypes";

import { IHubspotState } from "./types";

export const submitHubspotForm = (body: IStep1CallbackData | IFormStep2) => action(SUBMIT, body);
export const submitHubspotFormSuccess = (data: IHubspotState) => action(SUBMIT_SUCCESS, data);
export const submitHubspotFormFail = () => action(SUBMIT_FAIL);
