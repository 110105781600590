import { action } from "typesafe-actions";
import {
  GET_CHECKOUT_URL,
  GET_CHECKOUT_URL_SUCCESS,
  GET_CHECKOUT_URL_FAILURE,
  GET_BILLING_URL,
  GET_BILLING_URL_SUCCESS,
  GET_BILLING_URL_FAILURE,
} from "./actionTypes";

export const getCheckoutUrlAction = () => action(GET_CHECKOUT_URL);
export const getCheckoutUrlActionSuccess = (url: string) => action(GET_CHECKOUT_URL_SUCCESS, url);
export const getCheckoutUrlActionFailure = () => action(GET_CHECKOUT_URL_FAILURE);

export const getBillingUrlAction = () => action(GET_BILLING_URL);
export const getBillingUrlActionSuccess = (url: string) => action(GET_BILLING_URL_SUCCESS, url);
export const getBillingUrlActionFailure = () => action(GET_BILLING_URL_FAILURE);
