import classNames from "classnames";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import AssignUser from "@containers/AssignUser/AssignUser";
import ActionStar from "../CommentCard/commentActions/predefinedActions/ActionStar";
import ActionArchive from "../CommentCard/commentActions/predefinedActions/ActionArchive";
import TranslateAll from "../CommentCard/commentActions/predefinedActions/TranslateAll";

import {
  performCommentAction,
  assignCommentUserAction,
  updateCommentSentimentAction,
} from "@store/comments/actions";
import { meSelector } from "@store/me/selectors";

import s from "./ConversationActions.module.less";
import sentimentStyles from "../CommentCard/commentActions/CommentActions.module.less";

import { PostPlacementEnum } from "../Social/Post/Post.type";
import { IConversationActions } from "./CommentDetail.type";
import { useReplyModalContext } from "@containers/ReplyModal/ReplyModalContext";
import { useCommentExternalActionDropdownItems } from "../MessageCard/dropdowns/useCommentExternalActionDropdownItems"; // eslint-disable-line max-len

const ConversationActions: FC<IConversationActions> = ({ filters, comment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);
  const { isMessagesTranlated, toggleTranslation, translateAllButtonVisible } =
    useReplyModalContext();

  const isDirectMessage = comment?.post_placement === PostPlacementEnum.DM;
  const starActionLabel = comment.reply.is_starred ? t("generic:starred") : t("generic:star");
  const archiveActionLabel = comment.reply.is_ignored
    ? t("generic:archived")
    : t("generic:archive");
  const translateAllLabel = isMessagesTranlated
    ? t("components:reply:showOriginals")
    : t("components:reply:translateAll");

  const archiveComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value: value,
          action: "ignore",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const starComment = useCallback(
    (id: string, value: boolean) => {
      dispatch(
        performCommentAction({
          id,
          value: value,
          action: "star",
          filters,
        }),
      );
    },
    [dispatch, filters],
  );

  const onAssignUserChange = (value: string[], assigningCommentId: string) => {
    if (assigningCommentId) {
      dispatch(assignCommentUserAction(assigningCommentId, { assigned_users: value }, me?.id));
    }
  };

  const updateCommentSentiment = useCallback(
    (id, sentiment) => {
      dispatch(updateCommentSentimentAction(id, { sentiment }));
    },
    [dispatch],
  );

  const externalActionFunctions = {
    updateCommentSentiment,
  };

  const commentExternalActions = useCommentExternalActionDropdownItems({
    comment,
    actionFunctions: externalActionFunctions,
    showCommentLink: !me?.is_demo_mode_active,
  });

  return (
    <>
      {isDirectMessage && !!comment && (
        <div className={s.bbConversationActions}>
          <div className={s.bbConversationActionsTitle}>
            {t("components:reply:conversationActions")}
          </div>
          <div className={s.bbConversationActionsWrapper}>
            <span className={s.bbConversationActionAssignUser}>
              <div className={s.bbMessageActionButtonClass}>
                <AssignUser
                  value={comment?.assigned_users || []}
                  triggerPlaceholder={t("generic:assign")}
                  onChange={value => onAssignUserChange(value, comment.id)}
                  triggerClassName={classNames(
                    s.bbMessageActionTriggerClass,
                    s.bbAssignUserTrigger,
                  )}
                />
              </div>
            </span>
            <span className={s.bbConversationActionWrapper}>
              <ActionStar
                useShortcuts
                comment={comment}
                onStarClick={starComment}
                label={starActionLabel}
                className={s.bbMessageActionButtonClass}
              />
            </span>
            <span className={s.bbConversationActionWrapper}>
              <ActionArchive
                useShortcuts
                comment={comment}
                label={archiveActionLabel}
                onArchiveClick={archiveComment}
                className={s.bbMessageActionButtonClass}
              />
            </span>
            {translateAllButtonVisible && (
              <span className={s.bbConversationActionWrapper}>
                <TranslateAll
                  label={translateAllLabel}
                  className={s.bbMessageActionButtonClass}
                  onClick={toggleTranslation}
                />
              </span>
            )}
            {!!commentExternalActions.length && (
              <span>
                <ActionsDropdown
                  items={commentExternalActions}
                  triggerLabel={t("generic:more")}
                  triggerClass={classNames(
                    sentimentStyles.bbExternalActionsTrigger,
                    s.bbCommentExternalActionsTrigger,
                  )}
                />
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ConversationActions;
