import React from "react";
import { useTranslation } from "react-i18next";

import s from "./TermsConditions.module.less";

interface ITermsConditionsProps {
  label?: string;
}

export const TermsConditions = ({ label }: ITermsConditionsProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbTermsConditions}>
      <div className={s.bbTermsConditionsText}>
        {label || t("pages:signup:step1:left:form:terms:label:acceptTerms")}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://info.brandbastion.com/app/privacy-policy"
        >
          {t("pages:signup:step1:left:form:terms:label:privacyPolicy")}
        </a>{" "}
        {t("generic:and")}{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://info.brandbastion.com/app/terms-of-service"
        >
          {t("pages:signup:step1:left:form:terms:label:termsOfService")}
        </a>
        .
      </div>
    </div>
  );
};
