import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import React, { FC, useState } from "react";
import classNames from "classnames";

import { Grid } from "antd";
import ChartLabel from "./ChartLabel";
import DownloadCSV from "../../../_common/DownloadCSV";
import BreakdownChartTooltip from "./breakdownChartTooltip";
import BreakdownChartLegend from "./breakdownChartLegend";
import { BreakdownChart } from "../../../_common/BreakdownChart/BreakdownChart";
import { Card, Button, Tooltip, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentBreakdown.module.less";

import {
  LABEL_SMALL,
  LABEL_LARGE,
  CHART_HEIGHT,
  getCSVChartData,
  getCSVTableHeaders,
} from "./helpers";
import { useYTSelected } from "@utils/useYTselected";
import { ICommentBreakdownProps } from "./CommentBreakdown.type";
// eslint-disable-next-line max-len
import { IChartLabelClickEvent } from "../../_common/topInfluencers/topInfluencersChart/TopInfluencersChart.type";

import { DownloadIcon } from "@assets/index";
import { filterDataSelector } from "@store/filters/selectors";

const CommentBreakdown: FC<ICommentBreakdownProps> = ({
  data: breakdownUnits,
  filterToSpecificItem,
  className,
}) => {
  const { t } = useTranslation();
  const filters = useSelector(filterDataSelector);
  const isYTFilterSelected = useYTSelected(filters?.platform_types || []);
  const screens = Grid.useBreakpoint();

  const chartLabelWidth = screens.md ? LABEL_LARGE : LABEL_SMALL;

  const [activeTabKey, setActiveTabKey] = useState("platform_type");

  const getSelectedItemLabel = () => {
    return breakdownUnits?.find(item => item.id === activeTabKey)?.label;
  };

  const onYAxisLabelClick = (clickedItem: IChartLabelClickEvent) => {
    const currentSelectedItem = breakdownUnits.find(
      breakdownUnit => breakdownUnit.id === activeTabKey,
    );

    if (currentSelectedItem && clickedItem?.index !== undefined) {
      const selectedItemValue = currentSelectedItem["items"][clickedItem?.index];

      // Custom tags have to follow this structure to be properly shown in the filters pane
      if (activeTabKey === "custom_tags") {
        filterToSpecificItem({
          comment_tags: [`custom_tag::${selectedItemValue.id}`],
        });
      } else {
        filterToSpecificItem({
          [currentSelectedItem.search_param as string]: [selectedItemValue.id],
        });
      }
    }
  };

  const getActiveTabName = () => {
    return breakdownUnits.find(unit => unit.id === activeTabKey)?.label || "";
  };

  return (
    <Card
      id="card-breakdown"
      className={classNames(s.bbBreakdownCard, className)}
      data-cy="search-breakdown-chart"
      data-stonly-target="intelligence-search__breakdown"
      headerTitle={t("components:breakdown:title", {
        selected: getSelectedItemLabel(),
      })}
      headerTooltip={t("components:breakdown:info", {
        selected: getSelectedItemLabel(),
      })}
      headerAction={
        <>
          {
            <Tooltip title={isYTFilterSelected ? t("pages:insights:ytNoDownload") : ""}>
              <span>
                <Button className={s.bbDownloadButton} disabled={isYTFilterSelected}>
                  <DownloadCSV
                    icon={<DownloadIcon />}
                    fileNamePrefix="breakdown"
                    disabled={isYTFilterSelected}
                    data={getCSVChartData(breakdownUnits, activeTabKey)}
                    tableHeaders={getCSVTableHeaders(getActiveTabName())}
                  />
                </Button>
              </span>
            </Tooltip>
          }
        </>
      }
    >
      <div className={s.bbBreakdownContainer}>
        <TabsScrollable
          activeKey={activeTabKey}
          onChange={key => setActiveTabKey(key)}
          defaultActiveKey={breakdownUnits[0]?.id}
        >
          {breakdownUnits?.map(breakdownUnit => (
            <TabPane
              tab={
                <div data-stonly-target={"intelligence-search__breakdown--tab-" + breakdownUnit.id}>
                  {breakdownUnit.label}
                </div>
              }
              tabKey={breakdownUnit.id}
              key={breakdownUnit.id}
            >
              <ReactResizeDetector
                handleWidth
                refreshRate={2000}
                refreshMode={"throttle"}
                refreshOptions={{ leading: true, trailing: true }}
              >
                {(resizeProps: { width: number }) => {
                  return (
                    <React.Fragment key={breakdownUnit.id}>
                      <BreakdownChart
                        data={breakdownUnit}
                        height={CHART_HEIGHT}
                        labelWidth={chartLabelWidth}
                        width={resizeProps.width || 0}
                        onYAxisLabelClick={onYAxisLabelClick}
                        customTooltip={<BreakdownChartTooltip />}
                        generateLabel={props => (
                          <ChartLabel
                            type={activeTabKey}
                            labelTickProps={props}
                            breakdownUnit={breakdownUnit}
                            tooltipLabel={getActiveTabName()}
                            chartLabelWidth={chartLabelWidth}
                          />
                        )}
                      />
                      <BreakdownChartLegend chartLabelWidth={chartLabelWidth} />
                    </React.Fragment>
                  );
                }}
              </ReactResizeDetector>
            </TabPane>
          ))}
        </TabsScrollable>
      </div>
    </Card>
  );
};

export default CommentBreakdown;
