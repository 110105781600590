import { produce } from "immer";
import { createReducer } from "typesafe-actions";

import { IAttachmentState } from "./types";
import { OPEN_ATTACHMENT, CLOSE_ATTACHMENT } from "./actionTypes";

const initialState = {
  attachment: undefined,
};

export const attachmentReducer = createReducer<IAttachmentState>(initialState, {
  [OPEN_ATTACHMENT]: produce((draft, action) => {
    draft.attachment = action.payload;
  }),
  [CLOSE_ATTACHMENT]: produce(draft => {
    draft.attachment = undefined;
  }),
});
