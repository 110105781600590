import React from "react";
import { TFunction } from "react-i18next";

import ImageTooltip from "@components/_common/ImageTooltip";

import { CheckboxListType } from "@components/_common/CheckboxList/CheckboxList.types";
import { ReportSectionType } from "./DownloadReportModal.types";

import sentimentPreviewSrc from "./assets/sentiment-preview.png";

export const getSectionValues = (
  t: TFunction,
  sections: ReportSectionType[] = [],
  values?: string[],
) => ({
  sections: sections.reduce<CheckboxListType>((acc, section) => {
    acc[section] = {
      checked: values?.length ? values.includes(section) : true,
      id: section,
      label: (
        <>
          {t(`components:pdfReport:sections:${section}`)}{" "}
          {section === "sentiment_preview" && (
            <ImageTooltip width={329} height={179} src={sentimentPreviewSrc} />
          )}
        </>
      ),
    };
    return acc;
  }, {}),
});
