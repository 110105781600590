export const aggregationInfoKey = (rangeOption: string) => {
  let value = "";

  switch (rangeOption) {
    case "last_30_days":
      value = "30_days";
      break;
    case "last_14_days":
      value = "14_days";
      break;
    case "last_7_days":
      value = "7_days";
      break;
    case "current_day":
    case "yesterday":
      value = "day";
      break;
    case "current_week":
    case "last_week":
      value = "week";
      break;
    case "current_month":
    case "last_month":
      value = "month";
      break;
    default:
      value = "day";
      break;
  }

  return value;
};
