import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal, Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DeleteCommentConfirmationModal.module.less";

interface IDeleteCommentConfirmationModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteCommentConfirmationModal: FC<IDeleteCommentConfirmationModalProps> = ({
  isOpen,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      responsive
      classes={{
        root: s.bbDeleteConfirmationModal,
      }}
      centered
      title={t("components:comments:deleteConfirmationModal:title")}
      hideFooter
      open={isOpen}
      onCancel={onCancel}
    >
      <p className={s.bbDeleteConfirmationModalPrimaryText}>
        {t("components:comments:deleteConfirmationModal:primaryText")}
      </p>
      <p className={s.bbDeleteConfirmationModalSecondaryText}>
        {t("components:comments:deleteConfirmationModal:secondaryText")}
      </p>
      <div className={s.bbDeleteConfirmationModalActions}>
        <Button
          _size="md"
          _type="link"
          onClick={onCancel}
          className={s.bbDeleteConfirmationModalCancel}
        >
          {t("components:comments:deleteConfirmationModal:cancel")}
        </Button>
        <Button _size="md" _type="danger" onClick={onConfirm}>
          {t("components:comments:deleteConfirmationModal:confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteCommentConfirmationModal;
