import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import SectionDivider from "@components/insights/overview/OverviewSectionDivider";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "@components/insights/_common/pieChartWidget/PieChartWidget";
import { ProfilesTableWrapper } from "./profilesTable/ProfilesTableWrapper";

import s from "./Profiles.module.less";

import { ProfilesSectionProps } from "./Profiles.types";

import { UserIcon } from "@assets/index";
import { EyeIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ProfilesSection: FC<ProfilesSectionProps> = ({
  loading,
  chartData,
  tableData,
  totals,
  type,
}) => {
  const { t } = useTranslation();

  const isFollowersSection = type === "followers";

  return (
    <div className={s.bbProfilesContent}>
      <SectionDivider
        icon={isFollowersSection ? <UserIcon /> : <EyeIcon />}
        title={t(`components:listen:profiles:${type}:title`)}
        description={t(`components:listen:profiles:${type}:description`)}
      />
      {!isFollowersSection && (
        <span className={s.bbProfilesImpressionsTitle}>
          {t(`components:listen:profiles:impressions:chart:title`)}
        </span>
      )}
      <div className={s.bbProfilesWidgets}>
        <Card
          headerTitle={
            isFollowersSection ? t(`components:listen:profiles:followers:chart:title`) : undefined
          }
          className={classNames(s.bbProfilesChartWrapper)}
        >
          <div className={s.bbProfilesChart}>
            <PieChartWidget
              loading={loading}
              position="center"
              radiuses={isFollowersSection ? [50, 65] : undefined}
              sizes={isFollowersSection ? [130, 130] : undefined}
              hasLabel={false}
              defaultValue={totals.chart}
              chartData={chartData}
              emptyLabel={t("generic:emptyTable")}
            />
          </div>
        </Card>
        {type === "followers" ? (
          <ProfilesTableWrapper
            type="followers"
            data={tableData}
            loading={loading}
            total={totals.table}
          />
        ) : (
          <ProfilesTableWrapper
            type="impressions-platform"
            data={tableData}
            loading={loading}
            total={totals.table}
          />
        )}
      </div>
    </div>
  );
};
