import dayjs from "dayjs";
import React from "react";

import { CloseIcon, TiktokColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplySharePostPreview.module.less";

import { IReplySharePostPreviewProps } from "./ReplySharePostPreview.type";

export const ReplySharePostPreview = ({
  replySharePost,
  clearSharePost,
}: IReplySharePostPreviewProps) => (
  <div className={s.bbReplySharePostPreviewWrapper}>
    <div className={s.bbReplySharePostPreviewImageWrapper}>
      <img src={replySharePost.picture} />
    </div>
    <div>
      <div className={s.bbReplySharePostPreviewTitleWrapper}>
        <TiktokColorIcon />
        <div className={s.bbReplySharePostPreviewTitleTextWrapper}>
          <span className={s.bbReplySharePostPreviewTitle}>{replySharePost.page_name}</span>
          <span className={s.bbReplySharePostPreviewSubtitle}>
            {dayjs(replySharePost.created_time).format("DD MMM, YYYY")}
          </span>
        </div>
      </div>
      <div className={s.bbReplySharePostPreviewDescription}>{replySharePost.message}</div>
    </div>
    <span className={s.bbRemoveSharePost} onClick={clearSharePost}>
      <CloseIcon />
    </span>
  </div>
);
