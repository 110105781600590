import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useMemo, useCallback, useEffect } from "react";

import { Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import {
  commentTagsSelector,
  getCommentTagsFailedSelector,
  getCommentTagsProcessingSelector,
  getCommentTagsSuccessfulSelector,
} from "@store/commentTags/selectors";
import { getCommentTagsAction } from "@store/commentTags/actions";

import { tagToOption } from "./helpers";
import { useTagState } from "../common";
import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { ICommentsFiltersProps } from "./Comments.type";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

import s from "./tagOption.module.less";

const CommentsFilters: FC<ICommentsFiltersProps> = ({
  value,
  disabled,
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  InputVariation = Input,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const commentTags = useSelector(commentTagsSelector);
  const commentTagsFetching = useSelector(getCommentTagsProcessingSelector);
  const commentTagsFetched = useSelector(getCommentTagsSuccessfulSelector);
  const commentTagsFetchFailed = useSelector(getCommentTagsFailedSelector);

  const getCommentTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const optionsFormatted = useMemo(() => {
    return commentTags.map(tagToOption);
  }, [commentTags]);

  const { tagFilterValues, excludedValues, _onChange, _onClearValues, _onExcludeChange } =
    useTagState(optionsFormatted, customprops, value, onChange);

  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(optionsFormatted, t("generic:search"));

  useEffect(() => {
    if (
      !commentTags.length &&
      !commentTagsFetching &&
      !commentTagsFetched &&
      !commentTagsFetchFailed
    )
      getCommentTags();
    // eslint-disable-next-line
  }, []);

  const _onClear = () => {
    _onClearValues();
  };

  const getTooltipItem = (_value: string) => (
    <span className={s.bbDisplayBlock} key={_value}>
      {commentTags.flatMap(cg => cg.children).find(c => c?.value === _value)?.title}
    </span>
  );

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const triggerNotEmpty = !!tagFilterValues.length || !!excludedValues?.length;

    const tooltipElement = (
      <>{tagFilterValues.map(tagFilterValue => getTooltipItem(tagFilterValue))}</>
    );

    const excludedTooltipElement = (
      <>{excludedValues?.map(excludedValue => getTooltipItem(excludedValue))}</>
    );

    return ClearableTrigger({
      tooltip: triggerNotEmpty ? tooltipElement : undefined,
      excludedTooltip: triggerNotEmpty ? excludedTooltipElement : undefined,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id="comment-tags-filter"
      disabled={disabled}
      onChange={_onChange}
      value={tagFilterValues}
      options={searchedOptions}
      highlightTerm={searchTerm}
      DropdownHeader={SearchInput}
      InputVariation={InputVariation}
      excludedValues={excludedValues}
      TriggerVariation={TriggerWithTooltip}
      onExcludeChange={_onExcludeChange}
      dropdownPlacement={dropdownPlacement}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      onDropdownClose={() => setSearchTerm("")}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      data-cy={props["data-cy" as keyof typeof props]}
      placeholder={placeholder || t("components:comments:filterByTags:placeholder")}
    />
  );
};

export default CommentsFilters;
