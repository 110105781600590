import React from "react";
import classNames from "classnames";

import s from "./AppSidebar.module.less";

const AppSideBarLabel: React.FC<React.HTMLAttributes<HTMLLabelElement>> = ({
  children,
  className,
}) => {
  return <label className={classNames(s.bbAppSideBarLabel, className)}>{children}</label>;
};

export default AppSideBarLabel;
