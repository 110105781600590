import { action } from "typesafe-actions";
import {
  IInsightsChecklist,
  PossibleChecklistIdsType,
  serializeChecklist,
} from "@utils/userChecklist";
import {
  GET_ME,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  PATCH_ME,
  PATCH_ME_SUCCESS,
  PATCH_ME_FAILURE,
  PATCH_DEMO_MODE,
  PATCH_DEMO_STORY,
  PATCH_CHECKLIST,
  PATCH_ME_CLIENT,
  PATCH_ME_CLIENT_SUCCESS,
  PATCH_ME_CLIENT_FAILURE,
  PATCH_ME_SESSION_CLIENT,
  PATCH_ME_SESSION_CLIENT_SUCCESS,
  PATCH_ME_SESSION_CLIENT_FAILURE,
  POST_ME_EMAIL,
  POST_ME_EMAIL_SUCCESS,
  POST_ME_EMAIL_FAILURE,
  PATCH_ME_EMAIL,
  PATCH_ME_EMAIL_SUCCESS,
  PATCH_ME_EMAIL_FAILURE,
  CANCEL_ME_EMAIL,
  POST_ME_PASSWORD,
  POST_ME_PASSWORD_SUCCESS,
  POST_ME_PASSWORD_FAILURE,
  DELETE_ME_AVATAR,
  DELETE_ME_AVATAR_FAILURE,
  DELETE_ME_AVATAR_SUCCESS,
} from "./actionTypes";
import { IClient, IConfirmEmail, IMe } from "./types";

// GET
export const getMeAction = () => action(GET_ME);
export const getMeSuccessAction = (data: IMe) => action(GET_ME_SUCCESS, data);
export const getMeFailureAction = () => action(GET_ME_FAILURE);

// PATCH ME
export const patchMeAction = (data: IMe) => action(PATCH_ME, data);
export const patchMeSuccessAction = (data: IMe) => action(PATCH_ME_SUCCESS, data);
export const patchMeFailureAction = () => action(PATCH_ME_FAILURE);

// PATCH ME CLIENT
export const patchMeClientAction = (client: IClient) => action(PATCH_ME_CLIENT, client);
export const patchMeClientSuccessAction = (client: IClient) =>
  action(PATCH_ME_CLIENT_SUCCESS, client);
export const patchMeClientFailureAction = () => action(PATCH_ME_CLIENT_FAILURE);

// PATCH ME SESSION CLIENT
export const patchMeSessionClientAction = (client: string) =>
  action(PATCH_ME_SESSION_CLIENT, client);
export const patchMeSessionClientSuccessAction = (data: IMe) => {
  action(PATCH_ME_SESSION_CLIENT_SUCCESS, data);
  const redirectUrl = data?.features?.find(feature => feature.id === "insights")?.url;

  if (redirectUrl) {
    window.location.href = redirectUrl;
  } else {
    window.location.reload();
  }
};
export const patchMeSessionClientFailureAction = () => action(PATCH_ME_SESSION_CLIENT_FAILURE);

// POST ME EMAIL
export const postMeEmailAction = () => action(POST_ME_EMAIL);
export const postMeEmailSuccessAction = () => action(POST_ME_EMAIL_SUCCESS);
export const postMeEmailFailureAction = () => action(POST_ME_EMAIL_FAILURE);

// PATCH ME EMAIL
export const patchMeEmailAction = (data: IConfirmEmail) => action(PATCH_ME_EMAIL, data);
export const patchMeEmailSuccessAction = (data: IMe) => action(PATCH_ME_EMAIL_SUCCESS, data);
export const patchMeEmailFailureAction = () => action(PATCH_ME_EMAIL_FAILURE);

// CANCEL ME EMAIL
export const cancelMeEmailAction = () => action(CANCEL_ME_EMAIL);

// CHANGE PASSWORD
export const postMePasswordAction = () => action(POST_ME_PASSWORD);
export const postMePasswordSuccessAction = () => action(POST_ME_PASSWORD_SUCCESS);
export const postMePasswordFailureAction = () => action(POST_ME_PASSWORD_FAILURE);

// DELETE ME AVATAR
export const deleteMeAvatarAction = () => action(DELETE_ME_AVATAR);
export const deleteMeAvatarSuccessAction = (data: IMe) => action(DELETE_ME_AVATAR_SUCCESS, data);
export const deleteMeAvatarFailureAction = () => action(DELETE_ME_AVATAR_FAILURE);

// PATCH DEMO MODE
export const patchDemoModeAction = (data: { is_active: boolean }) => action(PATCH_DEMO_MODE, data);

// PATCH DEMO STORY
export const patchDemoStoryAction = (data: IMe) => action(PATCH_DEMO_STORY, data);

// PATCH CHECKLIST
export const patchChecklistAction = (
  fullChecklist?: IInsightsChecklist,
  checklistQueryparam?: PossibleChecklistIdsType,
) =>
  action(PATCH_CHECKLIST, {
    insights: fullChecklist && serializeChecklist(fullChecklist, checklistQueryparam),
  });
