import { IComment } from "@store/comments/types";

export const REPLY_MAX_LENGTH = 100;

export interface IBrandReplyProps {
  comment: IComment;
  onReplyClick?: (commentId: string) => void;
}

export interface IBrandReplyMessageProps {
  message: string;
  onClickReply: () => void;
}
