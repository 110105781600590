import React from "react";
import { useTranslation, Trans } from "react-i18next";

import classNames from "classnames";

import s from "./Tooltips.module.less";

const platforms = ["facebook", "instagram", "tiktok", "linkedin", "twitter", "youtube"];

const NSSTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.bbPostMetricTooltip, s.bbNSSTooltip)}>
      <div>
        <Trans
          i18nKey={"components:posts:table:tableHeader:tooltips:nss:title"}
          components={{
            strong: <span className={s.bbPostMetricTooltipBold} />,
          }}
        />
      </div>
      <div className={s.bbPostMetricTooltipBlock}>
        {t("components:posts:table:tableHeader:tooltips:nss:subTitle")}
      </div>
      <div>{t("components:posts:table:tableHeader:tooltips:nss:definition")}</div>
    </div>
  );
};

const EngagementRateTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={s.bbPostMetricTooltipBold}>
        {t("components:posts:table:tableHeader:tooltips:engagementRate:title")}
      </div>
      <div className={s.bbPostMetricTooltipBlock}>
        {t("components:posts:table:tableHeader:tooltips:engagementRate:subTitle")}
      </div>

      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:engagementRate." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ImpressionsTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={classNames(s.bbPostMetricTooltipBlock, s.bbPostMetricTooltipBold)}>
        {t("components:posts:table:tableHeader:tooltips:impressions:title")}
      </div>

      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:impressions." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ERByImpressionsTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={s.bbPostMetricTooltipBold}>
        {t("components:posts:table:tableHeader:tooltips:erByImpressions:title")}
      </div>
      <div className={s.bbPostMetricTooltipBlock}>
        {t("components:posts:table:tableHeader:tooltips:erByImpressions:subTitle")}
      </div>

      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:erByImpressions." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ReachesTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={classNames(s.bbPostMetricTooltipBlock, s.bbPostMetricTooltipBold)}>
        {t("components:posts:table:tableHeader:tooltips:totalReaches:title")}
      </div>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:totalReaches." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const ReactionsTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:totalReactions." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

const SharesTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbPostMetricTooltip}>
      <div className={classNames(s.bbPostMetricTooltipBlock, s.bbPostMetricTooltipBold)}>
        {t("components:posts:table:tableHeader:tooltips:totalShares:title")}
      </div>
      {platforms.map(platform => (
        <div key={platform} className={s.bbPostMetricTooltipBlock}>
          <Trans
            i18nKey={"components:posts:table:tableHeader:tooltips:totalShares." + platform}
            components={{
              strong: <span className={s.bbPostMetricTooltipBold} />,
            }}
          />
        </div>
      ))}
      <div className={s.bbPostMetricTooltipFooter}>
        {t("components:posts:table:tableHeader:tooltips:footer")}
      </div>
    </div>
  );
};

export {
  EngagementRateTooltipContent,
  ERByImpressionsTooltipContent,
  ImpressionsTooltipContent,
  ReachesTooltipContent,
  ReactionsTooltipContent,
  SharesTooltipContent,
  NSSTooltipContent,
};
