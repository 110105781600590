import { createReducer, ActionType } from "typesafe-actions";

import { ITiktokState } from "./types";
import {
  tiktokConnectSuccessAction,
  tiktokPricingTableSuccessAction,
  tiktokSignupSuccessAction,
} from "./actions";
import {
  TIKTOK_CONNECT,
  TIKTOK_CONNECT_SUCCESS,
  TIKTOK_CONNECT_FAILURE,
  TIKTOK_SIGNUP,
  TIKTOK_SIGNUP_SUCCESS,
  TIKTOK_SIGNUP_FAILURE,
  TIKTOK_PRICING_TABLE,
  TIKTOK_PRICING_TABLE_SUCCESS,
  TIKTOK_PRICING_TABLE_FAILURE,
} from "./actionTypes";

const initialState = {
  tiktokCookies: undefined,
  tiktokSession: undefined,
  tiktokAction: undefined,
  tiktokSignupToken: undefined,
  tiktokSigninToken: undefined,
  tiktokUsername: undefined,
  tiktokAccessToken: undefined,

  tiktokConnecting: false,
  tiktokConnected: false,
  tiktokConnectedFail: false,

  tiktokSigningUp: false,
  tiktokSignedUp: false,
  tiktokSignupFail: false,

  tiktokPricingTableItems: [],
};

export const tiktokAppReducer = createReducer<ITiktokState>(initialState, {
  [TIKTOK_CONNECT]: (state: ITiktokState) => ({
    ...state,
    tiktokConnecting: true,
    tiktokConnected: false,
    tiktokConnectedFail: false,
  }),
  [TIKTOK_CONNECT_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof tiktokConnectSuccessAction>,
  ) => ({
    ...state,
    tiktokCookies: action.payload.cookies,
    tiktokSession: action.payload.session,
    tiktokAction: action.payload.action,
    tiktokSignupToken: action.payload.signup_token,
    tiktokSigninToken: action.payload.signin_token,
    tiktokAccessToken: action.payload.access_token,
    tiktokUsername: action.payload.username,
    tiktokConnecting: false,
    tiktokConnected: true,
    tiktokConnectedFail: false,
  }),
  [TIKTOK_CONNECT_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokConnecting: false,
    tiktokConnected: false,
    tiktokConnectedFail: true,
  }),

  [TIKTOK_SIGNUP]: (state: ITiktokState) => ({
    ...state,
    tiktokSigningUp: true,
    tiktokSignedUp: false,
    tiktokSignedUpFail: false,
  }),
  [TIKTOK_SIGNUP_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof tiktokSignupSuccessAction>,
  ) => ({
    ...state,
    tiktokAccessToken: action.payload.access_token,
    tiktokCookies: action.payload.cookies,
    tiktokSigninToken: action.payload.signin_token,
    tiktokUsername: action.payload.username,
    tiktokSigningUp: false,
    tiktokSignedUp: true,
    tiktokSignedUpFail: false,
  }),
  [TIKTOK_SIGNUP_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokSigningUp: false,
    tiktokSignedUp: false,
    tiktokSignedUpFail: true,
  }),
  [TIKTOK_PRICING_TABLE]: (state: ITiktokState) => ({
    ...state,
    tiktokPricingTableItems: [],
  }),
  [TIKTOK_PRICING_TABLE_SUCCESS]: (
    state: ITiktokState,
    action: ActionType<typeof tiktokPricingTableSuccessAction>,
  ) => ({
    ...state,
    tiktokPricingTableItems: action.payload,
  }),
  [TIKTOK_PRICING_TABLE_FAILURE]: (state: ITiktokState) => ({
    ...state,
    tiktokPricingTableItems: [],
  }),
});
