import React from "react";
import { useTranslation } from "react-i18next";

import { Trans } from "react-i18next";

import s from "./Notifications.module.less";

import EmptyNotificationsImg from "@assets/EmptyNotifications.svg";

export const NotificationsEmpty: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className={s.bbNotificationsEmpty}>
      <div className={s.bbNotificationsEmptyImg}>
        <EmptyNotificationsImg />
      </div>
      <div className={s.bbNotificationsEmptyTexts}>
        <span>{t("header:notifications:emptyTexts:sentence1")}</span>
        <span>
          <Trans
            i18nKey={"header:notifications:emptyTexts:sentence2"}
            components={{
              a: (
                <a
                  className={s.bbNotificationsEmptyLink}
                  href={`${process.env.BASE_URL}/care/workflows`}
                />
              ),
            }}
          />
        </span>
      </div>
    </div>
  );
};
