import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DMPostAttachment.module.less";

import { ArrowTopRightIcon } from "@assets/index";

import { IPostAttachmentProps } from "./Variants.types";

const DMPostAttachment = ({ url }: IPostAttachmentProps) => {
  const { t } = useTranslation();
  const onClick = () => window.open(url, "_blank");

  return (
    <Button _size="sm" className={s.bbViewPostButton} onClick={onClick}>
      {t("components:comments:actions:showPost")}
      <ArrowTopRightIcon />
    </Button>
  );
};

export default DMPostAttachment;
