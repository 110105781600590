import React from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "@bbdevcrew/bb_ui_kit_fe";
import SubscriptionPricingTable from "./SubscriptionPricingTable";

import s from "./SubscriptionModal.module.less";

import { ISubscriptionModalProps } from "./types";

const SubscriptionModal = ({ isVisible, onClose }: ISubscriptionModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      responsive
      width={1400}
      open={isVisible}
      onCancel={onClose}
      hideFooter
      centered
      classes={{
        root: s.bbSubscriptionModalWrapper,
      }}
      titleSufix={
        <div className={s.bbSubscriptionModalTitle}>{t("components:subscriptionModal:title")}</div>
      }
    >
      <SubscriptionPricingTable isVisible={isVisible} />
    </Modal>
  );
};

export default SubscriptionModal;
