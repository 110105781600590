import React from "react";
import i18next from "i18next";

import { i18nextInstance } from "../../App";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { IPlatformAsset } from "@components/_common/AssetTabs/AssetTabs.type";

export const normalizePostAssetPayload = (
  platformAssets: IPlatformAsset[],
  webhook_active?: boolean,
) => ({
  items: platformAssets.map(({ id, asset_platform, parent_id }) => ({
    id,
    parent_id,
    asset_platform,
    webhook_active,
  })),
});

export const notifyAssetPostSuccess = (data: IPlatformAsset[]) => {
  const addedAssetsCount = data.filter(({ success }) => success).length;
  const failedAssetsCount = data.filter(({ success }) => !success).length;

  if (addedAssetsCount > 0) {
    addToast({
      type: "success_accent",
      title: i18next.t("generic:success"),
      message: i18next.t("components:addAssets:addedAssetsMsg", {
        count: addedAssetsCount,
      }),
    });
  }

  if (failedAssetsCount > 0) {
    addToast({
      type: "danger_accent",
      title: i18next.t("generic:error"),
      message: i18next.t("components:addAssets:failedAssetsMsg", {
        count: failedAssetsCount,
      }),
    });
  }
};

export const getAssetsTimeoutErrorMessage = () => {
  return (
    <span>
      {i18nextInstance.t("pages:addAssets:timeoutErrorMessage")}
      {/* eslint-disable-next-line */}
      <a
        target="_blank"
        href="https://brandbastion.stonly.com/kb/guide/en/how-to-grant-brandbastion-access-to-accounts-via-bm-WubtlbaWCz/Steps/2736776"
      >
        {i18nextInstance.t("pages:addAssets:timeoutErrorLink")}
      </a>
      .
    </span>
  );
};
