import cn from "classnames/bind";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Avatar } from "antd";
import { Card, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./PostCard.module.less";

import { IPostCardImageProps } from "./PostCard.type";

const MAX_CONTAINER_WIDTH = 300;
const MAX_CONTAINER_PADDING = 32;
const PORTRAIT_IMAGE_GAP = 2;

const cx = cn.bind(s);

const PostCardImage: FC<IPostCardImageProps> = ({
  message,
  imageUrl,
  fullImage,
  onImageClick,
  containerWidth,
  showTextAsImage,
  isPortraitImage,
  imageActionItem,
}) => {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  const imageSize = useMemo(() => {
    const size = (containerWidth || MAX_CONTAINER_WIDTH) + (fullImage ? MAX_CONTAINER_PADDING : 0);

    if (isPortraitImage) {
      return size + PORTRAIT_IMAGE_GAP;
    }

    return size;
  }, [containerWidth, isPortraitImage, fullImage]);

  return (
    <div
      data-cy="post-card-image"
      className={cx(s.bbPostImage, {
        [s.bbPostImageFull]: fullImage,
        [s.bbShowTextAsImage]: showTextAsImage,
        [s.bbPostImageMissing]: !imageUrl,
        [s.bbPostImagePortrait]: isPortraitImage,
      })}
    >
      {showTextAsImage ? (
        <Card
          className={cx(s.bbPostImageText, {
            [s.bbPostImageTextClickable]: onImageClick,
          })}
          onClick={onImageClick}
        >
          {message}
        </Card>
      ) : (
        <div
          onClick={onImageClick}
          className={cx({
            [s.bbPostImageClickable]: onImageClick,
          })}
        >
          <Tooltip
            placement="top"
            title={onImageClick ? t("components:postTrends:exploreTooltip") : ""}
          >
            <Avatar shape="square" size={imageSize} src={imageUrl || me?.client?.logo} />
          </Tooltip>
        </div>
      )}
      {imageActionItem}
    </div>
  );
};

export default PostCardImage;
