export const GET_CUSTOM_TAGS = "CUSTOM_TAGS/GET_CUSTOM_TAGS";
export const GET_CUSTOM_TAGS_SUCCESS = "CUSTOM_TAGS/GET_CUSTOM_TAGS_SUCCESS";
export const GET_CUSTOM_TAGS_FAILURE = "CUSTOM_TAGS/GET_CUSTOM_TAGS_FAILURE";

export const POST_CUSTOM_TAG = "CUSTOM_TAGS/POST_CUSTOM_TAG";
export const POST_CUSTOM_TAG_SUCCESS = "CUSTOM_TAGS/POST_CUSTOM_TAG_SUCCESS";
export const POST_CUSTOM_TAG_FAILURE = "CUSTOM_TAGS/POST_CUSTOM_TAG_FAILURE";

export const DELETE_CUSTOM_TAG = "CUSTOM_TAGS/DELETE_CUSTOM_TAG";
export const DELETE_CUSTOM_TAG_SUCCESS = "CUSTOM_TAGS/DELETE_CUSTOM_TAG_SUCCESS";
export const DELETE_CUSTOM_TAG_FAILURE = "CUSTOM_TAGS/DELETE_CUSTOM_TAG_FAILURE";

export const DELETE_CUSTOM_TAG_FROM_COMMENT = "CUSTOM_TAGS/DELETE_CUSTOM_TAG_FROM_COMMENT";
export const DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS =
  "CUSTOM_TAGS/DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS";
export const DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE =
  "CUSTOM_TAGS/DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE";

export const DELETE_CUSTOM_TAG_FROM_REPLY = "CUSTOM_TAGS/DELETE_CUSTOM_TAG_FROM_REPLY";
