export const GET_PUBLISHINGS_LIST = "PUBLISHING/GET_PUBLISHINGS_LIST";
export const GET_PUBLISHINGS_LIST_SUCCESS = "PUBLISHING/GET_PUBLISHINGS_LIST_SUCCESS";
export const GET_PUBLISHINGS_LIST_FAILURE = "PUBLISHING/GET_PUBLISHINGS_LIST_FAILURE";

export const CREATE_POST_PUBLISHING = "PUBLISHING/CREATE_POST_PUBLISHING";
export const CREATE_POST_PUBLISHING_SUCCESS = "PUBLISHING/CREATE_POST_PUBLISHING_SUCCESS";
export const CREATE_POST_PUBLISHING_FAILURE = "PUBLISHING/CREATE_POST_PUBLISHING_FAILURE";

export const UPDATE_POST_PUBLISHING = "PUBLISHING/UPDATE_POST_PUBLISHING";
export const UPDATE_POST_PUBLISHING_SUCCESS = "PUBLISHING/UPDATE_POST_PUBLISHING_SUCCESS";
export const UPDATE_POST_PUBLISHING_FAILURE = "PUBLISHING/UPDATE_POST_PUBLISHING_FAILURE";

export const DELETE_POST_PUBLISHING = "PUBLISHING/DELETE_POST_PUBLISHING";
export const DELETE_POST_PUBLISHING_SUCCESS = "PUBLISHING/DELETE_POST_PUBLISHING_SUCCESS";
export const DELETE_POST_PUBLISHING_FAILURE = "PUBLISHING/DELETE_POST_PUBLISHING_FAILURE";

export const GET_PUBLISHINGS_URL_PREVIEWS = "PUBLISHING/GET_PUBLISHINGS_URL_PREVIEWS";
export const GET_PUBLISHINGS_URL_PREVIEWS_SUCCESS =
  "PUBLISHING/GET_PUBLISHINGS_URL_PREVIEWS_SUCCESS";
export const GET_PUBLISHINGS_URL_PREVIEWS_FAILURE =
  "PUBLISHING/GET_PUBLISHINGS_URL_PREVIEWS_FAILURE";
export const CLEAR_PUBLISHINGS_URL_PREVIEWS = "PUBLISHING/CLEAR_PUBLISHINGS_URL_PREVIEWS";

export const GET_PUBLISHINGS_TT_CREATOR_INFO = "PUBLISHING/GET_PUBLISHINGS_TT_CREATOR_INFO";
export const GET_PUBLISHINGS_TT_CREATOR_INFO_SUCCESS =
  "PUBLISHING/GET_PUBLISHINGS_TT_CREATOR_INFO_SUCCESS";
export const GET_PUBLISHINGS_TT_CREATOR_INFO_FAILURE =
  "PUBLISHING/GET_PUBLISHINGS_TT_CREATOR_INFO_FAILURE";
export const CLEAR_PUBLISHINGS_TT_CREATOR_INFO = "PUBLISHING/CLEAR_PUBLISHINGS_TT_CREATOR_INFO";

export const GET_INSTAGRAM_ASSET_INFO = "PUBLISHING/GET_INSTAGRAM_ASSET_INFO";
export const GET_INSTAGRAM_ASSET_INFO_SUCCESS = "PUBLISHING/GET_INSTAGRAM_ASSET_INFO_SUCCESS";
export const GET_INSTAGRAM_ASSET_INFO_FAILURE = "PUBLISHING/GET_INSTAGRAM_ASSET_INFO_FAILURE";

export const GET_INSTAGRAM_PRODUCTS = "PUBLISHING/GET_INSTAGRAM_PRODUCTS";
export const GET_INSTAGRAM_PRODUCTS_SUCCESS = "PUBLISHING/GET_INSTAGRAM_PRODUCTS_SUCCESS";
export const GET_INSTAGRAM_PRODUCTS_FAILURE = "PUBLISHING/GET_INSTAGRAM_PRODUCTS_FAILURE";

export const GET_YOUTUBE_CATEGORIES = "PUBLISHING/GET_YOUTUBE_CATEGORIES";
export const GET_YOUTUBE_CATEGORIES_SUCCESS = "PUBLISHING/GET_YOUTUBE_CATEGORIES_SUCCESS";
export const GET_YOUTUBE_CATEGORIES_FAILURE = "PUBLISHING/GET_YOUTUBE_CATEGORIES_FAILURE";

export const GET_YOUTUBE_PLAYLISTS = "PUBLISHING/GET_YOUTUBE_PLAYLISTS";
export const GET_YOUTUBE_PLAYLISTS_SUCCESS = "PUBLISHING/GET_YOUTUBE_PLAYLISTS_SUCCESS";
export const GET_YOUTUBE_PLAYLISTS_FAILURE = "PUBLISHING/GET_YOUTUBE_PLAYLISTS_FAILURE";
