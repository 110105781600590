import React from "react";
import { useTranslation } from "react-i18next";

import s from "./ValidationToast.module.less";

interface IValidationToastProps {
  operationsDone: string[];
}

export function ValidationToast({ operationsDone }: IValidationToastProps) {
  const { t } = useTranslation();

  return (
    <div className={s.bbKeywordsValidationToast}>
      {t("components:filters:keywords:validation_toast:body")}
      <ul className={s.bbKeywordsValidationToastOperations}>
        {operationsDone.map((operation, idx) => (
          <li key={idx} className={s.bbKeywordsValidationToastOperation}>
            {t(`components:filters:keywords:validation_toast:validator_messages:${operation}`)}
          </li>
        ))}
      </ul>
    </div>
  );
}
