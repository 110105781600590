import { ajax } from "rxjs/ajax";
import React, { FC, useState } from "react";
import { i18nextInstance } from "../../../App";

import { Col, Grid, Row } from "antd";
import ForgotPassword from "./ForgotPassword";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { ForgotPassword as AWSForgotPassword } from "aws-amplify-react";

import { password } from "@utils/paths";

import { SignInCover as CoverImg } from "../SignInCover/SignInCover";

import s from "./ForgotPasswordView.module.less";
import globalStyles from "../index.module.less";

import { IFormValues } from "./ForgotPassword.type";
import { IForgotPasswordViewProps } from "./ForgotPasswordView.type";
import { IAuthPieceProps } from "aws-amplify-react/lib-esm/Auth/AuthPiece";

const { useBreakpoint } = Grid;

const ForgotPasswordViewComponent: FC<IForgotPasswordViewProps> = ({
  authData,
  handleInputChange,
  changeState,
  sendValue,
  submitValues,
}) => {
  const screens = useBreakpoint();

  const [delivery, setDelivery] = useState(false);
  const [username, setUsername] = useState<string | null>(null);

  const onFinish = (values: IFormValues, type: string, callback?: () => void) => {
    if (type === "send" && values.email) {
      setUsername(values.email);
      sendValue(values, callback).then(() => setDelivery(true));
    } else {
      submitValues(values, username, callback).then(() => setDelivery(false));
    }
  };

  const handleSubmit = () => {
    return !delivery
      ? changeState("signIn")
      : sendValue({
          email: username,
          resend: true,
        });
  };

  return (
    <Row className={s.bbForgotPasswordView}>
      <Col xs={24} xl={16} className={s.bbForgotPasswordContent}>
        <ForgotPassword
          onFinish={onFinish}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          delivery={!delivery && authData === undefined ? false : true}
        />
      </Col>

      {screens.xl ? (
        <Col xs={12} xl={8} className={globalStyles.bbInfoSection}>
          <div>
            <CoverImg />
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

class ForgotPasswordView extends AWSForgotPassword {
  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }

  error = (err: { statusCode: string; message: string }) => {
    addToast({
      type: "danger_accent",
      title: i18nextInstance.t("generic:error"),
      message:
        err.statusCode === "UserNotFoundException"
          ? i18nextInstance.t("components:forgotPassword:notification:errors:userNotFoundException")
          : err.message,
    });
  };

  sendValue = (values: IFormValues, callback?: () => void) => {
    return new Promise(resolve => {
      return ajax
        .post(`${password}/forgot`, {
          email: values.email,
        })
        .subscribe(
          () => {
            addToast({
              type: "info_accent",
              title: i18nextInstance.t("components:confirmSignIn:notification:codeSent:message"),
              message: i18nextInstance.t(
                "components:confirmSignIn:notification:codeSent:description",
              ),
            });
            resolve(true);
          },
          err => {
            callback?.();
            this.error(err.response);
          },
        );
    });
  };

  submitValues = (values: IFormValues, username: string | null, callback?: () => void) => {
    return new Promise(resolve => {
      return ajax
        .post(`${password}/change`, {
          email: username,
          confirmation_code: values.newCode,
          new_password: values.newPassword,
        })
        .subscribe(
          () => resolve(this.changeState("signIn")),
          err => {
            callback?.();
            this.error(err.response);
          },
        );
    });
  };

  showComponent() {
    return (
      <ForgotPasswordViewComponent
        sendValue={this.sendValue}
        authData={this.props.authData}
        changeState={this.changeState}
        submitValues={this.submitValues}
        handleInputChange={this.handleInputChange}
      />
    );
  }
}

export default ForgotPasswordView;
