import { ActionType, createReducer } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { IStreamsState } from "./types";

const initialState = {
  moderation: {
    streams: {
      data: [],
      fetching: false,
      fetched: false,
      fetchFail: false,
    },
    comments: {
      data: [],
      fetching: false,
      fetched: false,
      fetchFail: false,
    },
  },
  care_cps: {
    streams: {
      data: [],
      fetching: false,
      fetched: false,
      fetchFail: false,
    },
    counts: {
      data: [],
      fetching: false,
      fetched: false,
      fetchFail: false,
    },
  },
};

export const streamsReducer = createReducer<IStreamsState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.getModerationStreamsAction, state =>
    produce(state, draft => {
      draft.moderation.streams.fetching = true;
      draft.moderation.streams.fetched = false;
      draft.moderation.streams.fetchFail = false;
    }),
  )
  .handleAction(actions.getModerationStreamsActionSuccess, (state, action) =>
    produce(state, draft => {
      draft.moderation.streams.data = action.payload;
      draft.moderation.streams.fetching = false;
      draft.moderation.streams.fetched = true;
      draft.moderation.streams.fetchFail = false;
    }),
  )
  .handleAction(actions.getModerationStreamsActionFailure, state =>
    produce(state, draft => {
      draft.moderation.streams.fetching = false;
      draft.moderation.streams.fetched = false;
      draft.moderation.streams.fetchFail = true;
    }),
  )
  .handleAction(actions.getModerationStreamsCommentsAction, state =>
    produce(state, draft => {
      draft.moderation.comments.fetching = true;
      draft.moderation.comments.fetched = false;
      draft.moderation.comments.fetchFail = false;
    }),
  )
  .handleAction(actions.getModerationStreamsCommentsActionSuccess, (state, action) =>
    produce(state, draft => {
      draft.moderation.comments.data = action.payload;
      draft.moderation.comments.fetching = false;
      draft.moderation.comments.fetched = true;
      draft.moderation.comments.fetchFail = false;
    }),
  )
  .handleAction(actions.getModerationStreamsCommentsActionFailure, state =>
    produce(state, draft => {
      draft.moderation.comments.fetching = false;
      draft.moderation.comments.fetched = false;
      draft.moderation.comments.fetchFail = true;
    }),
  )

  .handleAction(actions.getCareCpsStreamsAction, state =>
    produce(state, draft => {
      draft.care_cps.streams.fetching = true;
      draft.care_cps.streams.fetched = false;
      draft.care_cps.streams.fetchFail = false;
    }),
  )
  .handleAction(actions.getCareCpsStreamsActionSuccess, (state, action) =>
    produce(state, draft => {
      draft.care_cps.streams.data = action.payload;
      draft.care_cps.streams.fetching = false;
      draft.care_cps.streams.fetched = true;
      draft.care_cps.streams.fetchFail = false;
    }),
  )
  .handleAction(actions.getCareCpsStreamsActionFailure, state =>
    produce(state, draft => {
      draft.care_cps.streams.fetching = false;
      draft.care_cps.streams.fetched = false;
      draft.care_cps.streams.fetchFail = true;
    }),
  )
  .handleAction(actions.resetCareCpsStreamsAction, state =>
    produce(state, draft => {
      draft.care_cps.streams.fetching = false;
      draft.care_cps.streams.fetched = false;
      draft.care_cps.streams.fetchFail = false;
    }),
  )
  .handleAction(actions.setCareCpsStreamAction, (state, action) =>
    produce(state, draft => {
      draft.care_cps.current = action.payload?.id;
    }),
  )
  .handleAction(actions.getCareCpsStreamsCountsAction, state =>
    produce(state, draft => {
      draft.care_cps.counts.fetching = true;
      draft.care_cps.counts.fetched = false;
      draft.care_cps.counts.fetchFail = false;
    }),
  )
  .handleAction(actions.getCareCpsStreamsCountsActionSuccess, (state, action) =>
    produce(state, draft => {
      draft.care_cps.streams.data?.forEach(stream => {
        const count = action.payload.find(c => c.id === stream.id);
        if (count) stream.counts = count.counts;
      });
      draft.care_cps.counts.fetching = false;
      draft.care_cps.counts.fetched = true;
      draft.care_cps.counts.fetchFail = false;
    }),
  )
  .handleAction(actions.getCareCpsStreamsCountsActionFailure, state =>
    produce(state, draft => {
      draft.care_cps.counts.fetching = false;
      draft.care_cps.counts.fetched = false;
      draft.care_cps.counts.fetchFail = true;
    }),
  );
