import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactResizeDetector from "react-resize-detector";
import React, { FC, useCallback, useEffect, useState, MouseEvent } from "react";

import {
  Card,
  Modal,
  Button,
  EyeIcon,
  CloseIcon,
  IAxisTick,
  TabsScrollable,
  TabPane,
} from "@bbdevcrew/bb_ui_kit_fe";
import Legend from "./Legend";
import NoData from "./NoData";
import { Row, Col, Grid } from "antd";
import { useForm } from "antd/lib/form/Form";
import CompareHeader from "./CompareHeader";
import CompareEmptyState from "./CompareEmptyState";
import CompareChartLabel from "./CompareChartLabel";
import CompareProjectForm from "./CompareProjectForm";
import DownloadCSV from "../../_common/DownloadCSV";
import YTNotAvailableChip from "../_common/ytNotAvailableChip";
import BreakdownTooltip from "../_common/commentBreakdown/breakdownChartTooltip";
import { BreakdownChart } from "../../_common/BreakdownChart/BreakdownChart";

import {
  compareProjects,
  postedCompareProject,
  editedCompareProject,
  deletedCompareProject,
  getEditedCompareProjectId,
  compareProjectsAggregations,
  getCompareProjectsSuccessful,
} from "@store/compareProjects/selectors";
import {
  getCompareProjects,
  postCompareProject,
  editCompareProject,
  clearProjectAggregations,
  getCompareProjectAggregations,
} from "@store/compareProjects/actions";
import { meSelector, meSuccessfulSelector } from "@store/me/selectors";

import s from "./Compare.module.less";

import {
  LABEL_LARGE,
  LABEL_SMALL,
  CHART_HEIGHT,
  DEFAULT_PROJECT,
  getCompareCSVData,
  getCompareCSVHeaders,
} from "./helpers";
import {
  AllowedCompareType,
  ICompareProject,
  ICompareProjectAggregation,
} from "@store/compareProjects/types";
import { IFilters } from "@store/filters/types";
import { IFormFilterRequest } from "../../_common/AppFilter/AppFilters.type";

import { CommentIcon, DownloadIcon } from "@assets/index";

const Compare: FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [form] = useForm<ICompareProject>();

  const chartLabelWidth = screens.lg ? LABEL_LARGE : LABEL_SMALL;

  const [isYTAvailable, setIsYTAvailable] = useState(false);
  const [isModalEditMode, setIsModalEditMode] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("sentiment");
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>();
  const [filterData, setFilterData] = useState<IFilters>({} as IFilters);

  const fetchCompareProjects = useCallback(() => dispatch(getCompareProjects()), [dispatch]);
  const fetchCompareProjectAggregations = useCallback(
    (id, filters) => dispatch(getCompareProjectAggregations(id, filters)),
    [dispatch],
  );
  const createCompareProject = useCallback(
    (project: ICompareProject) => dispatch(postCompareProject(project)),
    [dispatch],
  );
  const updateCompareProject = useCallback(
    (project: ICompareProject) => dispatch(editCompareProject(project)),
    [dispatch],
  );

  const me = useSelector(meSelector);
  const meFetched = useSelector(meSuccessfulSelector);
  const projects = useSelector(compareProjects);
  const aggregations = useSelector(compareProjectsAggregations);
  const projectCreateSuccess = useSelector(postedCompareProject);
  const projectRemoveSuccess = useSelector(deletedCompareProject);
  const projectUpdateSuccess = useSelector(editedCompareProject);
  const projectsFetched = useSelector(getCompareProjectsSuccessful);
  const editedCompareProjectId = useSelector(getEditedCompareProjectId);

  const onProjectChange = (selectedOptionId?: string) => {
    if (selectedOptionId) fetchCompareProjectAggregations(selectedOptionId, filterData);
    else clearProjectAggregations();

    setSelectedProjectId(selectedOptionId);
  };

  const selectNewestProject = () => {
    if (projects.length) onProjectChange(projects[0].id);
  };

  useEffect(() => {
    fetchCompareProjects();
  }, [fetchCompareProjects]);

  useEffect(() => {
    if (me && meFetched)
      setIsYTAvailable(
        !!me.client_data?.assets.some(asset => asset.platform_type.id === "youtube"),
      );
  }, [me, meFetched]);

  useEffect(() => {
    if (projectsFetched && !selectedProjectId) selectNewestProject();
    // eslint-disable-next-line
  }, [projectsFetched]);

  useEffect(() => {
    if (projectUpdateSuccess) {
      form.resetFields();
      setIsModalEditMode(false);
      setIsProjectModalVisible(false);
      setSelectedProjectId(editedCompareProjectId);
      fetchCompareProjectAggregations(editedCompareProjectId, filterData);
    }
    // eslint-disable-next-line
  }, [projectUpdateSuccess, form]);

  useEffect(() => {
    // If a project was selected and then deleted, deselect
    if (projectRemoveSuccess && !projects.some(project => project.id === selectedProjectId))
      setSelectedProjectId(undefined);
    // eslint-disable-next-line
  }, [projectRemoveSuccess]);

  useEffect(() => {
    if (projectCreateSuccess) {
      form.resetFields();
      selectNewestProject();
      setIsProjectModalVisible(false);
    }
    // eslint-disable-next-line
  }, [projectCreateSuccess, form, fetchCompareProjects]);

  const onFilter = (changedValues: IFormFilterRequest) => {
    if (!changedValues.data_range_option) setFilterData({} as IFilters);
    else setFilterData(changedValues);

    if (selectedProjectId) fetchCompareProjectAggregations(selectedProjectId, changedValues);
  };

  const onProjectModalConfirm = () => {
    form.validateFields().then(validatedProject => {
      const project = form.getFieldsValue(true);

      if (project.id) updateCompareProject({ ...validatedProject, id: project.id });
      else createCompareProject(validatedProject);
    });
  };

  const onEmptyStateCardClick = (compareType: AllowedCompareType) => {
    form.resetFields();
    form.setFieldsValue({ ...DEFAULT_PROJECT, compare_type: compareType });
    setIsProjectModalVisible(true);
  };

  const onEditProjectClick = (event: MouseEvent, project: ICompareProject) => {
    event.stopPropagation();

    form.resetFields();
    form.setFieldsValue({ ...project });

    setIsModalEditMode(true);
    setIsProjectModalVisible(true);
  };

  const getSelectedAggregation = () => {
    return aggregations.find(unit => unit.id === activeTabKey);
  };

  const getLabelData = (tickProps: IAxisTick, aggregation?: ICompareProjectAggregation) => {
    const itemId = tickProps.payload.value;
    return aggregation?.items?.find(unit => unit.id === itemId);
  };

  return (
    <div data-cy="compare-panel" className={s.bbCompareContainer}>
      {projectsFetched && !projects.length ? (
        <CompareEmptyState onCardClick={onEmptyStateCardClick} />
      ) : (
        <>
          <CompareHeader
            onFilter={onFilter}
            filterData={filterData}
            compareProjectOptions={projects}
            onProjectChange={onProjectChange}
            selectedProjectId={selectedProjectId}
            onEditProjectClick={onEditProjectClick}
            onNewProjectCreate={() => onEmptyStateCardClick("asset")}
          />

          {selectedProjectId ? (
            <Card
              className={s.bbCompareContent}
              data-sonly-target="intelligence-compare__breakdown"
            >
              <Row>
                <Col span={22}>
                  <TabsScrollable
                    onChange={key => setActiveTabKey(key)}
                    activeKey={activeTabKey || aggregations[0]?.id}
                    defaultActiveKey={aggregations.length ? aggregations[0]?.id : "sentiment"}
                  >
                    {aggregations.map(aggregation => (
                      <TabPane
                        tab={
                          <div
                            data-stonly-target={
                              "intelligence-compare__breakdown--tab-" + aggregation.id
                            }
                          >
                            {aggregation.label}
                          </div>
                        }
                        tabKey={aggregation.id}
                        key={aggregation.id}
                      >
                        {aggregations.length ? (
                          <Row
                            className={s.bbTabContentWrapper}
                            data-stonly-target={"intelligence-compare__comparison-chart"}
                          >
                            <Col xs={24} sm={24} md={24} lg={24} xl={20}>
                              <ReactResizeDetector
                                handleWidth
                                refreshRate={2000}
                                refreshMode={"throttle"}
                                refreshOptions={{
                                  leading: true,
                                  trailing: true,
                                }}
                              >
                                {(resizeProps: { width: number }) => {
                                  return (
                                    <React.Fragment key={aggregation.id}>
                                      <BreakdownChart
                                        data={aggregation}
                                        height={CHART_HEIGHT}
                                        labelWidth={chartLabelWidth}
                                        onYAxisLabelClick={() => {}} // eslint-disable-line
                                        width={resizeProps.width || 0}
                                        customTooltip={<BreakdownTooltip />}
                                        generateLabel={props => (
                                          <CompareChartLabel
                                            tickProps={props}
                                            width={chartLabelWidth}
                                            data={getLabelData(props, aggregation)}
                                          />
                                        )}
                                      />
                                      <div className={s.bbCommentsLegend}>
                                        <div className={s.bbComments}>
                                          <CommentIcon />
                                        </div>
                                        <div>{t("components:breakdown:count")}</div>
                                      </div>
                                    </React.Fragment>
                                  );
                                }}
                              </ReactResizeDetector>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                              <Legend aggregation={aggregation} />
                            </Col>
                          </Row>
                        ) : (
                          <NoData
                            icon={<CloseIcon />}
                            text={t("components:comparesPanel:noData")}
                          />
                        )}
                      </TabPane>
                    ))}
                  </TabsScrollable>
                </Col>
                <Col span={2} className={s.bbDownloadButtonContainer}>
                  {isYTAvailable && (
                    <span className={s.bbYTNotAvailable}>
                      <YTNotAvailableChip />
                    </span>
                  )}
                  {!!aggregations.length && (
                    <Button className={s.bbDownloadButton}>
                      <DownloadCSV
                        icon={<DownloadIcon />}
                        fileNamePrefix="compare"
                        data={getCompareCSVData(getSelectedAggregation())}
                        tableHeaders={getCompareCSVHeaders(getSelectedAggregation())}
                      />
                    </Button>
                  )}
                </Col>
              </Row>
            </Card>
          ) : (
            <NoData icon={<EyeIcon />} text={t("components:comparesPanel:noProjectsSelected")} />
          )}
        </>
      )}

      <Modal
        centered
        responsive
        width={660}
        onOk={onProjectModalConfirm}
        open={isProjectModalVisible}
        onCancel={() => setIsProjectModalVisible(false)}
        title={t(
          `components:comparesPanel:projectModal:${isModalEditMode ? "editTitle" : "createTitle"}`,
        )}
        confirmLabel={t(
          `components:comparesPanel:projectModal:${isModalEditMode ? "editLabel" : "createLabel"}`,
        )}
      >
        <CompareProjectForm form={form} />
      </Modal>
    </div>
  );
};

export default Compare;
