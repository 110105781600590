import { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setDraftReplyAction } from "@store/replies/actions";
import { getDraftReplySelector } from "@store/replies/selectors";

export type UseDraftReplyReturnType = [string, (message: string) => void];

export const useDraftReply = (commentId: string): UseDraftReplyReturnType => {
  const dispatch = useDispatch();

  const draftReply = useSelector(getDraftReplySelector(commentId));

  const [localDraftReply, setLocalDraftReply] = useState(draftReply);
  const latestDraftRef = useRef(localDraftReply);

  useEffect(() => {
    latestDraftRef.current = localDraftReply;
  }, [localDraftReply]);

  useEffect(() => {
    return () => {
      dispatch(setDraftReplyAction(commentId, latestDraftRef.current));
    };
    // eslint-disable-next-line
  }, []);

  return [localDraftReply, setLocalDraftReply];
};
