import { useSelector } from "react-redux";
import classNames from "classnames/bind";
import React, { FC, MouseEvent, useEffect, useState } from "react";

import { Chip, ChipAction, Tooltip, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { deleteTagFromPostSuccessfulSelector } from "@store/postTags/selectors";

import s from "./PostTagsList.module.less";

import { LoadingOutlined } from "@ant-design/icons";
import { IPostTagsListProps } from "./PostTags.type";
import { cropText } from "@utils/cropText";
import { IPostTag } from "@store/postTags/types";

const POST_TAG_MAX_LENGTH = 30;

const PostTagsList: FC<IPostTagsListProps> = ({
  postTags = [],
  maxTagListLength,
  onDeleteTag,
  tagClassName = "",
  tagIconClassName = "",
}) => {
  const listedTags = maxTagListLength ? postTags.slice(0, maxTagListLength) : postTags;
  const restOfTheTags = postTags.slice(maxTagListLength);

  const [deletingTagId, setDeletingTagId] = useState<string | null>(null);

  const tagFromPostDeleted = useSelector(deleteTagFromPostSuccessfulSelector);

  const getRestTooltipTitle = () => {
    return (
      <div className={s.bbRestOfTheTags}>
        {restOfTheTags.map(tag => (
          <div key={tag.id || tag.label}>{tag.label}</div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (tagFromPostDeleted) setDeletingTagId(null);
  }, [tagFromPostDeleted]);

  useEffect(() => {
    setDeletingTagId(null);
  }, [postTags.length]);

  const deleteTag = (event: MouseEvent<HTMLButtonElement>, tagId?: string) => {
    event.preventDefault();

    if (tagId) {
      setDeletingTagId(tagId);

      onDeleteTag?.(tagId);
    }
  };

  const renderActionIcon = (tag: IPostTag) => {
    return (
      <>
        {!!onDeleteTag ? (
          <>
            {tag.id === deletingTagId || !tag.id ? (
              <LoadingOutlined spin className={s.bbLoadingIcon} />
            ) : (
              <CloseIcon />
            )}
          </>
        ) : null}
      </>
    );
  };

  return (
    <span className={s.bbPostTagsList}>
      {listedTags.map(tag => {
        const { id, label } = tag;
        const tagExceedsMaxLength = label.length > POST_TAG_MAX_LENGTH;

        return (
          <Tooltip key={id || label} title={tagExceedsMaxLength ? label : ""}>
            <span
              key={id || label}
              className={classNames(s.bbPostTagWrapper, {
                [s.bbPostTagWrapperWithTooltip]: tagExceedsMaxLength,
              })}
            >
              <ChipAction
                _size="xxs"
                _type="white"
                text={cropText(label, POST_TAG_MAX_LENGTH)}
                actionIcon={renderActionIcon(tag)}
                iconClassName={tagIconClassName}
                className={classNames(tagClassName, s.bbPostTag)}
                onActionClick={
                  !!onDeleteTag && id && id !== deletingTagId ? e => deleteTag(e, id) : undefined
                }
              />
            </span>
          </Tooltip>
        );
      })}
      {maxTagListLength && !!restOfTheTags.length && (
        <Tooltip title={getRestTooltipTitle()}>
          <span className={s.bbPostTagWrapper}>
            <Chip
              _size="xxs"
              _type="white"
              text={"+" + restOfTheTags.length}
              className={s.bbRestOfTagsChip}
            />
          </span>
        </Tooltip>
      )}
    </span>
  );
};

export default PostTagsList;
