import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./PasswordChecker.module.less";

import { Tooltip, CheckIcon, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { MIN_PSW_LENGTH, validatePsw } from "./helpers";

const cx = cn.bind(s);

interface IPasswordCheckerProps {
  value: string;
  isValidated?: boolean;
}

export const PasswordChecker: FC<IPasswordCheckerProps> = ({ value, isValidated }) => {
  const { t } = useTranslation();
  const { hasNumber, hasSpecialChar, hasUppercase, hasMinLength, hasLowercase } =
    validatePsw(value);

  return (
    <div className={s.bbPasswordChecker}>
      <div
        className={cx(s.bbPasswordCheckerItem, {
          [s.bbPasswordCheckerItemMissing]: !hasMinLength,
          [s.bbPasswordCheckerItemInvalid]: !hasMinLength && isValidated,
        })}
      >
        {hasMinLength && <CheckIcon />}
        {t("components:passwordChecker:minLength", { count: MIN_PSW_LENGTH })}
      </div>
      <div
        className={cx(s.bbPasswordCheckerItem, {
          [s.bbPasswordCheckerItemMissing]: !hasUppercase,
          [s.bbPasswordCheckerItemInvalid]: !hasUppercase && isValidated,
        })}
      >
        {hasUppercase && <CheckIcon />}
        {t("components:passwordChecker:uppercaseChar")}
      </div>
      <div
        className={cx(s.bbPasswordCheckerItem, {
          [s.bbPasswordCheckerItemMissing]: !hasLowercase,
          [s.bbPasswordCheckerItemInvalid]: !hasLowercase && isValidated,
        })}
      >
        {hasLowercase && <CheckIcon />}
        {t("components:passwordChecker:lowercaseChar")}
      </div>
      <div
        className={cx(s.bbPasswordCheckerItem, {
          [s.bbPasswordCheckerItemMissing]: !hasNumber,
          [s.bbPasswordCheckerItemInvalid]: !hasNumber && isValidated,
        })}
      >
        {hasNumber && <CheckIcon />}
        {t("components:passwordChecker:number")}
      </div>
      <div
        className={cx(s.bbPasswordCheckerItem, {
          [s.bbPasswordCheckerItemMissing]: !hasSpecialChar,
          [s.bbPasswordCheckerItemInvalid]: !hasSpecialChar && isValidated,
        })}
      >
        {hasSpecialChar && <CheckIcon />}
        {t("components:passwordChecker:specialChar")}
        <Tooltip
          className={s.bbPasswordCheckerItemTooltip}
          title={`${t("generic:examples")}: ?!-.:@#$*&+()"`}
        >
          <HelpIcon />
        </Tooltip>
      </div>
    </div>
  );
};
