import { CheckboxListType } from "./CheckboxList.types";

export const checkboxListRequired = (message: string) => [
  { required: true },
  {
    validator: (_: unknown, value: CheckboxListType) => {
      const checkedOptions = Object.values(value).filter(option => option.checked);
      if (checkedOptions.length === 0) {
        return Promise.reject(message);
      }
      return Promise.resolve();
    },
  },
];
