import React from "react";

import { ILinkedInReactionMap } from "./types";

import {
  LinkedInReactionCelebrateIcon,
  LinkedInReactionFunnyIcon,
  LinkedInReactionInsightfulIcon,
  LinkedInReactionLikeIcon,
  LinkedInReactionLoveIcon,
  LinkedInReactionSupportIcon,
} from "@bbdevcrew/bb_ui_kit_fe";

export const HOVER_DELAY = 200;
export const DEFAULT_REACTION = "like";

const reactionItem = (icon: string, tooltip: string) => ({
  icon: () => <img src={icon} />,
  tooltip,
});

export const LinkedInReactionMap: ILinkedInReactionMap = {
  like: reactionItem(LinkedInReactionLikeIcon, "Like"),
  praise: reactionItem(LinkedInReactionCelebrateIcon, "Celebrate"),
  empathy: reactionItem(LinkedInReactionLoveIcon, "Love"),
  interest: reactionItem(LinkedInReactionInsightfulIcon, "Insightful"),
  appreciation: reactionItem(LinkedInReactionSupportIcon, "Support"),
  entertainment: reactionItem(LinkedInReactionFunnyIcon, "Funny"),
};
