import React from "react";

import s from "./PublishingPost.module.less";

import { IPublishingPost } from "@store/publishings/types";

import { AlertTriangleIcon } from "@assets/index";

interface IErrorPostingProps {
  post: IPublishingPost;
}

const ErrorPosting = ({ post }: IErrorPostingProps) => {
  return (
    <>
      {!!post.publishing_error && post.publishing_status === "failed" && (
        <div className={s.bbPostError}>
          <AlertTriangleIcon />
        </div>
      )}
    </>
  );
};

export default ErrorPosting;
