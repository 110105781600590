import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";

import DayOfWeek from "./DayOfWeek";

import {
  getPublishingsListSelector,
  getCalendarHolidaysSelector,
} from "@store/publishings/selectors";

import s from "./Calendar.module.less";

interface IWeekProps {
  week: Dayjs[];
}

export default function Week({ week }: IWeekProps) {
  const publishingsList = useSelector(getPublishingsListSelector);
  const holidays = useSelector(getCalendarHolidaysSelector);

  const getPostsForSpecificDay = (day: Dayjs) => {
    return publishingsList
      .filter(post => {
        const postDay = new Date(post.created_time_pretty).getUTCDate();

        return postDay === day.get("D");
      })
      .sort((a, b) => {
        const h1 = dayjs(a.created_time).get("h");
        const m1 = dayjs(a.created_time).get("m");
        const h2 = dayjs(b.created_time).get("h");
        const m2 = dayjs(b.created_time).get("m");

        return h1 - h2 || m1 - m2;
      });
  };

  const getHolidaysForSpecificDay = (day: Dayjs) => {
    return holidays.filter(holiday => {
      return new Date(holiday.date).getUTCDate() === day.get("D");
    });
  };

  return (
    <div className={s.bbWeek}>
      {week.map((day, index) => (
        <DayOfWeek
          day={day}
          key={index}
          index={index}
          posts={getPostsForSpecificDay(day)}
          holidays={getHolidaysForSpecificDay(day)}
        />
      ))}
    </div>
  );
}
