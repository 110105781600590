import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";
import { StateObservable } from "redux-observable";

import {
  tiktokConnectSuccessAction,
  tiktokConnectFailureAction,
  tiktokSignupSuccessAction,
  tiktokSignupFailureAction,
  tiktokPricingTableFailureAction,
  tiktokPricingTableSuccessAction,
} from "./actions";
import { TIKTOK_CONNECT, TIKTOK_PRICING_TABLE, TIKTOK_SIGNUP } from "./actionTypes";
import {
  TiktokAppActionsType,
  ITiktokConnectUserResponse,
  ITiktokPricingTableResponse,
} from "./types";
import { RootState } from "..";

import { tiktok, settings } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

function generateTTConnectUrl(jwtToken: string, authCode?: string) {
  const baseUrl = `${tiktok}/connect?jwt_token=${jwtToken}`;

  return baseUrl + (authCode ? `&auth_code=${authCode}` : "");
}

export const connectTiktokEpic = (action$: Observable<TiktokAppActionsType>) =>
  action$.pipe(
    filter(isOfType(TIKTOK_CONNECT)),
    switchMap(a => {
      return ajax
        .get<ITiktokConnectUserResponse>(
          generateTTConnectUrl(a.payload.jwt_token, a.payload.auth_code),
          getHeaders({}),
        )
        .pipe(
          map(e => e.response),
          map(tiktokConnectSuccessAction),
          catchError(e => handleError(e, tiktokConnectFailureAction)),
        );
    }),
  );

export const signupTiktokEpic = (action$: Observable<TiktokAppActionsType>) =>
  action$.pipe(
    filter(isOfType(TIKTOK_SIGNUP)),
    switchMap(a => {
      return ajax
        .post<ITiktokConnectUserResponse>(`${tiktok}/signup`, a.payload, getHeaders({}))
        .pipe(
          map(e => e.response),
          map(tiktokSignupSuccessAction),
          catchError(e => handleError(e, tiktokSignupFailureAction)),
        );
    }),
  );

export const getPricingTable = (
  action$: Observable<TiktokAppActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(TIKTOK_PRICING_TABLE)),
    switchMap(() => {
      return ajax
        .get<ITiktokPricingTableResponse>(
          `${settings}/pricing-table`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response.items),
          map(tiktokPricingTableSuccessAction),
          catchError(e => handleError(e, tiktokPricingTableFailureAction)),
        );
    }),
  );
