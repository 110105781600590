import React, { FC } from "react";
import cn from "classnames/bind";

import s from "./TrustpilotMessage.module.less";

import { IReviewStarsProps } from "./types";
import { getStarNumber, MAX_TRUSTPILOT_STARS } from "./helpers";
import { RatingStarsType } from "../../Social/Post/Post.type";

import TrustpilotStar from "@assets/TrustpilotStar.svg";

const cx = cn.bind(s);

export const TrustpilotStars: FC<IReviewStarsProps> = ({ stars }) => {
  const numberOfStars = getStarNumber(stars as RatingStarsType);
  return (
    <div className={s.bbStars}>
      {[...Array(MAX_TRUSTPILOT_STARS)].map((_, index) => {
        return (
          <div
            className={cx(
              s.bbStar,
              s[`bbStar-${numberOfStars && index < numberOfStars ? numberOfStars : ""}`],
            )}
            key={index}
          >
            <TrustpilotStar key={index} />
          </div>
        );
      })}
    </div>
  );
};
