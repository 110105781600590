export const allColorData = [
  "#3567A5",
  "#6E91BE",
  "#A7BDD8",
  "#87A4C9",
  "#537EB3",
  "#205497",
  "#7C9CC4",
];

export const positiveColorData = [
  "#52BA82",
  "#47966b",
  "#aaf2cb",
  "#85deae",
  "#469169",
  "#64cc94",
  "#96ebbd",
];

export const negativeColorData = [
  "#D94949",
  "#ad2b2b",
  "#ed7b7b",
  "#d45757",
  "#963333",
  "#c44343",
  "#e36d6d",
];
