import React from "react";
import classNames from "classnames";

import { LinkedInReactionMap } from "./config";
import { LinkedInReactionType, ILinkedInReactionBarProps } from "./types";

import s from "./LinkedInReactionButton.module.less";

export const LinkedInReactionBar: React.FC<ILinkedInReactionBarProps> = ({
  onSelect,
  className,
}) => {
  return (
    <nav className={classNames(s.bbLinkedInReactionBar, className)}>
      {(Object.keys(LinkedInReactionMap) as LinkedInReactionType[]).map(reaction => {
        const { icon: Icon, tooltip } = LinkedInReactionMap[reaction];
        return (
          <button
            key={reaction}
            onClick={() => onSelect?.(reaction)}
            className={s.bbLinkedInReactionBarButton}
          >
            <span className={s.bbLinkedInReactionBarTooltip}>{tooltip}</span>
            <Icon />
          </button>
        );
      })}
    </nav>
  );
};
