import React, { useEffect, useRef } from "react";
import JSONEditorClass from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import "jsoneditor/dist/img/jsoneditor-icons.svg";
import { IJSONEditorProps } from "./JSONEditor.types";

import s from "./JSONEditor.module.less";

export const JSONEditor: React.FC<IJSONEditorProps> = ({ value, onChange, options }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const jsonEditorRef = useRef<JSONEditorClass | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      jsonEditorRef.current = new JSONEditorClass(
        containerRef.current,
        {
          mode: "tree",
          onChangeText: onChange,
          ...options,
        },
        value,
      );
    }

    return () => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={s.bbJSONEditor}>
      <div ref={containerRef} />
    </div>
  );
};
