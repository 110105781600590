import cn from "classnames/bind";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, FormInstance } from "antd";
import { CodeInput } from "../codeInput/CodeInput";
import { Button, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";
import AgencyLogoBadge from "../../_common/AgencyLogoBadge/AgencyLogoBadge";

import s from "./ConfirmSignUp.module.less";
import globalStyles from "../index.module.less";

import { IFormValues, IConfirmSignUpProps } from "./ConfirmSignUp.type";

import Logo from "@assets/LogoNoBeta.svg";
import { RedoIcon, HeadphoneIcon } from "@assets/index";

const cx = cn.bind(s);

const ConfirmSignUp: React.FC<IConfirmSignUpProps> = ({ handleSubmit, onFinish }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isCodeEntered, setIsCodeEntered] = useState(false);

  const isAgencyView = JSON.parse(localStorage.getItem("isAgencyView") || "false");

  const onCodeChange = (form: FormInstance, value: string) => {
    if (value?.toString().length === 6) setIsCodeEntered(true);
    else setIsCodeEntered(false);

    form.setFieldsValue({
      newCode: value,
    });
  };

  const onSubmit = (values: IFormValues) => {
    setLoading(true);
    return onFinish && onFinish(values, () => setLoading(false));
  };

  return (
    <div className={s.bbConfirmSignUpContainer}>
      <div className={s.bbLogoContainer}>
        <Logo />
        {isAgencyView && <AgencyLogoBadge className={s.bbAgencyLogoBadge} />}
      </div>

      <div className={s.bbConfirmSignUpWindow}>
        <div className={s.bbConfirmSignUpHeader}>{t("components:confirmSignUp:title")}</div>

        <div className={s.bbConfirmSignUpTitle}>{t("components:confirmSignUp:subtitle")}</div>

        <Form
          name="signup"
          layout="vertical"
          onFinish={onSubmit}
          id="confirmSignUpForm"
          className={cx(s.bbConfirmSignUpForm, globalStyles.bbFormXL)}
        >
          <div className={s.bbCodeSubmitContainer}>
            <Form.Item shouldUpdate>
              {(form: FormInstance<IFormValues>) => (
                <Form.Item name="code">
                  <CodeInput onChange={value => onCodeChange(form, value)} />
                </Form.Item>
              )}
            </Form.Item>

            <Button
              _size="lg"
              _type="primary"
              type="submit"
              loading={loading}
              form="confirmSignUpForm"
              disabled={!isCodeEntered}
              className={s.bbConfirmSignUpSubmitBtn}
            >
              {t("components:confirmSignUp:enterPlatform")}
              <ChevronRightIcon />
            </Button>
          </div>

          <div className={s.bbConfirmSignUpHelpWrapper}>
            <Button
              _size="sm"
              _type="link"
              className={s.bbConfirmSignUpHelpBtn}
              onClick={e => handleSubmit && handleSubmit(e, "send")}
            >
              <RedoIcon />
              {t("generic:resendCode")}
            </Button>
            <Button _size="sm" _type="link" className={s.bbConfirmSignUpHelpBtn}>
              <a href="mailto:help@brandbastion.com" target="_blank" rel="noreferrer">
                <HeadphoneIcon />
                {t("generic:contactSupport")}
              </a>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ConfirmSignUp;
