export const TIKTOK_CONNECT = "TIKTOK/TIKTOK_CONNECT";
export const TIKTOK_CONNECT_SUCCESS = "TIKTOK/TIKTOK_CONNECT_SUCCESS";
export const TIKTOK_CONNECT_FAILURE = "TIKTOK/TIKTOK_CONNECT_FAILURE";

export const TIKTOK_SIGNUP = "TIKTOK/TIKTOK_SIGNUP";
export const TIKTOK_SIGNUP_SUCCESS = "TIKTOK/TIKTOK_SIGNUP_SUCCESS";
export const TIKTOK_SIGNUP_FAILURE = "TIKTOK/TIKTOK_SIGNUP_FAILURE";

export const TIKTOK_PRICING_TABLE = "TIKTOK/PRICING_TABLE";
export const TIKTOK_PRICING_TABLE_SUCCESS = "TIKTOK/TIKTOK_PRICING_TABLE_SUCCESS";
export const TIKTOK_PRICING_TABLE_FAILURE = "TIKTOK/TIKTOK_PRICING_TABLE_FAILURE";
