import React from "react";

import { SelectTrigger } from "./SelectTrigger";
import { Icon } from "@bbdevcrew/bb_ui_kit_fe";

import { IClearableTriggerProps } from "./ClearableTrigger.types";

import s from "./ClearableTrigger.module.less";

import { ChevronDownIcon, ChevronUpIcon, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

function ClearableTrigger({ isOpen, _size = "sm", onClear, ...props }: IClearableTriggerProps) {
  const _onClear = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.stopPropagation();
    onClear && onClear();
  };

  const suffixEl = (
    <>
      <Icon
        _size={_size}
        icon={CloseIcon}
        onClick={_onClear}
        className={s.bbIconClearableTrigger}
      />
      <Icon
        _size={_size}
        className={s.bbIconChevron}
        icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
      />
    </>
  );

  return (
    <SelectTrigger
      wrapperClassName={s.bbClearableTriggerGroup}
      _size={_size}
      suffixEl={suffixEl}
      {...props}
    />
  );
}

export default ClearableTrigger;
