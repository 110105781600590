import React, { useState } from "react";

import { LinkedInReactionBar } from "./LinkedInReactionBar";

import { ILinkedInReactionButtonProps } from "./types";
import { DEFAULT_REACTION, HOVER_DELAY, LinkedInReactionMap } from "./config";
import CommentAction from "../CommentCard/commentActions/commentAction/CommentAction";

import s from "./LinkedInReactionButton.module.less";

import { ThumbsUpIcon } from "@assets/index";
import { ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const LinkedInReactionButton: React.FC<ILinkedInReactionButtonProps> = ({
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const [leaveTimeoutRef, setLeaveTimeoutRef] = useState<NodeJS.Timeout>();
  const [enterTimeoutRef, setEnterTimeoutRef] = useState<NodeJS.Timeout>();

  const Icon = value ? LinkedInReactionMap[value].icon : ThumbsUpIcon;

  function onMouseEnterHandler() {
    setEnterTimeoutRef(setTimeout(() => setOpen(true), HOVER_DELAY));
    if (leaveTimeoutRef) clearTimeout(leaveTimeoutRef);
  }

  function onMouseLeaveHandler() {
    setLeaveTimeoutRef(
      setTimeout(() => {
        setOpen(false);
      }, HOVER_DELAY),
    );
    if (enterTimeoutRef) clearTimeout(enterTimeoutRef);
  }

  function onClickHandler() {
    onChange?.(value ? undefined : DEFAULT_REACTION);
  }

  return (
    <span
      className={s.bbLinkedInReactionButton}
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {open && (
        <LinkedInReactionBar
          onSelect={reaction => {
            setOpen(false);
            onChange?.(reaction);
          }}
          className={s.bbLinkedInReactionButtonWidget}
        />
      )}
      <CommentAction type="primary" active={!!value || open} onClick={onClickHandler}>
        <Icon />
        <ChevronDownIcon />
      </CommentAction>
    </span>
  );
};
