import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { IDocumentState } from "./types";

const initialState = {
  items: [],
  gettingDocuments: false,
  getDocumentFail: false,
  reportFile: "",
};

export const documentReducer = createReducer<IDocumentState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.getDocumentsAction, state => ({
    ...state,
    gettingDocuments: true,
    getDocumentFail: false,
  }))
  .handleAction(actions.getDocumentsSuccess, (state, action) => ({
    ...state,
    items: action.payload.length ? action.payload : [],
    gettingDocuments: false,
    getDocumentFail: false,
  }))
  .handleAction(actions.getDocumentsFailure, state => ({
    ...state,
    gettingDocuments: false,
    getDocumentFail: true,
  }))
  .handleAction(actions.getDocumentsUrlAction, state => ({
    ...state,
  }))
  .handleAction(actions.getDocumentsUrlSuccess, (state, action) => ({
    ...state,
    reportFile: action.payload.url,
  }));
