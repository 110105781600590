import { ViewChangeFuncType } from "../../Reply.types";

export const MAX_VARIATIONS_LENGTH = 50;

export interface ISavedReplyFormProps {
  tab: string;
  editItemId?: string;
  onViewChange: ViewChangeFuncType;
}

export interface IFormErrorMessageProps {
  message: string;
}

export interface IVariation {
  text: string;
  collapsed: boolean;
  showDeleteConfirmation: boolean;
}

export const platforms = [
  "instagram",
  "facebook",
  "tiktok",
  "twitter",
  "linkedin",
  "youtube",
  "trustpilot",
  "google_business",
];
