import SignOutView from "./signOut/SignOutView";
import ForgotPasswordView from "./forgotPassword/ForgotPasswordView";
import SignInView from "./signIn/SignInView";
import ConfirmSignInView from "./confirmSignIn/ConfirmSignInView";
import ConfirmSignUpView from "./confirmSignUp/ConfirmSignUpView";
import RequireNewPasswordView from "./requireNewPassword/RequireNewPasswordView";
import SignUpView from "./signupSteps/SignUpView";
import CookieBanner from "./cookieBanner/CookieBanner";

export const BBSignOutView = SignOutView;
export const BBForgotPasswordView = ForgotPasswordView;
export const BBSignInView = SignInView;
export const BBConfirmSignInView = ConfirmSignInView;
export const BBSignUpView = SignUpView;
export { CookieBanner };

export const BBConfirmSignUpView = ConfirmSignUpView;
export const BBRequireNewPasswordView = RequireNewPasswordView;
