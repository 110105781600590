import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { useState, useMemo, ChangeEvent, MouseEvent } from "react";

import s from "./ForgotPassword.module.less";
import globalStyles from "../index.module.less";

import { Form, FormInstance } from "antd";
import FormHeader from "../formHeader/FormHeader";
import { CodeInput } from "../codeInput/CodeInput";
import { PasswordChecker } from "../passwordChecker/PasswordChecker";
import { Input, Button, InputPassword } from "@bbdevcrew/bb_ui_kit_fe";

import { validatePsw } from "../passwordChecker/helpers";
import { IFormError } from "../confirmSignIn/ConfirmSignIn.type";
import { IForgotPasswordProps, IFormValues } from "./ForgotPassword.type";

import { RedoIcon, HeadphoneIcon } from "@assets/index";

const cx = cn.bind(s);

const ForgotPassword: React.FC<IForgotPasswordProps> = ({
  onFinish,
  onFinishFailed,
  handleSubmit,
  handleInputChange,
  delivery,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [status, setStatus] = useState(delivery ? "submit" : "send");

  const onCodeChange = (form: FormInstance<IFormValues>, value: string) => {
    form.setFieldsValue({
      newCode: value,
    });
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(event.target.value);
    if (status === "submit" && handleInputChange) {
      handleInputChange(event);
    }
  };

  useMemo(() => {
    if (delivery) {
      setLoading(false);
      setStatus(delivery ? "submit" : "send");
    }
  }, [delivery]);

  const onSubmitFailed = (errorInfo: IFormError<IFormValues>) => {
    return onFinishFailed ? onFinishFailed(errorInfo) : null;
  };

  const callBackError = () => setLoading(false);

  const sendView = () => {
    return (
      <Form
        name="send"
        layout="vertical"
        autoComplete="off"
        onFinishFailed={onSubmitFailed}
        className={cx(s.bbForgotPasswordForm, globalStyles.bbFormXL)}
      >
        <FormHeader
          title={t("components:signIn:forgotPassword")}
          subtitle={t("components:forgotPassword:subtitle")}
        />
        <Form.Item
          name="email"
          label="Email"
          className={s.bbForgotPasswordInput}
          rules={[
            {
              required: true,
              message: t("components:signIn:emailMsg"),
            },
            {
              type: "email",
            },
          ]}
        >
          <Input
            id="email"
            name="email"
            key="email"
            autoFocus
            onChange={e => status === "send" && handleInputChange && handleInputChange(e)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            _type="primary"
            type="submit"
            loading={loading}
            className={cx(
              globalStyles.buttonXL,
              globalStyles.buttonGradient,
              s.bbForgotPasswordButton,
            )}
          >
            {t("components:forgotPassword:sendResetCode")}
          </Button>
        </Form.Item>
        <div className={s.bbLoginSection}>
          <span>{t("generic:goBackTo")} </span>
          <Button
            _size="lg"
            _type="link"
            id="login-btn"
            type="submit"
            className={s.bbRightStep1Button}
            onClick={(e: MouseEvent) => handleSubmit?.(e, "send")}
          >
            {t("generic:login")}
          </Button>
        </div>
      </Form>
    );
  };

  const submitView = () => (
    <Form
      name="submit"
      layout="vertical"
      autoComplete="off"
      onFinishFailed={onSubmitFailed}
      className={cx(s.bbForgotPasswordForm, globalStyles.bbFormXL)}
    >
      <FormHeader
        title={t("components:requireNewPassword:title")}
        subtitle={t("components:forgotPassword:subtitleView")}
      />
      <Form.Item label={t("components:forgotPassword:codeLabel")} shouldUpdate>
        {(form: FormInstance<IFormValues>) => (
          <Form.Item name="newCode">
            <CodeInput onChange={value => onCodeChange(form, value)} />
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item
        name="newPassword"
        label={t("components:forgotPassword:newPswLabel")}
        validateTrigger="onBlur"
        rules={[
          {
            required: true,
            message: t("components:forgotPassword:newPswMessage"),
          },
          () => ({
            validator(rule, value) {
              const { isValid } = validatePsw(value);

              if (isValid) {
                return Promise.resolve();
              }

              return Promise.reject(t("pages:signup:step1:left:form:password:pattern"));
            },
          }),
        ]}
      >
        <InputPassword
          maxLength={100}
          id="newPassword"
          key="newPassword"
          name="newPassword"
          autoComplete="new-password"
          onChange={onPasswordChange}
          hideMaxLengthChip
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <PasswordChecker
            value={passwordValue}
            isValidated={!!form.getFieldError("newPassword")[0]}
          />
        )}
      </Form.Item>
      <Form.Item
        validateTrigger="onBlur"
        name="confirmNewPassword"
        label={t("components:forgotPassword:confirmPswlabel")}
        rules={[
          {
            required: true,
            message: t("components:forgotPassword:confirmPswMessage"),
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(t("components:forgotPassword:matchMessage"));
            },
          }),
        ]}
      >
        <InputPassword
          maxLength={100}
          id="confirmNewPassword"
          key="confirmNewPassword"
          name="confirmNewPassword"
          autoComplete="confirm-new-password"
          hideMaxLengthChip
        />
      </Form.Item>

      <Form.Item>
        <Button
          _type="primary"
          type="submit"
          loading={loading}
          className={cx(globalStyles.buttonXL, s.bbResetPassword)}
        >
          {t("components:forgotPassword:submitLabel")}
        </Button>
      </Form.Item>

      <div className={s.bbForgotPasswordHelpWrapper}>
        <Button
          _size="sm"
          _type="link"
          className={s.bbForgotPasswordHelpBtn}
          onClick={e => {
            e.preventDefault();
            handleSubmit?.(e, "submit");
          }}
        >
          <RedoIcon />
          {t("generic:resendCode")}
        </Button>
        <Button _size="sm" _type="link" className={s.bbForgotPasswordHelpBtn}>
          <a href="mailto:help@brandbastion.com" target="_blank" rel="noreferrer">
            <HeadphoneIcon />
            {t("generic:contactSupport")}
          </a>
        </Button>
      </div>
    </Form>
  );

  return (
    <div className={s.bbForgotPasswordContainer}>
      <Form.Provider
        onFormFinish={(name, info) => {
          setLoading(true);
          return onFinish?.(info.values, name, callBackError);
        }}
      >
        {status === "send" ? sendView() : <div />}
        {status === "submit" ? submitView() : <div />}
      </Form.Provider>
    </div>
  );
};

export default ForgotPassword;
