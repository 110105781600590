import { action } from "typesafe-actions";
import {
  GET_HIDING_CONFIG,
  GET_HIDING_CONFIG_SUCCESS,
  GET_HIDING_CONFIG_FAILURE,
  UPDATE_HIDING_CONFIG,
  UPDATE_HIDING_CONFIG_SUCCESS,
  UPDATE_HIDING_CONFIG_FAILURE,
} from "./actionTypes";
import { IConfigData, IConfigRequest } from "./types";

export const getHidingConfigAction = () => action(GET_HIDING_CONFIG);
export const getHidingConfigActionSuccess = (data: IConfigData) =>
  action(GET_HIDING_CONFIG_SUCCESS, data);
export const getHidingConfigActionFailure = () => action(GET_HIDING_CONFIG_FAILURE);

export const updateHidingConfigAction = (data: IConfigRequest) =>
  action(UPDATE_HIDING_CONFIG, data);
export const updateHidingConfigActionSuccess = (data: IConfigData) =>
  action(UPDATE_HIDING_CONFIG_SUCCESS, data);
export const updateHidingConfigActionFailure = () => action(UPDATE_HIDING_CONFIG_FAILURE);
