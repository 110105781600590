import React, { FC, useMemo, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import CommentAction from "../commentAction/CommentAction";

import { CommentClickFunctionType } from "../CommentActions.type";
import { IComment } from "@store/comments/types";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionReviewProps {
  className?: string;
  comment: IComment;
  onReviewClick?: CommentClickFunctionType;
}

const ActionReview: FC<IActionReviewProps> = ({ comment, onReviewClick, className = "" }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const isDisabled = comment.is_hidden || comment.is_deleted;

  const tooltipTitle: string | JSX.Element = useMemo(() => {
    if (comment.is_deleted) {
      return t("components:comments:tooltips:deletedReview");
    }

    if (comment.is_hidden) {
      return t("components:comments:tooltips:hiddenReview");
    }

    if (comment.moderation.is_reviewed) {
      return comment.tooltips?.moderation_review
        ? comment.tooltips.moderation_review
        : t("components:comments:tooltips:reviewed");
    }

    return t("components:comments:tooltips:review");
  }, [comment, t]);

  const buttonCopy = (
    <span>
      {t(`components:comments:actions:${!comment.moderation?.is_reviewed ? "review" : "reviewed"}`)}
    </span>
  );

  const onClickReview = (event: MouseEvent) => {
    event.preventDefault();

    onReviewClick && onReviewClick(comment.id, !comment.moderation?.is_reviewed);
  };

  return (
    <CommentAction
      className={className}
      type={isDisabled ? "disabled" : "primary"}
      onClick={onClickReview}
      loading={comment.actionsProcessing?.includes("review")}
      active={!comment.is_hidden && !comment.is_deleted && comment.moderation?.is_reviewed}
      tooltipProps={{
        title: tooltipTitle,
        style: comment.tooltips?.moderation_review
          ? {
              width: 255,
            }
          : {},
      }}
    >
      <CheckIcon />
      {screens.sm && buttonCopy}
    </CommentAction>
  );
};

export default ActionReview;
