import { useSelector } from "react-redux";
import React, { FC, useState } from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import { DemoMode } from "@containers/DemoMode/DemoMode";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import MissingAssetsDisclaimer from "@components/_common/Disclaimers/MissingAssetsDisclaimer";
import ReconnectAssetsDisclaimer from "@components/_common/Disclaimers/ReconnectAssetsDisclaimer";
import CommentLimitDisclaimer from "@components/_common/Disclaimers/CommentLimitDisclaimer";
import PaymentFailedDisclaimer from "@components/_common/Disclaimers/PaymentFailedDisclaimer";
import PendingAssetsDisclaimer from "@components/_common/Disclaimers/PendingAssetsDisclaimer";
import PDFReport from "@components/managed/Transparency/PDFReport";
import Insights from "@components/insights";

import { meSelector } from "@store/me/selectors";

import s from "./Routes.module.less";

import { IRoutesProps } from "./Routes.type";
import { isGracePeriod } from "@utils/grace";
import { useRoutes } from "@utils/useRoutes";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";

const InsightsRoutes: FC<IRoutesProps> = ({ menu, path, hasMenu, pendingAssets }) => {
  const { redirectCallback, extraMenuItems } = useRoutes(path);
  const [showPendingAssetsAlert, setShowPendingAssetsAlert] = useState(true);
  const [showPaymentDisclaimer, setShowPaymentDisclaimer] = useState(true);
  const me = useSelector(meSelector);

  return (
    <>
      <Routes>
        <Route path="pdf_export/overview" element={<PDFReport type="listen-overview" />} />
        <Route
          path="*"
          element={
            <AppLayout
              basename="insights"
              avatarUrl={me?.client?.logo}
              extra={extraMenuItems}
              menu={menu as unknown as IMenuItem[]}
              contentClassName={s.bbInsightsContent}
            >
              {{
                beforeContent: (
                  <>
                    {path !== "/unauthorized" ? (
                      <div>
                        {!hasMenu ? <MissingAssetsDisclaimer /> : null}
                        {me && isGracePeriod(me) && showPaymentDisclaimer ? (
                          <PaymentFailedDisclaimer
                            onCloseDisclaimer={() => setShowPaymentDisclaimer(false)}
                          />
                        ) : null}
                        {me?.asset_alert?.message ? <ReconnectAssetsDisclaimer /> : null}
                        {pendingAssets.length && showPendingAssetsAlert ? (
                          <PendingAssetsDisclaimer
                            onCloseDisclaimer={() => setShowPendingAssetsAlert(false)}
                          />
                        ) : null}
                        <CommentLimitDisclaimer />
                        <DemoMode />
                      </div>
                    ) : null}
                  </>
                ),
                content: (
                  <Routes>
                    <Route
                      path="/"
                      element={
                        <PrivateRoute>
                          <Insights />
                        </PrivateRoute>
                      }
                    />

                    <Route
                      path="unauthorized"
                      element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
                    />
                    <Route
                      path="*"
                      element={<NotFoundView redirectCallback={redirectCallback} />}
                    />
                  </Routes>
                ),
              }}
            </AppLayout>
          }
        />
      </Routes>
    </>
  );
};

export default InsightsRoutes;
