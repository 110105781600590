export const detectUserOS = () => {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];

  if (macosPlatforms.indexOf(platform) !== -1) return "Mac OS";
  else if (iosPlatforms.indexOf(platform) !== -1) return "iOS";
  else if (windowsPlatforms.indexOf(platform) !== -1) return "Windows";
  else if (/Android/.test(userAgent)) return "Android";
  else if (/Linux/.test(platform)) return "Linux";

  return "";
};
