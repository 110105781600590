import { RootState } from "../";

export const fromInboxStats = (state: RootState) => state.inboxStats;

export const inboxStatsSelector = (state: RootState) => fromInboxStats(state).data;

export const getInboxStatsSuccessfulSelector = (state: RootState) =>
  fromInboxStats(state).fetchedInboxStats;
export const fetchingInboxStatsSelector = (state: RootState) =>
  fromInboxStats(state).fetchingInboxStats;
export const getInboxStatsFailureSelector = (state: RootState) =>
  fromInboxStats(state).fetchedInboxStatsFail;
export const clearInboxStatsSelector = (state: RootState) => fromInboxStats(state).clearInboxStats;
