export type PossibleChecklistIdsType =
  | "add_assets"
  | "invite"
  | "keyword_search"
  | "group_assets"
  | "hide_harmful_comment"
  | "reply_to_comment";

interface IChecklistItem {
  checked: boolean;
  id: PossibleChecklistIdsType;
  label: string;
  path: string;
  description?: string;
  disabled?: boolean;
}

export interface IInsightsChecklist {
  dismissed: boolean;
  items: IChecklistItem[];
}
export interface IUserChecklist {
  insights: IInsightsChecklist;
}

export interface ISerializedInsightsChecklist extends Omit<IInsightsChecklist, "items"> {
  items: { id: PossibleChecklistIdsType; checked: boolean }[];
}

export const serializeChecklist = (
  checklist: IInsightsChecklist,
  idToUpdate?: PossibleChecklistIdsType,
): ISerializedInsightsChecklist => {
  const items = checklist.items.map(item => {
    if (item.id === idToUpdate) {
      return {
        id: item.id,
        checked: true,
      };
    }

    return {
      id: item.id,
      checked: item.checked,
    };
  });

  return {
    ...checklist,
    items,
  };
};
