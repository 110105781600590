/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

interface IReplyModalContext {
  isMessagesTranlated: boolean;
  toggleTranslation: () => void;
  resetTranslation: () => void;
  translateAllButtonVisible: boolean;
  setTranslateAllButtonVisible: Dispatch<SetStateAction<boolean>>;
}

type ReplyModalContextProviderType = {
  children: string | JSX.Element | JSX.Element[];
};

const ReplyModalContext = createContext<IReplyModalContext>({
  isMessagesTranlated: false,
  toggleTranslation: () => {},
  resetTranslation: () => {},
  translateAllButtonVisible: true,
  setTranslateAllButtonVisible: () => {},
});

export const useReplyModalContext = () => useContext(ReplyModalContext);

export default function ReplyModalContextProvider({ children }: ReplyModalContextProviderType) {
  const [isMessagesTranlated, setIsMessagesTranlated] = useState<boolean>(false);
  const [translateAllButtonVisible, setTranslateAllButtonVisible] = useState<boolean>(true);
  const toggleTranslation = () => setIsMessagesTranlated(state => !state);
  const resetTranslation = () => setIsMessagesTranlated(false);

  return (
    <ReplyModalContext.Provider
      value={{
        isMessagesTranlated,
        toggleTranslation,
        resetTranslation,
        translateAllButtonVisible,
        setTranslateAllButtonVisible,
      }}
    >
      {children}
    </ReplyModalContext.Provider>
  );
}
