export const GET_AI_PROMPTS = "GET_AI_PROMPTS";
export const GET_AI_PROMPTS_SUCCESS = "GET_AI_PROMPTS_SUCCESS";
export const GET_AI_PROMPTS_FAILURE = "GET_AI_PROMPTS_FAILURE";

export const CREATE_AI_PROMPT = "CREATE_AI_PROMPT";
export const CREATE_AI_PROMPT_SUCCESS = "CREATE_AI_PROMPT_SUCCESS";
export const CREATE_AI_PROMPT_FAILURE = "CREATE_AI_PROMPT_FAILURE";

export const UPDATE_AI_PROMPT = "UPDATE_AI_PROMPT";
export const UPDATE_AI_PROMPT_SUCCESS = "UPDATE_AI_PROMPT_SUCCESS";
export const UPDATE_AI_PROMPT_FAILURE = "UPDATE_AI_PROMPT_FAILURE";

export const DELETE_AI_PROMPT = "DELETE_AI_PROMPT";
export const DELETE_AI_PROMPT_SUCCESS = "DELETE_AI_PROMPT_SUCCESS";
export const DELETE_AI_PROMPT_FAILURE = "DELETE_AI_PROMPT_FAILURE";

export const SET_AI_PROMPT_AS_DEFAULT = "SET_AI_PROMPT_AS_DEFAULT";
export const SET_AI_PROMPT_AS_DEFAULT_SUCCESS = "SET_AI_PROMPT_AS_DEFAULT_SUCCESS";
export const SET_AI_PROMPT_AS_DEFAULT_FAILURE = "SET_AI_PROMPT_AS_DEFAULT_FAILURE";

export const GET_PROMPT_AI_SUGGESTION = "GET_PROMPT_AI_SUGGESTION";
export const GET_PROMPT_AI_SUGGESTION_SUCCESS = "GET_PROMPT_AI_SUGGESTION_SUCCESS";
export const GET_PROMPT_AI_SUGGESTION_FAILURE = "GET_PROMPT_AI_SUGGESTION_FAILURE";

export const CLEAR_AI_PROMPT_SUGGESTION = "CLEAR_AI_PROMPT_SUGGESTION";
export const CLEAR_AI_PROMPT = "CLEAR_AI_PROMPT";
