import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import s from "./PostCard.module.less";

import { LINK_REGEX } from "../../../publish/postCreation/helpers/constants";

interface IPostCardMessageProps {
  message: string;
  username?: string;
  maxMessageLength: number;
  showMoreText?: boolean;
}

const PostCardMessage: FC<IPostCardMessageProps> = ({
  message,
  username,
  maxMessageLength,
  showMoreText,
}) => {
  const { t } = useTranslation();

  const captionExceedsLimit = message.length > maxMessageLength;

  const cropText = (text: string, maxLength: number) => {
    if (text.length > maxLength) return text.substring(0, maxLength);

    return text;
  };

  const processMessage = useMemo(() => {
    let remainingLength = maxMessageLength;
    const segments = message.split(LINK_REGEX);
    const processedSegments: JSX.Element[] = [];

    segments.forEach((segment, index) => {
      if (remainingLength > 0) {
        const matchResult = segment.match(LINK_REGEX);

        if (matchResult) {
          const match = matchResult[0];

          if (match.length <= remainingLength) {
            processedSegments.push(
              <span key={index} className={s.bbHighlightedLink}>
                {match}
              </span>,
            );
            remainingLength -= match.length;
          } else {
            processedSegments.push(
              <span key={index} className={s.bbHighlightedLink}>
                {cropText(match, remainingLength)}
              </span>,
            );
            remainingLength = 0;
          }
        } else {
          const croppedSegment = cropText(segment, remainingLength);
          processedSegments.push(<span key={index}>{croppedSegment}</span>);
          remainingLength -= croppedSegment.length;
        }
      }
    });

    return processedSegments;
  }, [message, maxMessageLength]);

  return (
    <>
      {!!username && <strong>{username}</strong>}
      {processMessage.map(part => part)}
      {showMoreText && captionExceedsLimit && (
        <span className={s.bbShowMore}>...{t("components:comments:seeMore")}</span>
      )}
    </>
  );
};

export default PostCardMessage;
