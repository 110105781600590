import { createAction } from "typesafe-actions";
import {
  CARE_CPS_SEARCH_CLIENT,
  CARE_CPS_SEARCH_CLIENT_SUCCESS,
  CARE_CPS_SEARCH_CLIENT_FAILURE,
  CARE_CPS_GET_TAGS_FAILURE,
  CARE_CPS_GET_TAGS_SUCCESS,
  CARE_CPS_GET_TAGS,
  CARE_CPS_GET_MODERATION_USERS_FAILURE,
  CARE_CPS_GET_MODERATION_USERS_SUCCESS,
  CARE_CPS_GET_MODERATION_USERS,
  CARE_CPS_CREATE_STREAM,
  CARE_CPS_DELETE_STREAM_FAILURE,
  CARE_CPS_DELETE_STREAM_SUCCESS,
  CARE_CPS_DELETE_STREAM,
  CARE_CPS_UPDATE_STREAM,
  CARE_CPS_CREATE_OR_UPDATE_STREAM_SUCCESS,
  CARE_CPS_CREATE_OR_UPDATE_STREAM_FAILURE,
  CARE_CPS_STREAM_RESET,
} from "./actionTypes";
import { IClient, IModerationGroup, ITag } from "./types";
import { ICareCPSStream } from "@store/streams/types";

export const searchClientAction = createAction(CARE_CPS_SEARCH_CLIENT)<string>();
export const searchClientSuccessAction = createAction(CARE_CPS_SEARCH_CLIENT_SUCCESS)<IClient[]>();
export const searchClientFailureAction = createAction(CARE_CPS_SEARCH_CLIENT_FAILURE)();

export const getTagsAction = createAction(CARE_CPS_GET_TAGS)();
export const getTagsSuccessAction = createAction(CARE_CPS_GET_TAGS_SUCCESS)<ITag[]>();
export const getTagsFailureAction = createAction(CARE_CPS_GET_TAGS_FAILURE)();

export const getModerationUsersAction = createAction(CARE_CPS_GET_MODERATION_USERS)();
export const getModerationUsersSuccessAction = createAction(CARE_CPS_GET_MODERATION_USERS_SUCCESS)<
  IModerationGroup[]
>();
export const getModerationUsersFailureAction = createAction(
  CARE_CPS_GET_MODERATION_USERS_FAILURE,
)();

export const createCareCpsStreamAction = createAction(CARE_CPS_CREATE_STREAM)<ICareCPSStream>();
export const updateCareCpsStreamAction = createAction(CARE_CPS_UPDATE_STREAM)<ICareCPSStream>();
export const createOrUpdateCareCpsStreamSuccessAction = createAction(
  CARE_CPS_CREATE_OR_UPDATE_STREAM_SUCCESS,
)<ICareCPSStream>();
export const createOrUpdateCareCpsStreamFailureAction = createAction(
  CARE_CPS_CREATE_OR_UPDATE_STREAM_FAILURE,
)();
export const careCpsStreamResetAction = createAction(CARE_CPS_STREAM_RESET)();

export const deleteCareCpsStreamAction = createAction(CARE_CPS_DELETE_STREAM)<string>();
export const deleteCareCpsStreamSuccessAction = createAction(CARE_CPS_DELETE_STREAM_SUCCESS)();
export const deleteCareCpsStreamFailureAction = createAction(CARE_CPS_DELETE_STREAM_FAILURE)();
