import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Col } from "antd";
import Step1 from "./step1/Step1View";
import Step2 from "./step2/Step2View";
import Step3 from "./step3/Step3View";
import SignUpHeader from "../signUpHeader/SignUpHeader";
import CookieBanner from "../cookieBanner/CookieBanner";
import AppFooter from "@components/_common/Appfooter/AppFooter";
import AgencyLogoBadge from "@components/_common/AgencyLogoBadge/AgencyLogoBadge";

import s from "./SignUp.module.less";

import { submitHubspotForm } from "@store/hubspot/actions";
import { signUserUpAction, signUserUpSuccessAction } from "@store/user/actions";

import { accessToken, cognitoCookies, signedUserUpSelector } from "@store/user/selectors";

import { IFormStep2 } from "./step2/Step2.type";
import { getCurrentModuleName } from "@utils/paths";
import { IStep1CallbackData } from "./step1/Step1.type";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";

const SignUpView = (props: IAuthenticatorProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const queryParams = queryString.parse(window.location.search);
  const { authState } = props;

  // to debug the steps faster choose 0, 1, 2
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setData] = useState<IStep1CallbackData>();

  const [isPilotView, setIsPilotView] = useState(false);
  const [isAgencyView, setIsAgencyView] = useState(false);
  const [isSalesAnalysisView, setIsSalesAnalysisView] = useState(false);

  const userSignedUp = useSelector(signedUserUpSelector);
  const userAccessToken = useSelector(accessToken);
  const userCookies = useSelector(cognitoCookies);

  const submitHsForm = useCallback(body => dispatch(submitHubspotForm(body)), [dispatch]);

  // Switch to step 2 (assets) on signup success
  useEffect(() => {
    if (userSignedUp && userCookies && userAccessToken) {
      localStorage.setItem("accessToken", userAccessToken);
      // Set cookies to localStorage rather as cookies, so AWS doesn't think the user is logged in
      localStorage.setItem("tempCookies", JSON.stringify(userCookies));

      if (localStorage.getItem("isAgencyView") === "true") setIsAgencyView(true);

      setCurrentStep(2);
    }
  }, [userSignedUp, userAccessToken, userCookies]);

  // If redirected from some platform, set correct step
  useEffect(() => {
    if (authState !== "signUp" && queryParams.social_platform && userAccessToken) {
      setCurrentStep(2);
    }
  }, [queryParams, userAccessToken, authState]);

  useEffect(() => {
    if (currentStep === 0) {
      if (window.location.pathname === "/login/signup/analysis") setIsSalesAnalysisView(true);
      else if (window.location.pathname === "/login/signup/pilot") setIsPilotView(true);
      else if (window.location.pathname === "/login/signup/agency") {
        setIsAgencyView(true);
        localStorage.setItem("isAgencyView", "true");
      } else {
        localStorage.setItem("isAgencyView", "false");
      }
    }
  }, [currentStep]);

  const callbackStep1 = useCallback(
    (response: IStep1CallbackData) => {
      setData(response);

      if (process.env.HUBSPOT_FORM_ID) {
        submitHsForm(response);
      }

      setCurrentStep(1);
    },
    [submitHsForm],
  );

  const callbackStep2 = (response: IFormStep2) => {
    dispatch(
      signUserUpAction({
        ...data,
        ...response,
        managed_pilot: isPilotView,
        sales_analysis: isSalesAnalysisView,
      }),
    );

    localStorage.setItem("isAgencyView", response.is_agency ? "true" : "false");

    if (process.env.HUBSPOT_FORM_ID) {
      submitHsForm(response);
    }
  };

  const callbackStep3 = () => {
    const cookies = localStorage.getItem("tempCookies");

    if (cookies && userAccessToken) {
      dispatch(
        signUserUpSuccessAction({
          cookies: JSON.parse(cookies),
          access_token: userAccessToken,
        }),
      );
    }

    props.onStateChange && props.onStateChange("signIn", userAccessToken);
  };

  return authState === "signUp" &&
    (getCurrentModuleName() === "login" || window.location.pathname === "/") ? (
    <>
      <Col className={s.bbRootSignUp} xs={24}>
        {currentStep > 0 && (
          <SignUpHeader
            current={currentStep}
            logoBadge={isAgencyView ? <AgencyLogoBadge /> : null}
            steps={[
              { title: t("pages:signup:stepper:step1") },
              { title: t("pages:signup:stepper:step2") },
              { title: t("pages:signup:stepper:step3") },
            ]}
          />
        )}
        <>
          {currentStep === 0 && (
            <Step1
              {...props}
              onFinish={callbackStep1}
              isPilotView={isPilotView}
              isAgencyView={isAgencyView}
              setIsAgencyView={setIsAgencyView}
              isSalesAnalysisView={isSalesAnalysisView}
            />
          )}
          {currentStep === 1 && (
            <Step2
              {...props}
              onFinish={callbackStep2}
              isAgencyView={isAgencyView}
              onReturn={() => setCurrentStep(0)}
            />
          )}
          {currentStep === 2 && (
            <Step3 {...props} isAgencyView={isAgencyView} onFinish={callbackStep3} />
          )}
        </>
        <AppFooter />
      </Col>
      {currentStep === 0 ? <CookieBanner /> : null}
    </>
  ) : null;
};

export default SignUpView;
