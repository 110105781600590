import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import Tags from "./Tags";
import PostID from "../PostView/PostID";
import PostView from "../PostView/PostView";
import ChangeSentiment from "./ChangeSentiment";
import PostInsights from "./PostInsights/PostInsights";
import ConversationActions from "./ConversationActions";
import Accordion from "@components/_common/Accordion/Accordion";

import s from "./CommentDetails.module.less";

import { ICommentDetails } from "./CommentDetail.type";
import { useReplyingComment } from "@utils/comments";
import { isPrivateConversation } from "@utils/comments";
import { isInstagramPlatform, isTiktokPlatform } from "@utils/platform";
import { PostPlacementEnum } from "../Social/Post/Post.type";

export const CommentDetails: FC<ICommentDetails> = ({ filters, replyData, listId }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const isReview = replyData.post_placement === PostPlacementEnum.REVIEW;
  const postType = replyData.post_preview?.type;
  const platformId = replyData.author_detail.platform_type.id;
  const isTTMention =
    isTiktokPlatform(platformId) &&
    (postType === "CAPTION_MENTION" || postType === "HASHTAG_MENTION" || postType === "MENTION");
  const isIGHashtagMention = isInstagramPlatform(platformId) && postType === "HASHTAG_MENTION";
  const replyingComment = useReplyingComment(replyData, listId);
  const isPrivateMessage =
    !!replyData?.post_placement && isPrivateConversation(replyData.post_placement);

  return (
    <Accordion
      type="white"
      collapsed={collapsed}
      setCollapsed={() => setCollapsed(prevState => !prevState)}
      title={t("components:comments:details:title")}
      className={s.bbCommentDetailsAccordion}
      body={
        <div className={s.bbCommentDetails}>
          {!!replyingComment && (
            <>
              <ChangeSentiment comment={replyingComment} />
              <Tags
                comment={replyingComment}
                replyData={replyData}
                showMessageTags={isPrivateMessage}
              />
              <ConversationActions comment={replyingComment} filters={filters} />
              {!isPrivateMessage && !isReview && (
                <PostView
                  isClickable
                  type="block"
                  post_id={replyingComment?.post_id}
                  post={replyData.post_preview}
                />
              )}
              {(isTTMention || isIGHashtagMention) && (
                <PostInsights
                  commentsCount={replyData.post_preview?.comment_metrics?.total_comments || 0}
                  likesCount={replyData.post_preview?.reactions?.like || 0}
                  sharesCount={
                    isTTMention ? replyData.post_preview?.post_metrics.total_shares || 0 : undefined
                  }
                />
              )}
              {!isPrivateMessage && <PostID post_id={replyingComment?.post_id} />}
            </>
          )}
        </div>
      }
    />
  );
};
