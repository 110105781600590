import React from "react";
import { useTranslation } from "react-i18next";

import s from "./TikTokSubscriptionPricingTable.module.less";

import { ITikTokPricingItemProps } from "./TikTokSubscriptionPricingTable.types";

import { CheckCircleFilledIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const TikTokPricingTableItem = ({
  popular,
  title,
  description,
  price,
  url,
  buttonText,
  includes,
  type,
}: ITikTokPricingItemProps) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbTTPricingTableMainFrame}>
      <div className={s.bbTTChipWrapper}>
        {popular && (
          <span className={s.bbTTChip}>{t("components:subscriptionModal:tt:popular")}</span>
        )}
      </div>
      <div className={s.bbTTSectionType}>
        <span className={s.bbTTSectionTypeTitle}>{title}</span>
        <div className={s.bbTTSectionTypeDescription}>
          {!!description && <span className={s.bbTTSectionTypeSubTitle}>{description}</span>}
          {!!price && (
            <>
              <span className={s.bbTTSectionTypePrice}>{price}</span>
              <span>
                {t(`components:subscriptionModal:tt:per${type === "monthly" ? "Month" : "Year"}`)}
              </span>
            </>
          )}
        </div>
      </div>
      <div>
        <a className={s.bbTTLink} href={url} target="_blank" rel="noreferrer">
          {buttonText}
        </a>
      </div>
      <div className={s.bbTTList}>
        <span className={s.bbTTListTitle}>{t(`components:subscriptionModal:tt:includes`)}</span>
        <div className={s.bbTTListItems}>
          {includes.map((item, index) => (
            <div className={s.bbTTListItem} key={index}>
              <CheckCircleFilledIcon />
              <span>{item}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
