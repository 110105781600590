import React, { FC } from "react";
import cn from "classnames/bind";

import s from "./GoogleBusinessMessage.module.less";

import { IReviewStarsProps } from "./types";
import { RatingStarsType } from "../../Social/Post/Post.type";
import { getStarNumber, MAX_GOOGLE_BUSINESS_STARS } from "./helpers";

import GoogleBusinessStarIcon from "@assets/GoogleBusinessStar.svg";

const cx = cn.bind(s);

export const GoogleBusinessStars: FC<IReviewStarsProps> = ({ stars }) => {
  const numberOfStars = getStarNumber(stars as RatingStarsType);

  return (
    <div className={s.bbStars}>
      {[...Array(MAX_GOOGLE_BUSINESS_STARS)].map((_, index) => {
        return (
          <div
            key={index}
            className={cx({
              [s.bbStarFilled]: numberOfStars && index < numberOfStars,
            })}
          >
            <GoogleBusinessStarIcon />
          </div>
        );
      })}
    </div>
  );
};
