import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { SIGN_USER_UP, VALIDATE_EMAIL } from "./actionTypes";
import { UserActionsType, IEmailValidationResponse } from "./types";
import {
  signUserUpFailureAction,
  signUserUpSuccessAction,
  validateEmailFailureAction,
  validateEmailSuccessAction,
} from "./actions";
import { users } from "@utils/paths";
import { IUserSignupAPIResponse } from "../tiktokApp/types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

export const signUserUp = (action$: Observable<UserActionsType>) =>
  action$.pipe(
    filter(isOfType(SIGN_USER_UP)),
    switchMap(a => {
      return ajax.post<IUserSignupAPIResponse>(`${users}/signup`, a.payload, getHeaders({})).pipe(
        map(e => e.response),
        map(signUserUpSuccessAction),
        catchError(e => handleError(e, signUserUpFailureAction)),
      );
    }),
  );

export const validateEmail = (action$: Observable<UserActionsType>) =>
  action$.pipe(
    filter(isOfType(VALIDATE_EMAIL)),
    switchMap(action =>
      ajax
        .post<IEmailValidationResponse>(
          `${users}/signup/duplication-check`,
          action.payload,
          getHeaders({}),
        )
        .pipe(
          map(event => event.response),
          map(validateEmailSuccessAction),
          catchError(error => handleError(error, validateEmailFailureAction)),
        ),
    ),
  );
