import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import { NotificationsTabContent } from "./NotificationsTabContent";

import s from "./Notifications.module.less";

import { NotificationsTabType } from "./Notifications.types";

export const NotificationsContent: React.FC = () => {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<NotificationsTabType>("all");

  return (
    <TabsScrollable
      activeKey={activeTab}
      onChange={key => setActiveTab(key as NotificationsTabType)}
      tabContentClassName={s.bbNotificationsTab}
    >
      <TabPane
        tabKey="all"
        tab={<span className={s.bbNotificationsTabTitle}>{t("generic:all")}</span>}
      >
        <NotificationsTabContent />
      </TabPane>
      <TabPane
        tabKey="unread"
        tab={<span className={s.bbNotificationsTabTitle}>{t("generic:unread")}</span>}
      >
        <NotificationsTabContent isUnreadTab={true} />
      </TabPane>
    </TabsScrollable>
  );
};
