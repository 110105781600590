export const mockAssetId = "17841433771396751";

export const mockAssetInfoResponse = {
  shopping_product_tag_eligibility: true,
  id: mockAssetId,
};

export const mockProducts = [
  {
    product_id: "1",
    product_name: "New Era Poster",
    image_url: "https://picsum.photos/250/250",
  },
  {
    product_id: "2",
    product_name: "In Rainbows Poster",
    image_url: "https://picsum.photos/250/250",
  },
  {
    product_id: "3",
    product_name: "Field Patterns Poster",
    image_url: "https://picsum.photos/250/250",
  },
  {
    product_id: "4",
    product_name: "Blob Poster",
    image_url: "https://picsum.photos/250/250",
  },
  {
    product_id: "5",
    product_name: "Nature Patterns Poster",
    image_url: "https://picsum.photos/250/250",
  },
];

for (let i = 6; i <= 1000; i++) {
  mockProducts.push({
    product_id: `${i}`,
    product_name: `Poster ${i}`,
    image_url: "https://picsum.photos/250/250",
  });
}
