export const GET_SOURCES_LIST = "SOURCES/GET_SOURCES_LIST";
export const GET_SOURCES_LIST_SUCCESS = "SOURCES/GET_SOURCES_LIST_SUCCESS";
export const GET_SOURCES_LIST_FAILURE = "SOURCES/GET_SOURCES_LIST_FAILURE";

export const CREATE_SOURCE = "SOURCES/CREATE_SOURCE";
export const CREATE_SOURCE_SUCCESS = "SOURCES/CREATE_SOURCE_SUCCESS";
export const CREATE_SOURCE_FAILURE = "SOURCES/CREATE_SOURCE_FAILURE";

export const UPDATE_SOURCE = "SOURCES/UPDATE_SOURCE";
export const UPDATE_SOURCE_SUCCESS = "SOURCES/UPDATE_SOURCE_SUCCESS";
export const UPDATE_SOURCE_FAILURE = "SOURCES/UPDATE_SOURCE_FAILURE";

export const DELETE_SOURCE = "SOURCES/DELETE_SOURCE";
export const DELETE_SOURCE_SUCCESS = "SOURCES/DELETE_SOURCE_SUCCESS";
export const DELETE_SOURCE_FAILURE = "SOURCES/DELETE_SOURCE_FAILURE";
