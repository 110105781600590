export const GET_CAPTIONS_AI_PROMPTS = "GET_CAPTIONS_AI_PROMPTS";
export const GET_CAPTIONS_AI_PROMPTS_SUCCESS = "GET_CAPTIONS_AI_PROMPTS_SUCCESS";
export const GET_CAPTIONS_AI_PROMPTS_FAILURE = "GET_CAPTIONS_AI_PROMPTS_FAILURE";

export const CREATE_CAPTIONS_AI_PROMPT = "CREATE_CAPTIONS_AI_PROMPT";
export const CREATE_CAPTIONS_AI_PROMPT_SUCCESS = "CREATE_CAPTIONS_AI_PROMPT_SUCCESS";
export const CREATE_CAPTIONS_AI_PROMPT_FAILURE = "CREATE_CAPTIONS_AI_PROMPT_FAILURE";

export const UPDATE_CAPTIONS_AI_PROMPT = "UPDATE_CAPTIONS_AI_PROMPT";
export const UPDATE_CAPTIONS_AI_PROMPT_SUCCESS = "UPDATE_CAPTIONS_AI_PROMPT_SUCCESS";
export const UPDATE_CAPTIONS_AI_PROMPT_FAILURE = "UPDATE_CAPTIONS_AI_PROMPT_FAILURE";

export const DELETE_CAPTIONS_AI_PROMPT = "DELETE_CAPTIONS_AI_PROMPT";
export const DELETE_CAPTIONS_AI_PROMPT_SUCCESS = "DELETE_CAPTIONS_AI_PROMPT_SUCCESS";
export const DELETE_CAPTIONS_AI_PROMPT_FAILURE = "DELETE_CAPTIONS_AI_PROMPT_FAILURE";

export const SET_CAPTIONS_AI_PROMPT_AS_DEFAULT = "SET_CAPTIONS_AI_PROMPT_AS_DEFAULT";
export const SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS =
  "SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS";
export const SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE =
  "SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE";

export const GET_CAPTIONS_PROMPT_AI_SUGGESTION = "GET_CAPTIONS_PROMPT_AI_SUGGESTION";
export const GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS =
  "GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS";
export const GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE =
  "GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE";

export const CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION = "CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION";
export const CLEAR_CAPTIONS_AI_PROMPT = "CLEAR_CAPTIONS_AI_PROMPT";
