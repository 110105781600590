/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { Dayjs } from "dayjs";
import { IPublishingPost } from "@store/publishings/types";

interface ICalendarContext {
  monthIndex: number;
  setMonthIndex: (index: number) => void;
  weekIndex: number;
  setWeekIndex: (index: number) => void;
  daySelected?: Dayjs;
  setDaySelected: (day?: Dayjs) => void;
  isPostModalOpen: boolean;
  setIsPostModalOpen: (show: boolean) => void;
  editingPost: IPublishingPost | null;
  setEditingPost: (post: IPublishingPost | null) => void;
}

const CalendarContext = React.createContext<ICalendarContext>({
  monthIndex: 0,
  setMonthIndex: () => {},
  weekIndex: 0,
  setWeekIndex: () => {},
  daySelected: undefined,
  setDaySelected: () => {},
  isPostModalOpen: false,
  setIsPostModalOpen: () => {},
  editingPost: null,
  setEditingPost: () => {},
});

export default CalendarContext;
