export const GET_ONBOARDING_PLATFORM_TYPES =
  "ONBOARDING_PLATFORM_TYPE/GET_ONBOARDING_PLATFORM_TYPES";
export const GET_ONBOARDING_PLATFORM_TYPES_SUCCESS =
  "ONBOARDING_PLATFORM_TYPE/GET_ONBOARDING_PLATFORM_TYPES_SUCCESS";
export const GET_ONBOARDING_PLATFORM_TYPES_FAILURE =
  "ONBOARDING_PLATFORM_TYPE/GET_ONBOARDING_PLATFORM_TYPES_FAILURE";

export const POST_ONBOARDING_PLATFORM_TYPE_ASSETS =
  "ONBOARDING_PLATFORM_TYPE/POST_ONBOARDING_PLATFORM_TYPE_ASSET";
export const POST_ONBOARDING_PLATFORM_TYPE_ASSETS_SUCCESS =
  "ONBOARDING_PLATFORM_TYPE/POST_ONBOARDING_PLATFORM_TYPE_ASSET_SUCCESS";
export const POST_ONBOARDING_PLATFORM_TYPE_ASSETS_FAILURE =
  "ONBOARDING_PLATFORM_TYPE/POST_ONBOARDING_PLATFORM_TYPE_ASSET_FAILURE";

export const SELECT_ASSETS = "ONBOARDING_PLATFORM_TYPE/SELECT_ASSETS";
export const DESELECT_ASSETS = "ONBOARDING_PLATFORM_TYPE/DESELECT_ASSETS";

export const SET_PLATFORM_TYPE_ALERT = "ONBOARDING_PLATFORM_TYPE/SET_PLATFORM_TYPE_ALERT";
