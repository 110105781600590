import React, { FC } from "react";
import { Col, Empty } from "antd";
import { Card, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { Trans, useTranslation } from "react-i18next";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ReviewsWidget.module.less";

import { getPercentageType } from "./ReviewWidget.helpers";
import { IReviewsWidgetVolumeProps } from "./ReviewsWidget.types";

import { StarIcon } from "@assets/index";

export const ReviewsWidgetVolume: FC<IReviewsWidgetVolumeProps> = ({ data, loading }) => {
  const { t } = useTranslation();

  return (
    <Col xs={24} lg={6}>
      <Card className={s.bbCard}>
        <div className={s.bbCardContent}>
          {loading ? (
            <div className={s.bbReviewWidgetLoading}>
              <Loading isLoading />
            </div>
          ) : data ? (
            <>
              <div className={s.bbReviewVolumeHeader}>
                <span className={s.bbReviewVolumeTitle}>
                  <Trans
                    i18nKey="components:reviewWidget:volume:headerTitle"
                    components={{ strong: <strong /> }}
                  />
                </span>
                <div className={s.bbReviewVolumeStar}>
                  <StarIcon />
                </div>
              </div>
              <div className={s.bbReviewVolumeContent}>
                <span>{data.value}</span>
                {data.percentageChange && (
                  <PerformanceCardPill
                    percentageValue={data.percentageChange.value}
                    type={getPercentageType(
                      data.percentageChange?.display,
                      data.percentageChange?.value,
                    )}
                  />
                )}
              </div>
            </>
          ) : (
            <Empty
              className={s.bbReviewWidgetEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
            />
          )}
        </div>
      </Card>
    </Col>
  );
};
