import React, { Fragment } from "react";
import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { Step } from "./Step";
import { IStepperConfig, ITStep, StepStatusType } from "./types";

import s from "./Stepper.module.less";

interface IStepperProps {
  current: number;
  steps: ITStep[];
  config?: IStepperConfig;
}

const defaultConfig: IStepperConfig = {
  process: {
    backgroundColor: palette.colorBlue700,
    borderColor: palette.colorBlue700,
    color: palette.colorWhite,
    titleColor: palette.colorBlue700,
    tailColors: [palette.colorGrey200],
  },
  finish: {
    backgroundColor: palette.colorGreen600,
    borderColor: palette.colorGreen600,
    color: palette.colorWhite,
    titleColor: palette.colorGrey700,
    tailColors: [palette.colorGreen600],
    icon: (
      <div className={s.bbStepDefaultFinishIcon}>
        <CheckIcon />
      </div>
    ),
  },
  wait: {
    backgroundColor: palette.colorWhite,
    borderColor: palette.colorGrey200,
    color: palette.colorBlack,
    titleColor: palette.colorBlack,
    tailColors: [palette.colorGrey200],
  },
};

export const Stepper: React.FC<IStepperProps> = ({ current, steps, config = defaultConfig }) => {
  const getStepStatus = (stepIndex: number): StepStatusType => {
    if (stepIndex < current) return "finish";
    if (stepIndex > current) return "wait";

    return "process";
  };

  return (
    <div className={s.bbStepper}>
      {steps.map((step, idx) => {
        const status = getStepStatus(idx);
        const stepConfig = config[status];

        return (
          <Fragment key={idx}>
            {stepConfig && (
              <Step
                key={step.title}
                title={step.title}
                status={status}
                config={stepConfig}
                hasTail={idx < steps.length - 1}
                content={stepConfig?.icon || idx + 1}
              />
            )}
          </Fragment>
        );
      })}
    </div>
  );
};
