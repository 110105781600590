export type CacheType<T> = { [key: string]: { object: T; created: number } };

export const MAX_CACHE_SIZE = 1000;

export const cleanUpCache = (cache: CacheType<object>, limit: number = MAX_CACHE_SIZE) => {
  const entries = Object.entries(cache);
  if (entries.length > limit) {
    entries.sort((a, b) => a[1].created - b[1].created);
    const excess = entries.length - limit;
    entries.splice(0, excess).forEach(([key]) => {
      delete cache[key];
    });
  }
};

export const createCacheObject = <T>(object: T) => ({ object, created: Date.now() });

export const selectFromCache = <T>(cache: CacheType<T>, key: string) =>
  cache[key] ? cache[key].object : undefined;

export const selectAllFromCache = <T>(cache: CacheType<T>, keys: string[]) =>
  keys.reduce((result: T[], key) => {
    if (cache[key]) {
      result.push(cache[key].object);
    }
    return result;
  }, []);
