import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import ReactResizeDetector from "react-resize-detector";

import { Empty } from "antd";
import TopTagsChart from "./TopTagsChart";
import { Card, Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TopTags.module.less";

import { ITopTag } from "@store/dashboard/types";
import { getLines } from "@components/insights/sentimentDetails/helpers";

import { CommentIcon } from "@assets/index";

interface ITopTagsProps {
  loading: boolean;
  data: ITopTag[];
}

const TopTags: FC<ITopTagsProps> = ({ data, loading }) => {
  const { t } = useTranslation();

  return (
    <div
      className={s.bbTopTags}
      data-cy="top-tags-container"
      data-stonly-target="intelligence__top-tags"
    >
      <Card
        headerTitle={t("components:listen:topTags:headerTitle")}
        headerSubTitle={t("components:listen:topTags:headerSubTitle")}
      >
        {loading ? (
          <div className={s.bbTopTagsLoading}>
            <Loading isLoading />
          </div>
        ) : data?.length ? (
          <ReactResizeDetector
            handleWidth
            refreshMode={"throttle"}
            refreshOptions={{ leading: true, trailing: true }}
          >
            {(props: { width: number }) => {
              return data.length ? (
                <>
                  <TopTagsChart data={data} chartWidth={props.width} />
                  <div className={s.bbChartLegend}>
                    <div>
                      <CommentIcon />
                      {t("generic:conversations")}
                    </div>
                    <div>
                      {getLines()?.map(entry => {
                        const { dataKey, stroke, customLegendBackground } = entry;

                        return (
                          <div key={dataKey} className={s.bbChartLegendItem}>
                            <span
                              style={{
                                background: customLegendBackground
                                  ? customLegendBackground
                                  : stroke,
                              }}
                              className={s.bbChartLegendBox}
                            ></span>
                            <span className={s.bbChartLegendName}>{entry.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ) : (
                <Empty
                  className={s.bbTopTagsEmpty}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("components:empty:description")}
                  style={{
                    margin: 0,
                  }}
                />
              );
            }}
          </ReactResizeDetector>
        ) : (
          <Empty
            className={s.bbTopTagsEmpty}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={t("components:empty:description")}
          />
        )}
      </Card>
    </div>
  );
};

export default TopTags;
