import { ajax } from "rxjs/ajax";
import { from, Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, mergeMap, map, filter, catchError, startWith } from "rxjs/operators";

import {
  comments,
  repliesList,
  commentsActions,
  BASE_URL_API,
  getCurrentModuleName,
  commentsSearch,
  platformMediaImage,
} from "@utils/paths";

import { RootState } from "../";
import {
  AllowedCommentActionsType,
  CommentActionTypes,
  ICommentsResponse,
  IGenericAction,
} from "./types";
import {
  POST_COMMENTS,
  GET_INBOX_REPLIES,
  PERFORM_COMMENT_ACTION,
  GET_COMMENTS_CSV,
  ASSIGN_COMMENT_USER,
  UPDATE_COMMENT_SENTIMENT,
  PERFORM_BULK_COMMENT_ACTION,
  GET_COMMENT_BY_ID,
  POST_COMMENTS_SEARCH,
  GET_INBOX_REPLIES_ATTACHMENTS,
} from "./actionTypes";
import {
  postCommentsSuccessAction,
  postCommentsFailureAction,
  getCommentByIdSuccessAction,
  getCommentByIdFailureAction,
  loadCommentsPageSuccessAction,
  performCommentActionSuccessAction,
  performCommentActionFailureAction,
  getCommentsCSVSuccessAction,
  getCommentsCSVFailureAction,
  assignCommentUserSuccessAction,
  assignCommentUserFailureAction,
  updateCommentSentimentSuccessAction,
  updateCommentSentimentFailureAction,
  performBulkCommentActionSuccessAction,
  performBulkCommentActionFailureAction,
  currentUserAssigned,
  currentUserUnassigned,
  postCommentsSearchSuccessAction,
  getInboxRepliesAttachmentsSuccessAction,
  getInboxRepliesAttachmentsFailureAction,
} from "./actions";
import {
  patchRepliesParentSentimentAction,
  patchRepliesItemsSentimentAction,
  performRepliesItemsActionSuccessAction,
  performRepliesParentActionSuccessAction,
} from "../replies/actions";

import { IRepliesStatsResponse } from "../inboxStats/types";
import { ICommentActionPayload } from "../comments/types";
import { ICommentWithPostProps } from "../comments/types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { getSubFeatureName } from "@utils/feature";
import { patchChecklistAction } from "../me/actions";
import { isTiktokPlatform, isTwitterPlatform } from "@utils/platform";
import { getAllCommentsSelector } from "./selectors";

// GET COMMENTS
export const postComments = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMMENTS)),
    switchMap(a => {
      const { isReplyModal, replacePrevPage, ...payload } = a.payload;

      return ajax
        .post<ICommentsResponse>(
          comments,
          {
            ...payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            if (!!a.payload.cursor) {
              return loadCommentsPageSuccessAction(
                data.items,
                a.payload.conversation_list_id,
                data.cursor,
                data.navigation_id,
                undefined,
                replacePrevPage,
              );
            }

            return postCommentsSuccessAction(
              data.items,
              a.payload.conversation_list_id,
              data.cursor,
              data.navigation_id,
              undefined,
              isReplyModal,
            );
          }),
          catchError(e => {
            return [
              postCommentsFailureAction(a.payload),
              handleError(e, postCommentsFailureAction),
            ];
          }),
        );
    }),
  );

export const getCommentById = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_COMMENT_BY_ID)),
    switchMap(a => {
      return ajax
        .get<ICommentWithPostProps>(
          `${BASE_URL_API}/comments/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCommentByIdSuccessAction(data)),
          catchError(e => handleError(e, getCommentByIdFailureAction)),
        );
    }),
  );

// REPLIES/INBOX
export const getInboxReplies = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_INBOX_REPLIES)),
    switchMap(a => {
      const { isReplyModal, url, replacePrevPage, ...payload } = a.payload;

      return ajax
        .post<ICommentsResponse>(
          url || repliesList(getSubFeatureName()),
          {
            ...payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            if (!!a.payload.cursor) {
              return loadCommentsPageSuccessAction(
                data.items,
                a.payload.conversation_list_id,
                data.cursor,
                data.navigation_id,
                data.info,
                replacePrevPage,
              );
            }
            return postCommentsSuccessAction(
              data.items,
              a.payload.conversation_list_id,
              data.cursor,
              data.navigation_id,
              data.info,
              isReplyModal,
            );
          }),
          catchError(e => [
            postCommentsFailureAction(a.payload),
            handleError(e, postCommentsFailureAction),
          ]),
        );
    }),
  );

export const getInboxRepliesAttachments = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_INBOX_REPLIES_ATTACHMENTS)),
    switchMap(a => {
      const { conversation_list_id } = a.payload;
      const commentsItems = getAllCommentsSelector(state$.value, conversation_list_id);
      const fetchRepliesAttachments = async () => {
        const replyItemsPromises = commentsItems.map(async comment => {
          const isTiktok = isTiktokPlatform(comment.platform_type.id);
          const isTwitter = isTwitterPlatform(comment.platform_type.id);

          if (!isTiktok && !isTwitter) return comment;

          const dmMessagesPromises = (comment.dm_messages || []).map(async dmMessage => {
            const attachment = dmMessage.attachments?.[0];
            const base64Regex = /data:image\/(jpeg|png);base64,/;

            if (
              !attachment ||
              attachment.type !== "image" ||
              (attachment.url && base64Regex.test(attachment.url))
            ) {
              return Promise.resolve(dmMessage);
            }

            const params = new URLSearchParams({
              url: attachment.url,
              ...(dmMessage.id && isTiktok && { comment_id: dmMessage.id }),
              ...(comment.asset_id && { asset_id: comment.asset_id }),
            });
            const headers = getHeaders({
              Authorization: state$.value.auth.session.accessToken.jwtToken,
            });
            const platform = comment.platform_type.id;
            const apiUrl = `${platformMediaImage(platform)}?${params.toString()}`;
            const imageResponse = await fetch(isTwitter ? attachment.url : apiUrl, { headers });
            const { image } = await imageResponse.json();

            return { ...dmMessage, attachments: [{ ...attachment, url: image }] };
          });
          const dmMessages = await Promise.all(dmMessagesPromises);

          return { ...comment, dm_messages: dmMessages };
        });
        const replyItems = await Promise.all(replyItemsPromises);

        return replyItems;
      };

      return from(fetchRepliesAttachments()).pipe(
        map(data => getInboxRepliesAttachmentsSuccessAction(data, conversation_list_id)),
        catchError(e => handleError(e, getInboxRepliesAttachmentsFailureAction)),
      );
    }),
  );

// False value actions have "un" prefix in API (i.e. unhide, unflag, unarchive...)
const sanitizeActionName = (action: AllowedCommentActionsType, value: boolean) =>
  action === "reply_uncancel" ? "reply_uncancel" : value === false ? `un${action}` : action;

const getActionPayload = (actionPayload: ICommentActionPayload) => {
  const payload = {
    action: sanitizeActionName(actionPayload.action, actionPayload.value),
    filters: actionPayload.filters,
  };

  if (getCurrentModuleName() === "care")
    return {
      ...payload,
      stats_to_return: `${getCurrentModuleName()}/${getSubFeatureName()}`,
    };

  return payload;
};

// Comment actions (hide, flag, ignore)
export const performCommentActionEpic = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PERFORM_COMMENT_ACTION)),
    mergeMap(a => {
      return ajax
        .post<IRepliesStatsResponse>(
          `${commentsActions}/${a.payload.id}/actions`,
          getActionPayload(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            const actions: IGenericAction[] = [performCommentActionSuccessAction(a.payload, data)];
            const replyData = state$.value.replies.data;

            // Update reply thread state
            if (replyData) {
              const isParent = replyData.parent.id === a.payload.id;
              const isInChildCommentThread = !!replyData.items.find(
                ({ id }) => id === a.payload.id,
              );

              if (isParent) {
                actions.push(performRepliesParentActionSuccessAction(a.payload, replyData));
              }

              if (isInChildCommentThread) {
                actions.push(performRepliesItemsActionSuccessAction(a.payload, replyData));
              }
            }

            // Update checklist hide state
            if (a.payload.action === "hide") {
              const checklist = state$.value.me.data?.checklist?.insights;
              const isAddAssetsChecked = !!checklist?.items.find(
                ({ id, checked }) => id === "hide_harmful_comment" && checked,
              );

              if (checklist && !isAddAssetsChecked)
                actions.push(patchChecklistAction(checklist, "hide_harmful_comment"));

              return actions;
            }

            return actions;
          }),
          catchError(e => handleError(e, () => performCommentActionFailureAction(a.payload.id))),
        );
    }),
  );

export const updateCommentSentiment = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(UPDATE_COMMENT_SENTIMENT)),
    switchMap(a => {
      return ajax
        .patch<unknown>(
          `${commentsActions}/${a.payload.id}/sentiment`,
          a.payload.data,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => {
            const replyData = state$.value.replies.data;

            if (replyData && replyData.parent.id === a.payload.id && a.payload.data?.sentiment) {
              return patchRepliesParentSentimentAction(
                a.payload.id,
                a.payload.data.sentiment,
                replyData,
              );
            }

            if (
              replyData &&
              replyData.items.find(r => r.id === a.payload.id) &&
              a.payload.data?.sentiment
            ) {
              return patchRepliesItemsSentimentAction(
                a.payload.id,
                a.payload.data.sentiment,
                replyData,
              );
            }

            return updateCommentSentimentSuccessAction(a.payload.id, a.payload.data);
          }),
          catchError(e => handleError(e, updateCommentSentimentFailureAction)),
        );
    }),
  );

export const assignCommentUserAssignedUsers = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(ASSIGN_COMMENT_USER)),
    switchMap(a => {
      const actions: CommentActionTypes[] = [];
      const currentUser = a.payload.userId;
      const prevUsers = state$.value.comments.previouslyAssignedUsers;
      const nextUsers = a.payload.data?.assigned_users || [];
      const urlParams = new URLSearchParams(window.location.search);
      const currentTab = urlParams.get("tab");
      const validTabs = ["assigned_to_me", "review", null];
      if (
        currentUser &&
        ["smart-inbox", "inbox"].includes(getSubFeatureName()) &&
        validTabs.includes(currentTab)
      ) {
        if (!prevUsers.includes(currentUser) && nextUsers.includes(currentUser)) {
          actions.push(currentUserAssigned());
        }
        if (prevUsers.includes(currentUser) && !nextUsers.includes(currentUser)) {
          actions.push(currentUserUnassigned());
        }
      }

      return ajax
        .patch<unknown>(
          `${commentsActions}/${a.payload.id}/users`,
          a.payload.data,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => assignCommentUserSuccessAction()),
          catchError(e => handleError(e, assignCommentUserFailureAction)),
          startWith(...actions),
        );
    }),
  );

export const getCommentsCSV = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_COMMENTS_CSV)),
    switchMap(a => {
      return ajax<Blob>({
        method: "POST",
        url: `${comments}/csv`,
        body: a.payload,
        headers: getHeaders({
          Authorization: state$.value.auth.session.accessToken.jwtToken,
        }),
        responseType: "arraybuffer",
      }).pipe(
        map(e => e.response),
        map(data => getCommentsCSVSuccessAction(data)),
        catchError(e => handleError(e, getCommentsCSVFailureAction)),
      );
    }),
  );

// Patch Bulk Comment actions
export const performBulkCommentActionEpic = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(PERFORM_BULK_COMMENT_ACTION)),
    mergeMap(a => {
      return ajax
        .patch<IRepliesStatsResponse>(
          `${commentsActions}/bulk-actions`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(() => {
            const actions: IGenericAction[] = [performBulkCommentActionSuccessAction(a.payload)];

            return actions;
          }),
          catchError(e => handleError(e, performBulkCommentActionFailureAction)),
        );
    }),
  );

// GET COMMENTS
export const postCommentsSearchEpic = (
  action$: Observable<CommentActionTypes>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMMENTS_SEARCH)),
    switchMap(a => {
      const { isReplyModal, replacePrevPage, ...payload } = a.payload;

      return ajax
        .post<ICommentsResponse>(
          commentsSearch,
          {
            ...payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            if (!!a.payload.cursor) {
              return loadCommentsPageSuccessAction(
                data.items,
                payload.conversation_list_id,
                data.cursor,
                data.navigation_id,
                undefined,
                replacePrevPage,
              );
            }

            return postCommentsSearchSuccessAction(
              data.items,
              payload.conversation_list_id,
              data.cursor,
              data.navigation_id,
              undefined,
              isReplyModal,
            );
          }),
          catchError(e => {
            return [
              postCommentsFailureAction(a.payload),
              handleError(e, postCommentsFailureAction),
            ];
          }),
        );
    }),
  );
