import React from "react";
import cn from "classnames/bind";

import { Select as AntSelect } from "antd";
import { SelectOld } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CustomSelect.module.less";

import { ISelectProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IInjectedCustomProps } from "../Filters.type";
import { IAutocompleteOption } from "@store/autocomplete/types";

interface ICustomSelectProps extends ISelectProps, IInjectedCustomProps {
  options: (IAutocompleteOption & { id: string })[];
}

// Id/Label are flipped in case of post_ids
const getIdLabelValues = (name: string, id: string, label: string) => {
  if (name === "post_ids") {
    return { id: label, label: id };
  }

  return { id, label };
};

const cx = cn.bind(s);

export const CustomSelect = (props: ICustomSelectProps) => {
  const {
    customprops: { name },
  } = props;

  // Prevent "illegal" props to DOM
  const normalizedProps = { ...props, customprops: undefined };

  return (
    <SelectOld {...normalizedProps} className={s.bbCustomSelect}>
      {props.options.map(({ id: originalId, label: originalLabel, type, platform }) => {
        const { id, label } = getIdLabelValues(name, originalId, originalLabel);

        return (
          <AntSelect.Option
            name={name}
            key={originalId}
            value={originalId}
            className={cx(s.bbOption, {
              [s.bbOptionSmall]: type && platform,
            })}
          >
            <div className={s.bbOptionInfo}>
              <span className={s.bbOptionLabel} title={label}>
                {label}
              </span>
              <span className={s.bbOptionDescription}>{id}</span>
            </div>
          </AntSelect.Option>
        );
      })}
    </SelectOld>
  );
};
