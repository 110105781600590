import React, { MouseEvent, FC } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TrustpilotLoginButton.module.less";

interface ITrustpilotLoginButtonProps {
  isLoading?: boolean;
  onClickLogin: (event: MouseEvent) => void;
}

export const TrustpilotLoginButton: FC<ITrustpilotLoginButtonProps> = ({
  isLoading,
  onClickLogin,
}) => {
  const { t } = useTranslation();

  const loading = (
    <Loading
      type="spinning"
      isLoading
      style={{
        width: 204,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  return (
    <button onClick={onClickLogin} className={s.bbTrustpilotLoginButton}>
      {isLoading ? (
        loading
      ) : (
        <span className={s.bbTrustpilotLoginButtonText}>
          {t("components:signInButtons:trustpilot")}
        </span>
      )}
    </button>
  );
};
