import { IMessageTag } from "@store/comments/types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";

export const addToTagsListing = (tags: IMessageTag[], newTag: ICustomTag) => {
  const index = tags.findIndex(item => item.id === newTag.id);
  const updatedItems = [...tags];

  if (index !== -1) {
    // Tag with the same id exists, update the count
    updatedItems[index].count += 1;
  } else {
    // Tag doesn't exist, add it with count 1
    updatedItems.push(...tags, { ...newTag, count: 1 } as IMessageTag);
  }

  return updatedItems;
};

export const removeFromTagsListing = (tags: IMessageTag[], tagIdToRemove: string) => {
  const index = tags.findIndex(item => item.id === tagIdToRemove);

  if (index !== -1) {
    const updatedItems = [...tags];

    if (updatedItems[index].count === 1) {
      // If count is 1, remove the item from the array
      updatedItems.splice(index, 1);
    } else {
      // If count is greater than 1, decrease the count
      updatedItems[index].count -= 1;
    }
    return updatedItems;
  } else {
    return [...tags];
  }
};
