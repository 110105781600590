import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useCallback, useMemo, useEffect, SyntheticEvent } from "react";

import { Button, Dropdown, TreeView, useSearch, useToggle } from "@bbdevcrew/bb_ui_kit_fe";

import { getCommentTagsAction } from "@store/commentTags/actions";
import { commentTagsSelector } from "@store/commentTags/selectors";

import { ICommentTag } from "@store/comments/types";
import { tagToOption } from "../../AppFilter/filters/Comments/helpers";

import s from "./BBTags.module.less";

import { PlusIcon } from "@assets/index";

interface IBrandBastionTagsProps {
  tags: ICommentTag[];
  onAddTag: (tag: ICommentTag) => void;
  onDeleteTag: (id: string) => void;
  type?: "default" | "custom";
}

export default function BrandBastionTags({
  tags,
  type = "default",
  onAddTag,
  onDeleteTag,
}: IBrandBastionTagsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { isToggleSelected, toggleSelected, setToggleSelected } = useToggle(false);

  const commentTags = useSelector(commentTagsSelector).filter(
    commentTag => commentTag.key !== "custom_tags" && commentTag.key !== "ai_tags",
  );

  const getCommentTags = useCallback(() => dispatch(getCommentTagsAction()), [dispatch]);

  const optionsFormatted = useMemo(() => {
    return commentTags.map(tagToOption);
  }, [commentTags]);

  const tagsFormatted = commentTags.reduce(
    (acc, tag) => {
      if (tag.children) {
        const childrenTags = tag.children.map(({ key, title, value, category }) => ({
          id: key,
          label: title,
          value,
          category,
        }));

        return [...acc, ...childrenTags];
      }

      return acc;
    },
    [] as (ICommentTag & { value: string })[],
  );

  const { options, SearchInput, searchTerm } = useSearch(optionsFormatted, t("generic:search"));

  const filteredOptions = useMemo(
    () =>
      options.filter(opt => {
        if (opt.options && searchTerm) {
          return (
            (opt.options = opt.options.filter(option =>
              option.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
            )) || opt.options
          );
        }
        return opt;
      }),
    [options, searchTerm],
  );

  const getSelectedTags = (selected?: string) => {
    const existingTags: string[] = [];
    let tag = {};

    if (selected) {
      options.forEach(option =>
        option.options?.forEach(opt => {
          if (opt.id.includes(selected.split("::")[1])) {
            existingTags.push(opt.id);
            tag = opt;
          }
        }),
      );
    } else {
      tags.forEach(_tag => {
        options.forEach(option =>
          option.options?.forEach(opt => {
            if (opt.id.split("::")[1] === _tag.id) {
              existingTags.push(opt.id);
              tag = opt;
            }
          }),
        );
      });
    }

    return { existingTags, tag };
  };

  useEffect(() => {
    setSelectedTags([...getSelectedTags().existingTags]);
    // eslint-disable-next-line
  }, [tags]);

  useEffect(() => {
    if (isToggleSelected) getCommentTags();
    // eslint-disable-next-line
  }, [isToggleSelected]);

  const handleToggleDropdown = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toggleSelected();
  };

  const onChangeSelection = (selectedValue: string) => {
    const tagId = selectedValue.split("::")[1];
    const alreadyHasTag = selectedTags.includes(selectedValue);
    const selectedTag = tagsFormatted.find(tag => tag.value === selectedValue);

    if (tagId && selectedTag) {
      const { id, label, category } = selectedTag;

      alreadyHasTag ? onDeleteTag(tagId) : onAddTag({ id, label, category });

      setSelectedTags(currentValues => {
        if (currentValues.includes(selectedValue)) {
          return currentValues.filter(value => !value.includes(selectedValue));
        }
        return [...currentValues, ...getSelectedTags(selectedValue).existingTags];
      });
    }
  };

  const getBBTagsTriggerButton = () => {
    const classnames = classNames(s.bbBBTagsTrigger, {
      [s.bbBBTagsTriggerDefault]: type === "default",
      [s.bbBBTagsTriggerCustom]: type === "custom",
      [s.bbBBTagsTriggerToggleSelected]: isToggleSelected,
    });

    const content =
      type === "custom" ? (
        <span>{t("components:comments:bbTags:customButtonBBTag")}</span>
      ) : (
        <>
          <PlusIcon />
          <span>{t("components:comments:bbTags:bbTag")}</span>
        </>
      );

    return (
      <Button _size="sm" onClick={handleToggleDropdown} className={classnames}>
        {content}
      </Button>
    );
  };

  return (
    <Dropdown
      id="bbTag-dropdown"
      open={isToggleSelected}
      setOpen={setToggleSelected}
      dropdownClassName={classNames(s.bbTagsCustomDropdown, {
        [s.bbTagsCustomDropdownDefaultClass]: type === "default",
        [s.bbTagsCustomDropdownCustomClass]: type === "custom",
      })}
      menuClassName={s.bbTagsDropdownMenu}
      trigger={getBBTagsTriggerButton()}
    >
      <div className={s.bbTagsDropdownContent}>
        <h2 className={s.bbTagsDropdownContentTitle}>{t("components:comments:bbTags:addBBTag")}</h2>
        {SearchInput}
        <div>
          <TreeView
            options={filteredOptions}
            values={selectedTags}
            onChange={selected => onChangeSelection(selected.id)}
          />
        </div>
      </div>
    </Dropdown>
  );
}
