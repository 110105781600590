import { RootState } from "..";

export const fromPlatforms = (state: RootState) => state.onboardingPlatforms;

export const onboardingPlatformsSelector = (state: RootState) => fromPlatforms(state).platforms;

export const fetchingPlatformsSelector = (state: RootState) =>
  fromPlatforms(state).fetchingPlatforms;
export const fetchedPlatformsSelector = (state: RootState) => fromPlatforms(state).fetchedPlatforms;
export const fetchedPlatformsFailSelector = (state: RootState) =>
  fromPlatforms(state).fetchedPlatformsFail;

export const fetchingPlatformSelector = (state: RootState) => fromPlatforms(state).fetchingPlatform;
export const fetchedPlatformSelector = (state: RootState) => fromPlatforms(state).fetchedPlatform;
export const fetchedPlatformFailSelector = (state: RootState) =>
  fromPlatforms(state).fetchedPlatformFail;

export const twitterRegisterTokenUrlSelector = (state: RootState) =>
  fromPlatforms(state).platforms.find(({ id }) => id === "twitter")?.redirectUrl;

export const registerTokenPendingSelector = (state: RootState) =>
  fromPlatforms(state).registeringToken;
export const registeredTokenSelector = (state: RootState) => fromPlatforms(state).registeredToken;
export const deleteTokenPendingSelector = (state: RootState) => fromPlatforms(state).deletingToken;
export const deletedTokenSelector = (state: RootState) => fromPlatforms(state).deletingToken;
