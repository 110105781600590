import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

import { platformIconName } from "@utils/platform";
import { getPlatformIcon } from "./ProfilesTable.helpers";
import { formatter } from "@components/_common/CardStat/helpers";
import { ImpressionsPlatformTableRowProps } from "./ProfilesTable.types";

const ImpressionsPlatformTableRow = React.memo(
  ({ data: row, index, columns }: ImpressionsPlatformTableRowProps) => {
    return (
      <>
        {columns.length && (
          <div
            data-cy="profiles-table-row"
            className={classNames(s.bbProfilesTableRow, {
              [s.bbProfilesTableRowColored]: index % 2 === 1,
            })}
          >
            <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
              <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
                {row?.id && getPlatformIcon(platformIconName(row.id))}
              </div>
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[1].colSpan}>
              {row?.total_impressions != null ? formatter(row?.total_impressions) : ""}
              {!!row?.total_impression_growth && (
                <PerformanceCardPill percentageValue={Number(row.total_impression_growth)} />
              )}
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[2].colSpan}>
              {row?.organic_impressions != null ? formatter(row?.organic_impressions) : ""}
              {!!row?.organic_impression_growth && (
                <PerformanceCardPill percentageValue={Number(row.organic_impression_growth)} />
              )}
            </Col>
            <Col className={s.bbColumn} data-cy="table-column" span={columns[3].colSpan}>
              {row?.paid_impressions != null ? formatter(row?.paid_impressions) : ""}
              {!!row?.paid_impression_growth && (
                <PerformanceCardPill percentageValue={Number(row.paid_impression_growth)} />
              )}
            </Col>
          </div>
        )}
      </>
    );
  },
);

ImpressionsPlatformTableRow.displayName = "ImpressionsPlatformTableRow";

export default ImpressionsPlatformTableRow;
