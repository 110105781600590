import React, { FC } from "react";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import { Card, Loading, numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";
import { PerformanceCardPill } from "../../../../insights/_common/performanceCard/Pill";

import s from "./TaggedCommentsWidget.module.less";

import { IOverviewPerformanceItem } from "@store/dashboard/types";

interface ITaggedCommentsWidgetProps {
  data: IOverviewPerformanceItem[];
  loading: boolean;
  title: string;
  subTitle: string;
  headerIcon: JSX.Element;
  headerIconType: "neutral" | "danger";
  showCalculatedPercentage?: boolean;
}

const TaggedCommentsWidget: FC<ITaggedCommentsWidgetProps> = ({
  data = [],
  loading,
  title,
  subTitle,
  headerIcon,
  headerIconType,
  showCalculatedPercentage = true,
}) => {
  const { t } = useTranslation();
  const totalTaggedComments = data.length ? data[0] : undefined;

  return (
    <Card className={s.bbTaggedCommentsCard}>
      {loading ? (
        <div className={s.bbTaggedCommentsLoading}>
          <Loading isLoading />
        </div>
      ) : data.length ? (
        <div className={s.bbTaggedComments}>
          <div className={s.bbTaggedCommentsHeader}>
            <div>
              <div className={s.bbTaggedCommentsTitle}>{title}</div>
              <div className={s.bbTaggedCommentsSubtitle}>{subTitle}</div>
            </div>
            <div
              className={classnames(
                s.bbTaggedCommentsHeaderIcon,
                s[`bbTaggedCommentsHeaderIcon-${headerIconType}`],
              )}
            >
              {headerIcon}
            </div>
          </div>
          <div className={s.bbTaggedCommentsWrapper}>
            <span className={s.bbTaggedCommentsHeaderValue}>
              {numberFormatter(totalTaggedComments?.count || 0)}
            </span>
            {showCalculatedPercentage && (
              <span className={s.bbTaggedCommentsHeaderPercentage}>
                ({totalTaggedComments?.percentage_of_total || 0}%)
              </span>
            )}
            <PerformanceCardPill
              type="total"
              percentageValue={totalTaggedComments?.change_percentage || 0}
            />
          </div>
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={t("components:comments:tableEmpty")}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </Card>
  );
};

export default TaggedCommentsWidget;
