import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import StoryMessage from "./StoryMessage";
import DirectMessage from "./DirectMessage";

import s from "./ThreadBody.module.less";

import { IThreadBodyProps } from "./ThreadBody.type";
import { isStoryAttachment } from "@utils/comments";
import { isSingleStoryComment, scrollToBottom } from "./helpers";

const ThreadBody: React.FC<IThreadBodyProps> = ({ comment, onReplyClick }) => {
  const { t } = useTranslation();

  if (comment?.dm_messages && isSingleStoryComment(comment)) {
    return <StoryMessage message={comment.dm_messages[0]} />;
  }

  return (
    <div className={s.bbThreadBody}>
      {comment.dm_stats && comment.dm_stats.grand_total_count > 10 && (
        <div className={s.bbThreadBodyShowMore}>
          {t("components:comments:hasMoreMessages")}{" "}
          <a
            onClick={() =>
              comment?.id && onReplyClick && onReplyClick(comment?.replying_comment_id)
            }
          >
            {t("components:comments:seeFullConversation")}
          </a>
        </div>
      )}
      <div className={s.bbThreadBodyContainer} ref={scrollToBottom}>
        {!!comment.dm_messages?.length &&
          comment.dm_messages.map(message => (
            <div
              className={classNames(s.bbThreadBodyMessage, {
                [s.bbThreadBodyMessageOwned]: message.owned,
                [s.bbThreadBodyMessageError]:
                  message.attachments?.length &&
                  message.attachments[0].description === "unsupported_media",
              })}
              key={message.id}
            >
              {message.type === "private_message" ? (
                <DirectMessage message={message} />
              ) : !!message.attachments?.length &&
                !!isStoryAttachment(message.attachments[0].description) ? (
                <StoryMessage message={message} />
              ) : null}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ThreadBody;
