import { TFunction } from "react-i18next";

export const getPageName = (pageName: string, collaborators: string[], t: TFunction) => {
  return collaborators.length === 0
    ? pageName
    : t("components:publish:postCreationModal:preview:page_collaborator", {
        pageName,
        count: collaborators.length,
        collaborator: collaborators[0],
      });
};
