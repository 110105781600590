import React from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./EmptyStates.module.less";

import HighfiveIcon from "@assets/Highfive.svg";

const ToReviewEmptyState = () => {
  const { t } = useTranslation();

  const redirectToOverview = () => {
    window.location.href = `${process.env.BASE_URL}/insights`;
  };

  return (
    <div className={s.bbInboxEmptyState}>
      <HighfiveIcon />
      <div className={s.bbInboxEmptyStateTitle}>
        {t("components:inboxMenu:emptyState:toReview:title")}
      </div>
      <div className={s.bbInboxEmptyStateDescription}>
        {t("components:inboxMenu:emptyState:toReview:description1")}
        <Button _type="link" className={s.bbInboxEmptyStateLink} onClick={redirectToOverview}>
          {t("components:inboxMenu:emptyState:toReview:action")}
        </Button>
        {t("components:inboxMenu:emptyState:toReview:description2")}
      </div>
    </div>
  );
};

export default ToReviewEmptyState;
