import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./FacebookLoginButton.module.less";

import { FacebookRoundColor2Icon } from "@bbdevcrew/bb_ui_kit_fe";

interface IFacebookLoginButtonProps {
  isLoading?: boolean;
  buttonText?: string;
  onClickLogin: (event: MouseEvent) => void;
}

const FacebookLoginButton: FC<IFacebookLoginButtonProps> = ({
  isLoading,
  buttonText,
  onClickLogin,
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onClickLogin}
      className={s.bbFacebookLoginButton}
      data-stonly-target="app-global__facebook-login-button"
    >
      {isLoading ? (
        <Loading
          type="spinning"
          isLoading
          style={{
            width: 204,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ) : (
        <>
          <span className={s.bbFacebookLoginButtonIcon}>
            <FacebookRoundColor2Icon />
          </span>
          <span className={s.bbFacebookLoginButtonText}>
            {buttonText || t("components:signInButtons:facebook")}
          </span>
        </>
      )}
    </div>
  );
};

export default FacebookLoginButton;
