import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { NavBadge, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import { ModuleIconMap, unreadFeatures, useAppSidebar } from "./helpers";

import s from "./AppSidebar.module.less";

import { IAppSidebarFeatureProps, IAppSideBarFeaturesProps } from "./AppSidebar.types";

import { UnlockIcon } from "@assets/index";

export const AppSidebarFeature: React.FC<IAppSidebarFeatureProps> = ({
  feature,
  moduleId,
  sectionId,
  onClick,
}) => {
  const { t } = useTranslation();
  const { isCollapsed } = useAppSidebar();

  const count = feature.counts?.find(result => result.id === "total")?.count;
  const showCount = !!(!feature.is_locked && !isCollapsed && count && count > 0);

  return (
    <div
      key={feature.id}
      className={s.bbAppSidebarFeatureWrapper}
      data-feature-id={feature.id}
      data-feature-count={count}
    >
      <Tooltip
        title={
          feature.is_locked ? t(`components:listen:sidePanel:lockedFeatureTooltip`) : undefined
        }
      >
        <div
          onClick={!feature.is_locked ? () => onClick?.(feature) : undefined}
          className={classNames(s.bbAppSidebarFeature, {
            [s.bbAppSidebarFeatureActive]: feature.id === sectionId && !feature.is_locked,
            [s.bbAppSidebarFeatureLocked]: feature.is_locked,
          })}
        >
          <span
            className={classNames(s.bbAppSidebarFeatureIcon, {
              [s.bbAppSidebarFeatureIconUnread]: unreadFeatures.includes(feature.id),
              [s.bbAppSidebarFeatureIconActive]: feature.id === sectionId && !feature.is_locked,
            })}
          >
            {!!ModuleIconMap[moduleId]?.[feature.id] && ModuleIconMap[moduleId][feature.id]}
          </span>
          <span className={s.bbAppSidebarFeatureLabel}>{!isCollapsed ? feature.label : ""}</span>

          {feature.is_locked && !isCollapsed && (
            <div className={s.bbAppSidebarFeatureLockedIcon}>
              <UnlockIcon />
            </div>
          )}
          {showCount && <NavBadge className={s.bbAppSidebarFeatureCount}>{count}</NavBadge>}
        </div>
      </Tooltip>
      {!!feature?.features && (
        <div className={s.bbAppSidebarFeatures} data-feature-id={feature.id}>
          {/* eslint-disable @typescript-eslint/no-use-before-define */}
          <AppSideBarFeatures
            features={feature.features}
            moduleId={moduleId}
            sectionId={sectionId}
            onClick={onClick}
          />
          {/* eslint-enable @typescript-eslint/no-use-before-define */}
        </div>
      )}
    </div>
  );
};

export const AppSideBarFeatures: React.FC<IAppSideBarFeaturesProps> = ({
  features,
  moduleId,
  sectionId,
  onClick,
}) => {
  return (
    <>
      {features
        ?.filter(feature => !!feature.is_visible)
        .map(feature => (
          <AppSidebarFeature
            key={feature.id}
            feature={feature}
            moduleId={moduleId}
            sectionId={sectionId}
            onClick={onClick}
          />
        ))}
    </>
  );
};
