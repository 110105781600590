import React from "react";
import { Route, Routes } from "react-router-dom";

import { LoadingView } from "@bbdevcrew/bb_ui_kit_fe";
import { TTSignup } from "@components/login/ttSignup/TTSignup";
import { DeletedView } from "@components/login/account/DeletedView";

import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";

const LoginRoutes: React.FC<IAuthenticatorProps> = props => {
  return (
    <>
      {props.authState === "signedIn" ? (
        <Routes>
          <Route path="/" element={<LoadingView isLoading type="bb" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/account-deleted" element={<DeletedView />} />
          <Route path="/tiktok" element={<TTSignup authProps={props} />} />
        </Routes>
      )}
    </>
  );
};

export default LoginRoutes;
