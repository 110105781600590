import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useState } from "react";

import { Menu } from "antd";
import Ads from "../../../_common/Social/Ads/Ads";
import { Modal, Button, Dropdown } from "@bbdevcrew/bb_ui_kit_fe";

import { clearPostAdsAction, getPostAdsAction } from "@store/posts/actions";
import { filterDataSelector } from "@store/filters/selectors";
import { getPostAdsProcessingSelector, postAdsSelector } from "@store/posts/selectors";

import s from "./PostsTable.module.less";

import { useAppFilters } from "@utils/appFilters";

import { AdIcon, PostIcon, MoreActionsIcon, CommentIcon } from "@assets/index";

interface IPostsTableRowActionsProps {
  postId: string;
  showAds: boolean;
  onOpenPostClick?: () => void;
  triggerClassName?: string;
}

const PostsTableRowActions: FC<IPostsTableRowActionsProps> = ({
  postId,
  showAds,
  onOpenPostClick,
  triggerClassName,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateSearchDataAndUrl } = useAppFilters(false);

  const [adsOpen, setAdsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const postAds = useSelector(postAdsSelector);
  const arePostAdsLoading = useSelector(getPostAdsProcessingSelector);
  const filters = useSelector(filterDataSelector);

  const fetchAds = useCallback(id => dispatch(getPostAdsAction(id)), [dispatch]);
  const clearAds = useCallback(() => dispatch(clearPostAdsAction()), [dispatch]);

  const onOpenAdsClick = () => {
    fetchAds(postId);
    setAdsOpen(true);
  };

  const onCloseAds = () => {
    clearAds();
    setAdsOpen(false);
  };

  const onExploreClick = () => {
    const newFilters = {
      ...filters,
      post_ids: [postId],
    };
    updateSearchDataAndUrl(newFilters, "id");
    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      {
        state: {
          filters: newFilters,
        },
      },
    );
  };

  return (
    <>
      <Dropdown
        triggerMode="click"
        open={isDropdownOpen}
        setOpen={setIsDropdownOpen}
        placement="bottomLeft"
        menuClassName={s.bbPostActionsDropdown}
        trigger={
          <Button _type="primary" className={classNames(s.bbMoreBtn, triggerClassName)}>
            <MoreActionsIcon />
          </Button>
        }
      >
        <Menu>
          <Menu.Item key="explore" icon={<CommentIcon />} onClick={onExploreClick}>
            {t("components:posts:table:actions:exploreComments")}
          </Menu.Item>
          {showAds && (
            <Menu.Item key="ad" icon={<AdIcon />} onClick={onOpenAdsClick}>
              {t("components:posts:table:actions:viewAd")}
            </Menu.Item>
          )}
          <Menu.Item key="post" icon={<PostIcon />} onClick={onOpenPostClick}>
            {t("components:posts:table:actions:viewPost")}
          </Menu.Item>
        </Menu>
      </Dropdown>
      <Modal
        centered
        hideFooter
        width={800}
        title={t("generic:ads")}
        open={adsOpen}
        onCancel={() => onCloseAds()}
      >
        <Ads
          isLoading={arePostAdsLoading}
          ads={postAds}
          texts={{
            adLabel: t("components:comments:ads:table:adLabel"),
            adSetLabel: t("components:comments:ads:table:adSetLabel"),
            campaignLabel: t("components:comments:ads:table:campaignLabel"),
            spinText: t("pages:loading:spinText"),
          }}
        />
      </Modal>
    </>
  );
};

export default PostsTableRowActions;
