import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useCallback, useEffect } from "react";

import { Avatar } from "antd";
import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import Body from "../../CommentCard/cardBody/CommentCardBody";

import { getCommentSelector, getCommentByIdFetchingSelector } from "@store/comments/selectors";
import { getCommentByIdAction } from "@store/comments/actions";

import s from "./ReplyingCommentPreview.module.less";

import { LoadingOutlined } from "@ant-design/icons";
import { extractDate } from "@utils/dates";
import { cropText } from "@utils/cropText";
import { COMMENT_MAX_LENGTH } from "../../CommentsGrid/CommentsGridItem";
import { getPlatformIconBasicColor } from "@utils/platform";
import { formatTime, getCommentSourceInfo } from "../../CommentCard/cardHeader/helpers";

interface IReplyingCommentPreviewProps {
  commentId: string;
}

const ReplyingCommentPreview: FC<IReplyingCommentPreviewProps> = ({ commentId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const comment = useSelector(getCommentSelector);
  const commentLoading = useSelector(getCommentByIdFetchingSelector);

  const getCommentById = useCallback(
    (id: string) => dispatch(getCommentByIdAction(id)),
    [dispatch],
  );

  useEffect(() => {
    getCommentById(commentId);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!!comment && !commentLoading && (
        <div className={s.bbReplyingCommentPreview}>
          <>
            <div>
              <div className={classNames(s[`bbCommentSentimentBg-${comment.sentiment}`])}>
                <div className={s.bbContainerSentiment}></div>
              </div>
              <div>
                <div className={s.bbUsername}>{comment.username}</div>

                <div className={s.bbDateWrapper}>
                  <span className={s.bbCommentDate}>
                    {extractDate(comment.created_time_pretty)}
                  </span>
                  <span>{t("generic:at")}</span>{" "}
                  <span>{formatTime(comment.created_time_pretty)}</span>
                </div>

                <div className={s.bbCommentSourceInfo}>
                  {getCommentSourceInfo(comment.comment_phrase || "")}
                </div>
                <Body showSeeMore={false} comment={comment} maxLengthComment={COMMENT_MAX_LENGTH} />
              </div>
            </div>
            {comment.post && (
              <div className={s.bbReplyingCommentPostPreview}>
                <div>
                  <div className={s.bbReplyingCommentPostPreviewWrapper}>
                    <div>
                      <Avatar
                        size={72}
                        shape="square"
                        style={{ fontSize: "12px" }}
                        src={comment.post?.picture || ""}
                        className={s.bbReplyingCommentPostImagePreview}
                      >
                        {t("generic:noImage")}
                      </Avatar>
                    </div>

                    <div className={s.bbReplyingCommentPostPreviewDetails}>
                      <div className={s.bbReplyingCommentPostPreviewDetailsWrapper}>
                        <div className={s.bbReplyingCommentPostPreviewPlatform}>
                          {comment.post.platform_type.id &&
                            getPlatformIconBasicColor(comment.post.platform_type.id)}
                        </div>
                        <div>
                          <div className={s.bbReplyingCommentPostAuthor}>
                            {comment.post.author_name}
                          </div>
                          <div className={s.bbReplyingCommentPostDate}>
                            {extractDate(comment.post?.created_time_pretty)}
                          </div>
                        </div>
                      </div>
                      <div className={s.bbReplyingCommentPostMessage}>
                        {cropText(comment.post.message, 10)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      )}
      {commentLoading && (
        <div className={s.bbReplyingCommentPreviewLoading}>
          <Loading
            isLoading
            type="spinning"
            tip={t("pages:loading:spinText")}
            indicator={<LoadingOutlined />}
          />
        </div>
      )}
    </>
  );
};

export default ReplyingCommentPreview;
