import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, forwardRef } from "react";

import { Grid } from "antd";
import Filters from "./Filters";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import { SavedFilter } from "./filters/SavedFilters/SavedFilter";

import { predefinedFiltersSelector } from "@store/savedFilters/selectors";
import { saveFilterAction, toggleOpenFiltersPanelAction } from "@store/filters/actions";
import { filtersOpenSelector, filtersPanelWidthSelector } from "@store/filters/selectors";
import { isAIInsightsModalExpanded } from "@store/aiInsights/selectors";

import s from "./AppFilters.module.less";

import { IFormHandler } from "./Filters.type";
import { IFilters } from "@store/filters/types";
import { ISavedFilter, IAppFiltersProps } from "./AppFilters.type";

import { ChevronRightIcon, ChevronLeftIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { FolderIcon } from "@assets/index";

const AppFilters = forwardRef<IFormHandler, IAppFiltersProps>(
  (
    {
      clientData,
      hasNewSidenav,
      customFilters,
      hideSavedFilters,
      initialFilterData,
      hideFilterButtons,
      isBottomReached = false,
      className,
      onFilter,
      onResetFilters,
      onSavedFilterChange,
      onSavedStickyChange,
      setStickyFilterValue,
    },
    filtersRef,
  ) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const screens = Grid.useBreakpoint();
    const isMobileView = !screens.md;

    const filtersPanelOpen = useSelector(filtersOpenSelector);
    const filtersPanelWidth = useSelector(filtersPanelWidthSelector);
    const predefinedFilters = useSelector(predefinedFiltersSelector);
    const isAIModalOpen = useSelector(isAIInsightsModalExpanded);

    const filtersFormSizes = {
      width: isMobileView ? "100%" : isAIModalOpen && !filtersPanelOpen ? 0 : filtersPanelWidth,
      height: !filtersPanelOpen ? "100%" : undefined,
    };

    const toggleFiltersPanel = useCallback(
      (open: boolean) => dispatch(toggleOpenFiltersPanelAction(open)),
      [dispatch],
    );

    const setSavedFilterValue = useCallback(
      (id: string | number | null) => dispatch(saveFilterAction(id)),
      [dispatch],
    );

    useEffect(() => {
      if (initialFilterData.saved_filter && predefinedFilters.length) {
        // It's a sticky filter -> id = 'dsf766-sad324-asd3'
        if (Number.isNaN(Number(initialFilterData.saved_filter))) {
          setStickyFilterValue(initialFilterData.saved_filter);
        } else {
          setSavedFilterValue(Number(initialFilterData.saved_filter));
        }

        onSavedStickyChange(initialFilterData.saved_filter);
      }
      // eslint-disable-next-line
    }, [initialFilterData, predefinedFilters]);

    const onInternalFilter = (
      filterRequest: IFilters,
      param?: string | ISavedFilter | undefined,
    ) => {
      setSavedFilterValue(null);
      setStickyFilterValue(null);
      onFilter(filterRequest, param);
    };

    const renderFilterButtons = () => {
      return (
        <div
          style={{ width: isMobileView ? "100%" : filtersPanelWidth }}
          className={classNames(
            s.bbAppFilterFooterButtons,
            {
              [s.bbAppFilterFooterButtonsMobile]: isMobileView,
              [s.bbAppFilterFooterButtonsWithBottomReached]: !isMobileView && isBottomReached,
            },
            className,
          )}
        >
          <Button
            _size="sm"
            _type="secondary"
            type="submit"
            id="reset-filters"
            onClick={onResetFilters}
            className={s.bbFiltersResetBtn}
            data-cy="reset-filters-button"
            data-stonly-target="app-global__filters--clear-all-button"
          >
            {t("generic:clearAll")}
          </Button>
          <Button
            _size="sm"
            _type="primary"
            type="submit"
            id="apply-filter"
            className={s.bbFiltersApplyBtn}
            data-cy="apply-filters-button"
            data-stonly-target="app-global__filters--apply-button"
          >
            {t("components:filters:applyBtn")}
          </Button>
        </div>
      );
    };

    return (
      <div
        className={classNames(s.bbAppFiltersWrapper, {
          [s.bbAppFiltersWrapperDesktop]: !isMobileView && !isAIModalOpen,
          [s.bbAppFiltersWrapperDesktopCollapsed]:
            !isMobileView && !filtersPanelOpen && !isAIModalOpen,
          [s.bbAppFiltersWrapperMobile]: isMobileView,
          [s.bbAppFiltersWrapperDesktopWithBottomReached]: !isMobileView && isBottomReached,
          [s.bbAppFiltersWrapperDesktopWithAIModal]: !isMobileView && isAIModalOpen,
        })}
      >
        <div
          className={classNames(s.bbAppFiltersForm, {
            [s.bbAppFiltersFormNoMenu]: hasNewSidenav,
          })}
          style={filtersFormSizes}
          data-cy="app-filters-form"
        >
          {!isMobileView && !isAIModalOpen && (
            <Button
              className={s.bbToggleFiltersPanelBtn}
              style={{ right: filtersPanelWidth - 7 }}
              onClick={() => toggleFiltersPanel(!filtersPanelOpen)}
            >
              {filtersPanelOpen ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </Button>
          )}
          {(filtersPanelOpen || isMobileView) && (
            <Filters
              ref={filtersRef}
              onFilter={onInternalFilter}
              customFilters={customFilters}
              initialFilterData={initialFilterData}
              filterPlacement={!screens.md ? "left" : "right"}
              clientPlatformTypes={clientData?.platform_types || []}
              className={s.bbAppFiltersFormWrapper}
            >
              {{
                prefix: (
                  <div className={s.bbSavedFilterWrapper}>
                    <div className={s.bbSavedFilterTitle}>{t("generic:filters")}</div>
                    {hideSavedFilters ? null : (
                      <SavedFilter onSavedChange={onSavedFilterChange}>
                        {({ open }) => (
                          <Button
                            type="button"
                            _type="secondary"
                            className={classNames(s.bbSavedFilterSelect, {
                              [s.bbSavedFilterSelectActive]: open,
                            })}
                          >
                            <FolderIcon />
                          </Button>
                        )}
                      </SavedFilter>
                    )}
                  </div>
                ),
                suffix: <>{!hideFilterButtons ? renderFilterButtons() : null}</>,
              }}
            </Filters>
          )}
        </div>
      </div>
    );
  },
);

AppFilters.displayName = "AppFilters";

export default AppFilters;
