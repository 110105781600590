import { IInboxStats } from "@store/inboxStats/types";
import { ISideNavItemNested } from "./types";
import { CheckIcon, ISideNavItem } from "@bbdevcrew/bb_ui_kit_fe";
import { InboxIcon, ReplyIcon, StarIcon, ArchiveIcon, HourGlassIcon } from "@assets/index";

export interface IInboxOptions {
  statKey: keyof Omit<IInboxStats, "stats">;
  icon: string;
  nested?: boolean;
  label?: string;
  showBadge?: boolean;
  showAiTag?: boolean;
}

export function useDefaultInboxOptions() {
  const options: IInboxOptions[] = [
    {
      statKey: "all",
      icon: InboxIcon,
    },
    {
      statKey: "review",
      icon: HourGlassIcon,
    },
    {
      statKey: "starred",
      icon: StarIcon,
    },
    {
      statKey: "done",
      icon: CheckIcon,
    },
    {
      statKey: "replied",
      icon: ReplyIcon,
      nested: true,
    },
    {
      statKey: "ignored",
      icon: ArchiveIcon,
      nested: true,
    },
  ];

  return { options };
}

export function mapNestedOptionsToChildren(input: ISideNavItemNested[]): ISideNavItem[] {
  const result = input.reduce((acc: ISideNavItem[], item) => {
    if (item.nested) {
      const lastNonNestedItem = acc[acc.length - 1];
      if (!lastNonNestedItem.children) {
        lastNonNestedItem.children = [];
      }
      lastNonNestedItem.children.push(item);
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  return result;
}
