import React from "react";

import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { EditIcon, SettingsIcon } from "@assets/index";

export const ICON_MAPPING = {
  edit: <EditIcon />,
  delete: <DeleteIcon />,
  set_default: <SettingsIcon />,
};
