import { RootState } from "..";

export const fromNotifications = (state: RootState) => state.notifications;

// Integrations
export const getNotificationsSelector = (state: RootState) =>
  fromNotifications(state).notifications;
export const getNotificationsPendingSelector = (state: RootState) =>
  fromNotifications(state).fetchingNotifications;
export const getNotificationsSuccessfulSelector = (state: RootState) =>
  fromNotifications(state).fetchedNotifications;
export const getNotificationsFailureSelector = (state: RootState) =>
  fromNotifications(state).fetchingNotificationsFail;
