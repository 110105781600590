import React from "react";

import { i18nextInstance } from "../../../App";
import { BarProps } from "recharts";

import { IStatsProps } from "@store/overview/types";

import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";
import { IFilters } from "@store/filters/types";

export const stripePattern = (
  <>
    <pattern
      id="pattern-stripe"
      width="8"
      height="8"
      patternUnits="userSpaceOnUse"
      patternTransform="rotate(45)"
    >
      <rect width="4" height="8" transform="translate(0,0)" fill="white"></rect>
    </pattern>
    <mask id="mask-stripe">
      <rect x="0" y="0" width="100%" height="100%" fill="url(#pattern-stripe)" />
    </mask>
  </>
);

export const getSentimentBars = () => [
  {
    dataKey: i18nextInstance.t<keyof BarProps>("components:postTrends:sentiments:positive"),
    fill: palette.colorPositive,
    stackId: "stack",
  },
  {
    dataKey: i18nextInstance.t<keyof BarProps>("components:postTrends:sentiments:negative"),
    fill: palette.colorNegative,
    stackId: "stack",
  },
  {
    dataKey: i18nextInstance.t<keyof BarProps>("components:postTrends:sentiments:user_tags"),
    fill: palette.colorUserTags,
    stackId: "stack",
  },
  {
    dataKey: i18nextInstance.t<keyof BarProps>("components:postTrends:sentiments:neutral"),
    fill: palette.colorNeutral,
    stackId: "stack",
  },
  {
    dataKey: i18nextInstance.t<keyof BarProps>("components:postTrends:sentiments:not_applicable"),
    fill: palette.colorNotApplicable,
    shape: <rect mask="url(#mask-stripe)" x={10} y={10} width={10} height={10} />,
    stackId: "stack",
  },
];

export const generateStatsData = (data: IStatsProps, includeTitle = true) => {
  const statsData: { [key: string]: string | number | undefined } = {
    name: includeTitle
      ? (i18nextInstance.t("components:postTrends:titleGraphPost") as string)
      : undefined,
  };

  for (const key in data) {
    if (key !== "total")
      statsData[i18nextInstance.t(`components:postTrends:sentiments:${key}`)] =
        data[key as keyof IStatsProps];
  }

  return [statsData];
};

export const formatDateEnUs = (d: string | Date, format = "dd-MMM-yyyy") => {
  const date = new Date(d);

  const year = new Intl.DateTimeFormat("en-US", { year: "numeric" }).format(date);
  const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  const day = new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(date);

  if (format === "dd MMM, yyyy") {
    return `${day} ${month}, ${year}`;
  }

  return `${day}-${month}-${year}`;
};

export const calculateDateRange = (range: string) => {
  const today = new Date();
  let from: Date | undefined = new Date();
  let to: Date | undefined = new Date();

  if (range === "last_30_days") {
    from.setDate(today.getDate() - 29);
  } else if (range === "last_7_days") {
    from.setDate(today.getDate() - 6);
  } else if (range === "last_12_months") {
    from.setFullYear(today.getFullYear() - 1);
    from.setMonth(today.getMonth());
    from.setDate(today.getDate());
  } else if (range === "current_day") {
    to = undefined;
  } else if (range === "yesterday") {
    from.setDate(today.getDate() - 1);
    to = undefined;
  } else {
    from = undefined;
    to = undefined;
  }

  const fromDate = from ? formatDateEnUs(from) : from;
  const toDate = to ? formatDateEnUs(to) : to;

  return { fromDate, toDate };
};

export const getPDFName = (filters?: IFilters, clientName?: string) => {
  let from,
    to,
    fromString = "",
    toString = "";

  if (filters?.start_time && filters?.end_time) {
    from = formatDateEnUs(filters.start_time);
    to = formatDateEnUs(filters.end_time);
  } else if (filters?.data_range_option) {
    const { fromDate, toDate } = calculateDateRange(filters?.data_range_option);
    from = fromDate;
    to = toDate;
  }

  fromString = from ? "_" + from : "";
  toString = to ? "_" + to : "";

  return `Report_${clientName}${fromString}${toString}`;
};
