import { IZendeskTicket } from "@store/integrations/types";

export type LinkUserModalProps = {
  isOpen: boolean;
  ticket: IZendeskTicket;
  onClose: () => void;
};

export enum LinkZendeskUserTabs {
  ExistingZendeskUsers = "ExistingZendeskUsers",
  CreateNewZendeskUser = "CreateNewZendeskUser",
}
