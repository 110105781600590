import { FormProps } from "rc-field-form";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useMemo, useState } from "react";

import { FormInstance } from "antd";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";
import { Input, IObjectOption, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";

import { messageStatuses, messageStatusesLabels } from "./MessageStatus.helpers";
import { IMultiSelectFilterOption } from "@store/me/types";
import { DropdownMenuPlacementType, handleMultiselectState } from "@bbdevcrew/bb_ui_kit_fe";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

interface IMessageStatusFiltersProps extends Omit<FormProps, "onChange"> {
  placeholder?: string;
  InputVariation?: typeof Input;
  dropdownPlacement?: DropdownMenuPlacementType;
  dropdownMatchSelectWidth?: boolean;
  customprops?: { form?: FormInstance };
  onChange?: (messageTypes?: string[]) => void;
}

export const MessageStatusFilters: FC<IMessageStatusFiltersProps> = ({
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  InputVariation = Input,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();

  const [filterValues, setFilterValues] = useState<string[]>([]);

  const optionsFormatted = useMemo(() => {
    const messageStatusOption = ({
      id,
      label,
      items,
    }: IMultiSelectFilterOption): IObjectOption => ({
      id: id,
      label: label,
      options: items?.map(messageStatusOption),
    });

    return messageStatuses?.map(messageStatusOption) || [];
  }, []);

  const onInternalChange = (messageTypeValue: string) => {
    const newState = handleMultiselectState(optionsFormatted, filterValues, messageTypeValue);

    setFilterValues(newState);

    if (customprops?.form) {
      onChange?.(newState || undefined);
    }
  };

  useEffect(() => {
    setFilterValues(customprops?.form?.getFieldValue("message_statuses") || []);
    // eslint-disable-next-line
  }, []);

  const _onClear = () => {
    setFilterValues([]);

    if (customprops?.form) onChange?.(undefined);
  };

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    if (filterValues.length === 0) {
      return ClearableTrigger(tooltipProps);
    }

    const tooltipElement = filterValues.map(value => (
      <span key={value} style={{ display: "block" }}>
        {messageStatusesLabels[value]}
      </span>
    ));

    return ClearableTrigger({
      tooltip: tooltipElement,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <SelectStyledMulti
      _size="sm"
      id={props.id}
      value={filterValues}
      options={optionsFormatted}
      onChange={onInternalChange}
      InputVariation={InputVariation}
      dropdownPlacement={dropdownPlacement}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      TriggerVariation={TriggerWithTooltip}
      data-cy={props["data-cy" as keyof typeof props]}
      excludePlaceholder={t("generic:exclude")}
      excludedPlaceholder={t("generic:excluded")}
      placeholder={placeholder || t("components:filters:message_statuses:placeholder")}
    />
  );
};
