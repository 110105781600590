import { RootState } from "../";

export const fromAIPrompts = (state: RootState) => state.repliesAIPrompts;

export const AIPromptsSelector = (state: RootState) => fromAIPrompts(state).AIPrompts;

export const creatingAIPromptSelector = (state: RootState) => fromAIPrompts(state).postingAIPrompt;
export const createdAIPromptSuccessfulSelector = (state: RootState) =>
  fromAIPrompts(state).postedAIPrompt;

export const updatingAIPromptSelector = (state: RootState) => fromAIPrompts(state).updatingAIPrompt;
export const updatedAIPromptSuccessfulSelector = (state: RootState) =>
  fromAIPrompts(state).updatedAIPrompt;

export const AIPromptSuggestionSelector = (state: RootState) => fromAIPrompts(state).AISuggestion;
export const fetchingAIPromptSuggestionSelector = (state: RootState) =>
  fromAIPrompts(state).fetchingPromptAISuggestion;
export const fetchedAIPromptSuggestionSelector = (state: RootState) =>
  fromAIPrompts(state).fetchedPromptAISuggestion;
