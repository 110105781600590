import React from "react";
import cn from "classnames/bind";

import { IAudioAttachmentProps } from "./Variants.types";

import s from "../Attachment.module.less";

const cx = cn.bind(s);

const AudioAttachment: React.FC<IAudioAttachmentProps> = ({
  url,
  audioClassName,
  ...restProps
}) => {
  return (
    <div className={cx(s.bbAudioPlayerWrapper, audioClassName)}>
      <audio controls src={url} {...restProps} />
    </div>
  );
};

export default AudioAttachment;
