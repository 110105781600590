export const GET_POST_TAGS = "POST_TAGS/GET_POST_TAGS";
export const GET_POST_TAGS_SUCCESS = "POST_TAGS/GET_POST_TAGS_SUCCESS";
export const GET_POST_TAGS_FAILURE = "POST_TAGS/GET_POST_TAGS_FAILURE";

export const ADD_POST_TAG = "POST_TAGS/ADD_POST_TAG";
export const ADD_POST_TAG_SUCCESS = "POST_TAGS/ADD_POST_TAG_SUCCESS";
export const ADD_POST_TAG_FAILURE = "POST_TAGS/ADD_POST_TAG_FAILURE";

export const DELETE_POST_TAG = "CUSTOM_TAGS/DELETE_POST_TAG";
export const DELETE_POST_TAG_SUCCESS = "POST_TAGS/DELETE_POST_TAG_SUCCESS";
export const DELETE_POST_TAG_FAILURE = "POST_TAGS/DELETE_POST_TAG_FAILURE";

export const DELETE_TAG_FROM_POST = "POST_TAGS/DELETE_POST_TAG_FROM_POST";
export const DELETE_TAG_FROM_POST_SUCCESS = "POST_TAGS/DELETE_TAG_FROM_POST_SUCCESS";
export const DELETE_TAG_FROM_POST_FAILURE = "POST_TAGS/DELETE_TAG_FROM_POST_FAILURE";
