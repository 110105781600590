import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { AlertWarningAccent } from "@bbdevcrew/bb_ui_kit_fe";

import { meSelector } from "@store/me/selectors";

import s from "./Disclaimers.module.less";

function ReconnectAssetsDisclaimer() {
  const { t } = useTranslation();
  const me = useSelector(meSelector);

  return (
    <AlertWarningAccent
      size="large"
      align="center"
      className={s.bbDisclaimer}
      message={me?.asset_alert?.message || ""}
      action={
        <a
          className={s.bbReconnectAssetsDisclaimerAction}
          href={`${process.env.BASE_URL}/settings/asset-management`}
        >
          {t("components:assetManagementList:reconnect")}
        </a>
      }
    />
  );
}

export default ReconnectAssetsDisclaimer;
