import i18n from "i18next";

import { ZendeskTicketStatus, ZendeskTicketPriority } from "@store/integrations/types";

const NEW_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:new"),
  id: ZendeskTicketStatus.New,
};
const OPEN_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:open"),
  id: ZendeskTicketStatus.Open,
};
const PENDING_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:pending"),
  id: ZendeskTicketStatus.Pending,
};
const HOLD_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:hold"),
  id: ZendeskTicketStatus.Hold,
};
const SOLVED_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:solved"),
  id: ZendeskTicketStatus.Solved,
};
const CLOSED_STATUS_OPTION = {
  label: i18n.t("components:zendeskForm:status:options:closed"),
  id: ZendeskTicketStatus.Closed,
};

const statusesByCurrentStatus = {
  [ZendeskTicketStatus.New]: [
    NEW_STATUS_OPTION,
    OPEN_STATUS_OPTION,
    PENDING_STATUS_OPTION,
    HOLD_STATUS_OPTION,
    SOLVED_STATUS_OPTION,
  ],
  [ZendeskTicketStatus.Open]: [
    OPEN_STATUS_OPTION,
    PENDING_STATUS_OPTION,
    HOLD_STATUS_OPTION,
    SOLVED_STATUS_OPTION,
  ],
  [ZendeskTicketStatus.Pending]: [
    PENDING_STATUS_OPTION,
    OPEN_STATUS_OPTION,
    HOLD_STATUS_OPTION,
    SOLVED_STATUS_OPTION,
  ],
  [ZendeskTicketStatus.Hold]: [HOLD_STATUS_OPTION, OPEN_STATUS_OPTION, SOLVED_STATUS_OPTION],
  [ZendeskTicketStatus.Solved]: [SOLVED_STATUS_OPTION, OPEN_STATUS_OPTION, CLOSED_STATUS_OPTION],
  [ZendeskTicketStatus.Closed]: [CLOSED_STATUS_OPTION],
};

export const getStatusOptions = (currentStatus: ZendeskTicketStatus) =>
  statusesByCurrentStatus[currentStatus] || [];

export const priorityOptions = [
  {
    label: i18n.t("components:zendeskForm:priority:options:urgent"),
    id: ZendeskTicketPriority.Urgent,
  },
  {
    label: i18n.t("components:zendeskForm:priority:options:high"),
    id: ZendeskTicketPriority.High,
  },
  {
    label: i18n.t("components:zendeskForm:priority:options:normal"),
    id: ZendeskTicketPriority.Normal,
  },
  {
    label: i18n.t("components:zendeskForm:priority:options:low"),
    id: ZendeskTicketPriority.Low,
  },
];
