export const POST_COMMENTS = "COMMENTS/POST_COMMENTS";
export const POST_COMMENTS_SUCCESS = "COMMENTS/POST_COMMENTS_SUCCESS";
export const POST_COMMENTS_FAILURE = "COMMENTS/POST_COMMENTS_FAILURE";

export const GET_COMMENT_BY_ID = "COMMENTS/GET_COMMENT_BY_ID";
export const GET_COMMENT_BY_ID_SUCCESS = "COMMENTS/GET_COMMENT_BY_ID_SUCCESS";
export const GET_COMMENT_BY_ID_FAILURE = "COMMENTS/GET_COMMENT_BY_ID_FAILURE";

export const ASSIGN_COMMENT_USER = "COMMENTS/ASSIGN_COMMENT_USER";
export const ASSIGN_COMMENT_USER_SUCCESS = "COMMENTS/ASSIGN_COMMENT_USER_SUCCESS";
export const ASSIGN_COMMENT_USER_FAILURE = "COMMENTS/ASSIGN_COMMENT_USER_FAILURE";

export const CURRENT_USER_ASSIGNED = "COMMENTS/CURRENT_USER_ASSIGNED";
export const CURRENT_USER_UNASSIGNED = "COMMENTS/CURRENT_USER_UNASSIGNED";

export const UPDATE_COMMENT_SENTIMENT = "COMMENTS/UPDATE_COMMENT_SENTIMENT";
export const UPDATE_COMMENT_SENTIMENT_SUCCESS = "COMMENTS/UPDATE_COMMENT_SENTIMENT_SUCCESS";
export const UPDATE_COMMENT_SENTIMENT_FAILURE = "COMMENTS/UPDATE_COMMENT_SENTIMENT_FAILURE";

export const TRANSLATE_COMMENT = "COMMENTS/TRANSLATE_COMMENT";

export const LOAD_COMMENTS_PAGE_SUCCESS = "COMMENTS/LOAD_COMMENTS_PAGE_SUCCESS";

export const GET_INBOX_REPLIES = "REPLIES/GET_INBOX_REPLIES";
export const GET_INBOX_REPLIES_PAGE = "REPLIES/GET_INBOX_REPLIES_PAGE";

export const GET_INBOX_REPLIES_ATTACHMENTS = "REPLIES/GET_INBOX_REPLIES_ATTACHMENTS";
export const GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS =
  "REPLIES/GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS";
export const GET_INBOX_REPLIES_ATTACHMENTS_FAILURE =
  "REPLIES/GET_INBOX_REPLIES_ATTACHMENTS_FAILURE";

export const PERFORM_COMMENT_ACTION = "COMMENTS/PERFORM_COMMENT_ACTION";
export const PERFORM_COMMENT_ACTION_SUCCESS = "COMMENTS/PERFORM_COMMENT_ACTION_SUCCESS";
export const PERFORM_COMMENT_ACTION_FAILURE = "COMMENTS/PERFORM_COMMENT_ACTION_FAILURE";

export const GET_COMMENTS_CSV = "COMMENTS/GET_COMMENTS_CSV";
export const GET_COMMENTS_CSV_SUCCESS = "COMMENTS/GET_COMMENTS_CSV_SUCCESS";
export const GET_COMMENTS_CSV_FAILURE = "COMMENTS/GET_COMMENTS_CSV_FAILURE";
export const RESET_COMMENTS_CSV_STATE = "COMMENTS/RESET_COMMENTS_CSV_STATE";

export const PERFORM_BULK_COMMENT_ACTION = "COMMENTS/PERFORM_BULK_COMMENT_ACTION";
export const PERFORM_BULK_COMMENT_ACTION_SUCCESS = "COMMENTS/PERFORM_BULK_COMMENT_ACTION_SUCCESS";
export const PERFORM_BULK_COMMENT_ACTION_FAILURE = "COMMENTS/PERFORM_BULK_ACTION_FAILURE";

// TRIGGER NEXT BATCH OF COMMENTS
export const TRIGGER_NEXT_BATCH = "COMMENTS/TRIGGER_NEXT_BATCH";

export const POST_COMMENTS_SEARCH = "COMMENTS/POST_COMMENTS_SEARCH";
export const POST_COMMENTS_SEARCH_SUCCESS = "COMMENTS/POST_COMMENTS_SEARCH_SUCCESS";
export const POST_COMMENTS_SEARCH_FAILURE = "COMMENTS/POST_COMMENTS_SEARCH_FAILURE";
