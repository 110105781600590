import React from "react";

import s from "./DayOfWeek.module.less";

import { IHoliday } from "@store/publishings/types";

interface IHolidaysProps {
  holidays: IHoliday[];
}

export default function Holidays({ holidays }: IHolidaysProps) {
  return (
    <div className={s.bbHolidays}>
      {holidays.map(holiday => (
        <div className={s.bbHolidayChip} key={holiday.id}>
          {holiday.name}
        </div>
      ))}
    </div>
  );
}
