import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { NotificationTrigger } from "./NotificationTrigger";
import { Button, Dropdown } from "@bbdevcrew/bb_ui_kit_fe";
import { NotificationsEmpty } from "./NotificationsEmpty";
import { NotificationsContent } from "./NotificationsContent";

import { getNotificationsSelector } from "@store/notifications/selectors";

import { markAllAsReadAction } from "@store/notifications/actions";

import s from "./Notifications.module.less";

import { CheckIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const notifications = useSelector(getNotificationsSelector);
  const unreadNotifications = notifications.filter(({ status }) => status === "new");

  const isEmpty = notifications.length === 0;

  const onMarkAllAsRead = useCallback(() => dispatch(markAllAsReadAction()), [dispatch]);

  return (
    <>
      <Dropdown
        open={isOpen}
        setOpen={setIsOpen}
        placement="bottomRight"
        menuClassName={s.bbNotifications}
        trigger={<NotificationTrigger isPanelOpen={isOpen} count={unreadNotifications.length} />}
      >
        <div className={s.bbNotificationsHeader}>
          <span className={s.bbNotificationsTitle}>{t("header:notifications:title")}</span>
          {!isEmpty && (
            <Button _size="sm" _type="link" onClick={onMarkAllAsRead}>
              <CheckIcon />
              <span>{t("header:notifications:markAsRead")}</span>
            </Button>
          )}
        </div>
        {isEmpty ? <NotificationsEmpty /> : <NotificationsContent />}
      </Dropdown>
    </>
  );
};
