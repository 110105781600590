import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SimpleSelectSingle } from "@bbdevcrew/bb_ui_kit_fe";

import { searchClientAction } from "@store/careCps/actions";
import { careCpsClientsSelector } from "@store/careCps/selectors";

import { IClientSelectProps } from "./StreamsModal.types";
import { IClient } from "@store/careCps/types";

export const ClientSelect: React.FC<IClientSelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedClient, setSelectedClient] = useState<IClient>();
  const [query, setQuery] = useState("");

  const { data: clients = [], fetching } = useSelector(careCpsClientsSelector);

  const searchOptions = useMemo(() => {
    return clients.map(client => ({
      label: client.name,
      value: client.id,
    }));
  }, [clients]);

  const clientOptions = useMemo(() => {
    const options = [...searchOptions];
    if (selectedClient && !options.find(option => option.value === selectedClient.id)) {
      options.push({ label: selectedClient.name, value: selectedClient.id });
    }
    return options;
  }, [searchOptions, selectedClient]);

  useEffect(() => {
    if (query.length > 2) {
      dispatch(searchClientAction(query));
    }
  }, [query, dispatch]);

  useEffect(() => {
    if (value) {
      const client = clients.find(c => c.id === value);
      if (client) setSelectedClient(client);
    }
  }, [value, clients]);

  return (
    <SimpleSelectSingle
      placeholder={t("generic:select")}
      searchInputPlaceholder={t("generic:search")}
      options={clientOptions}
      showResultsBeforeSearch
      onSearch={q => setQuery(q || "")}
      emptyStatePlaceholder={t("generic:emptyTable")}
      value={value}
      onChange={onChange}
      optionsLoading={fetching}
    />
  );
};
