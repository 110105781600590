import React, { FC } from "react";

import { AreaChart } from "@bbdevcrew/bb_ui_kit_fe";
import { ISentimentsTrendProps } from "./SentimentCard.type";

import { areas, XAxisProps, YAxisProps, responsiveProps, normalizeChartData } from "./helper";

type ISentimentChartProps = Pick<ISentimentsTrendProps, "data" | "type">;

export const SentimentChart: FC<ISentimentChartProps> = ({ data, type }) => {
  const chartData = normalizeChartData(data, type);

  return (
    <AreaChart
      id={type}
      key={type}
      areas={areas}
      data={chartData}
      isZoomable={false}
      strokeWidth={1}
      curveType="linear"
      XAxisProps={XAxisProps}
      YAxisProps={YAxisProps}
      responsiveProps={responsiveProps}
    />
  );
};
