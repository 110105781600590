import { action } from "typesafe-actions";
import {
  GET_CAPTIONS_AI_PROMPTS,
  GET_CAPTIONS_AI_PROMPTS_FAILURE,
  GET_CAPTIONS_AI_PROMPTS_SUCCESS,
  CREATE_CAPTIONS_AI_PROMPT,
  CREATE_CAPTIONS_AI_PROMPT_FAILURE,
  CREATE_CAPTIONS_AI_PROMPT_SUCCESS,
  UPDATE_CAPTIONS_AI_PROMPT,
  UPDATE_CAPTIONS_AI_PROMPT_FAILURE,
  UPDATE_CAPTIONS_AI_PROMPT_SUCCESS,
  DELETE_CAPTIONS_AI_PROMPT,
  DELETE_CAPTIONS_AI_PROMPT_FAILURE,
  DELETE_CAPTIONS_AI_PROMPT_SUCCESS,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE,
  CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION,
  CLEAR_CAPTIONS_AI_PROMPT,
} from "./actionTypes";
import {
  ICaptionsAIPrompt,
  ICaptionsAIPromptsResponse,
  ICaptionsAIPromptCommentPayload,
} from "./types";

export const createCaptionsPromptAction = (AIPrompt: ICaptionsAIPrompt) =>
  action(CREATE_CAPTIONS_AI_PROMPT, AIPrompt);
export const createCaptionsPromptSuccessAction = (AIPrompt: ICaptionsAIPrompt) =>
  action(CREATE_CAPTIONS_AI_PROMPT_SUCCESS, AIPrompt);
export const createCaptionsPromptFailureAction = () => action(CREATE_CAPTIONS_AI_PROMPT_FAILURE);

export const getCaptionsPromptsAction = (query?: string) =>
  action(GET_CAPTIONS_AI_PROMPTS, { query });
export const getCaptionsPromptsSuccessAction = (data: ICaptionsAIPromptsResponse) =>
  action(GET_CAPTIONS_AI_PROMPTS_SUCCESS, data);
export const getCaptionsPromptsFailureAction = () => action(GET_CAPTIONS_AI_PROMPTS_FAILURE);

export const updateCaptionsPromptAction = (AIPrompt: ICaptionsAIPrompt) =>
  action(UPDATE_CAPTIONS_AI_PROMPT, AIPrompt);
export const updateCaptionsPromptSuccessAction = (AIPrompt: ICaptionsAIPrompt) =>
  action(UPDATE_CAPTIONS_AI_PROMPT_SUCCESS, AIPrompt);
export const updateCaptionsPromptFailureAction = () => action(UPDATE_CAPTIONS_AI_PROMPT_FAILURE);

export const deleteCaptionsPromptAction = (id: string) => action(DELETE_CAPTIONS_AI_PROMPT, id);
export const deleteCaptionsPromptSuccessAction = (id: string) =>
  action(DELETE_CAPTIONS_AI_PROMPT_SUCCESS, id);
export const deleteCaptionsPromptFailureAction = () => action(DELETE_CAPTIONS_AI_PROMPT_FAILURE);

export const setCaptionsPromptAsDefaultAction = (id: string) =>
  action(SET_CAPTIONS_AI_PROMPT_AS_DEFAULT, id);
export const setCaptionsPromptAsDefaultSuccessAction = (id: string) =>
  action(SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS, id);
export const setCaptionsPromptAsDefaultFailureAction = () =>
  action(SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE);

export const getCaptionsPromptAISuggestionAction = (payload: ICaptionsAIPromptCommentPayload) =>
  action(GET_CAPTIONS_PROMPT_AI_SUGGESTION, payload);
export const getCaptionsPromptAISuggestionSuccessAction = (suggestion: string) =>
  action(GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS, suggestion);
export const getCaptionsPromptAISuggestionFailureAction = () =>
  action(GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE);

export const clearCaptionsAISuggestionAction = () => action(CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION);
export const clearCaptionsPromptAction = () => action(CLEAR_CAPTIONS_AI_PROMPT);
