import { Dayjs } from "dayjs";
import { pdfjs } from "react-pdf";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useState, useEffect, useCallback, useContext } from "react";

import Week from "./Week";
// import Month from "./helpers/Month";
import CalendarContext from "./context/CalendarContext";

import { getPublishingsListAction } from "@store/publishings/actions";

import {
  createdPostPublishingSelector,
  creatingPostPublishingFailedSelector,
  scheduledPostPublishingSelector,
  scheduledUpdatedPostSelector,
  updatedPostPublishingSelector,
  postPublishingFailedMessageSelector,
  updatingPostPublishingSelectorFailure,
} from "@store/publishings/selectors";

import s from "./Calendar.module.less";

import { getTimespanTzDates, getWeek } from "./helpers/utils";
import { IPublishingsListPayload } from "@store/publishings/types";

// loading the pre-packaged version of pdfjs to initialize a worker in the browser
// needed to display any pdf's showing in the calendar and post creation modal
// eslint-disable-next-line
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Calendar: FC = () => {
  const dispatch = useDispatch();

  const { weekIndex } = useContext(CalendarContext);

  const [currentWeek, setCurrentWeek] = useState<Dayjs[]>(getWeek());

  const getPublishingsList = useCallback(
    (data: IPublishingsListPayload) => dispatch(getPublishingsListAction(data)),
    [dispatch],
  );

  const postPublished = useSelector(createdPostPublishingSelector);
  const postScheduled = useSelector(scheduledPostPublishingSelector);
  const postPublishingFailed = useSelector(creatingPostPublishingFailedSelector);

  const editPostPublished = useSelector(updatedPostPublishingSelector);
  const editPostScheduled = useSelector(scheduledUpdatedPostSelector);
  const editFailedMessage = useSelector(postPublishingFailedMessageSelector);
  const editPostPublishingFailed = useSelector(updatingPostPublishingSelectorFailure);

  const getSelectedWeekPublishings = useCallback(() => {
    const weekArray = getWeek(weekIndex);
    setCurrentWeek(weekArray);

    const { start_time, end_time } = getTimespanTzDates(weekArray);
    getPublishingsList({ start_time, end_time });
  }, [weekIndex, setCurrentWeek, getPublishingsList]);

  useEffect(() => {
    getSelectedWeekPublishings();
  }, [
    postPublished,
    postScheduled,
    postPublishingFailed,
    editPostPublished,
    editPostScheduled,
    editPostPublishingFailed,
    editFailedMessage,
    weekIndex,
    getSelectedWeekPublishings,
  ]);

  return (
    <div className={s.bbCalendarWrapper}>
      <div className={s.bbCalendar}>
        <Week week={currentWeek} />
        {/* uncomment when we need the month view */}
        {/* <Month month={currentMonth} /> */}
      </div>
    </div>
  );
};

export default Calendar;
