import dayjs from "dayjs";
import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DownloadCSV.module.less";

import { downloadAsCSV } from "@utils/csv";
import { CSVDataUnitType, IDownloadCSVProps } from "./DownloadCSV.type";

import { DownloadIcon } from "@assets/index";

const DownloadCSV: FC<IDownloadCSVProps> = ({
  data,
  icon,
  disabled,
  tableHeaders,
  fileNamePrefix,
}) => {
  const { t } = useTranslation();

  const downloadCSV = () => {
    const now = dayjs();
    const csvData: CSVDataUnitType[] = [];
    const dataCopy = [...data];

    dataCopy.forEach(item => {
      csvData.push(Object.values(item));
    });

    downloadAsCSV(
      csvData,
      tableHeaders,
      `${fileNamePrefix}_${now.format("YYYY-MM-DD")}_${now.format("hh_mm_A")}.csv`,
    );
  };

  return (
    <Tooltip title={!disabled && t("generic:downloadCSV")}>
      <div
        onClick={() => !disabled && downloadCSV()}
        className={classNames(s.bbDownloadAreaChartIcon, {
          [s.bbDownloadAreaChartIconDisabled]: disabled,
        })}
      >
        {icon || <DownloadIcon />}
      </div>
    </Tooltip>
  );
};

export default DownloadCSV;
