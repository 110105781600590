import React from "react";
import { useTranslation } from "react-i18next";

import { AlertDangerAccent } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Disclaimers.module.less";

import { CreditCardIcon } from "@assets/index";

interface IPaymentFailedDisclaimerProps {
  onCloseDisclaimer: () => void;
}

function PaymentFailedDisclaimer({ onCloseDisclaimer }: IPaymentFailedDisclaimerProps) {
  const { t } = useTranslation();

  return (
    <AlertDangerAccent
      size="medium"
      align="center"
      className={s.bbDisclaimer}
      onClose={onCloseDisclaimer}
      message={t("components:paymentFailed:message")}
      icon={
        <span className={s.bbPaymentFailedIcon}>
          <CreditCardIcon />
        </span>
      }
      action={
        <a
          className={s.bbPaymentFailedAction}
          href={`${process.env.BASE_URL}/settings/subscription`}
        >
          {t("components:paymentFailed:action")}
        </a>
      }
    />
  );
}

export default PaymentFailedDisclaimer;
