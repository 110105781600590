import { getBreakdownChartBarColor } from "@components/_common/BreakdownChart/helpers";
import { IDashboardFollowersPlatform, IDashboardImpressionsPlatform } from "@store/dashboard/types";

export const normalizePlatformData = <
  T extends IDashboardFollowersPlatform | IDashboardImpressionsPlatform,
>(
  platformData: T[],
  valueKey: keyof T,
) => {
  return platformData
    .map((item, index) => {
      const growth =
        "total_impression_growth" in item
          ? Number(item.total_impression_growth)
          : Number(item.growth);

      return {
        value: Number(item[valueKey]),
        rawName: item.id,
        name: item.name,
        color: getBreakdownChartBarColor(item.id, index),
        change_percentage: growth,
        percentage_of_total: Number(item.percentage),
      };
    })
    .sort((a, b) => (b.rawName > a.rawName ? -1 : 1));
};
