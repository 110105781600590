import { createReducer, ActionType } from "typesafe-actions";
import { IAssetGroupsState } from "./types";
import {
  getAssetGroupActionSuccess,
  createAssetGroupActionSuccess,
  updateAssetGroupActionSuccess,
  deleteAssetGroupActionSuccess,
} from "./actions";
import {
  GET_ASSET_GROUP,
  GET_ASSET_GROUP_FAILURE,
  GET_ASSET_GROUP_SUCCESS,
  CREATE_ASSET_GROUP,
  CREATE_ASSET_GROUP_SUCCESS,
  CREATE_ASSET_GROUP_FAILURE,
  UPDATE_ASSET_GROUP,
  UPDATE_ASSET_GROUP_SUCCESS,
  UPDATE_ASSET_GROUP_FAILURE,
  DELETE_ASSET_GROUP,
  DELETE_ASSET_GROUP_SUCCESS,
  DELETE_ASSET_GROUP_FAILURE,
} from "./actionTypes";

const initialState = {
  groups: [],
  fetchingGetAssetGroups: false,
  fetchedGetAssetGroups: false,
  fetchedGetAssetGroupsFail: false,

  fetchingPostAssetGroups: false,
  fetchedPostAssetGroups: false,
  fetchedPostAssetGroupsFail: false,

  fetchingPutAssetGroups: false,
  fetchedPutAssetGroups: false,
  fetchedPutAssetGroupsFail: false,

  fetchingDeleteAssetGroups: false,
  fetchedDeleteAssetGroups: false,
  fetchedDeleteAssetGroupsFail: false,
};

export const assetGroupsReducer = createReducer<IAssetGroupsState>(initialState, {
  [GET_ASSET_GROUP]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingGetAssetGroups: true,
    fetchedGetAssetGroups: false,
    fetchedGetAssetGroupsFail: false,
  }),
  [GET_ASSET_GROUP_SUCCESS]: (
    state: IAssetGroupsState,
    action: ActionType<typeof getAssetGroupActionSuccess>,
  ) => ({
    ...state,
    groups: action.payload,
    fetchingGetAssetGroups: false,
    fetchedGetAssetGroups: true,
    fetchedGetAssetGroupsFail: false,
  }),
  [GET_ASSET_GROUP_FAILURE]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingGetAssetGroups: false,
    fetchedGetAssetGroups: false,
    fetchedGetAssetGroupsFail: true,
  }),
  [CREATE_ASSET_GROUP]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingPostAssetGroups: true,
    fetchedPostAssetGroups: false,
    fetchedPostAssetGroupsFail: false,
  }),
  [CREATE_ASSET_GROUP_SUCCESS]: (
    state: IAssetGroupsState,
    action: ActionType<typeof createAssetGroupActionSuccess>,
  ) => ({
    ...state,
    groups: [...state.groups, action.payload],
    fetchingPostAssetGroups: false,
    fetchedPostAssetGroups: true,
    fetchedPostAssetGroupsFail: false,
  }),
  [CREATE_ASSET_GROUP_FAILURE]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingPostAssetGroups: false,
    fetchedPostAssetGroups: false,
    fetchedPostAssetGroupsFail: true,
  }),
  [UPDATE_ASSET_GROUP]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingPutAssetGroups: true,
    fetchedPutAssetGroups: false,
    fetchedPutAssetGroupsFail: false,
  }),
  [UPDATE_ASSET_GROUP_SUCCESS]: (
    state: IAssetGroupsState,
    action: ActionType<typeof updateAssetGroupActionSuccess>,
  ) => {
    const updatedGroups = [...state.groups];
    const groupIndex = updatedGroups.findIndex(({ id }) => id === action.payload.id);

    if (groupIndex > -1) {
      updatedGroups[groupIndex] = action.payload;
    }

    return {
      ...state,
      groups: updatedGroups,
      fetchingPutAssetGroups: false,
      fetchedPutAssetGroups: true,
      fetchedPutAssetGroupsFail: false,
    };
  },
  [UPDATE_ASSET_GROUP_FAILURE]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingPutAssetGroups: false,
    fetchedPutAssetGroups: false,
    fetchedPutAssetGroupsFail: true,
  }),
  [DELETE_ASSET_GROUP]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingDeleteAssetGroups: true,
    fetchedDeleteAssetGroups: false,
    fetchedDeleteAssetGroupsFail: false,
  }),
  [DELETE_ASSET_GROUP_SUCCESS]: (
    state: IAssetGroupsState,
    action: ActionType<typeof deleteAssetGroupActionSuccess>,
  ) => ({
    ...state,
    groups: state.groups.filter(({ id }) => id !== action.payload),
    fetchingDeleteAssetGroups: false,
    fetchedDeleteAssetGroups: true,
    fetchedDeleteAssetGroupsFail: false,
  }),
  [DELETE_ASSET_GROUP_FAILURE]: (state: IAssetGroupsState) => ({
    ...state,
    fetchingDeleteAssetGroups: false,
    fetchedDeleteAssetGroups: false,
    fetchedDeleteAssetGroupsFail: true,
  }),
});
