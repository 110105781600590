import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import DownloadReportModal from "@components/_common/DownloadReportModal";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DownloadButton.module.less";

import {
  IShareReportForm,
  ReportSectionType,
} from "@components/_common/DownloadReportModal/DownloadReportModal.types";
import {
  openReportPreview,
  useShortenedSearchesId,
} from "@components/managed/Transparency/ShareReportButton/ShareReportButton.helpers";

import { DownloadIcon } from "@assets/index";

const reportSections = ["overview", "sentiment_details", "topics"];
const reportSectionsMap = {
  overview: ["sentiment_preview", "message_types"],
  sentiment_details: ["sentiment_extended"],
  topics: ["topics"],
};

interface IDownloadButtonProps {
  disabled: boolean;
}

export const DownloadButton: FC<IDownloadButtonProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { id, savedFilterId } = useShortenedSearchesId();

  const [showDownloadModal, setShowDownloadModal] = useState(false);

  const downloadReport = useCallback(
    ({ name, sections }: IShareReportForm) => {
      if (id || savedFilterId) {
        const selectedSections = Object.entries(sections)
          .filter(([, value]) => value.checked)
          .map(([key]) => key)
          .flatMap(
            section => reportSectionsMap[section as keyof typeof reportSectionsMap] || [],
          ) as ReportSectionType[];

        openReportPreview({
          path: "insights/pdf_export/overview",
          ...(id && { id }),
          ...(savedFilterId && { saved_filter_id: savedFilterId }),
          name,
          sections: selectedSections,
        });
        setShowDownloadModal(false);
      }
    },
    [id, savedFilterId, setShowDownloadModal],
  );

  return (
    <>
      <Button
        _size="sm"
        _type="on-surface"
        disabled={disabled}
        onClick={() => !disabled && setShowDownloadModal(true)}
        className={s.bbDownloadReportButton}
      >
        <DownloadIcon />
        {t("generic:download")}
      </Button>
      <DownloadReportModal
        open={showDownloadModal}
        onCancel={() => setShowDownloadModal(false)}
        onFinish={downloadReport}
        sections={reportSections as ReportSectionType[]}
      />
    </>
  );
};
