import { createReducer, ActionType } from "typesafe-actions";
import { IHidingConfigState, IConfigData } from "./types";
import { getHidingConfigActionSuccess, updateHidingConfigActionSuccess } from "./actions";
import {
  GET_HIDING_CONFIG,
  GET_HIDING_CONFIG_SUCCESS,
  GET_HIDING_CONFIG_FAILURE,
  UPDATE_HIDING_CONFIG,
  UPDATE_HIDING_CONFIG_SUCCESS,
  UPDATE_HIDING_CONFIG_FAILURE,
} from "./actionTypes";

const initialState = {
  data: {} as IConfigData,
  fetchingHidingConfig: false,
  fetchedHidingConfig: false,
  fetchedHidingConfigFail: false,
  updatingHidingConfig: false,
  updatedHidingConfig: false,
  updatedHidingConfigFail: false,
};

export const hidingConfigReducer = createReducer<IHidingConfigState>(initialState, {
  [GET_HIDING_CONFIG]: (state: IHidingConfigState) => ({
    ...state,
    fetchingHidingConfig: true,
    fetchedHidingConfig: false,
    fetchedHidingConfigFail: false,
  }),
  [GET_HIDING_CONFIG_SUCCESS]: (
    state: IHidingConfigState,
    action: ActionType<typeof getHidingConfigActionSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingHidingConfig: false,
    fetchedHidingConfig: true,
    fetchedHidingConfigFail: false,
  }),
  [GET_HIDING_CONFIG_FAILURE]: (state: IHidingConfigState) => ({
    ...state,
    fetchingHidingConfig: false,
    fetchedHidingConfig: false,
    fetchedHidingConfigFail: true,
  }),
  [UPDATE_HIDING_CONFIG]: (state: IHidingConfigState) => ({
    ...state,
    fetchingHidingConfig: true,
    fetchedHidingConfig: false,
    fetchedHidingConfigFail: false,
  }),
  [UPDATE_HIDING_CONFIG_SUCCESS]: (
    state: IHidingConfigState,
    action: ActionType<typeof updateHidingConfigActionSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingHidingConfig: false,
    fetchedHidingConfig: true,
    fetchedHidingConfigFail: false,
  }),
  [UPDATE_HIDING_CONFIG_FAILURE]: (state: IHidingConfigState) => ({
    ...state,
    fetchingHidingConfig: false,
    fetchedHidingConfig: false,
    fetchedHidingConfigFail: true,
  }),
});
