import { createReducer } from "typesafe-actions";
import { DELETE_ACCOUNT, DELETE_ACCOUNT_FAILURE, DELETE_ACCOUNT_SUCCESS } from "./actionTypes";
import { IAccountState } from "./types";

const initialState = {
  deletingAccount: false,
  deletedAccount: false,
  deletedAccountFail: false,
};

export const accountsReducer = createReducer<IAccountState>(initialState, {
  [DELETE_ACCOUNT]: (state: IAccountState) => ({
    ...state,
    deletingAccount: true,
    deletedAccount: false,
    deletedAccountFail: false,
  }),
  [DELETE_ACCOUNT_SUCCESS]: (state: IAccountState) => ({
    ...state,
    deletingAccount: false,
    deletedAccount: true,
    deletedAccountFail: false,
  }),
  [DELETE_ACCOUNT_FAILURE]: (state: IAccountState) => ({
    ...state,
    deletingAccount: false,
    deletedAccount: false,
    deletedAccountFail: true,
  }),
});
