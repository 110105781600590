import { useTranslation } from "react-i18next";
import React, { FC, useState } from "react";

import { Grid } from "antd";
import CommentAction from "../commentAction/CommentAction";
import { DeleteCommentConfirmationModal } from "../../../DeleteCommentConfirmationModal";

import { IComment } from "@store/comments/types";
import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { PostPlacementEnum } from "@components/_common/Social/Post/Post.type";

interface IActionDeleteProps {
  className?: string;
  hideLabel?: boolean;
  comment: IComment;
  onDeleteClick?: (comment: IComment) => void;
}

const ActionDelete: FC<IActionDeleteProps> = ({
  comment,
  onDeleteClick,
  hideLabel = false,
  className = "",
}) => {
  const { t } = useTranslation();

  const screens = Grid.useBreakpoint();
  const hasPermission = comment?.actions.can_hide_unhide;
  const isDisabled = comment?.is_deleted || !hasPermission;

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState(false);

  const getTooltip = () => {
    if (comment.is_deleted) {
      return {
        style: { width: 250 },
        title:
          comment.post_placement === PostPlacementEnum.REVIEW
            ? t("components:comments:tooltips:deleted_review_info")
            : t("components:comments:tooltips:deleted_info"),
      };
    }

    if (!hasPermission) {
      return { title: t("permissions:delete") };
    }

    return { title: t("components:comments:tooltips:delete") };
  };

  const handleToggleConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(prev => !prev);
  };

  const handleConfirmDeleteComment = () => {
    onDeleteClick?.(comment);
    handleToggleConfirmationModal();
  };

  return (
    <>
      <CommentAction
        className={className}
        onClick={handleToggleConfirmationModal}
        tooltipProps={getTooltip()}
        active={comment.is_deleted}
        type={isDisabled ? "disabled" : "primary"}
        loading={comment.actionsProcessing?.includes("delete")}
      >
        <DeleteIcon />
        {screens.sm && !hideLabel && (
          <>
            {comment.is_deleted ? (
              <span>{t("components:comments:actions:deleted")}</span>
            ) : (
              <span>{t("components:comments:actions:delete")}</span>
            )}
          </>
        )}
      </CommentAction>
      <DeleteCommentConfirmationModal
        isOpen={isDeleteConfirmationModalOpen}
        onCancel={handleToggleConfirmationModal}
        onConfirm={handleConfirmDeleteComment}
      />
    </>
  );
};

export default ActionDelete;
