import { RootState } from "..";

export const fromCareCPS = (state: RootState) => state.careCps;

const careCpsSelector = (state: RootState) => fromCareCPS(state);

export const careCpsClientsSelector = (state: RootState) => careCpsSelector(state).clients;

export const careCpsTagsSelector = (state: RootState) => careCpsSelector(state).tags;

export const careCpsModerationUsersSelector = (state: RootState) =>
  careCpsSelector(state).moderationUsers;

export const careCpsCreateStreamSelector = (state: RootState) =>
  careCpsSelector(state).createStream;

export const careCpsDeleteStreamSelector = (state: RootState) =>
  careCpsSelector(state).deleteStream;

export default careCpsSelector;
