import React from "react";

import CalendarContext from "./AIPromptsContext";

import { IAIPromptsContextWrapper } from "./types";

export default function AIPromptsContextWrapper({
  children,
  fetchAIPrompts,
  deleteAIPrompt,
  setDefaultAIPrompt,
  getAIPromptSuggestion,
}: IAIPromptsContextWrapper) {
  return (
    <CalendarContext.Provider
      value={{
        fetchAIPrompts,
        deleteAIPrompt,
        setDefaultAIPrompt,
        getAIPromptSuggestion,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}
