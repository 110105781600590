import React from "react";
import { useTranslation } from "react-i18next";
import { useBatchMode } from "./BatchMode";
import { Button, CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { BatchStackIcon } from "@assets/index";

import s from "./BatchMode.module.less";

export default function BatchModeButton() {
  const { isBatchModeActive, toggleBatchMode } = useBatchMode();
  const { t } = useTranslation();

  return (
    <Button _size="sm" onClick={toggleBatchMode} className={s.bbBatchModeButton}>
      {isBatchModeActive ? (
        <>
          <CloseIcon />
          {t("containers:batchMode:button:exitBatchMode")}
        </>
      ) : (
        <>
          <BatchStackIcon />
          {t("containers:batchMode:button:batchMode")}
        </>
      )}
    </Button>
  );
}
