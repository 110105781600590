import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";

import { Button, AlertWarning } from "@bbdevcrew/bb_ui_kit_fe";
import SubscriptionModal from "../SubscriptionModal/SubscriptionModal";

import { getBillingUrlSelector, getBillingUrlSelectorSuccessful } from "@store/checkout/selectors";
import { meSelector } from "@store/me/selectors";
import { getBillingUrlAction } from "@store/checkout/actions";

import s from "./Disclaimers.module.less";

import { isDiffMoreThanAYear } from "./helpers";

function CommentLimitDisclaimer() {
  let message = "";
  let actionText = "";

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const me = useSelector(meSelector);
  const billingUrl = useSelector(getBillingUrlSelector);
  const billingUrlSuccess = useSelector(getBillingUrlSelectorSuccessful);

  const isAnnualySubscribed = isDiffMoreThanAYear(
    me?.client?.subscription?.period_start,
    me?.client?.subscription?.period_end,
  );

  const getBillingUrl = useCallback(() => dispatch(getBillingUrlAction()), [dispatch]);

  useEffect(() => {
    if (billingUrlSuccess && billingUrl) window.open(billingUrl, "_blank");
  }, [billingUrlSuccess, billingUrl]);

  const CTA = me?.client?.subscription?.limits?.upgrade_cta;

  switch (CTA) {
    case "pricing_table":
      message = t("components:reachedCommentLimit:trial:message");
      actionText = t("components:reachedCommentLimit:trial:action");
      break;
    case "customer_portal":
      message = t("components:reachedCommentLimit:starter:message");
      actionText = t("components:reachedCommentLimit:starter:action");
      break;
    case "email":
      message = t("components:reachedCommentLimit:advanced:message");
      actionText = t("components:reachedCommentLimit:advanced:action");
      break;
    default:
      break;
  }

  const onActionBtnClick = () => {
    if (CTA === "pricing_table") setShowSubscriptionModal(true);
    else if (CTA === "customer_portal") getBillingUrl();
    else if (CTA === "email") window.open("mailto:help@brandbastion.com", "_blank");
  };

  if (isAnnualySubscribed) {
    message = t("components:reachedCommentLimit:annually:message");
  }

  return (
    <div>
      {me?.client?.subscription?.limits?.exceeded && message && actionText ? (
        <AlertWarning
          size="large"
          message={message}
          className={s.bbDisclaimer}
          action={
            <Button _size="sm" onClick={onActionBtnClick} className={s.bbDisclaimerActionBtn}>
              {actionText}
            </Button>
          }
        />
      ) : null}
      <SubscriptionModal
        isVisible={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
      />
    </div>
  );
}

export default CommentLimitDisclaimer;
