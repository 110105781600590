import { createAction } from "typesafe-actions";

import {
  GET_DOCUMENT,
  GET_DOCUMENT_FAILURE,
  GET_DOCUMENT_SUCCESS,
  GET_DOCUMENT_URL,
  GET_DOCUMENT_URL_SUCCESS,
  GET_DOCUMENT_URL_FAILURE,
} from "./actionTypes";
import { IDocumentPayload, IDocumentUrlPayload } from "./types";

export const getDocumentsAction = createAction(GET_DOCUMENT)();
export const getDocumentsSuccess = createAction(GET_DOCUMENT_SUCCESS)<IDocumentPayload["items"]>();
export const getDocumentsFailure = createAction(GET_DOCUMENT_FAILURE)();

export const getDocumentsUrlAction = createAction(GET_DOCUMENT_URL)<string>();
export const getDocumentsUrlSuccess = createAction(GET_DOCUMENT_URL_SUCCESS)<IDocumentUrlPayload>();
export const getDocumentsUrlFailure = createAction(GET_DOCUMENT_URL_FAILURE)();
