import React from "react";

import { Row, Col } from "antd";
import { ReviewsWidgetVolume } from "./ReviewsWidgetVolume";
import { ReviewsDistribution } from "./ReviewsDistribution";
import { PlatformComparison } from "./PlatformComparison";

import { IReviewsWidgetProps } from "./ReviewsWidget.types";
import { sortedRatingDistribution } from "./ReviewWidget.helpers";

export const ReviewsWidget = ({ data, loading }: IReviewsWidgetProps) => {
  return (
    <Col xs={24} id="review-rating-section">
      <Row gutter={[28, 26]}>
        <ReviewsWidgetVolume data={data.volume} loading={loading} />
        <ReviewsDistribution
          data={sortedRatingDistribution(data.ratingDistribution || [])}
          loading={loading}
        />
        <PlatformComparison data={data.platformComparison} loading={loading} />
      </Row>
    </Col>
  );
};
