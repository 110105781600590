import React from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { Row, Col } from "antd";
import s from "./TTSignup.module.less";

import { LoadingOutlined } from "@ant-design/icons";

export const TTSignupLoading = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col xs={12} sm={8} md={8} lg={6} xl={4}>
        <div className={s.bbContainerLoading}>
          <Loading
            isLoading
            size="large"
            tip={t("pages:loading.spinText")}
            indicator={<LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />}
          />
        </div>
      </Col>
    </Row>
  );
};
