import { useTranslation } from "react-i18next";
import React, { FC, useMemo, MouseEvent } from "react";

import CommentAction from "../commentAction/CommentAction";

import { IActionLikeProps } from "./types";
import { isTiktokPlatform } from "@utils/platform";

import { ThumbsUpIcon, HeartIcon, ThumbsUpFilledIcon, HeartFilledIcon } from "@assets/index";

const LikeIconMap = {
  default: [ThumbsUpIcon, ThumbsUpFilledIcon],
  tiktok: [HeartIcon, HeartFilledIcon],
};

const ActionLike: FC<IActionLikeProps> = ({ comment, onLikeClick, className = "" }) => {
  const { t } = useTranslation();

  const tooltipTitle: string | JSX.Element = useMemo(() => {
    if (comment.is_liked) {
      return t("components:comments:tooltips:likeRemove");
    } else return t("components:comments:tooltips:like");
  }, [comment, t]);

  const onClickLike = (event: MouseEvent) => {
    event.preventDefault();
    onLikeClick && onLikeClick(comment.id, !comment.is_liked);
  };

  const [LikeIcon, LikedIcon] =
    LikeIconMap[isTiktokPlatform(comment.platform_type.id) ? "tiktok" : "default"];

  return (
    <CommentAction
      type="primary"
      className={className}
      active={comment.is_liked}
      loading={comment.actionsProcessing?.includes("like")}
      onClick={onClickLike}
      tooltipProps={{
        title: tooltipTitle,
      }}
    >
      {comment.is_liked ? <LikedIcon /> : <LikeIcon />}
    </CommentAction>
  );
};

export default ActionLike;
