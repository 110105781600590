import { createReducer, ActionType } from "typesafe-actions";
import {
  getDashboardFollowersActionSuccess,
  getDashboardImpressionsActionSuccess,
  postDashboardActionSuccess,
} from "./actions";
import { IDashboardState } from "./types";
import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
  GET_DASHBOARD_FOLLOWERS,
  GET_DASHBOARD_FOLLOWERS_SUCCESS,
  GET_DASHBOARD_FOLLOWERS_FAILURE,
  GET_DASHBOARD_IMPRESSIONS,
  GET_DASHBOARD_IMPRESSIONS_FAILURE,
  GET_DASHBOARD_IMPRESSIONS_SUCCESS,
} from "./actionTypes";

const initialState = {
  dashboard: undefined,
  fetchingDashboard: false,
  fetchedDashboard: false,
  fetchedDashboardFail: false,

  followers: undefined,
  fetchingFollowers: false,
  fetchedFollowers: false,
  fetchedFollowersFail: false,

  impressions: undefined,
  fetchingImpressions: false,
  fetchedImpressions: false,
  fetchedImpressionsFail: false,
};

export const dashboardReducer = createReducer<IDashboardState>(initialState, {
  //GET_DASHBOARD
  [GET_DASHBOARD]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: true,
    fetchedDashboard: false,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof postDashboardActionSuccess>,
  ) => ({
    ...state,
    dashboard: { ...state.dashboard, ...action.payload },
    fetchingDashboard: false,
    fetchedDashboard: true,
    fetchedDashboardFail: false,
  }),
  [GET_DASHBOARD_FAILURE]: (state: IDashboardState) => ({
    ...state,
    fetchingDashboard: false,
    fetchedDashboard: false,
    fetchedDashboardFail: true,
  }),
  [GET_DASHBOARD_FOLLOWERS]: (state: IDashboardState) => ({
    ...state,
    fetchingFollowers: true,
    fetchedFollowers: false,
    fetchedFollowersFail: false,
  }),
  [GET_DASHBOARD_FOLLOWERS_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof getDashboardFollowersActionSuccess>,
  ) => ({
    ...state,
    followers: action.payload,
    fetchingFollowers: false,
    fetchedFollowers: true,
    fetchedFollowersFail: false,
  }),
  [GET_DASHBOARD_FOLLOWERS_FAILURE]: (state: IDashboardState) => ({
    ...state,
    followers: undefined,
    fetchingFollowers: false,
    fetchedFollowers: false,
    fetchedFollowersFail: true,
  }),
  [GET_DASHBOARD_IMPRESSIONS]: (state: IDashboardState) => ({
    ...state,
    fetchingImpressions: true,
    fetchedImpressions: false,
    fetchedImpressionsFail: false,
  }),
  [GET_DASHBOARD_IMPRESSIONS_SUCCESS]: (
    state: IDashboardState,
    action: ActionType<typeof getDashboardImpressionsActionSuccess>,
  ) => ({
    ...state,
    impressions: action.payload,
    fetchingImpressions: false,
    fetchedImpressions: true,
    fetchedImpressionsFail: false,
  }),
  [GET_DASHBOARD_IMPRESSIONS_FAILURE]: (state: IDashboardState) => ({
    ...state,
    impressions: undefined,
    fetchingImpressions: false,
    fetchedImpressions: false,
    fetchedImpressionsFail: true,
  }),
});
