import cn from "classnames/bind";
import React, { FC } from "react";

import { Tooltip } from "antd";

import s from "./PerformanceCard.module.less";

import { IPerformanceCardPillProps } from "./types";

import ArrowUpIcon from "@assets/ArrowUp.svg";
import ArrowDownIcon from "@assets/ArrowDown.svg";

const cx = cn.bind(s);

export const PerformanceCardPill: FC<IPerformanceCardPillProps> = ({
  type,
  percentageValue,
  percentageTooltipProps,
  showPercentSymbol = true,
}) => {
  const getChangeArrow = () => {
    if (percentageValue === 0) return null;
    else {
      const isNegativeSentiment = type === "negative";

      if (percentageValue && percentageValue < 0)
        return (
          <span
            className={cx(s.bbChangeArrow, {
              [s.bbChangeArrowNegative]: !isNegativeSentiment,
            })}
          >
            <ArrowDownIcon />
          </span>
        );
      else
        return (
          <span
            className={cx(s.bbChangeArrow, {
              [s.bbChangeArrowNegative]: isNegativeSentiment,
            })}
          >
            <ArrowUpIcon />
          </span>
        );
    }
  };

  const getSentiment = (changePercentage: number) => {
    return changePercentage === 0
      ? "neutral"
      : type === "negative"
        ? changePercentage < 0
          ? "positive"
          : "negative"
        : changePercentage < 0
          ? "negative"
          : "positive";
  };

  const getPercentageValue = (value: number | null | undefined) => {
    if (typeof value !== "number") {
      return 0;
    }
    return Math.abs(value);
  };

  return (
    <div
      data-cy="performance-card-pill"
      className={cx(
        s.bbPerformanceCardPill,
        `bbPerformanceCardPillAmount-${getSentiment(percentageValue)}`,
      )}
    >
      <Tooltip
        placement="topRight"
        title={percentageTooltipProps?.title}
        overlayInnerStyle={percentageTooltipProps?.style}
      >
        <span className={s.bbPerformanceCardPillContent}>
          {!!percentageValue && <span className={s.bbChangeArrowWrapper}>{getChangeArrow()}</span>}
          <span
            className={s.bbPercentageValue}
          >{`${getPercentageValue(percentageValue)}${showPercentSymbol ? "%" : ""}`}</span>
        </span>
      </Tooltip>
    </div>
  );
};
