import { IUser } from "@store/users/types";

export const unAssignedOption = {
  id: "unassigned",
  key: 0,
  email: "",
  avatar: "",
  last_name: "",
  first_name: "Unassigned",
  is_disabled: false,
  display_name: "",
  client_short_name: "",
} as IUser;
