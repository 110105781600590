import React, { MouseEvent } from "react";

import { IImageAttachmentProps } from "./Variants.types";

const ImageAttachment: React.FC<IImageAttachmentProps> = ({
  attachment,
  onAttachmentClick,
  ...restProps
}) => (
  <span onClick={(e: MouseEvent<HTMLSpanElement>) => onAttachmentClick?.(e, attachment)}>
    <img src={attachment.url} alt="Image preview" {...restProps} />
  </span>
);

export default ImageAttachment;
