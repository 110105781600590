import { RootState } from "..";

export const fromSources = (state: RootState) => state.sources;

export const getSourcesListSelector = (state: RootState) => fromSources(state).sources;
export const getSourcesListPendingSelector = (state: RootState) =>
  fromSources(state).fetchingSourcesList;
export const getSourcesListSuccessfulSelector = (state: RootState) =>
  fromSources(state).fetchedSourcesList;
export const getSourcesListFailureSelector = (state: RootState) =>
  fromSources(state).fetchedSourcesListFail;

export const creatingSourceSelector = (state: RootState) => fromSources(state).creatingSource;
export const createdSourceSelector = (state: RootState) => fromSources(state).createdSource;
export const creatingSourceFailedSelector = (state: RootState) =>
  fromSources(state).creatingSourceFailed;
export const creatingSourceFailedMessageSelector = (state: RootState) =>
  fromSources(state).creatingSourceFailedMessage;

export const updatingSourceSelector = (state: RootState) => fromSources(state).updatingSource;
export const updatedSourceSelector = (state: RootState) => fromSources(state).updatedSource;
export const updatingSourceFailedSelector = (state: RootState) =>
  fromSources(state).updatingSourceFailed;
export const updatingSourceFailedMessageSelector = (state: RootState) =>
  fromSources(state).updatingSourceFailedMessage;

export const deletingSourceSelector = (state: RootState) => fromSources(state).deletingSource;
export const deletedSourceSelector = (state: RootState) => fromSources(state).deletedSource;
export const deletingSourceFailedSelector = (state: RootState) =>
  fromSources(state).deletingSourceFailed;
export const deletingSourceFailedMessageSelector = (state: RootState) =>
  fromSources(state).deletingSourceFailedMessage;
