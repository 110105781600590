export const GENERATE_PDF_DOCUMENT = "REPORTS/GENERATE_PDF_DOCUMENT";
export const GENERATE_PDF_DOCUMENT_SUCCESS = "REPORTS/GENERATE_PDF_DOCUMENT_SUCCESS";
export const GENERATE_PDF_DOCUMENT_FAILURE = "REPORTS/GENERATE_PDF_DOCUMENT_FAILURE";

export const GENERATE_EMAIL_REPORT_DOCUMENT = "REPORTS/GENERATE_EMAIL_REPORT_DOCUMENT";

export const GET_SCHEDULED_DOCUMENTS = "REPORTS/GET_SCHEDULED_DOCUMENTS";
export const GET_SCHEDULED_DOCUMENTS_SUCCESS = "REPORTS/GET_SCHEDULED_DOCUMENTS_SUCCESS";
export const GET_SCHEDULED_DOCUMENTS_FAILURE = "REPORTS/GET_SCHEDULED_DOCUMENTS_FAILURE";

export const POST_SCHEDULED_REPORT = "REPORTS/POST_SCHEDULED_REPORT";
export const POST_SCHEDULED_REPORT_SUCCESS = "REPORTS/POST_SCHEDULED_REPORT_SUCCESS";
export const POST_SCHEDULED_REPORT_FAILURE = "REPORTS/POST_SCHEDULED_REPORT_FAILURE";

export const UPDATE_SCHEDULED_REPORT = "REPORTS/UPDATE_SCHEDULED_REPORT";
export const UPDATE_SCHEDULED_REPORT_SUCCESS = "REPORTS/UPDATE_SCHEDULED_REPORT_SUCCESS";
export const UPDATE_SCHEDULED_REPORT_FAILURE = "REPORTS/UPDATE_SCHEDULED_REPORT_FAILURE";

export const DELETE_SCHEDULED_REPORT = "REPORTS/DELETE_SCHEDULED_REPORT";
export const DELETE_SCHEDULED_REPORT_SUCCESS = "REPORTS/DELETE_SCHEDULED_REPORT_SUCCESS";
export const DELETE_SCHEDULED_REPORT_FAILURE = "REPORTS/DELETE_SCHEDULED_REPORT_FAILURE";
