import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { addToast, Button, Input } from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";

import {
  createdOrUpdatedIntegrationUserSelector,
  creatingOrUpdatingIntegrationUserSelector,
} from "@store/integrations/selectors";
import { createOrUpdateIntegrationUserAction } from "@store/integrations/actions";

import s from "./CreateNewZendeskUserForm.module.less";

import { CreateNewZendeskUserFormProps } from "./CreateNewZendeskUserForm.type";
import { ICreateOrUpdateIntegrationUserPayload } from "@store/integrations/types";

const CreateNewZendeskUserForm = ({ onCancel }: CreateNewZendeskUserFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<ICreateOrUpdateIntegrationUserPayload>();

  const creatingOrUpdatingIntegrationUser = useSelector(creatingOrUpdatingIntegrationUserSelector);
  const createdOrUpdatedIntegrationUser = useSelector(createdOrUpdatedIntegrationUserSelector);

  const onSubmit = (data: ICreateOrUpdateIntegrationUserPayload) =>
    dispatch(createOrUpdateIntegrationUserAction(data));

  useEffect(() => {
    if (createdOrUpdatedIntegrationUser) {
      addToast({
        type: "success_accent",
        title: t("components:comments:zendeskTicket:toastMessages:success"),
      });
    }
  }, [createdOrUpdatedIntegrationUser, t]);

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
      requiredMark={false}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label={t("components:comments:zendeskTicket:linkUserModal:tab2:form:name:label")}
        rules={[
          {
            required: true,
            message: t("components:comments:zendeskTicket:linkUserModal:tab2:form:name:error"),
          },
        ]}
      >
        <Input _size="md" name="name" />
      </Form.Item>
      <Form.Item
        name="email"
        label={t("components:comments:zendeskTicket:linkUserModal:tab2:form:email:label")}
        rules={[
          {
            required: true,
            message: t("components:comments:zendeskTicket:linkUserModal:tab2:form:email:error"),
          },
        ]}
      >
        <Input
          _size="md"
          name="email"
          className={s.bbLinkUserModalCreateNewZendeskUserEmailInput}
        />
      </Form.Item>
      <Form.Item
        name="details"
        label={t("components:comments:zendeskTicket:linkUserModal:tab2:form:details:label")}
      >
        <Input _size="md" name="details" />
      </Form.Item>
      <div className={s.bbLinkUserModalCreateNewZendeskUserFooter}>
        <Button _type="secondary" type="button" onClick={onCancel}>
          {t("generic:cancel")}
        </Button>
        <Button _type="primary" type="submit" loading={creatingOrUpdatingIntegrationUser}>
          {t("components:comments:zendeskTicket:linkUserModal:tab2:form:createAndLinkUser")}
        </Button>
      </div>
    </Form>
  );
};

export default CreateNewZendeskUserForm;
