import { IWordCloudSentimentProps } from "@store/overview/types";
import { Word } from "react-wordcloud";
import { BaseType, select } from "d3-selection";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const getCloudSize = (width?: number) => [(width || 300) - 100, 320] as [number, number];
export const getCloudMaxWords = (width: number) =>
  width <= 360 ? 40 : width > 360 && width <= 500 ? 60 : 70;
export const getCloudPadding = (width: number) =>
  width <= 500 ? 2 : width > 500 && width <= 600 ? 3 : width > 700 && width <= 970 ? 5 : 8;

export const PanelColor = {
  positive: {
    other: [palette.colorWordcloud1, palette.colorWordcloud2, palette.colorWordcloud3],
    default: palette.colorPositive,
  },
  negative: {
    other: [palette.colorWordcloud1, palette.colorWordcloud2, palette.colorWordcloud3],
    default: palette.colorNegative,
  },
  all: {
    other: [palette.colorWordcloud1, palette.colorWordcloud2, palette.colorWordcloud3],
    default: palette.colorBlue900,
  },
};

export const calculateWordColor = (value: number, cloudType: string) => {
  const random = Math.floor(
    Math.random() * PanelColor[cloudType as keyof IWordCloudSentimentProps]["other"].length,
  );
  if (value <= 70) {
    return PanelColor[cloudType as keyof IWordCloudSentimentProps]["other"][random];
  } else {
    return PanelColor[cloudType as keyof IWordCloudSentimentProps]["default"];
  }
};

const calculateWordSize = (word: Word) => {
  return word.size * 1.25;
};

export const getHoverCallback = (callbackName: string, onWordClick: (word: Word) => void) => {
  return (word: Word, event: MouseEvent | undefined) => {
    const isActive = callbackName !== "onWordMouseOut";
    const element = event?.target;
    const text = select(element as BaseType);

    text
      .on("click", () => {
        if (isActive && typeof onWordClick === "function") {
          onWordClick(word);
        }
      })
      .transition()
      .attr("font-size", isActive ? calculateWordSize(word) + "px" : word.size + "px");
  };
};
