import { useTranslation } from "react-i18next";
import React, { ChangeEvent, FC, useEffect, useState } from "react";

import { Form } from "antd";
import { Label, Toggle, Textarea } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AIPromptSection.module.less";

import { IAIPromptField, IVariableToggle } from "./types";

export const VariableToggle: FC<IVariableToggle> = ({
  id,
  form,
  isToggleSelected,
  setToggleSelected,
  disabled,
}) => {
  const onChangeToggle = (e: ChangeEvent<HTMLInputElement>) => {
    const toggleChecked = !!e.target.checked;
    setToggleSelected(toggleChecked);

    if (!toggleChecked) {
      form.setFieldsValue({ [`${id}`]: "" });
      form.setFieldsValue({ [`${id}_enabled`]: false });
    }
  };

  return (
    <Form.Item name={`${id}_enabled`} valuePropName="checked" className={s.bbVariableToggle}>
      <Toggle
        id={`${id}_enabled`}
        checked={isToggleSelected}
        onChange={onChangeToggle}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const AIPromptField: FC<IAIPromptField> = ({ form, field, inlineToggle, parentToggled }) => {
  const { t } = useTranslation();

  const isConnectedToParent = !!inlineToggle;
  const isToggled = isConnectedToParent ? field.enabled && parentToggled : field.enabled;
  const isDisabled = isConnectedToParent && !parentToggled;

  const [isToggleSelected, setToggleSelected] = useState(isToggled);

  useEffect(() => {
    if (!isToggled) {
      setToggleSelected(isToggled);
      form.setFieldsValue({ [`${field.id}`]: "" });
      form.setFieldsValue({ [`${field.id}_enabled`]: false });
    }
    // eslint-disable-next-line
  }, [isToggled]);

  return (
    <div className={s.bbAIPromptField}>
      <div className={s.bbAIPromptFieldLabel}>
        <Label text={field.label} className={s.bbVariableLabel} />
        <div className={s.bbVariableToggleWrapper}>
          {isToggleSelected && (
            <span className={s.bbCharLimit}>
              {t("components:AIPrompts:edit:charLimit", {
                charLimit: field.maxCharLimit,
              })}
            </span>
          )}
          <VariableToggle
            form={form}
            id={field.id}
            isToggleSelected={isToggleSelected}
            setToggleSelected={setToggleSelected}
            disabled={isDisabled}
          />
        </div>
      </div>
      {isToggleSelected && (
        <Form.Item
          name={field.id}
          key={field.id}
          data-cy={`AI-prompt-${field.id}`}
          rules={[
            {
              required: isToggleSelected,
              message: field.errorMessage,
            },
          ]}
        >
          <Textarea
            rows={field.rowNumber}
            maxLength={field.maxCharLimit}
            placeholder={field.placeholder}
            data-cy={`AIPrompts-${field.id}-input`}
            className={s.bbAIPromptInput}
          />
        </Form.Item>
      )}
    </div>
  );
};

export default AIPromptField;
