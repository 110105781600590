import { RootState } from "../";

export const fromModerationStreams = (state: RootState) => state.streams.moderation;
export const fromCareCpsStreams = (state: RootState) => state.streams.care_cps;
export const getModerationStreamsSelector = (state: RootState) =>
  fromModerationStreams(state).streams.data;
export const getModerationStreamsSelectorPending = (state: RootState) =>
  fromModerationStreams(state).streams.fetching;
export const getModerationStreamsSelectorSuccessful = (state: RootState) =>
  fromModerationStreams(state).streams.fetched;
export const getModerationStreamsSelectorFailure = (state: RootState) =>
  fromModerationStreams(state).streams.fetchFail;

export const getModerationStreamsCommentsSelector = (state: RootState) =>
  fromModerationStreams(state).comments.data;
export const getModerationStreamsCommentsSelectorPending = (state: RootState) =>
  fromModerationStreams(state).comments.fetching;
export const getModerationStreamsCommentsSelectorSuccessful = (state: RootState) =>
  fromModerationStreams(state).comments.fetched;
export const getModerationStreamsCommentsSelectorFailure = (state: RootState) =>
  fromModerationStreams(state).comments.fetchFail;

export const getCareCpsStreamsSelector = (state: RootState) =>
  fromCareCpsStreams(state).streams.data;
export const getCareCpsStreamsSelectorPending = (state: RootState) =>
  fromCareCpsStreams(state).streams.fetching;
export const getCareCpsStreamsSelectorSuccessful = (state: RootState) =>
  fromCareCpsStreams(state).streams.fetched;
export const getCareCpsStreamsSelectorFailure = (state: RootState) =>
  fromCareCpsStreams(state).streams.fetchFail;
export const getCurrentCareCpsStreamSelector = (state: RootState) => {
  const currentStream = fromCareCpsStreams(state).streams.data?.find(
    stream => stream.id === fromCareCpsStreams(state).current,
  );
  return currentStream;
};
