import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { Typography } from "antd";
import { IModalProps, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import { ICareCPSStream } from "@store/streams/types";

export interface IDeleteStreamModalProps extends IModalProps {
  stream?: ICareCPSStream;
}

const DeleteStreamModal: FC<IDeleteStreamModalProps> = ({ stream, open, ...restProps }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      centered
      okButtonProps={{ _type: "danger" }}
      title={t("components:careCps:streamsModal:deleteModal:title")}
      {...restProps}
    >
      <Typography.Paragraph>
        {t("components:careCps:streamsModal:deleteModal:text", {
          deleteName: stream?.name || "",
        })}
      </Typography.Paragraph>
    </Modal>
  );
};

export default DeleteStreamModal;
