import React from "react";
import classNames from "classnames";

import s from "./MessageList.module.less";

interface IMessageCardWrapperProps {
  children: JSX.Element;
  isClientReply?: boolean;
  isChildMessage?: boolean;
}

export default function MessageCardWrapper({
  children,
  isClientReply,
  isChildMessage,
  ...props
}: IMessageCardWrapperProps) {
  return (
    <div
      data-testid="message-card-wrapper"
      className={classNames(s.bbMessageCardWrapper, {
        [s.bbMessageCardClientReply]: isClientReply,
        [s.bbMessageCardChild]: isChildMessage,
      })}
      {...props}
    >
      {children}
    </div>
  );
}
