import flags from "./flags";

export const Languages = {
  af: flags.ZA_SouthAfrica,
  ar: flags.AE_UnitedArabEmirates,
  "ar-ae": flags.AE_UnitedArabEmirates,
  "ar-bh": flags.BH_Bahrain,
  "ar-dz": flags.DZ_Algeria,
  "ar-eg": flags.EG_Egypt,
  "ar-iq": flags.IQ_Iraq,
  "ar-jo": flags.JO_Jordan,
  "ar-kw": flags.KW_Kuwait,
  "ar-lb": flags.LB_Lebanon,
  "ar-ly": flags.LY_Libya,
  "ar-ma": flags.MA_Morocco,
  "ar-om": flags.OM_Oman,
  "ar-qa": flags.QA_Qatar,
  "ar-sa": flags.SA_SaudiArabia,
  "ar-sy": flags.SY_Syria,
  "ar-tn": flags.TN_Tunisia,
  "ar-ye": flags.YE_Yemen,
  az: flags.AZ_Azerbaijan,
  "az-az": flags.AZ_Azerbaijan,
  be: flags.BY_Belarus,
  "be-by": flags.BY_Belarus,
  bg: flags.BG_Bulgaria,
  "bg-bg": flags.BG_Bulgaria,
  "bs-ba": flags.BA_BosniaAndHerzegovina,
  ca: flags.ES_Spain,
  "ca-es": flags.ES_Spain,
  cs: flags.CZ_CzechRepublic,
  "cs-cz": flags.CZ_CzechRepublic,
  cy: flags.UKM_United_Kingdom,
  "cy-gb": flags.UKM_United_Kingdom,
  da: flags.DK_Denmark,
  "da-dk": flags.DK_Denmark,
  de: flags.DE_Germany,
  "de-at": flags.DE_Germany,
  "de-ch": flags.DE_Germany,
  "de-de": flags.DE_Germany,
  "de-li": flags.DE_Germany,
  "de-lu": flags.DE_Germany,
  dv: flags.MV_Maldives,
  "dv-mv": flags.MV_Maldives,
  el: flags.GR_Greece,
  "el-gr": flags.GR_Greece,
  en: flags.UKM_United_Kingdom,
  "en-au": flags.AU_Australia,
  "en-bz": flags.BZ_Belize,
  "en-ca": flags.CA_Canada,
  "en-cb": flags.NL_Netherlands,
  "en-gb": flags.UKM_United_Kingdom,
  "en-ie": flags.IE_Ireland,
  "en-jm": flags.JM_Jamaica,
  "en-nz": flags.NZ_NewZealand,
  "en-ph": flags.PH_Philippines,
  "en-tt": flags.TT_TrinidadAndTobago,
  "en-us": flags.US_UnitedStates,
  "en-za": flags.ZA_SouthAfrica,
  "en-zw": flags.ZW_Zimbabwe,
  es: flags.ES_Spain,
  "es-ar": flags.AR_Argentina,
  "es-bo": flags.BO_Bolivia,
  "es-cl": flags.CL_Chile,
  "es-co": flags.CO_Colombia,
  "es-cr": flags.CR_CostaRica,
  "es-do": flags.DO_DominicanRepublic,
  "es-ec": flags.EC_Ecuador,
  "es-es": flags.ES_Spain,
  "es-gt": flags.GT_Guatemala,
  "es-hn": flags.HN_Honduras,
  "es-mx": flags.MX_Mexico,
  "es-ni": flags.NI_Nicaragua,
  "es-pa": flags.PA_Panama,
  "es-pe": flags.PE_Peru,
  "es-pr": flags.PR_PuertoRico,
  "es-py": flags.PY_Paraguay,
  "es-sv": flags.SV_ElSalvador,
  "es-uy": flags.UY_Uruguay,
  "es-ve": flags.VE_Venezuela,
  et: flags.EE_Estonia,
  "et-ee": flags.EE_Estonia,
  eu: flags.ES_Spain,
  "eu-es": flags.ES_Spain,
  fa: flags.IR_Iran,
  "fa-ir": flags.IR_Iran,
  fi: flags.FI_Finland,
  "fi-fi": flags.FI_Finland,
  fo: flags.FO_FaroeIslands,
  "fo-fo": flags.FO_FaroeIslands,
  fr: flags.FR_France,
  "fr-be": flags.BE_Belgium,
  "fr-ca": flags.CA_Canada,
  "fr-ch": flags.CH_Switzerland,
  "fr-fr": flags.FR_France,
  "fr-lu": flags.LU_Luxembourg,
  "fr-MC": flags.MC_Monaco,
  gl: flags.ES_Spain,
  "gl-es": flags.ES_Spain,
  gu: flags.IN_India,
  "gu-in": flags.IN_India,
  he: flags.IL_Isreal,
  "he-il": flags.IL_Isreal,
  hi: flags.IN_India,
  "hi-in": flags.IN_India,
  hr: flags.HR_Croatia,
  "hr-ba": flags.BA_BosniaAndHerzegovina,
  "hr-hr": flags.HR_Croatia,
  hu: flags.HU_Hungary,
  "hu-hu": flags.HU_Hungary,
  hy: flags.AM_Armenia,
  "hy-am": flags.AM_Armenia,
  id: flags.ID_Indonesia,
  "id-id": flags.ID_Indonesia,
  is: flags.IS_Iceland,
  "is-is": flags.IS_Iceland,
  it: flags.IT_Italy,
  "it-ch": flags.CH_Switzerland,
  "it-it": flags.IT_Italy,
  ja: flags.JP_Japan,
  "ja-jp": flags.JP_Japan,
  jw: flags.ID_Indonesia,
  jv: flags.ID_Indonesia,
  ka: flags.GE_Georgia,
  "ka-ge": flags.GE_Georgia,
  kk: flags.KZ_Kazakhstan,
  "kk-kz": flags.KZ_Kazakhstan,
  kn: flags.IN_India,
  "kn-in": flags.IN_India,
  ko: flags.KR_SouthKorea,
  "ko-kr": flags.KR_SouthKorea,
  kok: flags.IN_India,
  "kok-in": flags.IN_India,
  ky: flags.KG_Kyrgyzstan,
  "ky-kg": flags.KG_Kyrgyzstan,
  lt: flags.LT_Lithuania,
  "lt-lt": flags.LT_Lithuania,
  lv: flags.LV_Latvia,
  "lv-lv": flags.LV_Latvia,
  mi: flags.NZ_NewZealand,
  "mi-nz": flags.NZ_NewZealand,
  mk: flags.MK_NorthMacedonia,
  "mk-mk": flags.MK_NorthMacedonia,
  mn: flags.MN_Mongolia,
  "mn-mn": flags.MN_Mongolia,
  mr: flags.IN_India,
  "mr-in": flags.IN_India,
  ms: flags.BN_BruneiDarussalam,
  "ms-bn": flags.BN_BruneiDarussalam,
  "ms-my": flags.MY_Malaysia,
  mt: flags.MT_Malta,
  "mt-mt": flags.MT_Malta,
  nb: flags.NO_Norway,
  "nb-no": flags.NO_Norway,
  nl: flags.DK_Denmark,
  "nl-be": flags.BE_Belgium,
  "nl-nl": flags.NL_Netherlands,
  "nn-no": flags.NO_Norway,
  ns: flags.ZA_SouthAfrica,
  "ns-za": flags.ZA_SouthAfrica,
  pa: flags.IN_India,
  "pa-in": flags.IN_India,
  pl: flags.PL_Poland,
  "pl-pl": flags.PL_Poland,
  ps: flags.AF_Afghanistan,
  "ps-ar": flags.AF_Afghanistan,
  pt: flags.PT_Portugal,
  "pt-br": flags.BR_Brazil,
  "pt-pt": flags.PT_Portugal,
  qu: flags.BO_Bolivia,
  "qu-bo": flags.BO_Bolivia,
  "qu-ec": flags.EC_Ecuador,
  "qu-pe": flags.PE_Peru,
  ro: flags.RO_Romania,
  "ro-ro": flags.RO_Romania,
  ru: flags.RU_RussianFederation,
  "ru-ru": flags.RU_RussianFederation,
  rw: flags.RW_Rwanda,
  sa: flags.IN_India,
  "sa-in": flags.IN_India,
  se: flags.FI_Finland,
  "se-fi": flags.FI_Finland,
  "se-no": flags.NO_Norway,
  "se-se": flags.SE_Sweden,
  sk: flags.SK_Slovakia,
  "sk-sk": flags.SK_Slovakia,
  sl: flags.SI_Slovenia,
  "sl-si": flags.SI_Slovenia,
  sq: flags.AL_Albania,
  "sq-al": flags.AL_Albania,
  "sr-ba": flags.BA_BosniaAndHerzegovina,
  "sr-sp": flags.RS_Serbia,
  sv: flags.SE_Sweden,
  "sv-fi": flags.FI_Finland,
  "sv-se": flags.SE_Sweden,
  sw: flags.KE_Kenia,
  "sw-ke": flags.KE_Kenia,
  syr: flags.SY_Syria,
  "syr-sy": flags.SY_Syria,
  ta: flags.IN_India,
  "ta-in": flags.IN_India,
  te: flags.IN_India,
  "te-in": flags.IN_India,
  th: flags.TH_Thailand,
  "th-th": flags.TH_Thailand,
  tl: flags.PH_Philippines,
  "tl-ph": flags.PH_Philippines,
  tn: flags.ZA_SouthAfrica,
  "tn-za": flags.ZA_SouthAfrica,
  tr: flags.TR_Turkey,
  "tr-tr": flags.TR_Turkey,
  tt: flags.RU_RussianFederation,
  "tt-ru": flags.RU_RussianFederation,
  ts: flags.ZA_SouthAfrica,
  uk: flags.UA_Ukraine,
  "uk-ua": flags.UA_Ukraine,
  ur: flags.PK_Pakistan,
  "ur-pk": flags.PK_Pakistan,
  uz: flags.UZ_Uzbekistan,
  "uz-uz": flags.UZ_Uzbekistan,
  vi: flags.VN_Vietnam,
  "vi-vn": flags.VN_Vietnam,
  xh: flags.ZA_SouthAfrica,
  "xh-za": flags.ZA_SouthAfrica,
  zh: flags.CN_China,
  "zh-cn": flags.CN_China,
  "zh-hk": flags.HK_HongKong,
  "zh-mo": flags.MO_Macao,
  "zh-sg": flags.SG_Singapore,
  "zh-tw": flags.CN_China,
  zu: flags.ZA_SouthAfrica,
  "zu-za": flags.ZA_SouthAfrica,
  aa: flags.ET_Ethiopia,
  ab: flags.GE_Georgia,
  ace: flags.CN_China,
  ach: flags.UG_Uganda,
  ada: flags.GH_Ghana,
  ady: flags.RU_RussianFederation,
  ain: flags.JP_Japan,
  ak: flags.NE_Niger,
  ale: flags.GL_Greenland,
  alg: flags.CA_Canada,
  am: flags.ET_Ethiopia,
  anp: flags.NP_Nepal,
  an: flags.ES_Spain,
  arn: flags.CL_Chile,
  arp: flags.US_UnitedStates,
  arw: flags.VE_Venezuela,
  as: flags.IN_India,
  ast: flags.ES_Spain,
  aus: flags.AU_Australia,
  av: flags.AZ_Azerbaijan,
  ae: flags.IR_Iran,
  awa: flags.IN_India,
  ay: flags.BO_Bolivia,
  bad: flags.CF_CentralAfricanRepublic,
  bai: flags.CM_Cameroon,
  ba: flags.RU_RussianFederation,
  bal: flags.PK_Pakistan,
  bm: flags.ML_Mali,
  ban: flags.ID_Indonesia,
  bas: flags.CM_Cameroon,
  bej: flags.SD_Sudan,
  bem: flags.ZM_Zambia,
  bn: flags.BD_Bangladesh,
  bho: flags.IN_India,
  bh: flags.NP_Nepal,
  bik: flags.PH_Philippines,
  bin: flags.NG_Nigeria,
  bi: flags.VU_Vanuatu,
  bla: flags.CA_Canada,
  bo: flags.NP_Nepal,
  bs: flags.BA_BosniaAndHerzegovina,
  bra: flags.IN_India,
  br: flags.FR_France,
  btk: flags.PH_Philippines,
  bua: flags.RU_RussianFederation,
  bug: flags.ID_Indonesia,
  byn: flags.ER_Eritrea,
  cad: flags.US_UnitedStates,
  car: flags.VE_Venezuela,
  ceb: flags.PH_Philippines,
  ch: flags.PH_Philippines,
  chb: flags.CO_Colombia,
  ce: flags.RU_RussianFederation,
  chg: flags.UZ_Uzbekistan,
  chk: flags.FM_FederatedStatesOfMicronesia,
  chm: flags.RU_RussianFederation,
  chn: flags.CA_Canada,
  cho: flags.US_UnitedStates,
  chp: flags.CA_Canada,
  cu: flags.SK_Slovakia,
  cv: flags.RU_RussianFederation,
  chy: flags.US_UnitedStates,
  cmc: flags.ID_Indonesia,
  cnr: flags.ME_Montenegro,
  cop: flags.EG_Egypt,
  kw: flags.UKM_United_Kingdom,
  co: flags.IT_Italy,
  cpe: flags.UKM_United_Kingdom,
  cpf: flags.FR_France,
  cpp: flags.PT_Portugal,
  cr: flags.CA_Canada,
  crh: flags.TR_Turkey,
  csb: flags.PL_Poland,
  dak: flags.US_UnitedStates,
  dar: flags.RU_RussianFederation,
  del: flags.US_UnitedStates,
  dgr: flags.CA_Canada,
  din: flags.SD_Sudan,
  doi: flags.IN_India,
  dsb: flags.DE_Germany,
  dua: flags.CM_Cameroon,
  dyu: flags.BF_BurkinaFaso,
  dz: flags.BT_Bhutan,
  efi: flags.NG_Nigeria,
  egy: flags.EG_Egypt,
  eka: flags.NG_Nigeria,
  elx: flags.IR_Iran,
  ee: flags.GH_Ghana,
  ewo: flags.CM_Cameroon,
  fan: flags.GA_Gabon,
  fj: flags.FJ_Fiji,
  fil: flags.PH_Philippines,
  fon: flags.BJ_Benin,
  frr: flags.NL_Netherlands,
  frs: flags.NL_Netherlands,
  fy: flags.NL_Netherlands,
  ff: flags.CM_Cameroon,
  fur: flags.IT_Italy,
  gaa: flags.GH_Ghana,
  gay: flags.ID_Indonesia,
  gba: flags.CF_CentralAfricanRepublic,
  gez: flags.ET_Ethiopia,
  gil: flags.MY_Malaysia,
  gd: flags.UKM_United_Kingdom,
  ga: flags.UKM_United_Kingdom,
  gv: flags.UKM_United_Kingdom,
  gn: flags.PY_Paraguay,
  gsw: flags.SE_Sweden,
  gwi: flags.CA_Canada,
  hai: flags.CA_Canada,
  ht: flags.HT_Haiti,
  ha: flags.NG_Nigeria,
  haw: flags.US_UnitedStates,
  iw: flags.IL_Isreal,
  hz: flags.NA_Namibia,
  hil: flags.PH_Philippines,
  ho: flags.PG_PapuaNewGuinea,
  iba: flags.BN_BruneiDarussalam,
  ig: flags.NG_Nigeria,
  io: flags.FI_Finland,
  ii: flags.CN_China,
  ijo: flags.NG_Nigeria,
  iu: flags.CA_Canada,
  ilo: flags.PH_Philippines,
  inh: flags.RU_RussianFederation,
  ik: flags.US_UnitedStates,
  ira: flags.IR_Iran,
  iro: flags.US_UnitedStates,
  kaa: flags.UZ_Uzbekistan,
  kab: flags.DZ_Algeria,
  kac: flags.CN_China,
  kl: flags.DK_Denmark,
  kam: flags.KE_Kenia,
  kar: flags.MM_Myanmar,
  ks: flags.IN_India,
  kr: flags.NG_Nigeria,
  kaw: flags.ID_Indonesia,
  kbd: flags.RU_RussianFederation,
  kha: flags.IN_India,
  khi: flags.NA_Namibia,
  km: flags.KH_Cambodia,
  kho: flags.CN_China,
  ki: flags.KE_Kenia,
  kmb: flags.AO_Angola,
  kv: flags.RU_RussianFederation,
  kg: flags.CG_RepublicOfTheCongo,
  kos: flags.FM_FederatedStatesOfMicronesia,
  kpe: flags.LR_Liberia,
  krc: flags.RU_RussianFederation,
  krl: flags.RU_RussianFederation,
  kro: flags.LR_Liberia,
  kru: flags.IN_India,
  kj: flags.AO_Angola,
  kum: flags.RU_RussianFederation,
  ku: flags.IN_India,
  lad: flags.IL_Isreal,
  lah: flags.PK_Pakistan,
  lam: flags.ZM_Zambia,
  lo: flags.TH_Thailand,
  li: flags.NL_Netherlands,
  ln: flags.CG_RepublicOfTheCongo,
  lol: flags.CG_RepublicOfTheCongo,
  loz: flags.ZM_Zambia,
  lb: flags.LU_Luxembourg,
  lua: flags.CG_RepublicOfTheCongo,
  lu: flags.CG_RepublicOfTheCongo,
  lg: flags.UG_Uganda,
  lui: flags.US_UnitedStates,
  lun: flags.ZM_Zambia,
  luo: flags.KE_Kenia,
  lus: flags.IN_India,
  mad: flags.ID_Indonesia,
  mag: flags.IN_India,
  mh: flags.MH_MarshallIslands,
  mai: flags.IN_India,
  mak: flags.ID_Indonesia,
  ml: flags.IN_India,
  man: flags.SN_Senegal,
  mas: flags.KE_Kenia,
  mg: flags.MG_Madagascar,
  my: flags.MM_Myanmar,
  na: flags.NR_Nauru,
  nv: flags.US_UnitedStates,
  nr: flags.ZA_SouthAfrica,
  nd: flags.ZA_SouthAfrica,
  ng: flags.NA_Namibia,
  ne: flags.NP_Nepal,
  nn: flags.NO_Norway,
  no: flags.NO_Norway,
  ny: flags.MW_Malawi,
  oj: flags.CA_Canada,
  or: flags.IN_India,
  om: flags.ET_Ethiopia,
  os: flags.RU_RussianFederation,
  phi: flags.PH_Philippines,
  phn: flags.IT_Italy,
  pi: flags.IN_India,
  pra: flags.IN_India,
  raj: flags.IN_India,
  rap: flags.CL_Chile,
  rar: flags.CK_CookIslands,
  roa: flags.RO_Romania,
  rm: flags.RO_Romania,
  rom: flags.RO_Romania,
  rn: flags.BI_Burundi,
  rup: flags.RO_Romania,
  sad: flags.TZ_Tanzania,
  sg: flags.CF_CentralAfricanRepublic,
  sah: flags.RU_RussianFederation,
  sas: flags.ID_Indonesia,
  sat: flags.IN_India,
  scn: flags.IT_Italy,
  sco: flags.UKM_United_Kingdom,
  sel: flags.RU_RussianFederation,
  shn: flags.MM_Myanmar,
  sid: flags.ET_Ethiopia,
  si: flags.LK_SriLanka,
  sm: flags.AS_AmericanSamoa,
  sms: flags.FI_Finland,
  sn: flags.ZW_Zimbabwe,
  sd: flags.PK_Pakistan,
  so: flags.SO_Somalia,
  st: flags.LS_Lesotho,
  spa: flags.ES_Spain,
  sc: flags.IT_Italy,
  srn: flags.SR_Suriname,
  sr: flags.RS_Serbia,
  srr: flags.SN_Senegal,
  ssa: flags.DZ_Algeria,
  ss: flags.ZA_SouthAfrica,
  suk: flags.TZ_Tanzania,
  su: flags.MY_Malaysia,
  sus: flags.GN_Guinea,
  syc: flags.SY_Syria,
  ty: flags.PF_FrenchPolynesia,
  tem: flags.SL_SierraLeone,
  ter: flags.BR_Brazil,
  tet: flags.TL_TimorLeste,
  tg: flags.TJ_Tajikistan,
  tig: flags.ER_Eritrea,
  ti: flags.ER_Eritrea,
  tiv: flags.NG_Nigeria,
  tkl: flags.AS_AmericanSamoa,
  tli: flags.US_UnitedStates,
  tmh: flags.DZ_Algeria,
  tog: flags.MW_Malawi,
  to: flags.TO_Tonga,
  tpi: flags.PG_PapuaNewGuinea,
  tk: flags.TM_Turkmenistan,
  tum: flags.MW_Malawi,
  tup: flags.BR_Brazil,
  tvl: flags.TV_Tuvalu,
  tw: flags.GH_Ghana,
  tyv: flags.RU_RussianFederation,
  udm: flags.RU_RussianFederation,
  uga: flags.SY_Syria,
  ug: flags.CN_China,
  umb: flags.AO_Angola,
  vai: flags.LR_Liberia,
  ve: flags.ZA_SouthAfrica,
  vo: flags.DE_Germany,
  vot: flags.RU_RussianFederation,
  wal: flags.ET_Ethiopia,
  war: flags.PH_Philippines,
  was: flags.US_UnitedStates,
  wa: flags.BE_Belgium,
  wo: flags.SN_Senegal,
  xal: flags.RU_RussianFederation,
  yao: flags.MW_Malawi,
  yap: flags.FM_FederatedStatesOfMicronesia,
  yi: flags.IL_Isreal,
  yo: flags.NG_Nigeria,
  ypk: flags.GL_Greenland,
  zap: flags.MX_Mexico,
  zbl: flags.CA_Canada,
  zen: flags.MR_Mauritania,
  zgh: flags.DZ_Algeria,
  za: flags.CN_China,
  znd: flags.CF_CentralAfricanRepublic,
  zun: flags.MX_Mexico,
  zza: flags.TR_Turkey,
};
