import React, { FC } from "react";

import s from "./FormHeader.module.less";

import { Typography } from "antd";

interface IFormHeaderProps {
  title?: string;
  subtitle?: string;
  preTitle?: string;
}

const { Title } = Typography;

const FormHeader: FC<IFormHeaderProps> = ({ title, preTitle, subtitle }) => (
  <div className={s.bbFormHeader}>
    <Title level={4} className={s.bbContentPreTitle}>
      {preTitle}
    </Title>

    <Title level={1} className={s.bbContentTitle}>
      {title}
    </Title>
    <Title level={5} className={s.bbContentCTA}>
      {subtitle}
    </Title>
  </div>
);

export default FormHeader;
