import { createReducer } from "typesafe-actions";
import { IAssignState } from "./types";
import { ASSIGN_TO_ME, ASSIGN_TO_ME_SUCCESS, ASSIGN_TO_ME_FAILURE } from "./actionTypes";

const initialState = {
  loading: false,
};

export const assignReducer = createReducer<IAssignState>(initialState, {
  [ASSIGN_TO_ME]: (state: IAssignState) => ({
    ...state,
    loading: true,
  }),
  [ASSIGN_TO_ME_SUCCESS]: (state: IAssignState) => ({
    ...state,
    loading: false,
  }),
  [ASSIGN_TO_ME_FAILURE]: (state: IAssignState) => ({
    ...state,
    loading: false,
  }),
});
