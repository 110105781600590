export const CARE_CPS_SEARCH_CLIENT = "CARE_CPS/SEARCH_CLIENT";
export const CARE_CPS_SEARCH_CLIENT_SUCCESS = "CARE_CPS/SEARCH_CLIENT_SUCCESS";
export const CARE_CPS_SEARCH_CLIENT_FAILURE = "CARE_CPS/SEARCH_CLIENT_FAILURE";

export const CARE_CPS_GET_TAGS = "CARE_CPS/GET_TAGS";
export const CARE_CPS_GET_TAGS_SUCCESS = "CARE_CPS/GET_TAGS_SUCCESS";
export const CARE_CPS_GET_TAGS_FAILURE = "CARE_CPS/GET_TAGS_FAILURE";

export const CARE_CPS_GET_MODERATION_USERS = "CARE_CPS/GET_MODERATION_USERS";
export const CARE_CPS_GET_MODERATION_USERS_SUCCESS = "CARE_CPS/GET_MODERATION_USERS_SUCCESS";
export const CARE_CPS_GET_MODERATION_USERS_FAILURE = "CARE_CPS/GET_MODERATION_USERS_FAILURE";

export const CARE_CPS_CREATE_STREAM = "CARE_CPS/CREATE_STREAM";
export const CARE_CPS_UPDATE_STREAM = "CARE_CPS/UPDATE_STREAM";
export const CARE_CPS_CREATE_OR_UPDATE_STREAM_SUCCESS = "CARE_CPS/CREATE_OR_UPDATE_STREAM_SUCCESS";
export const CARE_CPS_CREATE_OR_UPDATE_STREAM_FAILURE = "CARE_CPS/CREATE_OR_UPDATE_STREAM_FAILURE";
export const CARE_CPS_STREAM_RESET = "CARE_CPS/STREAM_RESET";

export const CARE_CPS_DELETE_STREAM = "CARE_CPS/DELETE_STREAM";
export const CARE_CPS_DELETE_STREAM_SUCCESS = "CARE_CPS/DELETE_STREAM_SUCCESS";
export const CARE_CPS_DELETE_STREAM_FAILURE = "CARE_CPS/DELETE_STREAM_FAILURE";
