import React, { FC } from "react";

import { IMessageTag } from "@store/comments/types";

import s from "./TagCountBadge.module.less";

export interface ITagCountBadgeProps {
  tag: IMessageTag;
}

export const TagCountBadge: FC<ITagCountBadgeProps> = ({ tag }) => (
  <div className={s.bbTagCountBadge}>{tag.count}</div>
);
