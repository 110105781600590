import { action } from "typesafe-actions";

import { OPEN_ATTACHMENT, CLOSE_ATTACHMENT } from "./actionTypes";

import {
  IAttachment,
  IPrivateAttachment,
} from "@components/_common/CommentCard/cardBody/attachment/Attachment.type";

export const openAttachment = (attachment: IAttachment | IPrivateAttachment) =>
  action(OPEN_ATTACHMENT, attachment);

export const closeAttachment = () => action(CLOSE_ATTACHMENT);
