import { RatingStarsType } from "../../Social/Post/Post.type";

export const MAX_TRUSTPILOT_STARS = 5;
export const MAX_GOOGLE_BUSINESS_STARS = 5;

const starMapping = {
  ONE_STAR: 1,
  TWO_STAR: 2,
  THREE_STAR: 3,
  FOUR_STAR: 4,
  FIVE_STAR: 5,
};

export const getStarNumber = (starType?: RatingStarsType) => {
  return starType ? starMapping[starType] : null;
};
