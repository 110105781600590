import React from "react";

import CoverImg from "@assets/SignInCover.png";
import CoverImgX2 from "@assets/SignInCover@2x.png";

import CoverImgRegular from "@assets/SignInCoverRegular.png";
import CoverImgRegularX2 from "@assets/SignInCoverRegular@2x.png";

export const SignInCover: React.FC = () => (
  <img src={CoverImg} srcSet={`${CoverImg} 1x, ${CoverImgX2} 2x`} width={440} height={356} />
);

export const SignInCoverRegular: React.FC = () => (
  <img
    src={CoverImgRegular}
    srcSet={`${CoverImgRegular} 1x, ${CoverImgRegularX2} 2x`}
    width={440}
    height={342}
  />
);
