import { createReducer, ActionType } from "typesafe-actions";
import { ICommentTagsState } from "./types";
import { getCommentTagsSuccessAction } from "./actions";
import {
  GET_COMMENT_TAGS,
  GET_COMMENT_TAGS_SUCCESS,
  GET_COMMENT_TAGS_FAILURE,
  POST_COMMENT_TAG,
  POST_COMMENT_TAG_SUCCESS,
  POST_COMMENT_TAG_FAILURE,
  DELETE_COMMENT_TAG,
  DELETE_COMMENT_TAG_SUCCESS,
  DELETE_COMMENT_TAG_FAILURE,
} from "./actionTypes";

const initialState: ICommentTagsState = {
  commentTags: [],
  fetchingCommentTags: false,
  fetchedCommentTags: false,
  fetchedCommentTagsFail: false,
  postingCommentTag: false,
  postedCommentTag: false,
  postingCommentTagFailed: false,
  deletingCommentTag: false,
  deletingCommentTagFailed: false,
};

export const commentTagsReducer = createReducer<ICommentTagsState>(initialState, {
  [GET_COMMENT_TAGS]: (state: ICommentTagsState) => ({
    ...state,
    fetchingCommentTags: true,
    fetchedCommentTags: false,
    fetchedCommentTagsFail: false,
  }),
  [GET_COMMENT_TAGS_SUCCESS]: (
    state: ICommentTagsState,
    action: ActionType<typeof getCommentTagsSuccessAction>,
  ) => ({
    ...state,
    commentTags: action.payload.items,
    fetchingCommentTags: false,
    fetchedCommentTags: true,
    fetchedCommentTagsFail: false,
  }),
  [GET_COMMENT_TAGS_FAILURE]: (state: ICommentTagsState) => ({
    ...state,
    fetchingCommentTags: false,
    fetchedCommentTags: false,
    fetchedCommentTagsFail: true,
  }),
  [POST_COMMENT_TAG]: (state: ICommentTagsState) => ({
    ...state,
    postingCommentTag: true,
    postedCommentTag: false,
    postingCommentTagFailed: false,
  }),
  [POST_COMMENT_TAG_SUCCESS]: (state: ICommentTagsState) => ({
    ...state,
    postingCommentTag: false,
    postedCommentTag: true,
    postingCommentTagFailed: false,
  }),
  [POST_COMMENT_TAG_FAILURE]: (state: ICommentTagsState) => ({
    ...state,
    postingCommentTag: false,
    postedCommentTag: false,
    postingCommentTagFailed: true,
  }),

  [DELETE_COMMENT_TAG]: (state: ICommentTagsState) => ({
    ...state,
    deletingCommentTag: true,
    deletingCommentTagFailed: false,
  }),
  [DELETE_COMMENT_TAG_SUCCESS]: (state: ICommentTagsState) => ({
    ...state,
    deletingCommentTag: false,
    deletingCommentTagFailed: false,
  }),
  [DELETE_COMMENT_TAG_FAILURE]: (state: ICommentTagsState) => ({
    ...state,
    deletingCommentTag: false,
    deletingCommentTagFailed: true,
  }),
});
