import { createReducer, ActionType } from "typesafe-actions";
import { IMeState } from "./types";

import {
  deleteMeAvatarSuccessAction,
  getMeSuccessAction,
  patchMeClientSuccessAction,
  patchMeEmailSuccessAction,
  patchMeSuccessAction,
} from "./actions";

import {
  GET_ME,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  PATCH_ME,
  PATCH_ME_SUCCESS,
  PATCH_ME_FAILURE,
  PATCH_ME_CLIENT,
  PATCH_ME_CLIENT_SUCCESS,
  PATCH_ME_CLIENT_FAILURE,
  PATCH_ME_SESSION_CLIENT,
  PATCH_ME_SESSION_CLIENT_SUCCESS,
  PATCH_ME_SESSION_CLIENT_FAILURE,
  POST_ME_EMAIL,
  POST_ME_EMAIL_SUCCESS,
  POST_ME_EMAIL_FAILURE,
  PATCH_ME_EMAIL,
  PATCH_ME_EMAIL_SUCCESS,
  PATCH_ME_EMAIL_FAILURE,
  CANCEL_ME_EMAIL,
  POST_ME_PASSWORD,
  POST_ME_PASSWORD_SUCCESS,
  POST_ME_PASSWORD_FAILURE,
  DELETE_ME_AVATAR,
  DELETE_ME_AVATAR_SUCCESS,
  DELETE_ME_AVATAR_FAILURE,
} from "./actionTypes";

const initialState = {
  data: undefined,
  fetchingGetMe: false,
  fetchedGetMe: false,
  fetchedGetMeFail: false,
  fetchingPatchMe: false,
  fetchedPatchMe: false,
  fetchedPatchMeFail: false,
  fetchingPatchMeClient: false,
  fetchedPatchMeClient: false,
  fetchedPatchMeClientFail: false,
  fetchingPostMeEmail: false,
  fetchedPostMeEmail: false,
  fetchedPostMeEmailFail: false,
  fetchingPatchMeEmail: false,
  fetchedPatchMeEmail: false,
  fetchedPatchMeEmailFail: false,
  fetchingPostMePassword: false,
  fetchedPostMePassword: false,
  fetchedPostMePasswordFail: false,
  fetchingDeleteMeAvatar: false,
  fetchedDeleteMeAvatar: false,
  fetchedDeleteMeAvatarFail: false,
};

export const meReducer = createReducer<IMeState>(initialState, {
  [GET_ME]: (state: IMeState) => ({
    ...state,
    fetchingGetMe: true,
    fetchedGetMe: false,
    fetchedGetMeFail: false,
  }),
  [GET_ME_SUCCESS]: (state: IMeState, action: ActionType<typeof getMeSuccessAction>) => {
    return {
      ...state,
      data: action.payload,
      fetchingGetMe: false,
      fetchedGetMe: true,
      fetchedGetMeFail: false,
    };
  },
  [GET_ME_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingGetMe: false,
    fetchedGetMe: false,
    fetchedGetMeFail: true,
  }),
  [PATCH_ME]: (state: IMeState) => ({
    ...state,
    fetchingPatchMe: true,
    fetchedPatchMe: false,
    fetchedPatchMeFail: false,
  }),
  [PATCH_ME_SUCCESS]: (state: IMeState, action: ActionType<typeof patchMeSuccessAction>) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
      fetchingPatchMe: false,
      fetchedPatchMe: true,
      fetchedPatchMeFail: false,
    };
  },
  [PATCH_ME_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingPatchMe: false,
    fetchedPatchMe: false,
    fetchedPatchMeFail: true,
  }),
  // PATCH ME CLIENT
  [PATCH_ME_CLIENT]: (state: IMeState) => ({
    ...state,
    fetchingPatchMeClient: true,
    fetchedPatchMeClient: false,
    fetchedPatchMeClientFail: false,
  }),
  [PATCH_ME_CLIENT_SUCCESS]: (
    state: IMeState,
    action: ActionType<typeof patchMeClientSuccessAction>,
  ) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          client: {
            ...(state.data ? state.data.client : {}),
            ...action.payload,
          },
        },
        fetchingPatchMeClient: false,
        fetchedPatchMeClient: true,
        fetchedPatchMeClientFail: false,
      };
    }

    return state;
  },
  [PATCH_ME_CLIENT_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingPatchMeClient: false,
    fetchedPatchMeClient: false,
    fetchedPatchMeClientFail: true,
  }),

  // PATCH ME SESSION CLIENT
  [PATCH_ME_SESSION_CLIENT]: (state: IMeState) => ({
    ...state,
  }),
  [PATCH_ME_SESSION_CLIENT_SUCCESS]: (state: IMeState) => {
    if (state.data) {
      return {
        ...state,
        ...state.data,
      };
    }

    return state;
  },
  [PATCH_ME_SESSION_CLIENT_FAILURE]: (state: IMeState) => ({
    ...state,
  }),

  // POST ME EMAIL
  [POST_ME_EMAIL]: (state: IMeState) => ({
    ...state,
    fetchingPostMeEmail: true,
    fetchedPostMeEmail: false,
    fetchedPostMeEmailFail: false,
  }),
  [POST_ME_EMAIL_SUCCESS]: (state: IMeState) => ({
    ...state,
    fetchingPostMeEmail: false,
    fetchedPostMeEmail: true,
    fetchedPostMeEmailFail: false,
  }),
  [POST_ME_EMAIL_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingPostMeEmail: false,
    fetchedPostMeEmail: false,
    fetchedPostMeEmailFail: true,
  }),

  // PATCH ME EMAIL
  [PATCH_ME_EMAIL]: (state: IMeState) => ({
    ...state,
    fetchingPatchMeEmail: true,
    fetchedPatchMeEmail: false,
    fetchedPatchMeEmailFail: false,
  }),
  // Could probably be removed in favor of `PATCH_ME_SUCCESS`
  [PATCH_ME_EMAIL_SUCCESS]: (
    state: IMeState,
    action: ActionType<typeof patchMeEmailSuccessAction>,
  ) => ({
    ...state,
    data: {
      ...state.data,
      ...action.payload,
    },
    fetchingPatchMeEmail: false,
    fetchedPatchMeEmail: true,
    fetchedPatchMeEmailFail: false,
  }),
  [PATCH_ME_EMAIL_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingPatchMeEmail: false,
    fetchedPatchMeEmail: false,
    fetchedPatchMeEmailFail: true,
  }),
  // CANCEL EMAIL
  [CANCEL_ME_EMAIL]: (state: IMeState) => ({
    ...state,
    fetchingPostMeEmail: false,
    fetchedPostMeEmail: false,
    fetchedPostMeEmailFail: false,
    fetchingPatchMeEmail: false,
    fetchedPatchMeEmail: false,
    fetchedPatchMeEmailFail: false,
  }),

  // POST ME PASWORD
  [POST_ME_PASSWORD]: (state: IMeState) => ({
    ...state,
    fetchingPostMePassword: true,
    fetchedPostMePassword: false,
    fetchedPostMePasswordFail: false,
  }),
  [POST_ME_PASSWORD_SUCCESS]: (state: IMeState) => ({
    ...state,
    fetchingPostMePassword: false,
    fetchedPostMePassword: true,
    fetchedPostMePasswordFail: false,
  }),
  [POST_ME_PASSWORD_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingPostMePassword: false,
    fetchedPostMePassword: false,
    fetchedPostMePasswordFail: true,
  }),

  // DELETE ME AVATAR
  [DELETE_ME_AVATAR]: (state: IMeState) => ({
    ...state,
    fetchingDeleteMeAvatar: true,
    fetchedDeleteMeAvatar: false,
    fetchedDeleteMeAvatarFail: false,
  }),
  [DELETE_ME_AVATAR_SUCCESS]: (
    state: IMeState,
    action: ActionType<typeof deleteMeAvatarSuccessAction>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingDeleteMeAvatar: false,
    fetchedDeleteMeAvatar: true,
    fetchedDeleteMeAvatarFail: false,
  }),
  [DELETE_ME_AVATAR_FAILURE]: (state: IMeState) => ({
    ...state,
    fetchingDeleteMeAvatar: false,
    fetchedDeleteMeAvatar: false,
    fetchedDeleteMeAvatarFail: true,
  }),
});
