import { action } from "typesafe-actions";
import {
  GET_AI_PROMPTS,
  GET_AI_PROMPTS_FAILURE,
  GET_AI_PROMPTS_SUCCESS,
  CREATE_AI_PROMPT,
  CREATE_AI_PROMPT_FAILURE,
  CREATE_AI_PROMPT_SUCCESS,
  UPDATE_AI_PROMPT,
  UPDATE_AI_PROMPT_FAILURE,
  UPDATE_AI_PROMPT_SUCCESS,
  DELETE_AI_PROMPT,
  DELETE_AI_PROMPT_FAILURE,
  DELETE_AI_PROMPT_SUCCESS,
  SET_AI_PROMPT_AS_DEFAULT,
  SET_AI_PROMPT_AS_DEFAULT_FAILURE,
  SET_AI_PROMPT_AS_DEFAULT_SUCCESS,
  GET_PROMPT_AI_SUGGESTION,
  GET_PROMPT_AI_SUGGESTION_SUCCESS,
  GET_PROMPT_AI_SUGGESTION_FAILURE,
  CLEAR_AI_PROMPT,
  CLEAR_AI_PROMPT_SUGGESTION,
} from "./actionTypes";
import { IAIPrompt, IAIPromptsResponse, IAIPromptCommentPayload } from "./types";

export const createPromptAction = (prompt: IAIPrompt) => action(CREATE_AI_PROMPT, prompt);
export const createPromptSuccessAction = (prompt: IAIPrompt) =>
  action(CREATE_AI_PROMPT_SUCCESS, prompt);
export const createPromptFailureAction = () => action(CREATE_AI_PROMPT_FAILURE);

export const getPromptsAction = (query?: string) => action(GET_AI_PROMPTS, { query });
export const getPromptsSuccessAction = (data: IAIPromptsResponse) =>
  action(GET_AI_PROMPTS_SUCCESS, data);
export const getPromptsFailureAction = () => action(GET_AI_PROMPTS_FAILURE);

export const updatePromptAction = (prompt: IAIPrompt) => action(UPDATE_AI_PROMPT, prompt);
export const updatePromptSuccessAction = (prompt: IAIPrompt) =>
  action(UPDATE_AI_PROMPT_SUCCESS, prompt);
export const updatePromptFailureAction = () => action(UPDATE_AI_PROMPT_FAILURE);

export const deletePromptAction = (id: string) => action(DELETE_AI_PROMPT, id);
export const deletePromptSuccessAction = (id: string) => action(DELETE_AI_PROMPT_SUCCESS, id);
export const deletePromptFailureAction = () => action(DELETE_AI_PROMPT_FAILURE);

export const setPromptAsDefaultAction = (id: string) => action(SET_AI_PROMPT_AS_DEFAULT, id);
export const setPromptAsDefaultSuccessAction = (id: string) =>
  action(SET_AI_PROMPT_AS_DEFAULT_SUCCESS, id);
export const setPromptAsDefaultFailureAction = () => action(SET_AI_PROMPT_AS_DEFAULT_FAILURE);

export const getAIPromptSuggestionAction = (payload: IAIPromptCommentPayload) =>
  action(GET_PROMPT_AI_SUGGESTION, payload);
export const getAIPromptSuggestionSuccessAction = (suggestion: string) =>
  action(GET_PROMPT_AI_SUGGESTION_SUCCESS, suggestion);
export const getAIPromptSuggestionFailureAction = () => action(GET_PROMPT_AI_SUGGESTION_FAILURE);

export const clearAIPromptSuggestion = () => action(CLEAR_AI_PROMPT_SUGGESTION);
export const clearPromptAction = () => action(CLEAR_AI_PROMPT);
