export const CURRENT_SESSION = "AUTH/CURRENT_SESSION";
export const CURRENT_SESSION_SUCCESS = "AUTH/CURRENT_SESSION_SUCCESS";
export const CURRENT_SESSION_FAILURE = "AUTH/CURRENT_SESSION_FAILURE";

export const REFRESH_CURRENT_SESSION = "AUTH/REFRESH_CURRENT_SESSION";
export const REFRESH_CURRENT_SESSION_SUCCESS = "AUTH/REFRESH_CURRENT_SESSION_SUCCESS";
export const REFRESH_CURRENT_SESSION_FAILURE = "AUTH/REFRESH_CURRENT_SESSION_FAILURE";

export const CURRENT_AUTHENTICATE_USER = "AUTH/CURRENT_AUTHENTICATE_USER";
export const CURRENT_AUTHENTICATE_USER_SUCCESS = "AUTH/CURRENT_AUTHENTICATE_USER_SUCCESS";
export const CURRENT_AUTHENTICATE_USER_FAILURE = "AUTH/CURRENT_AUTHENTICATE_USER_FAILURE";

export const GET_MFA = "AUTH/GET_MFA";
export const GET_MFA_SUCCESS = "AUTH/GET_MFA_SUCCESS";
export const GET_MFA_FAILURE = "AUTH/GET_MFA_FAILURE";

export const SET_MFA = "AUTH/SET_MFA";
export const SET_MFA_SUCCESS = "AUTH/SET_MFA_SUCCESS";
export const SET_MFA_FAILURE = "AUTH/SET_MFA_FAILURE";

export const SETUP_TOTP = "AUTH/SETUP_TOTP";
export const SETUP_TOTP_SUCCESS = "AUTH/SETUP_TOTP_SUCCESS";
export const SETUP_TOTP_FAILURE = "AUTH/SETUP_TOTP_FAILURE";

export const VERIFY_TOTP = "AUTH/VERIFY_TOTP";
export const VERIFY_TOTP_SUCCESS = "AUTH/VERIFY_TOTP_SUCCESS";
export const VERIFY_TOTP_FAILURE = "AUTH/VERIFY_TOTP_FAILURE";
