/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CookieBanner.module.less";

const CookieBanner: FC = () => {
  const { t } = useTranslation();
  let observer: MutationObserver;

  const [cookieBannerOpen, setCookieBannerOpen] = useState(false);

  useEffect(() => {
    // Track when the hubspot banner is mounted into the DOM
    observer = new MutationObserver(() => {
      if (document.getElementById("hs-eu-cookie-confirmation") && !cookieBannerOpen) {
        setCookieBannerOpen(true);
        observer.disconnect();
      }
    });

    observer.observe(document, {
      attributes: false,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }, []);

  useEffect(() => () => observer.disconnect(), []);

  const onClickAccept = () => {
    // Click hubspot built-in banner accept button
    document.getElementById("hs-eu-confirmation-button")?.click();
    setCookieBannerOpen(false);
  };

  return (
    <>
      {cookieBannerOpen ? (
        <div className={s.bbCookieBanner}>
          <div className={s.bbCookieBannerTitle}>{t("cookieDisclaimer:title")}</div>
          <div className={s.bbCookieBannerDisclaimer}>
            <span>{t("cookieDisclaimer:dislaimer")}</span>
            <span className={s.bbCookieBannerLink}>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.brandbastion.com/privacy-policy?__hstc=181257784.0ce73b4233cb2080afb123958482945b.1662635307447.1662635307447.1662639609789.2&__hssc=181257784.4.1662639609789&__hsfp=2351831027"
              >
                {t("generic:learnMore")}
              </a>
            </span>
          </div>
          <div className={s.bbCookieBannerButtons}>
            <Button
              _size="md"
              _type="primary"
              onClick={onClickAccept}
              data-cy="cookies-accept-button"
              className={s.bbCookieBannerButtonsAccept}
            >
              {t("cookieDisclaimer:acceptBtnText")}
            </Button>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CookieBanner;
