import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";

import ItemCard from "../../ItemCardComponent/ItemCard";
import SavedReplyVariationSelector from "./SavedReplyVariationSelector";

import { deleteSavedRepliesAction } from "@store/replies/actions";
import { getSavedRepliesSelector } from "@store/replies/selectors";

import s from "./SavedReplyList.module.less";

import { ISavedReply } from "@store/replies/types";
import { IReplyList, SavedReplyTabKeys } from "./helper";

export default function SavedReplyList({ mode, type, onViewChange, onReplyClick }: IReplyList) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [replies, setReplies] = useState<ISavedReply[]>([]);

  const savedReplies = useSelector(getSavedRepliesSelector);

  useEffect(() => {
    setReplies(
      type === SavedReplyTabKeys.CustomReplies
        ? savedReplies?.items || []
        : savedReplies?.internal_items || [],
    );
  }, [savedReplies, type]);

  const deleteSavedReplies = useCallback(
    id => {
      dispatch(
        deleteSavedRepliesAction(
          id,
          type === SavedReplyTabKeys.CustomReplies ? "custom" : "internal",
        ),
      );
    },
    [dispatch, type],
  );

  const onClickDeleteSavedReplies = (id: string) => {
    deleteSavedReplies(id);
  };

  return (
    <div className={s.bbSavedReplyList}>
      {replies?.map(reply => {
        const { id, name, message, tags, custom_tags, variations } = reply;
        const tagLabels: { id: string; label: string }[] = [
          ...(tags || []),
          ...(custom_tags || []),
        ].map(tag => ({
          id: tag.id || "",
          label: tag.label,
        }));

        return (
          <ItemCard
            name={name}
            message={message}
            key={id}
            onItemClick={
              mode === "modal"
                ? () => {
                    onReplyClick?.(reply);
                  }
                : undefined
            }
            tags={tagLabels}
            tooltipProps={
              mode === "modal"
                ? {
                    overlayInnerStyle: { width: 370 },
                    title: t("components:reply:savedReplies:list:selectTooltip"),
                  }
                : undefined
            }
            actions={[
              {
                key: "edit",
                label: t("generic:edit"),
                onActionClick: () => onViewChange("edit", type, id),
              },
              {
                key: "delete",
                label: t("generic:delete"),
                onActionClick: () => onClickDeleteSavedReplies(id),
                confirmationText: t("components:reply:savedReplies:list:deletePrompt"),
              },
            ]}
            suffix={
              <SavedReplyVariationSelector
                replyId={id}
                variations={variations}
                replies={replies}
                setReplies={setReplies}
              />
            }
          />
        );
      })}
    </div>
  );
}
