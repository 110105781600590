import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Tooltip, numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import { ILimitsCounterProps, LIMITS_STATUS_COLOR } from "./helpers";

import s from "./LimitsCounter.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const LimitsCounter = ({ limit, used, status }: ILimitsCounterProps) => {
  const { t } = useTranslation();

  const lineWidth = ((limit - used) / limit) * 100;
  const lineColor = LIMITS_STATUS_COLOR[status];
  const limitText = limit >= 1000 ? `${Math.trunc(limit / 1000)}K` : limit;
  const isExceeded = used === limit;

  const label = isExceeded ? `${limitText}/${limitText}` : `${used}/${limitText}`;

  return (
    <Tooltip
      title={t("header:limitsTooltip", {
        used: numberFormatter(used),
        limit: numberFormatter(limit),
      })}
    >
      <div className={s.bbLimitsCounterWrapper}>
        <div
          className={classNames(s.bbLimitsCounter, {
            [s.bbLimitsCounterExceeded]: isExceeded,
          })}
        >
          {label}
        </div>
        <div
          style={{ backgroundColor: palette.colorNotApplicable }}
          className={s.bbLimitsCounterProgressBar}
        >
          <div
            style={{ backgroundColor: lineColor, width: `${lineWidth}%` }}
            className={s.bbLimitsCounterProgressBarFill}
          ></div>
        </div>
      </div>
    </Tooltip>
  );
};
