import { createReducer } from "typesafe-actions";
import produce from "immer";
import * as actions from "./actions";
import { CareCpsActionsType, ICareCpsState } from "./types";

const defaultRequestState = {
  fetching: false,
  fetched: false,
  fetchFail: false,
};

const initialState = {
  clients: {
    ...defaultRequestState,
    data: [],
  },
  tags: {
    ...defaultRequestState,
    data: [],
  },
  moderationUsers: {
    ...defaultRequestState,
    data: [],
  },
  createStream: {
    ...defaultRequestState,
  },
  deleteStream: {
    ...defaultRequestState,
  },
};

export const careCpsReducer = createReducer<ICareCpsState, CareCpsActionsType>(initialState)
  .handleAction(actions.searchClientAction, state => {
    return produce(state, draft => {
      draft.clients.fetching = false;
      draft.clients.fetched = false;
      draft.clients.fetchFail = false;
    });
  })
  .handleAction(actions.searchClientSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.clients.data = action.payload;
      draft.clients.fetching = false;
      draft.clients.fetched = true;
      draft.clients.fetchFail = false;
    });
  })
  .handleAction(actions.searchClientFailureAction, state => {
    return produce(state, draft => {
      draft.clients.fetching = false;
      draft.clients.fetched = false;
      draft.clients.fetchFail = true;
    });
  })
  .handleAction(actions.getTagsAction, state => {
    return produce(state, draft => {
      draft.tags.fetching = true;
      draft.tags.fetched = false;
      draft.tags.fetchFail = false;
    });
  })
  .handleAction(actions.getTagsSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.tags.data = action.payload;
      draft.tags.fetching = false;
      draft.tags.fetched = true;
      draft.tags.fetchFail = false;
    });
  })
  .handleAction(actions.getTagsFailureAction, state => {
    return produce(state, draft => {
      draft.tags.fetching = false;
      draft.tags.fetched = false;
      draft.tags.fetchFail = true;
    });
  })
  .handleAction(actions.getModerationUsersAction, state => {
    return produce(state, draft => {
      draft.moderationUsers.fetching = true;
      draft.moderationUsers.fetched = false;
      draft.moderationUsers.fetchFail = false;
    });
  })
  .handleAction(actions.getModerationUsersSuccessAction, (state, action) => {
    return produce(state, draft => {
      draft.moderationUsers.data = action.payload;
      draft.moderationUsers.fetching = false;
      draft.moderationUsers.fetched = true;
      draft.moderationUsers.fetchFail = false;
    });
  })
  .handleAction(actions.getModerationUsersFailureAction, state => {
    return produce(state, draft => {
      draft.moderationUsers.fetching = false;
      draft.moderationUsers.fetched = false;
      draft.moderationUsers.fetchFail = true;
    });
  })
  .handleAction(actions.createCareCpsStreamAction, state => {
    return produce(state, draft => {
      draft.createStream.fetching = true;
      draft.createStream.fetched = false;
      draft.createStream.fetchFail = false;
    });
  })
  .handleAction(actions.updateCareCpsStreamAction, state => {
    return produce(state, draft => {
      draft.createStream.fetching = true;
      draft.createStream.fetched = false;
      draft.createStream.fetchFail = false;
    });
  })
  .handleAction(actions.createOrUpdateCareCpsStreamSuccessAction, state => {
    return produce(state, draft => {
      draft.createStream.fetching = false;
      draft.createStream.fetched = true;
      draft.createStream.fetchFail = false;
    });
  })
  .handleAction(actions.createOrUpdateCareCpsStreamFailureAction, state => {
    return produce(state, draft => {
      draft.createStream.fetching = false;
      draft.createStream.fetched = false;
      draft.createStream.fetchFail = true;
    });
  })
  .handleAction(actions.careCpsStreamResetAction, state => {
    return produce(state, draft => {
      draft.createStream = { ...initialState.createStream };
      draft.deleteStream = { ...initialState.deleteStream };
    });
  })
  .handleAction(actions.deleteCareCpsStreamAction, state => {
    return produce(state, draft => {
      draft.deleteStream.fetching = true;
      draft.deleteStream.fetched = false;
      draft.deleteStream.fetchFail = false;
    });
  })
  .handleAction(actions.deleteCareCpsStreamSuccessAction, state => {
    return produce(state, draft => {
      draft.deleteStream.fetching = false;
      draft.deleteStream.fetched = true;
      draft.deleteStream.fetchFail = false;
    });
  })
  .handleAction(actions.deleteCareCpsStreamFailureAction, state => {
    return produce(state, draft => {
      draft.deleteStream.fetching = false;
      draft.deleteStream.fetched = false;
      draft.deleteStream.fetchFail = true;
    });
  });
