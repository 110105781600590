/* eslint-disable @typescript-eslint/no-explicit-any */
import { action } from "typesafe-actions";
import {
  CURRENT_SESSION,
  CURRENT_SESSION_SUCCESS,
  CURRENT_SESSION_FAILURE,
  REFRESH_CURRENT_SESSION,
  REFRESH_CURRENT_SESSION_SUCCESS,
  REFRESH_CURRENT_SESSION_FAILURE,
  CURRENT_AUTHENTICATE_USER,
  CURRENT_AUTHENTICATE_USER_SUCCESS,
  CURRENT_AUTHENTICATE_USER_FAILURE,
  SET_MFA,
  SET_MFA_SUCCESS,
  SET_MFA_FAILURE,
  GET_MFA,
  GET_MFA_SUCCESS,
  GET_MFA_FAILURE,
  SETUP_TOTP,
  SETUP_TOTP_SUCCESS,
  SETUP_TOTP_FAILURE,
  VERIFY_TOTP,
  VERIFY_TOTP_SUCCESS,
  VERIFY_TOTP_FAILURE,
} from "./actionTypes";

export const getCurrentSessionAction = () =>
  action(CURRENT_SESSION, null, {
    public: true,
  });
export const getCurrentSessionSuccessAction = (user: any) =>
  action(CURRENT_SESSION_SUCCESS, user, {
    public: true,
  });
export const getCurrentSessionFailureAction = () =>
  action(CURRENT_SESSION_FAILURE, null, {
    public: true,
  });

export const currentAuthenticateUserAction = () =>
  action(CURRENT_AUTHENTICATE_USER, null, {
    public: true,
  });
export const currentAuthenticateUserSuccessAction = (user: any) =>
  action(CURRENT_AUTHENTICATE_USER_SUCCESS, user, {
    public: true,
  });
export const currentAuthenticateUserFailureAction = () =>
  action(CURRENT_AUTHENTICATE_USER_FAILURE, null, {
    public: true,
  });

export const refreshCurrentSessionAction = () =>
  action(REFRESH_CURRENT_SESSION, null, {
    public: true,
  });
export const refreshCurrentSessionSuccessAction = (session: any) =>
  action(REFRESH_CURRENT_SESSION_SUCCESS, session, {
    public: true,
  });
export const refreshCurrentSessionFailureAction = () =>
  action(REFRESH_CURRENT_SESSION_FAILURE, null, {
    public: true,
  });

export const setMFAAction = (method: "TOTP" | "SMS" | "NOMFA") =>
  action(SET_MFA, method, {
    public: true,
  });
export const setMFASuccessAction = (data: any) =>
  action(SET_MFA_SUCCESS, data, {
    public: true,
  });
export const setMFAFailureAction = () =>
  action(SET_MFA_FAILURE, null, {
    public: true,
  });

export const getMFAAction = () =>
  action(GET_MFA, null, {
    public: true,
  });
export const getMFASuccessAction = (data: any) =>
  action(GET_MFA_SUCCESS, data, {
    public: true,
  });
export const getMFAFailureAction = () =>
  action(GET_MFA_FAILURE, null, {
    public: true,
  });

export const setupTOTPAction = () =>
  action(SETUP_TOTP, null, {
    public: true,
  });
export const setupTOTPSuccessAction = (code: string) =>
  action(SETUP_TOTP_SUCCESS, code, {
    public: true,
  });
export const setupTOTPFailureAction = () =>
  action(SETUP_TOTP_FAILURE, null, {
    public: true,
  });

export const verifyTOTPAction = (code: string) =>
  action(VERIFY_TOTP, code, {
    public: true,
  });
export const verifyTOTPSuccessAction = () =>
  action(VERIFY_TOTP_SUCCESS, null, {
    public: true,
  });
export const verifyTOTPFailureAction = () =>
  action(VERIFY_TOTP_FAILURE, null, {
    public: true,
  });
