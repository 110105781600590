import React from "react";
import { i18n } from "i18next";

import { IPost } from "../Social/Post/Post.type";
import { getI18next } from "../../../languages/i18next";

import { ReelIcon, ImageIcon, CameraIcon, FileIcon, CarouselImageIcon } from "@assets/index";

const i18nextinstance = getI18next() as i18n;
const t = i18nextinstance.t;

export const getPostMediaTypeIcon = (post: IPost) => {
  switch (post.post_media_type?.id) {
    case "image":
      return <ImageIcon />;
    case "video":
      return post.platform_type.id === "instagram" ? <ReelIcon /> : <CameraIcon />;
    case "multiimage":
      return <CarouselImageIcon />;
    case "pdf":
    case "doc":
    case "ppt":
      return <FileIcon />;
    default:
      return <ImageIcon />;
  }
};

export const getPostMediaTypeLabel = (post: IPost) => {
  switch (post.post_media_type?.id) {
    case "image":
      return t("components:postPreview:mediaLabel:image");
    case "video":
      return post.platform_type.id === "instagram"
        ? t("components:postPreview:mediaLabel:reel")
        : t("components:postPreview:mediaLabel:video");
    case "multiimage":
      return t("components:postPreview:mediaLabel:carousel");
    default:
      return t("components:postPreview:mediaLabel:image");
  }
};
