import React from "react";
import classNames from "classnames";

import { Chip, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SelectedExcludedIndicator.module.less";

interface ISelectedExcludedIndicatorProps {
  selected?: string;
  excluded?: string;
  className?: string;
  excludedTooltip?: React.ReactNode;
  tooltip?: React.ReactNode;
}

function SelectedExcludedIndicator({
  selected,
  excluded,
  className,
  excludedTooltip,
  tooltip,
}: ISelectedExcludedIndicatorProps) {
  return (
    <div className={classNames(s.bbIndicator, className)} data-testid="selected-excluded-indicator">
      {!!selected && (
        <Tooltip placement="topLeft" title={tooltip}>
          <Chip _size="xxs" _type="primary" text={selected} className={s.bbIndicatorSelected} />
        </Tooltip>
      )}
      {!!excluded && (
        <Tooltip title={excludedTooltip}>
          <Chip _size="xxs" _type="primary" className={s.bbIndicatorExcluded} text={excluded} />
        </Tooltip>
      )}
    </div>
  );
}

export default SelectedExcludedIndicator;
