import { TFunction } from "i18next";

export const getSentimentOptions = (t: TFunction) => [
  {
    value: "negative",
    tooltip: t("components:filters:comments:items:sentiment:negative"),
  },
  {
    value: "positive",
    tooltip: t("components:filters:comments:items:sentiment:positive"),
  },
  {
    value: "neutral",
    tooltip: t("components:filters:comments:items:sentiment:neutral"),
  },
  {
    value: "user_tags",
    tooltip: `${t(
      "components:filters:comments:items:sentiment:user_tags",
    )} (${t("components:filters:comments:items:sentiment:user_tags_tooltip")})`,
  },
  {
    value: "not_applicable",
    tooltip: `${t("components:filters:comments:items:sentiment:not_applicable")} - ${t(
      "components:filters:comments:items:sentiment:not_applicable_tooltip",
    )}`,
  },
];
