import flags from "./flags";
import React from "react";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./countries.module.less";

export interface ICountry {
  id: string;
  label: string;
}

export const Countries = {
  af: flags.AF_Afghanistan,
  al: flags.AL_Albania,
  dz: flags.DZ_Algeria,
  as: flags.AS_AmericanSamoa,
  ad: flags.AD_Andorra,
  ao: flags.AO_Angola,
  ai: flags.AI_Anguilla,
  aq: flags.AQ_Antarctica,
  ag: flags.AG_AntiguaAndBarbuda,
  ar: flags.AR_Argentina,
  am: flags.AM_Armenia,
  aw: flags.AW_Aruba,
  au: flags.AU_Australia,
  at: flags.AT_Austria,
  az: flags.AZ_Azerbaijan,
  bs: flags.BS_Bahamas,
  bh: flags.BH_Bahrain,
  bd: flags.BD_Bangladesh,
  bb: flags.BB_Barbados,
  by: flags.BY_Belarus,
  be: flags.BE_Belgium,
  bz: flags.BZ_Belize,
  bj: flags.BJ_Benin,
  bm: flags.BM_Bermuda,
  bt: flags.BT_Bhutan,
  ba: flags.BA_BosniaAndHerzegovina,
  bo: flags.BO_Bolivia,
  bw: flags.BW_Botswana,
  bv: flags.BV_BouvetIsland,
  br: flags.BR_Brazil,
  io: flags.IO_BritishIndianOceanTerritory,
  bn: flags.BN_BruneiDarussalam,
  bg: flags.BG_Bulgaria,
  bf: flags.BF_BurkinaFaso,
  bi: flags.BI_Burundi,
  kh: flags.KH_Cambodia,
  cm: flags.CM_Cameroon,
  ca: flags.CA_Canada,
  cv: flags.CV_CaboVerde,
  ky: flags.KY_CaymanIslands,
  cf: flags.CF_CentralAfricanRepublic,
  td: flags.TD_Chad,
  cl: flags.CL_Chile,
  cn: flags.CN_China,
  cx: flags.CX_ChristmasIsland,
  cc: flags.CC_CocosIslands,
  co: flags.CO_Colombia,
  km: flags.KM_Comoros,
  cg: flags.CG_RepublicOfTheCongo,
  cd: flags.CD_DemocraticRepublicOfTheCongo,
  ck: flags.CK_CookIslands,
  cr: flags.CR_CostaRica,
  ci: flags.CI_IvoryCoast,
  hr: flags.HR_Croatia,
  cu: flags.CU_Cuba,
  cy: flags.CY_Cyprus,
  cz: flags.CZ_CzechRepublic,
  dk: flags.DK_Denmark,
  dj: flags.DJ_Djibouti,
  dm: flags.DM_Dominica,
  do: flags.DO_DominicanRepublic,
  ec: flags.EC_Ecuador,
  eg: flags.EG_Egypt,
  sv: flags.SV_ElSalvador,
  gq: flags.GQ_EquatorialGuinea,
  er: flags.ER_Eritrea,
  ee: flags.EE_Estonia,
  et: flags.ET_Ethiopia,
  fk: flags.FK_FalklandIslands,
  fo: flags.FO_FaroeIslands,
  fj: flags.FJ_Fiji,
  fi: flags.FI_Finland,
  fr: flags.FR_France,
  gf: flags.GF_FrenchGuiana,
  pf: flags.PF_FrenchPolynesia,
  tf: flags.TF_FrenchSouthernAndAntarcticLands,
  ga: flags.GA_Gabon,
  gm: flags.GM_Gambia,
  ge: flags.GE_Georgia,
  de: flags.DE_Germany,
  gh: flags.GH_Ghana,
  gi: flags.GI_Gibraltar,
  gr: flags.GR_Greece,
  gl: flags.GL_Greenland,
  gd: flags.GD_Grenada,
  gp: flags.GP_Guadeloupe,
  gu: flags.GU_Guam,
  gt: flags.GT_Guatemala,
  gg: flags.GG_Guernsey,
  gn: flags.GN_Guinea,
  gw: flags.GW_GuineaBissau,
  gy: flags.GY_Guyana,
  ht: flags.HT_Haiti,
  hm: flags.HM_HeardAndMcDonaldIslands,
  va: flags.VA_VaticanCityState,
  hn: flags.HN_Honduras,
  hk: flags.HK_HongKong,
  hu: flags.HU_Hungary,
  is: flags.IS_Iceland,
  in: flags.IN_India,
  id: flags.ID_Indonesia,
  ir: flags.IR_Iran,
  iq: flags.IQ_Iraq,
  ie: flags.IE_Ireland,
  im: flags.IM_IsleOfMan,
  il: flags.IL_Isreal,
  it: flags.IT_Italy,
  jm: flags.JM_Jamaica,
  jp: flags.JP_Japan,
  je: flags.JE_Jersey,
  jo: flags.JO_Jordan,
  kz: flags.KZ_Kazakhstan,
  ke: flags.KE_Kenia,
  ki: flags.KI_Kiribati,
  kr: flags.KR_SouthKorea,
  kw: flags.KW_Kuwait,
  kg: flags.KG_Kyrgyzstan,
  la: flags.LA_Laos,
  lv: flags.LV_Latvia,
  lb: flags.LB_Lebanon,
  ls: flags.LS_Lesotho,
  lr: flags.LR_Liberia,
  ly: flags.LY_Libya,
  li: flags.LI_Liechtenstein,
  lt: flags.LT_Lithuania,
  lu: flags.LU_Luxembourg,
  mo: flags.MO_Macao,
  mk: flags.MK_NorthMacedonia,
  mg: flags.MG_Madagascar,
  mw: flags.MW_Malawi,
  my: flags.MY_Malaysia,
  mv: flags.MV_Maldives,
  ml: flags.ML_Mali,
  mt: flags.MT_Malta,
  mh: flags.MH_MarshallIslands,
  mq: flags.MQ_Martinique,
  mr: flags.MR_Mauritania,
  mu: flags.MU_Mauritius,
  yt: flags.YT_UNF_Mayotte,
  mx: flags.MX_Mexico,
  fm: flags.FM_FederatedStatesOfMicronesia,
  md: flags.MD_Moldova,
  mc: flags.MC_Monaco,
  mn: flags.MN_Mongolia,
  me: flags.ME_Montenegro,
  ms: flags.MS_Montserrat,
  ma: flags.MA_Morocco,
  mz: flags.MZ_Mozambique,
  mm: flags.MM_Myanmar,
  na: flags.NA_Namibia,
  nr: flags.NR_Nauru,
  np: flags.NP_Nepal,
  nl: flags.NL_Netherlands,
  an: flags.NL_Netherlands,
  nc: flags.NC_NewCaledonia,
  nz: flags.NZ_NewZealand,
  ni: flags.NI_Nicaragua,
  ne: flags.NE_Niger,
  ng: flags.NG_Nigeria,
  nu: flags.NU_Niue,
  nf: flags.NF_NorfolkIsland,
  mp: flags.MP_NorthernMarianaIslands,
  no: flags.NO_Norway,
  om: flags.OM_Oman,
  pk: flags.PK_Pakistan,
  pw: flags.PW_Palau,
  ps: flags.PS_PalestinianTerritory,
  pa: flags.PA_Panama,
  pg: flags.PG_PapuaNewGuinea,
  py: flags.PY_Paraguay,
  pe: flags.PE_Peru,
  ph: flags.PH_Philippines,
  pn: flags.PN_PitcairnIslands,
  pl: flags.PL_Poland,
  pt: flags.PT_Portugal,
  pr: flags.PR_PuertoRico,
  qa: flags.QA_Qatar,
  re: flags.RE_Reunion,
  ro: flags.RO_Romania,
  ru: flags.RU_RussianFederation,
  rw: flags.RW_Rwanda,
  bl: flags.BL_SaintBarthelemy,
  sh: flags.SH_SaintHelena,
  kn: flags.KN_SaintKittsAndNevis,
  lc: flags.LC_SaintLucia,
  mf: flags.MF_SaintMartin,
  pm: flags.PM_SaintPierreAndMiquelon,
  vc: flags.VC_SaintVincentAndTheGrenadines,
  ws: flags.WS_Samoa,
  sm: flags.SM_SanMarino,
  st: flags.ST_SaoTomeAndPrincipe,
  sa: flags.SA_SaudiArabia,
  sn: flags.SN_Senegal,
  rs: flags.RS_Serbia,
  sc: flags.SC_Seychelles,
  sl: flags.SL_SierraLeone,
  sg: flags.SG_Singapore,
  sk: flags.SK_Slovakia,
  si: flags.SI_Slovenia,
  sb: flags.SB_SolomonIslands,
  so: flags.SO_Somalia,
  za: flags.ZA_SouthAfrica,
  gs: flags.GS_SGeorgiaAndSSandwichIslands,
  es: flags.ES_Spain,
  lk: flags.LK_SriLanka,
  sd: flags.SD_Sudan,
  sr: flags.SR_Suriname,
  sj: flags.SJ_SvalbardAndJanMayen,
  sz: flags.SZ_Swaziland,
  se: flags.SE_Sweden,
  ch: flags.CH_Switzerland,
  sy: flags.SY_Syria,
  tw: flags.TW_Taiwan,
  tj: flags.TJ_Tajikistan,
  tz: flags.TZ_Tanzania,
  th: flags.TH_Thailand,
  tl: flags.TL_TimorLeste,
  tg: flags.TG_Togo,
  tk: flags.TK_Tokelau,
  to: flags.TO_Tonga,
  tt: flags.TT_TrinidadAndTobago,
  tn: flags.TN_Tunisia,
  tr: flags.TR_Turkey,
  tm: flags.TM_Turkmenistan,
  tc: flags.TC_TurksAndCaicosIslands,
  tv: flags.TV_Tuvalu,
  ug: flags.UG_Uganda,
  ua: flags.UA_Ukraine,
  ae: flags.AE_UnitedArabEmirates,
  gb: flags.UKM_United_Kingdom,
  us: flags.US_UnitedStates,
  um: flags.UM_UnitedStatesMinorOutlyingIslands,
  uy: flags.UY_Uruguay,
  uz: flags.UZ_Uzbekistan,
  vu: flags.VU_Vanuatu,
  ve: flags.VE_Venezuela,
  vn: flags.VN_Vietnam,
  vg: flags.VG_VirginIslandsBritish,
  vi: flags.VI_VirginIslandsUS,
  wf: flags.WF_WallisAndFutuna,
  eh: flags.EH_WesternSahara,
  ye: flags.YE_Yemen,
  zm: flags.ZM_Zambia,
  zw: flags.ZW_Zimbabwe,
};

export const getCountriesFlags = (countries: ICountry[], maxShownCountryCount = 2) => {
  return (
    <>
      {countries?.slice(0, maxShownCountryCount).map(country => {
        const Flag = Countries[country?.id.toLowerCase() as keyof typeof Countries];

        if (!Flag) {
          console.warn(`Missing flag for ${country.id}`);
          return null;
        }

        return (
          <Tooltip placement="top" title={country.label} key={country.id}>
            <div className={s.bbCountryFlag}>
              <Flag />
            </div>
          </Tooltip>
        );
      })}
      {(countries?.length || 0) > maxShownCountryCount ? (
        <Tooltip
          title={countries
            ?.slice(maxShownCountryCount, countries?.length)
            .map(country => <div key={country.id}>{country.label}</div>)}
        >
          <div className={s.bbCountryFlagTooltip}>+{countries?.length - maxShownCountryCount}</div>
        </Tooltip>
      ) : null}
    </>
  );
};
