import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import Attachments from "../attachment/Attachment";

import s from "./ThreadBody.module.less";
import audioStyles from "../attachment/variants/AudioAttachment.module.less";

import { cropText } from "@utils/cropText";
import { IPrivateMessageProps } from "./ThreadBody.type";

import { WarningIcon } from "@bbdevcrew/bb_ui_kit_fe";

const DirectMessage: React.FC<IPrivateMessageProps> = ({ message }) => {
  const { t } = useTranslation();
  const isUnsupportedMediaMessage =
    !!message.attachments?.length && message.attachments[0].description === "unsupported_media";
  const messageTextExists = !!message.message && message.message.trim().length > 0;

  return (
    <>
      {messageTextExists && (
        <div className={s.bbThreadBodyMessageBubble}>{cropText(`${message.message}`, 150)}</div>
      )}
      {!messageTextExists && isUnsupportedMediaMessage && (
        <div className={s.bbThreadBodyMessageBubble}></div>
      )}
      <Attachments
        isPreviewMessage
        createdTime={message.created_time}
        attachments={message.attachments || []}
        audioClassName={classNames(
          audioStyles[`bbAudioAttachment-${message.owned ? "right" : "left"}`],
          audioStyles[`bbAudioTrack-${message.owned ? "right" : "left"}`],
        )}
      />
      {isUnsupportedMediaMessage && (
        <div>
          <WarningIcon />
          {t("components:reply:unsupportedMedia")}
        </div>
      )}
    </>
  );
};

export default DirectMessage;
