import { SubscriptionLimitStatusType } from "@store/me/types";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

export const LIMITS_STATUS_COLOR = {
  orange: palette.colorOrange600,
  red: palette.colorRed700,
  green: palette.colorGreen600,
};

export interface ILimitsCounterProps {
  limit: number;
  used: number;
  status: SubscriptionLimitStatusType;
}
