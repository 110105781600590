import React, { FC } from "react";

import { Table } from "@bbdevcrew/bb_ui_kit_fe";
import { ProfilesTablePrefix } from "./ProfilesTablePrefix";
import FollowersTableRow from "./FollowersTableRow";
import ImpressionsPlatformTableRow from "./ImpressionsPlatformTableRow";
import ImpressionsProfileTableRow from "./ImpressionsProfileTableRow";

import s from "./ProfilesTable.module.less";

import {
  IProfilesFollowersTableProps,
  IProfilesImpressionsPlatformTableProps,
  IProfilesImpressionsProfileTableProps,
} from "./ProfilesTable.types";
import { useProfilesTableLogic } from "./ProfilesTable.helpers";
import {
  IDashboardFollowersRow,
  IDashboardImpressionsPlatform,
  IDashboardImpressionsRow,
} from "@store/dashboard/types";

export const ProfilesFollowersTable: FC<IProfilesFollowersTableProps> = ({
  data,
  total,
  pagination,
  ...props
}) => {
  const { tableData, onSort, onChangePage, pageSize, currentPage } = useProfilesTableLogic({
    data,
  });

  return (
    <div className={s.bbProfilesWrapper} data-cy="followers-table">
      <Table<IDashboardFollowersRow>
        data={tableData}
        onSort={onSort}
        tableRow={FollowersTableRow}
        pagination={{
          total: data.length,
          pageSize,
          currentPage,
          onChangePage,
          ...pagination,
        }}
        rowsPrefix={<ProfilesTablePrefix value={total} type="followers" />}
        stonlySelector="intelligence-followers_table"
        {...props}
      />
    </div>
  );
};

export const ProfilesImpressionsPlatformTable: FC<IProfilesImpressionsPlatformTableProps> = ({
  data,
  total,
  ...props
}) => {
  const { tableData, onSort } = useProfilesTableLogic({
    data,
  });

  return (
    <div className={s.bbProfilesWrapper} data-cy="profiles-table">
      <Table<IDashboardImpressionsPlatform>
        data={tableData}
        onSort={onSort}
        tableRow={ImpressionsPlatformTableRow}
        rowsPrefix={<ProfilesTablePrefix value={total} type="impressions-platform" />}
        stonlySelector="intelligence-impressions_table"
        {...props}
        pagination={undefined}
      />
    </div>
  );
};

export const ProfilesImpressionsProfileTable: FC<IProfilesImpressionsProfileTableProps> = ({
  data,
  total,
  pagination,
  ...props
}) => {
  const { tableData, onSort, onChangePage, pageSize, currentPage } = useProfilesTableLogic({
    data,
  });

  return (
    <div className={s.bbProfilesWrapper} data-cy="profiles-table">
      <Table<IDashboardImpressionsRow>
        data={tableData}
        onSort={onSort}
        tableRow={ImpressionsProfileTableRow}
        pagination={{
          total: data.length,
          pageSize,
          currentPage,
          onChangePage,
          ...pagination,
        }}
        rowsPrefix={<ProfilesTablePrefix value={total} type="impressions-profile" />}
        stonlySelector="intelligence-impressions_table"
        {...props}
      />
    </div>
  );
};
