import React from "react";
import { TFunction } from "i18next";
import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

export interface ISentimentStats {
  total: number;
  positive: number;
  negative: number;
  user_tags: number;
  neutral: number;
  not_applicable: number;
}

const getPercentageString = (value: number, total: number) => {
  const rawPercent = total === 0 ? 0 : (value / total) * 100;

  return rawPercent === 100
    ? "(100%)"
    : "(" + (rawPercent === 0 ? 0 : rawPercent.toFixed(2)) + "%)";
};

export const renderStatsTooltip = (stats: ISentimentStats, t: TFunction) => {
  return (
    <div>
      {stats
        ? Object.keys(stats).map(key => {
            const value = stats[key as keyof typeof stats];
            const totalValue = stats["total"];

            if (key === "total") return null;

            return (
              <div key={key}>
                <span>
                  {t(`components:postTrends:charts:${key}`)}
                  {": "}
                  {numberFormatter(value || 0)}{" "}
                </span>
                <span>{getPercentageString(value || 0, totalValue)}</span>
              </div>
            );
          })
        : null}
      <div>
        {t(`components:postTrends:charts:total`)}
        {": "}
        {numberFormatter(stats.total || 0)}{" "}
      </div>
    </div>
  );
};
