import React from "react";
import { createPortal } from "react-dom";

import useElementObserver from "@utils/useElementObserver";

const AppSidebarPortal: React.FC<{
  placement?: string;
  component: React.FC;
}> = ({ component: Component, placement = "top" }) => {
  const sidebarRoot = useElementObserver(`#appSidebar-${placement}`);
  if (!sidebarRoot) return null;
  return createPortal(<Component />, sidebarRoot);
};

export default AppSidebarPortal;
