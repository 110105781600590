import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Form } from "antd";
import { Modal, Input } from "@bbdevcrew/bb_ui_kit_fe";
import CheckboxList, { checkboxListRequired } from "./../CheckboxList";

import { meSelector } from "@store/me/selectors";

import { getSectionValues } from "./DownloadReportModal.helpers";

import s from "./DownloadReportModal.module.less";

import { IDownloadReportModalProps, IShareReportForm } from "./DownloadReportModal.types";

import { DownloadIcon } from "@assets/index";

export const DownloadReportModal: React.FC<IDownloadReportModalProps> = ({
  open,
  onCancel,
  onFinish,
  sections,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IShareReportForm>();

  const me = useSelector(meSelector);

  useEffect(() => {
    if (open) form.resetFields();
  }, [form, open]);

  const initialValues = useMemo(
    () => ({
      name: me?.client?.name,
      ...getSectionValues(t, sections),
    }),
    [me, sections, t],
  );

  return (
    <Modal
      width={600}
      onCancel={onCancel}
      onOk={() => form.submit()}
      noPadding
      title={t("components:pdfReport:downloadModal:title")}
      open={open}
      cancelButtonProps={{
        _type: "secondary",
      }}
      confirmLabel={
        <>
          <DownloadIcon /> {t("components:pdfReport:downloadPdf")}
        </>
      }
      {...restProps}
    >
      <div className={s.bbDownloadReportModal}>
        <Form
          form={form}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <Form.Item
            label={t("components:pdfReport:downloadModal:inputLabel")}
            name="name"
            rules={[
              {
                required: true,
                message: t("generic:validation:required"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {!!sections?.length && (
            <Form.Item
              name="sections"
              valuePropName="options"
              label={t("components:pdfReport:sectionsTitle")}
              rules={[...checkboxListRequired(t("generic:validation:required"))]}
              className={s.bbDownloadReportModalSections}
            >
              <CheckboxList />
            </Form.Item>
          )}
        </Form>
      </div>
    </Modal>
  );
};
