import { t } from "i18next";

type InputType = "keywords";

function generateAllState(
  selectedValues?: string[],
  excludedValues?: string[],
  placeholder?: string,
  showDefaultPlaceholder?: boolean,
) {
  const nothingSelected = !selectedValues?.length && !excludedValues?.length;

  if (showDefaultPlaceholder || nothingSelected) return placeholder;
}

function generateSelectedState(selectedValues?: string[], type?: InputType) {
  if (selectedValues && selectedValues.length >= 1)
    return t(`components:filters:${type === "keywords" ? "specifiedTag" : "selectedTag"}`, {
      number: selectedValues.length,
    });
}

function generateExcludedState(excludedValues?: string[]) {
  if (excludedValues && excludedValues.length >= 1)
    return t("components:filters:excludedTag", {
      number: excludedValues.length,
    });
}

export function getMultiSelectState(
  selectedValues?: string[],
  excludedValues?: string[],
  type?: InputType,
  placeholder?: string,
  showDefaultPlaceholder?: boolean,
) {
  const result: { all?: string; selected?: string; excluded?: string } = {};

  result.all = generateAllState(
    selectedValues,
    excludedValues,
    placeholder,
    showDefaultPlaceholder,
  );
  result.selected = generateSelectedState(selectedValues, type);
  result.excluded = generateExcludedState(excludedValues);

  return result;
}

export function generateInputValueState(
  selectedValues?: string[],
  excludedValues?: string[],
  placeholder?: string,
  showDefaultPlaceholder?: boolean,
) {
  return getMultiSelectState(
    selectedValues,
    excludedValues,
    undefined,
    placeholder,
    showDefaultPlaceholder,
  );
}

export function generateKeywordsInputValueState(
  selectedValues?: string[],
  excludedValues?: string[],
) {
  return getMultiSelectState(selectedValues, excludedValues, "keywords");
}

export function getSingleSelectState(selectedValue?: string) {
  return {
    all: selectedValue,
    selected: selectedValue,
    excluded: undefined,
  };
}

export function generateInputValueStateSingle(selectedValue?: string) {
  return getSingleSelectState(selectedValue);
}
