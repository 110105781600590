import { createAction } from "typesafe-actions";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_ALL_AS_READ,
  MARK_AS_READ,
  MARK_AS_READ_FAILURE,
  MARK_AS_READ_SUCCESS,
} from "./actionTypes";
import { INotificationsResponse } from "./types";

export const getNotificationsAction = createAction(GET_NOTIFICATIONS)();
export const getNotificationsSuccessAction =
  createAction(GET_NOTIFICATIONS_SUCCESS)<INotificationsResponse>();
export const getNotificationsFailureAction = createAction(GET_NOTIFICATIONS_FAILURE)<void>();

export const markAllAsReadAction = createAction(MARK_ALL_AS_READ)();
export const markAsReadAction = createAction(MARK_AS_READ)<string>();
export const markAsReadSuccessAction = createAction(MARK_AS_READ_SUCCESS)<void>();
export const markAsReadFailureAction = createAction(MARK_AS_READ_FAILURE)<void>();
