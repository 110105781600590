import React from "react";
import { i18n } from "i18next";

import { useToggle } from "@bbdevcrew/bb_ui_kit_fe";
import { getI18next } from "../../../../languages/i18next";
import { IActionDropdownItem } from "../../ActionsDropdown/ActionsDropdown.type";

import { DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";

const i18nextinstance = getI18next() as i18n;

export const useBrandReplyDropdownItems = () => {
  const { isToggleSelected, toggleSelected } = useToggle(false);

  const brandReplyDropdownItems: IActionDropdownItem[] = [
    {
      id: "deleteBrandReply",
      icon: <DeleteIcon />,
      label: i18nextinstance.t("generic:delete"),
      onClick: toggleSelected,
    },
  ];

  return {
    brandReplyDropdownItems,
    showDeleteReplyAlert: isToggleSelected,
    toggleDeleteReplyAlert: toggleSelected,
  };
};
