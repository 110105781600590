import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { isYoutubePlatform } from "./platform";
import { meSelector, meSuccessfulSelector } from "@store/me/selectors";

export function useYTSelected(platform_types?: string[]) {
  const [isYTFilterSelected, setIsYTFilterSelected] = useState(false);

  const me = useSelector(meSelector);
  const meFetched = useSelector(meSuccessfulSelector);

  useEffect(() => {
    if (me && meFetched && platform_types) {
      const ytSelected = platform_types?.includes("youtube");
      // if none of the platforms are selected, it means they're all selected
      const allSelected =
        !!me?.client_data.platform_types.find(item => isYoutubePlatform(item.id)) &&
        !platform_types?.length;

      setIsYTFilterSelected(ytSelected || allSelected);
    }
  }, [platform_types, me, meFetched]);

  return isYTFilterSelected;
}
