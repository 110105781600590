import { RootState } from "..";
import { IPlatformAsset } from "@components/_common/AssetTabs/AssetTabs.type";
import { PlatformType } from "../platform/types";

export const fromOnboardingPlatformTypes = (state: RootState) => state.onboardingPlatformTypes;

export const onboardingPlatformTypesSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).platformTypes;

export const fetchingOnboardingPlatformTypesSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).fetchingPlatformTypes;
export const fetchedOnboardingPlatformTypesSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).fetchedPlatformTypes;
export const fetchedOnboardingPlatformTypesFailSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).fetchedPlatformTypesFail;

export const selectedAssetsSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).selectedAssets;

export const postingOnboardingPlatformTypeAssetsSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).postingPlatformTypeAssets;
export const postedOnboardingPlatformTypeAssetsSelector = (state: RootState) =>
  fromOnboardingPlatformTypes(state).postedPlatformTypeAssets;

const isMissingOrganicAssetsSelector = (
  platformId: PlatformType,
  addedAssets: IPlatformAsset[],
) => {
  const hasAdsAdded =
    addedAssets.filter(({ asset_platform }) => asset_platform === `${platformId}_ads`).length > 0;

  if (!hasAdsAdded) return false;

  const hasOrganicAdded =
    addedAssets.filter(({ asset_platform }) => asset_platform === platformId).length > 0;

  return !hasOrganicAdded;
};

export const missingFBOrganicSelector = (state: RootState) =>
  isMissingOrganicAssetsSelector("facebook", selectedAssetsSelector(state));
export const missingTwitterOrganicSelector = (state: RootState) =>
  isMissingOrganicAssetsSelector("twitter", selectedAssetsSelector(state));
