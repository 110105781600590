import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../_common/breadcrumbs";
import TrendingHashtagsTable from "./TrendingHashtagsTable";
import { TiktokTrendingHashtagsFilters } from "./TiktokTrendingHashtagsFilters";

import {
  tiktokTrendingHashtagsSelector,
  fetchingTiktokTrendingHashtagsSelector,
} from "@store/tiktokHashtags/selectors";
import { getTrendingHashtagsAction } from "@store/tiktokHashtags/actions";

import s from "./TiktokTrendingHashtags.module.less";

import { IFilterValue } from "@store/tiktokHashtags/types";

export const TiktokTrendingHashtags = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filterValues, setFilterValues] = useState<IFilterValue>({
    industryId: "ALL",
    countryId: "US",
    dateRangeId: "7DAY",
  });
  const [page, setPage] = useState<number>(1);

  const trendingHashtagsList = useSelector(tiktokTrendingHashtagsSelector);
  const fetchingTiktokTrendingHashtags = useSelector(fetchingTiktokTrendingHashtagsSelector);

  const getTrendingHashtags = useCallback(
    data => dispatch(getTrendingHashtagsAction(data)),
    [dispatch],
  );

  useEffect(() => {
    getTrendingHashtags({ ...filterValues });
  }, [filterValues, getTrendingHashtags]);

  const onChangeFilter = (filter: keyof IFilterValue) => (value: string) => {
    setFilterValues(prev => ({ ...prev, [filter]: value }));
    setPage(1);
  };

  const onTablePageChange = (value: number) => setPage(value);

  return (
    <>
      <Breadcrumbs title={t("components:listen:trendingHashtags:title")} />
      <div className={s.bbTTTrendingHashtags}>
        <div className={s.bbTTTrendingHashtagsHeader}>
          <TiktokTrendingHashtagsFilters value={filterValues} onChange={onChangeFilter} />
        </div>
        <TrendingHashtagsTable
          data={trendingHashtagsList}
          loading={fetchingTiktokTrendingHashtags}
          onChangePage={onTablePageChange}
          page={page}
        />
      </div>
    </>
  );
};
