import React, { MouseEvent, FC } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./LinkedinLoginButton.module.less";

import { LinkedinIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ILinkedinLoginButtonProps {
  isLoading?: boolean;
  onClickLogin: (event: MouseEvent) => void;
}

const LinkedinLoginButton: FC<ILinkedinLoginButtonProps> = ({ isLoading, onClickLogin }) => {
  const { t } = useTranslation();

  const loading = (
    <Loading
      type="spinning"
      isLoading
      style={{
        width: 204,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );

  return (
    <div
      onClick={onClickLogin}
      className={s.bbLinkedinLoginButton}
      data-stonly-target="app-global__linkedin-login-button"
    >
      {isLoading ? (
        loading
      ) : (
        <>
          <span className={s.bbLinkedinIcon}>
            <LinkedinIcon />
          </span>
          <span className={s.bbLinkedinLoginButtonText}>
            {t("components:signInButtons:linkedin")}
          </span>
        </>
      )}
    </div>
  );
};

export default LinkedinLoginButton;
