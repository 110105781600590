import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import GoogleLoginButton from "../SocialLoginButton/googleLoginButton/GoogleLoginButton";
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from "react-google-login";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { PlatformType } from "@store/platform/types";

interface IBBGoogleLoginProps {
  id: PlatformType;
  app_id: string;
  scopes: string[];
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  onLoginFail?: (id: PlatformType) => void;
  onLoginSuccess?: (
    id: PlatformType,
    accessToken: string,
    authorizationCode?: string,
    shouldRelogin?: boolean,
  ) => void;
}

const BBGoogleLogin: FC<IBBGoogleLoginProps> = ({
  id,
  app_id,
  scopes,
  isLoading,
  setIsLoading,
  onLoginFail,
  onLoginSuccess,
}) => {
  const { t } = useTranslation();

  const onGoogleLoginCallback = (
    _id: PlatformType,
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    setIsLoading(false);

    if (!response.code) {
      addToast({
        type: "warning",
        title: t("components:addAssets:cancelledLogin"),
        message: t("components:addAssets:cancelledLoginDescription"),
      });
      onLoginFail && onLoginFail(_id);
    } else {
      onLoginSuccess && onLoginSuccess(_id, response.code, response.code);
    }
  };

  const loginBtn = (
    <GoogleLoginButton
      isLoading={isLoading}
      onClickLogin={() => {
        setIsLoading(true);
        const GoogleAuth =
          // eslint-disable-next-line
          // @ts-ignore
          window.gapi?.auth2?.getAuthInstance();
        GoogleAuth.grantOfflineAccess({
          scope: scopes.join(" "),
          prompt: "consent",
        })
          .then((response: GoogleLoginResponse | GoogleLoginResponseOffline) =>
            onGoogleLoginCallback(id, response),
          )
          .catch((response: GoogleLoginResponse | GoogleLoginResponseOffline) =>
            onGoogleLoginCallback(id, response),
          );
      }}
    />
  );

  return (
    <GoogleLogin
      prompt="consent"
      responseType="code"
      accessType="offline"
      render={() => loginBtn}
      clientId={app_id}
      onFailure={(response: GoogleLoginResponse | GoogleLoginResponseOffline) =>
        onGoogleLoginCallback(id, response)
      }
      scope={scopes.join(" ")}
      onSuccess={(response: GoogleLoginResponse | GoogleLoginResponseOffline) =>
        onGoogleLoginCallback(id, response)
      }
      cookiePolicy="single_host_origin"
    />
  );
};

export default BBGoogleLogin;
