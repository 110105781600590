import { RootState } from "..";

export const fromReports = (state: RootState) => state.reports;

export const pdfReportSelector = (search_id: string) => (state: RootState) =>
  fromReports(state).pdfReports?.[search_id] || {};

export const creatingScheduledReportSelector = (state: RootState) =>
  fromReports(state).fetchingPostScheduledReports;
export const createdScheduledReportSelector = (state: RootState) =>
  fromReports(state).fetchedPostScheduledReports;

export const updatingScheduledReportSelector = (state: RootState) =>
  fromReports(state).updatingScheduledReport;
export const updatedScheduledReportSelector = (state: RootState) =>
  fromReports(state).updatedScheduledReport;

export const scheduledReportsSelector = (state: RootState) => fromReports(state).scheduledReports;
export const fetchingScheduledReportsSelector = (state: RootState) =>
  fromReports(state).fetchingScheduledReports;
export const fetchedScheduledReportsSelector = (state: RootState) =>
  fromReports(state).fetchedScheduledReports;

export const deletingScheduledReportSelector = (state: RootState) =>
  fromReports(state).deletingScheduledReport;
