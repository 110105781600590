import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, MouseEvent, useState, ChangeEvent, useEffect } from "react";

import { Dropdown, Loading } from "@bbdevcrew/bb_ui_kit_fe";
import TagsDropdownHeader from "./TagsDropdownHeader";
import TagsDropdownListing from "./TagsDropdownListing";

import s from "./TagsDropdown.module.less";

import { IPostTag } from "@store/postTags/types";
import { ITagsDropdownProps, ICustomTag } from "./TagsDropdown.type";
import { DropdownMenuPlacementType } from "@bbdevcrew/bb_ui_kit_fe";

const cx = cn.bind(s);

const TagsDropdown: FC<ITagsDropdownProps> = ({
  id,
  tags = [],
  initialTags = [],
  tagsStateData,
  dropdownClassName,
  trigger: TriggerComponent,
  _dropdownPlacement = "bottomLeft",
  fetchTags,
  onCreateTag,
  onSelectTag,
  onSelectTagCallback,
  deleteTagFromDropdown,
}) => {
  const { t } = useTranslation();
  const { tagsLoading, tagsLoaded, tagCreating, tagCreated, lastCreatedTag } = tagsStateData;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownTags, setDropdownTags] = useState<ICustomTag[] | IPostTag[]>([]);
  const [dropdownPlacement, setDropdownPlacement] =
    useState<DropdownMenuPlacementType>(_dropdownPlacement);
  const [inputValue, setInputValue] = useState("");
  const [existingTagEntered, setExistingTagEntered] = useState(false);

  const getInitialUniqueDropdownTags = () => {
    const tagLabels = tags.map(tag => tag.label);

    return initialTags.filter(tag => !tagLabels.includes(tag.label));
  };

  const setInitialUniqueDropdownTags = () => {
    setDropdownTags(getInitialUniqueDropdownTags());
  };

  useEffect(() => {
    if (isDropdownOpen) fetchTags();
    else {
      setInputValue("");
      setExistingTagEntered(false);
    }
    // eslint-disable-next-line
  }, [isDropdownOpen]);

  useEffect(() => {
    if (tagsLoaded) setInitialUniqueDropdownTags();
    // eslint-disable-next-line
  }, [tagsLoaded, tags.length]);

  useEffect(() => {
    const shouldSelectedLastCreatedTag = !!(onSelectTagCallback && tagCreated && lastCreatedTag);
    if (shouldSelectedLastCreatedTag) {
      onSelectTagCallback(lastCreatedTag);
      setIsDropdownOpen(false);
      fetchTags();
    }
    // eslint-disable-next-line
  }, [onSelectTagCallback, tagCreated, lastCreatedTag]);

  const filterDropdownTagsByInput = (input: string) => {
    const initialUniqueDropdownTags = getInitialUniqueDropdownTags();

    const visibleTags = initialUniqueDropdownTags.filter(tag => {
      const tagLabel = tag.label.toUpperCase().toLowerCase();
      const inputTextLowercase = input.toUpperCase().toLowerCase();

      return tagLabel.includes(inputTextLowercase);
    });
    setDropdownTags(visibleTags);
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredText = event.target.value;
    setInputValue(enteredText);

    if (enteredText) {
      filterDropdownTagsByInput(enteredText);

      const tagExistsInDropdown = dropdownTags.find(tag => tag.label === enteredText);
      const tagIsAlreadyAdded = tags.find(tag => tag.label === enteredText);

      if (tagExistsInDropdown || tagIsAlreadyAdded) {
        setExistingTagEntered(true);
      } else {
        setExistingTagEntered(false);
      }
    } else {
      setInitialUniqueDropdownTags();
      setExistingTagEntered(false);
    }
  };

  const onTagSelect = (tag: ICustomTag | IPostTag) => {
    onSelectTag(tag);
    setInputValue("");
    setIsDropdownOpen(false);

    const tagsCopy = [...dropdownTags];
    setDropdownTags(tagsCopy.filter(_tag => _tag.id !== tag.id));
  };

  const onAddNewTag = () => {
    onCreateTag(inputValue);
    setInputValue("");

    if (!onSelectTagCallback) {
      setIsDropdownOpen(false);
    }
  };

  const toggleDropdown = (e: MouseEvent) => {
    e.preventDefault();

    if (!isDropdownOpen) {
      const middlehalf = window.innerHeight / 2;

      if (e.clientY > middlehalf) setDropdownPlacement("topLeft");
      else setDropdownPlacement("bottomLeft");
    }

    setIsDropdownOpen(!isDropdownOpen);
  };

  const onRemoveTag = (e: MouseEvent, tag: ICustomTag | IPostTag) => {
    e.stopPropagation();
    tag.id && deleteTagFromDropdown?.(tag.id);
    setIsDropdownOpen(false);
    const tagsCopy = [...dropdownTags];
    setDropdownTags(tagsCopy.filter(_tag => _tag.id !== tag.id));
  };

  return (
    <Dropdown
      open={isDropdownOpen}
      placement={dropdownPlacement}
      setOpen={open => setIsDropdownOpen(open)}
      menuClassName={s.bbTagsDropdownMenu}
      dropdownClassName={cx(s.bbTagsDropdown, {
        dropdownClassName,
      })}
      trigger={React.cloneElement(TriggerComponent as React.ReactElement, {
        onClick: toggleDropdown,
      })}
    >
      <div
        id={`${id}-dropdown-trigger`}
        data-cy={`${id}-dropdown-trigger`}
        className={s.bbTagsDropdownTriggerWrapper}
      >
        <TagsDropdownHeader
          id={id}
          inputValue={inputValue}
          isDropdownOpen={isDropdownOpen}
          isTagBeingCreated={tagCreating}
          existingTagEntered={existingTagEntered}
          onAddNewTag={onAddNewTag}
          onInputChange={onInputChange}
          title={t(`components:${id}:addTitle`)}
        />

        {tagsLoading ? (
          <div className={s.bbTagsLoading}>
            <Loading type="spinning" isLoading />
          </div>
        ) : tagsLoaded && dropdownTags.length ? (
          <TagsDropdownListing
            searchTerm={inputValue}
            dropdownTags={dropdownTags}
            selectTag={onTagSelect}
            removeTag={!!deleteTagFromDropdown ? onRemoveTag : undefined}
          />
        ) : null}
      </div>
    </Dropdown>
  );
};

export default TagsDropdown;
