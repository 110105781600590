import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { IPostID } from "../CommentDetail/CommentDetail.type";

import s from "./PostView.module.less";

const PostID: FC<IPostID> = ({ post_id }) => {
  const { t } = useTranslation();

  if (!post_id) return null;

  return (
    <div className={s.bbPostID}>
      <p className={s.bbPostIDTitle}>{t("components:comments:details:postID")}</p>
      <p className={s.bbPostIDNumber}>{post_id}</p>
    </div>
  );
};

export default PostID;
