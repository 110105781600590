import { ajax } from "rxjs/ajax";
import { Auth, JS } from "aws-amplify";
import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import ConfirmSignUp from "./ConfirmSignUp";
import { ConfirmSignUp as AWSConfignSignUp } from "aws-amplify-react";

import s from "./ConfirmSignUpView.module.less";

import { signUp } from "@utils/paths";
import { IFormValues } from "./ConfirmSignUp.type";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { IAuthPieceProps } from "aws-amplify-react/lib-esm/Auth/AuthPiece";

interface IConfirmSignUpViewProps {
  // eslint-disable-next-line
  changeState: (state: string, data?: any) => void;
  authData: { attributes: { email: string; email_verified: boolean } };
}

const ConfirmSignUpViewComponent: FC<IConfirmSignUpViewProps> = ({ changeState, authData }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line
  const checkContact = async (user: any) => {
    await Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        changeState("signedIn", user);
      } else {
        user = Object.assign(user, data);
        changeState("signIn", user);
      }
    });
  };

  const valid = async (values: IFormValues, callback?: () => void) => {
    const user = authData;

    await Auth.confirmSignIn(user, values.code, "SOFTWARE_TOKEN_MFA")
      .then(() => checkContact(user))
      .catch(err => {
        callback?.();
        addToast({
          type: "danger_accent",
          title: t("generic:error"),
          message: err.response.message,
        });
      });
  };

  const resendCode = (e: MouseEvent) => {
    e.preventDefault();
    ajax
      .post(`${signUp}/verification`, {
        action: "resend",
        email: authData.attributes.email,
      })
      .subscribe(
        () => {
          addToast({
            type: "info_accent",
            title: t("components:confirmSignIn:notification:codeSent:message"),
            message: t("components:confirmSignIn:notification:codeSent:description"),
          });
        },
        err => {
          addToast({
            type: "danger_accent",
            title: t("generic:error"),
            message: err.response.message,
          });
        },
      );
  };

  const onFinish = (values: IFormValues, callback?: () => void) => {
    if (
      authData &&
      authData.attributes &&
      authData.attributes.email_verified === false &&
      authData.attributes.email &&
      values?.code
    ) {
      ajax
        .post(`${signUp}/verification`, {
          action: "verify_code",
          code: values.code,
          email: authData.attributes.email,
        })
        .subscribe(
          () => {
            changeState("signedIn", { isSigningIn: true });
            window.location.href = `${process.env.BASE_URL}/insights/overview`;
          },
          err => {
            callback?.();
            addToast({
              type: "danger_accent",
              title: t("generic:error"),
              message: err.response.message,
            });
          },
        );
    } else {
      valid(values, callback);
    }
  };

  return (
    <div className={s.bbConfirmSignUpViewContent}>
      <ConfirmSignUp
        onFinish={onFinish}
        handleSubmit={e =>
          authData?.attributes?.email_verified === false ? resendCode(e) : changeState("signIn")
        }
      />
    </div>
  );
};

class ConfirmSignUpView extends AWSConfignSignUp {
  constructor(props: IAuthPieceProps) {
    super(props);

    this._validAuthStates = ["confirmSignUp"];
  }

  showComponent() {
    return (
      <ConfirmSignUpViewComponent changeState={this.changeState} authData={this.props.authData} />
    );
  }
}

export default ConfirmSignUpView;
