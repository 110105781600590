import { Observable } from "rxjs";
import { StateObservable } from "redux-observable";
import { ajax } from "rxjs/ajax";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "../";
import { AssetGroupsActions } from "./types";
import {
  GET_ASSET_GROUP,
  CREATE_ASSET_GROUP,
  UPDATE_ASSET_GROUP,
  DELETE_ASSET_GROUP,
} from "./actionTypes";

import { assetGroups } from "@utils/paths";

import {
  getAssetGroupActionFailure,
  getAssetGroupActionSuccess,
  createAssetGroupActionFailure,
  createAssetGroupActionSuccess,
  updateAssetGroupActionFailure,
  updateAssetGroupActionSuccess,
  deleteAssetGroupActionFailure,
  deleteAssetGroupActionSuccess,
} from "./actions";
import {
  IAssetGroupData,
  IModifiableAssetGroupData,
} from "@components/_common/AssetGroupModal/AssetGroupModal.types";
import { getHeaders } from "@utils/headers";
import { patchChecklistAction } from "../me/actions";
import { handleError } from "@utils/apiErrorHandler";

const serializePutPayload = (assetGroup: IAssetGroupData): IModifiableAssetGroupData => {
  const data: IModifiableAssetGroupData = {
    name: assetGroup.name,
    group_id: assetGroup.group_id,
    description: assetGroup.description,
    asset_ids: assetGroup.asset_ids,
    order_number: assetGroup.order_number,
    is_advanced: assetGroup.is_advanced,
    is_internal: assetGroup.is_internal,
    advanced_filters: assetGroup.advanced_filters,
  };

  return data;
};

// GET
export const getAssetGroupsEpic = (
  action$: Observable<AssetGroupsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(GET_ASSET_GROUP)),
    switchMap(() => {
      return ajax
        .get<IAssetGroupData[]>(
          `${assetGroups}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getAssetGroupActionSuccess(data)),
          catchError(err => handleError(err, getAssetGroupActionFailure)),
        );
    }),
  );
};

// POST
export const postAssetGroupsEpic = (
  action$: Observable<AssetGroupsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(CREATE_ASSET_GROUP)),
    switchMap(a => {
      return ajax
        .post<IAssetGroupData>(
          `${assetGroups}`,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          switchMap(data => {
            const checklist = state$.value.me.data?.checklist?.insights;
            const isGroupAssetsChecked = !!checklist?.items.find(
              ({ id, checked }) => id === "group_assets" && checked,
            );

            // Update checklist
            if (checklist && !isGroupAssetsChecked) {
              return [
                createAssetGroupActionSuccess(data),
                patchChecklistAction(checklist, "group_assets"),
              ];
            }

            return [createAssetGroupActionSuccess(data)];
          }),
          catchError(err => handleError(err, createAssetGroupActionFailure)),
        );
    }),
  );
};

// PUT
export const putAssetGroupsEpic = (
  action$: Observable<AssetGroupsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(UPDATE_ASSET_GROUP)),
    switchMap(a => {
      return ajax
        .put<IAssetGroupData>(
          `${assetGroups}/${a.payload.id}`,
          serializePutPayload(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => updateAssetGroupActionSuccess(data)),
          catchError(err => handleError(err, updateAssetGroupActionFailure)),
        );
    }),
  );
};

// DELETE
export const deleteAssetGroupsEpic = (
  action$: Observable<AssetGroupsActions>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(DELETE_ASSET_GROUP)),
    switchMap(a => {
      return ajax
        .delete(
          `${assetGroups}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteAssetGroupActionSuccess(a.payload)),
          catchError(err => handleError(err, deleteAssetGroupActionFailure)),
        );
    }),
  );
};
