import { RootState } from "..";

export const fromShortenedSearches = (state: RootState) => state.shortenedSearches;

export const shortenedSearchesIdSelector = (state: RootState) => fromShortenedSearches(state).id;
export const shortenedSearchesExplorerLinkSelector = (state: RootState) =>
  fromShortenedSearches(state).explorer_link;

export const getShortenedSearchesResponseSelector = (state: RootState) =>
  fromShortenedSearches(state).filters;
export const clientSwitchedSelector = (state: RootState) =>
  fromShortenedSearches(state).is_client_switched;

export const dateRangeSelector = (state: RootState) => ({
  start_day: fromShortenedSearches(state).start_day,
  end_day: fromShortenedSearches(state).end_day,
});

export const getShortenedSearchesFailedSelector = (state: RootState) =>
  fromShortenedSearches(state).fetchedGetShortenedSearchesFail;
export const getShortenedSearchesSuccessfulSelector = (state: RootState) =>
  fromShortenedSearches(state).fetchedGetShortenedSearches;

export const postShortenedSearchesSuccessfulSelector = (state: RootState) =>
  fromShortenedSearches(state).fetchedPostShortenedSearches;
