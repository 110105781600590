import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useState } from "react";

import { Input, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import ClearableTrigger from "@components/_common/SelectTrigger/ClearableTrigger";

import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import { mergeDedupe } from "../helper";
import { useTagState } from "../common";
import { ICampaignFiltersProps } from "./Campaigns.type";
import { IGetAutocompleteOptionsPayload } from "@store/autocomplete/types";
import { debounce, useSearch, IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { IClearableTriggerProps } from "@components/_common/SelectTrigger/ClearableTrigger.types";

import s from "./Campaigns.module.less";

const CampaignFilters: FC<ICampaignFiltersProps> = ({
  name,
  value,
  disabled,
  placeholder,
  customprops,
  dropdownPlacement,
  dropdownMatchSelectWidth,
  InputVariation = Input,
  onChange,
  ...props
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [options, setOptions] = useState<IObjectOption[]>([]);

  const autocompleteOptions = useSelector(autocompleteOptionsSelector);

  const {
    excludedValues,
    tagFilterValues,
    _onChange,
    _onClearValues,
    _onExcludeChange,
    _setTagFilterValues,
  } = useTagState(options, customprops, value, onChange);

  const { SearchInput, searchTerm, setSearchTerm } = useSearch(options, t("generic:search"));

  // eslint-disable-next-line
  const getAutocompleteOptions = useCallback(
    debounce((payload: IGetAutocompleteOptionsPayload) => {
      dispatch(getAutocompleteOptionsAction(payload));
    }, 300),
    [dispatch],
  );

  const fetchValues = (term: string) => {
    getAutocompleteOptions({
      field: "campaign",
      query: term,
    });
  };

  useEffect(() => {
    fetchValues(searchTerm);
    // eslint-disable-next-line
  }, [searchTerm]);

  useEffect(() => {
    const _options = autocompleteOptions.campaign.map(item => ({
      ...item,
      additionalLabel: props?.additionalLabelFields?.map(field => item[field as keyof typeof item]),
    }));

    const searchedOptions = searchTerm
      .split(",")
      .map(term => term.trim())
      .filter(_term => !!_term);

    setOptions(_options as IObjectOption[]);

    if (searchTerm.includes(",") && _options.length <= searchedOptions.length) {
      _setTagFilterValues(
        mergeDedupe(
          tagFilterValues,
          _options.map(o => o.id),
        ),
      );
    }
    // eslint-disable-next-line
  }, [autocompleteOptions.campaign]);

  const _onClear = () => {
    _onClearValues();
  };

  const getTooltipItem = (_value: string) => options.find(c => c?.id === _value)?.label || _value;

  const TriggerWithTooltip = (tooltipProps: IClearableTriggerProps) => {
    const triggerNotEmpty = !!tagFilterValues.length || !!excludedValues?.length;

    const tooltipElement = (
      <>
        {tagFilterValues.map(tagFilterValue => (
          <span key={tagFilterValue} className={s.bbDisplayBlock}>
            {getTooltipItem(tagFilterValue)}
          </span>
        ))}
      </>
    );

    const excludedTooltipElement = (
      <>
        {excludedValues?.map(excludedValue => (
          <span key={excludedValue} className={s.bbDisplayBlock}>
            {getTooltipItem(excludedValue)}
          </span>
        ))}
      </>
    );

    return ClearableTrigger({
      tooltip: triggerNotEmpty ? tooltipElement : undefined,
      excludedTooltip: triggerNotEmpty ? excludedTooltipElement : undefined,
      onClear: _onClear,
      ...tooltipProps,
    });
  };

  return (
    <div data-cy="app-filter-campaign_ids" data-stonly-target="app-global__filters--campaign_ids">
      <SelectStyledMulti
        _size="sm"
        id="campaign_ids"
        name={name}
        options={options}
        disabled={disabled}
        onChange={_onChange}
        value={tagFilterValues}
        highlightTerm={searchTerm}
        DropdownHeader={SearchInput}
        InputVariation={InputVariation}
        excludedValues={excludedValues}
        excludePlaceholder={t("generic:exclude")}
        excludedPlaceholder={t("generic:excluded")}
        TriggerVariation={TriggerWithTooltip}
        onExcludeChange={_onExcludeChange}
        dropdownPlacement={dropdownPlacement}
        onDropdownClose={() => setSearchTerm("")}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        data-cy={props["data-cy" as keyof typeof props]}
        placeholder={placeholder || t("components:comments:filterByTags:placeholder")}
      />
    </div>
  );
};

export default CampaignFilters;
