import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import CommentAction from "../commentAction/CommentAction";

import { IComment } from "@store/comments/types";

import { PostIcon } from "@assets/index";

interface IActionDetailsProps {
  className?: string;
  comment: IComment;
  onClickViewDetails?: () => void;
}

const ActionDetails: FC<IActionDetailsProps> = ({
  comment,
  className = "",
  onClickViewDetails,
}) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  return (
    <CommentAction
      className={className}
      onClick={onClickViewDetails}
      type={!comment.actions.can_reply ? "disabled" : "primary"}
      tooltipProps={{
        title: t("components:comments:actions:viewDetails"),
      }}
    >
      <PostIcon />
      {screens.sm && <span>{t("components:comments:actions:details")}</span>}
    </CommentAction>
  );
};

export default ActionDetails;
