import { i18n } from "i18next";
import { TooltipProps } from "antd";
import { getI18next } from "../../../../languages/i18next";

export const i18nextInstance = getI18next() as i18n;

const EDIT_TOOLTIP_TITLE = i18nextInstance.t("components:publish:calendar:editTooltip");

const EDIT_TOOLTIP_PLACEMENT = "top";

const EDIT_TOOLTIP_FONT_SIZE = 12;

const EDIT_TOOLTIP_WIDTH = "74px";

const EDIT_TOOLTIP_TEXT_ALIGNMENT = "center";

export const EDIT_TOOLTIP_CONFIG: TooltipProps = {
  title: EDIT_TOOLTIP_TITLE,
  placement: EDIT_TOOLTIP_PLACEMENT,
  overlayInnerStyle: {
    fontSize: EDIT_TOOLTIP_FONT_SIZE,
    width: EDIT_TOOLTIP_WIDTH,
    textAlign: EDIT_TOOLTIP_TEXT_ALIGNMENT,
  },
};

export const TOOLTIP_ERROR_WIDTH = 360;

export const TOOLTIP_HIDDEN_CONFIG = { title: "" };
