import React from "react";
import { useTranslation } from "react-i18next";

import { Avatar } from "antd";

import CollaborationAvatar from "./CollaborationAvatar";

import { getPageName } from "./PostCard.helpers";

import s from "./PostCard.module.less";

import { IPostCardHeaderProps } from "./PostCardHeader.types";

const PostCardHeader: React.FC<IPostCardHeaderProps> = ({
  pageName,
  pageImage,
  headerActionItem,
  postTypeText,
  pageImageShape = "square",
  pageCollaborators = [],
}) => {
  const { t } = useTranslation();
  return (
    <div className={s.bbPostHeader} data-cy="post-header">
      {pageCollaborators.length > 0 ? (
        <CollaborationAvatar src={pageImage as string} />
      ) : (
        <Avatar
          size={30}
          src={pageImage}
          shape={pageImageShape}
          className={s.bbPostLogo}
          data-cy="post-logo"
        />
      )}
      <div className={s.bbPostInfo} data-cy="post-info">
        <span>{getPageName(pageName, pageCollaborators, t)}</span>
        {!!postTypeText && <small>{postTypeText}</small>}
      </div>

      {headerActionItem}
    </div>
  );
};

export default PostCardHeader;
