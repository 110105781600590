import classNames from "classnames";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Avatar } from "antd";
import MessageCardHeader from "./MessageCardHeader";
import { Icon, Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import AssignUser from "@containers/AssignUser/AssignUser";
import CommentActionError from "../CommentCard/cardHeader/CommentActionError";

import { meSelector } from "@store/me/selectors";
import { assignCommentUserAction } from "@store/comments/actions";

import s from "./MessageCard.module.less";

import { getInitials } from "./helpers";
import { IMessageCardProps } from "./MessageCard.type";

import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";

const MessageCard: FC<IMessageCardProps> = ({
  size = "md",
  type = "default",
  date,
  author,
  footer,
  message,
  isHidden,
  avatarSrc,
  sentiment,
  actionError,
  avatarTooltip,
  assignedUsers,
  commentPhrase,
  recommendationType,
  assigningCommentId,
  actionDropdownItems,
  hiddenIconTooltipTitle,
  messageTranslated,
  commentTooltips,
  hideActions = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const [isMessageTranslated, setIsMessageTranslated] = useState(false);

  const avatarCursorClass = avatarTooltip ? "cursor-help" : "cursor-default";

  const onAssignUserChange = (value: string[]) => {
    if (assigningCommentId) {
      dispatch(assignCommentUserAction(assigningCommentId, { assigned_users: value }, me?.id));
    }
  };

  const ViewTranslationLink = () => {
    const onClickTranslate = () => {
      setIsMessageTranslated(state => !state);
    };

    return (
      <Button _size="sm" _type="link" className={s.bbTranslateBtn} onClick={onClickTranslate}>
        {isMessageTranslated
          ? t("components:comments:actions:showOriginal")
          : t("components:comments:actions:translate")}
      </Button>
    );
  };

  return (
    <div data-testid="message-card" className={s.bbMessageCardWrapper}>
      <Tooltip title={avatarTooltip || ""}>
        <Avatar
          size={32}
          src={avatarSrc}
          className={classNames(s.bbMessageCardAvatar, avatarCursorClass)}
        >
          {!avatarSrc && author ? getInitials(author) : undefined}
        </Avatar>
      </Tooltip>
      <div
        className={classNames(
          s[`bbMessageCardSentiment-${sentiment}`],
          s[`bbMessageCardBackground-${type}`],
          s[`bbMessageCardSize-${size}`],
          s.bbMessageCard,
          {
            [s.bbMessageCardSentiment]: !!sentiment,
          },
        )}
      >
        <div className={s.bbMessageCardHeaderWrapper}>
          <MessageCardHeader
            date={date || ""}
            author={author || ""}
            commentPhrase={commentPhrase}
            recommendationType={recommendationType}
            commentTooltips={commentTooltips}
          />

          {!hideActions && (
            <div className={s.bbMessageCardRightActionsWrapper}>
              {!!actionError && <CommentActionError actionError={actionError} />}

              {isHidden && (
                <Tooltip title={hiddenIconTooltipTitle}>
                  <Icon
                    icon={EyeOffIcon}
                    className={classNames(s.bbMessageCardHiddenIcon, {
                      [s.bbMessageCardHiddenIconWithTooltip]: !!hiddenIconTooltipTitle,
                    })}
                  />
                </Tooltip>
              )}

              {assigningCommentId && (
                <AssignUser
                  key={assigningCommentId}
                  onChange={onAssignUserChange}
                  value={assignedUsers || []}
                />
              )}

              {!!actionDropdownItems?.length && (
                <ActionsDropdown
                  items={actionDropdownItems}
                  triggerClass={s.bbMessageActionsDropdown}
                />
              )}
            </div>
          )}
        </div>
        <p
          data-testid="message"
          className={classNames(s[`bbMessageCardSize-${size}`], s.bbMessageCardText)}
        >
          {isMessageTranslated ? messageTranslated : message}
        </p>
        <div>{!!messageTranslated && <ViewTranslationLink />}</div>
        {footer}
      </div>
    </div>
  );
};

export default MessageCard;
