import { action } from "typesafe-actions";
import {
  SIGN_USER_UP,
  SIGN_USER_UP_SUCCESS,
  SIGN_USER_UP_FAILURE,
  VALIDATE_EMAIL,
  VALIDATE_EMAIL_SUCCESS,
  VALIDATE_EMAIL_FAILURE,
  CLEAR_EMAIL_VALIDATION,
} from "./actionTypes";
import { UserSignupPayloadType, IValidateEmailPayload, IEmailValidationResponse } from "./types";
import { IUserSignupAPIResponse } from "../tiktokApp/types";

export const signUserUpAction = (data: UserSignupPayloadType) =>
  action(SIGN_USER_UP, data, { public: true });
export const signUserUpSuccessAction = (data: IUserSignupAPIResponse) =>
  action(SIGN_USER_UP_SUCCESS, data, { public: true });
export const signUserUpFailureAction = () => action(SIGN_USER_UP_FAILURE, { public: true });

export const clearEmailValidationAction = () => action(CLEAR_EMAIL_VALIDATION, { public: true });
export const validateEmailAction = (payload: IValidateEmailPayload) =>
  action(VALIDATE_EMAIL, payload, { public: true });
export const validateEmailSuccessAction = (response: IEmailValidationResponse) =>
  action(VALIDATE_EMAIL_SUCCESS, response, { public: true });
export const validateEmailFailureAction = () => action(VALIDATE_EMAIL_FAILURE, { public: true });
