/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";

import { IAIPromptsContext } from "./types";

const AIPromptsContext = React.createContext<IAIPromptsContext>({
  fetchAIPrompts: () => {},
  deleteAIPrompt: () => {},
  setDefaultAIPrompt: () => {},
  getAIPromptSuggestion: () => {},
});

export default AIPromptsContext;
