import classNames from "classnames/bind";
import React, { useEffect, useState } from "react";

import { Button, InputWhiteBordered } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ModalHeaderActions.module.less";

import { IModalHeaderActionsProps } from "./ModalHeaderActions.types";

import { PlusIcon } from "@assets/index";

export const ModalHeaderActions: React.FC<IModalHeaderActionsProps> = ({
  title,
  onAddClick,
  onSearch,
  placeholder,
  hideButton,
  className,
}) => {
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    onSearch(query);
  }, [query, onSearch]);

  return (
    <div className={classNames(s.bbModalHeader, className)}>
      <div className={s.bbModalHeaderTitle}>{title}</div>
      <div className={s.bbModalHeaderActions}>
        {!hideButton && (
          <Button _type="primary" onClick={onAddClick} className={s.bbModalHeaderActionsCreateBtn}>
            <PlusIcon />
          </Button>
        )}
        <InputWhiteBordered
          placeholder={placeholder}
          value={query}
          onChange={event => {
            setQuery(event.target.value);
          }}
        />
      </div>
    </div>
  );
};
