import { createReducer, ActionType } from "typesafe-actions";

import {
  performBulkCommentActionSuccessAction,
  performCommentActionSuccessAction,
} from "../comments/actions";
import { DELETE_BRAND_REPLY_SUCCESS, POST_COMMENT_REPLY_SUCCESS } from "../replies/actionTypes";
import { deleteBrandReplySuccessAction, postCommentReplySuccessAction } from "../replies/actions";

import {
  CURRENT_USER_ASSIGNED,
  CURRENT_USER_UNASSIGNED,
  PERFORM_BULK_COMMENT_ACTION_SUCCESS,
  PERFORM_COMMENT_ACTION_SUCCESS,
} from "../comments/actionTypes";

import { getInboxStatsSuccess } from "./actions";
import { GET_INBOX_STATS, GET_INBOX_STATS_FAILURE, GET_INBOX_STATS_SUCCESS } from "./actionTypes";
import { IInboxStatsState } from "./types";

const initialState = {
  data: undefined,
  fetchedInboxStats: false,
  fetchingInboxStats: false,
  fetchedInboxStatsFail: false,
  clearInboxStats: false,
};

export const inboxStatsReducer = createReducer<IInboxStatsState>(initialState, {
  [GET_INBOX_STATS]: (state: IInboxStatsState) => ({
    ...state,
    fetchingInboxStats: true,
    fetchedInboxStats: false,
    fetchedInboxStatsFail: false,
    clearInboxStats: false,
  }),
  [GET_INBOX_STATS_SUCCESS]: (
    state: IInboxStatsState,
    action: ActionType<typeof getInboxStatsSuccess>,
  ) => ({
    ...state,
    data: action.payload,
    fetchingInboxStats: false,
    fetchedInboxStats: true,
    fetchedInboxStatsFail: false,
    clearInboxStats: false,
  }),
  [GET_INBOX_STATS_FAILURE]: (state: IInboxStatsState) => ({
    ...state,
    fetchingInboxStats: false,
    fetchedInboxStats: false,
    fetchedInboxStatsFail: true,
    clearInboxStats: false,
  }),
  [PERFORM_COMMENT_ACTION_SUCCESS]: (
    state: IInboxStatsState,
    action: ActionType<typeof performCommentActionSuccessAction>,
  ) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            ...action.payload.stats.stats.stats,
          },
        },
      };
    }

    return state;
  },
  [PERFORM_BULK_COMMENT_ACTION_SUCCESS]: (
    state: IInboxStatsState,
    action: ActionType<typeof performBulkCommentActionSuccessAction>,
  ) => {
    const { actions, comment_ids } = action.payload.commentAction;

    const reviewCount = state?.data?.stats.review || 0;
    const aiHighlightsCount = state?.data?.stats.ai_highlights || 0;

    const calculateRemaningReview = (currentCount: number) =>
      currentCount > 0 ? currentCount - comment_ids.length : 0;

    if (state.data) {
      if (actions?.includes("review")) {
        return {
          ...state,
          data: {
            ...state.data,
            stats: {
              ...state.data.stats,
              review: calculateRemaningReview(reviewCount),
              ai_highlights: calculateRemaningReview(aiHighlightsCount),
              reviewed: (state.data.stats?.reviewed || 0) + comment_ids.length,
            },
          },
        };
      }
      if (actions?.includes("ignore")) {
        return {
          ...state,
          data: {
            ...state.data,
            stats: {
              ...state.data.stats,
              review: calculateRemaningReview(reviewCount),
              ai_highlights: calculateRemaningReview(aiHighlightsCount),
              ignored: (state.data.stats?.ignored || 0) + comment_ids.length,
            },
          },
        };
      }
      if (actions?.includes("hide")) {
        return {
          ...state,
          data: {
            ...state.data,
            stats: {
              ...state.data.stats,
              review: calculateRemaningReview(reviewCount),
              ai_highlights: calculateRemaningReview(aiHighlightsCount),
              hidden: (state.data.stats?.hidden || 0) + comment_ids.length,
            },
          },
        };
      }
    }

    return state;
  },
  [POST_COMMENT_REPLY_SUCCESS]: (
    state: IInboxStatsState,
    action: ActionType<typeof postCommentReplySuccessAction>,
  ) => {
    if (state.data) {
      const additionalAmount = action.payload.is_ignored ? 0 : 1;
      const decreaseAmountToAiHighlights = action.payload.data.is_ai_highlighted ? 1 : 0;

      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            review: (state.data.stats.review || 0) - additionalAmount,
            ai_highlights: (state.data.stats.ai_highlights || 0) - decreaseAmountToAiHighlights,
            done: (state.data.stats.done || 0) + additionalAmount,
            replied: (state.data.stats.replied || 0) + additionalAmount,
          },
        },
      };
    }

    return state;
  },
  [DELETE_BRAND_REPLY_SUCCESS]: (
    state: IInboxStatsState,
    action: ActionType<typeof deleteBrandReplySuccessAction>,
  ) => {
    const additionalAmount = action.payload.isIgnored ? 0 : 1;
    const decreaseAmountToAiHighlights = action.payload.isAIHighlighted ? 1 : 0;

    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            review: (state.data.stats.review || 0) + additionalAmount,
            ai_highlights: (state.data.stats.ai_highlights || 0) + decreaseAmountToAiHighlights,
            done: (state.data.stats.done || 0) - additionalAmount,
            replied: (state.data.stats.replied || 0) - additionalAmount,
          },
        },
      };
    }

    return state;
  },
  [CURRENT_USER_ASSIGNED]: (state: IInboxStatsState) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            assigned_to_me: (state.data.stats?.assigned_to_me || 0) + 1,
          },
        },
      };
    }

    return state;
  },
  [CURRENT_USER_UNASSIGNED]: (state: IInboxStatsState) => {
    if (state.data) {
      return {
        ...state,
        data: {
          ...state.data,
          stats: {
            ...state.data.stats,
            assigned_to_me: (state.data.stats?.assigned_to_me || 1) - 1,
          },
        },
      };
    }

    return state;
  },
});
