import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Switch } from "antd";

import s from "./DemoMode.module.less";
import { meSelector } from "@store/me/selectors";
import { patchDemoModeAction } from "@store/me/actions";

export function DemoMode() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const me = useSelector(meSelector);
  const [isChecked, setIsChecked] = useState(me?.is_demo_mode_active);

  if (!me?.is_demo_toggle_visible) {
    return null;
  }

  const _onChange = (value: boolean) => {
    setIsChecked(value);
    dispatch(patchDemoModeAction({ is_active: value }));

    // TODO: Figure out a better way for this. Maybe patch success?
    setTimeout(() => window.location.reload(), 300);
  };

  return (
    <div
      data-cy="demo-mode"
      className={classNames(s.bbDemoMode, {
        [s.bbDemoModeEnabled]: isChecked,
      })}
    >
      <Switch
        checked={isChecked}
        onChange={_onChange}
        data-cy="demo-mode-switch"
        className={classNames(s.bbDemoModeSwitch, {
          [s.bbDemoModeSwitchEnabled]: isChecked,
        })}
      />
      <span data-cy="demo-mode-title" className={s.bbDemoModeTitle}>
        {t("components:demoMode:title")}
      </span>

      <span className={s.bbDemoModeDivider}>|</span>

      <span data-cy="demo-mode-description" className={s.bbDemoModeDescription}>
        {isChecked ? t("components:demoMode:on") : t("components:demoMode:off")}
      </span>
    </div>
  );
}
