export const MIN_PSW_LENGTH = 16;

export const validatePsw = (value: string) => {
  const hasNumber = (value || "").match(/\d/);
  const hasUppercase = (value || "").match(/[A-Z]/);
  const hasLowercase = (value || "").match(/[a-z]/);
  const hasMinLength = (value || "").length >= MIN_PSW_LENGTH;
  const hasSpecialChar = (value || "").match(
    // eslint-disable-next-line
    /(?=.*[!'/\"*\]\[½|~,-\s:;\?`._<>£$â(){}@#$%^&+=])/
  );
  const isValid = hasNumber && hasUppercase && hasLowercase && hasMinLength && hasSpecialChar;

  return {
    isValid,
    hasNumber,
    hasUppercase,
    hasLowercase,
    hasMinLength,
    hasSpecialChar,
  };
};
