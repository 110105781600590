import React from "react";
import classNames from "classnames";

import s from "./CollaborationAvatar.module.less";

import { useImagePreload } from "@utils/useImagePreload";
import { ICollaborationAvatarProps } from "./CollaborationAvatar.types";

import { UserIcon } from "@assets/index";

export const CollaborationAvatar: React.FC<ICollaborationAvatarProps> = ({
  className,
  src,
  ...restProps
}) => {
  const [loading, error] = useImagePreload(src);
  const renderImage = !loading && !error;
  return (
    <span className={classNames(s.bbCollaborationAvatar, className)}>
      <UserIcon />
      <span>{renderImage && <img {...restProps} />}</span>
    </span>
  );
};
