import React, { FC } from "react";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Post.module.less";

import { IPostInfoProps } from "./Post.type";

export const PostInfo: FC<IPostInfoProps> = ({ post, texts, tooltips }) => {
  return (
    <>
      <div className={s.bbPostId}>
        <Tooltip title={tooltips?.postId || ""}>
          <div>
            <div className={s.bbPostLabel} data-cy="post-label">
              {texts?.postId || ""}:
            </div>
            <div className={s.bbPostValue} data-cy="post-value">
              {post.object_id}
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={s.bbPostAssetId}>
        <Tooltip title={tooltips?.assetId || ""}>
          <div>
            <div className={s.bbPostLabel} data-cy="post-label">
              {texts?.assetId || ""}:
            </div>
            <div className={s.bbPostValue} data-cy="post-value">
              {post.page_id}
            </div>
          </div>
        </Tooltip>
      </div>

      <div className={s.bbPostType}>
        <Tooltip title={tooltips?.postType || ""}>
          <div>
            <div className={s.bbPostLabel} data-cy="post-label">
              {texts?.postType || ""}:
            </div>
            <div className={s.bbPostValue} data-cy="post-value">
              {post.post_type}
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  );
};
