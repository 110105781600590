import React from "react";

import SentimentsCards from "@components/insights/overview/sentimentsCards/SentimentsCards"; // eslint-disable-line max-len
import SentimentPieChart from "@components/insights/overview/sentimentPieChart/SentimentPieChart"; // eslint-disable-line max-len
import PlatformsPieChart from "@components/insights/overview/platformsPieChart/PlatformsPieChart"; // eslint-disable-line max-len

import MostCommentedPosts from "./MostCommentedPosts";

import reportStyles from "./../PDFReport.module.less";
import s from "./SentimentPreview.module.less";

import { ISentimentPreviewProps } from "./SentimentPreview.types";

export const SentimentPreview: React.FC<ISentimentPreviewProps> = ({
  title,
  data,
  isFetching,
  filters,
  onLoad,
}) => (
  <div className={reportStyles.bbPDFReportSection}>
    {title && <h2 className={reportStyles.bbPDFReportSectionTitle}>{title}</h2>}
    <div className={s.bbSentimentPreview}>
      <SentimentsCards data={data.sentiment} loading={isFetching} filters={filters} />
    </div>
    <MostCommentedPosts onLoad={onLoad} filters={filters} />
    <div className={s.bbSentimentPreviewBreakdown}>
      <SentimentPieChart
        loading={isFetching}
        data={data.sentiment}
        filterData={filters}
        className={s.bbSentimentPreviewBreakdownItem}
      />
      <PlatformsPieChart
        loading={isFetching}
        data={data.platforms}
        filterData={filters}
        className={s.bbSentimentPreviewBreakdownItem}
      />
    </div>
  </div>
);
