import { createReducer, ActionType } from "typesafe-actions";
import { IAuthSession, IAuthState } from "./types";

import {
  getCurrentSessionSuccessAction,
  refreshCurrentSessionSuccessAction,
  currentAuthenticateUserSuccessAction,
  getMFASuccessAction,
  setMFAAction,
  setupTOTPSuccessAction,
  verifyTOTPSuccessAction,
} from "./actions";

import {
  CURRENT_SESSION,
  CURRENT_SESSION_SUCCESS,
  CURRENT_SESSION_FAILURE,
  REFRESH_CURRENT_SESSION,
  REFRESH_CURRENT_SESSION_SUCCESS,
  REFRESH_CURRENT_SESSION_FAILURE,
  CURRENT_AUTHENTICATE_USER,
  CURRENT_AUTHENTICATE_USER_SUCCESS,
  CURRENT_AUTHENTICATE_USER_FAILURE,
  GET_MFA,
  GET_MFA_SUCCESS,
  GET_MFA_FAILURE,
  SET_MFA,
  SET_MFA_SUCCESS,
  SET_MFA_FAILURE,
  SETUP_TOTP,
  SETUP_TOTP_SUCCESS,
  SETUP_TOTP_FAILURE,
  VERIFY_TOTP,
  VERIFY_TOTP_SUCCESS,
  VERIFY_TOTP_FAILURE,
} from "./actionTypes";
import { TIKTOK_CONNECT_SUCCESS } from "../tiktokApp/actionTypes";
import { tiktokConnectSuccessAction } from "../tiktokApp/actions";

const initialState = {
  session: {} as IAuthSession,
  user: {} as any, // eslint-disable-line
  mfa: "",
  codeTOTP: "",
  fetchingCurrentSession: false,
  fetchedCurrentSession: false,
  fetchedCurrentSessionFail: false,
  fetchingRefreshCurrentSession: false,
  fetchedRefreshCurrentSession: false,
  fetchedRefreshCurrentSessionFail: false,
  fetchingCurrentAuthenticatedUser: false,
  fetchedCurrentAuthenticatedUser: false,
  fetchedCurrentAuthenticatedUserFail: false,

  fetchingGetMfa: false,
  fetchedGetMfa: false,
  fetchedGetMfaFail: false,
  fetchingSetMfa: false,
  fetchedSetMfa: false,
  fetchedSetMfaFail: false,
  fetchingSetupTOTP: false,
  fetchedSetupTOTP: false,
  fetchedSetupTOTPFail: false,
  fetchingVerifyTOTP: false,
  fetchedVerifyTOTP: false,
  fetchedVerifyTOTPFail: false,
};

export const authReducer = createReducer<IAuthState>(initialState, {
  [CURRENT_AUTHENTICATE_USER]: (state: IAuthState) => ({
    ...state,
    fetchingCurrentAuthenticatedUser: true,
    fetchedCurrentAuthenticatedUser: false,
    fetchedCurrentAuthenticatedUserFail: false,
  }),
  [CURRENT_AUTHENTICATE_USER_SUCCESS]: (
    state: IAuthState,
    action: ActionType<typeof currentAuthenticateUserSuccessAction>,
  ) => ({
    ...state,
    user: action.payload,
    fetchingCurrentAuthenticatedUser: false,
    fetchedCurrentAuthenticatedUser: true,
    fetchedCurrentAuthenticatedUserFail: false,
  }),
  [CURRENT_AUTHENTICATE_USER_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingCurrentAuthenticatedUser: false,
    fetchedCurrentAuthenticatedUser: false,
    fetchedCurrentAuthenticatedUserFail: true,
  }),
  [CURRENT_SESSION]: (state: IAuthState) => ({
    ...state,
    fetchingCurrentSession: true,
    fetchedCurrentSession: false,
    fetchedCurrentSessionFail: false,
  }),
  [CURRENT_SESSION_SUCCESS]: (
    state: IAuthState,
    action: ActionType<typeof getCurrentSessionSuccessAction>,
  ) => ({
    ...state,
    session: action.payload,
    fetchingCurrentSession: false,
    fetchedCurrentSession: true,
    fetchedCurrentSessionFail: false,
  }),
  [CURRENT_SESSION_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingCurrentSession: false,
    fetchedCurrentSession: false,
    fetchedCurrentSessionFail: true,
  }),
  [REFRESH_CURRENT_SESSION]: (state: IAuthState) => ({
    ...state,
    fetchingRefreshCurrentSession: true,
    fetchedRefreshCurrentSession: false,
    fetchedRefreshCurrentSessionFail: false,
  }),
  [REFRESH_CURRENT_SESSION_SUCCESS]: (
    state: IAuthState,
    action: ActionType<typeof refreshCurrentSessionSuccessAction>,
  ) => ({
    ...state,
    session: action.payload,
    fetchingRefreshCurrentSession: false,
    fetchedRefreshCurrentSession: true,
    fetchedRefreshCurrentSessionFail: false,
  }),
  [REFRESH_CURRENT_SESSION_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingRefreshCurrentSession: false,
    fetchedRefreshCurrentSession: false,
    fetchedRefreshCurrentSessionFail: true,
  }),
  [GET_MFA]: (state: IAuthState) => ({
    ...state,
    fetchingGetMfa: true,
    fetchedGetMfa: false,
    fetchedGetMfaFail: false,
  }),
  [GET_MFA_SUCCESS]: (state: IAuthState, action: ActionType<typeof getMFASuccessAction>) => ({
    ...state,
    mfa: action.payload,
    fetchingGetMfa: false,
    fetchedGetMfa: true,
    fetchedGetMfaFail: false,
  }),
  [GET_MFA_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingGetMfa: false,
    fetchedGetMfa: false,
    fetchedGetMfaFail: true,
  }),
  [SET_MFA]: (state: IAuthState, action: ActionType<typeof setMFAAction>) => ({
    ...state,
    mfa: action.payload,
    fetchingSetMfa: true,
    fetchedSetMfa: false,
    fetchedSetMfaFail: false,
  }),
  [SET_MFA_SUCCESS]: (state: IAuthState) => ({
    ...state,
    fetchingSetMfa: false,
    fetchedSetMfa: true,
    fetchedSetMfaFail: false,
  }),
  [SET_MFA_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingSetMfa: false,
    fetchedSetMfa: false,
    fetchedSetMfaFail: true,
  }),
  [SETUP_TOTP]: (state: IAuthState) => ({
    ...state,
    fetchingSetupTOTP: true,
    fetchedSetupTOTP: false,
    fetchedSetupTOTPFail: false,
  }),
  [SETUP_TOTP_SUCCESS]: (state: IAuthState, action: ActionType<typeof setupTOTPSuccessAction>) => ({
    ...state,
    codeTOTP: action.payload,
    fetchingSetupTOTP: false,
    fetchedSetupTOTP: true,
    fetchedSetupTOTPFail: false,
  }),
  [SETUP_TOTP_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingSetupTOTP: false,
    fetchedSetupTOTP: false,
    fetchedSetupTOTPFail: true,
  }),
  [VERIFY_TOTP]: (state: IAuthState) => ({
    ...state,
    fetchingVerifyTOTP: true,
    fetchedVerifyTOTP: false,
    fetchedVerifyTOTPFail: false,
  }),
  [VERIFY_TOTP_SUCCESS]: (
    state: IAuthState,
    action: ActionType<typeof verifyTOTPSuccessAction>,
  ) => ({
    ...state,
    codeMFA: action.payload,
    fetchingVerifyTOTP: false,
    fetchedVerifyTOTP: true,
    fetchedVerifyTOTPFail: false,
  }),
  [VERIFY_TOTP_FAILURE]: (state: IAuthState) => ({
    ...state,
    fetchingVerifyTOTP: false,
    fetchedVerifyTOTP: false,
    fetchedVerifyTOTPFail: true,
  }),
  [TIKTOK_CONNECT_SUCCESS]: (
    state: IAuthState,
    action: ActionType<typeof tiktokConnectSuccessAction>,
  ) => {
    if (action.payload.session?.access_token) {
      return {
        ...state,
        session: {
          ...state.session,
          accessToken: {
            ...state.session.accessToken,
            jwtToken: action.payload.session?.access_token,
          },
        },
      };
    }

    return state;
  },
});
