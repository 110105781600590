import React from "react";
import { TFunction } from "i18next";
import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";
import { IStatsProps } from "@store/overview/types";

const getPercentageString = (value: number, total: number) => {
  const rawPercent = total === 0 ? 0 : (value / total) * 100;

  return rawPercent === 100
    ? "(100%)"
    : "(" + (rawPercent === 0 ? 0 : rawPercent.toFixed(2)) + "%)";
};

export const renderStatsTooltip = (commentStats: IStatsProps, t: TFunction) => {
  return (
    <div>
      {commentStats
        ? Object.keys(commentStats).map(key => {
            const value = commentStats[key as keyof typeof commentStats];
            const totalValue = commentStats["total"];

            if (key === "total") return null;

            return (
              <div key={key}>
                <span>
                  {t(`components:postTrends:charts:${key}`)}
                  {": "}
                  {numberFormatter(value || 0)}{" "}
                </span>
                <span>{getPercentageString(value || 0, totalValue)}</span>
              </div>
            );
          })
        : null}
      <div>
        {t(`components:postTrends:charts:total`)}
        {": "}
        {numberFormatter(commentStats.total || 0)}{" "}
      </div>
    </div>
  );
};
