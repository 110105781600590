import { Auth } from "aws-amplify";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ACApp from "@tiktok-service/app-center-connect";
import React, { FC, useState, useEffect, useCallback } from "react";

import { TTSignupStep1 } from "./TTSignupStep1";
import { TTSignupStep2 } from "./TTSignupStep2";
import { TTSignupLoading } from "./TTSignupLoading";
import SignUpHeader from "../signUpHeader/SignUpHeader";
import AppFooter from "@components/_common/Appfooter/AppFooter";

import {
  getTiktokAccessTokenSelector,
  getTiktokActionSelector,
  getTiktokCookiesSelector,
  getTiktokSigninTokenSelector,
  getTiktokUsernameSelector,
  tiktokConnectedSelector,
  tiktokSignedUpSelector,
} from "@store/tiktokApp/selectors";
import { signedUserUpSelector } from "@store/user/selectors";
import { tiktokConnectAction } from "@store/tiktokApp/actions";
import { signUserUpSuccessAction } from "@store/user/actions";

import s from "./TTSignup.module.less";

import { ITTSignupProps } from "./TTSignup.types";
import { setCognitoCookies } from "@utils/user";
import { isTiktokPlatform } from "@utils/platform";
import { stepperConfig, isValidQueryParam, APP_ID } from "./helpers";
import { ICognitoCookies, ITiktokConnectPayload } from "@store/tiktokApp/types";

export const TTSignup: FC<ITTSignupProps> = ({ authProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const app = new ACApp(APP_ID);
  const queryParams = queryString.parse(window.location.search);
  const authCodeQueryParam = isValidQueryParam(queryParams, "auth_code")
    ? (queryParams.auth_code as string)
    : undefined;
  const codeQueryParam = isValidQueryParam(queryParams, "code")
    ? (queryParams.code as string)
    : undefined;
  const socialPlatformParam = isValidQueryParam(queryParams, "social_platform")
    ? (queryParams.social_platform as string)
    : undefined;

  const [currentStep, setCurrentStep] = useState(0);

  const tiktokSignedUp = useSelector(tiktokSignedUpSelector);
  const tiktokAction = useSelector(getTiktokActionSelector);
  const tiktokConnected = useSelector(tiktokConnectedSelector);
  const tiktokUsername = useSelector(getTiktokUsernameSelector);
  const tiktokCookies = useSelector(getTiktokCookiesSelector);
  const userSignUpSuccessful = useSelector(signedUserUpSelector);
  const tiktokAccessToken = useSelector(getTiktokAccessTokenSelector);
  const tiktokSigninToken = useSelector(getTiktokSigninTokenSelector);

  const connectTiktok = useCallback(
    (payload: ITiktokConnectPayload) => dispatch(tiktokConnectAction(payload)),
    [dispatch],
  );

  const signUserInAndRedirect = (
    username: string,
    signinToken: string,
    cookies: ICognitoCookies,
  ) => {
    Auth.signIn(username).then(user => {
      if (user?.challengeName === "CUSTOM_CHALLENGE") {
        Auth.sendCustomChallengeAnswer(user, signinToken).then(challengeAnswerResponse => {
          if (challengeAnswerResponse) {
            setCognitoCookies(cookies);
            authProps.onStateChange && authProps.onStateChange("signedIn");
            window.location.href = `${process.env.BASE_URL}/insights/overview`;
          }
        });
      }
    });
  };

  useEffect(() => {
    if (
      tiktokConnected &&
      tiktokUsername &&
      tiktokSigninToken &&
      tiktokCookies &&
      tiktokAction === "load_app" &&
      !socialPlatformParam
    ) {
      signUserInAndRedirect(tiktokUsername, tiktokSigninToken, tiktokCookies);
    }
    // eslint-disable-next-line
  }, [tiktokConnected, tiktokAction]);

  useEffect(() => {
    if (userSignUpSuccessful && tiktokUsername && tiktokSigninToken && tiktokCookies) {
      signUserInAndRedirect(tiktokUsername, tiktokSigninToken, tiktokCookies);
    }
    // eslint-disable-next-line
  }, [userSignUpSuccessful]);

  useEffect(() => {
    if (tiktokSignedUp) {
      setCurrentStep(1);
    }
  }, [tiktokSignedUp]);

  useEffect(() => {
    if (tiktokAccessToken) localStorage.setItem("ttAccessToken", tiktokAccessToken);
  }, [tiktokAccessToken]);

  useEffect(() => {
    if (socialPlatformParam && isTiktokPlatform(socialPlatformParam)) setCurrentStep(1);
  }, [socialPlatformParam, queryParams]);

  useEffect(() => {
    app
      .getSessionToken()
      .then(sessionToken => {
        if (sessionToken && typeof sessionToken === "string") {
          connectTiktok({
            jwt_token: sessionToken,
            auth_code: authCodeQueryParam || codeQueryParam,
          });
        } else console.error(sessionToken);
      })
      .catch(e => console.error(e));
    // eslint-disable-next-line
  }, []);

  const onFinishSignupCallback = () => {
    if (tiktokCookies && tiktokAccessToken) {
      dispatch(
        signUserUpSuccessAction({
          cookies: tiktokCookies,
          access_token: tiktokAccessToken,
        }),
      );
    }

    authProps.onStateChange && authProps.onStateChange("signIn", tiktokAccessToken);
  };

  return (
    <div className={s.bbTTSignupWrapper}>
      <SignUpHeader
        current={currentStep}
        stepperConfig={stepperConfig}
        steps={[
          { title: t("components:ttAppCenter:stepper:step1") },
          { title: t("components:ttAppCenter:stepper:step2") },
        ]}
      />
      {tiktokConnected && tiktokAction === "show_signup" && currentStep === 0 ? (
        <TTSignupStep1 />
      ) : tiktokConnected && currentStep === 1 ? (
        <TTSignupStep2 onFinish={onFinishSignupCallback} />
      ) : (
        <TTSignupLoading />
      )}
      <AppFooter />
    </div>
  );
};
