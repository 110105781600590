import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import PostView from "../PostView/PostView";
import { Tooltip, Chip } from "@bbdevcrew/bb_ui_kit_fe";

import { IPost, RecommendationType } from "../Social/Post/Post.type";
import { getCommentSourceInfo } from "../CommentCard/cardHeader/helpers";

import s from "./CommentPhrase.module.less";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";
import RecommendationPositiveIcon from "@assets/FacebookRecommends.svg";
import RecommendationNegativeIcon from "@assets/FacebookDoesntRecommend.svg";

interface ICommentPhraseProps {
  post?: IPost;
  commentPhrase: string;
  showPostViewTooltip: boolean;
  recommendationType?: RecommendationType;
  editedTooltip?: string;
}

const CommentPhrase: FC<ICommentPhraseProps> = ({
  post,
  commentPhrase,
  recommendationType,
  showPostViewTooltip,
  editedTooltip,
}) => {
  const { t } = useTranslation();

  const _recommendationType = recommendationType?.toLowerCase();
  const isIconVisible = recommendationType === "POSITIVE" || recommendationType === "NEGATIVE";

  const recommendationIcon = (
    <span className={s.bbRecommendationIcon}>
      {_recommendationType === "positive" ? (
        <RecommendationPositiveIcon />
      ) : (
        <RecommendationNegativeIcon />
      )}
    </span>
  );

  return (
    <>
      {isIconVisible && _recommendationType && recommendationIcon}
      {getCommentSourceInfo(commentPhrase)}
      {!!editedTooltip && !isIconVisible && !showPostViewTooltip && (
        <Tooltip title={editedTooltip}>
          <Chip _size="xxs" text={t(`generic:updated`)} className={s.bbUpdatedPost} />
        </Tooltip>
      )}
      {showPostViewTooltip && (
        <Tooltip
          destroyTooltipOnHide
          title={<PostView type="block" post_id={post?.id} post={post} />}
          overlayInnerStyle={{
            minWidth: 400,
            maxWidth: 400,
            backgroundColor: "white",
          }}
        >
          <InformationIcon />
        </Tooltip>
      )}
      {!!_recommendationType && isIconVisible && (
        <>
          :
          <Chip
            _size="xxs"
            _type={_recommendationType === "positive" ? "success" : "error"}
            text={t(`components:comments:cardHeader:recommendations:${_recommendationType}`)}
            className={classNames(
              s.bbRecommendationChip,
              s[`bbRecommendationChip-${_recommendationType}`],
            )}
          />
        </>
      )}
    </>
  );
};

export default CommentPhrase;
