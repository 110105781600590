import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import {
  Tooltip,
  BarChart,
  numberToQuantity,
  defaultGridProps,
  IAxisTick,
} from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TopTagsChart.module.less";

import { ITopTagsChartProps } from "./TopTagsChart.type";
import { ITopTag, SentimentType } from "@store/dashboard/types";
import { getSentimentBars, stripePattern } from "@components/insights/overview/helpers";

const TopTagsChart: FC<ITopTagsChartProps> = ({ data, chartWidth }) => {
  const { t } = useTranslation();

  const generateLabel = (props: IAxisTick) => {
    return (
      <Tooltip title={props.payload.value}>
        <foreignObject
          x={5}
          y={props.y - 12}
          cursor="pointer"
          data-cy="top-tags-chart-label"
          className={s.bbTopTagsChartLabel}
        >
          <div className={s.bbTopTagsChartLabelContainer}>
            <div className={s.bbTopTagsChartLabelContent}>{props.payload.value}</div>
          </div>
        </foreignObject>
      </Tooltip>
    );
  };

  const generateChartData = (items: ITopTag[]) => {
    const getSentiment = (item: ITopTag, sentiment: SentimentType) => {
      return item.sentiment_breakdown.find(breakdownItem => breakdownItem.id === sentiment)?.count;
    };

    return items.map((item: ITopTag) => {
      return {
        label: item.label,
        [t(`components:listen:topTags:chart:positive`)]: getSentiment(item, "positive"),
        [t(`components:listen:topTags:chart:negative`)]: getSentiment(item, "negative"),
        [t(`components:listen:topTags:chart:user_tags`)]: getSentiment(item, "user_tags"),
        [t(`components:listen:topTags:chart:neutral`)]: getSentiment(item, "neutral"),
        [t(`components:listen:topTags:chart:not_applicable`)]: getSentiment(item, "not_applicable"),
      };
    });
  };

  return (
    <div className={s.bbTopTagsChart} data-cy="top-tags-chart">
      <BarChart
        grid
        yAxis
        xAxis
        tooltip
        height={768}
        barSize={28}
        pattern={stripePattern}
        isShowPercent
        layout="vertical"
        stackOffset="sign"
        width={chartWidth - 50}
        percentagePrecision={1}
        gridProps={defaultGridProps}
        data={generateChartData(data)}
        tooltipProps={{ cursor: { fill: "transparent" } }}
        xAxisProps={{
          tickFormatter: numberToQuantity,
          type: "number",
          axisLine: { strokeWidth: 1, stroke: "#C2C6D2" },
          tickLine: { strokeWidth: 1, stroke: "#C2C6D2" },
        }}
        yAxisProps={{
          tickFormatter: numberToQuantity,
          dataKey: "label",
          type: "category",
          interval: 0,
          width: 145,
          tick: generateLabel,
          axisLine: { strokeWidth: 0 },
          tickLine: { strokeWidth: 0 },
        }}
        bars={getSentimentBars()}
        margin={{ top: 5, right: 40, left: 40, bottom: 5 }}
      />
    </div>
  );
};

export default TopTagsChart;
