export const GET_COMPARE_PROJECTS = "COMPARE_PROJECTS/GET_COMPARE_PROJECTS";
export const GET_COMPARE_PROJECTS_SUCCESS = "COMPARE_PROJECTS/GET_COMPARE_PROJECTS_SUCCESS";
export const GET_COMPARE_PROJECTS_FAILURE = "COMPARE_PROJECTS/GET_COMPARE_PROJECTS_FAILURE";

// CREATE
export const POST_COMPARE_PROJECT = "COMPARE_PROJECTS/POST_COMPARE_PROJECT";
export const POST_COMPARE_PROJECT_SUCCESS = "COMPARE_PROJECTS/POST_COMPARE_PROJECT_SUCCESS";
export const POST_COMPARE_PROJECT_FAILURE = "COMPARE_PROJECTS/POST_COMPARE_PROJECT_FAILURE";

// EDIT
export const EDIT_COMPARE_PROJECT = "COMPARE_PROJECTS/EDIT_COMPARE_PROJECT";
export const EDIT_COMPARE_PROJECT_SUCCESS = "COMPARE_PROJECTS/EDIT_COMPARE_PROJECT_SUCCESS";
export const EDIT_COMPARE_PROJECT_FAILURE = "COMPARE_PROJECTS/EDIT_COMPARE_PROJECT_FAILURE";

// DELETE
export const DELETE_COMPARE_PROJECT = "COMPARE_PROJECTS/DELETE_COMPARE_PROJECT";
export const DELETE_COMPARE_PROJECT_SUCCESS = "COMPARE_PROJECTS/DELETE_COMPARE_PROJECT_SUCCESS";
export const DELETE_COMPARE_PROJECT_FAILURE = "COMPARE_PROJECTS/DELETE_COMPARE_PROJECT_FAILURE";

// GET AGGREGATIONS
export const GET_COMPARE_PROJECT_AGGREGATIONS = "COMPARE_PROJECTS/GET_COMPARE_PROJECT_AGGREGATIONS";
export const GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS =
  "COMPARE_PROJECTS/GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS";
export const GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE =
  "COMPARE_PROJECTS/GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE";
export const CLEAR_PROJECT_AGGREGATIONS = "COMPARE_PROJECTS/CLEAR_PROJECT_AGGREGATIONS";
