export const messageTypesDictionary: Record<string, string[]> = {
  owned_public: ["comments", "ad_comments", "wall_posts"],
  earned: [
    "reviews",
    "mentions",
    "story_mentions",
    "quote_tweets",
    "replies_to_twitter_mentions",
    "brand_hashtag_mentions",
  ],
  owned_private: ["private_messages", "replies_to_stories"],
};
