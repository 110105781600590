import { ChangeEvent, MouseEvent } from "react";
import { IPostTag } from "@store/postTags/types";
import { DropdownMenuPlacementType } from "@bbdevcrew/bb_ui_kit_fe";

export const MAX_TAGS_SCROLL_LENGTH = 8;

export type TagDropdownType = "postTags" | "customTags";

export interface ITagsDropdownListingProps {
  searchTerm: string;
  dropdownTags: ICustomTag[] | IPostTag[];
  selectTag: (tag: ICustomTag | IPostTag) => void;
  removeTag?: (e: MouseEvent<HTMLButtonElement>, tag: ICustomTag | IPostTag) => void;
}

export interface ITagsDropdownHeaderProps {
  id: TagDropdownType;
  title: string;
  inputValue: string;
  isDropdownOpen: boolean;
  isTagBeingCreated: boolean;
  existingTagEntered: boolean;
  onAddNewTag: (e: MouseEvent<HTMLButtonElement>) => void;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export interface ITagsStateData {
  tagsLoading: boolean;
  tagsLoaded: boolean;
  tagCreating: boolean;
  tagCreated: boolean;
  lastCreatedTag?: ICustomTag | IPostTag;
}

export interface ITagsDropdownProps {
  id: TagDropdownType;
  tagsStateData: ITagsStateData;
  tags: ICustomTag[] | IPostTag[];
  initialTags: ICustomTag[] | IPostTag[];
  trigger: React.ReactNode;
  dropdownClassName?: string;
  _dropdownPlacement?: DropdownMenuPlacementType;
  fetchTags: () => void;
  deleteTagFromDropdown?: (tagId: string) => void;
  onSelectTag: (tag: ICustomTag) => void;
  onSelectTagCallback?: (tag: ICustomTag) => void;
  onCreateTag: (tagLabel: string) => void;
}

interface INewlyAddedTag {
  newlyAdded?: boolean;
}

export interface ICustomTag extends INewlyAddedTag {
  id?: string;
  label: string;
  count?: number;
  tooltip?: string;
  created_at?: string;
  created_by?: string;
  removed_at?: string;
  removed_by?: string;
}
