import "d3-transition";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { FC, useState, useMemo } from "react";

import { Empty } from "antd";
import ReactResizeDetector from "react-resize-detector";
import { WordCloud, Loading, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";

import {
  getCloudSize,
  getCloudPadding,
  getCloudMaxWords,
  getHoverCallback,
  calculateWordColor,
} from "../../mostUsedEmojis/helpers";
import { Word } from "react-wordcloud";
import { IWordCloudTrendsProps } from "./helpers";
import { IWordCloudProps } from "@store/overview/types";

import s from "./WordCloudTrends.module.less";
import "../../mostUsedEmojis/Tooltip.module.less";

const WordCloudTrends: FC<IWordCloudTrendsProps> = ({
  data,
  filters,
  loading,
  filterToSpecificItem,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [cloudType, setCloudType] = useState("all");

  const keywords = useMemo(() => {
    return data.find(item => item.id === cloudType)?.keywords || [];
  }, [data, cloudType]);

  const onWordClick = (word: Word) => {
    if (word && word.keywords) {
      const wordFilters =
        cloudType === "all"
          ? {
              keyword_query: word.keywords,
            }
          : {
              keyword_query: word.keywords,
              sentiments: [cloudType],
            };
      filterToSpecificItem?.({
        ...filters,
        ...wordFilters,
      });
      navigate(
        {
          search: "?section=listen-sentiment_details",
        },
        {
          state: {
            filters: {
              ...filters,
              ...wordFilters,
            },
          },
        },
      );
    }
  };

  const callbacks = {
    onWordMouseOut: getHoverCallback("onWordMouseOut", onWordClick),
    onWordMouseOver: getHoverCallback("onWordMouseOver", onWordClick),
    getWordColor: (word: Word) => calculateWordColor(word.value, cloudType),
    getWordTooltip: () => t("components:analysisTrends:tooltips:explore"),
  };

  const generateTab = () => {
    return (
      <ReactResizeDetector handleWidth>
        {(props: { width: number }) => {
          return loading ? (
            <div className={s.bbRootWordCloudTrendsLoading}>
              <Loading isLoading />
            </div>
          ) : data.length && props.width && keywords.length ? (
            <WordCloud
              words={keywords}
              callbacks={callbacks}
              onClick={onWordClick}
              size={getCloudSize(props.width)}
              maxWords={getCloudMaxWords(props.width)}
              options={{
                padding: getCloudPadding(props.width),
                fontFamily: "Manrope",
                deterministic: true,
                fontSizes: [11, 40],
                transitionDuration: 0,
                enableOptimizations: true,
              }}
            />
          ) : (
            <Empty
              className={s.bbWorldCloudEmpty}
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t("components:empty:description")}
              imageStyle={{
                height: 40,
              }}
            />
          );
        }}
      </ReactResizeDetector>
    );
  };

  const onChangeTab = (tabKey: string) => {
    setCloudType(tabKey as keyof IWordCloudProps);
  };

  return (
    <div className={s.bbRootWordCloudTrends} data-cy="wordcloud-container">
      <TabsScrollable
        centered
        noUnderline
        activeKey={cloudType}
        defaultActiveKey="all"
        onChange={onChangeTab}
        className={s.bbTabs}
      >
        <TabPane tab={t(`components:analysisTrends:tabs:all`)} tabKey="all">
          {generateTab()}
        </TabPane>
        <TabPane tab={t(`components:analysisTrends:tabs:positive`)} tabKey="positive">
          {generateTab()}
        </TabPane>
        <TabPane tab={t(`components:analysisTrends:tabs:negative`)} tabKey="negative">
          {generateTab()}
        </TabPane>
      </TabsScrollable>
    </div>
  );
};

export default WordCloudTrends;
