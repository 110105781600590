import { ajax } from "rxjs/ajax";
import { of, Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import {
  getShortenedSearchesFailureAction,
  getShortenedSearchesSuccessAction,
  postShortenedSearchesFailureAction,
  postShortenedSearchesSuccessAction,
} from "./actions";
import { GET_SHORTENED_SEARCHES, POST_SHORTENED_SEARCHES } from "./actionTypes";

import { RootState } from "..";
import {
  ShortenedSearchesActionsType,
  IGetShortenedSearchesResponse,
  IPostShortenedSearchesResponse,
} from "./types";

import { shortenedSearches } from "@utils/paths";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

// GET SHORTENED_SEARCHES
export const getShortenedSearches = (
  action$: Observable<ShortenedSearchesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_SHORTENED_SEARCHES)),
    switchMap(a => {
      return ajax
        .get<IGetShortenedSearchesResponse>(
          `${shortenedSearches}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map((data: IGetShortenedSearchesResponse) => getShortenedSearchesSuccessAction(data)),
          catchError(e =>
            e.status === 404
              ? of(getShortenedSearchesFailureAction(e))
              : handleError(e, getShortenedSearchesFailureAction),
          ),
        );
    }),
  );

// POST SHORTENED_SEARCH
export const postShortenedSearches = (
  action$: Observable<ShortenedSearchesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_SHORTENED_SEARCHES)),
    switchMap(a => {
      return ajax
        .post<IPostShortenedSearchesResponse>(
          shortenedSearches,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postShortenedSearchesSuccessAction(data)),
          catchError(e => handleError(e, postShortenedSearchesFailureAction)),
        );
    }),
  );
