import cn from "classnames/bind";
import React, { FC } from "react";

import s from "./Bullet.module.less";

import { BulletType, IBulletProps } from "./Bullet.type";

const cx = cn.bind(s);

export const Bullet: FC<IBulletProps> = ({ type }) => (
  <div className={cx(s.bbBullet, `bbBullet-${type}`)}></div>
);

export const getColumnBullet = (type: BulletType) => <Bullet type={type} />;
