import "dayjs/locale/en";
import dayjs, { Dayjs } from "dayjs";

export const getMonth = (month: number = dayjs().month()) => {
  month = Math.floor(month);
  const year = dayjs().year();
  const firstDayOfTheMonth = dayjs(new Date(year, month, 1)).day();
  let currentMonthCount = 0 - firstDayOfTheMonth;

  const daysMatrix = new Array(5).fill([]).map(() => {
    return new Array(7).fill(null).map(() => {
      currentMonthCount++;
      return dayjs(new Date(year, month, currentMonthCount));
    });
  });

  return daysMatrix;
};

export const getCurrentISOWeekNumber = () => {
  const now = dayjs();
  const yearStart = now.startOf("year");
  const weekStart = yearStart.startOf("week");

  // Calculate the number of full weeks between the start of the year and the current date
  const weekNumber = now.diff(weekStart, "week") + 1;

  return weekNumber;
};

function getFirstDayOfSpecificWeek(week: number): dayjs.Dayjs {
  const currentDay = dayjs().startOf("year");
  const startOfWeek = currentDay.add(week - 1, "week").startOf("week");

  return startOfWeek;
  // Use this to add 1 day to start the week with Monday
  // return startOfWeek.add(1, "day");
}

export const getWeek = (week?: number) => {
  week = week === undefined ? getCurrentISOWeekNumber() : week;
  const firstDayOfWeek = getFirstDayOfSpecificWeek(week);

  const currentWeekDays: dayjs.Dayjs[] = [];

  for (let i = 0; i < 7; i++) {
    currentWeekDays.push(firstDayOfWeek.add(i, "day"));
  }

  return currentWeekDays;
};

export const getTimespanTzDates = (weekArray: Dayjs[]) => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000;
  const startTime = new Date(weekArray[0].toISOString());
  const endTime = new Date(weekArray[6].toISOString());

  const start_time = new Date(startTime.getTime() - timezoneOffset).toISOString().slice(0, -1);
  const end_time = new Date(endTime.getTime() - timezoneOffset).toISOString().slice(0, -1);

  return { start_time, end_time };
};
