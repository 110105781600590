import cn from "classnames/bind";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import globalStyles from "../index.module.less";
import s from "../confirmSignUp/ConfirmSignUp.module.less";

import { Form, FormInstance } from "antd";
import { CodeInput } from "../codeInput/CodeInput";
import { Button, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

import { IFormError, IFormValues, IConfirmSignInProps } from "./ConfirmSignIn.type";

import Logo from "@assets/LogoNoBeta.svg";
import { RedoIcon, HeadphoneIcon } from "@assets/index";

const cx = cn.bind(s);

const ConfirmSignIn: React.FC<IConfirmSignInProps> = ({
  onFinish,
  isActiveUser,
  onFinishFailed,
  onResendCode,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isCodeEntered, setIsCodeEntered] = useState(false);

  const onCodeChange = (form: FormInstance, value: string) => {
    if (value?.toString().length === 6) {
      setIsCodeEntered(true);
    } else {
      setIsCodeEntered(false);
    }

    form.setFieldsValue({
      newCode: value,
    });
  };

  const callBackError = () => setLoading(false);

  const onSubmit = (values: IFormValues) => {
    setLoading(true);
    return onFinish ? onFinish(values, callBackError) : null;
  };

  const onSubmitFailed = (errorInfo: IFormError<IFormValues>) => {
    return onFinishFailed ? onFinishFailed(errorInfo) : null;
  };

  return (
    <div className={s.bbConfirmSignUpContainer}>
      <div className={s.bbLogoContainer}>
        <Logo />
      </div>

      <div className={s.bbConfirmSignUpWindow}>
        <div className={s.bbConfirmSignUpHeader}>
          {isActiveUser
            ? t("components:confirmSignIn:title")
            : t("components:confirmMfaCode:title")}
        </div>

        <div className={s.bbConfirmSignUpTitle}>
          {isActiveUser
            ? t("components:confirmSignIn:subtitle")
            : t("components:confirmMfaCode:subtitle")}
        </div>

        <Form
          name="signIn"
          layout="vertical"
          onFinish={onSubmit}
          id="confirmSignInForm"
          onFinishFailed={onSubmitFailed}
          className={cx(s.bbConfirmSignUpForm, globalStyles.bbFormXL)}
        >
          <div className={s.bbCodeSubmitContainer}>
            <Form.Item shouldUpdate>
              {(form: FormInstance<IFormValues>) => (
                <Form.Item name="code">
                  <CodeInput onChange={value => onCodeChange(form, value)} />
                </Form.Item>
              )}
            </Form.Item>

            <Button
              _size="lg"
              _type="primary"
              type="submit"
              loading={loading}
              form="confirmSignInForm"
              disabled={!isCodeEntered}
              className={s.bbConfirmSignUpSubmitBtn}
            >
              {t("components:confirmSignUp:enterPlatform")}
              <ChevronRightIcon />
            </Button>
          </div>

          <div className={s.bbConfirmSignUpHelpWrapper}>
            {onResendCode && (
              <Button
                _size="sm"
                _type="link"
                className={s.bbConfirmSignUpHelpBtn}
                onClick={e => onResendCode(e)}
              >
                <RedoIcon />
                {t("generic:resendCode")}
              </Button>
            )}
            <Button _size="sm" _type="link" className={s.bbConfirmSignUpHelpBtn}>
              <a href="mailto:help@brandbastion.com" target="_blank" rel="noreferrer">
                <HeadphoneIcon />
                {t("generic:contactSupport")}
              </a>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ConfirmSignIn;
