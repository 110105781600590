import { createReducer, ActionType } from "typesafe-actions";
import { IFiltersState } from "./types";

import {
  saveFilterAction,
  toggleOpenFiltersPanelAction,
  triggerFilteringAction,
  triggerFilteringWithoutUrlAction,
} from "./actions";
import {
  TRIGGER_FILTERING,
  RESET_TRIGGER_FILTERING,
  TRIGGER_FILTERING_WITHOUT_URL,
  RESET_TRIGGER_FILTERING_WITHOUT_URL,
  TOGGLE_OPEN_FILTERS_PANEL,
  SAVE_FILTER,
} from "./actionTypes";

export const OPEN_FILTERS_PANEL_WIDTH = 270;
export const COLLAPSED_FILTERS_PANEL_WIDTH = 20;

const initialState: IFiltersState = {
  filterData: undefined,
  filtersOpen: true,
  filtersPanelWidth: OPEN_FILTERS_PANEL_WIDTH,
  filterDataWithoutUrl: undefined,
  isFilteringWithoutUrl: false,
  savedFilterValue: null,
};

export const filtersReducer = createReducer<IFiltersState>(initialState, {
  [TOGGLE_OPEN_FILTERS_PANEL]: (
    state: IFiltersState,
    action: ActionType<typeof toggleOpenFiltersPanelAction>,
  ) => {
    const filtersOpen = action.payload;

    return {
      ...state,
      filtersOpen: filtersOpen,
      filtersPanelWidth: filtersOpen ? OPEN_FILTERS_PANEL_WIDTH : COLLAPSED_FILTERS_PANEL_WIDTH,
    };
  },
  [TRIGGER_FILTERING_WITHOUT_URL]: (
    state: IFiltersState,
    action: ActionType<typeof triggerFilteringWithoutUrlAction>,
  ) => {
    return {
      ...state,
      isFilteringWithoutUrl: true,
      filterDataWithoutUrl: action.payload,
    };
  },
  [RESET_TRIGGER_FILTERING_WITHOUT_URL]: (state: IFiltersState) => {
    return {
      ...state,
      isFilteringWithoutUrl: false,
      filterDataWithoutUrl: undefined,
    };
  },
  [TRIGGER_FILTERING]: (
    state: IFiltersState,
    action: ActionType<typeof triggerFilteringAction>,
  ) => {
    if (JSON.stringify(state.filterData) === JSON.stringify(action.payload)) {
      return state;
    }
    return {
      ...state,
      filterData: action.payload,
    };
  },
  [RESET_TRIGGER_FILTERING]: (state: IFiltersState) => {
    return {
      ...state,
      filterData: undefined,
    };
  },
  [SAVE_FILTER]: (state: IFiltersState, action: ActionType<typeof saveFilterAction>) => {
    return {
      ...state,
      savedFilterValue: action.payload,
    };
  },
});
