import { useEffect, useState } from "react";

const useElementObserver = (selector: string) => {
  const [targetElement, setTargetElement] = useState<Element | null>(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const el = document.querySelector(selector);
      if (el) {
        setTargetElement(el);
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    const el = document.querySelector(selector);
    if (el) {
      setTargetElement(el);
      observer.disconnect();
    }

    return () => {
      observer.disconnect();
    };
  }, [selector]);

  return targetElement;
};

export default useElementObserver;
