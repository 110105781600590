import { TFunction } from "react-i18next";
import { getDefaultFiltersConfig } from "../AppFilter/config";
import { IMe } from "@store/me/types";
import { isPostFilterAllowed } from "@components/insights/posts/postsTable/helpers";

export const getCustomFiltersBySection = (section: string | null, t: TFunction, me?: IMe) => {
  const defaultFilters = getDefaultFiltersConfig(t, me);

  if (section === "report-posts") {
    return defaultFilters
      .filter(({ name, type }) => isPostFilterAllowed(type, name))
      .map(filter => {
        if (filter.name === "data_range_option") {
          return {
            ...filter,
            label: t("components:posts:filters:date:label"),
            tooltip: t("components:posts:table:tableHeader:tooltips:post"),
          };
        }
        return filter;
      });
  } else if (section === "report-profiles") {
    return defaultFilters
      .filter(({ name }) => ["data_range_option", "platform_types", "asset_ids"].includes(name))
      .map(filter => {
        if (filter.name === "data_range_option") {
          return {
            ...filter,
            tooltip: "",
            props: {
              ...filter.props,
              hideAllTimeOption: true,
            },
          };
        } else if (filter.name === "platform_types") {
          return {
            ...filter,
            props: {
              ...filter.props,
              hiddenOptions: ["trustpilot", "google_business"],
            },
          };
        }
        return filter;
      });
  }

  return defaultFilters;
};
