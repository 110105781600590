// GET SHORTENED_SEARCH
export const GET_SHORTENED_SEARCHES = "SHORTENED_SEARCHES/GET_SHORTENED_SEARCHES";
export const GET_SHORTENED_SEARCHES_SUCCESS = "SHORTENED_SEARCHES/GET_SHORTENED_SEARCHES_SUCCESS";
export const GET_SHORTENED_SEARCHES_FAILURE = "SHORTENED_SEARCHES/GET_SHORTENED_SEARCHES_FAILURE";

// POST SHORTENED_SEARCH
export const POST_SHORTENED_SEARCHES = "SHORTENED_SEARCHES/POST_SHORTENED_SEARCHES";
export const POST_SHORTENED_SEARCHES_SUCCESS = "SHORTENED_SEARCHES/POST_SHORTENED_SEARCHES_SUCCESS";
export const POST_SHORTENED_SEARCHES_FAILURE = "SHORTENED_SEARCHES/POST_SHORTENED_SEARCHES_FAILURE";

export const RESET_SHORTENED_SEARCHES = "SHORTENED_SEARCHES/RESET_SHORTENED_SEARCHES";
