import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { addToast, Modal } from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import { SteamsList } from "./StreamsList";
import { StreamForm } from "./StreamForm";
import DeleteFilterModal from "./DeleteStreamModal";

import { getCareCpsStreamsAction, setCareCpsStreamAction } from "@store/streams/actions";
import {
  careCpsStreamResetAction,
  createCareCpsStreamAction,
  deleteCareCpsStreamAction,
  updateCareCpsStreamAction,
} from "@store/careCps/actions";
import { meSelector } from "@store/me/selectors";
import { careCpsCreateStreamSelector, careCpsDeleteStreamSelector } from "@store/careCps/selectors";
import { getCareCpsStreamsSelector } from "@store/streams/selectors";

import { DEFAULT_CONVERSATION_LIST } from "../CareCPSInbox/CareCPSInbox.helpers";

import { IStreamsModalProps } from "./StreamsModal.types";
import { ICareCPSStream } from "@store/streams/types";

export const StreamsModal: React.FC<IStreamsModalProps> = ({
  open,
  view,
  setView,
  close,
  ...restProps
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const [form] = Form.useForm<ICareCPSStream>();

  const [selectedStream, setSelectedStream] = useState<ICareCPSStream>();
  const streams = useSelector(getCareCpsStreamsSelector);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const me = useSelector(meSelector);

  const {
    fetching: createFetching,
    fetched: createFetched,
    fetchFail: createFetchFail,
  } = useSelector(careCpsCreateStreamSelector);

  const {
    fetching: deleteFetching,
    fetched: deleteFetched,
    fetchFail: deleteFetchFail,
  } = useSelector(careCpsDeleteStreamSelector);

  const onStreamSelect = useCallback(
    (stream: ICareCPSStream) => {
      close();
      dispatch(setCareCpsStreamAction(stream));
      setSearchParams({
        section: DEFAULT_CONVERSATION_LIST,
        stream: stream.id,
      });
    },
    [close, dispatch, setSearchParams],
  );

  const modalProps = {
    list: {
      ...restProps,
      hideFooter: true,
      hideHeader: true,
      hideCloseIcon: true,
    },
    form: {
      title: selectedStream
        ? t("components:careCps:streamsModal:editTitle")
        : t("components:careCps:streamsModal:addTitle"),
      confirmLabel: selectedStream ? t("generic:save") : t("generic:create"),
      confirmLoading: createFetching,
      onOk() {
        if (!createFetching) form.submit();
      },
    },
  };

  const onSave = (stream: ICareCPSStream) => {
    if (streams?.find(s => s.id === stream.id)) {
      dispatch(updateCareCpsStreamAction(stream));
    } else {
      dispatch(createCareCpsStreamAction(stream));
    }
  };

  const onEditClick = useCallback(
    (stream: ICareCPSStream) => {
      form.resetFields();
      form.setFieldsValue({
        ...stream,
        client_id: me?.client?.id,
      });
      setSelectedStream(stream);
      setView("form");
    },
    [setView, form, me?.client?.id],
  );

  const onDeleteClick = useCallback((stream: ICareCPSStream) => {
    setSelectedStream(stream);
    setConfirmDelete(true);
  }, []);

  const onDeleteConfirm = useCallback(() => {
    if (selectedStream) {
      dispatch(deleteCareCpsStreamAction(selectedStream.id));
      setSelectedStream(undefined);
      setConfirmDelete(false);
    }
  }, [selectedStream, dispatch]);

  const onCancel = useCallback(() => {
    form.resetFields();
    setSelectedStream(undefined);
    close();
  }, [close, form]);

  const isCreateView = open && view === "form" && !selectedStream;

  useEffect(() => {
    if (isCreateView) {
      form.resetFields();
      form.setFieldsValue({
        client_id: me?.client?.id,
      });
    }
  }, [isCreateView, form, me]);

  const createOrUpdateSuccess = createFetched && !createFetchFail;
  const deleteSuccess = deleteFetched && !deleteFetchFail;

  const handleStreamActionSuccess = useCallback(() => {
    const createOrUpdateTitle = selectedStream
      ? t("components:careCps:streamsModal:streamUpdated")
      : t("components:careCps:streamsModal:streamCreated");
    const deleteTitle = t("components:careCps:streamsModal:streamDeleted");

    addToast({
      title: createOrUpdateSuccess ? createOrUpdateTitle : deleteTitle,
      type: "success",
    });

    form.resetFields();
    setSelectedStream(undefined);
    setView("list");
    dispatch(careCpsStreamResetAction());
    dispatch(getCareCpsStreamsAction());
  }, [form, setSelectedStream, selectedStream, dispatch, setView, t, createOrUpdateSuccess]);

  useEffect(() => {
    if (createOrUpdateSuccess || deleteSuccess) {
      handleStreamActionSuccess();
    }
  }, [createOrUpdateSuccess, deleteSuccess, handleStreamActionSuccess]);

  return (
    <>
      <Modal
        open={open}
        width={600}
        noPadding
        title={t("generic:streams")}
        onCancel={onCancel}
        {...(!!view ? modalProps[view] : {})}
      >
        <>
          {view === "list" && (
            <SteamsList
              onStreamSelect={onStreamSelect}
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              onAddClick={() => setView("form")}
            />
          )}
          {view && ["form"].includes(view) && <StreamForm form={form} onSave={onSave} />}
        </>
      </Modal>
      <DeleteFilterModal
        stream={selectedStream}
        open={confirmDelete}
        onOk={onDeleteConfirm}
        confirmLoading={deleteFetching}
        onCancel={() => {
          setSelectedStream(undefined);
          setConfirmDelete(false);
        }}
      />
    </>
  );
};
