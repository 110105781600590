import { RootState } from "../";
import { AIInsightsModalStateEnum } from "./types";

export const fromAiInsights = (state: RootState) => state.aiInsights;

export const aiInsightsModalStateSelecor = (state: RootState) => fromAiInsights(state).state;
export const isAIInsightsModalOpen = (state: RootState) =>
  fromAiInsights(state).state !== AIInsightsModalStateEnum.Closed;

export const isAIInsightsModalExpanded = (state: RootState) =>
  fromAiInsights(state).state === AIInsightsModalStateEnum.Expanded;

export const aiInsightsModalTitleSelecor = (state: RootState) => fromAiInsights(state).title;
export const aiInsightsModalIdSelecor = (state: RootState) => fromAiInsights(state).id;
export const aiInsightsModalMessagesSelecor = (state: RootState) => fromAiInsights(state).messages;
export const aiInsightsLoading = (state: RootState) => fromAiInsights(state).fetchingGetMessages;
export const aiInsightsMessagesFetched = (state: RootState) =>
  fromAiInsights(state).fetchedGetMessages;
export const aiInsightsInitialState = (state: RootState) => fromAiInsights(state).initialState;
export const aiInsightsPreviousThreadSelector = (state: RootState) =>
  fromAiInsights(state).previousThread;
