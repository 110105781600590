import { ajax } from "rxjs/ajax";
import { Auth, JS } from "aws-amplify";
import React, { MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import ConfirmSignIn from "./ConfirmSignIn";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ConfirmSignInView.module.less";

import { signUp } from "@utils/paths";
import { IFormValues } from "./ConfirmSignIn.type";
import { IAuthenticatorProps } from "aws-amplify-react/lib-esm/Auth/Authenticator";

interface ICognitoUser {
  attributes?: {
    email?: string;
    email_verified?: boolean;
  };
}

interface IConfirmSignInViewProps extends IAuthenticatorProps {
  authData?: ICognitoUser;
}

const ConfirmSignInView = ({ authState, onStateChange, authData }: IConfirmSignInViewProps) => {
  const { t } = useTranslation();

  const error = (err: { message: string }) => {
    addToast({
      type: "danger_accent",
      title: t("generic:error"),
      message: err.message,
    });
  };

  const checkContact = async (user: ICognitoUser) => {
    await Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        onStateChange && onStateChange("signedIn", user);
      } else {
        user = Object.assign(user, data);
        onStateChange && onStateChange("signIn", user);
      }
    });
  };

  const valid = async ({ code }: IFormValues, callback?: () => void) => {
    const user = authData;

    if (user) {
      await Auth.confirmSignIn(user, (code || "").toString(), "SOFTWARE_TOKEN_MFA")
        .then(() => checkContact(user))
        .catch(err => {
          callback?.();
          error(err);
        });
    }
  };

  const onFinish = (values: IFormValues, callback?: () => void) => {
    if (authData?.attributes?.email_verified === false) {
      ajax
        .post(`${signUp}/verification`, {
          action: "verify_code",
          code: values.code,
          email: authData?.attributes?.email,
        })
        .subscribe(
          () => {
            onStateChange?.("signedIn", { isSigningIn: true });
            window.location.href = `${process.env.BASE_URL}/insights/overview`;
          },
          err => {
            callback?.();
            error(err.response);
          },
        );
    } else {
      valid(values, callback);
    }
  };

  const isActiveUser = () => {
    return !(authData?.attributes?.email_verified === false);
  };

  const onResendCode = (e: MouseEvent) => {
    e.preventDefault();
    ajax
      .post(`${signUp}/verification`, {
        action: "resend",
        email: authData?.attributes?.email || "",
      })
      .subscribe(
        () => {
          addToast({
            type: "info_accent",
            title: t("components:confirmSignIn:notification:codeSent:message"),
            message: t("components:confirmSignIn:notification:codeSent:description"),
          });
        },
        err => {
          addToast({
            type: "danger_accent",
            title: t("generic:error"),
            message: err.response.message,
          });
        },
      );
  };

  return authState === "confirmSignIn" ? (
    <div className={s.bbConfirmSignInContent}>
      <ConfirmSignIn
        onFinish={onFinish}
        isActiveUser={isActiveUser()}
        onResendCode={!isActiveUser() ? onResendCode : undefined}
      />
    </div>
  ) : null;
};

export default ConfirmSignInView;
