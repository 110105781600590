import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Input, TextareaNew as Textarea, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import { Form } from "antd";
import { TagsInput } from "./TagsInput";

import s from "./ZendeskTicketForm.module.less";

import { priorityOptions, statusOptions, typeOptions } from "./ZendeskTicketForm.helpers";

import { ZendeskTicketFormProps } from "./ZendeskTicketForm.type";

const ZendeskTicketForm = ({
  showDescription,
  dataPrefix,
  form,
  errors = {},
}: ZendeskTicketFormProps) => {
  const { t } = useTranslation();

  const getFormItemName = useCallback(
    (name: string) => {
      if (!dataPrefix) return name;

      const splitPrefix = dataPrefix.split(".");

      return [...splitPrefix, name];
    },
    [dataPrefix],
  );
  const getInputName = useCallback(
    (name: string) => (dataPrefix ? `${dataPrefix}.${name}` : name),
    [dataPrefix],
  );

  return (
    <>
      <Form.Item
        name={getFormItemName("subject")}
        data-cy="ticket-subject"
        label={t("components:zendeskForm:subject:label")}
        className={s.bbZendeskTicketFormItem}
        rules={[
          {
            required: true,
            message: t("components:zendeskForm:subject:error"),
          },
        ]}
      >
        <Input
          _size="md"
          name={getInputName("subject")}
          data-cy="ticket-subject-input"
          hasError={!!errors.subject}
          wrapperClassName={s.bbZendeskTicketFormInput}
        />
      </Form.Item>
      <div className={s.bbZendeskTicketFormRow}>
        <Form.Item
          name={getFormItemName("priority")}
          data-cy="ticket-priority"
          label={t("components:zendeskForm:priority:label")}
          className={s.bbZendeskTicketFormItem}
        >
          <SelectStyled
            _size="md"
            name={getInputName("priority")}
            data-cy="ticket-priority-input"
            options={priorityOptions}
            wrapperClassName={s.bbZendeskTicketFormInput}
          />
        </Form.Item>
        <Form.Item
          name={getFormItemName("type")}
          data-cy="ticket-type"
          label={t("components:zendeskForm:type:label")}
          className={s.bbZendeskTicketFormItem}
        >
          <SelectStyled
            _size="md"
            name={getInputName("type")}
            data-cy="ticket-type-input"
            options={typeOptions}
            wrapperClassName={s.bbZendeskTicketFormInput}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={getFormItemName("status")}
        data-cy="ticket-status"
        label={t("components:zendeskForm:status:label")}
        className={s.bbZendeskTicketFormItem}
        rules={[
          {
            required: true,
            message: t("components:zendeskForm:status:error"),
          },
        ]}
      >
        <SelectStyled
          _size="md"
          name={getInputName("status")}
          data-cy="ticket-status-input"
          options={statusOptions}
          hasError={!!errors.status}
          wrapperClassName={s.bbZendeskTicketFormInput}
        />
      </Form.Item>
      {showDescription && (
        <Form.Item
          name={getFormItemName("description")}
          data-cy="ticket-description"
          label={t("components:zendeskForm:description:label")}
          className={s.bbZendeskTicketFormItem}
          rules={[
            {
              required: true,
              message: t("components:zendeskForm:description:error"),
            },
          ]}
        >
          <Textarea
            name={getInputName("description")}
            data-cy="ticket-description-input"
            hasError={!!errors.description}
            rows={6}
            className={s.bbZendeskTicketFormInput}
          />
        </Form.Item>
      )}
      <Form.Item
        name={getFormItemName("tags")}
        data-cy="ticket-tags"
        label={t("components:zendeskForm:tags:label")}
        className={s.bbZendeskTicketFormItem}
      >
        <TagsInput
          customprops={{ name: getFormItemName("tags"), form }}
          wrapperClassName={s.bbZendeskTicketFormInput}
        />
      </Form.Item>
    </>
  );
};

export default ZendeskTicketForm;
