import { useEffect, useState } from "react";

export const useImagePreload = (src: string) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!src) {
      return;
    }

    const img = new Image();
    img.src = src;

    const handleLoad = () => {
      setLoaded(true);
    };

    const handleError = () => {
      setError(true);
    };

    img.addEventListener("load", handleLoad);
    img.addEventListener("error", handleError);
    return () => {
      img.removeEventListener("load", handleLoad);
      img.removeEventListener("error", handleError);
    };
  }, [src]);

  return [loaded, error];
};
