import { StateObservable } from "redux-observable";

import { isTiktokApp } from "./isTiktokApp";

import { RootState } from "@store/index";

const contentType = "application/json";

// eslint-disable-next-line
export const getHeaders = (data: { [key: string]: any }) => {
  return {
    ...data,
    "Content-Frame": isTiktokApp() ? "iframe" : "",
    "Content-Type": contentType,
  };
};

export const getAuthAPIHeaders = (state$: StateObservable<RootState>) =>
  getHeaders({
    Authorization: state$.value.auth.session.accessToken.jwtToken,
  });
