import React from "react";

import { IActionSummaryStat, IHiddenBreakdownUnit } from "@store/dashboard/types";
import { ILine } from "@bbdevcrew/bb_ui_kit_fe";
import { i18nextInstance } from "../../../App";
import { IDataSet } from "@store/dashboard/types";
import { ICSVSentimentsData } from "@components/_common/DownloadCSV";

import { ReplyIcon, TagIcon } from "@assets/index";
import { EyeOffIcon } from "@bbdevcrew/bb_ui_kit_fe";
import SmtTotal from "@assets/sentiment/SentimentTotal.svg";

export const CHART_HEIGHT = 160;
export const COMMENTS_LIMIT = 4;

export const getSummaryIcon = (id: IActionSummaryStat["id"]) => {
  const icons: Record<IActionSummaryStat["id"], JSX.Element> = {
    tags: <TagIcon />,
    total_conversations: <SmtTotal />,
    hidden_conversations: <EyeOffIcon />,
    replies: <ReplyIcon />,
  };
  return icons[id];
};

export const getCSVTimelineData = (data: IDataSet[]) => {
  return data.map(item => {
    return {
      name: `${item.date.replace(",", " ").trim()}`,
      end_date: `${item.end_date.replace(",", " ").trim()}`,
      hidden: item.hidden.toString(),
    };
  });
};

export const getCSVTableHeaders = (columnName?: string) => {
  const headers = columnName
    ? [columnName]
    : [i18nextInstance.t("components:managed:protect:timeline:columnTitles:date")];
  return [
    ...headers,
    i18nextInstance.t("components:managed:protect:timeline:columnTitles:total"),
    i18nextInstance.t("components:managed:protect:timeline:columnTitles:hidden"),
  ];
};

export const getCSVBreakdownData = (breakdownUnits: IHiddenBreakdownUnit[], currentTab: string) => {
  let data: ICSVSentimentsData[] = [];
  const selectedDataSet = breakdownUnits.find(unit => unit.id === currentTab);

  if (selectedDataSet) {
    data = selectedDataSet.items.map(item => {
      const comment_stats: { [key: string]: string } = {};
      let total = 0;
      Object.values(item.comment_stats).forEach(el => {
        comment_stats["hidden"] = el?.toString();
        total += el;
      });

      return {
        name: item.label,
        total: total?.toString(),
        hidden: comment_stats["hidden"],
      };
    });
  }

  return data;
};

export const getTimelineLines = () => {
  return [
    {
      dataKey: "hidden",
      stroke: "var(--color-danger-700)",
      name: i18nextInstance.t("components:managed:protect:timeline:columnTitles:hidden"),
    },
  ] as ILine[];
};
