import { StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf, isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError, delay, withLatestFrom } from "rxjs/operators";

import * as actions from "./actions";
import { postModuleSectionsListAction } from "@store/modules/actions";
import {
  ASSIGN_COMMENT_USER_SUCCESS,
  PERFORM_COMMENT_ACTION_SUCCESS,
} from "@store/comments/actionTypes";
import { ASSIGN_TO_ME_SUCCESS } from "@store/assign/actionTypes";
import { filterDataSelector } from "@store/filters/selectors";
import { getCurrentCareCpsStreamSelector } from "@store/streams/selectors";

import { moderationUsers, getCurrentModuleName, careStreams } from "@utils/paths";
import { handleError } from "@utils/apiErrorHandler";
import { getHeaders } from "@utils/headers";

import { CareCpsActionsType, IModerationGroup } from "./types";
import { RootState } from "..";
import { GET_ME_SUCCESS } from "@store/me/actionTypes";
import { IMe } from "@store/me/types";

import { mockTags } from "./mockData";
import { ICareCPSStream } from "@store/streams/types";

const MODULE_NAME = "care_cps";

export const updateModuleStatsEpic = (
  action$: Observable<{ type: string }>,
  state$: Observable<RootState>,
) =>
  action$.pipe(
    filter(
      (action): boolean =>
        action.type === PERFORM_COMMENT_ACTION_SUCCESS ||
        action.type === ASSIGN_TO_ME_SUCCESS ||
        action.type === ASSIGN_COMMENT_USER_SUCCESS,
    ),
    withLatestFrom(state$),
    map(([, state]) => {
      if (getCurrentModuleName() === MODULE_NAME) {
        return postModuleSectionsListAction({
          module: MODULE_NAME,
          filters: filterDataSelector(state),
          streamId: getCurrentCareCpsStreamSelector(state)?.id,
        });
      }
    }),
  );

export const getCareCpsSearchClient = (action$: Observable<CareCpsActionsType>) =>
  action$.pipe(
    filter(isOfType(GET_ME_SUCCESS)),
    map(({ payload }: { payload: IMe }) => {
      const { id = "", name = "" } = payload.client || {};
      return actions.searchClientSuccessAction([{ id, name }]);
    }),
  );

export const getTagsEpic = (action$: Observable<CareCpsActionsType>) =>
  action$.pipe(
    filter(isActionOf(actions.getTagsAction)),
    switchMap(() => {
      return of(mockTags)
        .pipe(
          delay(500),
          map(response => ({
            response: response,
          })),
        )
        .pipe(
          map(e => e.response),
          map(response => actions.getTagsSuccessAction(response)),
          catchError(e => handleError(e, actions.getTagsFailureAction)),
        );
    }),
  );

export const getModerationUsersEpic = (
  action$: Observable<CareCpsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getModerationUsersAction)),
    switchMap(() => {
      return ajax
        .get<{ items: IModerationGroup[] }>(
          moderationUsers,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(({ items }) => actions.getModerationUsersSuccessAction(items)),
          catchError(e => handleError(e, actions.getModerationUsersFailureAction)),
        );
    }),
  );

export const createCareCpsStreamEpic = (
  action$: Observable<CareCpsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.createCareCpsStreamAction)),
    switchMap(({ payload }) => {
      return ajax
        .post<ICareCPSStream>(
          careStreams,
          {
            ...payload,
            filters: payload.filters || [],
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(response => actions.createOrUpdateCareCpsStreamSuccessAction(response)),
          catchError(e => handleError(e, actions.createOrUpdateCareCpsStreamFailureAction)),
        );
    }),
  );

export const updateCareCpsStreamEpic = (
  action$: Observable<CareCpsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.updateCareCpsStreamAction)),
    switchMap(({ payload }) => {
      return ajax
        .patch<ICareCPSStream>(
          `${careStreams}/${payload.id}`,
          {
            ...payload,
            filters: payload.filters || [],
            id: undefined,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(response => actions.createOrUpdateCareCpsStreamSuccessAction(response)),
          catchError(e => handleError(e, actions.createOrUpdateCareCpsStreamFailureAction)),
        );
    }),
  );

export const deleteCareCpsStreamEpic = (
  action$: Observable<CareCpsActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.deleteCareCpsStreamAction)),
    switchMap(({ payload: streamId }) => {
      return ajax
        .delete(
          `${careStreams}/${streamId}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(() => actions.deleteCareCpsStreamSuccessAction()),
          catchError(e => handleError(e, actions.deleteCareCpsStreamFailureAction)),
        );
    }),
  );
