import { createReducer, ActionType } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { INotificationsState } from "./types";

const initialState: INotificationsState = {
  notifications: [],

  fetchingNotifications: false,
  fetchedNotifications: false,
  fetchingNotificationsFail: false,
};

export const notificationsReducer = createReducer<INotificationsState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.getNotificationsAction, state =>
    produce(state, draft => {
      draft.fetchingNotifications = true;
      draft.fetchedNotifications = false;
      draft.fetchingNotificationsFail = false;
    }),
  )
  .handleAction(actions.getNotificationsSuccessAction, (state, action) =>
    produce(state, draft => {
      draft.notifications = action.payload.items;
      draft.fetchingNotifications = false;
      draft.fetchedNotifications = true;
      draft.fetchingNotificationsFail = false;
    }),
  )
  .handleAction(actions.getNotificationsFailureAction, state =>
    produce(state, draft => {
      draft.notifications = [];
      draft.fetchingNotifications = false;
      draft.fetchedNotifications = false;
      draft.fetchingNotificationsFail = true;
    }),
  )
  .handleAction(actions.markAllAsReadAction, state =>
    produce(state, draft => {
      draft.notifications = draft.notifications.map(notification => ({
        ...notification,
        status: "read",
      }));
    }),
  )
  .handleAction(actions.markAsReadAction, (state, action) =>
    produce(state, draft => {
      draft.notifications = draft.notifications.map(notification => ({
        ...notification,
        status: notification.id === action.payload ? "read" : notification.status,
      }));
    }),
  );
