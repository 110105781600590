import React from "react";
import { i18n } from "i18next";

import s from "./AssignUser.module.less";

import { IUser } from "@store/users/types";
import { getI18next } from "../../languages/i18next";

const i18nextinstance = getI18next() as i18n;
const t = i18nextinstance.t;

export function generateTooltip(
  assignedUsers: string[],
  allUsers: IUser[],
  loggedInUserId?: string,
) {
  if (assignedUsers.length === 0) {
    return t("components:comments:tooltips:assignComment");
  }

  const mappedUsers = allUsers.filter(({ id }) => assignedUsers.includes(id));

  if (mappedUsers.length === 1) {
    const { id, first_name, last_name, is_disabled } = mappedUsers[0];

    return `${t("components:comments:tooltips:assignedTo")} ${first_name} ${last_name} ${
      id === loggedInUserId ? `(${t("generic:me")})` : ""
    } ${is_disabled ? `(${t("generic:disabled")})`.toUpperCase() : ""}`;
  }

  return (
    <>
      <span className={s.bbDisplayBlock}>{`${t("components:comments:tooltips:assignedTo")}:`}</span>
      {mappedUsers.map(({ id, first_name, last_name, is_disabled }) => (
        <span key={id} className={s.bbDisplayBlock}>
          {first_name} {last_name}
          {id === loggedInUserId ? ` (${t("generic:me")})` : ""}
          {is_disabled ? ` (${t("generic:disabled")})`.toUpperCase() : ""}
        </span>
      ))}
    </>
  );
}
