import { parseCookies, setCookie } from "./cookies";
import { ICognitoCookies } from "@store/tiktokApp/types";

export const setCognitoCookies = (userCookies: ICognitoCookies) => {
  Object.entries(userCookies).map(cookie => {
    return setCookie(cookie[0], cookie[1], 1);
  });
};

export const extractCognitoCookies = () => {
  const allCookies = parseCookies(document.cookie);
  const cognitoCookies: ICognitoCookies = {};

  for (const key in allCookies) {
    if (key.includes("Cognito")) {
      cognitoCookies[key] = allCookies[key];
    }
  }

  if (Object.keys(cognitoCookies).length === 0) return undefined;

  return cognitoCookies;
};
