import React from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { IStoryNotAvailableProps } from "./Variants.types";

import s from "../Attachment.module.less";

const StoryNotAvailable: React.FC<IStoryNotAvailableProps> = ({
  isOwned = false,
  isPreviewMessage = false,
  className,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        s.bbStoryNotAvailable,
        {
          [s.bbStoryNotAvailablePreview]: isPreviewMessage,
          [s.bbStoryNotAvailableOwned]: isOwned,
        },
        className,
      )}
      {...restProps}
    >
      {t("components:comments:storyNoLongerAvailable")}
    </div>
  );
};

export default StoryNotAvailable;
