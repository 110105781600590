import {
  OrganicIcon,
  AdIcon,
  BrokenLinkIcon,
  RocketIcon,
  CameraIcon,
  PictureIcon,
  CarouselImageIcon,
} from "../assets";

export const PostTypeIcon = {
  pure_organic: OrganicIcon,
  pure_ads: AdIcon,
  unlinked_ads: BrokenLinkIcon,
  boosted_posts: RocketIcon,
};

export const MediaTypeIcon = {
  video: CameraIcon,
  image: PictureIcon,
  multiimage: CarouselImageIcon,
  other: "",
};
