import produce from "immer";
import { createReducer, ActionType } from "typesafe-actions";

import {
  getUsersSuccessAction,
  patchUserStatusSuccessAction,
  postUserSuccessAction,
} from "./actions";
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  PUT_USER,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  PATCH_USER_STATUS,
  PATCH_USER_STATUS_SUCCESS,
  PATCH_USER_STATUS_FAILURE,
  POST_USER,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
} from "./actionTypes";

import { IUsersState } from "./types";

const initialState = {
  users: [],
  fetchingGetUsers: false,
  fetchedGetUsers: false,
  fetchedGetUsersFail: false,

  fetchingPutUser: false,
  fetchedPutUser: false,
  fetchedPutUserFail: false,
  fetchingPatchUserStatus: false,
  fetchedPatchUserStatus: false,
  fetchedPatchUserStatusFail: false,
  fetchingPostUser: false,
  fetchedPostUser: false,
  fetchedPostUserFail: false,
};

export const usersReducer = createReducer<IUsersState>(initialState, {
  [GET_USERS]: (state: IUsersState) => ({
    ...state,
    fetchingGetUsers: true,
    fetchedGetUsers: false,
    fetchedGetUsersFail: false,
  }),
  [GET_USERS_SUCCESS]: (state: IUsersState, action: ActionType<typeof getUsersSuccessAction>) => ({
    ...state,
    users: action.payload,
    fetchingGetUsers: false,
    fetchedGetUsers: true,
    fetchedGetUsersFail: false,
  }),
  [GET_USERS_FAILURE]: (state: IUsersState) => ({
    ...state,
    fetchingGetUsers: false,
    fetchedGetUsers: false,
    fetchedGetUsersFail: true,
  }),
  //PUT USER
  [PUT_USER]: (state: IUsersState) => ({
    ...state,
    fetchingPutUser: true,
    fetchedPutUser: false,
    fetchedPutUserFail: false,
  }),
  [PUT_USER_SUCCESS]: produce((draft, action) => {
    const keyPutUser = draft.users.findIndex(({ id }) => id === action.payload.id);

    if (keyPutUser) {
      draft.users[keyPutUser] = {
        ...draft.users[keyPutUser],
        ...action.payload,
        status: !action.payload.is_disabled,
      };
      draft.fetchingPutUser = false;
      draft.fetchedPutUser = true;
      draft.fetchedPutUserFail = false;
    } else {
      draft.fetchingPutUser = false;
      draft.fetchedPutUser = true;
      draft.fetchedPutUserFail = false;
    }
  }),
  [PUT_USER_FAILURE]: (state: IUsersState) => ({
    ...state,
    fetchingPutUser: false,
    fetchedPutUser: false,
    fetchedPutUserFail: true,
  }),

  //PATCH USER STATUS
  [PATCH_USER_STATUS]: (state: IUsersState) => ({
    ...state,
    fetchingPatchUserStatus: true,
    fetchedPatchUserStatus: false,
    fetchedPatchUserStatusFail: false,
  }),
  [PATCH_USER_STATUS_SUCCESS]: (
    state: IUsersState,
    action: ActionType<typeof patchUserStatusSuccessAction>,
  ) => {
    const keyPatchUserStatus = state.users.findIndex(user => user && user.id === action.payload.id);

    if (keyPatchUserStatus) {
      return {
        ...state,
        users: Object.assign([], {
          ...state.users,
          [keyPatchUserStatus]: {
            ...action.payload,
            status: !action.payload.is_disabled,
          },
        }),
        fetchingPatchUserStatus: false,
        fetchedPatchUserStatus: true,
        fetchedPatchUserStatusFail: false,
      };
    }

    return {
      ...state,
      fetchingPatchUserStatus: false,
      fetchedPatchUserStatus: true,
      fetchedPatchUserStatusFail: false,
    };
  },
  [PATCH_USER_STATUS_FAILURE]: (state: IUsersState) => ({
    ...state,
    fetchingPatchUserStatus: false,
    fetchedPatchUserStatus: false,
    fetchedPatchUserStatusFail: true,
  }),

  // POST USER
  [POST_USER]: (state: IUsersState) => ({
    ...state,
    fetchingPostUser: true,
    fetchedPostUser: false,
    fetchedPostUserFail: false,
  }),
  [POST_USER_SUCCESS]: (state: IUsersState, action: ActionType<typeof postUserSuccessAction>) => {
    return {
      ...state,
      users: [...state.users, { ...action.payload, status: true }],
      fetchingPostUser: false,
      fetchedPostUser: true,
      fetchedPostUserFail: false,
    };
  },
  [POST_USER_FAILURE]: (state: IUsersState) => ({
    ...state,
    fetchingPostUser: false,
    fetchedPostUser: false,
    fetchedPostUserFail: true,
  }),
});
