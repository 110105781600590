import React, { FC } from "react";

import s from "./Compare.module.less";

interface ICompareNoDataProps {
  text: string;
  icon: JSX.Element;
}

const CompareNoData: FC<ICompareNoDataProps> = ({ icon, text }) => {
  return (
    <div className={s.bbNoProjectsSelected}>
      <div className={s.bbNoProjectsIcon}>{icon}</div>
      <div className={s.bbNoProjectsText}>{text}</div>
    </div>
  );
};

export default CompareNoData;
