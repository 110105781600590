import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { addToast } from "@bbdevcrew/bb_ui_kit_fe";

import { assignToMeAction } from "@store/assign/actions";
import { filterDataSelector } from "@store/filters/selectors";
import { getInboxStatsAction } from "@store/inboxStats/actions";
import { getInboxRepliesAction, postCommentsSuccessAction } from "@store/comments/actions";

import {
  OnTabChangeHandlerType,
  AssignToMeClickHandlerType,
} from "@components/care/inbox/inboxCommentsHeader/types";
import { AssignListType } from "@store/assign/types";
import { IInboxStats } from "@store/inboxStats/types";

export function useInbox(defaultStartTabValue: keyof IInboxStats = "review", sortValue?: string) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const [statTabValue, setStatTabValue] = useState<keyof IInboxStats>(defaultStartTabValue);

  const filters = useSelector(filterDataSelector);

  const onTabChange: OnTabChangeHandlerType = useCallback(
    tab => {
      const queryParamsId = searchParams.get("id") || "";
      setStatTabValue(tab);
      setSearchParams({
        tab,
        ...(queryParamsId && { id: queryParamsId }),
      });
      dispatch(postCommentsSuccessAction([]));
    },
    [dispatch, searchParams, setSearchParams],
  );

  const assignToMeHandler: AssignToMeClickHandlerType = useCallback(
    (assign_from_tab: string, closeModal) =>
      dispatch(
        assignToMeAction(
          (assign_from_tab || statTabValue) as AssignListType,
          filters,
          undefined,
          () => {
            addToast({
              type: "success_accent",
              title: t("components:assignToMe:successMessage"),
            });
            if (statTabValue === "assigned_to_me") {
              const sortVals = sortValue?.split("|");
              dispatch(
                getInboxRepliesAction({
                  filters: filters || {},
                  list_type: statTabValue,
                  sort: sortVals && sortVals[0],
                  sort_field: sortVals && sortVals[1],
                }),
              );
            } else {
              onTabChange("assigned_to_me");
            }
            dispatch(getInboxStatsAction(filters || {}));
            closeModal();
          },
        ),
      ),
    [dispatch, onTabChange, statTabValue, filters, sortValue, t],
  );

  return {
    statTabValue,
    setStatTabValue,
    onTabChange,
    assignToMeHandler,
  };
}
