import { action } from "typesafe-actions";
import {
  GET_ASSETS,
  GET_ASSETS_SUCCESS,
  GET_ASSETS_FAILURE,
  CONFIGURE_ASSET_MONITORING,
  CONFIGURE_ASSET_MONITORING_SUCCESS,
  CONFIGURE_ASSET_MONITORING_FAILURE,
  DELETE_ASSET,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  PATCH_RECONNECT_ASSETS,
  PATCH_RECONNECT_ASSETS_SUCCESS,
  PATCH_RECONNECT_ASSETS_FAILURE,
} from "./actionTypes";
import {
  IConfigureMonitoringRequest,
  IReconnectAssetsPayload,
  IReconnectAssetsResponse,
} from "./types";
import { IAsset } from "@components/_common/AssetTabs/AssetTabs.type";

export const getAssetsAction = () => action(GET_ASSETS);
export const getAssetsActionSuccess = (assets: IAsset[]) => action(GET_ASSETS_SUCCESS, assets);
export const getAssetsActionFailure = () => action(GET_ASSETS_FAILURE);

export const configureAssetMonitoringAction = (payload: IConfigureMonitoringRequest) =>
  action(CONFIGURE_ASSET_MONITORING, payload);
export const configureAssetMonitoringActionSuccess = (payload: IConfigureMonitoringRequest) =>
  action(CONFIGURE_ASSET_MONITORING_SUCCESS, payload);
export const configureAssetMonitoringActionFailure = () =>
  action(CONFIGURE_ASSET_MONITORING_FAILURE);

export const deleteAssetAction = (asset_id: string) => action(DELETE_ASSET, asset_id);
export const deleteAssetActionSuccess = (asset_id: string) =>
  action(DELETE_ASSET_SUCCESS, asset_id);
export const deleteAssetActionFailure = () => action(DELETE_ASSET_FAILURE);

export const patchReconnectAssetsAction = (data: IReconnectAssetsPayload) =>
  action(PATCH_RECONNECT_ASSETS, data);
export const patchReconnectAssetsActionSuccess = (data: IReconnectAssetsResponse) =>
  action(PATCH_RECONNECT_ASSETS_SUCCESS, data);
export const patchReconnectAssetsActionFailure = (data: IReconnectAssetsResponse) =>
  action(PATCH_RECONNECT_ASSETS_FAILURE, data);
