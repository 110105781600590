import { useTranslation } from "react-i18next";
import React, { useState, useEffect, MouseEvent } from "react";
import cn from "classnames/bind";

import ThreadBody from "./ThreadBody/ThreadBody";
import Attachments from "./attachment/Attachment";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import { isPrivateConversation } from "@utils/comments";

import s from "./CommentCardBody.module.less";

import { cropText } from "@utils/cropText";
import { ICommentCardBodyProps } from "./CommentCardBody.type";
import { formatDateEnUs } from "../../../insights/overview/helpers";

const cx = cn.bind(s);

const CommentCardBody: React.FC<ICommentCardBodyProps> = ({
  comment,
  isParent,
  maxLengthComment,
  showSeeMore = true,
  onTranslateClick,
  onReplyClick,
}) => {
  const { t } = useTranslation();

  const isTrustpilotPlatform = comment.platform_type.id === "trustpilot";

  const [seeMore, setSeeMore] = useState(false);
  const [isTranslated, setIsTranslated] = useState(comment.is_translated);
  const [messageComment, setMessageComment] = useState(comment.message);

  useEffect(() => {
    if (comment) {
      if (comment.is_translated) setMessageComment(comment.message_translated as string);
      else setMessageComment(comment.message as string);
    }
    // eslint-disable-next-line
  }, [comment]);

  const onClickTranslate = (event: MouseEvent) => {
    event.preventDefault();

    setIsTranslated(!isTranslated);

    if (onTranslateClick) {
      onTranslateClick(comment.id, isParent ? true : false);
    }
  };

  const attachments = (
    <Attachments createdTime={comment.created_time} attachments={comment?.attachments || []} />
  );

  const translateMessageButton = (
    <Button _type="link" _size="sm" onClick={onClickTranslate} className={s.bbTranslateMesssage}>
      {isTranslated
        ? t("components:comments:actions:showOriginal")
        : t("components:comments:actions:translate")}
    </Button>
  );

  if (comment.post_placement && isPrivateConversation(comment.post_placement)) {
    return <ThreadBody onReplyClick={onReplyClick} comment={comment} />;
  }

  return (
    <>
      {comment.title && <div className={s.bbCommentsTitle}>{comment.title}</div>}
      <div
        className={cx(s.bbCommentsMessage, {
          [s.bbCommentsTrustpilotMessage]: isTrustpilotPlatform,
        })}
      >
        {maxLengthComment && !seeMore ? (
          <>
            <span>{cropText(messageComment || "", maxLengthComment)}</span>
            {comment.message_translated && translateMessageButton}
            {attachments}
          </>
        ) : (
          <div className={s.bbScrollableCommentBody}>
            <span>{messageComment}</span>
            {comment.message_translated && translateMessageButton}
            {attachments}
          </div>
        )}

        {showSeeMore &&
          !!maxLengthComment &&
          !!messageComment &&
          messageComment.length > maxLengthComment &&
          !seeMore && (
            <div className={s.bbSeeMoreButtonContainer}>
              <Button
                _size="sm"
                _type="secondary"
                className={s.bbSeeMoreButton}
                onClick={() => setSeeMore(true)}
              >
                {t("components:comments:seeMore")}
              </Button>
            </div>
          )}
      </div>
      {!!comment.tp_data?.experienced_at && (
        <div className={s.bbCommentsDate}>
          <span>{t("components:comments:cardBody:date")}:</span>
          <span>{formatDateEnUs(comment.tp_data.experienced_at, "dd MMM, yyyy")}</span>
        </div>
      )}
    </>
  );
};

export default CommentCardBody;
