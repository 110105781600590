import React from "react";
import classNames from "classnames";

import PostCardImage from "./PostCardImage";
import PostCardHeader from "./PostCardHeader";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PostCardMessage from "./PostCardMessage";
import ReactResizeDetector from "react-resize-detector";

import s from "./PostCard.module.less";

import { IPostCardProps } from "./PostCard.type";

const MAX_MESSAGE_LENGTH = 85;

const PostCard: React.FC<IPostCardProps> = ({
  message,
  showMoreText,
  postTypeText,
  stonlyTarget,
  postImageProps,
  pageImageProps,
  headerActionItem,
  className,
  maxMessageLength = MAX_MESSAGE_LENGTH,
  renderCardFooter,
}) => {
  return (
    <Card data-stonly-target={stonlyTarget} className={classNames(s.bbPostCard, className)}>
      <div
        data-cy="post-card"
        className={classNames(s.bbPostCardContainer, {
          [s.bbPostCardTextImage]: postImageProps?.showTextAsImage,
        })}
      >
        <ReactResizeDetector
          handleWidth
          refreshMode="throttle"
          refreshOptions={{ leading: true, trailing: true }}
        >
          {(props: { width: number }) => (
            <>
              <PostCardHeader
                postTypeText={postTypeText}
                pageName={pageImageProps.pageName}
                pageImage={pageImageProps.pageImage}
                pageImageShape={pageImageProps.pageImageShape}
                pageCollaborators={pageImageProps.pageCollaborators}
                headerActionItem={headerActionItem}
              />

              {!postImageProps?.showTextAsImage && (
                <p
                  data-cy="post-card-message"
                  className={classNames(s.bbPostMessage, {
                    [s.bbPostMessagePortrait]: postImageProps?.isPortraitImg,
                  })}
                >
                  <PostCardMessage
                    message={message}
                    showMoreText={showMoreText}
                    maxMessageLength={maxMessageLength}
                  />
                </p>
              )}

              {postImageProps?.customImageRender ? (
                postImageProps?.customImageRender
              ) : (
                <PostCardImage
                  message={message}
                  containerWidth={props.width}
                  imageUrl={postImageProps?.imageUrl}
                  fullImage={postImageProps?.fullImage}
                  onImageClick={postImageProps?.onImageClick}
                  isPortraitImage={postImageProps?.isPortraitImg}
                  showTextAsImage={postImageProps?.showTextAsImage}
                  imageActionItem={postImageProps?.imageActionItem}
                />
              )}

              {renderCardFooter ? renderCardFooter(props.width) : null}
            </>
          )}
        </ReactResizeDetector>
      </div>
    </Card>
  );
};

export default PostCard;
