export const replaceAll = (string: string, search: string, replacement: string) => {
  const target = string;
  return target.replace(new RegExp(search, "g"), replacement);
};

export const camelize = (string: string) =>
  string
    .toLowerCase()
    .replace(/([-_][a-z])/g, group => group.toUpperCase().replace("-", "").replace("_", ""));

export const humanize = (string: string) => {
  return string.replace("_", " ").replace(/((^|_| |\/|,|')\w)/g, w => w.toUpperCase());
};
