/* eslint-disable max-len */
import AD_Andorra from "@assets/country-flags/AD_Andorra.svg";
import AE_UnitedArabEmirates from "@assets/country-flags/AE_UnitedArabEmirates.svg";
import AF_Afghanistan from "@assets/country-flags/AF_Afghanistan.svg";
import AG_AntiguaAndBarbuda from "@assets/country-flags/AG_AntiguaAndBarbuda.svg";
import AI_Anguilla from "@assets/country-flags/AI_Anguilla.svg";
import AL_Albania from "@assets/country-flags/AL_Albania.svg";
import AM_Armenia from "@assets/country-flags/AM_Armenia.svg";
import AO_Angola from "@assets/country-flags/AO_Angola.svg";
import AQ_Antarctica from "@assets/country-flags/AQ_Antarctica.svg";
import AR_Argentina from "@assets/country-flags/AR_Argentina.svg";
import AS_AmericanSamoa from "@assets/country-flags/AS_AmericanSamoa.svg";
import AT_Austria from "@assets/country-flags/AT_Austria.svg";
import AU_Australia from "@assets/country-flags/AU_Australia.svg";
import AW_Aruba from "@assets/country-flags/AW_Aruba.svg";
import AZ_Azerbaijan from "@assets/country-flags/AZ_Azerbaijan.svg";
import BA_BosniaAndHerzegovina from "@assets/country-flags/BA_BosniaAndHerzegovina.svg";
import BB_Barbados from "@assets/country-flags/BB_Barbados.svg";
import BD_Bangladesh from "@assets/country-flags/BD_Bangladesh.svg";
import BE_Belgium from "@assets/country-flags/BE_Belgium.svg";
import BF_BurkinaFaso from "@assets/country-flags/BF_BurkinaFaso.svg";
import BG_Bulgaria from "@assets/country-flags/BG_Bulgaria.svg";
import BH_Bahrain from "@assets/country-flags/BH_Bahrain.svg";
import BI_Burundi from "@assets/country-flags/BI_Burundi.svg";
import BJ_Benin from "@assets/country-flags/BJ_Benin.svg";
import BL_SaintBarthelemy from "@assets/country-flags/BL_SaintBarthelemy.svg";
import BM_Bermuda from "@assets/country-flags/BM_Bermuda.svg";
import BN_BruneiDarussalam from "@assets/country-flags/BN_BruneiDarussalam.svg";
import BO_Bolivia from "@assets/country-flags/BO_Bolivia.svg";
import BR_Brazil from "@assets/country-flags/BR_Brazil.svg";
import BS_Bahamas from "@assets/country-flags/BS_Bahamas.svg";
import BT_Bhutan from "@assets/country-flags/BT_Bhutan.svg";
import BV_BouvetIsland from "@assets/country-flags/BV_BouvetIsland.svg";
import BW_Botswana from "@assets/country-flags/BW_Botswana.svg";
import BY_Belarus from "@assets/country-flags/BY_Belarus.svg";
import BZ_Belize from "@assets/country-flags/BZ_Belize.svg";
import CA_Canada from "@assets/country-flags/CA_Canada.svg";
import CC_CocosIslands from "@assets/country-flags/CC_CocosIslands.svg";
import CD_DemocraticRepublicOfTheCongo from "@assets/country-flags/CD_DemocraticRepublicOfTheCongo.svg";
import CF_CentralAfricanRepublic from "@assets/country-flags/CF_CentralAfricanRepublic.svg";
import CG_RepublicOfTheCongo from "@assets/country-flags/CG_RepublicOfTheCongo.svg";
import CH_Switzerland from "@assets/country-flags/CH_Switzerland.svg";
import CI_IvoryCoast from "@assets/country-flags/CI_IvoryCoast.svg";
import CK_CookIslands from "@assets/country-flags/CK_CookIslands.svg";
import CL_Chile from "@assets/country-flags/CL_Chile.svg";
import CM_Cameroon from "@assets/country-flags/CM_Cameroon.svg";
import CN_China from "@assets/country-flags/CN_China.svg";
import CO_Colombia from "@assets/country-flags/CO_Colombia.svg";
import CR_CostaRica from "@assets/country-flags/CR_CostaRica.svg";
import CU_Cuba from "@assets/country-flags/CU_Cuba.svg";
import CV_CaboVerde from "@assets/country-flags/CV_CaboVerde.svg";
import CX_ChristmasIsland from "@assets/country-flags/CX_ChristmasIsland.svg";
import CY_Cyprus from "@assets/country-flags/CY_Cyprus.svg";
import CZ_CzechRepublic from "@assets/country-flags/CZ_CzechRepublic.svg";
import DE_Germany from "@assets/country-flags/DE_Germany.svg";
import DJ_Djibouti from "@assets/country-flags/DJ_Djibouti.svg";
import DK_Denmark from "@assets/country-flags/DK_Denmark.svg";
import DM_Dominica from "@assets/country-flags/DM_Dominica.svg";
import DO_DominicanRepublic from "@assets/country-flags/DO_DominicanRepublic.svg";
import DZ_Algeria from "@assets/country-flags/DZ_Algeria.svg";
import EC_Ecuador from "@assets/country-flags/EC_Ecuador.svg";
import EE_Estonia from "@assets/country-flags/EE_Estonia.svg";
import EG_Egypt from "@assets/country-flags/EG_Egypt.svg";
import EH_WesternSahara from "@assets/country-flags/EH_WesternSahara.svg";
import ER_Eritrea from "@assets/country-flags/ER_Eritrea.svg";
import ES_Spain from "@assets/country-flags/ES_Spain.svg";
import ET_Ethiopia from "@assets/country-flags/ET_Ethiopia.svg";
import FI_Finland from "@assets/country-flags/FI_Finland.svg";
import FJ_Fiji from "@assets/country-flags/FJ_Fiji.svg";
import FK_FalklandIslands from "@assets/country-flags/FK_FalklandIslands.svg";
import FM_FederatedStatesOfMicronesia from "@assets/country-flags/FM_FederatedStatesOfMicronesia.svg";
import FO_FaroeIslands from "@assets/country-flags/FO_FaroeIslands.svg";
import FR_France from "@assets/country-flags/FR_France.svg";
import GA_Gabon from "@assets/country-flags/GA_Gabon.svg";
import UKM_United_Kingdom from "@assets/country-flags/GB-UKM_United Kingdom.svg";
import GD_Grenada from "@assets/country-flags/GD_Grenada.svg";
import GE_Georgia from "@assets/country-flags/GE_Georgia.svg";
import GF_FrenchGuiana from "@assets/country-flags/GF_FrenchGuiana.svg";
import GG_Guernsey from "@assets/country-flags/GG_Guernsey.svg";
import GH_Ghana from "@assets/country-flags/GH_Ghana.svg";
import GI_Gibraltar from "@assets/country-flags/GI_Gibraltar.svg";
import GL_Greenland from "@assets/country-flags/GL_Greenland.svg";
import GM_Gambia from "@assets/country-flags/GM_Gambia.svg";
import GN_Guinea from "@assets/country-flags/GN_Guinea.svg";
import GP_Guadeloupe from "@assets/country-flags/GP_Guadeloupe.svg";
import GQ_EquatorialGuinea from "@assets/country-flags/GQ_EquatorialGuinea.svg";
import GR_Greece from "@assets/country-flags/GR_Greece.svg";
import GS_SGeorgiaAndSSandwichIslands from "@assets/country-flags/GS_SGeorgiaAndSSandwichIslands.svg";
import GT_Guatemala from "@assets/country-flags/GT_Guatemala.svg";
import GU_Guam from "@assets/country-flags/GU_Guam.svg";
import GW_GuineaBissau from "@assets/country-flags/GW_GuineaBissau.svg";
import GY_Guyana from "@assets/country-flags/GY_Guyana.svg";
import HK_HongKong from "@assets/country-flags/HK_HongKong.svg";
import HM_HeardAndMcDonaldIslands from "@assets/country-flags/HM_HeardAndMcDonaldIslands.svg";
import HN_Honduras from "@assets/country-flags/HN_Honduras.svg";
import HR_Croatia from "@assets/country-flags/HR_Croatia.svg";
import HT_Haiti from "@assets/country-flags/HT_Haiti.svg";
import HU_Hungary from "@assets/country-flags/HU_Hungary.svg";
import ID_Indonesia from "@assets/country-flags/ID_Indonesia.svg";
import IE_Ireland from "@assets/country-flags/IE_Ireland.svg";
import IL_Isreal from "@assets/country-flags/IL_Isreal.svg";
import IM_IsleOfMan from "@assets/country-flags/IM_IsleOfMan.svg";
import IN_India from "@assets/country-flags/IN_India.svg";
import IO_BritishIndianOceanTerritory from "@assets/country-flags/IO_BritishIndianOceanTerritory.svg";
import IQ_Iraq from "@assets/country-flags/IQ_Iraq.svg";
import IR_Iran from "@assets/country-flags/IR_Iran.svg";
import IS_Iceland from "@assets/country-flags/IS_Iceland.svg";
import IT_Italy from "@assets/country-flags/IT_Italy.svg";
import JE_Jersey from "@assets/country-flags/JE_Jersey.svg";
import JM_Jamaica from "@assets/country-flags/JM_Jamaica.svg";
import JO_Jordan from "@assets/country-flags/JO_Jordan.svg";
import JP_Japan from "@assets/country-flags/JP_Japan.svg";
import KE_Kenia from "@assets/country-flags/KE_Kenia.svg";
import KG_Kyrgyzstan from "@assets/country-flags/KG_Kyrgyzstan.svg";
import KH_Cambodia from "@assets/country-flags/KH_Cambodia.svg";
import KI_Kiribati from "@assets/country-flags/KI_Kiribati.svg";
import KM_Comoros from "@assets/country-flags/KM_Comoros.svg";
import KN_SaintKittsAndNevis from "@assets/country-flags/KN_SaintKittsAndNevis.svg";
import KR_SouthKorea from "@assets/country-flags/KR_SouthKorea.svg";
import KW_Kuwait from "@assets/country-flags/KW_Kuwait.svg";
import KY_CaymanIslands from "@assets/country-flags/KY_CaymanIslands.svg";
import KZ_Kazakhstan from "@assets/country-flags/KZ_Kazakhstan.svg";
import LA_Laos from "@assets/country-flags/LA_Laos.svg";
import LB_Lebanon from "@assets/country-flags/LB_Lebanon.svg";
import LC_SaintLucia from "@assets/country-flags/LC_SaintLucia.svg";
import LI_Liechtenstein from "@assets/country-flags/LI_Liechtenstein.svg";
import LK_SriLanka from "@assets/country-flags/LK_SriLanka.svg";
import LR_Liberia from "@assets/country-flags/LR_Liberia.svg";
import LS_Lesotho from "@assets/country-flags/LS_Lesotho.svg";
import LT_Lithuania from "@assets/country-flags/LT_Lithuania.svg";
import LU_Luxembourg from "@assets/country-flags/LU_Luxembourg.svg";
import LV_Latvia from "@assets/country-flags/LV_Latvia.svg";
import LY_Libya from "@assets/country-flags/LY_Libya.svg";
import MA_Morocco from "@assets/country-flags/MA_Morocco.svg";
import MC_Monaco from "@assets/country-flags/MC_Monaco.svg";
import MD_Moldova from "@assets/country-flags/MD_Moldova.svg";
import ME_Montenegro from "@assets/country-flags/ME_Montenegro.svg";
import MF_SaintMartin from "@assets/country-flags/MF_SaintMartin.svg";
import MG_Madagascar from "@assets/country-flags/MG_Madagascar.svg";
import MH_MarshallIslands from "@assets/country-flags/MH_MarshallIslands.svg";
import MK_NorthMacedonia from "@assets/country-flags/MK_NorthMacedonia.svg";
import ML_Mali from "@assets/country-flags/ML_Mali.svg";
import MM_Myanmar from "@assets/country-flags/MM_Myanmar.svg";
import MN_Mongolia from "@assets/country-flags/MN_Mongolia.svg";
import MO_Macao from "@assets/country-flags/MO_Macao.svg";
import MP_NorthernMarianaIslands from "@assets/country-flags/MP_NorthernMarianaIslands.svg";
import MQ_Martinique from "@assets/country-flags/MQ_Martinique.svg";
import MR_Mauritania from "@assets/country-flags/MR_Mauritania.svg";
import MS_Montserrat from "@assets/country-flags/MS_Montserrat.svg";
import MT_Malta from "@assets/country-flags/MT_Malta.svg";
import MU_Mauritius from "@assets/country-flags/MU_Mauritius.svg";
import MV_Maldives from "@assets/country-flags/MV_Maldives.svg";
import MW_Malawi from "@assets/country-flags/MW_Malawi.svg";
import MX_Mexico from "@assets/country-flags/MX_Mexico.svg";
import MY_Malaysia from "@assets/country-flags/MY_Malaysia.svg";
import MZ_Mozambique from "@assets/country-flags/MZ_Mozambique.svg";
import NA_Namibia from "@assets/country-flags/NA_Namibia.svg";
import NC_NewCaledonia from "@assets/country-flags/NC_NewCaledonia.svg";
import NE_Niger from "@assets/country-flags/NE_Niger.svg";
import NF_NorfolkIsland from "@assets/country-flags/NF_NorfolkIsland.svg";
import NG_Nigeria from "@assets/country-flags/NG_Nigeria.svg";
import NI_Nicaragua from "@assets/country-flags/NI_Nicaragua.svg";
import NL_Netherlands from "@assets/country-flags/NL_Netherlands.svg";
import NO_Norway from "@assets/country-flags/NO_Norway.svg";
import NP_Nepal from "@assets/country-flags/NP_Nepal.svg";
import NR_Nauru from "@assets/country-flags/NR_Nauru.svg";
import NU_Niue from "@assets/country-flags/NU_Niue.svg";
import NZ_NewZealand from "@assets/country-flags/NZ_NewZealand.svg";
import OM_Oman from "@assets/country-flags/OM_Oman.svg";
import PA_Panama from "@assets/country-flags/PA_Panama.svg";
import PE_Peru from "@assets/country-flags/PE_Peru.svg";
import PF_FrenchPolynesia from "@assets/country-flags/PF_FrenchPolynesia.svg";
import PG_PapuaNewGuinea from "@assets/country-flags/PG_PapuaNewGuinea.svg";
import PH_Philippines from "@assets/country-flags/PH_Philippines.svg";
import PK_Pakistan from "@assets/country-flags/PK_Pakistan.svg";
import PL_Poland from "@assets/country-flags/PL_Poland.svg";
import PM_SaintPierreAndMiquelon from "@assets/country-flags/PM_SaintPierreAndMiquelon.svg";
import PN_PitcairnIslands from "@assets/country-flags/PN_PitcairnIslands.svg";
import PR_PuertoRico from "@assets/country-flags/PR_PuertoRico.svg";
import PS_PalestinianTerritory from "@assets/country-flags/PS_PalestinianTerritory.svg";
import PT_Portugal from "@assets/country-flags/PT_Portugal.svg";
import PW_Palau from "@assets/country-flags/PW_Palau.svg";
import PY_Paraguay from "@assets/country-flags/PY_Paraguay.svg";
import QA_Qatar from "@assets/country-flags/QA_Qatar.svg";
import RE_Reunion from "@assets/country-flags/RE_Reunion.svg";
import RO_Romania from "@assets/country-flags/RO_Romania.svg";
import RS_Serbia from "@assets/country-flags/RS_Serbia.svg";
import RU_RussianFederation from "@assets/country-flags/RU_RussianFederation.svg";
import RW_Rwanda from "@assets/country-flags/RW_Rwanda.svg";
import SA_SaudiArabia from "@assets/country-flags/SA_SaudiArabia.svg";
import SB_SolomonIslands from "@assets/country-flags/SB_SolomonIslands.svg";
import SC_Seychelles from "@assets/country-flags/SC_Seychelles.svg";
import SD_Sudan from "@assets/country-flags/SD_Sudan.svg";
import SE_Sweden from "@assets/country-flags/SE_Sweden.svg";
import SG_Singapore from "@assets/country-flags/SG_Singapore.svg";
import SH_SaintHelena from "@assets/country-flags/SH_SaintHelena.svg";
import SI_Slovenia from "@assets/country-flags/SI_Slovenia.svg";
import SJ_SvalbardAndJanMayen from "@assets/country-flags/SJ_SvalbardAndJanMayen.svg";
import SK_Slovakia from "@assets/country-flags/SK_Slovakia.svg";
import SL_SierraLeone from "@assets/country-flags/SL_SierraLeone.svg";
import SM_SanMarino from "@assets/country-flags/SM_SanMarino.svg";
import SN_Senegal from "@assets/country-flags/SN_Senegal.svg";
import SO_Somalia from "@assets/country-flags/SO_Somalia.svg";
import SR_Suriname from "@assets/country-flags/SR_Suriname.svg";
import ST_SaoTomeAndPrincipe from "@assets/country-flags/ST_SaoTomeAndPrincipe.svg";
import SV_ElSalvador from "@assets/country-flags/SV_ElSalvador.svg";
import SY_Syria from "@assets/country-flags/SY_Syria.svg";
import SZ_Swaziland from "@assets/country-flags/SZ_Swaziland.svg";
import TC_TurksAndCaicosIslands from "@assets/country-flags/TC_TurksAndCaicosIslands.svg";
import TD_Chad from "@assets/country-flags/TD_Chad.svg";
import TF_FrenchSouthernAndAntarcticLands from "@assets/country-flags/TF_FrenchSouthernAndAntarcticLands.svg";
import TG_Togo from "@assets/country-flags/TG_Togo.svg";
import TH_Thailand from "@assets/country-flags/TH_Thailand.svg";
import TJ_Tajikistan from "@assets/country-flags/TJ_Tajikistan.svg";
import TK_Tokelau from "@assets/country-flags/TK_Tokelau.svg";
import TL_TimorLeste from "@assets/country-flags/TL_TimorLeste.svg";
import TM_Turkmenistan from "@assets/country-flags/TM_Turkmenistan.svg";
import TN_Tunisia from "@assets/country-flags/TN_Tunisia.svg";
import TO_Tonga from "@assets/country-flags/TO_Tonga.svg";
import TR_Turkey from "@assets/country-flags/TR_Turkey.svg";
import TT_TrinidadAndTobago from "@assets/country-flags/TT_TrinidadAndTobago.svg";
import TV_Tuvalu from "@assets/country-flags/TV_Tuvalu.svg";
import TW_Taiwan from "@assets/country-flags/TW_Taiwan.svg";
import TZ_Tanzania from "@assets/country-flags/TZ_Tanzania.svg";
import UA_Ukraine from "@assets/country-flags/UA_Ukraine.svg";
import UG_Uganda from "@assets/country-flags/UG_Uganda.svg";
import UM_UnitedStatesMinorOutlyingIslands from "@assets/country-flags/UM_UnitedStatesMinorOutlyingIslands.svg";
import US_UnitedStates from "@assets/country-flags/US_UnitedStates.svg";
import UY_Uruguay from "@assets/country-flags/UY_Uruguay.svg";
import UZ_Uzbekistan from "@assets/country-flags/UZ_Uzbekistan.svg";
import VA_VaticanCityState from "@assets/country-flags/VA_VaticanCityState.svg";
import VC_SaintVincentAndTheGrenadines from "@assets/country-flags/VC_SaintVincentAndTheGrenadines.svg";
import VE_Venezuela from "@assets/country-flags/VE_Venezuela.svg";
import VG_VirginIslandsBritish from "@assets/country-flags/VG_VirginIslandsBritish.svg";
import VI_VirginIslandsUS from "@assets/country-flags/VI_VirginIslandsUS.svg";
import VN_Vietnam from "@assets/country-flags/VN_Vietnam.svg";
import VU_Vanuatu from "@assets/country-flags/VU_Vanuatu.svg";
import WF_WallisAndFutuna from "@assets/country-flags/WF_WallisAndFutuna.svg";
import WS_Samoa from "@assets/country-flags/WS_Samoa.svg";
import YE_Yemen from "@assets/country-flags/YE_Yemen.svg";
import YT_UNF_Mayotte from "@assets/country-flags/YT-UNF_Mayotte.svg";
import ZA_SouthAfrica from "@assets/country-flags/ZA_SouthAfrica.svg";
import ZM_Zambia from "@assets/country-flags/ZM_Zambia.svg";
import ZW_Zimbabwe from "@assets/country-flags/ZW_Zimbabwe.svg";

export default {
  AD_Andorra,
  AE_UnitedArabEmirates,
  AF_Afghanistan,
  AG_AntiguaAndBarbuda,
  AI_Anguilla,
  AL_Albania,
  AM_Armenia,
  AO_Angola,
  AQ_Antarctica,
  AR_Argentina,
  AS_AmericanSamoa,
  AT_Austria,
  AU_Australia,
  AW_Aruba,
  AZ_Azerbaijan,
  BA_BosniaAndHerzegovina,
  BB_Barbados,
  BD_Bangladesh,
  BE_Belgium,
  BF_BurkinaFaso,
  BG_Bulgaria,
  BH_Bahrain,
  BI_Burundi,
  BJ_Benin,
  BL_SaintBarthelemy,
  BM_Bermuda,
  BN_BruneiDarussalam,
  BO_Bolivia,
  BR_Brazil,
  BS_Bahamas,
  BT_Bhutan,
  BV_BouvetIsland,
  BW_Botswana,
  BY_Belarus,
  BZ_Belize,
  CA_Canada,
  CC_CocosIslands,
  CD_DemocraticRepublicOfTheCongo,
  CF_CentralAfricanRepublic,
  CG_RepublicOfTheCongo,
  CH_Switzerland,
  CI_IvoryCoast,
  CK_CookIslands,
  CL_Chile,
  CM_Cameroon,
  CN_China,
  CO_Colombia,
  CR_CostaRica,
  CU_Cuba,
  CV_CaboVerde,
  CX_ChristmasIsland,
  CY_Cyprus,
  CZ_CzechRepublic,
  DE_Germany,
  DJ_Djibouti,
  DK_Denmark,
  DM_Dominica,
  DO_DominicanRepublic,
  DZ_Algeria,
  EC_Ecuador,
  EE_Estonia,
  EG_Egypt,
  EH_WesternSahara,
  ER_Eritrea,
  ES_Spain,
  ET_Ethiopia,
  FI_Finland,
  FJ_Fiji,
  FK_FalklandIslands,
  FM_FederatedStatesOfMicronesia,
  FO_FaroeIslands,
  FR_France,
  GA_Gabon,
  UKM_United_Kingdom,
  GD_Grenada,
  GE_Georgia,
  GF_FrenchGuiana,
  GG_Guernsey,
  GH_Ghana,
  GI_Gibraltar,
  GL_Greenland,
  GM_Gambia,
  GN_Guinea,
  GP_Guadeloupe,
  GQ_EquatorialGuinea,
  GR_Greece,
  GS_SGeorgiaAndSSandwichIslands,
  GT_Guatemala,
  GU_Guam,
  GW_GuineaBissau,
  GY_Guyana,
  HK_HongKong,
  HM_HeardAndMcDonaldIslands,
  HN_Honduras,
  HR_Croatia,
  HT_Haiti,
  HU_Hungary,
  ID_Indonesia,
  IE_Ireland,
  IL_Isreal,
  IM_IsleOfMan,
  IN_India,
  IO_BritishIndianOceanTerritory,
  IQ_Iraq,
  IR_Iran,
  IS_Iceland,
  IT_Italy,
  JE_Jersey,
  JM_Jamaica,
  JO_Jordan,
  JP_Japan,
  KE_Kenia,
  KG_Kyrgyzstan,
  KH_Cambodia,
  KI_Kiribati,
  KM_Comoros,
  KN_SaintKittsAndNevis,
  KR_SouthKorea,
  KW_Kuwait,
  KY_CaymanIslands,
  KZ_Kazakhstan,
  LA_Laos,
  LB_Lebanon,
  LC_SaintLucia,
  LI_Liechtenstein,
  LK_SriLanka,
  LR_Liberia,
  LS_Lesotho,
  LT_Lithuania,
  LU_Luxembourg,
  LV_Latvia,
  LY_Libya,
  MA_Morocco,
  MC_Monaco,
  MD_Moldova,
  ME_Montenegro,
  MF_SaintMartin,
  MG_Madagascar,
  MH_MarshallIslands,
  MK_NorthMacedonia,
  ML_Mali,
  MM_Myanmar,
  MN_Mongolia,
  MO_Macao,
  MP_NorthernMarianaIslands,
  MQ_Martinique,
  MR_Mauritania,
  MS_Montserrat,
  MT_Malta,
  MU_Mauritius,
  MV_Maldives,
  MW_Malawi,
  MX_Mexico,
  MY_Malaysia,
  MZ_Mozambique,
  NA_Namibia,
  NC_NewCaledonia,
  NE_Niger,
  NF_NorfolkIsland,
  NG_Nigeria,
  NI_Nicaragua,
  NL_Netherlands,
  NO_Norway,
  NP_Nepal,
  NR_Nauru,
  NU_Niue,
  NZ_NewZealand,
  OM_Oman,
  PA_Panama,
  PE_Peru,
  PF_FrenchPolynesia,
  PG_PapuaNewGuinea,
  PH_Philippines,
  PK_Pakistan,
  PL_Poland,
  PM_SaintPierreAndMiquelon,
  PN_PitcairnIslands,
  PR_PuertoRico,
  PS_PalestinianTerritory,
  PT_Portugal,
  PW_Palau,
  PY_Paraguay,
  QA_Qatar,
  RE_Reunion,
  RO_Romania,
  RS_Serbia,
  RU_RussianFederation,
  RW_Rwanda,
  SA_SaudiArabia,
  SB_SolomonIslands,
  SC_Seychelles,
  SD_Sudan,
  SE_Sweden,
  SG_Singapore,
  SH_SaintHelena,
  SI_Slovenia,
  SJ_SvalbardAndJanMayen,
  SK_Slovakia,
  SL_SierraLeone,
  SM_SanMarino,
  SN_Senegal,
  SO_Somalia,
  SR_Suriname,
  ST_SaoTomeAndPrincipe,
  SV_ElSalvador,
  SY_Syria,
  SZ_Swaziland,
  TC_TurksAndCaicosIslands,
  TD_Chad,
  TF_FrenchSouthernAndAntarcticLands,
  TG_Togo,
  TH_Thailand,
  TJ_Tajikistan,
  TK_Tokelau,
  TL_TimorLeste,
  TM_Turkmenistan,
  TN_Tunisia,
  TO_Tonga,
  TR_Turkey,
  TT_TrinidadAndTobago,
  TV_Tuvalu,
  TW_Taiwan,
  TZ_Tanzania,
  UA_Ukraine,
  UG_Uganda,
  UM_UnitedStatesMinorOutlyingIslands,
  US_UnitedStates,
  UY_Uruguay,
  UZ_Uzbekistan,
  VA_VaticanCityState,
  VC_SaintVincentAndTheGrenadines,
  VE_Venezuela,
  VG_VirginIslandsBritish,
  VI_VirginIslandsUS,
  VN_Vietnam,
  VU_Vanuatu,
  WF_WallisAndFutuna,
  WS_Samoa,
  YE_Yemen,
  YT_UNF_Mayotte,
  ZA_SouthAfrica,
  ZM_Zambia,
  ZW_Zimbabwe,
};
