import React, { UIEventHandler, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { CloseIcon, Loading, Modal, SearchInput, SelectStyledMulti } from "@bbdevcrew/bb_ui_kit_fe";
import { Button } from "antd";
import { Share2Icon } from "@assets/index";
import { SharePostItem } from "./sharePostItem/SharePostItem";
import SelectTrigger from "../SelectTrigger/SelectTrigger";
import { LoadingOutlined } from "@ant-design/icons";

import { getAccountPostsAction } from "@store/replies/actions";
import { getAutocompleteOptionsAction } from "@store/autocomplete/actions";
import {
  accountPostsSelector,
  gettingAccountPostsHasMoreSelector,
  gettingAccountPostsPageSelector,
  gettingAccountPostsSelector,
} from "@store/replies/selectors";
import { autocompleteOptionsSelector } from "@store/autocomplete/selectors";

import s from "./SharePostSelector.module.less";

import { SharePostSelectorProps } from "./SharePostSelector.type";
import { IPost } from "../Social/Post/Post.type";

export const SharePostSelector = ({ pageId, onSelectPost }: SharePostSelectorProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedAutocompletePostsIds, setSelectedAutocompletePostsIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  const currentPage = useSelector(gettingAccountPostsPageSelector);
  const hasMore = useSelector(gettingAccountPostsHasMoreSelector);
  const gettingAccountPosts = useSelector(gettingAccountPostsSelector);
  const accountPosts = useSelector(accountPostsSelector);
  const autocompletePosts = useSelector(autocompleteOptionsSelector);

  const fetchPosts = useCallback(
    ({ postsIds, page }: { postsIds?: string[]; page: number }) =>
      dispatch(
        getAccountPostsAction({
          filters: {
            platform_types: ["tiktok"],
            asset_ids: pageId ? [pageId] : [],
            include_not_initialized: true,
            ...(postsIds && postsIds.length && { post_ids: postsIds }),
          },
          pagination: { page },
        }),
      ),
    [dispatch, pageId],
  );

  const onScroll: UIEventHandler<HTMLDivElement> = useCallback(
    ({ target }) => {
      const reachedBottom =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Math.ceil(target.clientHeight + target.scrollTop) >= target.scrollHeight;

      if (reachedBottom && !gettingAccountPosts && hasMore) {
        fetchPosts({ page: currentPage + 1 });
      }
    },
    [currentPage, fetchPosts, gettingAccountPosts, hasMore],
  );

  useEffect(() => {
    if (open) {
      fetchPosts({ page: 1, postsIds: selectedAutocompletePostsIds });
    }
  }, [fetchPosts, selectedAutocompletePostsIds, open]);

  useEffect(() => {
    if (open) {
      dispatch(
        getAutocompleteOptionsAction({
          query: searchTerm,
          field: "post",
          filter: "posts_for_tiktok_dms",
        }),
      );
    }
  }, [dispatch, open, searchTerm]);

  const selectAutocompletePost = (id: string) =>
    setSelectedAutocompletePostsIds(prev =>
      prev.includes(id) ? prev.filter(postId => postId !== id) : [...prev, id],
    );
  const selectPost = (post: IPost) => {
    onSelectPost(post);
    setOpen(false);
  };

  return (
    <div className={s.bbSharePostWrapper}>
      <Button id="share-post-btn" className={s.bbSharePostButton} onClick={() => setOpen(true)}>
        <Share2Icon />
      </Button>
      <Modal
        open={open}
        centered
        hideHeader
        hideCloseIcon
        hideFooter
        onCancel={() => setOpen(false)}
      >
        <div className={s.bbSharePostSelectorModal}>
          <div className={s.bbSharePostSelectorModalHeader}>
            <div className={s.bbSharePostSelectorModalHeaderTitle}>
              {t("components:reply:replyPrivately:tiktok:sharePostSelectorModal:title")}
            </div>
            <div className={s.bbSharePostSelectorModalHeaderActions}>
              <SelectStyledMulti
                options={autocompletePosts.post.map(item => ({
                  id: item.id as string,
                  value: item.id,
                  label: item.label,
                }))}
                _size="sm"
                value={selectedAutocompletePostsIds}
                placeholder={t(
                  "components:reply:replyPrivately:tiktok:sharePostSelectorModal:filterPlaceholder",
                )}
                highlightTerm={searchTerm}
                onChange={selectAutocompletePost}
                TriggerVariation={SelectTrigger}
                DropdownHeader={
                  <SearchInput searchTerm={searchTerm} handleSetSearchTerm={setSearchTerm} />
                }
              />
              <div
                className={s.bbSharePostSelectorModalHeaderActionsCloseWrapper}
                onClick={() => setOpen(false)}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <div className={s.bbSharePostSelectorModalContent} onScroll={onScroll}>
            <ul className={s.bbSharePostSelectorModalContentList}>
              {accountPosts.map(post => (
                <SharePostItem key={post.id} post={post} onClick={() => selectPost(post)} />
              ))}
            </ul>
            {gettingAccountPosts && (
              <Loading
                isLoading
                type="spinning"
                size="small"
                indicator={<LoadingOutlined style={{ fontSize: 24 }} />}
                className={s.bbSharePostSelectorModalContentLoading}
              />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
