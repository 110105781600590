import React from "react";
import { i18n } from "i18next";
import classNames from "classnames";

import s from "../CommentCard/commentActions/CommentActions.module.less";

import { MediaTypeIcon } from "@utils/postType";
import { PostMediaType } from "../Social/Post/Post.type";
import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { getI18next } from "../../../languages/i18next";

export const t = (getI18next() as i18n).t;

export const sentimentOptions: IObjectOption[] = [
  {
    id: "positive",
    value: "positive",
    label: t("components:comments:actions:sentiments:positive"),
    iconPosition: "left",
    icon: (
      <span
        className={classNames(
          s.bbActionCircleClass,
          s.bbCommentDetailsSentiment,
          s[`bbActionCircleClass-positive`],
        )}
      >
        {" "}
      </span>
    ),
  },
  {
    id: "negative",
    value: "negative",
    label: t("components:comments:actions:sentiments:negative"),
    iconPosition: "left",
    icon: (
      <span
        className={classNames(
          s.bbActionCircleClass,
          s.bbCommentDetailsSentiment,
          s[`bbActionCircleClass-negative`],
        )}
      >
        {" "}
      </span>
    ),
  },
  {
    id: "neutral",
    value: "neutral",
    label: t("components:comments:actions:sentiments:neutral"),
    iconPosition: "left",
    icon: (
      <span
        className={classNames(
          s.bbActionCircleClass,
          s.bbCommentDetailsSentiment,
          s[`bbActionCircleClass-neutral`],
        )}
      >
        {" "}
      </span>
    ),
  },
];

export const getShortcutTooltipTitle = (): React.ReactNode => {
  return (
    <>
      <div className={s.bbShortcutsTooltip}>
        {`${t("components:comments:details:shortcutTooltip:shortcut:previous")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:next")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:hide")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:star")}${""} 
      ${t("components:comments:details:shortcutTooltip:shortcut:archive")}${""} 
      `}
      </div>

      {t("components:comments:details:shortcutTooltip:shortcut:windows:description")}
      <ul>
        <li className={s.bbShortcutsTooltipItem}>
          {t("components:comments:details:shortcutTooltip:shortcut:windows:altKey")}
        </li>

        <li className={s.bbShortcutsTooltipItem}>
          {t("components:comments:details:shortcutTooltip:shortcut:windows:controlKey")}
        </li>
      </ul>
    </>
  );
};

export const getMediaTypeIcon = (post_media_id: PostMediaType) => {
  const Icon = MediaTypeIcon[post_media_id as keyof typeof MediaTypeIcon];

  if (!Icon) return null;
  return <Icon />;
};
