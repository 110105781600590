import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SentimentBar.module.less";
import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { ICommentMetrics } from "../Social/Post/Post.type";
import { ISentimentStats, renderStatsTooltip } from "./helpers";

interface ISentimentBarProps {
  height?: number;
  data: ICommentMetrics;
}

const SentimentBar: FC<ISentimentBarProps> = ({ data, height = 9 }) => {
  const { t } = useTranslation();

  const totalSum = data.total_comments;

  const positivePercentage = (data.positive_comments / totalSum) * 100;
  const negativePercentage = (data.negative_comments / totalSum) * 100;
  const neutralPercentage = (data.neutral_comments / totalSum) * 100;
  const userTagPercentage = (data.user_tags_comments / totalSum) * 100;
  const notApplicablePercentage = (data.not_applicable_comments / totalSum) * 100;

  const statsMapping: ISentimentStats = {
    negative: data.negative_comments,
    positive: data.positive_comments,
    neutral: data.neutral_comments,
    user_tags: data.user_tags_comments,
    not_applicable: data.not_applicable_comments,
    total: data.total_comments,
  };

  return (
    <Tooltip title={renderStatsTooltip(statsMapping, t)} overlayInnerStyle={{ fontSize: 12 }}>
      {!!totalSum && (
        <div className={s.bbSentimentBar} style={{ height: height }}>
          {!!negativePercentage && (
            <div
              style={{
                flex: `${negativePercentage}%`,
                background: palette.colorNegative,
              }}
            />
          )}
          {!!positivePercentage && (
            <div
              style={{
                flex: `${positivePercentage}%`,
                background: palette.colorPositive,
              }}
            />
          )}
          {!!userTagPercentage && (
            <div
              style={{
                flex: `${userTagPercentage}%`,
                background: palette.colorUserTags,
              }}
            />
          )}
          {!!neutralPercentage && (
            <div
              style={{
                flex: `${neutralPercentage}%`,
                background: palette.colorNeutral,
              }}
            />
          )}
          {!!notApplicablePercentage && (
            <div
              style={{
                flex: `${notApplicablePercentage}%`,
                background: palette.colorStriped,
              }}
            />
          )}
        </div>
      )}
    </Tooltip>
  );
};

export default SentimentBar;
