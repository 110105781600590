import { action } from "typesafe-actions";
import {
  CREATE_ASSET_GROUP,
  CREATE_ASSET_GROUP_SUCCESS,
  CREATE_ASSET_GROUP_FAILURE,
  GET_ASSET_GROUP,
  GET_ASSET_GROUP_SUCCESS,
  GET_ASSET_GROUP_FAILURE,
  UPDATE_ASSET_GROUP,
  UPDATE_ASSET_GROUP_SUCCESS,
  UPDATE_ASSET_GROUP_FAILURE,
  DELETE_ASSET_GROUP,
  DELETE_ASSET_GROUP_SUCCESS,
  DELETE_ASSET_GROUP_FAILURE,
} from "./actionTypes";
import {
  IAssetGroupData,
  IModifiableAssetGroupData,
} from "@components/_common/AssetGroupModal/AssetGroupModal.types";

export const getAssetGroupAction = () => action(GET_ASSET_GROUP);
export const getAssetGroupActionSuccess = (groups: IAssetGroupData[]) =>
  action(GET_ASSET_GROUP_SUCCESS, groups);
export const getAssetGroupActionFailure = () => action(GET_ASSET_GROUP_FAILURE);

export const createAssetGroupAction = (data: IModifiableAssetGroupData) =>
  action(CREATE_ASSET_GROUP, data);
export const createAssetGroupActionSuccess = (group: IAssetGroupData) =>
  action(CREATE_ASSET_GROUP_SUCCESS, group);
export const createAssetGroupActionFailure = () => action(CREATE_ASSET_GROUP_FAILURE);

export const updateAssetGroupAction = (data: IAssetGroupData) => action(UPDATE_ASSET_GROUP, data);
export const updateAssetGroupActionSuccess = (group: IAssetGroupData) =>
  action(UPDATE_ASSET_GROUP_SUCCESS, group);
export const updateAssetGroupActionFailure = () => action(UPDATE_ASSET_GROUP_FAILURE);

export const deleteAssetGroupAction = (id: string) => action(DELETE_ASSET_GROUP, id);
export const deleteAssetGroupActionSuccess = (id: string) => action(DELETE_ASSET_GROUP_SUCCESS, id);
export const deleteAssetGroupActionFailure = () => action(DELETE_ASSET_GROUP_FAILURE);
