import { createReducer, ActionType } from "typesafe-actions";
import { ICustomTagsState } from "./types";
import {
  getCustomTagsSuccessAction,
  deleteCustomTagFromReplyAction,
  deleteCustomTagAction,
} from "./actions";
import {
  GET_CUSTOM_TAGS,
  GET_CUSTOM_TAGS_SUCCESS,
  GET_CUSTOM_TAGS_FAILURE,
  POST_CUSTOM_TAG,
  POST_CUSTOM_TAG_SUCCESS,
  POST_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG,
  DELETE_CUSTOM_TAG_SUCCESS,
  DELETE_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG_FROM_COMMENT,
  DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS,
  DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE,
  DELETE_CUSTOM_TAG_FROM_REPLY,
} from "./actionTypes";

const initialState = {
  customTags: [],
  fetchingCustomTags: false,
  fetchedCustomTags: false,
  fetchedCustomTagsFail: false,
  postingCustomTag: false,
  postedCustomTag: false,
  postedCustomTagFail: false,
  deletingCustomTag: false,
  deletedCustomTag: false,
  deletedCustomTagFail: false,
  deletingCustomTagFromComment: false,
  deletedCustomTagFromComment: false,
  deletedCustomTagFromCommentFail: false,
  deletingCustomTagFromReplyId: null,
};

export const customTagsReducer = createReducer<ICustomTagsState>(initialState, {
  // GET_CUSTOM_TAGS
  [GET_CUSTOM_TAGS]: (state: ICustomTagsState) => ({
    ...state,
    fetchingCustomTags: true,
    fetchedCustomTags: false,
    fetchedCustomTagsFail: false,
    postedCustomTag: false,
    postedCustomTagResult: undefined,
  }),
  [GET_CUSTOM_TAGS_SUCCESS]: (
    state: ICustomTagsState,
    action: ActionType<typeof getCustomTagsSuccessAction>,
  ) => ({
    ...state,
    customTags: action.payload.items,
    fetchingCustomTags: false,
    fetchedCustomTags: true,
    fetchedCustomTagsFail: false,
  }),
  [GET_CUSTOM_TAGS_FAILURE]: (state: ICustomTagsState) => ({
    ...state,
    fetchingCustomTags: false,
    fetchedCustomTags: false,
    fetchedCustomTagsFail: true,
  }),

  // POST_CUSTOM_TAG
  [POST_CUSTOM_TAG]: (state: ICustomTagsState) => ({
    ...state,
    postingCustomTag: true,
    postedCustomTag: false,
    postedCustomTagFail: false,
  }),
  [POST_CUSTOM_TAG_SUCCESS]: (state: ICustomTagsState, action) => {
    return {
      ...state,
      postingCustomTag: false,
      postedCustomTag: true,
      postedCustomTagFail: false,
      postedCustomTagResult: action.payload.tag,
    };
  },
  [POST_CUSTOM_TAG_FAILURE]: (state: ICustomTagsState) => ({
    ...state,
    postingCustomTag: false,
    postedCustomTag: false,
    postedCustomTagFail: true,
  }),

  // DELETE_CUSTOM_TAG
  [DELETE_CUSTOM_TAG]: (
    state: ICustomTagsState,
    action: ActionType<typeof deleteCustomTagAction>,
  ) => ({
    ...state,
    customTags: state.customTags.filter(tag => tag.id !== action.payload),
    deletingCustomTag: true,
    deletedCustomTag: false,
    deletedCustomTagFail: false,
  }),
  [DELETE_CUSTOM_TAG_SUCCESS]: (state: ICustomTagsState) => ({
    ...state,
    deletingCustomTag: false,
    deletedCustomTag: true,
    deletedCustomTagFail: false,
  }),
  [DELETE_CUSTOM_TAG_FAILURE]: (state: ICustomTagsState) => ({
    ...state,
    deletingCustomTag: false,
    deletedCustomTag: false,
    deletedCustomTagFail: true,
  }),

  // DELETE_CUSTOM_TAG_FROM_COMMENT
  [DELETE_CUSTOM_TAG_FROM_COMMENT]: (state: ICustomTagsState) => ({
    ...state,
    deletingCustomTagFromComment: true,
    deletedCustomTagFromComment: false,
    deletedCustomTagFromCommentFail: false,
  }),
  [DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS]: (state: ICustomTagsState) => ({
    ...state,
    deletingCustomTagFromComment: false,
    deletedCustomTagFromComment: true,
    deletedCustomTagFromCommentFail: false,
  }),
  [DELETE_CUSTOM_TAG_FROM_COMMENT_FAILURE]: (state: ICustomTagsState) => ({
    ...state,
    deletingCustomTagFromComment: false,
    deletedCustomTagFromComment: false,
    deletedCustomTagFromCommentFail: true,
  }),

  // DELETE_CUSTOM_TAG_FROM_REPLY
  [DELETE_CUSTOM_TAG_FROM_REPLY]: (
    state: ICustomTagsState,
    action: ActionType<typeof deleteCustomTagFromReplyAction>,
  ) => ({
    ...state,
    deletingCustomTagFromReplyId: action.payload,
  }),
});
