import React, { FC } from "react";
import classNames from "classnames/bind";
import { useTranslation } from "react-i18next";

import { Button, Tooltip, InputWhite } from "@bbdevcrew/bb_ui_kit_fe";

import { ITagsDropdownHeaderProps } from "./TagsDropdown.type";

import s from "./TagsDropdown.module.less";

import { PlusIcon } from "@assets/index";

const TagsDropdownHeader: FC<ITagsDropdownHeaderProps> = ({
  id,
  title,
  inputValue,
  isDropdownOpen,
  existingTagEntered,
  isTagBeingCreated,
  onAddNewTag,
  onInputChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.bbTagsDropdownHeaderTitle}>{title}</div>

      <InputWhite
        autoFocus
        _size="sm"
        value={inputValue}
        id={`${id}-input`}
        onChange={onInputChange}
        data-testid={`${id}-tags-input`}
        placeholder={t(`components:${id}:placeholder`)}
        wrapperClassName={classNames(s.bbTagsDropdownHeaderInput, {
          [s.bbTagsDropdownHeaderInputOpen]: isDropdownOpen,
        })}
      />

      <Tooltip title={existingTagEntered ? t(`components:${id}:tagAlreadyExists`) : null}>
        <div>
          <Button
            _size="sm"
            _type="primary"
            onClick={onAddNewTag}
            className={s.bbAddNewTagBtn}
            disabled={!inputValue.trim() || existingTagEntered || isTagBeingCreated}
          >
            <span className={s.bbAddNewTagBtnIcon}>
              <PlusIcon />
            </span>
            {t(`components:${id}:dropdownAddBtn`)}
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

export default TagsDropdownHeader;
