import React from "react";
import { Document, Page } from "react-pdf";

import ErrorPosting from "./ErrorPosting";
import ReactResizeDetector from "react-resize-detector";

import s from "./PublishingPost.module.less";

import { defaultPDFDocOptions } from "./helpers";
import { IPublishingPost } from "@store/publishings/types";
import { getPostMediaTypeIcon } from "../../../_common/PostPreviewTooltip/helpers";

interface IPublishingPostPDFPreviewProps {
  post: IPublishingPost;
}

export default function PublishingPostPDFPreview({ post }: IPublishingPostPDFPreviewProps) {
  return (
    <ReactResizeDetector
      handleWidth
      refreshMode="throttle"
      refreshOptions={{ leading: true, trailing: true }}
    >
      {(props: { width: number }) => (
        <>
          <div className={s.bbPostVideoWrapper}>
            <Document
              file={post.attachments[0].url}
              renderMode="canvas"
              options={defaultPDFDocOptions}
            >
              <Page
                key={1}
                pageNumber={1}
                width={props.width}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>
            <div className={s.bbGradientOverlay}></div>
            <ErrorPosting post={post} />
          </div>
          {!!post.message && <div className={s.bbPostCaptionCropped}>{post.message}</div>}
          <div className={s.bbPostMediaType}>{getPostMediaTypeIcon(post)}</div>
        </>
      )}
    </ReactResizeDetector>
  );
}
