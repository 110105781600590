import React, { useEffect, useState } from "react";

import { SimpleDropdown } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ImageTooltip.module.less";

import { IImageTooltipProps } from "./ImageTooltip.types";

import { InformationIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const ImageTooltip: React.FC<IImageTooltipProps> = ({ src, ...props }) => {
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setLoaded(true);
  }, [src]);

  return loaded ? (
    <SimpleDropdown
      triggerMode="hover"
      open={open}
      setOpen={setOpen}
      placement="top-start"
      trigger={
        <i className={s.bbImageTooltipTrigger}>
          <InformationIcon />
        </i>
      }
      className={s.bbImageTooltip}
      triggerWrapperClassName={s.bbImageTooltipTriggerWrapper}
    >
      <img className={s.bbImageTooltipImage} src={src} {...props} />
    </SimpleDropdown>
  ) : null;
};
