import React, { useEffect, useState } from "react";
import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { TikTokPricingTableItem } from "./TikTokPricingTableItem";

import { tiktokPricingTable } from "@store/tiktokApp/actions";
import { tiktokPricingTableSelector } from "@store/tiktokApp/selectors";

import s from "./TikTokSubscriptionPricingTable.module.less";

import { PricingTableType } from "./TikTokSubscriptionPricingTable.types";

const cx = cn.bind(s);

export const TikTokSubscriptionPricingTable = ({ isVisible }: { isVisible: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState<PricingTableType>("monthly");

  const isMonthly = activeTab === "monthly";

  const pricingItems = useSelector(tiktokPricingTableSelector);

  useEffect(() => {
    if (isVisible) dispatch(tiktokPricingTable());
  }, [dispatch, isVisible]);

  const onActiveTab = (active: PricingTableType) => () => setActiveTab(active);
  return (
    <div className={s.bbTTPricingTable}>
      <div className={s.bbTTPricingTableHeader}>
        <div className={s.bbTTPricingTableHeaderTabs}>
          <div
            onClick={onActiveTab("monthly")}
            className={cx(s.bbTTPricingTableHeaderTab, s.bbTTPricingTableHeaderTabLeft, {
              [s.bbTTPricingTableHeaderTabActive]: isMonthly,
            })}
          >
            {t("components:subscriptionModal:tt:monthly")}
          </div>
          <div
            onClick={onActiveTab("yearly")}
            className={cx(s.bbTTPricingTableHeaderTab, s.bbTTPricingTableHeaderTabRight, {
              [s.bbTTPricingTableHeaderTabActive]: !isMonthly,
            })}
          >
            {t("components:subscriptionModal:tt:yearly")}
          </div>
        </div>
      </div>
      <div className={s.bbTTPricingTableMain}>
        {pricingItems
          .filter(item => item.plan_duration === activeTab)
          .map((item, index) => (
            <TikTokPricingTableItem
              key={index}
              title={item.plan_name}
              popular={item.popular}
              buttonText={item.button_text}
              description={item.description}
              price={item.price}
              type={item.plan_duration}
              url={item.url}
              includes={item.includes}
            />
          ))}
      </div>
    </div>
  );
};
