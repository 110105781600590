import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./GeneratePromptButton.module.less";

import { MagicWandIcon } from "@assets/index";

interface IGeneratePromptButtonProps {
  loading: boolean;
  disabled?: boolean;
  onClick: () => void;
  tooltipText?: string;
}

export const GeneratePromptButton: FC<IGeneratePromptButtonProps> = ({
  loading,
  disabled,
  tooltipText,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={tooltipText}>
      <div>
        <Button
          _size="sm"
          onClick={onClick}
          loading={loading}
          disabled={disabled}
          loadingIndicatorPosition="left"
          className={s.bbGeneratePromptButton}
        >
          <MagicWandIcon />
          {t("generic:generate")}
        </Button>
      </div>
    </Tooltip>
  );
};
