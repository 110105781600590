import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter } from "rxjs/operators";

import { APILoggerActionsType } from "./types";
import { apiLog } from "@utils/paths";

import { LOG_IN_API } from "./actionTypes";
import { logItinAPISuccess } from "./actions";
import { getHeaders } from "@utils/headers";

export const apiLoggerEpic = (action$: Observable<APILoggerActionsType>) =>
  action$.pipe(
    filter(isOfType(LOG_IN_API)),
    switchMap(a => {
      return ajax
        .post(
          apiLog,
          {
            severity: "info",
            data: a.payload,
          },
          getHeaders({}),
        )
        .pipe(
          map(e => e.response),
          map(() => logItinAPISuccess()),
        );
    }),
  );
