// eslint-disable-next-line
export const downloadAsCSV = (data: any[], headers: string[], fileName = "download.csv") => {
  const a = document.createElement("a");
  const mimeType = "text/csv";

  data.unshift(headers);
  let dataString = "";

  // eslint-disable-next-line
  data.forEach(function (rowData: any, index: number) {
    dataString += index < data.length ? rowData.join(",") + "\n" : rowData.join(",");
  });

  /**
   * TODO: Need to find an alternative for this as it's being deprecated.
   * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/msSaveBlob
   */
  // eslint-disable-next-line
  // @ts-ignore
  if (navigator.msSaveBlob) {
    // IE10 :p
    // eslint-disable-next-line
    // @ts-ignore
    navigator.msSaveBlob(
      new Blob([dataString], {
        type: mimeType,
      }),
      fileName,
    );
  } else if (URL && "download" in a) {
    // html5
    a.href = URL.createObjectURL(
      new Blob([dataString], {
        type: mimeType,
      }),
    );
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.location.href = "data:application/octet-stream," + encodeURIComponent(dataString);
  }
};
