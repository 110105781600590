import { action } from "typesafe-actions";
import {
  GET_SHORTENED_SEARCHES,
  GET_SHORTENED_SEARCHES_SUCCESS,
  GET_SHORTENED_SEARCHES_FAILURE,
  POST_SHORTENED_SEARCHES,
  POST_SHORTENED_SEARCHES_SUCCESS,
  POST_SHORTENED_SEARCHES_FAILURE,
  RESET_SHORTENED_SEARCHES,
} from "./actionTypes";

import {
  IShortenedSearchesRequest,
  IGetShortenedSearchesResponse,
  IPostShortenedSearchesResponse,
} from "./types";
import { IAPIError } from "@utils/getAPIErrorMessages";

// GET
export const getShortenedSearchesAction = (id: string) => action(GET_SHORTENED_SEARCHES, id);
export const getShortenedSearchesSuccessAction = (data: IGetShortenedSearchesResponse) =>
  action(GET_SHORTENED_SEARCHES_SUCCESS, data);
export const getShortenedSearchesFailureAction = (error: IAPIError) =>
  action(GET_SHORTENED_SEARCHES_FAILURE, error);

// POST
export const postShortenedSearchesAction = (body: IShortenedSearchesRequest) =>
  action(POST_SHORTENED_SEARCHES, body);
export const postShortenedSearchesSuccessAction = (data: IPostShortenedSearchesResponse) =>
  action(POST_SHORTENED_SEARCHES_SUCCESS, data);
export const postShortenedSearchesFailureAction = (error: IAPIError) =>
  action(POST_SHORTENED_SEARCHES_FAILURE, error);

export const resetShortenedSearchesAction = () => action(RESET_SHORTENED_SEARCHES);
