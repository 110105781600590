import { ActionType, createReducer } from "typesafe-actions";
import * as actions from "./actions";
import { IReportsState } from "./types";

const initialState = {
  pdfReports: {},
  scheduledReports: [],

  fetchingScheduledReports: false,
  fetchedScheduledReports: false,
  fetchedScheduledReportsFail: false,

  fetchingPostScheduledReports: false,
  fetchedPostScheduledReports: false,
  fetchedPostScheduledReportsFail: false,

  updatingScheduledReport: false,
  updatedScheduledReport: false,
  updatedScheduledReportFail: false,

  deletingScheduledReport: false,
  deletedScheduledReport: false,
  deletedScheduledReportFail: false,
};

export const reportsReducer = createReducer<IReportsState, ActionType<typeof actions>>(initialState)
  .handleAction(actions.generatePDFDocumentAction, (state, action) => ({
    ...state,
    pdfReports: {
      ...state.pdfReports,
      [`${action.payload.search_id}`]: {
        inProgress: true,
      },
    },
  }))
  .handleAction(actions.generatePDFDocumentSuccessAction, (state, action) => ({
    ...state,
    pdfReports: {
      ...state.pdfReports,
      [action.payload.search_id]: {
        inProgress: false,
        url: action.payload.url,
        filename: action.payload.filename,
        report: action.payload.report,
      },
    },
  }))
  .handleAction(actions.generatePDFDocumentFailureAction, (state, action) => ({
    ...state,
    pdfReports: {
      ...state.pdfReports,
      [action.payload]: {
        inProgress: false,
      },
    },
  }))
  .handleAction(actions.postScheduledReportsAction, state => ({
    ...state,
    fetchedPostScheduledReports: false,
    fetchingPostScheduledReports: true,
    fetchedPostScheduledReportsFail: false,
  }))
  .handleAction(actions.postScheduledReportsSuccess, state => ({
    ...state,
    fetchedPostScheduledReports: true,
    fetchingPostScheduledReports: false,
    fetchedPostScheduledReportsFail: false,
  }))
  .handleAction(actions.postScheduledReportsFailure, state => ({
    ...state,
    fetchedPostScheduledReports: false,
    fetchingPostScheduledReports: false,
    fetchedPostScheduledReportsFail: true,
  }))
  .handleAction(actions.updateScheduledReportsAction, state => ({
    ...state,
    updatingScheduledReport: true,
    updatedScheduledReport: false,
    updatedScheduledReportFail: false,
  }))
  .handleAction(actions.updateScheduledReportsSuccess, state => ({
    ...state,
    updatingScheduledReport: false,
    updatedScheduledReport: true,
    updatedScheduledReportFail: false,
  }))
  .handleAction(actions.updateScheduledReportsFailure, state => ({
    ...state,
    updatingScheduledReport: false,
    updatedScheduledReport: false,
    updatedScheduledReportFail: true,
  }))
  .handleAction(actions.getScheduledDocumentsAction, state => ({
    ...state,
    fetchingScheduledReports: true,
    fetchedScheduledReports: false,
    fetchedScheduledReportsFail: false,
  }))
  .handleAction(actions.getScheduledDocumentsSuccessAction, (state, action) => ({
    ...state,
    scheduledReports: action.payload.items,
    fetchingScheduledReports: false,
    fetchedScheduledReports: true,
    fetchedScheduledReportsFail: false,
  }))
  .handleAction(actions.getScheduledDocumentsFailureAction, state => ({
    ...state,
    fetchingScheduledReports: false,
    fetchedScheduledReports: false,
    fetchedScheduledReportsFail: true,
  }))
  .handleAction(actions.deleteScheduledReportAction, state => ({
    ...state,
    deletingScheduledReport: true,
    deletedScheduledReport: false,
    deletedScheduledReportFail: false,
  }))
  .handleAction(actions.deleteScheduledReportSuccessAction, (state, action) => ({
    ...state,
    scheduledReports: state.scheduledReports.filter(report => report.id !== action.payload),
    deletingScheduledReport: false,
    deletedScheduledReport: true,
    deletedScheduledReportFail: false,
  }))
  .handleAction(actions.deleteScheduledReportFailureAction, state => ({
    ...state,
    deletingScheduledReport: false,
    deletedScheduledReport: false,
    deletedScheduledReportFail: true,
  }));
