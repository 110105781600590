import { action } from "typesafe-actions";
import {
  GET_ONBOARDING_PLATFORM_TYPES,
  GET_ONBOARDING_PLATFORM_TYPES_SUCCESS,
  GET_ONBOARDING_PLATFORM_TYPES_FAILURE,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS_SUCCESS,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS_FAILURE,
  SELECT_ASSETS,
  SET_PLATFORM_TYPE_ALERT,
  DESELECT_ASSETS,
} from "./actionTypes";
import { PlatformType } from "../platform/types";
import { IAlertProps } from "@bbdevcrew/bb_ui_kit_fe";
import {
  IPlatformAsset,
  IOnboardingPlatformItem,
} from "@components/_common/AssetTabs/AssetTabs.type";

export const getOnboardingPlatformTypesAction = (platformType: PlatformType) =>
  action(GET_ONBOARDING_PLATFORM_TYPES, platformType);
export const getOnboardingPlatformTypesSuccessAction = (assets: IOnboardingPlatformItem[]) =>
  action(GET_ONBOARDING_PLATFORM_TYPES_SUCCESS, assets);
export const getOnboardingPlatformTypesFailureAction = () =>
  action(GET_ONBOARDING_PLATFORM_TYPES_FAILURE);

export const postOnboardingPlatformTypeAssetsAction = (
  assets: IPlatformAsset[],
  webhook_active?: boolean,
) => action(POST_ONBOARDING_PLATFORM_TYPE_ASSETS, { assets, webhook_active });
export const postOnboardingPlatformTypeAssetsSuccessAction = () =>
  action(POST_ONBOARDING_PLATFORM_TYPE_ASSETS_SUCCESS);
export const postOnboardingPlatformTypeAssetsFailureAction = () =>
  action(POST_ONBOARDING_PLATFORM_TYPE_ASSETS_FAILURE);

export const selectAssets = (assets: IPlatformAsset[]) => action(SELECT_ASSETS, assets);
export const deselectAssets = (assets: IPlatformAsset[]) => action(DESELECT_ASSETS, assets);

export const setPlatformTypeAlert = (platformTypeId: string, alert?: IAlertProps) =>
  action(SET_PLATFORM_TYPE_ALERT, { alert, platformTypeId });
