import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SavedReplyList.module.less";

import { ISavedReply, IVariation } from "@store/replies/types";

import { ChevronLeftIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ISavedReplyVariationSelectorProps {
  replyId: string;
  variations: IVariation[];
  replies: ISavedReply[];
  setReplies: (replies: ISavedReply[]) => void;
}

export default function SavedReplyVariationSelector({
  replyId,
  variations,
  replies,
  setReplies,
}: ISavedReplyVariationSelectorProps) {
  const { t } = useTranslation();

  const [selectedVariation, setSelectedVariation] = useState<IVariation>(variations[0]);

  const selectedVariationIndex = useMemo(
    () => variations.findIndex(v => v.id === selectedVariation?.id) || 0,
    [selectedVariation, variations],
  );

  const goToNextVariation = () => {
    if (selectedVariationIndex === variations.length - 1) return;

    const newSelectedVariation = variations.find(
      (_, index) => index === selectedVariationIndex + 1,
    );

    if (newSelectedVariation) {
      setSelectedVariation(newSelectedVariation);
      const updatedReplies = replies.map(reply => {
        if (reply.id !== replyId) return reply;

        return {
          ...reply,
          message: newSelectedVariation?.message,
        };
      });
      setReplies(updatedReplies);
    }
  };

  const goToPreviousVariation = () => {
    if (selectedVariationIndex === 0) return;

    const newSelectedVariation = variations.find(
      (_, index) => index === selectedVariationIndex - 1,
    );

    if (newSelectedVariation) {
      setSelectedVariation(newSelectedVariation);
      const updatedReplies = replies.map(reply => {
        if (reply.id !== replyId) return reply;

        return {
          ...reply,
          message: newSelectedVariation?.message,
        };
      });
      setReplies(updatedReplies);
    }
  };

  return (
    <div className={s.bbSavedReplyVariationSelectorWrapper}>
      <Button
        _type="link"
        onClick={goToPreviousVariation}
        disabled={selectedVariationIndex === 0}
        className={s.bbSavedReplyVariationSelectorArrow}
      >
        <ChevronLeftIcon />
      </Button>
      <span>
        {t("components:reply:bbSavedReplies:create:variationTitle") + (selectedVariationIndex + 1)}/
        {variations.length}
      </span>
      <Button
        _type="link"
        onClick={goToNextVariation}
        disabled={selectedVariationIndex === variations.length - 1}
        className={s.bbSavedReplyVariationSelectorArrow}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
}
