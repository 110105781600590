import React, { FC, ChangeEvent } from "react";
import { i18nextInstance } from "../../../App";

import { Col, Grid, Row } from "antd";
import { Auth, JS } from "aws-amplify";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import RequireNewPassword from "./RequireNewPassword";
import { RequireNewPassword as AWSRequireNewPassword } from "aws-amplify-react";

import s from "./RequireNewPasswordView.module.less";
import globalStyles from "../index.module.less";

import { IFormValues } from "./RequireNewPassword.type";
import { IAuthPieceProps } from "aws-amplify-react/lib-esm/Auth/AuthPiece";

import { SignInCover as CoverImg } from "../SignInCover/SignInCover";

import TrustedBy from "@assets/TrustedBy.svg";
import Logo from "@assets/LogoNoBeta.svg";

const { useBreakpoint } = Grid;

interface ICreateNewPasswordViewProps {
  valid: (values: IFormValues, callback: (values?: IFormValues) => void) => void;
  handleInputChange: (e: ChangeEvent) => void;
}

const CreateNewPasswordView: FC<ICreateNewPasswordViewProps> = ({ valid, handleInputChange }) => {
  const screens = useBreakpoint();

  return (
    <Row className={s.bbCreateNewPasswordView}>
      <div className={globalStyles.bbInfoSectionLogo}>
        <Logo />
      </div>
      <Col xs={24} xl={16} className={s.bbCreateNewPasswordContent}>
        <RequireNewPassword onFinish={valid} handleInputChange={handleInputChange} />
      </Col>
      {screens.xl ? (
        <Col xl={8} xs={12} className={globalStyles.bbInfoSection}>
          <div className={globalStyles.bbInfoSectionTrustedBy}>
            <TrustedBy />
          </div>
          <div>
            <CoverImg />
          </div>
        </Col>
      ) : null}
    </Row>
  );
};

class RequireNewPasswordView extends AWSRequireNewPassword {
  constructor(props: IAuthPieceProps) {
    super(props);
    this._validAuthStates = ["requireNewPassword"];
  }

  error(err: { message: string }) {
    addToast({
      type: "danger_accent",
      title: i18nextInstance.t("generic:error"),
      message: err.message,
    });
  }

  onFinish = (values: IFormValues, callback: (values?: IFormValues) => void) =>
    this.valid(values, callback);

  // eslint-disable-next-line
  checkContact = (user: any) => {
    Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        this.changeState("signedIn", user);
      } else {
        user = Object.assign(user, data);
        this.changeState("verifyContact", user);
      }
    });
  };

  valid = (values: IFormValues, callback: (values?: IFormValues) => void) => {
    const user = this.props.authData;
    let requiredAttributes = {};

    if (user.challengeParam && user.challengeParam.requiredAttributes) {
      requiredAttributes = user.challengeParam.requiredAttributes;
    }

    Auth.completeNewPassword(user, values.requireNewPassword, requiredAttributes)
      .then(async _user => {
        await Auth.updateUserAttributes(_user, {
          "custom:terms_accepted": "true",
        });

        if (_user.challengeName === "SMS_MFA" || _user.challengeName === "SOFTWARE_TOKEN_MFA") {
          this.changeState("confirmSignIn", _user);
        } else {
          this.checkContact(_user);
        }
      })
      .catch(err => {
        callback?.();
        this.error(err);
      });
  };

  showComponent() {
    return <CreateNewPasswordView valid={this.valid} handleInputChange={this.handleInputChange} />;
  }
}

export default RequireNewPasswordView;
