import palette from "@bbdevcrew/bb_ui_kit_fe/dist/theme/scheme.module.less";

import { IExplorerOverviewSentiments, IOverviewPerformanceItem } from "@store/dashboard/types";
import { IAreaChartData, IColorData } from "./SentimentDetails.type";
import { i18nextInstance } from "../../../App";
import { extractDate } from "@utils/dates";
import { IDataSearchProps, ITimelineDataSet } from "@store/search/types";
import { getBreakdownChartBarColor } from "../../_common/BreakdownChart/helpers";
import { ILine } from "@bbdevcrew/bb_ui_kit_fe";

export const CHART_WIDTH = 280;
export const CHART_HEIGHT = 280;

export const DEFAULT_COMMENT_EXPORT_LIMIT = 5000;

export const sentimentColorData = {
  positive: palette.colorPositive,
  negative: palette.colorNegative,
  neutral: palette.colorNeutral,
  user_tags: palette.colorUserTags,
  not_applicable: palette.colorNotApplicable,
  total: palette.colorBlue900,
} as IColorData;

export const stripedBackground = palette.colorStriped;

export const getTimelineTooltipLabel = (timelineDataSet: ITimelineDataSet) => {
  const date = extractDate(timelineDataSet.date);
  const end_date = extractDate(timelineDataSet.end_date);
  const hour = timelineDataSet.hour ? ` - ${timelineDataSet.hour}` : "";
  const end_hour = timelineDataSet.end_hour ? ` - ${timelineDataSet.end_hour}` : "";

  return `${date}${hour} / ${end_date}${end_hour}`;
};

export const normalizeTimelineData = (searchData: IDataSearchProps) =>
  searchData.date_histogram?.datasets.map((timelineDataSet: ITimelineDataSet) => {
    return {
      ...timelineDataSet,
      date: `${timelineDataSet.date || ""} ${timelineDataSet.hour || ""}`,
      tooltipLabel: getTimelineTooltipLabel(timelineDataSet),
    };
  });

export const normalizeSentimentData = (searchData: IDataSearchProps) => {
  return Object.entries(searchData.comment_stats).map((commentStat: [string, number]) => {
    const commentSentiment = commentStat[0];
    const commentAmount = commentStat[1];

    return {
      value: commentAmount,
      rawName: commentSentiment,
      color: sentimentColorData[commentSentiment as keyof IColorData],
      name: i18nextInstance.t(`components:searchPanel:charts:${commentSentiment}`),
      tooltipString: i18nextInstance.t(`components:searchPanel:cardTooltips:${commentSentiment}`),
    };
  });
};

export const normalizePlatformData = (
  platformData: IExplorerOverviewSentiments[] | IOverviewPerformanceItem[],
) => {
  return platformData.map((item, index) => {
    return {
      value: item.count,
      rawName: item.id,
      name: item.name,
      color: getBreakdownChartBarColor(item.id, index),
      change_percentage: item.change_percentage,
      percentage_of_total: item.percentage_of_total,
    };
  });
};

export const getLines = () =>
  [
    {
      dataKey: "negative",
      stroke: sentimentColorData["negative"],
      name: i18nextInstance.t("components:searchPanel:charts:negative"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:negative"),
    },
    {
      dataKey: "positive",
      stroke: sentimentColorData["positive"],
      name: i18nextInstance.t("components:searchPanel:charts:positive"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:positive"),
    },
    {
      dataKey: "user_tags",
      stroke: sentimentColorData["user_tags"],
      name: i18nextInstance.t("components:searchPanel:charts:user_tags"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:user_tags"),
    },
    {
      dataKey: "neutral",
      stroke: sentimentColorData["neutral"],
      name: i18nextInstance.t("components:searchPanel:charts:neutral"),
      tooltipString: i18nextInstance.t("components:searchPanel:areaChartTooltips:neutral"),
    },
    {
      dataKey: "not_applicable",
      stroke: sentimentColorData["not_applicable"],
      customLegendBackground: stripedBackground,
      strokeDasharray: "5 5",
      name: i18nextInstance.t("components:searchPanel:charts:not_applicable"),
    },
  ] as ILine[];

export const getCSVData = (data: IAreaChartData[]) => {
  return data.map(item => {
    return {
      name: `${item.date.replace(",", " ").trim()}`,
      total: item.total.toString(),
      negative: item.negative.toString(),
      positive: item.positive.toString(),
      user_tags: item.user_tags.toString(),
      neutral: item.neutral.toString(),
    };
  });
};

export const getCSVTableHeaders = () => {
  return [
    i18nextInstance.t("components:searchPanel:columnTitles:date"),
    i18nextInstance.t("components:searchPanel:columnTitles:totalComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:negativeComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:positiveComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:userTags"),
    i18nextInstance.t("components:searchPanel:columnTitles:neutralComments"),
  ];
};
