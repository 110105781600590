import { TagIcon, UserAssignedIcon, AlertTriangleFilledIcon } from "@assets/index";
import { useDefaultInboxOptions, IInboxOptions } from "@containers/InboxMenu/helpers";

export function useInboxOptions() {
  const { options } = useDefaultInboxOptions();
  const reviewIndex = options.findIndex(({ statKey }) => statKey === "review");

  if (reviewIndex > -1) {
    options.splice(
      reviewIndex + 1,
      0,
      {
        statKey: "assigned_to_me",
        icon: UserAssignedIcon,
        nested: true,
        showBadge: true,
      },
      {
        statKey: "care_highlights",
        icon: TagIcon,
        nested: true,
      },
    );
  }

  return { options };
}

export function useErrorInboxOptions() {
  const options: IInboxOptions[] = [
    {
      statKey: "errors",
      icon: AlertTriangleFilledIcon,
    },
  ];
  return { options };
}

export const getInboxParentTabValue = (options: IInboxOptions[], currentTabValue: string) => {
  const targetIndex = options.findIndex(o => o.statKey === currentTabValue);

  if (options[targetIndex]?.nested) {
    const previousNestedItem = options
      .slice(0, targetIndex)
      .reverse()
      .find(item => !item.nested);

    return previousNestedItem && !previousNestedItem.nested
      ? previousNestedItem.statKey
      : undefined;
  }

  return undefined;
};
