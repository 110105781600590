import { RootState } from "../";

export const fromOverview = (state: RootState) => state.overview;

export const getTrendingPostsSelector = (state: RootState) => fromOverview(state).trendingPosts;
export const getTrendingPostsSelectorPending = (state: RootState) =>
  fromOverview(state).fetchingTrendingPosts;
export const getTrendingPostsSelectorSuccessful = (state: RootState) =>
  fromOverview(state).fetchedTrendingPosts;

export const getAdTargetingsSelector = (state: RootState) => fromOverview(state).adTargetings;
export const getAdTargetingsSelectorPending = (state: RootState) =>
  fromOverview(state).fetchingAdTargetings;
export const getAdTargetingsSelectorSuccessful = (state: RootState) =>
  fromOverview(state).fetchedAdTargetings;
