import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { getInboxStatsFailure, getInboxStatsSuccess } from "./actions";

import { RootState } from "../";
import { GET_INBOX_STATS } from "./actionTypes";
import { repliesStats } from "@utils/paths";
import { InboxStatsActions, IRepliesStatsResponse } from "./types";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { getSubFeatureName } from "@utils/feature";

export const getInboxStatsEpic = (
  action$: Observable<InboxStatsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_INBOX_STATS)),
    switchMap(a => {
      return ajax
        .post<IRepliesStatsResponse>(
          repliesStats(getSubFeatureName()),
          {
            filters: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getInboxStatsSuccess(data)),
          catchError(e => handleError(e, getInboxStatsFailure)),
        );
    }),
  );
