import { createAction } from "typesafe-actions";

import {
  GENERATE_PDF_DOCUMENT,
  GENERATE_PDF_DOCUMENT_SUCCESS,
  GENERATE_PDF_DOCUMENT_FAILURE,
  GET_SCHEDULED_DOCUMENTS,
  GET_SCHEDULED_DOCUMENTS_SUCCESS,
  GET_SCHEDULED_DOCUMENTS_FAILURE,
  POST_SCHEDULED_REPORT,
  POST_SCHEDULED_REPORT_SUCCESS,
  POST_SCHEDULED_REPORT_FAILURE,
  UPDATE_SCHEDULED_REPORT,
  UPDATE_SCHEDULED_REPORT_SUCCESS,
  UPDATE_SCHEDULED_REPORT_FAILURE,
  DELETE_SCHEDULED_REPORT,
  DELETE_SCHEDULED_REPORT_SUCCESS,
  DELETE_SCHEDULED_REPORT_FAILURE,
  GENERATE_EMAIL_REPORT_DOCUMENT,
} from "./actionTypes";

import {
  IGetScheduledDocumentsResponse,
  IGeneratePDFDocumentPayload,
  IPDFReportState,
  IScheduledReport,
  IGenerateEmailReportDocumentPayload,
} from "./types";

export const generatePDFDocumentAction =
  createAction(GENERATE_PDF_DOCUMENT)<IGeneratePDFDocumentPayload>();
export const generatePDFDocumentSuccessAction = createAction(GENERATE_PDF_DOCUMENT_SUCCESS)<
  IPDFReportState & { search_id: string }
>();
export const generatePDFDocumentFailureAction = createAction(
  GENERATE_PDF_DOCUMENT_FAILURE,
)<string>();

export const generateEmailReportDocumentAction = createAction(
  GENERATE_EMAIL_REPORT_DOCUMENT,
)<IGenerateEmailReportDocumentPayload>();

export const getScheduledDocumentsAction = createAction(GET_SCHEDULED_DOCUMENTS)();
export const getScheduledDocumentsSuccessAction = createAction(
  GET_SCHEDULED_DOCUMENTS_SUCCESS,
)<IGetScheduledDocumentsResponse>();
export const getScheduledDocumentsFailureAction = createAction(
  GET_SCHEDULED_DOCUMENTS_FAILURE,
)<string>();

export const postScheduledReportsAction = createAction(POST_SCHEDULED_REPORT)<IScheduledReport>();
export const postScheduledReportsSuccess = createAction(
  POST_SCHEDULED_REPORT_SUCCESS,
)<IScheduledReport>();
export const postScheduledReportsFailure = createAction(POST_SCHEDULED_REPORT_FAILURE)();

export const updateScheduledReportsAction =
  createAction(UPDATE_SCHEDULED_REPORT)<IScheduledReport>();
export const updateScheduledReportsSuccess = createAction(
  UPDATE_SCHEDULED_REPORT_SUCCESS,
)<IScheduledReport>();
export const updateScheduledReportsFailure = createAction(UPDATE_SCHEDULED_REPORT_FAILURE)();

export const deleteScheduledReportAction = createAction(DELETE_SCHEDULED_REPORT)<string>();
export const deleteScheduledReportSuccessAction = createAction(
  DELETE_SCHEDULED_REPORT_SUCCESS,
)<string>();
export const deleteScheduledReportFailureAction = createAction(DELETE_SCHEDULED_REPORT_FAILURE)();
