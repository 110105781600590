import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";
import ReplyingCommentPreview from "../replyingCommentPreview/ReplyingCommentPreview";

import s from "./index.module.less";

export interface IPublicToPrivateReplyDisclaimerProps {
  commentId: string;
  text: string;
}

export const PublicToPrivateReplyDisclaimer = ({
  text,
  commentId,
}: IPublicToPrivateReplyDisclaimerProps) => {
  const { t } = useTranslation();

  return (
    <span className={s.bbReplyDisclaimer}>
      {text}
      <Tooltip
        destroyTooltipOnHide
        title={<ReplyingCommentPreview commentId={commentId} />}
        overlayInnerStyle={{
          minWidth: 630,
          maxWidth: 630,
          backgroundColor: "white",
        }}
      >
        <div className={s.bbReplyDisclaimerAction}>
          {t("components:reply:replyPrivately:disclaimerRespondAction")}
        </div>
      </Tooltip>
    </span>
  );
};
