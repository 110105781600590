import Form from "antd/lib/form";
import { useTranslation } from "react-i18next";
import React from "react";

import s from "./Keywords.module.less";

import { Toggle, Tooltip, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

export const KeywordsTranslation = ({ name }: { name: string | string[] }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbKeywordsTranslationSwitch}>
      <div className={s.bbKeywordsTranslationSwitchLabel}>
        <div>{t("components:filters:keyword_query_in_translation:label")}</div>
        <Tooltip
          title={t("components:filters:keyword_query_in_translation:tooltip")}
          zIndex={99999}
        >
          <span className={s.bbKeywordsTranslationSwitchHelp}>
            <HelpIcon />
          </span>
        </Tooltip>
      </div>

      <Form.Item labelAlign="left" valuePropName="checked" name={`${name}_in_translation`}>
        <Toggle id={`${name}_in_translation`} />
      </Form.Item>
    </div>
  );
};
