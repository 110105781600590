import React from "react";
import classNames from "classnames";

import { Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CheckboxList.module.less";

import { ICheckboxListProps } from "./CheckboxList.types";

export const CheckboxList: React.FC<ICheckboxListProps> = ({
  options = {},
  onChange,
  className,
}) => {
  return (
    <div className={classNames(s.bbCheckboxList, className)}>
      {Object.entries(options).map(([key, { checked, label, id }]) => (
        <label key={key} className={s.bbCheckboxListItem}>
          <Checkbox
            checked={checked}
            id={id}
            onChange={() => onChange?.({ ...options, [key]: { checked: !checked, label, id } })}
          />
          {label}
        </label>
      ))}
    </div>
  );
};
