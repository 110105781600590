import React from "react";

import StoryNotAvailable from "./StoryNotAvailable";
import VideoAttachment from "./VideoAttachment";

import s from "../Attachment.module.less";

import { IDMVideoAttachmentProps } from "./Variants.types";
import { hoursFromNow } from "@utils/dates";

const DMVideoAttachment: React.FC<IDMVideoAttachmentProps> = ({
  isOwned,
  attachment,
  createdTime,
  onAttachmentClick,
  isPreviewMessage = false,
  ...restProps
}) => {
  const storyNoLongerAvailable = createdTime && hoursFromNow(createdTime) > 24;

  if (storyNoLongerAvailable) {
    return <StoryNotAvailable isPreviewMessage={isPreviewMessage} isOwned={isOwned} />;
  }

  return (
    <VideoAttachment
      attachment={attachment}
      onAttachmentClick={onAttachmentClick}
      className={s.bbDMVideoAttachment}
      {...restProps}
    />
  );
};

export default DMVideoAttachment;
