import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import ToReviewEmptyState from "./ToReviewEmptyState";
import AssignedToMeEmptyState from "./AssignedToMeEmptyState";

import { IInboxStats } from "@store/inboxStats/types";
import { AssignToMeClickHandlerType } from "../inboxCommentsHeader/types";

interface IEmptyStateProps {
  tab: keyof IInboxStats;
  stats: {
    review?: number;
    assigned_to_me?: number;
  };
  onAssignClick: AssignToMeClickHandlerType;
}

const EmptyState: FC<IEmptyStateProps> = ({ tab, stats, onAssignClick }) => {
  const { t } = useTranslation();

  if (tab === "review" && stats["review"] === 0) return <ToReviewEmptyState />;

  if (tab === "assigned_to_me" && stats["assigned_to_me"] === 0) {
    if (stats["review"] === 0) return <AssignedToMeEmptyState />;
    else return <AssignedToMeEmptyState showAssignBtn onAssignClick={onAssignClick} />;
  }

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<span>{t("components:comments:tableEmpty")}</span>}
    />
  );
};

export default EmptyState;
