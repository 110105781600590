/* eslint-disable @typescript-eslint/no-empty-function */
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Checkbox, CheckboxIndeterminate } from "@bbdevcrew/bb_ui_kit_fe";

import { useBatchMode } from "./BatchMode";
import { commentsSelector } from "@store/comments/selectors";

import s from "./BatchMode.module.less";

export default function BatchModeSelectAll() {
  const { t } = useTranslation();

  const { selectedComments, toggleSelectAll } = useBatchMode();

  const comments = useSelector(commentsSelector);

  const onSelectAll = () => {
    toggleSelectAll(comments);
  };

  return (
    <label htmlFor="batch-mode-select-all" className={s.bbBatchModeSelectAll}>
      {!!selectedComments?.length && selectedComments?.length < comments.length ? (
        <CheckboxIndeterminate
          id="batch-mode-select-all"
          checked={!!selectedComments?.length}
          onClick={onSelectAll}
        />
      ) : (
        <Checkbox
          onClick={onSelectAll}
          id="batch-mode-select-all"
          checked={!!selectedComments?.length}
        />
      )}
      <span className={s.bbBatchModeSelectAllTitle}>{t("containers:batchMode:selectAll")}</span>
    </label>
  );
}
