import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import React, { FC, useState, useEffect } from "react";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import PieChartWidget from "../../_common/pieChartWidget/PieChartWidget";

import s from "./MessageTypePieChart.module.less";

import { IPieChartUnit } from "../../sentimentDetails/SentimentDetails.type";
import { normalizePlatformData } from "../../sentimentDetails/helpers";
import { IFilters } from "@store/filters/types";
import { IOverviewMessageTypeDistribution } from "@store/dashboard/types";
import { messageTypesDictionary } from "./helpers";

interface IMessageTypePieChartProps {
  loading: boolean;
  filterData: IFilters;
  data?: IOverviewMessageTypeDistribution;
  filterToSpecificItem?: (item: IFilters) => void;
}

const MessageTypePieChart: FC<IMessageTypePieChartProps> = ({
  filterData,
  data,
  loading,
  filterToSpecificItem,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [commentPlatformsData, setCommentPlatformsData] = useState<IPieChartUnit[]>([]);
  const [total, setTotal] = useState<IPieChartUnit>({} as IPieChartUnit);

  useEffect(() => {
    const normalizeData = () => {
      const totalItem = {
        id: "total",
        name: "Total",
        count: data?.total || 0,
        percentage_of_total: 100,
        change_percentage: 0,
      };
      const items =
        data?.categoriesDistribution.map(item => ({
          id: item.category.id,
          name: item.category.label,
          count: item.count,
          percentage_of_total: item.percentage,
          change_percentage: item.percentageChange,
        })) || [];
      const normalizedPlatformData = normalizePlatformData([totalItem, ...items]);

      const pieChartTotal = normalizedPlatformData.find(
        (unit: IPieChartUnit) => unit.rawName === "total",
      ) as IPieChartUnit;
      const pieChartData = normalizedPlatformData.filter(
        (unit: IPieChartUnit) => unit.rawName !== "total",
      ) as IPieChartUnit[];

      setTotal(pieChartTotal);
      setCommentPlatformsData(pieChartData);
    };

    normalizeData();
  }, [data]);

  const onChartClick = (event: { payload: { rawName: string } }) => {
    const filters = {
      ...filterData,
      message_types: messageTypesDictionary[event.payload.rawName],
    };
    filterToSpecificItem?.(filters);

    navigate(
      {
        search: "?section=listen-sentiment_details",
      },
      { state: { filters } },
    );
  };

  return (
    <Card
      headerTitle={t("components:messageTypeDistributionPieChart:headerTitle")}
      headerSubTitle={t("components:messageTypeDistributionPieChart:headerSubTitle")}
      className={s.bbMessageTypePieChartCard}
    >
      <div className={s.bbMessageTypePieChart}>
        <PieChartWidget
          position="center"
          loading={loading}
          defaultValue={total}
          onChartClick={onChartClick}
          chartData={commentPlatformsData.filter(unit => unit.value !== 0)}
        />
      </div>
    </Card>
  );
};

export default MessageTypePieChart;
