import React, { FC } from "react";

import s from "./RatingReview.module.less";

import { IRatingReviewStarsProps } from "./RatingReview.types";
import { MAX_TRUSTPILOT_STARS } from "@components/_common/MessageList/ReviewMessage/helpers";

import { StarFilledIcon, StarIcon } from "@assets/index";

export const RatingReviewStars: FC<IRatingReviewStarsProps> = ({ rating }) => {
  return (
    <div className={s.bbRatingReviewStars}>
      {[...Array(MAX_TRUSTPILOT_STARS)].map((_, index) => {
        return (
          <div className={index < rating ? s.bbFilledIconStar : s.bbOutlinedIconStar} key={index}>
            {index < rating ? <StarFilledIcon /> : <StarIcon />}
          </div>
        );
      })}
    </div>
  );
};
