import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { useRoutes } from "@utils/useRoutes";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import Report from "@components/report";

import { IRestrictedRoutesProps } from "./Routes.type";

const ReportRoutes: FC<IRestrictedRoutesProps> = ({ path }) => {
  const { redirectCallback } = useRoutes(path);

  return (
    <Routes>
      <Route
        path="*"
        element={
          <AppLayout basename="report" menu={[]}>
            {{
              content: (
                <Routes>
                  <Route
                    path="/"
                    element={
                      <PrivateRoute>
                        <Report />
                      </PrivateRoute>
                    }
                  />

                  <Route
                    path="unauthorized"
                    element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
                  />
                  <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
                </Routes>
              ),
            }}
          </AppLayout>
        }
      />
    </Routes>
  );
};

export default ReportRoutes;
