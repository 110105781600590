import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";
import { Tooltip, IAxisTick } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CommentBreakdown.module.less";

import { PostTypeIcon } from "./postTypes";
import { Languages } from "@utils/languages";
import { Countries } from "@utils/countries";
import { cropText } from "@utils/cropText";
import { IBreakdownUnit } from "@store/aggregations/types";
import { getPlatformIconBasicColor, platformIconName } from "@utils/platform";

interface IChartLabelProps {
  type: string;
  tooltipLabel: string;
  chartLabelWidth: number;
  breakdownUnit: IBreakdownUnit;
  labelTickProps: IAxisTick;
}

const cx = cn.bind(s);

const ChartLabel: FC<IChartLabelProps> = ({
  type,
  tooltipLabel,
  breakdownUnit,
  labelTickProps,
  chartLabelWidth,
}) => {
  const { t } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const labelMargin = 12;
  const maxTextLength = screens.md ? 40 : 20;
  const itemId = labelTickProps.payload.value;
  const item = breakdownUnit.items?.find(unit => unit.id === itemId);

  const tooltipTitle = `${t("components:breakdown:filter")} ${tooltipLabel?.toLowerCase()}`;

  const getBreakdownChartIcon = (_type: string, value: string) => {
    let Icon = "";

    switch (_type) {
      case "platform_type":
        return (
          <span className={cx(`bbPlatformIcon-${platformIconName(value)}`)}>
            {getPlatformIconBasicColor(platformIconName(value))}
          </span>
        );
      case "post_type":
        Icon = PostTypeIcon[value as keyof typeof PostTypeIcon];
        break;
      case "language":
        Icon = Languages[value as keyof typeof Languages];
        break;
      case "ad_targeting":
        Icon = Countries[value as keyof typeof Countries];
        break;
    }
    return <>{Icon ? <Icon /> : null}</>;
  };

  const showDetails = () => {
    return ["asset_name", "campaign_name", "adset_name", "ad_name"].includes(type);
  };

  return (
    <Tooltip placement="topLeft" title={tooltipTitle}>
      <foreignObject
        x={5}
        y={labelTickProps.y - labelMargin}
        cursor="pointer"
        className={s.bbBreakdownChartLabel}
        style={{ width: chartLabelWidth }}
      >
        <div className={s.bbBreakdownChartLabelContainer}>
          <div className={s.bbBreakdownChartLabelContent}>
            <g transform={`translate(${labelTickProps.x},${labelTickProps.y - labelMargin})`}>
              <div className={s.bbChartLabel} style={{ width: chartLabelWidth }}>
                {getBreakdownChartIcon(type, platformIconName(itemId))}
                <span className={s.bbChartLabelText}>
                  {type === "ad_targeting" ? item?.id + " - " : null}
                  {cropText(item?.label || "", maxTextLength)}
                </span>
              </div>

              {showDetails() ? (
                <tspan textAnchor="left" x="0" dy="20" className={s.bbDetails}>
                  {item?.id}
                  {item?.details?.platform_type
                    ? getBreakdownChartIcon("platform_type", item?.details?.platform_type)
                    : null}
                  {item?.details?.asset_type}
                </tspan>
              ) : null}
            </g>
          </div>
        </div>
      </foreignObject>
    </Tooltip>
  );
};

export default ChartLabel;
