import { RootState } from "..";

export const fromIntegrations = (state: RootState) => state.integrations;

// Integrations
export const getIntegrationsListSelector = (state: RootState) =>
  fromIntegrations(state).integrations;
export const getIntegrationsListPendingSelector = (state: RootState) =>
  fromIntegrations(state).fetchingIntegrationsList;
export const getIntegrationsListSuccessfulSelector = (state: RootState) =>
  fromIntegrations(state).fetchedIntegrationsList;
export const getIntegrationsListFailureSelector = (state: RootState) =>
  fromIntegrations(state).fetchedIntegrationsListFail;

export const creatingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegration;
export const createdIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).createdIntegration;
export const creatingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegrationFailed;
export const creatingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).creatingIntegrationFailedMessage;

export const updatingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegration;
export const updatedIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).updatedIntegration;
export const updatingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegrationFailed;
export const updatingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).updatingIntegrationFailedMessage;

export const deletingIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegration;
export const deletedIntegrationSelector = (state: RootState) =>
  fromIntegrations(state).deletedIntegration;
export const deletingIntegrationFailedSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegrationFailed;
export const deletingIntegrationFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).deletingIntegrationFailedMessage;

// Users
export const integrationUsersListSelector = (state: RootState) =>
  fromIntegrations(state).integrationUsers;
export const fetchingIntegrationUsersListSelector = (state: RootState) =>
  fromIntegrations(state).fetchingIntegrationUsersList;
export const fetchedIntegrationUsersListSelector = (state: RootState) =>
  fromIntegrations(state).fetchedIntegrationUsersList;
export const fetchingIntegrationUsersListFailureSelector = (state: RootState) =>
  fromIntegrations(state).fetchingIntegrationUsersListFailed;
export const fetchingIntegrationUsersListFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).fetchingIntegrationUsersListFailedMessage;

export const creatingOrUpdatingIntegrationUserSelector = (state: RootState) =>
  fromIntegrations(state).creatingOrUpdatingIntegrationUser;
export const createdOrUpdatedIntegrationUserSelector = (state: RootState) =>
  fromIntegrations(state).createdOrUpdatedIntegrationUser;
export const creatingOrUpdatingIntegrationUserFailedSelector = (state: RootState) =>
  fromIntegrations(state).creatingOrUpdatingIntegrationUserFailed;
export const creatingOrUpdatingIntegrationUserFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).creatingOrUpdatingIntegrationUserFailedMessage;

// Tickets
export const creatingTicketSelector = (state: RootState) => fromIntegrations(state).creatingTicket;
export const createdTicketSelector = (state: RootState) => fromIntegrations(state).createdTicket;
export const creatingTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).creatingTicketFailed;
export const creatingTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).creatingTicketFailedMessage;

export const updatingTicketSelector = (state: RootState) => fromIntegrations(state).updatingTicket;
export const updatedTicketSelector = (state: RootState) => fromIntegrations(state).updatedTicket;
export const updatingTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).updatingTicketFailed;
export const updatingTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).updatingTicketFailedMessage;

export const sendingNewInternalCommentSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalComment;
export const sentNewInternalCommentSelector = (state: RootState) =>
  fromIntegrations(state).sentNewInternalComment;
export const sendingNewInternalCommentFailedSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalCommentFailed;
export const sendingNewInternalCommentFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).sendingNewInternalCommentFailedMessage;

export const linkingIntegrationUserToTicketSelector = (state: RootState) =>
  fromIntegrations(state).linkingIntegrationUserToTicket;
export const linkedIntegrationUserToTicketSelector = (state: RootState) =>
  fromIntegrations(state).linkedIntegrationUserToTicket;
export const linkingIntegrationUserToTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).linkingIntegrationUserToTicketFailed;
export const linkingIntegrationUserToTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).linkingIntegrationUserToTicketFailedMessage;

export const unlinkingIntegrationUserFromTicketSelector = (state: RootState) =>
  fromIntegrations(state).unlinkingIntegrationUserFromTicket;
export const unlinkedIntegrationUserFromTicketSelector = (state: RootState) =>
  fromIntegrations(state).unlinkedIntegrationUserFromTicket;
export const unlinkingIntegrationUserFromTicketFailedSelector = (state: RootState) =>
  fromIntegrations(state).unlinkingIntegrationUserFromTicketFailed;
export const unlinkingIntegrationUserFromTicketFailedMessageSelector = (state: RootState) =>
  fromIntegrations(state).unlinkingIntegrationUserFromTicketFailedMessage;
