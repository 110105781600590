import { ActionType, createReducer } from "typesafe-actions";
import produce from "immer";

import * as actions from "./actions";

import { AIInsightsModalStateEnum, IAIInsightsState } from "./types";

const initialState: IAIInsightsState = {
  state: AIInsightsModalStateEnum.Closed,
  title: "",
  id: "",
  messages: [],
  initialState: true,
  previousThread: undefined,

  fetchingGetMessages: false,
  fetchedGetMessages: false,
  failedGetMessages: false,
};

export const aiInsightsReducer = createReducer<IAIInsightsState, ActionType<typeof actions>>(
  initialState,
)
  .handleAction(actions.toggleOpenAIModalAction, (state, action) => {
    if (!action.payload) {
      return initialState;
    }
    return produce(state, draft => {
      draft.state = action.payload;
    });
  })
  .handleAction(actions.fetchThreadsSuccess, (state, action) =>
    produce(state, draft => {
      draft.title = action.payload.created_thread.title;
      draft.id = action.payload.created_thread.id;
      draft.previousThread = action.payload.previous_thread;
    }),
  )
  .handleAction(actions.fetchThreadMessagesAction, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = true;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = false;
    }),
  )
  .handleAction(actions.fetchThreadMessagesSuccess, (state, action) =>
    produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = true;
      draft.failedGetMessages = false;
      draft.messages = action.payload.messages;
      draft.id = action.payload.id;
      draft.title = action.payload.thread_title || state.title;
      draft.previousThread = action.payload.isPreviousThread
        ? initialState.previousThread
        : state.previousThread;
      draft.initialState = action.payload.isPreviousThread ? false : state.initialState;
    }),
  )
  .handleAction(actions.fetchThreadMessagesFailure, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = true;
    }),
  )
  .handleAction(actions.sendThreadMessageAction, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = true;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = false;
    }),
  )
  .handleAction(actions.sendThreadMessageSuccess, (state, action) => {
    const previousMessages = state.messages.map(({ call_to_actions_options, ...message }) => {
      const filteredCallToActionsOptions = call_to_actions_options?.filter(
        option => option.is_selected,
      );

      return {
        ...message,
        ...(filteredCallToActionsOptions?.length
          ? { call_to_actions_options: filteredCallToActionsOptions }
          : {}),
      };
    });

    return produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = true;
      draft.failedGetMessages = false;
      (draft.initialState = false),
        (draft.messages = [
          ...previousMessages,
          action.payload.created_message,
          ...action.payload.initial_responses,
        ]);
      draft.title = action.payload.thread_title || state.title;
    });
  })
  .handleAction(actions.sendThreadMessageFailure, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = true;
    }),
  )
  .handleAction(actions.fetchAIResponseAction, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = true;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = false;
    }),
  )
  .handleAction(actions.fetchAIResponseSuccess, (state, action) =>
    produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = true;
      draft.failedGetMessages = false;
      draft.messages = [...state.messages, ...action.payload.items];
      draft.title = action.payload.thread_title || state.title;
    }),
  )
  .handleAction(actions.fetchAIResponseFailure, state =>
    produce(state, draft => {
      draft.fetchingGetMessages = false;
      draft.fetchedGetMessages = false;
      draft.failedGetMessages = true;
    }),
  );
