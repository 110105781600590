import React from "react";
import { useTranslation } from "react-i18next";

import { Alert } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Disclaimers.module.less";

import { LoadingIcon } from "@assets/index";

interface IPendingAssetsDisclaimerProps {
  onCloseDisclaimer: () => void;
}

function PendingAssetsDisclaimer({ onCloseDisclaimer }: IPendingAssetsDisclaimerProps) {
  const { t } = useTranslation();

  return (
    <Alert
      size="large"
      align="center"
      className={s.bbDisclaimer}
      onClose={onCloseDisclaimer}
      message={t("components:assetAlerts:pending:message")}
      icon={
        <span className={s.bbPendingAssetsDisclaimerIcon}>
          <LoadingIcon />
        </span>
      }
      action={
        <a
          className={s.bbPendingAssetsDisclaimerAction}
          href={`${process.env.BASE_URL}/settings/asset-management`}
        >
          {t("components:assetAlerts:pending:action")}
        </a>
      }
    />
  );
}

export default PendingAssetsDisclaimer;
