import { StateObservable } from "redux-observable";
import { Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { isActionOf } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import * as actions from "./actions";
import { careStreamCounts, careStreams, moderationStreams } from "@utils/paths";
import {
  postModerationStreamsCommentsSuccessAction,
  loadModerationStreamsCommentsPageSuccessAction,
} from "../comments/actions";

import {
  IModerationStream,
  StreamsActions,
  IStreamCommentResponse,
  IStreamCommentPayload,
  ICareCPSStream,
  IStreamCounts,
} from "./types";
import { RootState } from "..";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

const constructStreamCommentsEndpoint = (payload: IStreamCommentPayload) => {
  const { id: stream_id, cursor, sort } = payload;
  const BASE = `${moderationStreams}/${stream_id}/comments`;
  const params = [];

  if (cursor) params.push(`cursor=${cursor}`);
  if (sort) params.push(`sort=${sort}`);

  return params.length > 0 ? `${BASE}?${params.join("&")}` : BASE;
};

export const getModerationStreamsEpic = (
  action$: Observable<StreamsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getModerationStreamsAction)),
    switchMap(() => {
      return ajax
        .get<{ items: IModerationStream[] }>(
          moderationStreams,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(({ items }) => actions.getModerationStreamsActionSuccess(items)),
          catchError(e => handleError(e, actions.getModerationStreamsActionFailure)),
        );
    }),
  );

export const getModerationStreamsCommentsEpic = (
  action$: Observable<StreamsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getModerationStreamsCommentsAction)),
    switchMap(a => {
      return ajax
        .get<IStreamCommentResponse>(
          constructStreamCommentsEndpoint(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => {
            if (!!a.payload.cursor) {
              return loadModerationStreamsCommentsPageSuccessAction(
                data.items,
                data.cursor,
                undefined,
                undefined,
              );
            }

            return postModerationStreamsCommentsSuccessAction(data.items, data.cursor);
          }),
          catchError(e => handleError(e, actions.getModerationStreamsCommentsActionFailure)),
        );
    }),
  );

export const getCareCpsStreamsEpic = (
  action$: Observable<StreamsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getCareCpsStreamsAction)),
    switchMap(() => {
      return ajax
        .get<{ items: ICareCPSStream[] }>(
          careStreams,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(({ items }) => [
            actions.getCareCpsStreamsActionSuccess(items),
            actions.getCareCpsStreamsCountsAction(items.map(item => item.id)),
          ]),
          switchMap(nextActions => of(...nextActions)),
          catchError(e => handleError(e, actions.getCareCpsStreamsActionFailure)),
        );
    }),
  );

export const getCareCpsStreamsCountsEpic = (
  action$: Observable<StreamsActions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isActionOf(actions.getCareCpsStreamsCountsAction)),
    switchMap(a => {
      return ajax
        .post<{ items: IStreamCounts[] }>(
          careStreamCounts,
          {
            items: a.payload,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => actions.getCareCpsStreamsCountsActionSuccess(data.items)),
          catchError(e => handleError(e, actions.getCareCpsStreamsCountsActionFailure)),
        );
    }),
  );
