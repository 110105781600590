import React from "react";
import cn from "classnames";

import { IStepConfig, StepStatusType } from "./types";

import s from "./Step.module.less";

interface IStepProps {
  title?: React.ReactNode;
  content: React.ReactNode;
  hasTail?: boolean;
  config: IStepConfig;
  status: StepStatusType;
}

export const Step: React.FC<IStepProps> = ({ title, content, hasTail, config, status }) => {
  const { color, borderColor, backgroundColor, tailColors, titleColor } = config;

  return (
    <div className={s.bbStep}>
      <div
        style={{
          backgroundColor,
          borderColor,
          color,
        }}
        className={s.bbStepContent}
      >
        {content}
        {hasTail && (
          <div className={s.bbStepContentTail}>
            {tailColors.map(tailColor => (
              <div
                key={tailColor}
                style={{
                  backgroundColor: tailColor,
                  width: `${100 / tailColors.length}%`,
                }}
                className={s.bbStepContentTailPart}
              ></div>
            ))}
          </div>
        )}
      </div>

      {title && (
        <div
          style={{ color: titleColor }}
          className={cn(s.bbStepTitle, {
            [s.bbStepTitleBold]: status === "process",
          })}
        >
          {title}
        </div>
      )}
    </div>
  );
};
