import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";
import { Picker, EmojiData } from "emoji-mart";
import { Dropdown, OutsideClickDetector } from "@bbdevcrew/bb_ui_kit_fe";

import "emoji-mart/css/emoji-mart.css";
import s from "./EmojiSelect.module.less";

import { IEmojiSelectProps } from "./EmojiSelect.type";
import { getEmojiSetForPlatform, getCategories } from "./helpers";

import { SmileyIcon } from "@assets/index";

const EmojiSelect: FC<IEmojiSelectProps> = ({
  platform,
  onEmojiSelect,
  pickerStyle,
  useDropdownPortal,
}) => {
  const { t } = useTranslation();

  const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);

  const onEmojiClick = (emoji: EmojiData) => {
    setEmojiPickerOpen(false);
    onEmojiSelect(emoji);
  };

  const trigger = (
    <Button
      id="emojis-btn"
      className={s.bbEmojiButton}
      onClick={() => setEmojiPickerOpen(!emojiPickerOpen)}
    >
      <SmileyIcon />
    </Button>
  );

  const emojiPicker = (
    <Picker
      style={pickerStyle}
      showPreview={false}
      showSkinTones={false}
      onSelect={onEmojiClick}
      set={getEmojiSetForPlatform(platform)}
      i18n={{
        search: t("generic:search"),
        categories: getCategories(t),
      }}
    />
  );

  return (
    <div className={s.bbEmojiPicker}>
      {useDropdownPortal ? (
        <Dropdown
          open={emojiPickerOpen}
          setOpen={setEmojiPickerOpen}
          placement="topRight"
          trigger={trigger}
          menuClassName={s.bbEmojiDropdown}
        >
          {emojiPicker}
        </Dropdown>
      ) : (
        <>
          {trigger}

          {emojiPickerOpen ? (
            <OutsideClickDetector onOutsideClick={() => setEmojiPickerOpen(false)}>
              {emojiPicker}
            </OutsideClickDetector>
          ) : null}
        </>
      )}
    </div>
  );
};

export default EmojiSelect;
