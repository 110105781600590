import React, { useMemo, FC } from "react";
import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import { ListItem, useSearch } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ExtraMenu.module.less";

import { meSelector } from "@store/me/selectors";
import { patchMeSessionClientAction } from "@store/me/actions";

export const ChangeClient: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const me = useSelector(meSelector);

  const transformClient = useMemo(() => {
    return (me?.available_clients || []).map(client => ({
      id: client.id,
      label: client.name,
    }));
  }, [me?.available_clients]);

  const selectedClient = useMemo(() => {
    return [me?.client?.id || ""];
  }, [me?.client]);

  const { options: searchedOptions, SearchInput } = useSearch(transformClient, t("generic:search"));

  const changeClient = (value: string) => {
    dispatch(patchMeSessionClientAction(value));
  };

  return (
    <div className={s.bbChangeClient}>
      {SearchInput}
      <div>
        {searchedOptions.map(option => (
          <ListItem
            key={option.id}
            value={option.id}
            selected={selectedClient.includes(option.id)}
            onClick={() => changeClient(option.id)}
          >
            {option.label}
          </ListItem>
        ))}
      </div>
    </div>
  );
};

export const ChangeClientTitle = () => {
  const me = useSelector(meSelector);
  const { t } = useTranslation();

  return (
    <>
      <div className={s.bbChangeClientTitleWrapper}>
        <span className={s.bbChangeClientTitle}>{t("generic:client")}</span>
        <span className={s.bbChangeClientName}>{me?.client?.name}</span>
      </div>
    </>
  );
};
