import React, { FC } from "react";

import CommentAction from "../commentAction/CommentAction";

import { TranslateSimpleIcon } from "@assets/index";

interface ITranslateAllProps {
  label: string;
  className?: string;
  onClick?: () => void;
}

const TranslateAll: FC<ITranslateAllProps> = ({ label, className = "", onClick }) => {
  return (
    <CommentAction type="primary" className={className} onClick={onClick}>
      <TranslateSimpleIcon />
      {label}
    </CommentAction>
  );
};

export default TranslateAll;
