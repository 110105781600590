import { RootState } from "..";

export const fromUsers = (state: RootState) => state.users;

export const usersSelector = (state: RootState) => fromUsers(state).users;

export const getUsersPendingSelector = (state: RootState) => fromUsers(state).fetchingGetUsers;
export const getUsersSuccessfulSelector = (state: RootState) => fromUsers(state).fetchedGetUsers;
export const getUsersFailedSelector = (state: RootState) => fromUsers(state).fetchedGetUsersFail;

export const putUserPendingSelector = (state: RootState) => fromUsers(state).fetchingPutUser;
export const putUserSuccessfulSelector = (state: RootState) => fromUsers(state).fetchedPutUser;
export const putUserFailureSelector = (state: RootState) => fromUsers(state).fetchedPutUserFail;

export const patchUserStatusPendingSelector = (state: RootState) =>
  fromUsers(state).fetchingPatchUserStatus;
export const patchUserStatusSuccessfulSelector = (state: RootState) =>
  fromUsers(state).fetchedPatchUserStatus;
export const patchUserStatusFailureSelector = (state: RootState) =>
  fromUsers(state).fetchedPatchUserStatusFail;

export const postUserPendingSelector = (state: RootState) => fromUsers(state).fetchingPostUser;
export const postUserSuccessfulSelector = (state: RootState) => fromUsers(state).fetchedPostUser;
export const postUserFailureSelector = (state: RootState) => fromUsers(state).fetchedPostUserFail;
