import React from "react";
import classNames from "classnames/bind";

import { Input } from "@bbdevcrew/bb_ui_kit_fe";
import { useInputChip, _IInputChipProps } from "./InputChipConfig";
import { ChipListAction } from "@components/_common/ChipList/ChipList";

import s from "./InputChip.module.less";

function InputChip({
  value,
  _size,
  onChipAdd,
  onChipRemove,
  wrapperClassName,
  ...props
}: _IInputChipProps) {
  const { chipValue, onKeyUp, onKeyDown, onChange, onPaste } = useInputChip({
    value,
    onChipAdd,
    onChipRemove,
  });

  return (
    <div className={s.bbInputChip}>
      <ChipListAction
        _size="xs"
        value={value}
        onActionClick={onChipRemove}
        renderAddChip={() => (
          <Input
            {...props}
            _size={_size}
            value={chipValue}
            onKeyUp={onKeyUp}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            wrapperClassName={classNames(wrapperClassName, s.bbInputChipWrapper, {
              [s.bbInputChipWrapperNotEmpty]: value.length !== 0,
            })}
          />
        )}
      />
    </div>
  );
}

export default InputChip;
