import { createReducer, ActionType } from "typesafe-actions";

import { getWorkflowsSavedRepliesSuccessAction, getWorkflowsSuccessAction } from "./actions";

import { IWorkflowsState } from "./types";
import {
  GET_WORKFLOWS,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
  CREATE_WORKFLOW,
  CREATE_WORKFLOW_SUCCESS,
  CREATE_WORKFLOW_FAILURE,
  EDIT_WORKFLOW,
  EDIT_WORKFLOW_SUCCESS,
  EDIT_WORKFLOW_FAILURE,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  ENABLE_WORKFLOW,
  ENABLE_WORKFLOW_SUCCESS,
  ENABLE_WORKFLOW_FAILURE,
  DISABLE_WORKFLOW,
  DISABLE_WORKFLOW_SUCCESS,
  DISABLE_WORKFLOW_FAILURE,
  SET_WORKFLOW_FORM_DIRTY,
  RESET_WORKFLOWS,
  GET_WORKFLOWS_SAVED_REPLIES,
  GET_WORKFLOWS_SAVED_REPLIES_SUCCESS,
  GET_WORKFLOWS_SAVED_REPLIES_FAILURE,
} from "./actionTypes";

import { toggleWorkflowEnabled } from "./helpers";

const initialState = {
  workflowItems: [],
  workflowCategories: [],
  workflowRiskOptions: {
    risk_lvl: [],
    impact_area: [],
  },
  workflowsSavedReplies: [],

  initialFetchDone: false,

  fetchingWorkflows: false,
  fetchedWorkflows: false,
  fetchingWorkflowsFailed: false,

  creatingWorkflow: false,
  createdWorkflow: false,
  creatingWorkflowFailed: false,

  editingWorkflow: false,
  editedWorkflow: false,
  editingWorkflowFailed: false,

  deletingWorkflow: false,
  deletedWorkflow: false,
  deletingWorkflowFailed: false,

  enablingWorkflow: false,
  enabledWorkflow: false,
  enablingWorkflowFailed: false,

  disablingWorkflow: false,
  disabledWorkflow: false,
  disablingWorkflowFailed: false,

  fetchingWorkflowsSavedReplies: false,
  fetchedWorkflowsSavedReplies: false,
  fetchingWorkflowsSavedRepliesFailed: false,

  formDirty: false,
};

export const workflowsReducer = createReducer<IWorkflowsState>(initialState, {
  // RESET_WORKFLOWS
  [RESET_WORKFLOWS]: (state: IWorkflowsState) => ({
    ...initialState,
    initialFetchDone: state.initialFetchDone,
    workflowItems: [...state.workflowItems],
    workflowCategories: [...state.workflowCategories],
    workflowRiskOptions: { ...state.workflowRiskOptions },
  }),
  // GET_WORKFLOWS
  [GET_WORKFLOWS]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflows: true,
    fetchedWorkflows: false,
    fetchingWorkflowsFailed: false,
  }),
  [GET_WORKFLOWS_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsSuccessAction>,
  ) => ({
    ...state,
    workflowItems: action.payload.items,
    workflowCategories: action.payload.categories,
    workflowRiskOptions: action.payload.risk_identifications,
    fetchingWorkflows: false,
    fetchedWorkflows: true,
    fetchingWorkflowsFailed: false,
    initialFetchDone: true,
  }),
  [GET_WORKFLOWS_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflows: false,
    fetchedWorkflows: false,
    fetchingWorkflowsFailed: true,
  }),

  // CREATE_WORKFLOW
  [CREATE_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflow: true,
    createdWorkflow: false,
    creatingWorkflowFailed: false,
  }),
  [CREATE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflow: false,
    createdWorkflow: true,
    creatingWorkflowFailed: false,
  }),
  [CREATE_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    creatingWorkflow: false,
    createdWorkflow: false,
    creatingWorkflowFailed: true,
  }),

  // EDIT_WORKFLOW
  [EDIT_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: true,
    editedWorkflow: false,
    editingWorkflowFailed: false,
  }),
  [EDIT_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: false,
    editedWorkflow: true,
    editingWorkflowFailed: false,
  }),
  [EDIT_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    editingWorkflow: false,
    editedWorkflow: false,
    editingWorkflowFailed: true,
  }),

  // DELETE_WORKFLOW
  [DELETE_WORKFLOW]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: true,
    deletedWorkflow: false,
    deletingWorkflowFailed: false,
  }),
  [DELETE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: false,
    deletedWorkflow: true,
    deletingWorkflowFailed: false,
  }),
  [DELETE_WORKFLOW_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    deletingWorkflow: false,
    deletedWorkflow: false,
    deletingWorkflowFailed: true,
  }),

  // ENABLE_WORKFLOW
  [ENABLE_WORKFLOW]: (state: IWorkflowsState, action) => ({
    ...state,
    enablingWorkflow: true,
    enabledWorkflow: false,
    enablingWorkflowFailed: false,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),
  [ENABLE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    enablingWorkflow: false,
    enabledWorkflow: true,
    enablingWorkflowFailed: false,
  }),
  [ENABLE_WORKFLOW_FAILURE]: (state: IWorkflowsState, action) => ({
    ...state,
    enablingWorkflow: false,
    enabledWorkflow: false,
    enablingWorkflowFailed: true,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),

  // DISABLE_WORKFLOW
  [DISABLE_WORKFLOW]: (state: IWorkflowsState, action) => ({
    ...state,
    disablingWorkflow: true,
    disabledWorkflow: false,
    disablingWorkflowFailed: false,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),
  [DISABLE_WORKFLOW_SUCCESS]: (state: IWorkflowsState) => ({
    ...state,
    disablingWorkflow: false,
    disabledWorkflow: true,
    disablingWorkflowFailed: false,
  }),
  [DISABLE_WORKFLOW_FAILURE]: (state: IWorkflowsState, action) => ({
    ...state,
    disablingWorkflow: false,
    disabledWorkflow: false,
    disablingWorkflowFailed: true,
    workflowItems: toggleWorkflowEnabled(state, action.payload),
  }),

  // SET_WORKFLOW_FORM_DIRTY
  [SET_WORKFLOW_FORM_DIRTY]: (state, action) => ({
    ...state,
    formDirty: action.payload,
  }),

  // GET_WORKFLOWS_SAVED_REPLIES
  [GET_WORKFLOWS_SAVED_REPLIES]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsSavedReplies: true,
    fetchedWorkflowsSavedReplies: false,
    fetchingWorkflowsSavedRepliesFailed: false,
  }),
  [GET_WORKFLOWS_SAVED_REPLIES_SUCCESS]: (
    state: IWorkflowsState,
    action: ActionType<typeof getWorkflowsSavedRepliesSuccessAction>,
  ) => ({
    ...state,
    workflowsSavedReplies: action.payload,
    fetchingWorkflowsSavedReplies: false,
    fetchedWorkflowsSavedReplies: true,
    fetchingWorkflowsSavedRepliesFailed: false,
  }),
  [GET_WORKFLOWS_SAVED_REPLIES_FAILURE]: (state: IWorkflowsState) => ({
    ...state,
    fetchingWorkflowsSavedReplies: false,
    fetchedWorkflowsSavedReplies: false,
    fetchingWorkflowsSavedRepliesFailed: true,
  }),
});
