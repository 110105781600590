import { Avatar } from "antd";
import ReactDOM from "react-dom";
import classNames from "classnames";
import React, { HTMLAttributes } from "react";

import s from "./PostImageOverlay.module.less";
import { useTranslation } from "react-i18next";

export interface IPostImageOverlayProps extends HTMLAttributes<HTMLDivElement> {
  imgUrl?: string;
  isImgPortrait?: boolean;
}

export function PostImageOverlay({ imgUrl, className, isImgPortrait }: IPostImageOverlayProps) {
  const { t } = useTranslation();

  return (
    <div className={classNames(s.bbPostImageOverlay, className)}>
      <Avatar
        size={136}
        src={imgUrl}
        shape="square"
        style={{ fontSize: "12px" }}
        className={classNames(s.bbPostImageOverlayAvatar, {
          [s.bbPostImageOverlayAvatarPortrait]: isImgPortrait,
        })}
      >
        {t("generic:noImage")}
      </Avatar>

      <span className={s.bbPostImageOverlayShowPost}>
        {t("components:comments:actions:showPost")}
      </span>
    </div>
  );
}

export function PostImageOverlayPortal({
  imgUrl,
  className,
  isImgPortrait,
  portalId = "post-overlay",
  ...rest
}: IPostImageOverlayProps & { portalId?: string }) {
  const target = document.getElementById(portalId);

  if (!target) {
    console.warn(`Could not find parent with id ${portalId}`);
    return null;
  }

  return ReactDOM.createPortal(
    <div className={classNames(s.bbPostImageOverlayPortal, className)} {...rest}>
      <PostImageOverlay imgUrl={imgUrl} isImgPortrait={isImgPortrait} />
    </div>,
    target,
  );
}
