import React, { FC } from "react";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Post.module.less";

import { IPostReactionsProps } from "./Post.type";
import { numberToQuantity, IconReaction } from "@bbdevcrew/bb_ui_kit_fe";

export const PostReactions: FC<IPostReactionsProps> = ({ post, platform }) => {
  return (
    <div className={s.bbPostReactions}>
      {post && post.reactions
        ? Object.entries(post.reactions).map((reaction: [string, number], key: number) => {
            return reaction[1] > 0 ? (
              <Tooltip key={key} placement="top" title={reaction[1]}>
                <div className={s.bbPostReactionsItem}>
                  {platform ? (
                    <div className={s.bbPostReactionsIcon}>
                      <IconReaction platform={platform} name={reaction[0]} />
                    </div>
                  ) : null}
                  <div className={s.bbPostReactionsValue}>
                    <span>{numberToQuantity(reaction[1])}</span>
                  </div>
                </div>
              </Tooltip>
            ) : null;
          })
        : null}
    </div>
  );
};
