/* eslint-disable */
export const sanitizeUnderScoreAndUppercase = (value: string) => {
  return value
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const mergeDedupe = (a: any[], b: any[], predicate = (a: any[], b: any[]) => a === b) => {
  // copying to avoid side effects
  const c = [...a];
  // add all items from B to copy C if they're not already present
  b.forEach(bItem => (c.some(cItem => predicate(bItem, cItem)) ? null : c.push(bItem)));
  return c;
};
