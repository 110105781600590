export const GET_DASHBOARD = "DASHBOARD/GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "DASHBOARD/GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "DASHBOARD/GET_DASHBOARD_FAILURE";

export const GET_DASHBOARD_CSV = "DASHBOARD/GET_CSV";
export const GET_DASHBOARD_CSV_FAILURE = "DASHBOARD/GET_CSV_FAILURE";

export const GET_DASHBOARD_FOLLOWERS = "DASHBOARD/GET_DASHBOARD_FOLLOWERS";
export const GET_DASHBOARD_FOLLOWERS_SUCCESS = "DASHBOARD/GET_DASHBOARD_FOLLOWERS_SUCCESS";
export const GET_DASHBOARD_FOLLOWERS_FAILURE = "DASHBOARD/GET_DASHBOARD_FOLLOWERS_FAILURE";

export const GET_DASHBOARD_IMPRESSIONS = "DASHBOARD/GET_DASHBOARD_IMPRESSIONS";
export const GET_DASHBOARD_IMPRESSIONS_SUCCESS = "DASHBOARD/GET_DASHBOARD_IMPRESSIONS_SUCCESS";
export const GET_DASHBOARD_IMPRESSIONS_FAILURE = "DASHBOARD/GET_DASHBOARD_IMPRESSIONS_FAILURE";
