import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Accordion from "@components/_common/Accordion/Accordion";
import { addToast, Button, CheckIcon, Input, SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";
import LinkUserModal from "./linkUserModal/LinkUserModal";

import {
  clearTicketStateAction,
  sendNewInternalCommentAction,
  updateTicketAction,
} from "@store/integrations/actions";
import {
  sentNewInternalCommentSelector,
  updatedTicketSelector,
} from "@store/integrations/selectors";

import s from "./CommentZendeskTicketDetails.module.less";

import { getStatusOptions, priorityOptions } from "./CommentZendeskTicketDetails.helpers";
import { CommentZendeskTicketDetailsProps } from "./CommentZendeskTicketDetails.type";
import { ZendeskTicketStatus } from "@store/integrations/types";

import { LinkIcon, SendIcon, ZendeskIcon } from "@assets/index";

const CommentZendeskTicketDetails = ({ ticket }: CommentZendeskTicketDetailsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(true);
  const [data, setData] = useState({
    ...ticket,
    status: ticket.status || ZendeskTicketStatus.New,
  });
  const internalReplies = [...ticket.internal_replies].reverse();
  const [newMessage, setNewMessage] = useState("");
  const [isLinkUserModalOpen, setIsLinkUserModalOpen] = useState(false);

  const statusOptions = getStatusOptions(data.status);

  const updatedTicket = useSelector(updatedTicketSelector);
  const sentNewInternalComment = useSelector(sentNewInternalCommentSelector);

  const updateTicket = (field: "status" | "priority", id: string) => (value: string) => {
    dispatch(updateTicketAction({ id, [field]: value }));
    setData(prevState => ({ ...prevState, [field]: value }));
  };

  const sendNewInternalComment = () => {
    dispatch(sendNewInternalCommentAction({ ticketId: ticket.id, message: newMessage }));
    setNewMessage("");
  };

  useEffect(() => {
    if (updatedTicket) {
      addToast({
        type: "success_accent",
        title: t("components:comments:zendeskTicket:toastMessages:success"),
      });
      dispatch(clearTicketStateAction());
    }
  }, [updatedTicket, t, dispatch]);

  useEffect(() => {
    if (sentNewInternalComment) {
      addToast({
        type: "success_accent",
        title: t("components:comments:zendeskTicket:toastMessages:newInternalComment"),
      });
      dispatch(clearTicketStateAction());
    }
  }, [dispatch, sentNewInternalComment, t]);

  return (
    <Accordion
      type="white"
      collapsed={collapsed}
      setCollapsed={() => setCollapsed(prevState => !prevState)}
      headerPrefix={<ZendeskIcon />}
      className={s.bbCommentZendeskTicketDetailsAccordion}
      title={t("components:comments:zendeskTicket:title")}
      body={
        <div>
          <div className={s.bbCommentZendeskTicketDetailsField}>
            <div className={s.bbCommentZendeskTicketDetailsFieldLabel}>
              {t("components:comments:zendeskTicket:subject")}
            </div>
            <div className={s.bbCommentZendeskTicketDetailsSubject}>{ticket.subject}</div>
          </div>
          <div className={s.bbCommentZendeskTicketDetailsField}>
            <div className={s.bbCommentZendeskTicketDetailsFieldLabel}>
              {t("components:comments:zendeskTicket:status")}
            </div>
            <SelectStyled
              _size="sm"
              name="status"
              data-cy="ticket-status-input"
              disabled={data.status === ZendeskTicketStatus.Closed}
              options={statusOptions}
              value={data.status}
              wrapperClassName={s.bbZendeskTicketFormInput}
              onChange={updateTicket("status", ticket.id)}
            />
          </div>
          <div className={s.bbCommentZendeskTicketDetailsField}>
            <div className={s.bbCommentZendeskTicketDetailsFieldLabel}>
              {t("components:comments:zendeskTicket:priority")}
            </div>
            <SelectStyled
              _size="sm"
              name="priority"
              data-cy="ticket-priority-input"
              disabled={data.status === ZendeskTicketStatus.Closed}
              options={priorityOptions}
              value={data.priority}
              wrapperClassName={s.bbZendeskTicketFormInput}
              onChange={updateTicket("priority", ticket.id)}
            />
          </div>
          <div className={s.bbCommentZendeskTicketDetailsField}>
            <div className={s.bbCommentZendeskTicketDetailsFieldLabel}>
              {t("components:comments:zendeskTicket:description")}
            </div>
            <div className={s.bbCommentZendeskTicketDetailsDescription}>{ticket.description}</div>
          </div>
          <div className={s.bbCommentZendeskTicketDetailsSection}>
            <div className={s.bbCommentZendeskTicketDetailsSectionTitle}>
              {t("components:comments:zendeskTicket:userTitle")}
            </div>
            <div className={s.bbCommentZendeskTicketDetailsUser}>
              {ticket.linked_user && (
                <span className={s.bbCommentZendeskTicketDetailsUserName}>
                  {ticket.linked_user.name}
                </span>
              )}
              <Button
                _size="sm"
                _type="secondary"
                className={
                  !!ticket.linked_user
                    ? s.bbCommentZendeskTicketDetailsUserLinkedButton
                    : s.bbCommentZendeskTicketDetailsUserLinkButton
                }
                onClick={() => setIsLinkUserModalOpen(true)}
              >
                {!!ticket.linked_user ? (
                  <>
                    <CheckIcon />
                    {t("components:comments:zendeskTicket:linked")}
                  </>
                ) : (
                  <>
                    <LinkIcon />
                    {t("components:comments:zendeskTicket:link")}
                  </>
                )}
              </Button>
            </div>
          </div>
          {internalReplies.length > 0 && (
            <div className={s.bbCommentZendeskTicketDetailsSection}>
              <div className={s.bbCommentZendeskTicketDetailsSectionTitle}>
                {t("components:comments:zendeskTicket:internalCommentsTitle")}
              </div>
              {internalReplies.map(reply => (
                <div
                  key={reply.message}
                  className={s.bbCommentZendeskTicketDetailsInternalCommentsComment}
                >
                  <span className={s.bbCommentZendeskTicketDetailsInternalCommentsCommentAuthor}>
                    {reply.author_name}
                  </span>
                  {reply.message}
                </div>
              ))}
            </div>
          )}
          {![ZendeskTicketStatus.Closed, ZendeskTicketStatus.Solved].includes(data.status) && (
            <div className={s.bbCommentZendeskTicketDetailsInternalCommentsNewComment}>
              <Input
                _size="sm"
                name="newInternalComment"
                data-cy="ticket-new-internal-comment-input"
                placeholder={t("components:comments:zendeskTicket:newInternalCommentPlaceholder")}
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
              />
              <Button
                _size="sm"
                _type="primary"
                data-cy="ticket-new-internal-comment-button"
                onClick={sendNewInternalComment}
              >
                <SendIcon />
              </Button>
            </div>
          )}
          <LinkUserModal
            isOpen={isLinkUserModalOpen}
            ticket={ticket}
            onClose={() => setIsLinkUserModalOpen(false)}
          />
        </div>
      }
    />
  );
};

export default CommentZendeskTicketDetails;
