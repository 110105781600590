import { ajax } from "rxjs/ajax";
import { Observable, of } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { RootState } from "..";
import { OnboardingPlatformTypesActionsType } from "./types";
import { GET_ONBOARDING_PLATFORM_TYPES, POST_ONBOARDING_PLATFORM_TYPE_ASSETS } from "./actionTypes";
import {
  getOnboardingPlatformTypesSuccessAction,
  getOnboardingPlatformTypesFailureAction,
  postOnboardingPlatformTypeAssetsSuccessAction,
  postOnboardingPlatformTypeAssetsFailureAction,
} from "./actions";

import {
  IPlatformAsset,
  IOnboardingPlatformItem,
} from "@components/_common/AssetTabs/AssetTabs.type";
import {
  notifyAssetPostSuccess,
  normalizePostAssetPayload,
  getAssetsTimeoutErrorMessage,
} from "./helpers";
import { i18nextInstance } from "../../App";
import { getHeaders } from "@utils/headers";
import { patchChecklistAction } from "../me/actions";
import { handleError } from "@utils/apiErrorHandler";
import { addToast } from "@bbdevcrew/bb_ui_kit_fe";
import { getUserAccessToken } from "../onboardingPlatforms/helpers";
import { assetsOnboarding, getCurrentModuleName } from "@utils/paths";

const getOnboardingPlatformTypesAction = (state$: StateObservable<RootState>) => {
  if (getCurrentModuleName() === "login")
    return map((data: { items: IPlatformAsset[] }) => {
      notifyAssetPostSuccess(data.items);
      return postOnboardingPlatformTypeAssetsSuccessAction();
    });
  else {
    return switchMap((data: { items: IPlatformAsset[] }) => {
      notifyAssetPostSuccess(data.items);

      const checklist = state$.value.me.data?.checklist?.insights;
      const isAddAssetsChecked = !!checklist?.items.find(
        ({ id, checked }) => id === "add_assets" && checked,
      );

      // Update checklist
      if (checklist && !isAddAssetsChecked) {
        return [
          postOnboardingPlatformTypeAssetsSuccessAction(),
          patchChecklistAction(checklist, "add_assets"),
        ];
      }

      return [postOnboardingPlatformTypeAssetsSuccessAction()];
    });
  }
};

export const getOnboardingPlatformTypes = (
  action$: Observable<OnboardingPlatformTypesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_ONBOARDING_PLATFORM_TYPES)),
    switchMap(a => {
      return ajax
        .get<{ items: IOnboardingPlatformItem[] }>(
          `${assetsOnboarding}/${a.payload}/assets`,
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getOnboardingPlatformTypesSuccessAction(data.items)),
          catchError(e => {
            if (e.name === "TimeoutError") {
              addToast({
                type: "danger_accent",
                title: i18nextInstance.t("pages:addAssets:timeoutErrorTitle"),
                message: getAssetsTimeoutErrorMessage(),
              });

              return of(getOnboardingPlatformTypesFailureAction());
            }

            return handleError(e, getOnboardingPlatformTypesFailureAction);
          }),
        );
    }),
  );

export const postOnboardingPlatformTypeAssets = (
  action$: Observable<OnboardingPlatformTypesActionsType>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_ONBOARDING_PLATFORM_TYPE_ASSETS)),
    switchMap(a => {
      return ajax
        .post<{ items: IPlatformAsset[] }>(
          `${assetsOnboarding}/assets`,
          normalizePostAssetPayload(a.payload.assets, a.payload.webhook_active),
          getHeaders({
            Authorization: getUserAccessToken(state$),
          }),
        )
        .pipe(
          map(e => e.response),
          getOnboardingPlatformTypesAction(state$),
          catchError(e => handleError(e, postOnboardingPlatformTypeAssetsFailureAction)),
        );
    }),
  );
