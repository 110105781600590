import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import { IPieChartLabel } from "@bbdevcrew/bb_ui_kit_fe";

interface IPieChartWidgetLabelProps {
  color: string;
  value: number;
  labelProps: IPieChartLabel;
  hasLabel?: boolean;
}

const PieChartWidgetLabel: FC<IPieChartWidgetLabelProps> = ({
  labelProps,
  color,
  value,
  hasLabel = true,
}) => {
  const { t } = useTranslation();
  const cx = +(labelProps.cx || 0);
  const cy = +(labelProps.cy || 0);
  const yCoords = [cy - 9, cy + 9];

  return (
    <g>
      <text
        x={cx}
        y={hasLabel ? yCoords[0] : cy}
        dy={4}
        textAnchor="middle"
        fill={color}
        fontSize={18}
        fontWeight={"bold"}
      >
        {numberFormatter(value)}
      </text>
      {hasLabel && (
        <text x={cx} y={yCoords[1]} dy={4} textAnchor="middle" fill="#333" fontSize={14}>
          {labelProps.label || t("generic:conversations")}
        </text>
      )}
    </g>
  );
};

export default PieChartWidgetLabel;
