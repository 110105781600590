import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Grid } from "antd";

import s from "./ProfilesTable.module.less";

import { IProfilesTableCommonProps, ProfilesTableProps } from "./ProfilesTable.types";
import {
  ProfilesFollowersTable,
  ProfilesImpressionsPlatformTable,
  ProfilesImpressionsProfileTable,
} from "./ProfilesTables";
import { getProfilesColumnsWithKeys, PAGE_SIZE_OPTIONS } from "./ProfilesTable.helpers";

export const ProfilesTableWrapper: FC<ProfilesTableProps> = ({ data, loading, total, type }) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const columns = useMemo(() => getProfilesColumnsWithKeys(type), [type]);

  const showSizeChanger = !!screens.lg;

  const props: IProfilesTableCommonProps = {
    withStickyHeader: true,
    emptyTableText: t("generic:emptyTable"),
    pageOutOfText: [
      t("components:sortableTable:pageOutOfPart1"),
      t("components:sortableTable:pageOutOfPart2"),
    ],
    tableClassName: s.bbProfilesTable,
    containerClassName: s.bbProfilesTableContainer,
    loading,
    columns,
    pagination: {
      showSizeChanger,
      pageSizeOptions: PAGE_SIZE_OPTIONS,
      pageSizeTitle: t("components:sortableTable:pageSizeTitle"),
    },
  };

  return (
    <>
      {type === "followers" && <ProfilesFollowersTable data={data} total={total} {...props} />}
      {type === "impressions-platform" && (
        <ProfilesImpressionsPlatformTable data={data} total={total} {...props} />
      )}
      {type === "impressions-profile" && (
        <ProfilesImpressionsProfileTable data={data} total={total} {...props} />
      )}
    </>
  );
};
