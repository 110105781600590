import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import React, { FC, useState, useCallback, useEffect } from "react";

import { Form } from "antd";
import { Input, Button, Tooltip, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";
import AIPromptSection from "../../AIPrompts/aiPromptsSection/AIPromptSection";

import {
  createPromptAction,
  updatePromptAction,
  clearPromptAction,
} from "@store/repliesAIPrompts/actions";
import {
  creatingAIPromptSelector,
  updatingAIPromptSelector,
  createdAIPromptSuccessfulSelector,
  updatedAIPromptSuccessfulSelector,
} from "@store/repliesAIPrompts/selectors";

import s from "./AIPromptForm.module.less";

import { IAIPromptFormProps } from "./AIPromptForm.type";
import { IAIPrompt } from "@store/repliesAIPrompts/types";
import { getAIPromptSectionsConfig } from "./useAIPromptSectionsConfig";

import { ChevronLeftIcon, HelpIcon } from "@bbdevcrew/bb_ui_kit_fe";

const AIPromptForm: FC<IAIPromptFormProps> = ({ mode, editItem, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm<IAIPrompt>();
  const sections = editItem ? getAIPromptSectionsConfig(editItem) : getAIPromptSectionsConfig();

  const [isDefault, setIsDefault] = useState(false);

  const creatingAIPrompt = useSelector(creatingAIPromptSelector);
  const updatingAIPrompt = useSelector(updatingAIPromptSelector);
  const createdAIPrompt = useSelector(createdAIPromptSuccessfulSelector);
  const updatedAIPrompt = useSelector(updatedAIPromptSuccessfulSelector);

  const clearPrompt = useCallback(() => dispatch(clearPromptAction()), [dispatch]);

  const createPrompt = useCallback(
    (prompt: IAIPrompt) => dispatch(createPromptAction(prompt)),
    [dispatch],
  );

  const updatePrompt = useCallback(
    (prompt: IAIPrompt) => dispatch(updatePromptAction(prompt)),
    [dispatch],
  );

  useEffect(() => {
    if (mode === "edit" && editItem) {
      form.setFieldsValue(editItem);
      setIsDefault(editItem.is_default);
    }
    // eslint-disable-next-line
  }, [mode, editItem]);

  useEffect(() => {
    if (createdAIPrompt || updatedAIPrompt) {
      clearPrompt();
      onCancel();
    }
    // eslint-disable-next-line
  }, [createdAIPrompt, updatedAIPrompt]);

  const onFinish = (values: IAIPrompt) => {
    if (mode === "create") {
      createPrompt({ ...values, is_default: isDefault });
    } else if (mode === "edit" && editItem) {
      updatePrompt({ ...values, is_default: isDefault, id: editItem.id });
    }
  };

  const onSetAsDefaultChange = () => {
    if (!isDefault) {
      form.resetFields(["is_default"]);
    } else {
      form.setFieldsValue({ is_default: true });
    }

    setIsDefault(!isDefault);
  };

  return (
    <>
      <div className={s.bbAIPromptFormHeader}>
        <Button _type="link" className={s.bbAIPromptFormHeaderBack} onClick={onCancel}>
          <ChevronLeftIcon />
        </Button>
        <div className={s.bbAIPromptFormHeaderTitle}>{t(`components:AIPrompts:${mode}:title`)}</div>
      </div>
      <div data-cy="edit-AI-prompt-config" className={s.bbAIPromptFormConfig}>
        <Form
          form={form}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          requiredMark={false}
          scrollToFirstError
          className={s.bbAIPromptFormConfigForm}
        >
          <Form.Item
            name="name"
            data-cy="AIPrompt-name"
            label={t("components:AIPrompts:edit:name:label")}
            rules={[
              {
                required: true,
                message: t("components:AIPrompts:edit:name:error"),
              },
            ]}
          >
            <Input _size="md" data-cy="AIPrompts-name-input" />
          </Form.Item>

          <div className={s.bbSetAsDefaultFormItem}>
            <span>
              <Checkbox
                id="is_default"
                name="is_default"
                checked={isDefault}
                onChange={onSetAsDefaultChange}
                data-cy="AI-prompt-is_default"
              />

              <span className={s.bbSetAsDefaultTitle}>
                {t("components:AIPrompts:edit:setAsDefault")}
              </span>
              <Tooltip title={t("components:AIPrompts:edit:setAsDefaultTooltip")}>
                <span className={s.bbSetAsDefaultHelpIcon}>
                  <HelpIcon />
                </span>
              </Tooltip>
            </span>
          </div>

          <div className={s.bbPromptSettingsTitle}>
            {t("components:AIPrompts:edit:structureSectionName")}
          </div>

          {sections.map((section, index) => (
            <AIPromptSection key={index} form={form} {...section} />
          ))}

          <Form.Item>
            <div className={s.bbAIPromptFormConfigFormActions}>
              <Button _type="secondary" onClick={onCancel}>
                {t("generic:discard")}
              </Button>
              <Button _type="primary" type="submit" loading={creatingAIPrompt || updatingAIPrompt}>
                {t("generic:save")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default AIPromptForm;
