import cn from "classnames/bind";
import React, { useState } from "react";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./ReplyImagePreview.module.less";

import { IReplyImagePreviewProps } from "./ReplyImagePreview.type";

const cx = cn.bind(s);

export const ReplyImagePreview = ({
  isUploadingReplyImage,
  replyImageURL,
  clearReplyImage,
}: IReplyImagePreviewProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <div
      className={cx(s.bbReplyImageWrapper, {
        [s.bbReplyImageWrapperWithLoading]: isUploadingReplyImage,
      })}
    >
      <img src={replyImageURL} className={s.bbReplyImage} onLoad={() => setIsImageLoaded(true)} />
      {isImageLoaded && (
        <span className={s.bbRemoveReplyImage} onClick={clearReplyImage}>
          <CloseIcon />
        </span>
      )}
    </div>
  );
};
