import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TiktokLoginButton.module.less";

import { TiktokColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

export interface ITiktokLoginButtonProps {
  isLoading?: boolean;
  onClickLogin: (event: MouseEvent) => void;
}

const TiktokLoginButton: FC<ITiktokLoginButtonProps> = ({ isLoading, onClickLogin }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbTiktokLoginButtonWrapper} onClick={isLoading ? undefined : onClickLogin}>
      {isLoading ? (
        <Loading type="spinning" isLoading />
      ) : (
        <div className={s.bbTiktokLoginButton}>
          <TiktokColorIcon />
          <span>{t("components:signInButtons:tiktok")}</span>
        </div>
      )}
    </div>
  );
};

export default TiktokLoginButton;
