import React from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import { isPrivateConversation } from "@utils/comments";

import { IComment, IDirectMessageStats } from "@store/comments/types";

import s from "./CommentThreadCount.module.less";

interface ICommentThreadCountProps {
  comment: IComment;
  onReplyClick?: (commentId: string) => void;
}

const CommentThreadCountTooltip: React.FC<ICommentThreadCountProps> = ({ comment }) => {
  const { t } = useTranslation();
  const statKeys: Array<keyof IDirectMessageStats> = [
    "private_message_total_count",
    "story_mention_total_count",
    "story_reply_total_count",
  ];
  const stats = statKeys
    .filter(statsKey => {
      return comment.dm_stats && comment.dm_stats[statsKey] && comment.dm_stats[statsKey] > 0;
    })
    .map(statsKey => {
      return (
        <>
          {comment.dm_stats && comment.dm_stats[statsKey]}{" "}
          {t(`components:comments:tooltips:threadCount:${statsKey}`)}
        </>
      );
    });

  return (
    <>
      {stats.map((stat, index) => (
        <>
          {stat}
          {index < stats.length - 1 && <br />}
        </>
      ))}
    </>
  );
};

const CommentThreadCount: React.FC<ICommentThreadCountProps> = ({ comment, onReplyClick }) => {
  const showDirectMessageStats =
    comment.dm_stats && comment.post_placement && isPrivateConversation(comment.post_placement);

  return showDirectMessageStats ? (
    <Tooltip title={<CommentThreadCountTooltip comment={comment} />}>
      <div
        className={s.bbCommentThreadCount}
        onClick={() => comment?.id && onReplyClick && onReplyClick(comment?.replying_comment_id)}
      >
        {comment?.dm_stats?.grand_total_count}
      </div>
    </Tooltip>
  ) : null;
};

export default CommentThreadCount;
