import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { postShortenedSearchesAction } from "@store/shortenedSearches/actions";
import { filterDataSelector } from "@store/filters/selectors";

import { IReportPreview } from "./ShareReportButton.types";
import { ReportSectionType } from "@components/_common/DownloadReportModal/DownloadReportModal.types"; // eslint-disable-line max-len

export const reportSections: ReportSectionType[] = [
  "sentiment_preview",
  "action_summary",
  "bb_engage",
  "bb_protect",
];

export const useShortenedSearchesId = () => {
  const dispatch = useDispatch();
  const filters = useSelector(filterDataSelector);

  const urlParams = new URLSearchParams(window.location.search);
  const searchId = urlParams.get("id");
  const savedFilterId = urlParams.get("saved_filter");

  useEffect(() => {
    if (!searchId && !savedFilterId) {
      dispatch(postShortenedSearchesAction({ filters: filters || {} }));
    }
  }, [searchId, savedFilterId, dispatch, filters]);

  return { id: searchId, savedFilterId };
};

export const openReportPreview = async ({
  path,
  id,
  saved_filter_id,
  name,
  sections,
}: IReportPreview) => {
  const url = new URL(path, window.location.origin);
  url.searchParams.append("name", name);
  url.searchParams.append("mode", "download");
  url.searchParams.append("sections", sections.join(","));

  if (id) url.searchParams.append("id", id);
  if (saved_filter_id) url.searchParams.append("saved_filter", saved_filter_id);

  window.open(url, "_blank");
};

export const forceFileDownload = (url: string, filename: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
