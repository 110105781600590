import dayjs, { Dayjs } from "dayjs";
import classNames from "classnames/bind";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Holidays from "./Holidays";
import { Button } from "@bbdevcrew/bb_ui_kit_fe";
import PublishingPostComponent from "./post/PublishingPost";
import CalendarContext from "./context/CalendarContext";
import ButtonPermissionGuard from "../../_common/ButtonPermissionGuard/ButtonPermissionGuard";

import s from "./DayOfWeek.module.less";

import { IHoliday, IPublishingPost } from "@store/publishings/types";

import { PlusIcon } from "@assets/index";

interface IDayOfWeekProps {
  day: Dayjs;
  index: number;
  posts: IPublishingPost[];
  holidays: IHoliday[];
}

export default function DayOfWeek({ day, index, posts, holidays }: IDayOfWeekProps) {
  const { t } = useTranslation();

  const { setDaySelected, setIsPostModalOpen } = useContext(CalendarContext);

  const onCreatePostClick = () => {
    setIsPostModalOpen(true);
    setDaySelected(day);
  };

  return (
    <div className={s.bbWeekDay}>
      <header
        className={classNames(s.bbWeekDayHeader, {
          [s.bbWeekDayHeaderMonday]: index === 0,
        })}
      >
        <p
          className={classNames(s.bbWeekDayHeaderDay, {
            [s.bbWeekDayHeaderDaySelected]: day.format("DD-MM-YY") === dayjs().format("DD-MM-YY"),
          })}
        >
          {day.format("ddd")}
        </p>
        <p className={s.bbWeekDayHeaderDate}>{day.format("DD")}</p>
        {!!posts.length && <div className={s.bbWeekDayHeaderPostNumber}>{posts.length}</div>}
      </header>
      <div
        className={classNames(s.bbWeekDayBody, {
          [s.bbWeekDayBodyFuture]: day.isAfter(dayjs()) || day.isSame(dayjs(), "day"),
        })}
      >
        {!!holidays.length && <Holidays holidays={holidays} />}

        {posts.map(post => (
          <PublishingPostComponent key={post.id} post={post} />
        ))}
        <ButtonPermissionGuard permission="canCreatePost" message={t("permissions:createPost")}>
          <Button _size="sm" onClick={onCreatePostClick} className={s.bbCreatePostBtn}>
            <span>
              <PlusIcon />
            </span>
            <span>{t("components:publish:calendar:createPost")}</span>
          </Button>
        </ButtonPermissionGuard>
      </div>
    </div>
  );
}
