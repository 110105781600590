import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { TikTokSubscriptionPricingTable } from "./TikTokSubscriptionPricingTable";

import { isTiktokApp } from "@utils/isTiktokApp";
import { meSelector } from "@store/me/selectors";

import s from "./SubscriptionModal.module.less";

import { ArrowDownCurvedIcon } from "@assets/index";

const SubscriptionPricingTable = ({ isVisible }: { isVisible: boolean }) => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  return (
    <div>
      <div className={s.bbSubscriptionPricingTableHeader}>
        <h2 className={s.bbSubscriptionPricingTableHeaderTitle}>
          {t("components:subscriptionModal:header")}
        </h2>
        <div className={s.bbSubscriptionPricingTableHeaderDiscount}>
          <p className={s.bbSubscriptionPricingTableHeaderDiscountText}>
            {t("components:subscriptionModal:discountText")}
          </p>
          <p className={s.bbSubscriptionPricingTableHeaderDiscountIcon}>
            <ArrowDownCurvedIcon />
          </p>
        </div>
      </div>

      {isTiktokApp() ? (
        <TikTokSubscriptionPricingTable isVisible={isVisible} />
      ) : (
        <stripe-pricing-table
          customer-email={me?.email}
          pricing-table-id={me?.static_data.stripe_pricing_table_id}
          client-reference-id={me?.client?.subscription?.stripe_customer_id}
          publishable-key={me?.static_data.stripe_publishable_key}
        />
      )}
    </div>
  );
};

export default SubscriptionPricingTable;
