import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { ReplyModalViewType, CategoryType } from "./ReplyModal.type";

import s from "./ReplyModal.module.less";

import { Tooltip, InformationIcon, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";

export interface IBreadCrumbsProps {
  currentView: ReplyModalViewType;
  onClick: () => void;
  authorName: string;
  category: CategoryType;
}

export const Breadcrumbs = ({ currentView, onClick, authorName, category }: IBreadCrumbsProps) => {
  const { t } = useTranslation();

  const areExtendedBreadcrumbs = currentView === "details" && category !== "private_messages";
  const title = t(`components:reply:${category}:title`, { author: authorName });
  const tooltip = t(`components:reply:${category}:tooltip`, {
    author: authorName,
  });
  const details = t(`components:reply:${category}:details`);

  return (
    <React.Fragment>
      <Tooltip title={tooltip || ""} placement="bottom">
        <div className={s.bbCategoryTitleDefault}>
          <span
            className={classNames(s.bbCategoryTitleText, {
              [s.bbCategoryTitleTextLink]: areExtendedBreadcrumbs,
            })}
            onClick={areExtendedBreadcrumbs ? onClick : undefined}
          >
            {title}
          </span>
          <div className={s.bbCategoryTitleIcon}>
            <InformationIcon />
          </div>
        </div>
      </Tooltip>
      {areExtendedBreadcrumbs && (
        <div className={s.bbCategoryDetails}>
          <div className={s.bbCategoryDetailsIcon}>
            <ChevronRightIcon />
          </div>
          <div className={s.bbCategoryDetailsText}>{details}</div>
        </div>
      )}
    </React.Fragment>
  );
};
