import classNames from "classnames";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Icon, Button, Badge, Tooltip, UserAvatar, ITriggerProps } from "@bbdevcrew/bb_ui_kit_fe";

import { generateTooltip } from "./helpers";
import { IUser } from "@store/users/types";
import { meSelector } from "@store/me/selectors";

import s from "./AssignUser.module.less";

import { UserPlusIcon } from "@assets/index";
import { ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IAssignUserTriggerProps extends ITriggerProps {
  users: IUser[];
  className?: string;
  placeholder?: string;
  hideTriggerPlaceholder: boolean;
}

interface ITriggerContentProps {
  value?: string[];
  users: IUser[];
  hideTriggerPlaceholder: boolean;
}

const Content = React.memo(({ value, users, hideTriggerPlaceholder }: ITriggerContentProps) => {
  const { t } = useTranslation();

  if (!value?.length) {
    return <Icon className={s.bbAssignUserTriggerIcon} icon={UserPlusIcon} />;
  }

  const user = users.find(({ id }) => id === value[0]);
  let props: { img?: string; lastName?: string; firstName?: string } = {};

  if (value.length === 1) {
    props = {
      img: user?.avatar,
      lastName: value.length > 1 ? undefined : user?.last_name,
      firstName: value.length > 1 ? undefined : user?.first_name,
    };
  }

  return (
    <>
      <UserAvatar
        className={classNames({
          [s.bbAssignUserAvatarName]: props.firstName || props.lastName,
        })}
        {...props}
      />

      {!hideTriggerPlaceholder && (
        <span className={s.bbAssignUserTriggerPlaceholder}>{t("generic:assigned")}</span>
      )}

      {value.length > 1 && <Badge text={value.length} className={s.bbAssignUserTriggerBadge} />}
    </>
  );
});

Content.displayName = "AssignUserTriggerContent";

export function Trigger({
  id,
  value,
  _size,
  isOpen,
  onClick,
  users = [],
  placeholder,
  className,
  hideTriggerPlaceholder,
}: IAssignUserTriggerProps) {
  const me = useSelector(meSelector);

  const assignTooltip = useMemo(
    () => generateTooltip(value || [], users, me?.id),
    // eslint-disable-next-line
    [users.length, value?.length, me],
  );

  return (
    <Tooltip title={assignTooltip}>
      <Button id={id} _size={_size} onClick={onClick} className={className}>
        <Content value={value} users={users} hideTriggerPlaceholder={hideTriggerPlaceholder} />
        {placeholder && !hideTriggerPlaceholder ? (
          <span className={s.bbAssignUserTriggerPlaceholder}>{placeholder}</span>
        ) : null}
        <Icon
          _size="xs"
          className={s.bbAssignUserTriggerChevronIcon}
          icon={isOpen ? ChevronUpIcon : ChevronDownIcon}
        />
      </Button>
    </Tooltip>
  );
}
