import cn from "classnames/bind";
import { useSelector } from "react-redux";
import React, { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Menu } from "antd";
import UpgradePlanButton from "./UpgradePlanButton";
import ExtraMenuItem, { Divider } from "./ExtraMenuItem";
import TrialTimeLeftCircle from "./TrialTimeLeftCircle";
import { ChangeClient, ChangeClientTitle } from "./ChangeClient";

import { meSelector } from "@store/me/selectors";

import s from "./ExtraMenu.module.less";

import { daysFromNow } from "@utils/dates";
import { useTrialSubscription } from "@utils/useTrialSubscription";
import { IExtraItem, IExtraMenu } from "./AppHeader.type";

const cx = cn.bind(s);

const AppHeaderExtraMenu: FC<IExtraMenu> = ({
  extra,
  basename,
  selectedKey,
  callbackItemClick,
  defaultSelectedKey,
  onSubscriptionModalOpen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const me = useSelector(meSelector);
  const isTrialSubscription = useTrialSubscription();

  const trialDaysLeft = daysFromNow(new Date(me?.client?.subscription?.period_end || ""));

  const onExtraItemClick = (item: IExtraItem, base: boolean) => {
    if (callbackItemClick && typeof callbackItemClick === "function") callbackItemClick();

    if (item.basename) {
      base
        ? navigate({
            pathname: `/${item.id}`,
          })
        : (window.location.href = `${item.url}/${item.id}`);
    } else if (item.external) {
      window.open(item.external, "_blank");
    } else if (item.action && typeof item.action === "function") {
      return item.action(item.id);
    }
  };

  const getUpgradePlanCTA = (item: IExtraItem) => (
    <>
      {isTrialSubscription ? (
        <>
          <Menu.Item key={item.id} className={s[item.id]}>
            <div className={s.bbSubscriptionUpgrade}>
              <div className={s.bbSubscriptionUpgradeCircleWrapper}>
                <TrialTimeLeftCircle trialPeriodEnd={me?.client?.subscription?.period_end || ""} />
                <span className={s.bbSubscriptionUpgradeTrialEndsText}>
                  {t("header:trialEndsText", { count: trialDaysLeft })}
                </span>
              </div>
              <div className={s.bbSubscriptionUpgradeBtn}>
                <UpgradePlanButton onClick={onSubscriptionModalOpen} />
              </div>
            </div>
          </Menu.Item>
          <Divider id={item.id} />
        </>
      ) : null}
    </>
  );

  const isChangeClient = (item: IExtraItem) => item.id === "change-client";

  const menuItem = (item: IExtraItem) => (
    <Menu.Item
      key={item.id}
      className={cx(s.bbExtraMenuItem, s[item.id])}
      onClick={() => onExtraItemClick(item, basename === item.basename)}
      data-stonly-target={`app-global__my-account-menu--${item.id}`}
    >
      <ExtraMenuItem item={item} />
    </Menu.Item>
  );

  const subMenuItem = (item: IExtraItem, title?: React.ReactNode, children?: React.ReactNode) => (
    <Menu.SubMenu
      key={item.id}
      title={title}
      popupClassName={s.bbExtraMenuPopup}
      data-stonly-target={`app-global__my-account-menu--${item.id}`}
      className={cx(s.bbExtraMenuItem, s[item.id])}
    >
      {children}
    </Menu.SubMenu>
  );

  return (
    <Menu
      className={s.bbExtraMenu}
      selectedKeys={selectedKey?.split(",")}
      defaultSelectedKeys={defaultSelectedKey?.split(",")}
      subMenuCloseDelay={0.5}
    >
      {extra?.map(item => (
        <Fragment key={item.id}>
          {item.id === "upgrade-plan" ? (
            <>{getUpgradePlanCTA(item)}</>
          ) : (
            <>
              {item.includeDividerBefore && <Divider id={item.id} />}
              {!isChangeClient(item)
                ? menuItem(item)
                : subMenuItem(item, <ChangeClientTitle />, <ChangeClient />)}
              {item.includeDividerAfter && <Divider id={item.id} />}
            </>
          )}
        </Fragment>
      ))}
    </Menu>
  );
};

export default AppHeaderExtraMenu;
