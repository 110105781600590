import { createReducer, ActionType } from "typesafe-actions";
import { IPostTagsState } from "./types";
import {
  getPostTagsSuccessAction,
  createPostTagSuccessAction,
  deleteTagFromPostAction,
  createPostTagAction,
} from "./actions";
import {
  GET_POST_TAGS,
  GET_POST_TAGS_SUCCESS,
  GET_POST_TAGS_FAILURE,
  ADD_POST_TAG,
  ADD_POST_TAG_SUCCESS,
  ADD_POST_TAG_FAILURE,
  DELETE_POST_TAG,
  DELETE_POST_TAG_SUCCESS,
  DELETE_POST_TAG_FAILURE,
  DELETE_TAG_FROM_POST,
  DELETE_TAG_FROM_POST_SUCCESS,
  DELETE_TAG_FROM_POST_FAILURE,
} from "./actionTypes";

const initialState = {
  postTags: [],
  fetchingPostTags: false,
  fetchedPostTags: false,
  fetchedPostTagsFail: false,
  postingPostTag: false,
  postingPostTagLabel: undefined,
  postedPostTag: false,
  postedPostTagResult: undefined,
  postedPostTagFail: false,
  deletingTag: false,
  deletedTag: false,
  deletedTagFail: false,
  deletingTagFromPost: false,
  deletedTagFromPost: false,
  deletedTagFromPostId: undefined,
  deletedTagFromPostFail: false,
};

export const postTagsReducer = createReducer<IPostTagsState>(initialState, {
  // GET_POST_TAGS
  [GET_POST_TAGS]: (state: IPostTagsState) => ({
    ...state,
    fetchingPostTags: true,
    fetchedPostTags: false,
    fetchedPostTagsFail: false,
  }),
  [GET_POST_TAGS_SUCCESS]: (
    state: IPostTagsState,
    action: ActionType<typeof getPostTagsSuccessAction>,
  ) => ({
    ...state,
    postTags: action.payload.items,
    fetchingPostTags: false,
    fetchedPostTags: true,
    fetchedPostTagsFail: false,
  }),
  [GET_POST_TAGS_FAILURE]: (state: IPostTagsState) => ({
    ...state,
    fetchingPostTags: false,
    fetchedPostTags: false,
    fetchedPostTagsFail: true,
  }),

  // CREATE_POST_TAG
  [ADD_POST_TAG]: (state: IPostTagsState, action: ActionType<typeof createPostTagAction>) => ({
    ...state,
    postingPostTag: true,
    postingPostTagLabel: action.payload.label,
    postedPostTag: false,
    postedPostTagResult: undefined,
    postedPostTagFail: false,
  }),
  [ADD_POST_TAG_SUCCESS]: (
    state: IPostTagsState,
    action: ActionType<typeof createPostTagSuccessAction>,
  ) => ({
    ...state,
    postingPostTag: false,
    postingPostTagLabel: undefined,
    postedPostTag: true,
    postedPostTagResult: action.payload.tag,
    postedPostTagFail: false,
  }),
  [ADD_POST_TAG_FAILURE]: (state: IPostTagsState) => ({
    ...state,
    postingPostTag: false,
    postingPostTagLabel: undefined,
    postedPostTag: false,
    postedPostTagResult: undefined,
    postedPostTagFail: true,
  }),

  // DELETE_CUSTOM_TAG
  [DELETE_POST_TAG]: (state: IPostTagsState) => ({
    ...state,
    deletingTag: true,
    deletedTag: false,
    deletedTagFail: false,
  }),
  [DELETE_POST_TAG_SUCCESS]: (state: IPostTagsState) => ({
    ...state,
    deletingTag: false,
    deletedTag: true,
    deletedTagFail: false,
  }),
  [DELETE_POST_TAG_FAILURE]: (state: IPostTagsState) => ({
    ...state,
    deletingTag: false,
    deletedTag: false,
    deletedTagFail: true,
  }),

  // DELETE_TAG_FROM_POST
  [DELETE_TAG_FROM_POST]: (state: IPostTagsState) => ({
    ...state,
    deletingTagFromPost: true,
    deletedTagFromPost: false,
    deletedTagFromPostId: undefined,
    deletedTagFromPostFail: false,
  }),
  [DELETE_TAG_FROM_POST_SUCCESS]: (
    state: IPostTagsState,
    action: ActionType<typeof deleteTagFromPostAction>,
  ) => ({
    ...state,
    deletingTagFromPost: false,
    deletedTagFromPost: true,
    deletedTagFromPostId: action.payload.post_tag_id,
    deletedTagFromPostFail: false,
  }),
  [DELETE_TAG_FROM_POST_FAILURE]: (state: IPostTagsState) => ({
    ...state,
    deletingTagFromPost: false,
    deletedTagFromPost: false,
    deletedTagFromPostId: undefined,
    deletedTagFromPostFail: true,
  }),
});
