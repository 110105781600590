import cn from "classnames/bind";
import React, { FC, Fragment } from "react";
import s from "./AssetTab.module.less";

import { IAssetTabProps, generateLabel } from "./AssetTab.type";

const cx = cn.bind(s);

export const AssetTab: FC<IAssetTabProps> = ({ id, label, description, isActive }) => {
  return (
    <div
      className={cx(s.bbAssetTab, `bbAssetTab-${id}`)}
      data-stonly-target={`settings-assets__add-assets--tab-${id}`}
    >
      <div
        className={cx(s.bbAssetTabIconWrapper, {
          [s.bbAssetTabActive]: isActive,
        })}
      >
        {generateLabel(id, label).map(({ label: assetTabLabel, PlatformIcon }) => (
          <Fragment key={assetTabLabel}>{PlatformIcon}</Fragment>
        ))}
      </div>
      <div className={s.bbAssetTabLabelWrapper}>
        <span className={s.bbAssetTabLabel}>
          {label.includes("&") ? (
            <span>
              {label.split("&")[0]}
              <br />&{label.split("&")[1]}
            </span>
          ) : (
            label
          )}
        </span>
        {description && <span className={s.bbAssetTabDescription}>{description}</span>}
      </div>
    </div>
  );
};
