import { numberToQuantity } from "@bbdevcrew/bb_ui_kit_fe";

export const areas = [
  {
    name: "value",
    label: "Comments",
    color: "#23d7b6",
  },
];

export const responsiveProps = {
  height: 50,
};

export const XAxisProps = {
  hide: true,
  dataKey: "name",
};

export const YAxisProps = {
  hide: true,
};

export const formatter = (value: number | string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const calculatePreviousValue = (prevValue: number, value: number) => {
  const v = (value - prevValue) as number;
  let result = "";

  if (v >= 0) result = `+${numberToQuantity(v)}`;
  else result = `${numberToQuantity(v)}`;

  return formatter(result);
};

export const calculatePreviousValuePercent = (prevValue: number, value: number) => {
  let percent;
  const a = prevValue;
  const b = value;

  if (b !== 0) {
    if (a !== 0) percent = ((b - a) / a) * 100;
    else percent = 100;
  } else {
    percent = -a * 100;
  }

  if (percent <= -100) {
    percent = -100;
  }

  const str = numberToQuantity(percent);

  return `${percent >= 0 ? "+" : ""}${
    str.lastIndexOf(".") !== -1
      ? str.slice(0, str.lastIndexOf(".") + (/K|M|B/g.test(str) ? 3 : 2))
      : str
  }%`;
};
