import { action } from "typesafe-actions";
import {
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  PUT_USER,
  PUT_USER_SUCCESS,
  PUT_USER_FAILURE,
  PATCH_USER_STATUS,
  PATCH_USER_STATUS_SUCCESS,
  PATCH_USER_STATUS_FAILURE,
  POST_USER,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
} from "./actionTypes";
import { IUser, IUsersQueryParam } from "./types";

export const getUsersAction = (params: IUsersQueryParam = {}) => action(GET_USERS, params);
export const getUsersSuccessAction = (users: IUser[]) => action(GET_USERS_SUCCESS, users);
export const getUsersFailureAction = () => action(GET_USERS_FAILURE);

export const putUserAction = (user: Partial<IUser>) => action(PUT_USER, user);
export const putUserSuccessAction = (user: IUser) => action(PUT_USER_SUCCESS, user);
export const putUserFailureAction = () => action(PUT_USER_FAILURE);

export const patchUserStatusAction = (id: string, status: boolean) =>
  action(PATCH_USER_STATUS, {
    id,
    status,
  });
export const patchUserStatusSuccessAction = (user: IUser) =>
  action(PATCH_USER_STATUS_SUCCESS, user);
export const patchUserStatusFailureAction = () => action(PATCH_USER_STATUS_FAILURE);

export const postUserAction = (user: IUser) => action(POST_USER, user);
export const postUserSuccessAction = (user: IUser) => action(POST_USER_SUCCESS, user);
export const postUserFailureAction = () => action(POST_USER_FAILURE);
