export const GET_USERS = "USERS/GET_USERS";
export const GET_USERS_SUCCESS = "USERS/GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "USERS/GET_USERS_FAILURE";

export const PUT_USER = "USERS/PUT_USER";
export const PUT_USER_SUCCESS = "USERS/PUT_USER_SUCCESS";
export const PUT_USER_FAILURE = "USERS/PUT_USER_FAILURE";

export const PATCH_USER_STATUS = "USERS/PATCH_USER_STATUS";
export const PATCH_USER_STATUS_SUCCESS = "USERS/PATCH_USER_STATUS_SUCCESS";
export const PATCH_USER_STATUS_FAILURE = "USERS/PATCH_USER_STATUS_FAILURE";

export const POST_USER = "USERS/POST_USER";
export const POST_USER_SUCCESS = "USERS/POST_USER_SUCCESS";
export const POST_USER_FAILURE = "USERS/POST_USER_FAILURE";
