import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, useState } from "react";

import s from "./RequireNewPassword.module.less";
import globalStyles from "../index.module.less";

import { Form } from "antd";
import FormHeader from "../formHeader/FormHeader";
import { Button, InputPassword } from "@bbdevcrew/bb_ui_kit_fe";
import { PasswordChecker } from "../passwordChecker/PasswordChecker";
import { TermsConditions } from "../termsConditions/TermsConditions";

import { validatePsw } from "../passwordChecker/helpers";
import { IFormError } from "../confirmSignIn/ConfirmSignIn.type";
import { IFormValues, IRequireNewPasswordProps } from "./RequireNewPassword.type";

const cx = cn.bind(s);

const RequireNewPassword: React.FC<IRequireNewPasswordProps> = ({
  onFinish,
  onFinishFailed,
  handleInputChange,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<IFormValues>();

  const [loading, setLoading] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(event.target.value);

    if (handleInputChange) {
      handleInputChange(event);
    }
  };

  const callBackError = () => {
    setLoading(false);
  };

  const onSubmit = (values: IFormValues) => {
    setLoading(true);
    return onFinish && onFinish(values, callBackError);
  };

  const onSubmitFailed = (errorInfo: IFormError<IFormValues>) =>
    onFinishFailed && onFinishFailed(errorInfo);

  return (
    <div className={s.bbRequireNewPasswordContainer}>
      <Form
        form={form}
        name="signIn"
        layout="vertical"
        onFinish={onSubmit}
        onFinishFailed={onSubmitFailed}
        className={cx(s.bbRequireNewPasswordForm, globalStyles.bbFormXL)}
      >
        <FormHeader title={t("components:requireNewPassword:title")} />
        <Form.Item
          validateTrigger="onBlur"
          name="requireNewPassword"
          label={t("components:requireNewPassword:newPassword")}
          rules={[
            {
              required: true,
              message: t("components:forgotPassword:newPswMessage"),
            },
            ({}) => ({
              validator(rule, value) {
                const { isValid } = validatePsw(value);

                if (isValid) {
                  return Promise.resolve();
                }
                return Promise.reject(t("pages:signup:step1:left:form:password:pattern"));
              },
            }),
          ]}
        >
          <InputPassword
            autoFocus
            maxLength={100}
            onChange={onChange}
            id="requireNewPassword"
            name="requireNewPassword"
            hideMaxLengthChip
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {_form => (
            <PasswordChecker
              value={passwordValue}
              isValidated={!!_form.getFieldError("requireNewPassword")[0]}
            />
          )}
        </Form.Item>
        <Form.Item
          validateTrigger="onBlur"
          name="confirmRequireNewPassword"
          label={t("components:requireNewPassword:confirmNewPassword")}
          rules={[
            {
              required: true,
              message: t("components:forgotPassword:confirmPswMessage"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("requireNewPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("components:forgotPassword:matchMessage"));
              },
            }),
          ]}
        >
          <InputPassword
            maxLength={100}
            autoComplete="new-password"
            id="confirmRequireNewPassword"
            key="confirmRequireNewPassword"
            name="confirmRequireNewPassword"
            hideMaxLengthChip
          />
        </Form.Item>
        <Form.Item>
          <Button _type="primary" type="submit" loading={loading} className={globalStyles.buttonXL}>
            {t("components:requireNewPassword:submitLabel")}
          </Button>
        </Form.Item>
        <TermsConditions />
      </Form>
    </div>
  );
};

export default RequireNewPassword;
