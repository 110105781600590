import { i18nextInstance } from "../../../../App";
import { IBreakdownUnit } from "@store/aggregations/types";
import { ICSVSentimentsData } from "../../../_common/DownloadCSV";

export const LABEL_SMALL = 150;
export const LABEL_LARGE = 200;
export const CHART_HEIGHT = 430;

export const getCSVChartData = (breakdownUnits: IBreakdownUnit[], currentTab: string) => {
  let data: ICSVSentimentsData[] = [];
  const selectedDataSet = breakdownUnits.find(unit => unit.id === currentTab);

  if (selectedDataSet) {
    data = selectedDataSet.items.map(item => {
      const comment_stats: { [key: string]: string } = {};
      let total = 0;

      Object.values(item.comment_stats).forEach(el => {
        comment_stats[el.label] = el.count.toString();
        total += el.count;
      });

      return {
        name: item.label,
        Total: total.toString(),
        ...comment_stats,
      };
    });
  }

  return data;
};

export const getCSVTableHeaders = (columnName: string) => {
  return [
    columnName,
    i18nextInstance.t("components:searchPanel:columnTitles:totalComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:positiveComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:negativeComments"),
    i18nextInstance.t("components:searchPanel:columnTitles:userTags"),
    i18nextInstance.t("components:searchPanel:columnTitles:neutralComments"),
  ];
};
