import { createReducer, ActionType } from "typesafe-actions";
import { ICaptionsAIPromptsState } from "./types";
import {
  createCaptionsPromptSuccessAction,
  updateCaptionsPromptSuccessAction,
  getCaptionsPromptsSuccessAction,
  deleteCaptionsPromptSuccessAction,
  setCaptionsPromptAsDefaultSuccessAction,
  getCaptionsPromptAISuggestionSuccessAction,
} from "./actions";
import {
  GET_CAPTIONS_AI_PROMPTS_FAILURE,
  GET_CAPTIONS_AI_PROMPTS_SUCCESS,
  CREATE_CAPTIONS_AI_PROMPT,
  CREATE_CAPTIONS_AI_PROMPT_FAILURE,
  CREATE_CAPTIONS_AI_PROMPT_SUCCESS,
  UPDATE_CAPTIONS_AI_PROMPT,
  UPDATE_CAPTIONS_AI_PROMPT_FAILURE,
  UPDATE_CAPTIONS_AI_PROMPT_SUCCESS,
  DELETE_CAPTIONS_AI_PROMPT,
  DELETE_CAPTIONS_AI_PROMPT_FAILURE,
  DELETE_CAPTIONS_AI_PROMPT_SUCCESS,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE,
  SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS,
  GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE,
  CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION,
  CLEAR_CAPTIONS_AI_PROMPT,
} from "./actionTypes";
import { ICaptionsAIPromptsResponse } from "./types";

const initialState = {
  captionsAIPrompts: {
    presets: [],
    items: [],
  } as ICaptionsAIPromptsResponse,
  captionsAISuggestion: undefined,
  postingCaptionsAIPrompt: false,
  postedCaptionsAIPrompt: false,
  postedCaptionsAIPromptFail: false,
  updatingCaptionsAIPrompt: false,
  updatedCaptionsAIPrompt: false,
  updatedCaptionsAIPromptFail: false,
  fetchingCaptionsPromptAISuggestion: false,
  fetchedCaptionsPromptAISuggestion: false,
  fetchedCaptionsPromptAISuggestionFail: false,
};

export const captionsAIPromptsReducer = createReducer<ICaptionsAIPromptsState>(initialState, {
  // CREATE
  [CREATE_CAPTIONS_AI_PROMPT]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    postingCaptionsAIPrompt: true,
    postedCaptionsAIPrompt: false,
    postedCaptionsAIPromptFail: false,
  }),
  [CREATE_CAPTIONS_AI_PROMPT_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof createCaptionsPromptSuccessAction>,
  ) => ({
    ...state,
    captionsAIPrompts: {
      presets: [...state.captionsAIPrompts.presets],
      items: [...state.captionsAIPrompts.items, action.payload],
    },
    postingCaptionsAIPrompt: false,
    postedCaptionsAIPrompt: true,
    postedCaptionsAIPromptFail: false,
  }),
  [CREATE_CAPTIONS_AI_PROMPT_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    postingCaptionsAIPrompt: false,
    postedCaptionsAIPrompt: false,
    postedCaptionsAIPromptFail: true,
  }),

  // UPDATE
  [UPDATE_CAPTIONS_AI_PROMPT]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    updatingCaptionsAIPrompt: true,
    updatedCaptionsAIPrompt: false,
    updatedCaptionsAIPromptFail: false,
  }),
  [UPDATE_CAPTIONS_AI_PROMPT_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof updateCaptionsPromptSuccessAction>,
  ) => {
    const promptIdx = state.captionsAIPrompts?.items.findIndex(
      ({ id }) => id === action.payload.id,
    );

    const promptListCopy = state.captionsAIPrompts?.items;
    promptListCopy[promptIdx] = action.payload;

    return {
      ...state,
      captionsAIPrompts: {
        presets: [...state.captionsAIPrompts.presets],
        items: promptListCopy,
      },
      updatingCaptionsAIPrompt: false,
      updatedCaptionsAIPrompt: true,
      updatedCaptionsAIPromptFail: false,
    };
  },
  [UPDATE_CAPTIONS_AI_PROMPT_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    updatingCaptionsAIPrompt: false,
    updatedCaptionsAIPrompt: false,
    updatedCaptionsAIPromptFail: true,
  }),

  // GET LIST
  [GET_CAPTIONS_AI_PROMPTS_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof getCaptionsPromptsSuccessAction>,
  ) => {
    return {
      ...state,
      captionsAIPrompts: {
        presets: [...action.payload.presets],
        items: [...action.payload.items],
      },
    };
  },
  [GET_CAPTIONS_AI_PROMPTS_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
  }),

  // DELETE
  [DELETE_CAPTIONS_AI_PROMPT]: (state: ICaptionsAIPromptsState) => ({
    ...state,
  }),
  [DELETE_CAPTIONS_AI_PROMPT_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof deleteCaptionsPromptSuccessAction>,
  ) => {
    const isDeletedPromptDefault = state.captionsAIPrompts.items.find(
      item => item.id === action.payload,
    )?.is_default;

    return {
      ...state,
      captionsAIPrompts: {
        presets: [
          ...state.captionsAIPrompts.presets.map((item, index) => {
            if (isDeletedPromptDefault && index === 0) {
              // If the deleted prompt was the default, set first presets prompt as default
              return {
                ...item,
                is_default: true,
              };
            }
            return item;
          }),
        ],
        items: state.captionsAIPrompts.items.filter(item => item.id !== action.payload),
      },
    };
  },
  [DELETE_CAPTIONS_AI_PROMPT_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
  }),

  // SET AS DEFAULT
  [SET_CAPTIONS_AI_PROMPT_AS_DEFAULT]: (state: ICaptionsAIPromptsState) => ({
    ...state,
  }),
  [SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof setCaptionsPromptAsDefaultSuccessAction>,
  ) => {
    return {
      ...state,
      captionsAIPrompts: {
        presets: [
          ...state.captionsAIPrompts.presets.map(item =>
            item.id === action.payload
              ? { ...item, is_default: true }
              : { ...item, is_default: false },
          ),
        ],
        items: [
          ...state.captionsAIPrompts.items.map(item => {
            if (item.id === action.payload) {
              return {
                ...item,
                is_default: true,
              };
            }
            return {
              ...item,
              is_default: false,
            };
          }),
        ],
      },
    };
  },
  [SET_CAPTIONS_AI_PROMPT_AS_DEFAULT_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
  }),

  // CREATE
  [GET_CAPTIONS_PROMPT_AI_SUGGESTION]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    fetchingCaptionsPromptAISuggestion: true,
    fetchedCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestionFail: false,
  }),
  [GET_CAPTIONS_PROMPT_AI_SUGGESTION_SUCCESS]: (
    state: ICaptionsAIPromptsState,
    action: ActionType<typeof getCaptionsPromptAISuggestionSuccessAction>,
  ) => ({
    ...state,
    captionsAISuggestion: action.payload,
    fetchingCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestion: true,
    fetchedCaptionsPromptAISuggestionFail: false,
  }),
  [GET_CAPTIONS_PROMPT_AI_SUGGESTION_FAILURE]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    fetchingCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestionFail: true,
  }),
  [CLEAR_CAPTIONS_PROMPT_AI_SUGGESTION]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    captionsAISuggestion: undefined,
    fetchingCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestion: false,
    fetchedCaptionsPromptAISuggestionFail: false,
  }),
  // CLEAR
  [CLEAR_CAPTIONS_AI_PROMPT]: (state: ICaptionsAIPromptsState) => ({
    ...state,
    postingCaptionsAIPrompt: false,
    postedCaptionsAIPrompt: false,
    postedCaptionsAIPromptFail: false,
    updatedCaptionsAIPrompt: false,
    updatingCaptionsAIPrompt: false,
    updatedCaptionsAIPromptFail: false,
  }),
});
