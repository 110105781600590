import i18next from "i18next";
import en_US from "./translations/en_US.json";

i18next.init({
  lng: "en",
  debug: process.env.BUILD_ENV === "development",
  resources: {
    en: en_US,
  },
  interpolation: {
    escapeValue: false,
  },
});

export const getI18next = () => i18next;
