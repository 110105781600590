import React from "react";
import { useTranslation } from "react-i18next";

import { Divider } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Notifications.module.less";

import { INotificationsFooterProps } from "./Notifications.types";

export const NotificationsFooter: React.FC<INotificationsFooterProps> = ({ total }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbNotificationsFooter}>
      <Divider className={s.bbNotificationsDivider} />
      <span className={s.bbNotificationsFooterText}>
        {t("header:notifications:footer", { total })}
      </span>
    </div>
  );
};
