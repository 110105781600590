import React, { FC, useEffect, useState } from "react";

import AIPromptField, { VariableToggle } from "./AIPromptSectionField";

import s from "./AIPromptSection.module.less";

import { IAIPromptSectionProps } from "./types";

const AIPromptSection: FC<IAIPromptSectionProps> = ({
  form,
  title,
  description,
  fields,
  inlineToggle,
}) => {
  const [isToggleSelected, setToggleSelected] = useState(!!inlineToggle?.enabled);

  useEffect(() => {
    form.setFieldsValue({
      [`${inlineToggle?.id}_enabled`]: !!inlineToggle?.enabled,
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className={s.bbAIPromptSection}>
      <div className={s.bbAIPromptSectionTitle}>{title}</div>
      <div className={s.bbVariableDescriptionWrapper}>
        <div>{description}</div>
        {!!inlineToggle && (
          <VariableToggle
            form={form}
            id={inlineToggle.id}
            isToggleSelected={isToggleSelected}
            setToggleSelected={setToggleSelected}
          />
        )}
      </div>
      {fields?.map(field => (
        <AIPromptField
          key={field.id}
          form={form}
          field={field}
          inlineToggle={inlineToggle}
          parentToggled={!!inlineToggle && isToggleSelected}
        />
      ))}
    </div>
  );
};

export default AIPromptSection;
