import React from "react";
import { useTranslation } from "react-i18next";

import { AlertWarningAccent } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./Disclaimers.module.less";

function MissingAssetsDisclaimer() {
  const { t } = useTranslation();

  return (
    <AlertWarningAccent
      size="medium"
      align="center"
      className={s.bbDisclaimer}
      message={t("components:assetAlerts:missingAssets:message")}
      action={
        <a
          className={s.bbMissingAssetsDislaimerAction}
          href={`${process.env.BASE_URL}/settings/asset-management/add-assets`}
        >
          {t("components:assetAlerts:missingAssets:action")}
        </a>
      }
    />
  );
}

export default MissingAssetsDisclaimer;
