import { Feature } from "@store/modules/types";

export interface IAppSidebarProps {
  hasSavedFilters: boolean;
  onSavedFilterChange: (newValue: string | number | null) => void;
}

export interface IAppSidebarContext {
  sidebarPanelState: SidebarPanelStateEnum;
  isCollapsed: boolean;
  isExpanded: boolean;
  isPreviewMode: boolean;
  collapsePanel: () => void;
  expandPanel: () => void;
  previewPanel: () => void;
  ref: React.RefObject<HTMLDivElement>;
}

export enum SidebarPanelStateEnum {
  EXPANDED,
  COLLAPSED,
  COLLAPSE_PREVIEW,
}

export interface IAppSidebarFeatureProps {
  feature: Feature;
  moduleId: string;
  sectionId: string;
  onClick?: (feature: Feature) => void;
}

export interface IAppSideBarFeaturesProps {
  features: Feature[];
  moduleId: string;
  sectionId: string;
  onClick?: (feature: Feature) => void;
}
