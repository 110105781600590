export const DATE_FORMAT = "MMM DD, YYYY hh:mm:ss A";

export const getInitials = (fullName: string) => {
  const split = fullName.split(" ");

  if (split.length >= 2) {
    return `${split[0].charAt(0)}${split[1].charAt(0)}`.toUpperCase();
  }

  return split[0].charAt(0).toUpperCase();
};

export const removeCurlyBraces = (str: string) => {
  return str.replace(/[{}]/g, "");
};
