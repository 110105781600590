import { createReducer } from "typesafe-actions";

import { IHubspotState } from "./types";
import { SUBMIT, SUBMIT_SUCCESS, SUBMIT_FAIL } from "./actionTypes";

const initialState = {
  fetching: false,
  fetched: false,
  fail: false,
};

export const hubspotReducer = createReducer<IHubspotState>(initialState, {
  [SUBMIT]: (state: IHubspotState) => ({
    ...state,
    fetched: false,
    fetching: true,
    fail: false,
  }),
  [SUBMIT_SUCCESS]: (state: IHubspotState) => ({
    ...state,
    fetched: true,
    fetching: false,
    fail: false,
  }),
  [SUBMIT_FAIL]: (state: IHubspotState) => ({
    ...state,
    industries: [],
    fb_scope: "",
    fetched: false,
    fetching: false,
    fail: true,
  }),
});
