export const GET_ONBOARDING_PLATFORMS = "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORMS";
export const GET_ONBOARDING_PLATFORMS_SUCCESS =
  "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORMS_SUCCESS";
export const GET_ONBOARDING_PLATFORMS_FAILURE =
  "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORMS_FAILURE";

export const GET_ONBOARDING_PLATFORM = "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORM";
export const GET_ONBOARDING_PLATFORM_SUCCESS =
  "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORM_SUCCESS";
export const GET_ONBOARDING_PLATFORM_FAILURE =
  "ONBOARDING_PLATFORMS/GET_ONBOARDING_PLATFORM_FAILURE";

export const REGISTER_TOKEN = "ONBOARDING_PLATFORMS/REGISTER_TOKEN";
export const REGISTER_TOKEN_SUCCESS = "ONBOARDING_PLATFORMS/REGISTER_TOKEN_SUCCESS";
export const REGISTER_TOKEN_FAILURE = "ONBOARDING_PLATFORMS/REGISTER_TOKEN_FAILURE";

export const DELETE_TOKEN = "ONBOARDING_PLATFORMS/DELETE_TOKEN";
export const DELETE_TOKEN_SUCCESS = "ONBOARDING_PLATFORMS/DELETE_TOKEN_SUCCESS";
export const DELETE_TOKEN_FAILURE = "ONBOARDING_PLATFORMS/DELETE_TOKEN_FAILURE";

export const GET_TWITTER_REGISTER_TOKEN_URL = "ONBOARDING_PLATFORMS/GET_TWITTER_REGISTER_TOKEN_URL";
export const GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS =
  "ONBOARDING_PLATFORMS/GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS";
export const GET_TWITTER_REGISTER_TOKEN_URL_FAILURE =
  "ONBOARDING_PLATFORMS/GET_TWITTER_REGISTER_TOKEN_URL_FAILURE";
