import { createReducer, ActionType } from "typesafe-actions";
import { ICompareProjectsState } from "./types";

import {
  GET_COMPARE_PROJECTS,
  GET_COMPARE_PROJECTS_SUCCESS,
  GET_COMPARE_PROJECT_AGGREGATIONS,
  GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE,
  GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS,
  POST_COMPARE_PROJECT,
  POST_COMPARE_PROJECT_SUCCESS,
  EDIT_COMPARE_PROJECT,
  EDIT_COMPARE_PROJECT_SUCCESS,
  EDIT_COMPARE_PROJECT_FAILURE,
  DELETE_COMPARE_PROJECT,
  DELETE_COMPARE_PROJECT_SUCCESS,
  CLEAR_PROJECT_AGGREGATIONS,
} from "./actionTypes";
import {
  getCompareProjectsSuccess,
  getCompareProjectAggregationsSuccess,
  postCompareProjectSuccess,
  editCompareProjectSuccess,
  deleteCompareProjectSuccess,
} from "./actions";

const initialState = {
  projects: [],
  compareProjectsAggregations: [],

  fetchingCompareProjects: false,
  fetchedCompareProjects: false,
  fetchedCompareProjectsFail: false,

  postingCompareProject: false,
  postedCompareProject: false,
  postedCompareProjectFail: false,

  editingCompareProject: false,
  editedCompareProject: false,
  editedCompareProjectFail: false,
  editedCompareProjectId: undefined,

  deletingCompareProject: false,
  deletedCompareProject: false,
  deletedCompareProjectFail: false,

  fetchingCompareProjectsAggregations: false,
  fetchedCompareProjectsAggregations: false,
  fetchedCompareProjectsAggregationsFail: false,
};

export const compareProjectsReducer = createReducer<ICompareProjectsState>(initialState, {
  [GET_COMPARE_PROJECTS]: (state: ICompareProjectsState) => ({
    ...state,
    fetchingCompareProjects: true,
    fetchedCompareProjects: false,
    fetchedCompareProjectsFail: false,
  }),
  [GET_COMPARE_PROJECTS_SUCCESS]: (
    state: ICompareProjectsState,
    action: ActionType<typeof getCompareProjectsSuccess>,
  ) => ({
    ...state,
    projects: action.payload,
    fetchingCompareProjects: false,
    fetchedCompareProjects: true,
    fetchedCompareProjectsFail: false,
  }),
  [POST_COMPARE_PROJECT]: (state: ICompareProjectsState) => ({
    ...state,
    postedCompareProject: false,
    postingCompareProject: true,
  }),
  [POST_COMPARE_PROJECT_SUCCESS]: (
    state: ICompareProjectsState,
    action: ActionType<typeof postCompareProjectSuccess>,
  ) => ({
    ...state,
    projects: [action.payload, ...state.projects],
    postedCompareProject: true,
    postingCompareProject: false,
  }),
  [DELETE_COMPARE_PROJECT]: (state: ICompareProjectsState) => ({
    ...state,
    deletedCompareProject: false,
    deletingCompareProject: true,
  }),
  [DELETE_COMPARE_PROJECT_SUCCESS]: (
    state: ICompareProjectsState,
    action: ActionType<typeof deleteCompareProjectSuccess>,
  ) => ({
    ...state,
    projects: [...state.projects.filter(project => project.id !== action.payload)],
    deletedCompareProject: true,
    deletingCompareProject: false,
  }),
  [EDIT_COMPARE_PROJECT]: (state: ICompareProjectsState) => ({
    ...state,
    editedCompareProjectId: undefined,
    editingCompareProject: true,
    editedCompareProject: false,
    editedCompareProjectFail: false,
  }),
  [EDIT_COMPARE_PROJECT_SUCCESS]: (
    state: ICompareProjectsState,
    action: ActionType<typeof editCompareProjectSuccess>,
  ) => {
    const compareProjects = [...state.projects];
    const updatedProject = action.payload;
    const editedProjectIndex = compareProjects.findIndex(p => p.id === updatedProject.id);

    if (editedProjectIndex >= 0) compareProjects[editedProjectIndex] = updatedProject;

    return {
      ...state,
      projects: compareProjects,
      editedCompareProjectId: updatedProject.id,
      editingCompareProject: false,
      editedCompareProject: true,
      editedCompareProjectFail: false,
    };
  },
  [EDIT_COMPARE_PROJECT_FAILURE]: (state: ICompareProjectsState) => ({
    ...state,
    editedCompareProjectId: undefined,
    editingCompareProject: false,
    editedCompareProject: false,
    editedCompareProjectFail: true,
  }),
  [GET_COMPARE_PROJECT_AGGREGATIONS]: (state: ICompareProjectsState) => ({
    ...state,
    fetchingCompareProjectsAggregations: true,
    fetchedCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregationsFail: false,
  }),
  [GET_COMPARE_PROJECT_AGGREGATIONS_SUCCESS]: (
    state: ICompareProjectsState,
    action: ActionType<typeof getCompareProjectAggregationsSuccess>,
  ) => ({
    ...state,
    compareProjectsAggregations: action.payload,
    fetchingCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregations: true,
    fetchedCompareProjectsAggregationsFail: false,
  }),
  [GET_COMPARE_PROJECT_AGGREGATIONS_FAILURE]: (
    state: ICompareProjectsState,
    action: ActionType<typeof getCompareProjectAggregationsSuccess>,
  ) => ({
    ...state,
    compareProjectsAggregations: action.payload,
    fetchingCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregationsFail: true,
  }),
  [CLEAR_PROJECT_AGGREGATIONS]: (state: ICompareProjectsState) => ({
    ...state,
    compareProjectsAggregations: [],
    fetchingCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregations: false,
    fetchedCompareProjectsAggregationsFail: false,
  }),
});
