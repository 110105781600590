import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { ScrollView } from "@bbdevcrew/bb_ui_kit_fe";
import ModalHeaderActions from "@components/_common/ModalHeaderActions";
import ItemCard from "@components/_common/ItemCardComponent/ItemCard";

import { getCareCpsStreamsSelector } from "@store/streams/selectors";

import s from "./StreamsModal.module.less";

import { IStreamsListProps } from "./StreamsModal.types";

export const SteamsList: React.FC<IStreamsListProps> = ({
  onStreamSelect,
  onEditClick,
  onDeleteClick,
  ...restProps
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const streams = useSelector(getCareCpsStreamsSelector);

  const filteredStreams = useMemo(() => {
    return (
      streams?.filter(({ name }) =>
        name.trim().toLowerCase().includes(query.trim().toLowerCase()),
      ) || []
    );
  }, [streams, query]);

  return (
    <div className={s.bbStreamsModalList}>
      <ModalHeaderActions
        title={t("generic:streams")}
        onSearch={value => setQuery(value)}
        placeholder={t("generic:search")}
        className={s.bbStreamsModalListHeader}
        {...restProps}
      />

      <ScrollView className={s.bbStreamsModalListView}>
        <>
          {filteredStreams.map(stream => {
            const { id, name, color, counts } = stream;
            return (
              <ItemCard
                key={id}
                name={name}
                color={color}
                tooltipProps={{ title: t("components:careCps:streamsModal:goToStream") }}
                suffix={
                  <span className={s.bbStreamsModalListCounts}>
                    {counts?.map(count => (
                      <span key={count.id} className={s.bbStreamsModalListCountsItem}>
                        {count.count}
                      </span>
                    ))}
                  </span>
                }
                onItemClick={() => onStreamSelect(stream)}
                actions={[
                  {
                    key: "edit",
                    label: "generic:edit",
                    onActionClick: () => onEditClick(stream),
                  },
                  {
                    key: "delete",
                    label: "generic:delete",
                    onActionClick: () => onDeleteClick(stream),
                  },
                ]}
              />
            );
          })}
        </>
      </ScrollView>
    </div>
  );
};
