import { createReducer, ActionType } from "typesafe-actions";
import { IOverviewState, ITrendingPlatformPostsProps, IAdTargetingsProps } from "./types";
import { getTrendingPostsActionSuccess, getAdTargetingsActionSuccess } from "./actions";
import {
  GET_TRENDING_POSTS,
  GET_TRENDING_POSTS_SUCCESS,
  GET_TRENDING_POSTS_FAILURE,
  GET_AD_TARGETINGS,
  GET_AD_TARGETINGS_SUCCESS,
  GET_AD_TARGETINGS_FAILURE,
} from "./actionTypes";

const initialState = {
  trendingPosts: [] as ITrendingPlatformPostsProps[],
  adTargetings: [] as IAdTargetingsProps[],
  fetchingTrendingPosts: false,
  fetchedTrendingPosts: false,
  fetchedTrendingPostsFail: false,
  fetchingAdTargetings: false,
  fetchedAdTargetings: false,
  fetchedAdTargetingsFail: false,
};

export const overviewReducer = createReducer<IOverviewState>(initialState, {
  //GET_TRENDING_POSTS
  [GET_TRENDING_POSTS]: (state: IOverviewState) => ({
    ...state,
    fetchingTrendingPosts: true,
    fetchedTrendingPosts: false,
    fetchedTrendingPostsFail: false,
  }),
  [GET_TRENDING_POSTS_SUCCESS]: (
    state: IOverviewState,
    action: ActionType<typeof getTrendingPostsActionSuccess>,
  ) => ({
    ...state,
    trendingPosts: action.payload,
    fetchingTrendingPosts: false,
    fetchedTrendingPosts: true,
    fetchedTrendingPostsFail: false,
  }),
  [GET_TRENDING_POSTS_FAILURE]: (state: IOverviewState) => ({
    ...state,
    fetchingTrendingPosts: false,
    fetchedTrendingPosts: false,
    fetchedTrendingPostsFail: true,
  }),
  //GET_AD_TARGETINGS
  [GET_AD_TARGETINGS]: (state: IOverviewState) => ({
    ...state,
    fetchingAdTargetings: true,
    fetchedAdTargetings: false,
    fetchedAdTargetingsFail: false,
  }),
  [GET_AD_TARGETINGS_SUCCESS]: (
    state: IOverviewState,
    action: ActionType<typeof getAdTargetingsActionSuccess>,
  ) => ({
    ...state,
    adTargetings: action.payload,
    fetchingAdTargetings: false,
    fetchedAdTargetings: true,
    fetchedAdTargetingsFail: false,
  }),
  [GET_AD_TARGETINGS_FAILURE]: (state: IOverviewState) => ({
    ...state,
    fetchingAdTargetings: false,
    fetchedAdTargetings: false,
    fetchedAdTargetingsFail: true,
  }),
});
