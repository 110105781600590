export function isDiffMoreThanAYear(periodStart?: string, periodEnd?: string): boolean {
  if (!periodStart || !periodEnd) return false;

  const yearInMs = 1000 * 3600 * 24 * 365;

  const startDate = new Date(periodStart);
  const endDate = new Date(periodEnd);

  const diffInMs = endDate.getTime() - startDate.getTime();
  const diffInYears = diffInMs / yearInMs;

  return diffInYears >= 1;
}
