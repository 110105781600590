import React, { HTMLAttributes, useState, useEffect, useRef } from "react";
import classNames from "classnames";

import { SimpleDropdown } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DropdownMenu.module.less";

import { IDropdownMenuProps } from "./DropdownMenu.types";

export const DropdownMenu: React.FC<IDropdownMenuProps> = ({
  trigger: TriggerComponent,
  placement = "bottom-start",
  className,
  children,
}) => {
  const [open, setOpen] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (triggerRef.current && menuRef.current) {
      menuRef.current.style.minWidth = `${triggerRef.current.clientWidth}px`;
    }
  }, [open]);

  return (
    <SimpleDropdown
      open={open}
      setOpen={setOpen}
      placement={placement}
      trigger={
        <div ref={triggerRef}>
          <TriggerComponent open={open} />
        </div>
      }
    >
      <div ref={menuRef} className={classNames(s.bbDropdownMenu, className)}>
        {children(() => setOpen(false))}
      </div>
    </SimpleDropdown>
  );
};

export const DropdownMenuItem: React.FC<HTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <button className={classNames(s.bbDropdownMenuItem, className)} {...props}>
      {children}
    </button>
  );
};

export const DropdownMenuDivider = () => {
  return <hr className={s.bbDropdownMenuDivider} />;
};
