import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import AssignToMeButton from "../../../_common/AssignToMeButton/AssignToMeButton";

import s from "./EmptyStates.module.less";

import { assignToMeLoadingSelector } from "@store/assign/selectors";

import { AssignToMeClickHandlerType } from "../inboxCommentsHeader/types";

import BreakIcon from "@assets/Break.svg";

interface IAssignedToMeEmptyStateProps {
  showAssignBtn?: boolean;
  onAssignClick?: AssignToMeClickHandlerType;
}

const AssignedToMeEmptyState: FC<IAssignedToMeEmptyStateProps> = ({
  showAssignBtn = false,
  onAssignClick,
}) => {
  const { t } = useTranslation();

  const assignToMeLoading = useSelector(assignToMeLoadingSelector);

  return (
    <div className={s.bbInboxEmptyState}>
      <BreakIcon />
      <div className={s.bbInboxEmptyStateTitle}>
        {t("components:inboxMenu:emptyState:assignedToMe:title")}
      </div>
      <div className={s.bbInboxEmptyStateDescription}>
        {t(
          `components:inboxMenu:emptyState:assignedToMe:${
            showAssignBtn ? "description2" : "description1"
          }`,
        )}
      </div>
      {showAssignBtn && (
        <AssignToMeButton
          _size="md"
          loading={assignToMeLoading}
          loadingIndicatorPosition="center"
          className={s.bbInboxEmptyStateBtn}
          onActionHandler={closeModal => onAssignClick?.("review", closeModal)}
          label={t("components:inboxMenu:emptyState:assignedToMe:action")}
        />
      )}
    </div>
  );
};

export default AssignedToMeEmptyState;
