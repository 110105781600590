import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import CommentAction from "../commentAction/CommentAction";

import { CommentClickFunctionType } from "../CommentActions.type";
import { IComment } from "@store/comments/types";

import { CloseIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IActionCancelProps {
  className?: string;
  comment: IComment;
  onCancelClick?: CommentClickFunctionType;
}

const ActionCancel: FC<IActionCancelProps> = ({ comment, onCancelClick, className = "" }) => {
  const { t } = useTranslation();

  const isCanceled = comment.actions.reply_cancel_capability.is_cancelled;
  const canCancel = comment.actions.reply_cancel_capability.capability === "enabled";

  const onClickHandler = useCallback(() => {
    onCancelClick?.(comment.id, !isCanceled);
  }, [onCancelClick, comment.id, isCanceled]);

  return (
    <CommentAction
      className={className}
      onClick={onClickHandler}
      loading={
        comment.actionsProcessing?.includes("reply_cancel") ||
        comment.actionsProcessing?.includes("reply_uncancel")
      }
      type={!canCancel ? "disabled" : "primary"}
      active={isCanceled}
      tooltipProps={{
        title: t(`components:comments:tooltips:${isCanceled ? "uncancel" : "cancel"}`),
      }}
    >
      <CloseIcon />
    </CommentAction>
  );
};

export default ActionCancel;
