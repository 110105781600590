/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Grid, Form, FormInstance } from "antd";
import FilterLabel from "./filterLabel/FilterLabel";

import { IFormFilterRequest } from "./AppFilters.type";
import { IDropdownOptions, IDynamicFilter, FilterPlacementType } from "./Filters.type";

import s from "./Filters.module.less";

interface IDynamicFilterComponentProps {
  maxTagCount?: number;
  filter: IDynamicFilter<any>;
  dropdownPlacement?: FilterPlacementType;
  onFocus: (name: string) => void;
  dropdownOptions: IDropdownOptions;
  onSearch: (value: string, name: string) => void;
  onMultiPaste: (
    name: string,
    mergedValues: string[],
    isNoResult: boolean,
    originalSearchString: string,
  ) => void;
}

export const DynamicFilterComponent = ({
  filter: { name, label, tooltip, props, component: DynamicComponent },
  onFocus,
  onSearch,
  maxTagCount,
  onMultiPaste,
  dropdownOptions,
  dropdownPlacement,
}: IDynamicFilterComponentProps) => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const isMultiSelect = "mode" in props && props.mode === "multiple";
  const maxTagCountMulti = maxTagCount || 2;
  const elementOptions =
    "options" in props
      ? props.options.length
        ? props.options
        : dropdownOptions[name as keyof IDropdownOptions] || []
      : undefined;

  return (
    <div data-stonly-target={`app-global__filters--${name}`} key={`filter-col-${name}`}>
      <Form.Item shouldUpdate style={{ marginBottom: 16 }}>
        {(form: FormInstance<IFormFilterRequest>) => {
          const fieldValue: any[] | undefined = form.getFieldValue(name);

          return (
            <>
              <FilterLabel label={label} tooltip={tooltip} />
              <Form.Item
                name={name}
                className={classNames(s.bbDynamicFilterFormItem, `bb${name}FormItem`)}
              >
                <DynamicComponent
                  size="middle"
                  data-cy={`app-filter-${name}`}
                  // All props + dynamic props (options + callbacks)
                  {...{
                    ...props,
                    customprops: {
                      form,
                      name,
                      onFocus,
                    },
                    onFocus:
                      "onFocus" in props && typeof props.onFocus === "function"
                        ? () => onFocus(name)
                        : undefined,
                    onMultiPaste:
                      "isMultiPaste" in props
                        ? (
                            mergedValues: string[],
                            isNoResult: boolean,
                            originalSearchString: string,
                          ) => onMultiPaste(name, mergedValues, isNoResult, originalSearchString)
                        : undefined,
                    maxTagCount: isMultiSelect
                      ? fieldValue && fieldValue.length < maxTagCountMulti
                        ? maxTagCountMulti
                        : 0
                      : undefined,
                    maxTagPlaceholder: isMultiSelect
                      ? t(props.options.selectedLabel || "components:filters:selectedTag", {
                          number: fieldValue && fieldValue.length,
                        })
                      : undefined,
                    onSearch:
                      "onSearch" in props && typeof props.onSearch === "function"
                        ? (value: string) => onSearch(value, name)
                        : undefined,
                    options: elementOptions,
                    dropdownPlacement: props.dropdownPlacement
                      ? props.dropdownPlacement
                      : dropdownPlacement === "right"
                        ? "bottomRight"
                        : "bottomLeft",
                    dropdownMatchSelectWidth: props.dropdownMatchSelectWidth || !screens.md,
                  }}
                />
              </Form.Item>
            </>
          );
        }}
      </Form.Item>
    </div>
  );
};
