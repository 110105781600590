import classNames from "classnames/bind";
import React from "react";

import { Col } from "antd";
import { PerformanceCardPill } from "@components/insights/_common/performanceCard/Pill";

import s from "./ProfilesTable.module.less";

import { platformIconName } from "@utils/platform";
import { getPlatformIcon } from "./ProfilesTable.helpers";
import { formatter } from "@components/_common/CardStat/helpers";
import { ImpressionsProfileTableRowProps } from "./ProfilesTable.types";

const ImpressionsProfileTableRow = React.memo(
  ({ data: row, index, columns }: ImpressionsProfileTableRowProps) => {
    return (
      <>
        {columns.length && (
          <div
            data-cy="profiles-table-row"
            className={classNames(s.bbProfilesTableRow, {
              [s.bbProfilesTableRowColored]: index % 2 === 1,
            })}
          >
            <Col span={columns[0].colSpan} data-cy="profiles-column" className={s.bbColPlatform}>
              <div data-cy="profiles-platform" className={s.bbPlatformIconSmall}>
                {row?.platform && getPlatformIcon(platformIconName(row.platform))}
              </div>
              <span>{row?.asset_name}</span>
            </Col>
            <Col
              className={classNames(s.bbColumn, s.bbImpressionColumn)}
              data-cy="table-column"
              span={columns[1].colSpan}
            >
              {row?.total_impressions != null ? formatter(row?.total_impressions) : ""}
              {!!row?.growth && <PerformanceCardPill percentageValue={Number(row.growth)} />}
            </Col>
          </div>
        )}
      </>
    );
  },
);

ImpressionsProfileTableRow.displayName = "ImpressionsProfileTableRow";

export default ImpressionsProfileTableRow;
