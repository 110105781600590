import React from "react";

import { Icon } from "@bbdevcrew/bb_ui_kit_fe";

import { IComment } from "@store/comments/types";

import s from "./CommentCardHeader.module.less";

import { RemoveIcon } from "@assets/index";

interface IDeletedCommentIcon {
  comment: IComment;
}

export default function DeletedCommentIcon({ comment }: IDeletedCommentIcon) {
  if (!comment.is_deleted) return null;

  return <Icon icon={RemoveIcon} className={s.bbHiddenCommentIcon} />;
}
