import { t } from "i18next";

const messageStatusesLabels: Record<string, string> = {
  archived: t("components:filters:message_statuses:options:archived"),
  starred: t("components:filters:message_statuses:options:starred"),
  replied: t("components:filters:message_statuses:options:replied"),
  replied_in_platform: t("components:filters:message_statuses:options:replied_in_platform"),
  replied_by_bb: t("components:filters:message_statuses:options:replied_by_bb"),
};

const messageStatuses = [
  {
    id: "archived",
    label: messageStatusesLabels.archived,
  },
  {
    id: "starred",
    label: messageStatusesLabels.starred,
  },
  {
    id: "replied",
    label: messageStatusesLabels.replied,
    items: [
      {
        id: "replied_in_platform",
        label: messageStatusesLabels.replied_in_platform,
      },
      {
        id: "replied_by_bb",
        label: messageStatusesLabels.replied_by_bb,
      },
    ],
  },
];

export { messageStatusesLabels, messageStatuses };
