import { IComment } from "@store/comments/types";

export const isSingleStoryComment = (comment: IComment) =>
  comment.dm_stats &&
  comment.dm_stats.grand_total_count === 1 &&
  comment.dm_messages &&
  ["story_reply", "story_mention"].includes(comment.dm_messages[0].type);

export const scrollToBottom = (node: HTMLDivElement) => {
  setTimeout(() => {
    node?.scrollTo({ top: node.scrollHeight });
  }, 300);
};
