import React from "react";

import { CommentStats } from "./CommentStats";
import { numberFormatter } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./MostCommentedPostFooter.module.less";

import { ITrendingPostProps } from "@store/overview/types";

import { CommentIcon } from "@assets/index";

interface IMostCommentedPostFooterProps {
  chartWidth: number;
  postData: ITrendingPostProps;
}

const MostCommentedPostFooter: React.FC<IMostCommentedPostFooterProps> = ({
  postData,
  chartWidth,
}) => (
  <div className={s.bbMostCommentedPostFooter} data-cy="most-commented-post-footer">
    <div className={s.bbPostTotal} data-cy="most-commented-post-total">
      <span className={s.bbPostTotalIcon}>
        <CommentIcon />
      </span>
      <span>{numberFormatter(postData?.comment_stats?.total || 0)}</span>
    </div>
    {!postData.hide_sentiment_bar ? (
      <CommentStats chartWidth={chartWidth} commentStats={postData.comment_stats} />
    ) : null}
  </div>
);

export default MostCommentedPostFooter;
