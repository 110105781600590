import { RootState } from "../";

export const fromCustomTags = (state: RootState) => state.customTags;

export const customTagsSelector = (state: RootState) => fromCustomTags(state).customTags;

export const getCustomTagsSuccessfulSelector = (state: RootState) =>
  fromCustomTags(state).fetchedCustomTags;
export const fetchingCustomTagsSelector = (state: RootState) =>
  fromCustomTags(state).fetchingCustomTags;
export const getCustomTagsFailedSelector = (state: RootState) =>
  fromCustomTags(state).fetchedCustomTagsFail;

export const postCustomTagSelector = (state: RootState) => fromCustomTags(state).postingCustomTag;
export const postCustomTagSuccessfulSelector = (state: RootState) =>
  fromCustomTags(state).postedCustomTag;
export const postCustomTagFailedSelector = (state: RootState) =>
  fromCustomTags(state).postedCustomTagFail;
export const postCustomTagLastCreatedSelector = (state: RootState) =>
  fromCustomTags(state).postedCustomTagResult;

export const deleteCustomTagSelector = (state: RootState) =>
  fromCustomTags(state).deletingCustomTag;
export const deleteCustomTagSuccessfulSelector = (state: RootState) =>
  fromCustomTags(state).deletedCustomTag;
export const deleteCustomTagFailedSelector = (state: RootState) =>
  fromCustomTags(state).deletedCustomTagFail;

export const deleteCustomTagFromCommentSelector = (state: RootState) =>
  fromCustomTags(state).deletingCustomTagFromComment;
export const deleteCustomTagFromCommentSuccessfulSelector = (state: RootState) =>
  fromCustomTags(state).deletedCustomTagFromComment;
export const deleteCustomTagFromCommentFailedSelector = (state: RootState) =>
  fromCustomTags(state).deletedCustomTagFromCommentFail;

export const deletingCustomTagFromReplyIdSelector = (state: RootState) =>
  fromCustomTags(state).deletingCustomTagFromReplyId;
