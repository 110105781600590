import React from "react";
import classNames from "classnames/bind";

import Attachments from "../attachment/Attachment";

import s from "./ThreadBody.module.less";

import { IPrivateMessageProps } from "./ThreadBody.type";
import { cropText } from "@utils/cropText";
import { getStoryPreviewMessage } from "../../../MessageCard/privateMessageCard/PrivateMessageCard.helpers"; // eslint-disable-line max-len

const StoryMessage: React.FC<IPrivateMessageProps> = ({ message }) => {
  const hasMessage = !!message.message && message.message.trim().length > 0;

  return message.attachments && message.attachments.length > 0 ? (
    <div className={s.bbStoryMessage}>
      <div className={s.bbStoryMessageDescription}>
        {getStoryPreviewMessage(message.attachments[0].description, message.owned)}
      </div>
      <Attachments
        isPreviewMessage
        isStoryAttachment
        isOwned={message.owned}
        createdTime={message.created_time}
        attachments={message.attachments || []}
        className={classNames({
          [s.bbStoryMessageAttachmentWithText]: hasMessage,
        })}
      />
      {hasMessage && (
        <div className={s.bbThreadBodyMessageBubble}>{cropText(`${message.message}`, 150)}</div>
      )}
    </div>
  ) : null;
};

export default StoryMessage;
