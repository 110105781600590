// PREDEFINED FILTERS
export const GET_PREDEFINED_FILTERS = "PREDEFINED_FILTERS/GET_PREDEFINED_FILTERS";
export const GET_PREDEFINED_FILTERS_SUCCESS = "PREDEFINED_FILTERS/GET_PREDEFINED_FILTERS_SUCCESS";
export const GET_PREDEFINED_FILTERS_FAILURE = "PREDEFINED_FILTERS/GET_PREDEFINED_FILTERS_FAILURE";

// POST SAVED FILTER
export const POST_SAVED_FILTER = "SAVED_FILTERS/POST_SAVED_FILTER";
export const POST_SAVED_FILTER_SUCCESS = "SAVED_FILTERS/POST_SAVED_FILTER_SUCCESS";
export const POST_SAVED_FILTER_FAILURE = "SAVED_FILTERS/POST_SAVED_FILTER_FAILURE";

// PUT SAVED FILTER
export const PUT_SAVED_FILTER = "SAVED_FILTERS/PUT_SAVED_FILTER";

// DELETE SAVED FILTER
export const DELETE_SAVED_FILTER = "SAVED_FILTERS/DELETE_SAVED_FILTER";
export const DELETE_SAVED_FILTER_SUCCESS = "SAVED_FILTERS/DELETE_SAVED_FILTER_SUCCESS";
export const DELETE_SAVED_FILTER_FAILURE = "SAVED_FILTERS/DELETE_SAVED_FILTER_FAILURE";

export const RESET_SAVED_FILTERS_DATA = "SAVED_FILTERS/RESET_SAVED_FILTERS_DATA";
