import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./GoogleLoginButton.module.less";

import { Logoogle } from "@bbdevcrew/bb_ui_kit_fe";

interface IGoogleLoginButtonProps {
  isLoading?: boolean;
  onClickLogin: (event: MouseEvent) => void;
}

const GoogleLoginButton: FC<IGoogleLoginButtonProps> = ({ isLoading, onClickLogin }) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbGoogleLoginButton} onClick={onClickLogin}>
      {isLoading ? (
        <Loading
          isLoading
          type="spinning"
          style={{
            width: 173,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      ) : (
        <React.Fragment>
          <Logoogle />
          <span className={s.bbGoogleLoginButtonText}>{t("components:signInButtons:google")}</span>
        </React.Fragment>
      )}
    </div>
  );
};

export default GoogleLoginButton;
