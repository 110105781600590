import React from "react";

import { UserAvatar } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./StreamsModal.module.less";

import { IModerationGroup } from "@store/careCps/types";

export const mapUsersToAccessSelectOptions = (data: IModerationGroup[], query: string) => {
  return data.map(group => ({
    label: group.group_label.charAt(0).toUpperCase() + group.group_label.slice(1),
    value: group.group_id,
    children: group.users.map(user => ({
      label: (
        <div className={s.bbAccessSelectLabel}>
          <UserAvatar
            firstName={user.name.split(" ")[0]}
            lastName={user.name.split(" ")[1]}
            className="bbAccessSelectAvatar"
          />{" "}
          {user.name}
        </div>
      ),
      value: user.id,
      hidden: !!(query && !user.name.toLowerCase().includes(query.toLowerCase())),
    })),
  }));
};

export const matchSubstring = (query: string) => (text: string) =>
  text.toLowerCase().includes(query.toLowerCase());

export const defaultColor = "#52A2F2";
