import React from "react";

import { IComment } from "@store/comments/types";
import { IMessage } from "@store/replies/types";
import { TrustpilotMessage } from "./ReviewMessage";
import { GoogleBusinessMessage } from "./ReviewMessage";
import { isTrustpilotPlatform, isGoogleBusinessPlatform } from "@utils/platform";

export const getMessage = (message: IMessage["message"], comment?: IComment) => {
  if (!!comment?.platform_type?.id && isTrustpilotPlatform(comment?.platform_type?.id)) {
    return (
      <TrustpilotMessage
        message={message}
        title={comment.title}
        date={comment.tp_data?.experienced_at}
        stars={comment.recommendation_type}
      />
    );
  } else if (!!comment?.platform_type?.id && isGoogleBusinessPlatform(comment?.platform_type?.id)) {
    return (
      <GoogleBusinessMessage
        message={message}
        title={comment.title}
        date={comment.tp_data?.experienced_at}
        stars={comment.recommendation_type}
      />
    );
  }

  return message;
};
