import React from "react";
import { useTranslation } from "react-i18next";

import { Form } from "antd";
import { ColorPicker, FormErrorMessage, Input, ScrollView } from "@bbdevcrew/bb_ui_kit_fe";
import { ClientSelect } from "./ClientSelect";
import { AccessSelect } from "./AccessSelect";
import JSONEditor from "@components/_common/JSONEditor";

import { defaultPresets } from "@components/_common/AppFilter/filters/SavedFilters/SaveFilterModal.helpers"; // eslint-disable-line max-len
import { defaultColor } from "./StreamsModal.helpers";

import s from "./StreamsModal.module.less";

import { ISteamsFormProps } from "./StreamsModal.types";

export const StreamForm: React.FC<ISteamsFormProps> = ({ form, onSave }) => {
  const { t } = useTranslation();

  return (
    <ScrollView className={s.bbStreamForm}>
      <>
        <Form
          form={form}
          layout="vertical"
          requiredMark="optional"
          autoComplete="off"
          onFinish={onSave}
        >
          <div className={s.bbStreamFormGroup}>
            <Form.Item
              name="id"
              label={t("generic:id")}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage>{t("generic:validation.required")}</FormErrorMessage>,
                },
              ]}
            >
              <Input name="id" _size="sm" />
            </Form.Item>
            <Form.Item
              name="color"
              label={t("generic:color")}
              initialValue={defaultColor}
              valuePropName="hex"
            >
              <ColorPicker
                key="color"
                onChange={color => {
                  form.setFieldsValue({ color });
                }}
                presets={[
                  {
                    label: t("generic:presets"),
                    open: true,
                    colors: defaultPresets,
                  },
                ]}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="name"
            label={t("generic:name")}
            rules={[
              {
                required: true,
                message: <FormErrorMessage>{t("generic:validation.required")}</FormErrorMessage>,
              },
            ]}
          >
            <Input name="name" _size="md" />
          </Form.Item>
          <div className={s.bbStreamFormGroup}>
            <Form.Item
              name="client_id"
              label={t("generic:client")}
              rules={[
                {
                  required: true,
                  message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
                },
              ]}
            >
              <ClientSelect />
            </Form.Item>
            <Form.Item noStyle>
              <Form.Item name="users" hidden initialValue={[]} />
              <Form.Item name="roles" hidden initialValue={[]} />
              <Form.Item
                label={t("components:careCps:streamsModal:form:access")}
                required
                dependencies={["users", "roles"]}
                shouldUpdate
              >
                {() => (
                  <AccessSelect
                    value={{
                      users: form.getFieldValue("users") || [],
                      roles: form.getFieldValue("roles") || [],
                    }}
                    onChange={({ users, roles } = { users: [], roles: [] }) => {
                      form.setFieldsValue({ users, roles });
                    }}
                  />
                )}
              </Form.Item>
            </Form.Item>
          </div>
          <hr className={s.bbStreamFormRule} />
          <h3 className={s.bbStreamFormTitle}>
            {t("components:careCps:streamsModal:form:settingsTitle")}
          </h3>
          <Form.Item
            name="filters"
            label={t("components:careCps:streamsModal:form:filters:label")}
            normalize={JSON.parse}
            rules={[
              {
                required: true,
                message: <FormErrorMessage>{t("generic:validation:required")}</FormErrorMessage>,
              },
            ]}
          >
            <JSONEditor options={{ mode: "code" }} />
          </Form.Item>
        </Form>
      </>
    </ScrollView>
  );
};
