import { createReducer, ActionType } from "typesafe-actions";
import { IAIPromptsState } from "./types";
import {
  createPromptSuccessAction,
  updatePromptSuccessAction,
  getPromptsSuccessAction,
  deletePromptSuccessAction,
  setPromptAsDefaultSuccessAction,
  getAIPromptSuggestionSuccessAction,
} from "./actions";
import {
  GET_AI_PROMPTS_FAILURE,
  GET_AI_PROMPTS_SUCCESS,
  CREATE_AI_PROMPT,
  CREATE_AI_PROMPT_FAILURE,
  CREATE_AI_PROMPT_SUCCESS,
  UPDATE_AI_PROMPT,
  UPDATE_AI_PROMPT_FAILURE,
  UPDATE_AI_PROMPT_SUCCESS,
  DELETE_AI_PROMPT,
  DELETE_AI_PROMPT_FAILURE,
  DELETE_AI_PROMPT_SUCCESS,
  SET_AI_PROMPT_AS_DEFAULT,
  SET_AI_PROMPT_AS_DEFAULT_FAILURE,
  SET_AI_PROMPT_AS_DEFAULT_SUCCESS,
  CLEAR_AI_PROMPT,
  GET_PROMPT_AI_SUGGESTION,
  GET_PROMPT_AI_SUGGESTION_SUCCESS,
  GET_PROMPT_AI_SUGGESTION_FAILURE,
  CLEAR_AI_PROMPT_SUGGESTION,
} from "./actionTypes";
import { IAIPromptsResponse } from "./types";

const initialState = {
  AIPrompts: {
    presets: [],
    items: [],
  } as IAIPromptsResponse,
  AISuggestion: undefined,
  postingAIPrompt: false,
  postedAIPrompt: false,
  postedAIPromptFail: false,
  updatingAIPrompt: false,
  updatedAIPrompt: false,
  updatedAIPromptFail: false,
  fetchingPromptAISuggestion: false,
  fetchedPromptAISuggestion: false,
  fetchedPromptAISuggestionFail: false,
};

export const repliesAIPromptsReducer = createReducer<IAIPromptsState>(initialState, {
  // CREATE
  [CREATE_AI_PROMPT]: (state: IAIPromptsState) => ({
    ...state,
    postingAIPrompt: true,
    postedAIPrompt: false,
    postedAIPromptFail: false,
  }),
  [CREATE_AI_PROMPT_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof createPromptSuccessAction>,
  ) => ({
    ...state,
    AIPrompts: {
      presets: [...state.AIPrompts.presets],
      items: [...state.AIPrompts.items, action.payload],
    },
    postingAIPrompt: false,
    postedAIPrompt: true,
    postedAIPromptFail: false,
  }),
  [CREATE_AI_PROMPT_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
    postingAIPrompt: false,
    postedAIPrompt: false,
    postedAIPromptFail: true,
  }),

  // UPDATE
  [UPDATE_AI_PROMPT]: (state: IAIPromptsState) => ({
    ...state,
    updatingAIPrompt: true,
    updatedAIPrompt: false,
    updatedAIPromptFail: false,
  }),
  [UPDATE_AI_PROMPT_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof updatePromptSuccessAction>,
  ) => {
    const promptIdx = state.AIPrompts?.items.findIndex(({ id }) => id === action.payload.id);

    const promptListCopy = state.AIPrompts?.items;
    promptListCopy[promptIdx] = action.payload;

    return {
      ...state,
      AIPrompts: {
        presets: [...state.AIPrompts.presets],
        items: promptListCopy,
      },
      updatingAIPrompt: false,
      updatedAIPrompt: true,
      updatedAIPromptFail: false,
    };
  },
  [UPDATE_AI_PROMPT_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
    updatingAIPrompt: false,
    updatedAIPrompt: false,
    updatedAIPromptFail: true,
  }),

  // GET LIST
  [GET_AI_PROMPTS_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof getPromptsSuccessAction>,
  ) => {
    return {
      ...state,
      AIPrompts: {
        presets: [...action.payload.presets],
        items: [...action.payload.items],
      },
    };
  },
  [GET_AI_PROMPTS_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
  }),

  // DELETE
  [DELETE_AI_PROMPT]: (state: IAIPromptsState) => ({
    ...state,
  }),
  [DELETE_AI_PROMPT_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof deletePromptSuccessAction>,
  ) => {
    const isDeletedPromptDefault = state.AIPrompts.items.find(
      item => item.id === action.payload,
    )?.is_default;

    return {
      ...state,
      AIPrompts: {
        presets: [
          ...state.AIPrompts.presets.map((item, index) => {
            if (isDeletedPromptDefault && index === 0) {
              // If the deleted prompt was the default, set first presets prompt as default
              return {
                ...item,
                is_default: true,
              };
            }
            return item;
          }),
        ],
        items: state.AIPrompts.items.filter(item => item.id !== action.payload),
      },
    };
  },
  [DELETE_AI_PROMPT_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
  }),

  // SET AS DEFAULT
  [SET_AI_PROMPT_AS_DEFAULT]: (state: IAIPromptsState) => ({
    ...state,
  }),
  [SET_AI_PROMPT_AS_DEFAULT_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof setPromptAsDefaultSuccessAction>,
  ) => {
    return {
      ...state,
      AIPrompts: {
        presets: [
          ...state.AIPrompts.presets.map(item =>
            item.id === action.payload
              ? { ...item, is_default: true }
              : { ...item, is_default: false },
          ),
        ],
        items: [
          ...state.AIPrompts.items.map(item => {
            if (item.id === action.payload) {
              return {
                ...item,
                is_default: true,
              };
            }
            return {
              ...item,
              is_default: false,
            };
          }),
        ],
      },
    };
  },
  [SET_AI_PROMPT_AS_DEFAULT_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
  }),

  // CREATE
  [GET_PROMPT_AI_SUGGESTION]: (state: IAIPromptsState) => ({
    ...state,
    fetchingPromptAISuggestion: true,
    fetchedPromptAISuggestion: false,
    fetchedPromptAISuggestionFail: false,
  }),
  [GET_PROMPT_AI_SUGGESTION_SUCCESS]: (
    state: IAIPromptsState,
    action: ActionType<typeof getAIPromptSuggestionSuccessAction>,
  ) => ({
    ...state,
    AISuggestion: action.payload,
    fetchingPromptAISuggestion: false,
    fetchedPromptAISuggestion: true,
    fetchedPromptAISuggestionFail: false,
  }),
  [GET_PROMPT_AI_SUGGESTION_FAILURE]: (state: IAIPromptsState) => ({
    ...state,
    fetchingPromptAISuggestion: false,
    fetchedPromptAISuggestion: false,
    fetchedPromptAISuggestionFail: true,
  }),

  // CLEAR
  [CLEAR_AI_PROMPT_SUGGESTION]: (state: IAIPromptsState) => ({
    ...state,
    AISuggestion: undefined,
    fetchingPromptAISuggestion: false,
    fetchedPromptAISuggestion: false,
    fetchedPromptAISuggestionFail: false,
  }),
  [CLEAR_AI_PROMPT]: (state: IAIPromptsState) => ({
    ...state,
    postingAIPrompt: false,
    postedAIPrompt: false,
    postedAIPromptFail: false,
    updatedAIPrompt: false,
    updatingAIPrompt: false,
    updatedAIPromptFail: false,
  }),
});
