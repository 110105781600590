import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { SimpleSelectMulti } from "@bbdevcrew/bb_ui_kit_fe";

import { careCpsModerationUsersSelector } from "@store/careCps/selectors";
import { getModerationUsersAction } from "@store/careCps/actions";

import { mapUsersToAccessSelectOptions } from "./StreamsModal.helpers";

import s from "./StreamsModal.module.less";

import { IAccessSelectProps } from "./StreamsModal.types";

export const AccessSelect: React.FC<IAccessSelectProps> = ({ value, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");

  const { data: users = [], fetching } = useSelector(careCpsModerationUsersSelector);
  const userOptions = useMemo(() => mapUsersToAccessSelectOptions(users, query), [users, query]);

  useEffect(() => {
    dispatch(getModerationUsersAction());
  }, [dispatch]);

  const allGroups = useMemo(() => users.map(group => group.group_id), [users]);
  const allUsers = useMemo(() => users.flatMap(group => group.users.map(user => user.id)), [users]);

  const handleChange = useCallback(
    (newValue?: string | string[]) => {
      newValue = newValue ? (Array.isArray(newValue) ? newValue : [newValue]) : [];
      const usersValue = newValue.filter(id => allUsers.includes(id));
      const rolesValue = newValue.filter(id => allGroups.includes(id));
      onChange?.({ users: usersValue, roles: rolesValue });
    },
    [onChange, allUsers, allGroups],
  );

  const joinedValue = useMemo(() => {
    const { users: usersValue, roles: rolesValue } = value || { users: [], roles: [] };
    return [...usersValue, ...rolesValue];
  }, [value]);

  return (
    <SimpleSelectMulti
      placeholder={t("generic:select")}
      searchInputPlaceholder={t("generic:search")}
      options={userOptions}
      allowSearch
      showResultsBeforeSearch
      onSearch={q => setQuery(q || "")}
      emptyStatePlaceholder={t("generic:emptyTable")}
      value={joinedValue}
      onChange={handleChange}
      optionsLoading={fetching}
      multiPlaceholder={t("generic:users", {
        count: (value?.users.length || 0) + (value?.roles.length || 0),
      })}
      className={s.bbAccessSelect}
    />
  );
};
