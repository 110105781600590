import React from "react";
import { useTranslation } from "react-i18next";

import PresetSelector from "@components/_common/PresetSelector";

import { useAppSidebar } from "@components/_common/AppSidebar/helpers";

import { ICareCPSStream } from "@store/streams/types";
import { IPresetSelectorProps } from "@components/_common/PresetSelector/PresetSelector.types";

export interface IStreamSelectorProps extends IPresetSelectorProps {
  stream: ICareCPSStream;
}

export const StreamSelector: React.FC<IStreamSelectorProps> = ({ stream, ...restProps }) => {
  const { t } = useTranslation();
  const { isCollapsed } = useAppSidebar();

  return (
    <PresetSelector
      isCollapsed={isCollapsed}
      value={stream}
      allLabel={t("components:careCps:streamsModal:allStreams")}
      {...restProps}
    />
  );
};
