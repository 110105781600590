import { action } from "typesafe-actions";
import {
  TIKTOK_CONNECT,
  TIKTOK_CONNECT_SUCCESS,
  TIKTOK_CONNECT_FAILURE,
  TIKTOK_SIGNUP,
  TIKTOK_SIGNUP_SUCCESS,
  TIKTOK_SIGNUP_FAILURE,
  TIKTOK_PRICING_TABLE,
  TIKTOK_PRICING_TABLE_SUCCESS,
  TIKTOK_PRICING_TABLE_FAILURE,
} from "./actionTypes";
import {
  ITiktokSignupPayload,
  ITiktokConnectPayload,
  ITiktokConnectUserResponse,
  ITikTokPricingTableItem,
} from "./types";

export const tiktokConnectAction = (data: ITiktokConnectPayload) => action(TIKTOK_CONNECT, data);
export const tiktokConnectSuccessAction = (data: ITiktokConnectUserResponse) =>
  action(TIKTOK_CONNECT_SUCCESS, data);
export const tiktokConnectFailureAction = () => action(TIKTOK_CONNECT_FAILURE);

export const tiktokSignupAction = (data: ITiktokSignupPayload) => action(TIKTOK_SIGNUP, data);
export const tiktokSignupSuccessAction = (data: ITiktokConnectUserResponse) =>
  action(TIKTOK_SIGNUP_SUCCESS, data);
export const tiktokSignupFailureAction = () => action(TIKTOK_SIGNUP_FAILURE);

export const tiktokPricingTable = () => action(TIKTOK_PRICING_TABLE);
export const tiktokPricingTableSuccessAction = (data: ITikTokPricingTableItem[]) =>
  action(TIKTOK_PRICING_TABLE_SUCCESS, data);
export const tiktokPricingTableFailureAction = () => action(TIKTOK_PRICING_TABLE_FAILURE);
