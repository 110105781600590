import React from "react";

import ErrorPosting from "./ErrorPosting";

import s from "./PublishingPost.module.less";

import { IPublishingPost } from "@store/publishings/types";
import { getPostMediaTypeIcon } from "../../../_common/PostPreviewTooltip/helpers";

interface IPublishingPostVideoPreviewProps {
  post: IPublishingPost;
}

export default function PublishingPostVideoPreview({ post }: IPublishingPostVideoPreviewProps) {
  return (
    <>
      <div className={s.bbPostVideoWrapper}>
        <video className={s.bbPostVideo}>
          <source src={post.attachments[0].url} />
        </video>
        <div className={s.bbGradientOverlay}></div>
        <ErrorPosting post={post} />
      </div>
      {!!post.message && <div className={s.bbPostCaptionCropped}>{post.message}</div>}
      <div className={s.bbPostMediaType}>{getPostMediaTypeIcon(post)}</div>
    </>
  );
}
