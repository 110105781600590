import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SelectStyled } from "@bbdevcrew/bb_ui_kit_fe";

import { tiktokTrendingHashtagsFiltersSelector } from "@store/tiktokHashtags/selectors";
import { getTrendingHashtagsFiltersAction } from "@store/tiktokHashtags/actions";

import s from "./TiktokTrendingHashtags.module.less";

import { useSearch } from "@bbdevcrew/bb_ui_kit_fe";
import { ITiktokTrendingHashtagsFiltersProps } from "./TiktokTrendingHashtags.types";
import { getIndustryOptions } from "./TiktokTrendingHashtags.helpers";
import { TiktokTrendingHashtagsIndustryFilter } from "./TiktokTrendingHashtagsIndustryFilter";

export const TiktokTrendingHashtagsFilters = ({
  value,
  onChange,
}: ITiktokTrendingHashtagsFiltersProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filtersOptions = useSelector(tiktokTrendingHashtagsFiltersSelector);
  const {
    options: searchedOptions,
    SearchInput,
    searchTerm,
    setSearchTerm,
  } = useSearch(filtersOptions?.countries || [], t("generic:search"));

  const getTrendingHashtagsFilters = useCallback(
    () => dispatch(getTrendingHashtagsFiltersAction()),
    [dispatch],
  );

  useEffect(() => {
    getTrendingHashtagsFilters();
  }, [getTrendingHashtagsFilters]);

  return (
    <div className={s.bbTTTrendingHashtagsFilters}>
      <TiktokTrendingHashtagsIndustryFilter
        options={getIndustryOptions(filtersOptions?.industries || [])}
        value={value.industryId}
        onChange={onChange}
      />
      <SelectStyled
        _size="sm"
        value={value.countryId}
        onChange={onChange("countryId")}
        highlightTerm={searchTerm}
        DropdownHeader={SearchInput}
        onDropdownClose={() => setSearchTerm("")}
        dropdownPlacement="bottomRight"
        wrapperClassName={s.bbTTTrendingHashtagsDropdownWrapper}
        dropdownMenuClassName={s.bbTTTrendingHashtagsDropdown}
        options={searchedOptions}
        disabled={!filtersOptions?.countries?.length}
      />
      <SelectStyled
        _size="sm"
        dropdownPlacement="bottomRight"
        disabled={!filtersOptions?.dateRangeOptions?.length}
        value={value.dateRangeId}
        onChange={onChange("dateRangeId")}
        wrapperClassName={s.bbTTTrendingHashtagsDropdownWrapper}
        dropdownMenuClassName={s.bbTTTrendingHashtagsDropdown}
        options={filtersOptions?.dateRangeOptions || []}
      />
    </div>
  );
};
