import dayjs from "dayjs";
import { Subject, of, merge, Observable } from "rxjs";
import { isOfType, PayloadMetaAction } from "typesafe-actions";
import { Epic, combineEpics, StateObservable } from "redux-observable";
import { mergeMap, take, map, takeUntil, filter } from "rxjs/operators";

import {
  REFRESH_CURRENT_SESSION,
  REFRESH_CURRENT_SESSION_SUCCESS,
  REFRESH_CURRENT_SESSION_FAILURE,
} from "./auth/actionTypes";
import { RootState } from ".";

export const flattenEpics = (collection: Epic[][]) =>
  collection.reduce((result, current) => result.concat(current), []);

export const createRequiresTokenEpic =
  (epics: Epic[]) =>
  (
    action$: Observable<PayloadMetaAction<string, unknown, { public?: boolean }>>,
    state$: StateObservable<RootState>,
    dependencies: unknown,
  ) => {
    const delegatorEpic = combineEpics(...epics);
    const output$ = new Subject();

    const filteredAction$ = action$.pipe(
      mergeMap(action => {
        // Check meta public true to every action
        if (!action.meta || (action.meta && !action.meta.public)) {
          if (state$.value.auth.session?.accessToken?.payload?.exp - dayjs().unix() <= 0) {
            // Kick off the refreshing of the token
            output$.next({
              type: REFRESH_CURRENT_SESSION,
              meta: {
                public: true,
              },
            });
            // Wait for a successful refresh
            return action$.pipe(
              filter(isOfType(REFRESH_CURRENT_SESSION_SUCCESS)),
              take(1),
              map(() => action),
              takeUntil(action$.pipe(filter(isOfType(REFRESH_CURRENT_SESSION_FAILURE)))),
            );
          }
        }
        // Actions which don't require auth are passed through as-is
        return of(action);
      }),
    );

    return merge(delegatorEpic(filteredAction$, state$, dependencies), output$);
  };

// Assume: All provided epics area treated as private epics
export const generateEpics = (providedEpics?: Epic[]) =>
  combineEpics(createRequiresTokenEpic(providedEpics || []));
