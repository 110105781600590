import { IObjectOption } from "@bbdevcrew/bb_ui_kit_fe";
import { IReplyUser } from "@store/replies/types";
import { IComment } from "@store/comments/types";
import { IInternalReplyFn } from "../MessageList/MessageList.type";

export const TAG_OFFSET = 8;
export const DEFAULT_MAX_LENGTH = 1000;

export type AllowedViewsType = "create" | "list" | "edit";
export type ModeType = "modal" | "list";
export type ViewChangeFuncType = (view: AllowedViewsType, tab: string, editId?: string) => void;

export interface IReplierAsset {
  id: string;
  label: string;
  selected: boolean;
}

export interface IReplierInfo {
  enabled: boolean;
  assets: IReplierAsset[];
}

export interface IReplyProps {
  replyingCommentId: string;
  comment?: IComment;
  replyUser: IReplyUser;
  isPrivateReply?: boolean;
  maxMessageLength?: number;
  suggestedReplyMessage?: string;
  isReplyBtnLoading?: boolean;
  disabledMentions?: boolean;
  replierInfo?: IReplierInfo;
  canSendDMMedia?: boolean;
  canSendDMMediaTooltip?: string;
  onClickReply: IInternalReplyFn;
}

export interface IReplierName {
  platformId: string;
  replierInfo?: IReplierInfo;
  replyingAsset?: IObjectOption;
  setReplyingAsset: (asset?: IObjectOption) => void;
}

export interface IReplyBottomActions {
  comment?: IComment;
  isPrivateReply?: boolean;
  replyMessageLength: number;
  maxReplyMessageLength: number;
  isReplyBtnLoading?: boolean;
  isReplyBtnDisabled?: boolean;
  onInternalReplyClick: () => void;
}
