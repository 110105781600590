import { TFunction } from "i18next";
import { EmojiSet } from "emoji-mart";
import { PlatformType } from "@store/platform/types";

export const getEmojiSetForPlatform = (platform?: PlatformType): EmojiSet => {
  switch (platform) {
    case "facebook":
    case "facebook_ads":
    case "instagram":
      return "facebook";
    case "twitter":
    case "twitter_ads":
      return "twitter";
    case "youtube":
    case "youtube_ads":
    case "google_business":
      return "google";
    default:
      return "facebook";
  }
};

export const getCategories = (t: TFunction) => ({
  search: t("components:reply:emojiPicker:search"),
  recent: t("components:reply:emojiPicker:recent"),
  people: t("components:reply:emojiPicker:people"),
  nature: t("components:reply:emojiPicker:nature"),
  foods: t("components:reply:emojiPicker:foods"),
  activity: t("components:reply:emojiPicker:activity"),
  places: t("components:reply:emojiPicker:places"),
  objects: t("components:reply:emojiPicker:objects"),
  symbols: t("components:reply:emojiPicker:symbols"),
  flags: t("components:reply:emojiPicker:flags"),
  custom: t("components:reply:emojiPicker:custom"),
});
