import React from "react";
import classNames from "classnames";

import { Button, ChevronDownIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DropdownMenuTrigger.module.less";

import { IDropdownMenuTriggerProps } from "./DropdownMenuTrigger.types";

export const DropdownMenuTrigger: React.FC<IDropdownMenuTriggerProps> = ({
  open,
  className,
  label,
}) => (
  <Button
    _type="on-surface"
    _size="sm"
    className={classNames(
      s.bbDropdownMenuTrigger,
      { [s.bbDropdownMenuTriggerOpen]: open },
      className,
    )}
  >
    {label} <ChevronDownIcon />
  </Button>
);
