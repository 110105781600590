import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { NotificationsSection } from "./NotificationsSection";
import { NotificationsFooter } from "./NotificationsFooter";

import { getNotificationsSelector } from "@store/notifications/selectors";

export const NotificationsTabContent = ({ isUnreadTab }: { isUnreadTab?: boolean }) => {
  const { t } = useTranslation();

  const todayDate = dayjs();

  const notifications = useSelector(getNotificationsSelector);

  const todayNotifications = notifications.filter(({ created_at }) =>
    dayjs(created_at).isSame(todayDate, "day"),
  );

  const weekNotifications = notifications.filter(
    ({ created_at }) =>
      dayjs(created_at).isBefore(todayDate, "day") && dayjs(created_at).isSame(todayDate, "week"),
  );

  return (
    <>
      <div>
        <NotificationsSection
          noDivider
          title={t("header:notifications:times:today")}
          notifications={todayNotifications}
          isUnreadTab={isUnreadTab}
        />
        <NotificationsSection
          title={t("header:notifications:times:week")}
          notifications={weekNotifications}
          noDivider={todayNotifications.length === 0}
          isUnreadTab={isUnreadTab}
        />
        <NotificationsSection
          title={t("header:notifications:times:older")}
          notifications={notifications.filter(({ created_at }) =>
            dayjs(created_at).isBefore(todayDate, "week"),
          )}
          noDivider={todayNotifications.length === 0 && weekNotifications.length === 0}
          isUnreadTab={isUnreadTab}
        />
      </div>
      {!isUnreadTab && <NotificationsFooter total={notifications.length} />}
    </>
  );
};
