import React, { FC } from "react";

import { BarChart } from "@bbdevcrew/bb_ui_kit_fe";

import {
  BAR_SIZE,
  xAxisProps,
  totalProps,
  LABEL_WIDTH,
  generateBars,
  normalizeData,
  chartAxisColor,
  breakdownChartMargins,
  stripePattern,
} from "./helpers";
import { IBreakdownChartProps } from "./BreakdownChart.type";
import { numberToQuantity, defaultGridProps } from "@bbdevcrew/bb_ui_kit_fe";

export const BreakdownChart: FC<IBreakdownChartProps> = ({
  data,
  width,
  height,
  labelWidth,
  customTooltip,
  generateLabel,
  onYAxisLabelClick,
  hasStripePattern = true,
}) => {
  const getBars = () => {
    return generateBars(data.items?.length ? data.items[0].comment_stats : []);
  };

  return (
    <BarChart
      grid
      yAxis
      xAxis
      tooltip
      pattern={hasStripePattern ? stripePattern : undefined}
      isShowPercent
      width={width}
      height={height}
      bars={getBars()}
      layout="vertical"
      barSize={BAR_SIZE}
      stackOffset="sign"
      total={totalProps}
      xAxisProps={xAxisProps}
      percentagePrecision={1}
      gridProps={defaultGridProps}
      margin={breakdownChartMargins}
      data={normalizeData(data.items || [])}
      tooltipProps={{
        content: customTooltip,
        cursor: { fill: "transparent" },
      }}
      yAxisProps={{
        dataKey: "id",
        type: "category",
        tick: generateLabel,
        onClick: onYAxisLabelClick,
        tickFormatter: numberToQuantity,
        width: labelWidth || LABEL_WIDTH,
        axisLine: { stroke: chartAxisColor },
      }}
    />
  );
};
