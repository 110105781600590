import { RootState } from "../";

export const fromUsers = (state: RootState) => state.user;

export const getUsersSelector = (state: RootState) => fromUsers(state).user;

export const signingUserUpSelector = (state: RootState) => fromUsers(state).signingUserUp;
export const signedUserUpSelector = (state: RootState) => fromUsers(state).signedUserUp;
export const signedUserUpSelectorFail = (state: RootState) => fromUsers(state).signedUserUpFail;

export const accessToken = (state: RootState) => fromUsers(state).access_token;
export const cognitoCookies = (state: RootState) => fromUsers(state).cookies;

export const emailValidationResponse = (state: RootState) =>
  fromUsers(state).emailValidationResponse;
