import { useSelector } from "react-redux";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

import BatchModeButton from "@containers/BatchMode/BatchModeButton";
import {
  IObjectOption,
  SimpleSelectSingle,
  Button,
  ChevronDownIcon,
} from "@bbdevcrew/bb_ui_kit_fe";
import BatchModeSelectAll from "@containers/BatchMode/BatchModeSelectAll";
import AssignToMeButton from "../../../_common/AssignToMeButton/AssignToMeButton";

import { assignToMeLoadingSelector } from "@store/assign/selectors";

import s from "./InboxCommentsHeader.module.less";

import { IInboxStats } from "@store/inboxStats/types";
import { useBatchMode } from "@containers/BatchMode/BatchMode";
import { IInboxCommentsHeaderProps, RepliedMapEnum } from "./types";
import { getDefaultSortOptions, getDefaultReplyPlatformTypeOptions } from "@utils/comments";
import { ReplyPlatformType } from "@store/comments/types";

export const InboxCommentsHeader: FC<IInboxCommentsHeaderProps> = ({
  statTabValue,
  statTabParent,
  sortValue,
  replyPlatformTypeValue,
  stats,
  onSortChange,
  onClickAssignToMe,
  onReplyPlatformChange,
}) => {
  const { t } = useTranslation();
  const { isBatchModeActive } = useBatchMode();

  const assignToMeLoading = useSelector(assignToMeLoadingSelector);

  const normalizeReplyPlatformTypeOptions = useCallback(() => {
    return getDefaultReplyPlatformTypeOptions().map((option: IObjectOption) => {
      const count =
        stats[
          RepliedMapEnum[option.id as keyof typeof RepliedMapEnum] as keyof IInboxStats["stats"]
        ] || 0;
      return {
        ...option,
        value: option.id,
        label: `${option.label} (${count})`,
      };
    });
    // eslint-disable-next-line
  }, [getDefaultReplyPlatformTypeOptions()]);

  const queueNotEmpty = Number(stats[statTabValue]) > 0;

  return (
    <div>
      <div data-cy="comments-grid-header" className={s.bbInboxCommentsHeader}>
        <h5 data-cy="comments-title" className={s.bbInboxCommentsHeaderTitle}>
          {!isBatchModeActive && (
            <>
              {statTabParent && (
                <span className={s.bbInboxCommentsHeaderTitlePrefix}>
                  {t(`components:inboxMenu:tabs:${statTabParent}Label`)}
                </span>
              )}{" "}
              {t(`components:inboxMenu:tabs:${statTabValue}Label`)}
            </>
          )}
        </h5>
        <div className={s.bbInboxCommentsHeaderFilterWrapper}>
          {!isBatchModeActive && statTabValue === "replied" && (
            <SimpleSelectSingle
              value={replyPlatformTypeValue}
              onChange={value => onReplyPlatformChange(value as ReplyPlatformType)}
              options={normalizeReplyPlatformTypeOptions()}
              trigger={() => (
                <Button _type="on-surface" _size="sm" className={s.bbInboxCommentsHeaderDropdown}>
                  <label>
                    {
                      normalizeReplyPlatformTypeOptions().find(
                        option => option.value === replyPlatformTypeValue,
                      )?.label
                    }
                  </label>
                  <ChevronDownIcon />
                </Button>
              )}
            />
          )}
          {["review", "ai_highlights", "care_highlights"].includes(statTabValue) && (
            <>
              {!isBatchModeActive && queueNotEmpty && (
                <AssignToMeButton
                  loading={assignToMeLoading}
                  loadingIndicatorPosition="center"
                  data-cy="care-inbox__start-shift_button"
                  onActionHandler={closeModal => onClickAssignToMe(statTabValue, closeModal)}
                />
              )}
              {isBatchModeActive && <BatchModeSelectAll />}
              <BatchModeButton />
            </>
          )}
          {!isBatchModeActive && (
            <SimpleSelectSingle
              value={sortValue}
              onChange={value => onSortChange(value as string)}
              options={getDefaultSortOptions()}
              trigger={() => (
                <Button _type="on-surface" _size="sm" className={s.bbInboxCommentsHeaderDropdown}>
                  {getDefaultSortOptions().find(option => option.value === sortValue)?.label}
                  <ChevronDownIcon />
                </Button>
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
};
