import { RootState } from "../types";

export const fromAuth = (state: RootState) => state.auth;

export const currentSessionSelector = (state: RootState) => fromAuth(state).session;
export const currentSessionSuccessfulSelector = (state: RootState) =>
  fromAuth(state).fetchedCurrentSession;

export const isLoggedInSelector = (state: RootState) =>
  fromAuth(state).session.accessToken?.jwtToken !== "";

export const getTokenSelector = (state: RootState) => fromAuth(state).session.accessToken?.jwtToken;

export const mfaSelector = (state: RootState) => fromAuth(state).mfa;
export const cognitoUserSelector = (state: RootState) => fromAuth(state).user;
export const codeTOTPSelector = (state: RootState) => fromAuth(state).codeTOTP;

export const setMfaSelectorSuccessful = (state: RootState) => fromAuth(state).fetchedSetMfa;
export const setMfaSelectorFailure = (state: RootState) => fromAuth(state).fetchedSetMfaFail;

export const getMfaSelectorSuccessful = (state: RootState) => fromAuth(state).fetchedGetMfa;
export const getMfaSelectorFailure = (state: RootState) => fromAuth(state).fetchedGetMfaFail;

export const setupTOTPSelectorSuccessful = (state: RootState) => fromAuth(state).fetchedSetupTOTP;
export const setupTOTPSelectorFailure = (state: RootState) => fromAuth(state).fetchedSetupTOTPFail;

export const verifyTOTPSelectorSuccessful = (state: RootState) => fromAuth(state).fetchedVerifyTOTP;
export const verifyTOTPSelectorFailure = (state: RootState) =>
  fromAuth(state).fetchedVerifyTOTPFail;
