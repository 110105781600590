import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import CommentAction from "../commentAction/CommentAction";

import { IComment } from "@store/comments/types";

import { SendIcon } from "@assets/index";

interface IActionReplyPrivatelyProps {
  tooltip?: string;
  disabled?: boolean;
  className?: string;
  comment: IComment;
  onPrivateReplyClick?: (comment: IComment) => void;
}

const ActionReplyPrivately: FC<IActionReplyPrivatelyProps> = ({
  comment,
  tooltip,
  disabled,
  className = "",
  onPrivateReplyClick,
}) => {
  const { t } = useTranslation();

  const tooltipTitle: string = tooltip || t("components:comments:tooltips:replyPrivately");

  const onClickReplyPrivately = () => {
    onPrivateReplyClick?.(comment);
  };

  return (
    <CommentAction
      type="primary"
      className={className}
      disabled={disabled}
      onClick={onClickReplyPrivately}
      tooltipProps={{ title: tooltipTitle }}
    >
      {<SendIcon />}
      {t("components:comments:actions:replyPrivately")}
    </CommentAction>
  );
};

export default ActionReplyPrivately;
