import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import s from "./Parent.module.less";

import Info from "../info/Info";

import { IParentProps } from "./Parent.type";
import { PlatformType } from "@store/platform/types";

const Parent: React.FC<IParentProps> = ({
  comment,
  functions,
  child,
  commentIndex,
  showHiddenIcon,
}) => {
  const [messageComment, setMessageComment] = useState<string>(
    comment?.message ? comment?.message : "",
  );

  useEffect(() => {
    if (comment) {
      if (comment.is_translated) {
        setMessageComment(comment.message_translated as string);
      } else {
        setMessageComment(comment.message as string);
      }
    }
    // eslint-disable-next-line
  }, [comment]);

  const extractDate = (date: string) => {
    return dayjs(date).format("DD MMM, YYYY");
  };

  const extractTime = (date: string) => {
    return dayjs(date).format("hh:mm:ss A");
  };

  return (
    <div>
      <div className={s.bbParent}>
        <div className={s.bbParentInfo}>
          <Info
            id={child.id}
            hideLinks={true}
            index={commentIndex}
            hasTranslate={comment?.message_translated ? true : false}
            isTranslated={comment?.is_translated}
            platformIconName={comment?.platform.name as PlatformType}
            username={comment.username}
            date={extractDate(comment.created_time_pretty)}
            time={extractTime(comment.created_time_pretty)}
            isHidden={comment?.is_hidden}
            functions={functions}
            showHiddenIcon={showHiddenIcon}
            isParent={true}
          />
        </div>
        <div className={s.bbParentMessage}>{messageComment}</div>
      </div>
    </div>
  );
};

export default Parent;
