import { ActionType } from "typesafe-actions";

import * as actions from "./actions";

export type ThreadsActions = ActionType<typeof actions>;

export interface IAIInsightsAction {
  id: string;
  label: string;
  is_selected: boolean;
  type: string;
}

export interface IAIInsightsMessage {
  id: string;
  timestamp: string;
  message: string;
  owner: "ai" | "user";
  call_to_actions_options?: IAIInsightsAction[];
}

export enum AIInsightsModalStateEnum {
  Closed,
  Collapsed,
  Expanded,
}

export interface IAIInsightsState {
  state: AIInsightsModalStateEnum;
  title: string;
  messages: IAIInsightsMessage[];
  id: string;
  initialState: boolean;
  previousThread?: IPostThreadsPayload["previous_thread"];

  fetchingGetMessages: boolean;
  fetchedGetMessages: boolean;
  failedGetMessages: boolean;
}

export interface IPostThreadsPayload {
  created_thread: {
    id: string;
    title: string;
  };
  previous_thread: {
    id: string;
    title: string;
  };
}

export interface IGetThreadMessagesData {
  messages: IAIInsightsMessage[];
  thread_title?: string;
  message_id_waiting_for_response?: string;
  isPreviousThread?: boolean;
  id: string;
}

export interface IGetThreadMessagesPayload {
  id: string;
  isPreviousThread?: boolean;
}

export interface ISendThreadMessagesPayload {
  id: string;
  message: string;
}

export interface IGetMessagesResponsePayload {
  id: string;
  messageId: string;
}

export interface IGetMessagesResponseSuccessPayload {
  items: IAIInsightsMessage[];
  thread_title: string;
}

export interface ISendThreadMessagesSuccessPayload {
  created_message: IAIInsightsMessage;
  initial_responses: IAIInsightsMessage[];
  thread_title: string;
}
