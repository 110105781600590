import dayjs from "dayjs";
import React from "react";

import { TiktokColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SharePostItem.module.less";

import { SharePostItemProps } from "../SharePostSelector.type";

export const SharePostItem = ({ post, onClick }: SharePostItemProps) => {
  const [error, setError] = React.useState<boolean>(false);

  return (
    <li className={s.bbSharePostSelectorModalItem} onClick={onClick}>
      <div className={s.bbSharePostSelectorModalItemImageWrapper}>
        {error ? (
          <div className={s.bbSharePostSelectorModalItemNoImage}>No Image</div>
        ) : (
          <img src={post.picture} onError={() => setError(true)} />
        )}
      </div>
      <div className={s.bbSharePostSelectorModalItemTextsWrapper}>
        <div className={s.bbSharePostSelectorModalItemTitleWrapper}>
          <TiktokColorIcon />
          <div className={s.bbSharePostSelectorModalItemTitleTextsWrapper}>
            <span className={s.bbSharePostSelectorModalItemTitle}>{post.page_name}</span>
            <span className={s.bbSharePostSelectorModalItemSubtitle}>
              {dayjs(post.created_time * 1000).format("DD MMM, YYYY")}
            </span>
          </div>
        </div>
        <div className={s.bbSharePostSelectorModalItemDescription}>{post.message}</div>
      </div>
    </li>
  );
};
