import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import s from "./BreakdownChartLegend.module.less";

import { useTiktokEnabled } from "@utils/useTiktokEnabled";

import { CommentIcon } from "@assets/index";

interface IBreakdownChartLegendProps {
  chartLabelWidth: number;
}

const BreakdownChartLegend: FC<IBreakdownChartLegendProps> = ({ chartLabelWidth }) => {
  const { t } = useTranslation();
  const isTiktokEnabled = useTiktokEnabled();

  return (
    <div className={s.bbBreakdownLegend} style={{ marginLeft: chartLabelWidth }}>
      <div className={s.bbBreakdownLegendComments}>
        <div className={s.bbComments}>
          <CommentIcon />
        </div>
        <div>{t("components:breakdown:count")}</div>
      </div>
      <div className={s.bbBreakdownLegendSentiments}>
        <div className={s.bbLegendNegative}></div>
        {t("components:postTrends:charts:negative")}

        <div className={s.bbLegendPositive}></div>
        {t("components:postTrends:charts:positive")}

        <div className={s.bbLegendUserTags}></div>
        {t("components:postTrends:charts:user_tags")}

        <div className={s.bbLegendNeutral}></div>
        {t("components:postTrends:charts:neutral")}

        {isTiktokEnabled && (
          <>
            <div className={s.bbLegendNotApplicable}></div>
            {t("components:postTrends:charts:not_applicable")}
          </>
        )}
      </div>
    </div>
  );
};

export default BreakdownChartLegend;
