import React, {
  createContext,
  createRef,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { Grid } from "antd";

import { IAppSidebarContext, SidebarPanelStateEnum } from "./AppSidebar.types";

import {
  CommentIcon,
  GridIcon,
  HashIcon,
  HeadphoneIcon,
  HeartIcon,
  StarIcon,
  RepeatIcon,
  PostIcon,
  ListIcon,
  InboxIcon,
  HourGlassIcon,
  ReplyIcon,
  ArchiveIcon,
  UserAssignedIcon,
  AlertTriangleFilledIcon,
  UserBlockIcon,
} from "@assets/index";
import { CheckIcon, CloseIcon, UserGroupIcon } from "@bbdevcrew/bb_ui_kit_fe";

export type ModuleIconMapType = {
  [key: string]: FeatureModuleIconMapType;
};

export type FeatureModuleIconMapType = {
  [key: string]: ReactNode;
};

export const AnalyzeModuleIconMap: FeatureModuleIconMapType = {
  topics: <ListIcon />,
  "report-profiles": <UserBlockIcon />,
  "listen-overview": <GridIcon />,
  "listen-sentiment_details": <HeartIcon />,
  "listen-reviews": <StarIcon />,
  "listen-agent_performance": <UserGroupIcon />,
  "listen-conversation_list": <CommentIcon />,
  "listen_more-trending_hashtags": <HashIcon />,
  "listen_more-listening_sources": <HeadphoneIcon />,
  "report-posts": <PostIcon />,
  "report-compare": <RepeatIcon />,
};

export const CareCPSModuleIconMap: FeatureModuleIconMapType = {
  all: <InboxIcon />,
  review: <HourGlassIcon />,
  starred: <StarIcon />,
  done: <CheckIcon />,
  replied: <ReplyIcon />,
  ignored: <ArchiveIcon />,
  assigned_to_me: <UserAssignedIcon />,
  cancelled: <CloseIcon />,
  errors: <AlertTriangleFilledIcon />,
};

export const ModuleIconMap: ModuleIconMapType = {
  listen: AnalyzeModuleIconMap,
  insights: AnalyzeModuleIconMap,
  care_cps: CareCPSModuleIconMap,
};

export const unreadFeatures = ["assigned_to_me"];

export const AppSidebarContext = createContext<IAppSidebarContext>({
  sidebarPanelState: SidebarPanelStateEnum.EXPANDED,
  isCollapsed: false,
  isExpanded: false,
  isPreviewMode: false,
  collapsePanel: () => null,
  expandPanel: () => null,
  previewPanel: () => null,
  ref: createRef<HTMLDivElement>(),
});

export const AppSidebarProvider: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const screens = Grid.useBreakpoint();
  const isMobileView = !screens.md;

  const [sidebarPanelState, setSidebarPanelState] = useState(SidebarPanelStateEnum.EXPANDED);

  const isCollapsed = sidebarPanelState === SidebarPanelStateEnum.COLLAPSED;
  const isExpanded = sidebarPanelState === SidebarPanelStateEnum.EXPANDED;
  const isPreviewMode = sidebarPanelState === SidebarPanelStateEnum.COLLAPSE_PREVIEW;

  const collapsePanel = useCallback(
    () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSED),
    [],
  );
  const expandPanel = useCallback(() => setSidebarPanelState(SidebarPanelStateEnum.EXPANDED), []);
  const previewPanel = useCallback(
    () => setSidebarPanelState(SidebarPanelStateEnum.COLLAPSE_PREVIEW),
    [],
  );

  const collapseTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseLeave = useCallback(() => {
    if (isPreviewMode) {
      if (collapseTimeout.current) clearTimeout(collapseTimeout.current);
      collapseTimeout.current = setTimeout(collapsePanel, 300);
    }
  }, [isPreviewMode, collapsePanel]);

  const handleMouseEnter = useCallback(() => {
    if (collapseTimeout.current) clearTimeout(collapseTimeout.current);
    if (isCollapsed) {
      previewPanel();
    }
  }, [isCollapsed, previewPanel]);

  useEffect(() => {
    if (isMobileView) collapsePanel();
    else expandPanel();
    // eslint-disable-next-line
  }, [isMobileView]);

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;

    if (ref.current) {
      ref.current.addEventListener("mouseleave", handleMouseLeave, { signal });
      ref.current.addEventListener("mouseenter", handleMouseEnter, { signal });
    }

    return () => {
      abortController.abort();
    };
  }, [handleMouseLeave, handleMouseEnter]);

  return (
    <AppSidebarContext.Provider
      value={{
        sidebarPanelState,
        isCollapsed,
        isExpanded,
        isPreviewMode,
        collapsePanel,
        expandPanel,
        previewPanel,
        ref,
      }}
    >
      {children}
    </AppSidebarContext.Provider>
  );
};

export const useAppSidebar = () => useContext(AppSidebarContext);
