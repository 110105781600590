import React, { FC, MouseEvent } from "react";
import { useTranslation } from "react-i18next";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TwitterLoginButton.module.less";

import { TwitterColorIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface ITwitterLoginButtonProps {
  isLoading?: boolean;
  onClickLogin: (event: MouseEvent) => void;
}

const TwitterLoginButton: FC<ITwitterLoginButtonProps> = ({ isLoading, onClickLogin }) => {
  const { t } = useTranslation();

  return (
    <div
      className={s.bbTwitterLoginButton}
      onClick={isLoading ? undefined : onClickLogin}
      data-stonly-target="app-global__twitter-login-button"
    >
      {isLoading ? (
        <Loading type="spinning" isLoading />
      ) : (
        <div className={s.bbTwitterLoginButtonText}>
          <span className={s.bbTwitterLoginButtonIcon}>
            <TwitterColorIcon />
          </span>
          <span className={s.loginButtonText}>{t("components:signInButtons:twitter")}</span>
        </div>
      )}
    </div>
  );
};

export default TwitterLoginButton;
