import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { HelpIcon, Tooltip, Toggle } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CrawlingAdCommentsToggle.module.less";

interface ICrawlingAdCommentsToggleProps {
  isWebhookEnabled?: boolean;
  onWebhookChange?: ({ target: { checked } }: { target: { checked: boolean } }) => boolean;
}

const CrawlingAdCommentsToggle: FC<ICrawlingAdCommentsToggleProps> = ({
  isWebhookEnabled,
  onWebhookChange,
}) => {
  const { t } = useTranslation();

  return (
    <div className={s.bbCrawlingAdCommentsToggle}>
      <div className={s.bbCrawlingAdCommentsToggleTitle}>
        <span>{t("components:updateAsset:monitoringAdComments")}</span>
        <Tooltip title={t("components:updateAsset:monitoringAdCommentsTooltip")}>
          <span className={s.bbCrawlingAdCommentsToggleTitleIcon}>
            <HelpIcon />
          </span>
        </Tooltip>
      </div>
      <Toggle id="monitor_webhook" checked={isWebhookEnabled} onChange={onWebhookChange} />
    </div>
  );
};

export default CrawlingAdCommentsToggle;
