import { produce } from "immer";
import { createReducer, ActionType } from "typesafe-actions";

import {
  postCommentsSuccessAction,
  translateCommentAction,
  loadCommentsPageSuccessAction,
  getInboxRepliesAction,
  performCommentAction,
  getCommentsCSVSuccessAction,
  updateCommentSentimentSuccessAction,
  performBulkCommentAction,
  performBulkCommentActionSuccessAction,
  postCommentsFailureAction,
  postCommentsAction,
  assignCommentUserAction,
  getCommentByIdSuccessAction,
  postCommentsSearchAction,
  postCommentsSearchSuccessAction,
  postCommentsSearchFailureAction,
  getInboxRepliesAttachmentsSuccessAction,
} from "./actions";
import {
  postCustomTagAction,
  postCustomTagSuccessAction,
  postCustomTagFailureAction,
  deleteCustomTagFromCommentSuccessAction,
  deleteCustomTagSuccessAction,
} from "../customTags/actions";
import {
  deleteCommentTagSuccessAction,
  postCommentTagSuccessAction,
} from "@store/commentTags/actions";
import { deleteBrandReplySuccessAction, postCommentReplySuccessAction } from "../replies/actions";

import {
  POST_COMMENTS,
  POST_COMMENTS_SUCCESS,
  POST_COMMENTS_FAILURE,
  GET_COMMENT_BY_ID,
  GET_COMMENT_BY_ID_SUCCESS,
  GET_COMMENT_BY_ID_FAILURE,
  TRANSLATE_COMMENT,
  LOAD_COMMENTS_PAGE_SUCCESS,
  GET_INBOX_REPLIES,
  PERFORM_COMMENT_ACTION,
  PERFORM_COMMENT_ACTION_SUCCESS,
  GET_COMMENTS_CSV,
  GET_COMMENTS_CSV_SUCCESS,
  RESET_COMMENTS_CSV_STATE,
  ASSIGN_COMMENT_USER,
  UPDATE_COMMENT_SENTIMENT,
  PERFORM_COMMENT_ACTION_FAILURE,
  PERFORM_BULK_COMMENT_ACTION,
  PERFORM_BULK_COMMENT_ACTION_SUCCESS,
  PERFORM_BULK_COMMENT_ACTION_FAILURE,
  TRIGGER_NEXT_BATCH,
  POST_COMMENTS_SEARCH,
  POST_COMMENTS_SEARCH_SUCCESS,
  POST_COMMENTS_SEARCH_FAILURE,
  GET_INBOX_REPLIES_ATTACHMENTS,
  GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS,
  GET_INBOX_REPLIES_ATTACHMENTS_FAILURE,
} from "./actionTypes";
import {
  POST_CUSTOM_TAG,
  POST_CUSTOM_TAG_SUCCESS,
  POST_CUSTOM_TAG_FAILURE,
  DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS,
  DELETE_CUSTOM_TAG_SUCCESS,
} from "../customTags/actionTypes";
import {
  ADD_LINKEDIN_REACTION,
  ADD_LINKEDIN_REACTION_SUCCESS,
  DELETE_LINKEDIN_REACTION,
  DELETE_LINKEDIN_REACTION_SUCCESS,
  LINKEDIN_REACTION_FAILURE,
} from "../linkedInReactions/actionTypes";
import {
  DELETE_COMMENT_TAG_SUCCESS,
  POST_COMMENT_TAG_SUCCESS,
} from "@store/commentTags/actionTypes";
import { GET_LISTEN_PROJECT_COMMENTS } from "@store/listen/actionTypes";
import { GET_MODERATION_STREAMS_COMMENTS } from "@store/streams/actionTypes";
import { DELETE_BRAND_REPLY_SUCCESS, POST_COMMENT_REPLY_SUCCESS } from "../replies/actionTypes";

import {
  handleSuccessfulCommentActionState,
  handleFailedCommentActionState,
  ActionToReplyProp,
  canReplyToComment,
  isPrivateConversation,
} from "@utils/comments";
import { DEFAULT_COMMENT_LIST_KEY, IComment, ICommentsState, IMessageTags } from "./types";
import { ICustomTag } from "@components/_common/TagsDropdown/TagsDropdown.type";
import { addToTagsListing, removeFromTagsListing } from "@utils/tagsListingOperations";
import { getModerationStreamsCommentsAction } from "@store/streams/actions";
import { getListenProjectCommentsAction } from "@store/listen/actions";

const initialState: ICommentsState = {
  cache: {},
  lists: {
    [DEFAULT_COMMENT_LIST_KEY]: {
      comments: [],
      navigation_id: undefined,
      cursor: undefined,
      info: undefined,
      fetchingComments: false,
      fetchedComments: false,
      fetchedCommentsFail: false,
    },
  },

  comment: undefined,
  commentsCSV: undefined,
  actionsProcessing: [],

  fetchingComment: false,
  fetchedComment: false,
  fetchedCommentFail: false,

  fetchingCommentsAttachments: false,
  fetchedCommentsAttachments: false,
  fetchedCommentsAttachmentsFail: false,

  triggerNextBatch: undefined,
  performingBatchActions: false,
  performedBatchActions: false,
  performedBatchActionsFailed: false,

  fetchingPostUnhideComments: false,
  fetchedPostUnhideComments: false,
  fetchedPostUnhideCommentsFail: false,

  fetchingCommentsCSV: false,
  fetchedCommentsCSV: false,
  fetchedCommentsCSVFail: false,

  previouslyAssignedUsers: [],

  previousReactions: {},
};

export const commentsReducer = createReducer<ICommentsState>(initialState, {
  [POST_COMMENTS]: (state: ICommentsState, action: ActionType<typeof postCommentsAction>) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [GET_INBOX_REPLIES]: (
    state: ICommentsState,
    action: ActionType<typeof getInboxRepliesAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [GET_INBOX_REPLIES_ATTACHMENTS]: (state: ICommentsState) => ({
    ...state,
    fetchingCommentsAttachments: true,
    fetchedCommentsAttachments: false,
    fetchedCommentsAttachmentsFail: false,
  }),

  [GET_INBOX_REPLIES_ATTACHMENTS_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof getInboxRepliesAttachmentsSuccessAction>,
  ) => {
    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      fetchingCommentsAttachments: false,
      fetchedCommentsAttachments: true,
      fetchedCommentsAttachmentsFail: false,
      cache: {
        ...state.cache,
        ...action.payload.data.reduce(
          (acc, comment) => {
            acc[comment.id] = comment;
            return acc;
          },
          {} as Record<string, IComment>,
        ),
      },
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          comments: action.payload.data.map(comment => comment.id),
        },
      },
    };
  },

  [GET_INBOX_REPLIES_ATTACHMENTS_FAILURE]: (state: ICommentsState) => ({
    ...state,
    fetchingCommentsAttachments: false,
    fetchedCommentsAttachments: false,
    fetchedCommentsAttachmentsFail: true,
  }),

  [GET_MODERATION_STREAMS_COMMENTS]: (
    state: ICommentsState,
    action: ActionType<typeof getModerationStreamsCommentsAction>,
  ) => {
    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [GET_LISTEN_PROJECT_COMMENTS]: (
    state: ICommentsState,
    action: ActionType<typeof getListenProjectCommentsAction>,
  ) => {
    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [POST_COMMENTS_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsSuccessAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      cache: {
        ...state.cache,
        ...action.payload.comments.reduce(
          (acc, comment) => {
            acc[comment.id] = comment;
            return acc;
          },
          {} as Record<string, IComment>,
        ),
      },
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          info: action.payload.info,
          navigation_id: action.payload.navigation_id,
          cursor: action.payload.cursor,
          comments: action.payload.comments.map(comment => comment.id),
          fetchingComments: false,
          fetchedComments: true,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [POST_COMMENTS_FAILURE]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsFailureAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: false,
          fetchedCommentsFail: true,
        },
      },
    };
  },

  // GET_COMMENT_BY_ID
  [GET_COMMENT_BY_ID]: (state: ICommentsState) => {
    return {
      ...state,
      comment: undefined,
      fetchingComment: true,
      fetchedComment: false,
      fetchedCommentFail: false,
    };
  },

  [GET_COMMENT_BY_ID_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof getCommentByIdSuccessAction>,
  ) => {
    return {
      ...state,
      comment: action.payload,
      fetchingComment: false,
      fetchedComment: true,
      fetchedCommentFail: false,
    };
  },

  [GET_COMMENT_BY_ID_FAILURE]: (state: ICommentsState) => {
    return {
      ...state,
      comment: undefined,
      fetchingComment: false,
      fetchedComment: false,
      fetchedCommentFail: true,
    };
  },

  // Pagination Success
  [LOAD_COMMENTS_PAGE_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof loadCommentsPageSuccessAction>,
  ) => {
    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;
    const comments = action.payload.replacePrevPage
      ? action.payload.comments.map(comment => comment.id)
      : [
          ...state.lists[conversation_list_id].comments,
          ...action.payload.comments.map(comment => comment.id),
        ];

    return {
      ...state,
      cache: {
        ...state.cache,
        ...action.payload.comments.reduce(
          (acc, comment) => {
            acc[comment.id] = comment;
            return acc;
          },
          {} as Record<string, IComment>,
        ),
      },
      lists: {
        ...state.lists,
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          info: action.payload.info,
          navigation_id: action.payload.navigation_id,
          cursor: action.payload.cursor,
          comments: comments,
          fetchingComments: false,
          fetchedComments: true,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [TRANSLATE_COMMENT]: (
    state: ICommentsState,
    action: ActionType<typeof translateCommentAction>,
  ) => {
    const comment = state.cache[action.payload.comment_id];

    if (comment) {
      if (!action.payload.isParent) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.comment_id]: {
              ...comment,
              is_translated: action.payload.isParent
                ? comment.is_translated
                : !comment.is_translated,
            },
          },
        };
      } else if (comment.parent && action.payload.isParent) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.comment_id]: {
              ...comment,
              parent: {
                ...comment.parent,
                is_translated: !comment.parent?.is_translated,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          fetchingSearch: false,
          fetchedSearch: false,
          fetchedSearchFail: false,
        };
      }
    }

    return state;
  },

  [PERFORM_COMMENT_ACTION]: (
    state: ICommentsState,
    action: ActionType<typeof performCommentAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.id]: {
            ...comment,
            actionsProcessing: [...state.actionsProcessing, action.payload.action],
          },
        },
      };
    }

    return state;
  },

  [PERFORM_COMMENT_ACTION_SUCCESS]: handleSuccessfulCommentActionState,
  [PERFORM_COMMENT_ACTION_FAILURE]: handleFailedCommentActionState,

  [POST_COMMENT_REPLY_SUCCESS]: (
    state: ICommentsState,
    { payload: { commentId, data } }: ActionType<typeof postCommentReplySuccessAction>,
  ) => {
    const comment = state.cache[commentId];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [commentId]: {
            ...comment,
            reply: {
              ...comment.reply,
              is_sent: true,
              raw_message: data.raw_message,
              replied_as: data.replied_as,
            },
          },
        },
      };
    }

    return state;
  },

  // CUSTOM TAG ACTIONS
  [POST_CUSTOM_TAG]: (state: ICommentsState, action: ActionType<typeof postCustomTagAction>) => {
    if (action.payload.comment_id) {
      const comment = state.cache[action.payload.comment_id];

      const newTag: ICustomTag = {
        label: action.payload.label,
        newlyAdded: true,
      };

      if (comment) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.comment_id]: {
              ...comment,
              custom_tags_by_user: [...(comment.custom_tags_by_user || []), newTag],
            },
          },
        };
      }

      return state;
    }
    return state;
  },

  [POST_CUSTOM_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCustomTagSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.commentId];

    if (comment) {
      const newTagIndex = comment.custom_tags_by_user?.findIndex(
        tag => tag.label === action.payload.tag.label,
      );

      if (newTagIndex !== undefined && newTagIndex > -1) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.commentId]: {
              ...comment,
              custom_tags_by_user: Object.assign([], {
                ...(comment.custom_tags_by_user || []),
                [newTagIndex]: {
                  ...action.payload.tag,
                  newlyAdded: true,
                },
              }),
            },
          },
        };
      }

      return state;
    } else if (action.payload.parentCommentId !== undefined) {
      // If it's a private conversation, the comment id is the parent id
      const parentComment = state.cache[action.payload.parentCommentId];

      if (parentComment) {
        let message_tags = parentComment.message_tags;

        if (parentComment.post_placement && isPrivateConversation(parentComment.post_placement)) {
          message_tags = {
            ...parentComment.message_tags,
            custom_tags: addToTagsListing(
              parentComment.message_tags?.custom_tags || [],
              action.payload.tag,
            ),
            count: (parentComment.message_tags?.count || 0) + 1,
          } as IMessageTags;
        }

        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.parentCommentId]: {
              ...parentComment,
              message_tags: Object.assign({}, message_tags),
            },
          },
        };
      }

      return state;
    }

    return state;
  },

  [POST_CUSTOM_TAG_FAILURE]: (
    state: ICommentsState,
    action: ActionType<typeof postCustomTagFailureAction>,
  ) => {
    if (action.payload) {
      const comment = state.cache[action.payload];

      if (comment) {
        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload]: {
              ...comment,
              custom_tags_by_user: Object.assign([], {
                ...(comment.custom_tags_by_user || []).filter(tag => tag.id !== action.payload),
              }),
            },
          },
        };
      }

      return state;
    }

    return state;
  },

  [DELETE_CUSTOM_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteCustomTagSuccessAction>,
  ) => {
    const nextCache = { ...state.cache };
    Object.keys(nextCache).forEach(commentId => {
      nextCache[commentId].custom_tags_by_user = nextCache[commentId].custom_tags_by_user?.filter(
        tag => tag.id !== action.payload,
      );
      nextCache[commentId].custom_tags_by_automation = nextCache[
        commentId
      ].custom_tags_by_automation?.filter(tag => tag.id !== action.payload);
    });
    return {
      ...state,
      cache: nextCache,
    };
  },

  [DELETE_CUSTOM_TAG_FROM_COMMENT_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteCustomTagFromCommentSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.comment_id];
    const removedTagId = action.payload.id;

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.comment_id]: {
            ...comment,
            custom_tags_by_user: Object.assign([], {
              ...(comment.custom_tags_by_user || []).filter(tag => tag.id !== removedTagId),
            }),
            custom_tags_by_automation: Object.assign([], {
              ...(comment.custom_tags_by_automation || []).filter(tag => tag.id !== removedTagId),
            }),
          },
        },
      };
    } else if (action.payload.parent_comment_id !== undefined) {
      // If it's a private conversation, the comment id is the parent id
      const parentComment = state.cache[action.payload.parent_comment_id];

      if (parentComment) {
        let message_tags = parentComment.message_tags;

        if (parentComment.post_placement && isPrivateConversation(parentComment.post_placement)) {
          message_tags = {
            ...parentComment.message_tags,
            custom_tags: removeFromTagsListing(
              parentComment.message_tags?.custom_tags || [],
              action.payload.id,
            ),
            count: (parentComment.message_tags?.count || 0) - 1,
          } as IMessageTags;
        }

        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.parent_comment_id]: {
              ...parentComment,
              message_tags: Object.assign({}, message_tags),
            },
          },
        };
      }

      return state;
    }

    return state;
  },

  [DELETE_BRAND_REPLY_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteBrandReplySuccessAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.id]: {
            ...comment,
            reply: {
              is_ignored: false,
              is_starred: false,
              is_sent: false,
              sent_at: "",
              sent_by: "",
              sent_id: "",
              message: "",
            },
          },
        },
      };
    }

    return state;
  },

  [POST_COMMENT_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentTagSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.commentId];

    if (comment) {
      const tags = comment.tags;
      const tagExists = tags?.some(tag => tag.id === action.payload.tagId);

      if (action.payload.tag && !tagExists) tags?.push(action.payload.tag);

      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentId]: {
            ...comment,
            tags: Object.assign([], {
              ...tags,
            }),
          },
        },
      };
    } else if (action.payload.parentCommentId !== undefined) {
      // If it's a private conversation, the comment id is the parent id
      const parentComment = state.cache[action.payload.parentCommentId];

      if (parentComment) {
        let message_tags = parentComment.message_tags;

        if (parentComment.post_placement && isPrivateConversation(parentComment.post_placement)) {
          message_tags = {
            ...parentComment.message_tags,
            managed_tags: addToTagsListing(
              parentComment.message_tags?.managed_tags || [],
              action.payload.tag,
            ),
            count: (parentComment.message_tags?.count || 0) + 1,
          } as IMessageTags;
        }

        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.parentCommentId]: {
              ...parentComment,
              message_tags: Object.assign({}, message_tags),
            },
          },
        };
      }

      return state;
    }

    return state;
  },

  [DELETE_COMMENT_TAG_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof deleteCommentTagSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.commentId];

    if (comment) {
      const tags = comment.tags?.filter(tag => tag.id !== action.payload.tagId);

      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.commentId]: {
            ...comment,
            tags: Object.assign([], {
              ...tags,
            }),
          },
        },
      };
    } else if (action.payload.parentCommentId !== undefined) {
      // If it's a private conversation, the comment id is the parent id
      const parentComment = state.cache[action.payload.parentCommentId];

      if (parentComment) {
        let message_tags = parentComment.message_tags;

        if (parentComment.post_placement && isPrivateConversation(parentComment.post_placement)) {
          message_tags = {
            ...parentComment.message_tags,
            managed_tags: removeFromTagsListing(
              parentComment.message_tags?.managed_tags || [],
              action.payload.tagId,
            ),
            count: (parentComment.message_tags?.count || 0) - 1,
          } as IMessageTags;
        }

        return {
          ...state,
          cache: {
            ...state.cache,
            [action.payload.parentCommentId]: {
              ...parentComment,
              message_tags: Object.assign({}, message_tags),
            },
          },
        };
      }

      return state;
    }

    return state;
  },

  // COMMENTS CSV
  [GET_COMMENTS_CSV]: (state: ICommentsState) => ({
    ...state,
    fetchingCommentsCSV: true,
    fetchedCommentsCSV: false,
    fetchedCommentsCSVFail: false,
  }),

  [GET_COMMENTS_CSV_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof getCommentsCSVSuccessAction>,
  ) => ({
    ...state,
    commentsCSV: action.payload,
    fetchingCommentsCSV: false,
    fetchedCommentsCSV: true,
    fetchedCommentsCSVFail: false,
  }),

  [RESET_COMMENTS_CSV_STATE]: (state: ICommentsState) => ({
    ...state,
    commentsCSV: undefined,
    fetchingCommentsCSV: false,
    fetchedCommentsCSV: false,
    fetchedCommentsCSVFail: false,
  }),

  [ASSIGN_COMMENT_USER]: (
    state: ICommentsState,
    action: ActionType<typeof assignCommentUserAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      const { assigned_users: prevUsers } = comment;
      const { assigned_users: nextUsers = [] } = action.payload.data;

      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.id]: {
            ...comment,
            assigned_users: nextUsers,
          },
        },
        previouslyAssignedUsers: prevUsers,
      };
    }

    return state;
  },

  [UPDATE_COMMENT_SENTIMENT]: (
    state: ICommentsState,
    action: ActionType<typeof updateCommentSentimentSuccessAction>,
  ) => {
    const comment = state.cache[action.payload.id];

    if (comment) {
      return {
        ...state,
        cache: {
          ...state.cache,
          [action.payload.id]: {
            ...comment,
            ...action.payload.data,
          },
        },
      };
    }

    return state;
  },

  [PERFORM_BULK_COMMENT_ACTION]: (
    state: ICommentsState,
    action: ActionType<typeof performBulkCommentAction>,
  ) => {
    if (action.payload.comment_ids?.length) {
      const updatedComments = Object.values(state.cache).filter(comment =>
        action.payload?.comment_ids.includes(comment.id),
      );

      if (!!updatedComments?.length) {
        return {
          ...state,
          performingBatchActions: true,
          performedBatchActions: false,
          performedBatchActionsFailed: false,
        };
      }

      return state;
    }

    return state;
  },

  [PERFORM_BULK_COMMENT_ACTION_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof performBulkCommentActionSuccessAction>,
  ) => {
    const comments = Object.values(state.cache).filter(comment =>
      action.payload.commentAction.comment_ids.includes(comment.id),
    );

    if (!!comments?.length) {
      if (action.payload.commentAction.actions?.includes("hide")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,

          cache: {
            ...state.cache,
            ...comments.reduce(
              (acc, comment) => {
                acc[comment.id] = {
                  ...comment,
                  actions: {
                    ...comment.actions,
                    can_reply: canReplyToComment(comment, true),
                  },
                  is_hidden: !!action.payload.commentAction.actions?.includes("hide"),
                };
                return acc;
              },
              {} as Record<string, IComment>,
            ),
          },
        };
      }

      if (action.payload.commentAction.actions?.includes("review")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,

          cache: {
            ...state.cache,
            ...comments.reduce(
              (acc, comment) => {
                acc[comment.id] = {
                  ...comment,
                  moderation: {
                    ...comment.moderation,
                    [ActionToReplyProp["review"]]: true,
                  },
                };
                return acc;
              },
              {} as Record<string, IComment>,
            ),
          },
        };
      }

      // Flag/ignore actions are handled in `reply` prop of comment
      if (action.payload.commentAction.actions?.includes("ignore")) {
        return {
          ...state,
          performingBatchActions: false,
          performedBatchActions: true,
          cache: {
            ...state.cache,
            ...comments.reduce(
              (acc, comment) => {
                acc[comment.id] = {
                  ...comment,
                  reply: {
                    ...comment.reply,
                    is_ignored: true,
                    is_starred: false,
                  },
                };
                return acc;
              },
              {} as Record<string, IComment>,
            ),
          },
        };
      }

      return {
        ...state,
        performingBatchActions: false,
        performedBatchActions: true,
        cache: {
          ...state.cache,
          ...comments.reduce(
            (acc, comment) => {
              acc[comment.id] = {
                ...comment,
                reply: {
                  ...comment.reply,
                  is_ignored: true,
                  is_starred: false,
                },
              };
              return acc;
            },
            {} as Record<string, IComment>,
          ),
        },
      };
    }

    return state;
  },

  [PERFORM_BULK_COMMENT_ACTION_FAILURE]: (state: ICommentsState) => {
    return {
      ...state,
      performingBatchActions: false,
      performedBatchActions: false,
      performedBatchActionsFailed: true,
    };
  },

  [ADD_LINKEDIN_REACTION]: produce((draft, action) => {
    const comment = draft.cache[action.payload.commentId];

    if (comment) {
      draft.previousReactions[action.payload.commentId] = comment.reaction;
      comment.reaction = {
        type: action.payload.reaction,
        is_sent: true,
      };
    }
  }),

  [ADD_LINKEDIN_REACTION_SUCCESS]: produce((draft, action) => {
    const comment = draft.cache[action.payload.commentId];

    if (comment) {
      comment.reaction = {
        type: action.payload.reaction,
        is_sent: true,
      };
    }
  }),

  [DELETE_LINKEDIN_REACTION]: produce((draft, action) => {
    const comment = draft.cache[action.payload];

    if (comment) {
      draft.previousReactions[action.payload] = comment.reaction;
      comment.reaction = undefined;
    }
  }),

  [DELETE_LINKEDIN_REACTION_SUCCESS]: produce((draft, action) => {
    draft.previousReactions[action.payload] = undefined;
  }),

  [LINKEDIN_REACTION_FAILURE]: produce((draft, action) => {
    const comment = draft.cache[action.payload];
    const prevReaction = draft.previousReactions[action.payload];

    if (comment) {
      comment.reaction = prevReaction;
    }
    draft.previousReactions[action.payload] = undefined;
  }),

  [TRIGGER_NEXT_BATCH]: produce((draft, action) => {
    draft.triggerNextBatch = action.payload;
  }),

  // POST COMMENTS SEARCH
  [POST_COMMENTS_SEARCH]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsSearchAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: true,
          fetchedComments: false,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [POST_COMMENTS_SEARCH_SUCCESS]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsSearchSuccessAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      cache: {
        ...state.cache,
        ...action.payload.comments.reduce(
          (acc, comment) => ({
            ...acc,
            [comment.id]: comment,
          }),
          {},
        ),
      },
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          info: action.payload.info,
          navigation_id: action.payload.navigation_id,
          cursor: action.payload.cursor,
          comments: Object.values(action.payload.comments).map(({ id }) => id),
          fetchingComments: false,
          fetchedComments: true,
          fetchedCommentsFail: false,
        },
      },
    };
  },

  [POST_COMMENTS_SEARCH_FAILURE]: (
    state: ICommentsState,
    action: ActionType<typeof postCommentsSearchFailureAction>,
  ) => {
    if (action.payload.isReplyModal) return state;

    const { conversation_list_id = DEFAULT_COMMENT_LIST_KEY } = action.payload;

    return {
      ...state,
      lists: {
        [conversation_list_id]: {
          ...state.lists[conversation_list_id],
          fetchingComments: false,
          fetchedComments: false,
          fetchedCommentsFail: true,
        },
      },
    };
  },
});
