import { RootState } from "../";

export const fromCommentTags = (state: RootState) => state.commentTags;

export const commentTagsSelector = (state: RootState) => fromCommentTags(state).commentTags;

export const getCommentTagsSuccessfulSelector = (state: RootState) =>
  fromCommentTags(state).fetchedCommentTags;
export const getCommentTagsProcessingSelector = (state: RootState) =>
  fromCommentTags(state).fetchingCommentTags;
export const getCommentTagsFailedSelector = (state: RootState) =>
  fromCommentTags(state).fetchedCommentTagsFail;

export const postingCommentTagSelector = (state: RootState) =>
  fromCommentTags(state).postingCommentTag;
export const postedCommentTagSelector = (state: RootState) =>
  fromCommentTags(state).postedCommentTag;
export const postingCommentTagFailedSelector = (state: RootState) =>
  fromCommentTags(state).postingCommentTagFailed;

export const deletingCommentTagSelector = (state: RootState) =>
  fromCommentTags(state).deletingCommentTag;
export const deletingCommentTagFailedSelector = (state: RootState) =>
  fromCommentTags(state).deletingCommentTagFailed;
