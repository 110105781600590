import { RootState } from "..";

export const fromAutocompleteOptions = (state: RootState) => state.autocompleteOptions;

export const autocompleteOptionsSelector = (state: RootState) =>
  fromAutocompleteOptions(state).options;
export const autocompleteOptionsFetchingSelector = (state: RootState) =>
  fromAutocompleteOptions(state).fetchingAutocompleteOptions;
export const getAutocompleteOptionsSuccessfulSelector = (state: RootState) =>
  fromAutocompleteOptions(state).fetchedAutocompleteOptions;
export const getAutocompleteOptionsFailureSelector = (state: RootState) =>
  fromAutocompleteOptions(state).fetchedAutocompleteOptionsFail;
