import React, { FC } from "react";

import s from "./ReplierName.module.less";

import { IObjectOption, ChevronDownIcon, ChevronUpIcon } from "@bbdevcrew/bb_ui_kit_fe";

interface IReplierNameTriggerProps {
  isOpen: boolean;
  selectedAsset?: IObjectOption;
}

const ReplierNameTrigger: FC<IReplierNameTriggerProps> = ({ selectedAsset, isOpen }) => {
  return (
    <div className={s.bbReplierNameTrigger}>
      <span>{selectedAsset?.label}</span>
      {isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
    </div>
  );
};

export default ReplierNameTrigger;
