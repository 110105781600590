import { RootState } from "../types";

export const fromCheckout = (state: RootState) => state.checkout;

export const getCheckoutUrlSelector = (state: RootState) => fromCheckout(state).checkout_url;

export const getCheckoutUrlSelectorPending = (state: RootState) =>
  fromCheckout(state).fetchingCheckoutUrl;
export const getCheckoutUrlSelectorSuccessful = (state: RootState) =>
  fromCheckout(state).fetchedCheckoutUrl;
export const getCheckoutUrlSelectorFailure = (state: RootState) =>
  fromCheckout(state).fetchedCheckoutUrlFail;

export const getBillingUrlSelector = (state: RootState) => fromCheckout(state).billing_url;

export const getBillingUrlSelectorPending = (state: RootState) =>
  fromCheckout(state).fetchingBillingUrl;
export const getBillingUrlSelectorSuccessful = (state: RootState) =>
  fromCheckout(state).fetchedBillingUrl;
export const getBillingUrlSelectorFailure = (state: RootState) =>
  fromCheckout(state).fetchedBillingUrlFail;
