import React, { FC, useState } from "react";

import ActionHide from "./predefinedActions/ActionHide";
import ActionStar from "./predefinedActions/ActionStar";
import ActionFlag from "./predefinedActions/ActionFlag";
import ActionLike from "./predefinedActions/ActionLike";
import ActionReply from "./predefinedActions/ActionReply";
import ActionReview from "./predefinedActions/ActionReview";
import ActionDelete from "./predefinedActions/ActionDelete";
import ActionCancel from "./predefinedActions/ActionCancel";
import ActionArchive from "./predefinedActions/ActionArchive";
import ActionDetails from "./predefinedActions/ActionDetails";
import ActionsDropdown from "../../ActionsDropdown/ActionsDropdown";
import ActionLinkedInReaction from "./predefinedActions/ActionLinkedInReaction";
import CreateZendeskTicketModal from "@components/_common/MessageCard/createZendeskTicketModal/CreateZendeskTicketModal"; // eslint-disable-line max-len

import { useCommentExternalActionDropdownItems } from "../../MessageCard/dropdowns/useCommentExternalActionDropdownItems"; // eslint-disable-line max-len

import s from "./CommentActions.module.less";

import { ICommentActions } from "./CommentActions.type";

const CommentActions: FC<ICommentActions> = ({
  comment,
  showCancelAction,
  showHideAction,
  showDeleteAction,
  showCommentLink,
  showReplyAction,
  showDetailsAction,
  showStarAction,
  showFlagAction,
  showReviewAction,
  showArchiveAction,
  showLikeAction,
  showLinkedInReactionAction,
  filterAuthorsId,
  onReplyClick,
  onOpenPostClick,
  onOpenAdsClick,
  onLikeClick,
  onLinkedInReactionClick,
  onHideClick,
  onDeleteClick,
  onCancelClick,
  onFlagClick,
  onReviewClick,
  onParentClick,
  onStarClick,
  onArchiveClick,
  onDismissErrorClick,
  updateCommentSentiment,
}) => {
  const [showCreateZendeskTicketModal, setShowCreateZendeskTicketModal] = useState(false);

  const onClickReply = () => onReplyClick && onReplyClick(comment.replying_comment_id);

  const hasExternalActions = () => {
    return (
      (showCommentLink && comment.permalink) ||
      !!comment.post_id ||
      !!comment.parent ||
      comment.has_ads
    );
  };

  const externalActionFunctions = {
    onReplyClick,
    onParentClick,
    onOpenAdsClick,
    onOpenPostClick,
    filterAuthorsId,
    onDismissErrorClick,
    updateCommentSentiment,
    onCreateZendeskTicketClick: () => setShowCreateZendeskTicketModal(true),
  };

  const commentExternalActions = useCommentExternalActionDropdownItems({
    comment,
    actionFunctions: externalActionFunctions,
    showCommentLink,
    showCreateZendeskTicketOption: !!comment?.actions.can_submit_ticket,
  });

  return (
    <>
      <span className={s.bbCommentActionsContainer}>
        {showReviewAction && <ActionReview comment={comment} onReviewClick={onReviewClick} />}
        {showReplyAction && <ActionReply comment={comment} onClickReply={onClickReply} />}
        {showDetailsAction && <ActionDetails comment={comment} onClickViewDetails={onClickReply} />}
        {showHideAction && <ActionHide comment={comment} onHideClick={onHideClick} />}
        {showDeleteAction && <ActionDelete comment={comment} onDeleteClick={onDeleteClick} />}
        {showLikeAction && <ActionLike comment={comment} onLikeClick={onLikeClick} />}
        {showLinkedInReactionAction && (
          <ActionLinkedInReaction comment={comment} onLikeClick={onLinkedInReactionClick} />
        )}
      </span>
      {showStarAction && <ActionStar comment={comment} onStarClick={onStarClick} />}
      {showCancelAction && <ActionCancel comment={comment} onCancelClick={onCancelClick} />}
      {showFlagAction && <ActionFlag comment={comment} onFlagClick={onFlagClick} />}
      {showArchiveAction && <ActionArchive comment={comment} onArchiveClick={onArchiveClick} />}
      {hasExternalActions() && !!commentExternalActions.length && (
        <ActionsDropdown items={commentExternalActions} triggerClass={s.bbExternalActionsTrigger} />
      )}

      <CreateZendeskTicketModal
        isOpen={showCreateZendeskTicketModal}
        comment={comment}
        onClose={() => setShowCreateZendeskTicketModal(false)}
      />
    </>
  );
};

export default CommentActions;
