import { RootState } from "../";

export const fromCompareProjects = (state: RootState) => state.compareProjects;

export const compareProjects = (state: RootState) => fromCompareProjects(state).projects;
export const getCompareProjectsSuccessful = (state: RootState) =>
  fromCompareProjects(state).fetchedCompareProjects;
export const getCompareProjectsFailure = (state: RootState) =>
  fromCompareProjects(state).fetchedCompareProjectsFail;

export const postingCompareProject = (state: RootState) =>
  fromCompareProjects(state).postingCompareProject;
export const postedCompareProject = (state: RootState) =>
  fromCompareProjects(state).postedCompareProject;
export const postedCompareProjectFail = (state: RootState) =>
  fromCompareProjects(state).postedCompareProjectFail;

export const editingCompareProject = (state: RootState) =>
  fromCompareProjects(state).editingCompareProject;
export const editedCompareProject = (state: RootState) =>
  fromCompareProjects(state).editedCompareProject;
export const editedCompareProjectFail = (state: RootState) =>
  fromCompareProjects(state).editedCompareProjectFail;
export const getEditedCompareProjectId = (state: RootState) =>
  fromCompareProjects(state).editedCompareProjectId;

export const deletedCompareProject = (state: RootState) =>
  fromCompareProjects(state).deletedCompareProject;
export const deletedCompareProjectFail = (state: RootState) =>
  fromCompareProjects(state).deletedCompareProjectFail;

export const compareProjectsAggregations = (state: RootState) =>
  fromCompareProjects(state).compareProjectsAggregations;
export const getCompareProjectsAggregationsSuccessful = (state: RootState) =>
  fromCompareProjects(state).fetchedCompareProjectsAggregations;
export const getCompareProjectsAggregationsFailure = (state: RootState) =>
  fromCompareProjects(state).fetchedCompareProjectsAggregationsFail;
