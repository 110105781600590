import classNames from "classnames";
import React from "react";

import Body from "../cardBody/CommentCardBody";
import { Card } from "@bbdevcrew/bb_ui_kit_fe";
import Attachments from "../cardBody/attachment/Attachment";
import CommentCardHeader from "../cardHeader/CommentCardHeader";
import CommentTagsList from "../commentTags/CommentTagsList";
import AiHighlightTags from "../aiHighlightTags/AiHighlightTags";

import { ICommentCardProps } from "../CommentCard.type";
import { isStoryAttachment } from "@utils/comments";

import s from "../CommentCard.module.less";

export default function BatchModeCommentCard({
  comment,
  isParent,
  maxLengthComment,
  onReplyClick,
  onTranslateClick,
}: ICommentCardProps) {
  const isMessageStoryAttachment =
    !!comment.attachments.length && isStoryAttachment(comment.attachments[0].description);

  return (
    <div
      data-cy="batch-mode-comment-card"
      className={classNames(s.bbCommentCard, s[`bbCommentCardSentiment-${comment.sentiment}`], {
        [s.bbCommentCardInfo]: !!comment.sentiment,
      })}
    >
      <Card>
        <CommentCardHeader comment={comment} onReplyClick={onReplyClick} />
        {isMessageStoryAttachment ? (
          <Attachments
            className={s.bbAttachments}
            isStoryAttachment
            attachments={comment.attachments || []}
            createdTime={comment.created_time}
          />
        ) : (
          <Body
            comment={comment}
            isParent={isParent}
            onTranslateClick={onTranslateClick}
            maxLengthComment={maxLengthComment || 900}
          />
        )}

        <div className={s.bbCommentCardTags}>
          <CommentTagsList
            tags={comment.tags || []}
            premiumTag={comment.tooltips?.premium}
            className={s.bbCommentCardTagsList}
          />

          <AiHighlightTags tags={comment.ai_tags} />
        </div>
      </Card>
    </div>
  );
}
