/* eslint-disable @typescript-eslint/no-explicit-any */
import dayjs from "@utils/dayjs-config";

export const mockMoment = (date: string | Date | number): any => {
  const dayjsDate = dayjs(date);

  return {
    ...dayjsDate,
    toDate: () => dayjsDate.toDate(),
    format: (formatString: string) => dayjsDate.format(formatString),
    isValid: () => dayjsDate.isValid(),
    valueOf: () => dayjsDate.valueOf(),
    year: () => dayjsDate.year(),
    month: () => dayjsDate.month(),
    date: () => dayjsDate.date(),
    hour: () => dayjsDate.hour(),
    minute: () => dayjsDate.minute(),
    second: () => dayjsDate.second(),
    millisecond: () => dayjsDate.millisecond(),
    utcOffset: () => dayjsDate.utcOffset(),
    local: () => dayjsDate.local(),
    isAfter: (comparisonDate: any) => dayjsDate.isAfter(dayjs(comparisonDate)),
    isBefore: (comparisonDate: any) => dayjsDate.isBefore(dayjs(comparisonDate)),
    clone: () => dayjsDate.clone(),
    weekday: () => dayjsDate.weekday(),
    localeData: () => ({
      firstDayOfWeek: () => dayjsDate.localeData().firstDayOfWeek(),
    }),
  };
};
