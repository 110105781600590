import { createAction } from "typesafe-actions";
import {
  GET_MODERATION_STREAMS,
  GET_MODERATION_STREAMS_SUCCESS,
  GET_MODERATION_STREAMS_FAILURE,
  GET_MODERATION_STREAMS_COMMENTS,
  GET_MODERATION_STREAMS_COMMENTS_SUCCESS,
  GET_MODERATION_STREAMS_COMMENTS_FAILURE,
  GET_CARE_CPS_STREAMS,
  GET_CARE_CPS_STREAMS_SUCCESS,
  GET_CARE_CPS_STREAMS_FAILURE,
  RESET_CARE_CPS_STREAMS,
  SET_CARE_CPS_STREAM,
  GET_CARE_CPS_STREAMS_COUNTS,
  GET_CARE_CPS_STREAMS_COUNTS_SUCCESS,
  GET_CARE_CPS_STREAMS_COUNTS_FAILURE,
} from "./actionTypes";
import { IModerationStream, IStreamCommentPayload, ICareCPSStream, IStreamCounts } from "./types";
import { IComment } from "../comments/types";

export const getModerationStreamsAction = createAction(GET_MODERATION_STREAMS)();
export const getModerationStreamsActionSuccess = createAction(GET_MODERATION_STREAMS_SUCCESS)<
  IModerationStream[]
>();
export const getModerationStreamsActionFailure = createAction(GET_MODERATION_STREAMS_FAILURE)();

export const getModerationStreamsCommentsAction = createAction(
  GET_MODERATION_STREAMS_COMMENTS,
)<IStreamCommentPayload>();
export const getModerationStreamsCommentsActionSuccess = createAction(
  GET_MODERATION_STREAMS_COMMENTS_SUCCESS,
)<IComment[]>();
export const getModerationStreamsCommentsActionFailure = createAction(
  GET_MODERATION_STREAMS_COMMENTS_FAILURE,
)();

export const getCareCpsStreamsAction = createAction(GET_CARE_CPS_STREAMS)();
export const getCareCpsStreamsActionSuccess = createAction(GET_CARE_CPS_STREAMS_SUCCESS)<
  ICareCPSStream[]
>();
export const getCareCpsStreamsActionFailure = createAction(GET_CARE_CPS_STREAMS_FAILURE)();
export const resetCareCpsStreamsAction = createAction(RESET_CARE_CPS_STREAMS)();
export const setCareCpsStreamAction = createAction(SET_CARE_CPS_STREAM)<
  ICareCPSStream | undefined
>();

export const getCareCpsStreamsCountsAction = createAction(GET_CARE_CPS_STREAMS_COUNTS)<string[]>();
export const getCareCpsStreamsCountsActionSuccess = createAction(
  GET_CARE_CPS_STREAMS_COUNTS_SUCCESS,
)<IStreamCounts[]>();
export const getCareCpsStreamsCountsActionFailure = createAction(
  GET_CARE_CPS_STREAMS_COUNTS_FAILURE,
)();
