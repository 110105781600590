import { action } from "typesafe-actions";

import { GET_INBOX_STATS, GET_INBOX_STATS_SUCCESS, GET_INBOX_STATS_FAILURE } from "./actionTypes";
import { IRepliesStatsResponse } from "./types";
import { IFilters } from "../filters/types";

export const getInboxStatsAction = (filters: IFilters) => action(GET_INBOX_STATS, filters);
export const getInboxStatsSuccess = (payload: IRepliesStatsResponse) =>
  action(GET_INBOX_STATS_SUCCESS, payload);
export const getInboxStatsFailure = () => action(GET_INBOX_STATS_FAILURE);
