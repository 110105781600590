import React from "react";

import { ClientPlanType } from "@store/me/types";

import {
  CalendarIcon,
  ChartIcon,
  CommentIcon,
  HeadphoneIcon,
  DiamondSimpleIcon,
  AgencyIcon,
  PrimeIcon,
  GrowthIcon,
} from "@assets/index";

export const AppHeaderIcon = (item: string) => {
  const icons = {
    insights: <ChartIcon />,
    care: <CommentIcon />,
    publish: <CalendarIcon />,
    listen: <HeadphoneIcon />,
    managed: <DiamondSimpleIcon />,
  };

  return icons[item as keyof typeof icons];
};

export const getPlanIcon = (type: ClientPlanType = "ESSENTIAL") => {
  const icons: Record<ClientPlanType, JSX.Element> = {
    ESSENTIAL: <AgencyIcon />,
    GROWTH: <GrowthIcon />,
    GROWTH_SEMI: <GrowthIcon />,
    PRIME: <PrimeIcon />,
    AGENCY: <AgencyIcon />,
  };
  return icons[type];
};
