import React from "react";

import ImageAttachment from "./ImageAttachment";
import StoryNotAvailable from "./StoryNotAvailable";

import s from "../Attachment.module.less";

import { IDMImageAttachmentProps } from "./Variants.types";
import { hoursFromNow } from "@utils/dates";

const DMImageAttachment: React.FC<IDMImageAttachmentProps> = ({
  isOwned,
  attachment,
  createdTime,
  onAttachmentClick,
  isPreviewMessage = false,
  ...restProps
}) => {
  const storyNoLongerAvailable = createdTime && hoursFromNow(createdTime) > 24;

  if (storyNoLongerAvailable) {
    return <StoryNotAvailable isPreviewMessage={isPreviewMessage} isOwned={isOwned} />;
  }

  return (
    <ImageAttachment
      attachment={attachment}
      onAttachmentClick={onAttachmentClick}
      alt="Story preview"
      className={s.bbDMImageAttachment}
      {...restProps}
    />
  );
};

export default DMImageAttachment;
