import React, { ChangeEvent, useRef } from "react";
import classNames from "classnames";

import { Button } from "antd";
import { Tooltip } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./UploadImageButton.module.less";

import { IUploadImageButtonProps } from "./UploadImageButton.type";

import { PictureIcon } from "@assets/index";
import { FILE_SIZE_LIMIT } from "./helpers";

const UploadImageButton = ({
  canSendDMMedia,
  canSendDMMediaTooltip,
  fileSizeLimit = FILE_SIZE_LIMIT,
  onSelectFile,
  onFileSizeLimitError,
}: IUploadImageButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => inputRef.current?.click();
  const onChangeFile = (event: ChangeEvent<HTMLInputElement>) => {
    const image = event.target.files?.[0] as File;

    if (image.size > fileSizeLimit) return onFileSizeLimitError();

    onSelectFile(image);

    // clear input value to allow uploading the same image and trigger onChangeFile again
    event.target.value = "";
  };

  return (
    <div className={s.bbUploadImageWrapper}>
      {canSendDMMedia ? (
        <Button id="upload-image-btn" className={s.bbUploadImageButton} onClick={onClick}>
          <PictureIcon />
        </Button>
      ) : (
        <Tooltip
          title={canSendDMMediaTooltip}
          className={classNames(s.bbUploadImageButton, s.bbUploadImageButtonDisabled)}
        >
          <PictureIcon />
        </Tooltip>
      )}

      <input
        hidden
        ref={inputRef}
        type="file"
        accept="image/png, image/jpeg"
        onChange={onChangeFile}
      />
    </div>
  );
};

export default UploadImageButton;
