import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal, TabPane, TabsScrollable } from "@bbdevcrew/bb_ui_kit_fe";
import CreateNewZendeskUserForm from "../createNewZendeskUserForm/CreateNewZendeskUserForm";
import ExistingZendeskUsersList from "../existingZendeskUsersList/ExistingZendeskUsersList";

import { LinkUserModalProps, LinkZendeskUserTabs } from "./LinkUserModal.type";

const LinkUserModal = ({ isOpen, ticket, onClose }: LinkUserModalProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(LinkZendeskUserTabs.ExistingZendeskUsers);

  return (
    <Modal
      centered
      responsive
      hideFooter
      onCancel={onClose}
      open={isOpen}
      width={600}
      title={t("components:comments:zendeskTicket:linkUserModal:title")}
    >
      <TabsScrollable
        activeKey={selectedTab}
        onChange={(key: string) => setSelectedTab(key as LinkZendeskUserTabs)}
        defaultActiveKey={LinkZendeskUserTabs.ExistingZendeskUsers}
      >
        <TabPane
          tab={t("components:comments:zendeskTicket:linkUserModal:tab1:title")}
          tabKey={LinkZendeskUserTabs.ExistingZendeskUsers}
        >
          <ExistingZendeskUsersList ticket={ticket} closeModal={onClose} />
        </TabPane>

        <TabPane
          tab={t("components:comments:zendeskTicket:linkUserModal:tab2:title")}
          tabKey={LinkZendeskUserTabs.CreateNewZendeskUser}
        >
          <CreateNewZendeskUserForm onCancel={onClose} />
        </TabPane>
      </TabsScrollable>
    </Modal>
  );
};

export default LinkUserModal;
