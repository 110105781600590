import { ActionType } from "typesafe-actions";

import * as actions from "./actions";

export type IntegrationsActions = ActionType<typeof actions>;

export interface Integration {
  id: string;
  subdomain: string;
  email: string;
  token: string;
}

export interface IIntegrationsListResponse {
  items: Integration[];
}

export interface IIntegrationsState {
  // Integrations
  integrations: Integration[];

  fetchingIntegrationsList: boolean;
  fetchedIntegrationsList: boolean;
  fetchedIntegrationsListFail: boolean;

  creatingIntegration: boolean;
  createdIntegration: boolean;
  creatingIntegrationFailed: boolean;
  creatingIntegrationFailedMessage: string;

  updatingIntegration: boolean;
  updatedIntegration: boolean;
  updatingIntegrationFailed: boolean;
  updatingIntegrationFailedMessage: string;

  deletingIntegration: boolean;
  deletedIntegration: boolean;
  deletingIntegrationFailed: boolean;
  deletingIntegrationFailedMessage: string;

  // Users
  integrationUsers: IIntegrationUser[];

  fetchingIntegrationUsersList: boolean;
  fetchedIntegrationUsersList: boolean;
  fetchingIntegrationUsersListFailed: boolean;
  fetchingIntegrationUsersListFailedMessage: string;

  creatingOrUpdatingIntegrationUser: boolean;
  createdOrUpdatedIntegrationUser: boolean;
  creatingOrUpdatingIntegrationUserFailed: boolean;
  creatingOrUpdatingIntegrationUserFailedMessage: string;

  // Tickets
  creatingTicket: boolean;
  createdTicket: boolean;
  creatingTicketFailed: boolean;
  creatingTicketFailedMessage: string;

  updatingTicket: boolean;
  updatedTicket: boolean;
  updatingTicketFailed: boolean;
  updatingTicketFailedMessage: string;

  sendingNewInternalComment: boolean;
  sentNewInternalComment: boolean;
  sendingNewInternalCommentFailed: boolean;
  sendingNewInternalCommentFailedMessage: string;

  linkingIntegrationUserToTicket: boolean;
  linkedIntegrationUserToTicket: boolean;
  linkingIntegrationUserToTicketFailed: boolean;
  linkingIntegrationUserToTicketFailedMessage: string;

  unlinkingIntegrationUserFromTicket: boolean;
  unlinkedIntegrationUserFromTicket: boolean;
  unlinkingIntegrationUserFromTicketFailed: boolean;
  unlinkingIntegrationUserFromTicketFailedMessage: string;
}

export interface ICreateIntegrationPayload {
  subdomain: string;
  email: string;
  token: string;
}

export type IDeleteIntegrationPayload = string;

export interface IZendeskTicketInternalReply {
  message: string;
  author_name?: string;
}

export enum ZendeskTicketStatus {
  New = "new",
  Open = "open",
  Pending = "pending",
  Hold = "hold",
  Solved = "solved",
  Closed = "closed",
}

export enum ZendeskTicketPriority {
  Urgent = "urgent",
  High = "high",
  Normal = "normal",
  Low = "low",
}

export interface IZendeskTicket {
  id: string;
  subject: string;
  priority?: ZendeskTicketPriority;
  status?: ZendeskTicketStatus;
  type?: string;
  description: string;
  internal_replies: IZendeskTicketInternalReply[];
  linked_user?: {
    id: string;
    name: string;
  };
}

export interface ICreateTicketPayload extends Omit<IZendeskTicket, "id" | "internal_replies"> {
  type?: string;
  tags: string[];
  requester?: {
    name: string;
    email: string;
  };
  integration_id?: string;
  comment_id?: string;
}

export interface ISendNewInternalCommentPayload {
  ticketId: string;
  message: string;
}

export interface ISendNewInternalCommentResponse {
  id: string;
  message: string;
  created_at: number;
  created_at_pretty: string;
  created_by: string;
  author_name: string;
}

export interface IIntegrationUsersListPayload {
  ticketId: string;
  keyword?: string;
}

export interface IIntegrationUser {
  id: string;
  name: string;
  email: string;
  is_linked: boolean;
}

export interface IIntegrationUsersListResponse {
  items: IIntegrationUser[];
}

export interface ICreateOrUpdateIntegrationUserPayload {
  name: string;
  email: string;
  details?: string;
}

export interface ILinkIntegrationUserToTicketPayload {
  ticketId: string;
  linked_user: {
    id: string;
    name: string;
  };
}

export interface IUnlinkIntegrationUserFromTicketPayload {
  ticketId: string;
}
