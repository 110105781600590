import React from "react";
import { useTranslation } from "react-i18next";

import { Card } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./DeletedView.module.less";

import Logo from "@assets/LogoNoBeta.svg";

export const DeletedView = () => {
  const { t } = useTranslation();

  return (
    <div className={s.bbDeletedView}>
      <a href="/" className={s.bbDeletedViewLogo}>
        <Logo />
      </a>
      <Card className={s.bbDeletedViewCard}>
        <h1 className={s.bbDeletedViewCardTitle}>{t("pages:accountDeleted:title")}</h1>
        <h2 className={s.bbDeletedViewCardSubtitle}>{t("pages:accountDeleted:subtitle")}.</h2>
      </Card>
    </div>
  );
};
