import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { switchMap, map, filter, catchError } from "rxjs/operators";
import { StateObservable } from "redux-observable";

import {
  getTrendingHashtagsFiltersSuccessAction,
  getTrendingHashtagsSuccessAction,
  getTrendingHashtagsFailureAction,
  getTrendingHashtagsFiltersFailureAction,
} from "./actions";
import { TIKTOK_GET_TRENDING_HASHTAGS, TIKTOK_GET_TRENDING_HASHTAGS_FILTERS } from "./actionTypes";
import {
  TiktokHashtagsActionsType,
  ITrendingHashtagsFilters,
  ITrendingHashtagsItem,
} from "./types";
import { RootState } from "..";

import { trendingHashtags, trendingHashtagsFilters } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";
import { generateQueryParams } from "@utils/queryParams";

export const getTrendingHashtags = (
  action$: Observable<TiktokHashtagsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAGS)),
    switchMap(a => {
      return ajax
        .get<ITrendingHashtagsItem[]>(
          `${trendingHashtags}${generateQueryParams(a.payload)}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getTrendingHashtagsSuccessAction(data)),
          catchError(e => handleError(e, getTrendingHashtagsFailureAction)),
        );
    }),
  );
};

export const getTrendingHashtagsFilters = (
  action$: Observable<TiktokHashtagsActionsType>,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isOfType(TIKTOK_GET_TRENDING_HASHTAGS_FILTERS)),
    switchMap(() => {
      return ajax
        .get<ITrendingHashtagsFilters>(
          trendingHashtagsFilters,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getTrendingHashtagsFiltersSuccessAction(data)),
          catchError(e => handleError(e, getTrendingHashtagsFiltersFailureAction)),
        );
    }),
  );
};
