import cn from "classnames/bind";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./TagsDropdown.module.less";

import { getHighlightedText, DeleteIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { ITagsDropdownListingProps, MAX_TAGS_SCROLL_LENGTH } from "./TagsDropdown.type";

const cx = cn.bind(s);

const TagsDropdownListing: FC<ITagsDropdownListingProps> = ({
  searchTerm,
  dropdownTags,
  selectTag,
  removeTag,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={s.bbTagsDropdownListingTitle}>
        {t("components:customTags:chooseFromExisting")}
      </div>

      <div
        className={cx({
          [s.bbTagsOverflow]: dropdownTags?.length > MAX_TAGS_SCROLL_LENGTH,
        })}
      >
        <div className={s.bbTagsDropdownListing}>
          {dropdownTags.map(tag => (
            <div
              key={tag.id}
              onClick={() => selectTag(tag)}
              className={s.bbTagsDropdownListingItem}
            >
              {searchTerm ? getHighlightedText(tag.label, searchTerm) : tag.label}
              {!!removeTag && (
                <Button
                  _size="sm"
                  onClick={e => removeTag(e, tag)}
                  className={s.bbTagsDropdownListingRemoveTagBtn}
                >
                  <DeleteIcon />
                </Button>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TagsDropdownListing;
