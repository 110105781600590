import React from "react";

import s from "./AIInsightsModal.module.less";

import { IAIInsightsModalIconProps } from "./AIInsightsModal.types";

import AstraImg from "@assets/astra.png";

export const AIInsightsModalIcon: React.FC<IAIInsightsModalIconProps> = ({
  onClick,
  className,
}) => {
  return (
    <div className={className} onClick={onClick}>
      <img src={AstraImg} data-cy="ai-insights-modal-icon" />
      <div className={s.bbAIInsightsIconIndicator}>
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};
