import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { ChangeEvent, FC, useState } from "react";

import { Form } from "antd";
import FormHeader from "../formHeader/FormHeader";
import { Button, Input, InputPassword } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./SignIn.module.less";
import globalStyles from "../index.module.less";

import { IFormValues, ISignInProps } from "./SignIn.type";
import { IFormError } from "../confirmSignIn/ConfirmSignIn.type";

const cx = cn.bind(s);

const SignIn: FC<ISignInProps> = ({
  onFinish,
  providers,
  hideSignUp,
  handleSignUp,
  onFinishFailed,
  handleInputChange,
  handleForgotPassword,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<IFormValues>();
  const [loading, setLoading] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => handleInputChange && handleInputChange(e);
  const callBackError = () => setLoading(false);

  const onSubmit = (values: IFormValues) => {
    setLoading(true);

    if (onFinish) {
      onFinish(values, callBackError);
    }
  };

  const onSubmitFailed = (errorInfo: IFormError<IFormValues>) =>
    onFinishFailed ? onFinishFailed(errorInfo) : null;

  return (
    <div className={s.bbSignInContainer}>
      <Form
        form={form}
        name="signIn"
        layout="vertical"
        onFinish={onSubmit}
        requiredMark={false}
        onFinishFailed={onSubmitFailed}
        className={cx(s.bbSignInForm, globalStyles.bbFormXL)}
      >
        <FormHeader
          title={t("components:signIn:title")}
          subtitle={t("components:signIn:subtitle")}
        />
        <Form.Item
          label="Email"
          name="username"
          className={s.bbEmailInput}
          rules={[
            {
              required: true,
              message: t("components:signIn:emailMsg"),
            },
          ]}
        >
          <Input
            autoFocus
            _size="md"
            id="username"
            name="username"
            type="text"
            autoComplete="on"
            onChange={onChange}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label={
            <>
              <span>{t("generic:password")}</span>

              <Button
                _size="sm"
                _type="link"
                type="button"
                id="forgot-psw-btn"
                className={s.bbForgotPasswordLink}
                onClick={e => handleForgotPassword?.(e)}
              >
                {t("components:signIn:forgotPassword")}
              </Button>
            </>
          }
          rules={[
            {
              required: true,
              message: t("components:signIn:passwordMsg"),
            },
          ]}
        >
          <InputPassword
            _size="md"
            id="password"
            name="password"
            onChange={onChange}
            maxLength={100}
            hideMaxLengthChip
          />
        </Form.Item>

        <Form.Item>
          <Button
            _type="primary"
            type="submit"
            loading={loading}
            className={cx(globalStyles.buttonXL, globalStyles.buttonGradient, s.bbSignInBtn)}
          >
            {t("generic:login")}
          </Button>
        </Form.Item>

        {providers ? <Form.Item noStyle>{providers}</Form.Item> : null}

        {!hideSignUp ? (
          <Form.Item className={s.bbSignUpSection}>
            <span className={s.bbSignUpQ}>{t("components:signIn:ctaQ")}</span>
            <Button
              _size="lg"
              _type="custom"
              className={s.bbSignUpBtn}
              onClick={e => handleSignUp?.(e)}
            >
              {t("components:signIn:ctaBtn")}
            </Button>
          </Form.Item>
        ) : null}
      </Form>
    </div>
  );
};

export default SignIn;
