import { useTranslation } from "react-i18next";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumbs from "../../_common/breadcrumbs";
import { ProfilesSection } from "./ProfilesSection";
import { ProfilesTableWrapper } from "./profilesTable/ProfilesTableWrapper";

import {
  getFollowersSelector,
  getFollowersFetchingSelector,
  getFollowersFetchedSelector,
  getImpressionsSelector,
  getImpressionsFetchingSelector,
} from "@store/dashboard/selectors";

import { triggerFilteringWithoutUrlAction } from "@store/filters/actions";
import {
  getDashboardFollowersAction,
  getDashboardImpressionsAction,
} from "@store/dashboard/actions";

import s from "./Profiles.module.less";

import { normalizePlatformData } from "./Profiles.helpers";
import { useCurrentFilters } from "@utils/useCurrentFilters";

export const Profiles: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useCurrentFilters();

  const customFilterData = useMemo(() => {
    return {
      data_range_option: filters?.data_range_option || "last_30_days",
      platform_types: filters?.platform_types?.filter(
        item => item !== "trustpilot" && item !== "google_business",
      ),
      asset_ids: filters?.asset_ids,
      start_time: filters?.start_time,
      end_time: filters?.end_time,
    };
  }, [filters]);

  const followersData = useSelector(getFollowersSelector);

  const impressionsData = useSelector(getImpressionsSelector);
  const followersDataFetching = useSelector(getFollowersFetchingSelector);
  const followersDataFetched = useSelector(getFollowersFetchedSelector);
  const impressionsDataFetching = useSelector(getImpressionsFetchingSelector);

  const getFollowersData = useCallback(
    () => dispatch(getDashboardFollowersAction(customFilterData)),
    [dispatch, customFilterData],
  );

  const getImpressionsData = useCallback(
    () => dispatch(getDashboardImpressionsAction(customFilterData)),
    [dispatch, customFilterData],
  );

  const triggerFilterReset = useCallback(
    filterData => dispatch(triggerFilteringWithoutUrlAction(filterData)),
    [dispatch],
  );

  useEffect(() => {
    if (filters) {
      getFollowersData();
      if (!filters.data_range_option) {
        triggerFilterReset({
          ...customFilterData,
        });
      }
    }
    // eslint-disable-next-line
  }, [getFollowersData]);

  useEffect(() => {
    if (!followersDataFetching && followersDataFetched) {
      getImpressionsData();
    }
  }, [followersDataFetching, followersDataFetched, getImpressionsData]);

  const normalizeFollowersChart = normalizePlatformData(
    followersData?.platforms || [],
    "followers_count",
  );
  const followersChartTotal = normalizeFollowersChart.find(item => item.rawName === "total");
  const followersChartData = normalizeFollowersChart.filter(
    item => item.rawName !== "total" && item.value !== 0,
  );

  const followersTableData =
    followersData?.items?.filter(item => !!item.id && item.id !== "total") || [];

  const normalizeImpressionsChart = normalizePlatformData(
    impressionsData?.platforms || [],
    "total_impressions",
  );
  const impressionsChartTotal = normalizeImpressionsChart.find(item => item.rawName === "total");
  const impressionsChartData = normalizeImpressionsChart.filter(
    item => item.rawName !== "total" && item.value !== 0,
  );

  const impressionsPlatformTableData =
    impressionsData?.platforms?.filter(item => !!item.id && item.id !== "total") || [];

  const impressionsProfilesTableData =
    impressionsData?.items?.filter(item => !!item.id && item.id !== "total") || [];

  return (
    <div className={s.bbProfiles}>
      <Breadcrumbs
        title={
          <div className={s.bbProfilesTitle}>
            <span className={s.bbProfilesTitleLabel}>{t("components:listen:profiles:title")}</span>
            <span className={s.bbProfilesTitleTag}>{t("generic:beta")}</span>
          </div>
        }
      />
      <ProfilesSection
        chartData={followersChartData}
        tableData={followersTableData}
        totals={{
          chart: followersChartTotal,
          table: followersData?.items?.find(item => !!item.id && item.id === "total"),
        }}
        loading={followersDataFetching}
        type="followers"
      />
      <ProfilesSection
        chartData={impressionsChartData}
        tableData={impressionsPlatformTableData}
        totals={{
          chart: impressionsChartTotal,
          table: impressionsData?.platforms?.find(item => !!item.id && item.id === "total"),
        }}
        loading={impressionsDataFetching}
        type="impressions"
      />

      <div className={s.bbProfilesImpressionsTable}>
        <span>{t(`components:listen:profiles:impressions:tableTitle`)}</span>
        <ProfilesTableWrapper
          type="impressions-profile"
          data={impressionsProfilesTableData}
          loading={impressionsDataFetching}
          total={impressionsData?.items?.find(item => !!item.id && item.id === "total")}
        />
      </div>
    </div>
  );
};
