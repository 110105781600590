import React, { MouseEvent } from "react";
import classNames from "classnames";

import { IVideoAttachmentProps } from "./Variants.types";

import s from "../Attachment.module.less";

import { PlayIcon } from "@assets/index";

const VideoAttachment: React.FC<IVideoAttachmentProps> = ({
  attachment,
  className,
  onAttachmentClick,
  ...restProps
}) => (
  <div
    className={classNames(s.bbVideoPlayerWrapper, className)}
    onClick={(e: MouseEvent<HTMLSpanElement>) => onAttachmentClick?.(e, attachment)}
  >
    <video preload="metadata" {...restProps}>
      <source src={attachment.url} type="video/mp4" />
    </video>
    <div className={s.bbPlayIconOverlay}>
      <PlayIcon />
    </div>
  </div>
);

export default VideoAttachment;
