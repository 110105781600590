import { ActionType } from "typesafe-actions";
import * as actions from "./actions";
import { IAsset } from "@components/_common/AssetTabs/AssetTabs.type";

export type AssetsActions = ActionType<typeof actions>;

export enum ASSET_EXPIRY_STATUS {
  not_monitoring = "not_monitoring",
  initializing = "initializing",
  monitoring = "monitoring",
  expired = "expired",
  removed = "removed",
  pending_removal = "pending_removal",
  about_to_expire = "about_to_expire",
}

export interface IConfigureMonitoringRequest {
  id: string;
  asset_platform: string;
  webhook_main_monitoring: boolean;
  webhook_organic_comments: boolean;
  webhook_ad_comments: boolean;
  webhook_private_messages: boolean;
  webhook_mentions: boolean;
  webhook_ig_story_reply: boolean;
  replier_asset_id?: string;
  webhook_reviews?: boolean;
}

export interface IReconnectAssetsPayload {
  app_id: string;
  access_token: string;
  authorization_code?: string;
  social_platform: string;
}

export interface IReconnectAssetsResponse {
  missing_permissions: string[];
  status: "complete" | "incomplete";
}

export interface IAssetsState {
  assets: IAsset[];
  fetchingGetAssets: boolean;
  fetchedGetAssets: boolean;
  fetchedGetAssetsFail: boolean;
  fetchingConfigureAssetMonitoring: boolean;
  fetchedConfigureAssetMonitoring: boolean;
  fetchedConfigureAssetMonitoringFail: boolean;
  fetchingDeleteAsset: boolean;
  fetchedDeleteAsset: boolean;
  fetchedDeleteAssetFail: boolean;
  fetchingPatchReconnectAssets: boolean;
  fetchedPatchReconnectAssets: boolean;
  fetchedPatchReconnectAssetsFail: boolean;
}
