import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, IButtonProps, Tooltip, Modal, Label, Checkbox } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./AssignToMeButton.module.less";

import { getCookie, setCookie } from "@utils/cookies";

import { AssignCommentIcon, StartShiftGraphic } from "@assets/index";

const SKIP_CONFIRMATION_MODAL_COOKIE_ID = "SkipAssignConfirmationModal";

type IAssignToMeButton = IButtonProps & {
  label?: string;
  onActionHandler: (closeModal: () => void) => void;
};

const AssignToMeButton: React.FC<IAssignToMeButton> = ({
  _size,
  label,
  loading,
  className = "",
  onActionHandler,
  ...props
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [skipModal, setSkipModal] = useState(getCookie(SKIP_CONFIRMATION_MODAL_COOKIE_ID) === "1");

  const onClickHandler = () => {
    if (!skipModal) {
      setShowModal(true);
    } else {
      onActionHandler?.(() => setShowModal(false));
    }
  };

  const onConfirmHandler = () => {
    if (skipModal) {
      setCookie(SKIP_CONFIRMATION_MODAL_COOKIE_ID, "1", 365);
    }
    onActionHandler?.(() => setShowModal(false));
  };

  return (
    <>
      <Tooltip title={t("components:assignToMe:tooltip")}>
        <Button
          _size={_size || "sm"}
          _type="primary"
          className={classNames(className, s.bbAssignToMeBtn)}
          loading={!showModal && loading}
          {...props}
          onClick={onClickHandler}
        >
          <span className={s.bbAssignToMeBtnTitle}>
            <AssignCommentIcon />
            {label || t("components:assignToMe:button")}
          </span>
        </Button>
      </Tooltip>
      <Modal
        open={showModal}
        onCancel={() => {
          setShowModal(false);
          setSkipModal(false);
        }}
        onOk={onConfirmHandler}
        okButtonProps={{ loadingIndicatorPosition: "center" }}
        hideHeader
        noPadding
        width={660}
        confirmLabel={t("components:assignToMe:confirmModal:confirmButton")}
        confirmLoading={loading}
        customActionBtn={
          <>
            <Checkbox
              id="skip-confirm-modal"
              checked={skipModal}
              onChange={() => setSkipModal(!skipModal)}
              _size="sm"
            />
            <Label
              htmlFor="skip-confirm-modal"
              className={s.bbConfirmModalLabel}
              text={t("components:assignToMe:confirmModal:skipMessage")}
            />
          </>
        }
      >
        <div className={s.bbConfirmModalContent}>
          <div className={s.bbConfirmModalImage}>
            <StartShiftGraphic />
          </div>
          <h2 className={s.bbConfirmModalContentTitle}>
            {t("components:assignToMe:confirmModal:title")}
          </h2>
          <p className={s.bbConfirmModalContentDescription}>
            {t("components:assignToMe:confirmModal:body")}
          </p>
        </div>
      </Modal>
    </>
  );
};

export default AssignToMeButton;
