import { i18nextInstance } from "../../../../src/App";

import { ISimpleTableColumn } from "@bbdevcrew/bb_ui_kit_fe";

export const TABLE_PAGE_SIZE = 25;

export const getTableColumns = (): ISimpleTableColumn[] => [
  {
    name: i18nextInstance.t("components:listen:sources:table:columns:source"),
    id_name: "source_label",
    colSpan: 7,
  },
  {
    name: i18nextInstance.t("components:listen:sources:table:columns:type"),
    id_name: "type_id",
    colSpan: 4,
  },
  {
    name: i18nextInstance.t("components:listen:sources:table:columns:asset"),
    id_name: "asset_name",
    colSpan: 5,
  },
  {
    name: i18nextInstance.t("components:listen:sources:table:columns:status"),
    id_name: "asset_status",
    colSpan: 4,
  },
];
