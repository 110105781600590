import React, { FC } from "react";
import cn from "classnames/bind";

import s from "./Compare.module.less";

import { ICompareProjectAggregation } from "@store/compareProjects/types";
import { getBreakdownChartBarColor } from "../../_common/BreakdownChart/helpers";

const cx = cn.bind(s);

interface ILegendProps {
  aggregation: ICompareProjectAggregation;
}

const Legend: FC<ILegendProps> = ({ aggregation }) => {
  return (
    <div className={s.bbStatLegend}>
      {aggregation.items?.length
        ? aggregation.items[0].comment_stats.map((comment_stat, index) => (
            <div className={s.bbCommentStatLegend} key={comment_stat.id}>
              <div
                className={cx(
                  s.bbCommentStatLegendItem,
                  comment_stat.id == "not_applicable" && s.bbCommentStatLegendStripeBackground,
                )}
                style={{
                  backgroundColor: getBreakdownChartBarColor(comment_stat.id, index),
                }}
              ></div>
              <span>{comment_stat.label}</span>
            </div>
          ))
        : null}
    </div>
  );
};

export default Legend;
