export const GET_COMMENT_TAGS = "COMMENT_TAGS/GET_COMMENT_TAGS";
export const GET_COMMENT_TAGS_SUCCESS = "COMMENT_TAGS/GET_COMMENT_TAGS_SUCCESS";
export const GET_COMMENT_TAGS_FAILURE = "COMMENT_TAGS/GET_COMMENT_TAGS_FAILURE";

export const POST_COMMENT_TAG = "COMMENT_TAGS/POST_COMMENT_TAG";
export const POST_COMMENT_TAG_SUCCESS = "COMMENT_TAGS/POST_COMMENT_TAG_SUCCESS";
export const POST_COMMENT_TAG_FAILURE = "COMMENT_TAGS/POST_COMMENT_TAG_FAILURE";

export const DELETE_COMMENT_TAG = "COMMENT_TAGS/DELETE_COMMENT_TAG";
export const DELETE_COMMENT_TAG_SUCCESS = "COMMENT_TAGS/DELETE_COMMENT_TAG_SUCCESS";
export const DELETE_COMMENT_TAG_FAILURE = "COMMENT_TAGS/DELETE_COMMENT_TAG_FAILURE";
