import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Loading } from "@bbdevcrew/bb_ui_kit_fe";
import { ConversationListHeader } from "./ConversationListHeader";
import { CommentsGridContainer } from "@containers/Comments/Comments";

import { meLoadingSelector } from "@store/me/selectors";
import { filterDataSelector } from "@store/filters/selectors";
import { postCommentsSearchAction } from "@store/comments/actions";

import s from "./ConversationList.module.less";

import { useAppFilters } from "@utils/appFilters";
import { IGetInboxRepliesPayload, IPostCommentBody } from "@store/comments/types";

export const ConversationList = () => {
  const { updateSearchDataAndUrl } = useAppFilters();

  const [sortValue, setSortValue] = useState<string>("desc");

  const meFetching = useSelector(meLoadingSelector);
  const filters = useSelector(filterDataSelector);

  const fetchConversationList = (body: IPostCommentBody | IGetInboxRepliesPayload) => {
    const sortVals = body.sort?.split("|");

    return postCommentsSearchAction({
      ...body,
      sort: sortVals && sortVals[0],
      sort_field: sortVals && sortVals[1],
    });
  };

  const onFilterAuthorId = (authorIds: string[]) =>
    updateSearchDataAndUrl({ author_ids: authorIds }, "id");

  return (
    <div className={s.bbConversationListRoot}>
      {meFetching || !filters ? (
        <Loading isLoading type="bb" />
      ) : (
        <div className={s.bbConversationListWrapper}>
          <ConversationListHeader sortValue={sortValue} onSortChange={val => setSortValue(val)} />
          <div className={s.bbConversationListCommentsGrid}>
            <CommentsGridContainer
              showStarAction
              showArchiveAction
              showHideAction
              showDeleteAction
              showLikeAction
              showLinkedInReactionAction
              withPostPreview
              filters={filters}
              sortValue={sortValue}
              providedFetchAction={fetchConversationList}
              providedFilterAuthorIdAction={(authorIds: string[]) => onFilterAuthorId(authorIds)}
              listId="listen-conversation_list"
            />
          </div>
        </div>
      )}
    </div>
  );
};
