import { Col, Row } from "antd";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { CardCentered } from "@bbdevcrew/bb_ui_kit_fe";
import { AdIcon, PostIcon, WordsIcon, SettingsIcon } from "@assets/index";

import s from "./CompareEmptyState.module.less";

import { AllowedCompareType } from "@store/compareProjects/types";

interface ICompareEmptyStateProps {
  onCardClick: (compareType: AllowedCompareType) => void;
}

const CompareEmptyState: FC<ICompareEmptyStateProps> = ({ onCardClick }) => {
  const { t } = useTranslation();

  return (
    <>
      <h1 className={s.bbCompareTitle}>{t("components:comparesPanel:title")}!</h1>
      <span className={s.bbCompareSubtitle}>{t("components:comparesPanel:subtitle")}:</span>

      <Row
        align="stretch"
        gutter={[20, 20]}
        data-cy="empty-state"
        className={s.bbEmptyStateCardRow}
      >
        <Col xs={24} sm={12} md={12} lg={7} xl={6}>
          <CardCentered className={s.bbEmptyStateCard} onClick={() => onCardClick("asset")}>
            <div className={s.bbEmptyStateCardBody}>
              <div className={s.bbEmptyStateCardIcon}>
                <AdIcon />
              </div>
              <span className={s.bbEmptyStateCardTitle}>
                {t("components:comparesPanel:cards:multiTitle")}
              </span>
            </div>
          </CardCentered>
        </Col>
        <Col xs={24} sm={12} md={12} lg={7} xl={6}>
          <CardCentered className={s.bbEmptyStateCard} onClick={() => onCardClick("post")}>
            <div className={s.bbEmptyStateCardBody}>
              <div className={s.bbEmptyStateCardIcon}>
                <PostIcon />
              </div>
              <span className={s.bbEmptyStateCardTitle}>
                {t("components:comparesPanel:cards:postsTitle")}
              </span>
            </div>
          </CardCentered>
        </Col>
        <Col xs={24} sm={12} md={12} lg={7} xl={6}>
          <CardCentered className={s.bbEmptyStateCard} onClick={() => onCardClick("keyword")}>
            <div className={s.bbEmptyStateCardBody}>
              <div className={s.bbEmptyStateCardIcon}>
                <WordsIcon />
              </div>
              <span className={s.bbEmptyStateCardTitle}>
                {t("components:comparesPanel:cards:keywordsTitle")}
              </span>
            </div>
          </CardCentered>
        </Col>
        <Col xs={24} sm={12} md={12} lg={7} xl={6}>
          <CardCentered className={s.bbEmptyStateCard} onClick={() => onCardClick("custom")}>
            <div className={s.bbEmptyStateCardBody}>
              <div className={s.bbEmptyStateCardIcon}>
                <SettingsIcon />
              </div>
              <span className={s.bbEmptyStateCardTitle}>
                {t("components:comparesPanel:cards:customTitle")}
              </span>
            </div>
          </CardCentered>
        </Col>
      </Row>
    </>
  );
};

export default CompareEmptyState;
