import React, { FC, Fragment } from "react";
import { Navigate, RouteProps } from "react-router-dom";

interface IPrivateRouteProps extends RouteProps {
  isDisabled?: boolean;
}

const PrivateRoute: FC<IPrivateRouteProps> = ({ isDisabled, children }) => {
  if (isDisabled) return <Navigate to={"/unauthorized"} />;

  return <Fragment> {children} </Fragment>;
};

export default PrivateRoute;
