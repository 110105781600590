import React, { FC, useState, useEffect, KeyboardEvent, ClipboardEvent } from "react";

import { Col, Row } from "antd";
import { Input } from "@bbdevcrew/bb_ui_kit_fe";

import s from "./CodeInput.module.less";

interface ICodeInputProps {
  onChange: (value: string) => void;
}

export const CodeInput: FC<ICodeInputProps> = ({ onChange }) => {
  const [value1, setValue1] = useState<undefined | number>();
  const [value2, setValue2] = useState<undefined | number>();
  const [value3, setValue3] = useState<undefined | number>();
  const [value4, setValue4] = useState<undefined | number>();
  const [value5, setValue5] = useState<undefined | number>();
  const [value6, setValue6] = useState<undefined | number>();

  const onCodePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const data = event.clipboardData.items[0];

    if (data.kind === "string" && data.type === "text/plain") {
      data.getAsString(value => {
        const [val1, val2, val3, val4, val5, val6] = value.split("");
        setValue1(parseFloat(val1));
        setValue2(parseFloat(val2));
        setValue3(parseFloat(val3));
        setValue4(parseFloat(val4));
        setValue5(parseFloat(val5));
        setValue6(parseFloat(val6));
        onChange(value);
      });
    }
  };

  const focusNextInput = (event: KeyboardEvent) => {
    const parentCol = event.currentTarget.closest(".ant-col");
    const nextCol = parentCol?.nextSibling as Element | null | undefined;

    nextCol?.querySelector("input")?.focus();
  };

  const focusPrevInput = (event: KeyboardEvent) => {
    const parentCol = event.currentTarget.closest(".ant-col");
    const prevCol = parentCol?.previousSibling as Element | null | undefined;

    prevCol?.querySelector("input")?.focus();
  };

  const validateInput = (event: KeyboardEvent) => {
    if (!event.key.match(/[0-9]/)) {
      event.preventDefault();
      return false;
    }

    const isValidInput = !Number.isNaN(event.key);

    if (isValidInput) focusNextInput(event);

    return isValidInput;
  };

  useEffect(() => {
    onChange(`${value1}${value2}${value3}${value4}${value5}${value6}`);
    // eslint-disable-next-line
  }, [value1, value2, value3, value4, value5, value6]);

  return (
    <Row className={s.bbCodeInput}>
      <Col>
        <Input
          autoFocus
          _size="lg"
          tabIndex={1}
          maxLength={1}
          type="number"
          value={value1}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") return setValue1(undefined);

            return validateInput(event) && setValue1(Number(event.key));
          }}
        />
      </Col>
      <Col>
        <Input
          _size="lg"
          tabIndex={2}
          maxLength={1}
          type="number"
          value={value2}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") {
              focusPrevInput(event);
              return setValue2(undefined);
            }

            validateInput(event) && setValue2(Number(event.key));
          }}
        />
      </Col>
      <Col>
        <Input
          _size="lg"
          tabIndex={3}
          maxLength={1}
          type="number"
          value={value3}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") {
              focusPrevInput(event);
              return setValue3(undefined);
            }

            validateInput(event) && setValue3(Number(event.key));
          }}
        />
      </Col>
      <Col>
        <Input
          _size="lg"
          tabIndex={4}
          maxLength={1}
          type="number"
          value={value4}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") {
              focusPrevInput(event);
              return setValue4(undefined);
            }

            validateInput(event) && setValue4(Number(event.key));
          }}
        />
      </Col>
      <Col>
        <Input
          _size="lg"
          tabIndex={5}
          maxLength={1}
          type="number"
          value={value5}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") {
              focusPrevInput(event);
              return setValue5(undefined);
            }

            validateInput(event) && setValue5(Number(event.key));
          }}
        />
      </Col>
      <Col>
        <Input
          _size="lg"
          tabIndex={6}
          maxLength={1}
          type="number"
          value={value6}
          hideMaxLengthChip
          onPaste={onCodePaste}
          wrapperClassName={s.bbCodeInputWrapper}
          onKeyUp={(event: KeyboardEvent) => {
            if (event.key === "Backspace") {
              focusPrevInput(event);
              return setValue6(undefined);
            }

            validateInput(event) && setValue6(Number(event.key));
          }}
        />
      </Col>
    </Row>
  );
};
