import { createReducer, ActionType } from "typesafe-actions";
import { IOnboardingPlatformTypesState } from "./types";
import {
  selectAssets,
  deselectAssets,
  setPlatformTypeAlert,
  getOnboardingPlatformTypesSuccessAction,
} from "./actions";
import {
  GET_ONBOARDING_PLATFORM_TYPES,
  GET_ONBOARDING_PLATFORM_TYPES_SUCCESS,
  GET_ONBOARDING_PLATFORM_TYPES_FAILURE,
  SELECT_ASSETS,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS_SUCCESS,
  POST_ONBOARDING_PLATFORM_TYPE_ASSETS_FAILURE,
  SET_PLATFORM_TYPE_ALERT,
  DESELECT_ASSETS,
} from "./actionTypes";
import {
  DELETE_TOKEN_SUCCESS,
  GET_ONBOARDING_PLATFORM,
  GET_ONBOARDING_PLATFORMS,
} from "../onboardingPlatforms/actionTypes";

const initialState = {
  platformTypes: [],
  selectedAssets: [],

  fetchingPlatformTypes: false,
  fetchedPlatformTypes: false,
  fetchedPlatformTypesFail: false,

  postingPlatformTypeAssets: false,
  postedPlatformTypeAssets: false,
  postedPlatformTypeAssetsFail: false,
};

export const onboardingPlatformTypesReducer = createReducer<IOnboardingPlatformTypesState>(
  initialState,
  {
    [GET_ONBOARDING_PLATFORM_TYPES]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      fetchingPlatformTypes: true,
      fetchedPlatformTypes: false,
      fetchedPlatformTypesFail: false,
    }),
    [GET_ONBOARDING_PLATFORM_TYPES_SUCCESS]: (
      state: IOnboardingPlatformTypesState,
      action: ActionType<typeof getOnboardingPlatformTypesSuccessAction>,
    ) => ({
      ...state,
      platformTypes: action.payload,
      fetchingPlatformTypes: false,
      fetchedPlatformTypes: true,
      fetchedPlatformTypesFail: false,
    }),
    [GET_ONBOARDING_PLATFORM_TYPES_FAILURE]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      fetchingPlatformTypes: false,
      fetchedPlatformTypes: false,
      fetchedPlatformTypesFail: true,
    }),
    [GET_ONBOARDING_PLATFORM]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      platformTypes: [],
      fetchingPlatformTypes: false,
      fetchedPlatformTypes: false,
      fetchedPlatformTypesFail: false,
    }),
    [GET_ONBOARDING_PLATFORMS]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      platformTypes: [],
      selectedAssets: [],
      fetchingPlatformTypes: false,
      fetchedPlatformTypes: false,
      fetchedPlatformTypesFail: false,
    }),
    [SELECT_ASSETS]: (
      state: IOnboardingPlatformTypesState,
      action: ActionType<typeof selectAssets>,
    ) => ({
      ...state,
      selectedAssets: [...state.selectedAssets, ...action.payload],
    }),
    [DESELECT_ASSETS]: (
      state: IOnboardingPlatformTypesState,
      action: ActionType<typeof deselectAssets>,
    ) => ({
      ...state,
      selectedAssets: state.selectedAssets.filter(
        ({ id: selectedId }) => !action.payload.find(({ id }) => id === selectedId),
      ),
    }),
    [POST_ONBOARDING_PLATFORM_TYPE_ASSETS]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      postingPlatformTypeAssets: true,
      postedPlatformTypeAssets: false,
      postedPlatformTypeAssetsFail: false,
    }),
    [POST_ONBOARDING_PLATFORM_TYPE_ASSETS_SUCCESS]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      selectedAssets: [],
      postingPlatformTypeAssets: false,
      postedPlatformTypeAssets: true,
      postedPlatformTypeAssetsFail: false,
    }),
    [POST_ONBOARDING_PLATFORM_TYPE_ASSETS_FAILURE]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      postingPlatformTypeAssets: false,
      postedPlatformTypeAssets: false,
      postedPlatformTypeAssetsFail: true,
    }),
    [SET_PLATFORM_TYPE_ALERT]: (
      state: IOnboardingPlatformTypesState,
      action: ActionType<typeof setPlatformTypeAlert>,
    ) => {
      const platTypes = [...state.platformTypes];
      const platformType = platTypes.find(({ id }) => id === action.payload.platformTypeId);

      if (platformType) {
        platformType.alert = action.payload.alert;
      }

      return {
        ...state,
        platformTypes: platTypes,
      };
    },
    [DELETE_TOKEN_SUCCESS]: (state: IOnboardingPlatformTypesState) => ({
      ...state,
      platformTypes: [],
      selectedAssets: [],
      fetchingPlatformTypes: false,
      fetchedPlatformTypes: false,
      fetchedPlatformTypesFail: false,
    }),
  },
);
