import { Auth } from "aws-amplify";
import { deleteAllCookies } from "./cookies";

export const globalSignOutRedirectLoginPage = () => {
  const redirectToLogin = () => {
    deleteAllCookies();
    localStorage.setItem("amplify-authenticator-authState", "signIn");
    window.location.href = `${process.env.BASE_URL}/login`;
  };

  Auth.signOut()
    .then(() => {
      redirectToLogin();
    })
    .catch(() => {
      redirectToLogin();
    });
};

export const interceptors = (status: number, type?: string) => {
  if (status === 401) globalSignOutRedirectLoginPage();

  if (status === 400 && type === "auth") globalSignOutRedirectLoginPage();
};
