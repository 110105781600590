import { createReducer, ActionType } from "typesafe-actions";
import { IOnboardingPlatformsState } from "./types";
import {
  deleteTokenSuccessAction,
  getOnboardingPlatformsSuccessAction,
  getOnboardingPlatformSuccessAction,
  getTwitterRegisterTokenUrlSuccessAction,
  registerTokenSuccessAction,
} from "./actions";
import {
  GET_ONBOARDING_PLATFORMS,
  GET_ONBOARDING_PLATFORMS_SUCCESS,
  GET_ONBOARDING_PLATFORMS_FAILURE,
  GET_ONBOARDING_PLATFORM,
  GET_ONBOARDING_PLATFORM_SUCCESS,
  GET_ONBOARDING_PLATFORM_FAILURE,
  REGISTER_TOKEN_SUCCESS,
  DELETE_TOKEN_SUCCESS,
  GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS,
  REGISTER_TOKEN,
  DELETE_TOKEN,
  DELETE_TOKEN_FAILURE,
} from "./actionTypes";

const initialState = {
  platforms: [],
  fetchingPlatforms: false,
  fetchedPlatforms: false,
  fetchedPlatformsFail: false,

  fetchingPlatform: false,
  fetchedPlatform: false,
  fetchedPlatformFail: false,

  registeredToken: false,
  registeringToken: false,
  registeredTokenFail: false,

  deletedToken: false,
  deletingToken: false,
  deletingTokenFail: false,
};

export const onboardingPlatformsReducer = createReducer<IOnboardingPlatformsState>(initialState, {
  [GET_ONBOARDING_PLATFORMS]: (state: IOnboardingPlatformsState) => ({
    ...state,
    fetchingPlatforms: true,
    fetchedPlatforms: false,
    fetchedPlatformsFail: false,
  }),
  [GET_ONBOARDING_PLATFORMS_SUCCESS]: (
    state: IOnboardingPlatformsState,
    action: ActionType<typeof getOnboardingPlatformsSuccessAction>,
  ) => ({
    ...state,
    platforms: action.payload,
    fetchingPlatforms: false,
    fetchedPlatforms: true,
    fetchedPlatformsFail: false,
  }),
  [GET_ONBOARDING_PLATFORMS_FAILURE]: (state: IOnboardingPlatformsState) => ({
    ...state,
    fetchingPlatforms: false,
    fetchedPlatforms: false,
    fetchedPlatformsFail: true,
  }),
  // Specific platform
  [GET_ONBOARDING_PLATFORM]: (state: IOnboardingPlatformsState) => ({
    ...state,
    fetchingPlatform: true,
    fetchedPlatform: false,
    fetchedPlatformFail: false,
  }),
  [GET_ONBOARDING_PLATFORM_SUCCESS]: (
    state: IOnboardingPlatformsState,
    action: ActionType<typeof getOnboardingPlatformSuccessAction>,
  ) => {
    const platIdx = state.platforms.findIndex(({ id }) => id === action.payload.platformId);

    if (platIdx > -1) {
      return {
        ...state,
        platforms: Object.assign([], {
          ...state.platforms,
          [platIdx]: {
            ...state.platforms[platIdx],
            ...action.payload.platform,
          },
        }),
        fetchingPlatform: false,
        fetchedPlatform: true,
        fetchedPlatformFail: false,
      };
    }

    return state;
  },
  [GET_ONBOARDING_PLATFORM_FAILURE]: (state: IOnboardingPlatformsState) => ({
    ...state,
    fetchingPlatform: false,
    fetchedPlatform: false,
    fetchedPlatformFail: true,
  }),
  [REGISTER_TOKEN]: (state: IOnboardingPlatformsState) => ({
    ...state,
    registeringToken: true,
    registeredToken: false,
    registeredTokenFail: false,
  }),
  [REGISTER_TOKEN_SUCCESS]: (
    state: IOnboardingPlatformsState,
    action: ActionType<typeof registerTokenSuccessAction>,
  ) => {
    const platIdx = state.platforms.findIndex(({ id }) => id === action.payload.platformId);

    if (platIdx > -1) {
      return {
        ...state,
        registeredToken: true,
        registeringToken: false,
        registeredTokenFail: false,
        platforms: Object.assign([], {
          ...state.platforms,
          [platIdx]: {
            ...state.platforms[platIdx],
            permissions: {
              ...state.platforms[platIdx].permissions,
              ...action.payload.data,
            },
            missing_permissions: action.payload?.data?.missing_permissions,
          },
        }),
      };
    }

    return state;
  },
  [DELETE_TOKEN]: (state: IOnboardingPlatformsState) => ({
    ...state,
    deletedToken: false,
    deletingToken: true,
    deletingTokenFail: false,
  }),
  [DELETE_TOKEN_SUCCESS]: (
    state: IOnboardingPlatformsState,
    action: ActionType<typeof deleteTokenSuccessAction>,
  ) => {
    const platIdx = state.platforms.findIndex(({ id }) => id === action.payload);

    if (platIdx > -1) {
      return {
        ...state,
        deletingToken: false,
        deletedToken: true,
        platforms: Object.assign([], {
          ...state.platforms,
          [platIdx]: {
            ...state.platforms[platIdx],
            permissions: {
              ...state.platforms[platIdx].permissions,
              status: "incomplete",
              user_profile: undefined,
            },
          },
        }),
      };
    }

    return { ...state, deletedToken: true, deletingToken: false };
  },
  [DELETE_TOKEN_FAILURE]: (state: IOnboardingPlatformsState) => ({
    ...state,
    deletedToken: false,
    deletingToken: true,
    deletingTokenFail: true,
  }),
  [GET_TWITTER_REGISTER_TOKEN_URL_SUCCESS]: (
    state: IOnboardingPlatformsState,
    action: ActionType<typeof getTwitterRegisterTokenUrlSuccessAction>,
  ) => {
    const platIdx = state.platforms.findIndex(({ id }) => id === action.payload.asset_platform);

    if (platIdx > -1) {
      return {
        ...state,
        platforms: Object.assign([], {
          ...state.platforms,
          [platIdx]: {
            ...state.platforms[platIdx],
            redirectUrl: action.payload.url,
          },
        }),
      };
    }

    return state;
  },
});
