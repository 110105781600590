import { useDispatch, useSelector } from "react-redux";
import React, { FC, useEffect } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { useRoutes } from "@utils/useRoutes";

import PrivateRoute from "./PrivateRoute";
import NotFoundView from "@containers/NotFoundView/NotFoundView";
import AppLayout from "@components/_common/AppLayout/AppLayout";
import CareCPSInbox from "@components/care-cps/CareCPSInbox";

import { filterDataSelector } from "@store/filters/selectors";
import {
  getCareCpsStreamsSelector,
  getCareCpsStreamsSelectorFailure,
  getCareCpsStreamsSelectorPending,
  getCareCpsStreamsSelectorSuccessful,
  getCurrentCareCpsStreamSelector,
} from "@store/streams/selectors";
import { postModuleSectionsListAction } from "@store/modules/actions";
import {
  getCareCpsStreamsAction,
  resetCareCpsStreamsAction,
  setCareCpsStreamAction,
} from "@store/streams/actions";

import { DEFAULT_CONVERSATION_LIST } from "@components/care-cps/CareCPSInbox/CareCPSInbox.helpers";

import { IMenuItem } from "@components/_common/AppHeader/AppHeader.type";
import { IRestrictedRoutesProps } from "./Routes.type";

const CareCPSRoutes: FC<IRestrictedRoutesProps> = ({ menu, path }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { redirectCallback } = useRoutes(path);
  const dispatch = useDispatch();

  const streams = useSelector(getCareCpsStreamsSelector);
  const fetched = useSelector(getCareCpsStreamsSelectorSuccessful);
  const fetching = useSelector(getCareCpsStreamsSelectorPending);
  const fetchFailed = useSelector(getCareCpsStreamsSelectorFailure);
  const filters = useSelector(filterDataSelector);
  const stream = useSelector(getCurrentCareCpsStreamSelector);
  const streamId = searchParams.get("stream");

  const shouldFetch = !fetched && !fetching && !fetchFailed;

  useEffect(() => {
    if (shouldFetch) {
      dispatch(getCareCpsStreamsAction());
    }
  }, [shouldFetch, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCareCpsStreamsAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (fetched && !fetchFailed && streams && streams.length > 0 && !stream) {
      const streamIndex = streams.findIndex(({ id }) => id === streamId);
      const currentStreamIndex = streamIndex !== -1 ? streamIndex : 0;
      dispatch(setCareCpsStreamAction(streams[currentStreamIndex]));
      setSearchParams({
        section: searchParams.get("section") || DEFAULT_CONVERSATION_LIST,
        stream: streams[currentStreamIndex].id,
      });
    }
  }, [fetched, fetchFailed, stream, streams, dispatch, streamId, searchParams, setSearchParams]);

  useEffect(() => {
    if (streamId) {
      dispatch(
        postModuleSectionsListAction({
          module: "care_cps",
          filters,
          streamId: streamId,
        }),
      );
    }
  }, [streamId, filters, dispatch]);

  return (
    <AppLayout
      basename="care-cps"
      menu={menu as unknown as IMenuItem[]}
      selectedKey={(path && path.replace(/\//g, "")) || "streams"}
    >
      {{
        content: (
          <Routes>
            <>
              <Route
                path="/"
                element={
                  <Navigate
                    replace
                    to={{
                      pathname: "streams",
                      search: searchParams.toString(),
                    }}
                  />
                }
              />
              <Route
                path="streams"
                element={<PrivateRoute>{<CareCPSInbox stream={stream} />}</PrivateRoute>}
              />
              <Route
                path="unauthorized"
                element={<NotFoundView redirectCallback={redirectCallback} type="403" />}
              />
              <Route path="*" element={<NotFoundView redirectCallback={redirectCallback} />} />
            </>
          </Routes>
        ),
      }}
    </AppLayout>
  );
};

export default CareCPSRoutes;
