/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import { PropsWithChildren } from "react";
import { TFunction } from "i18next";

import Keywords from "./filters/Keywords/Keywords";
import { AssignUser } from "./filters/AssignUser/AssignUser";
import CommentsFilters from "./filters/Comments/Comments";
import PostTypeFilters from "./filters/PostType/PostType";
import CampaignFilters from "./filters/Campaigns/Campaigns";
import FilterRange from "./filters/FilterRange/FilterRange";
import AssetsFilterMulti from "./filters/Assets/AssetsMulti";
import SentimentSelect from "./filters/Sentiment/SentimentSelect";
import { PlatformType } from "./filters/PlatformType/PlatformType";
import { MessageTypeFilters } from "./filters/MessageType/MessageType";
import { MessageStatusFilters } from "./filters/MessageStatus/MessageStatus";
import { KeywordsDropdownBasic } from "./filters/Keywords/KeywordsDropdownBasic";
import { SelectStyledWrapper } from "./filters/SelectStyledSingleFormWrapper/SelectStyledSingleFormWrapper";
import DynamicSelectStyledMultiFormWrapper from "./filters/DynamicSelectStyledMultiFormWrapper/DynamicSelectStyledMultiFormWrapper";
import RatingReview from "./filters/RatingReview";
import AssetsGroups from "./filters/Assets/AssetsGroups";
import AllAuthors from "./filters/Authors";

import { getSentimentOptions } from "@utils/sentimentOptions";

import { IMe } from "@store/me/types";
import { IDynamicFilter } from "./Filters.type";
import { ISelectProps } from "@bbdevcrew/bb_ui_kit_fe";
import { IFilterRangeProps } from "./filters/FilterRange/FilterRange.type";

export const commonProps = {
  options: [],
  noBorder: true,
  showArrow: true,
  allowClear: true,
  mode: "multiple",
  isMultiPaste: true,
  filterOption: false,
  dropdownMatchSelectWidth: false,
  isMultiPasteCustomAllowed: true,
  onFocus: () => {},
  onSearch: () => {},
};

// Default filters accross the APPs
export const getDefaultFiltersConfig = (t: TFunction, me?: IMe, isInModal?: boolean) => {
  const defaultFilters = [
    {
      name: "data_range_option",
      type: "asset",
      component: FilterRange,
      label: t("components:filters:dates:label"),
      tooltip: t("components:filters:dates:tooltip"),
      props: {
        dropdownMatchSelectWidth: false,
      },
    },
    {
      name: "platform_types",
      type: "asset",
      component: PlatformType,
      label: t("components:filters:platform_types:label"),
      props: {
        options: [],
        placeholder: t("components:filters:platform_types:placeholder"),
      },
    },
    {
      name: "asset_group_ids",
      type: "asset",
      component: AssetsGroups,
      label: t("components:filters:asset_group:label"),
      tooltip: t("components:filters:asset_group:tooltip"),
      props: {
        ...commonProps,
        placeholder: t("components:filters:asset_group:placeholder"),
      },
    },
    {
      name: "assetsInfix",
      type: "asset",
    },
    {
      name: "asset_ids",
      type: "asset",
      component: AssetsFilterMulti,
      label: t("components:filters:asset:label"),
      props: {
        ...commonProps,
        sanitizeFieldName: "asset",
        additionalLabelFields: ["id"], // additionalLabelFields store the fields that will be displayed in the option label
        placeholder: t("components:filters:asset:placeholder"),
      },
    },
    {
      name: "sentiments",
      type: "comment",
      component: SentimentSelect,
      label: t("components:filters:sentiment:label"),
      tooltip: t("components:filters:sentiment:tooltip"),
      groupLabel: t("components:filters:groups:comments"),
      props: {
        options: getSentimentOptions(t),
        noBorder: true,
        showArrow: true,
        allowClear: true,
        mode: "multiple",
        dropdownMatchSelectWidth: false,
        placeholder: t("components:filters:sentiment:placeholder"),
      },
    },
    {
      name: "keyword_query",
      type: "comment",
      component: Keywords,
      label: t("components:filters:keywords:label"),
      props: {
        options: [],
        limited: true,
        showTranslationSwitch: true,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "comment_tags",
      type: "comment",
      component: CommentsFilters,
      label: t("components:filters:comment_tags:label"),
      props: {
        ...commonProps,
        onFocus: undefined,
        onSearch: undefined,
        placeholder: t("components:filters:comment_tags:placeholder"),
      },
    },
    {
      name: "message_types",
      type: "asset",
      component: MessageTypeFilters,
      label: t("components:filters:message_types:label"),
      props: {
        placeholder: t("components:filters:message_types:placeholder"),
      },
    },
    {
      name: "author_ids",
      type: "comment",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:author_ids:label"),
      tooltip: t("components:filters:author_ids:tooltip"),
      props: {
        sanitizeFieldName: "author",
        additionalLabelFields: ["id"],
        ...commonProps,
        placeholder: t("components:filters:author_ids:placeholder"),
      },
    },
    {
      name: "author_names",
      type: "comment",
      component: AllAuthors,
      label: t("components:filters:author_names:label"),
      tooltip: t("components:filters:author_names:tooltip"),
      props: {
        ...commonProps,
        dropdownMatchSelectWidth: !!isInModal,
        placeholder: t("components:filters:author_names:placeholder"),
      },
    },
    {
      name: "languages",
      type: "comment",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:languages:label"),
      props: {
        sanitizeFieldName: "language",
        placeholder: t("components:filters:languages:placeholder"),
      },
    },
    {
      name: "review_types",
      type: "comment",
      component: RatingReview,
      isHidden: !me?.review_filters?.enabled,
      label: t("components:filters:review_filters:label"),
      props: {
        ...commonProps,
        options: me?.review_filters?.items,
        placeholder: t("components:filters:review_filters:placeholder"),
      },
    },
    {
      name: "assigned_users",
      type: "comment",
      component: AssignUser,
      label: t("components:filters:assigned_users:label"),
      props: {
        ...commonProps,
        onFocus: undefined,
        onSearch: undefined,
        placeholder: t("components:filters:assigned_users:placeholder"),
      },
    },
    {
      name: "visibility_type",
      type: "asset",
      component: SelectStyledWrapper,
      label: t("components:filters:visibility_type:label"),
      props: {
        fieldName: "visibility_type",
        _size: "sm",
        options: [
          {
            id: "visible",
            label: t("components:filters:visibility_type:options:visible"),
          },
          {
            id: "hidden",
            label: t("components:filters:visibility_type:options:hidden"),
          },
          {
            id: "deleted",
            label: t("components:filters:visibility_type:options:deleted"),
          },
        ],
        placeholder: t("components:filters:visibility_type:placeholder"),
      },
    },
    {
      name: "listen_sources",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      isHidden: !me?.has_sources,
      label: t("components:filters:listen_source:label"),
      props: {
        ...commonProps,
        fieldName: "listen_source",
        sanitizeFieldName: "listen_source",
        placeholder: t("components:filters:listen_source:placeholder"),
      },
    },
    {
      name: "comment_ids",
      type: "comment",
      component: Keywords,
      label: t("components:filters:comment_ids:label"),
      props: {
        name: "comment_ids",
        options: [],
        DropdownVariation: KeywordsDropdownBasic,
        showTranslationSwitch: false,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "message_statuses",
      type: "asset",
      component: MessageStatusFilters,
      label: t("components:filters:message_statuses:label"),
      props: {
        placeholder: t("components:filters:message_statuses:placeholder"),
      },
    },
    {
      name: "post_types",
      type: "asset",
      component: PostTypeFilters,
      label: t("components:filters:post_types:label"),
      groupLabel: t("components:filters:groups:posts"),
      props: {
        placeholder: t("components:filters:post_types:placeholder"),
      },
    },
    {
      name: "post_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:post_ids:label"),
      tooltip: t("components:filters:post_ids:tooltip"),
      props: {
        sanitizeFieldName: "post",
        additionalLabelFields: ["id"],
        ...commonProps,
        dropdownMatchSelectWidth: true,
        placeholder: t("components:filters:post_ids:placeholder"),
      },
    },
    {
      name: "post_tags",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:post_tags:label"),
      tooltip: t("components:filters:post_tags:tooltip"),
      props: {
        sanitizeFieldName: "post_tags",
        ...commonProps,
        dropdownMatchSelectWidth: true,
        placeholder: t("components:filters:post_tags:placeholder"),
      },
    },
    {
      name: "post_keyword_query",
      type: "asset",
      component: Keywords,
      label: t("components:filters:post_keywords:label"),
      tooltip: t("components:filters:post_keywords:tooltip"),
      props: {
        options: [],
        limited: true,
        showTranslationSwitch: true,
        dropdownMatchSelectWidth: !!isInModal,
        selectedLabel: t("components:filters:specifiedTag"),
      },
    },
    {
      name: "campaign_ids",
      type: "asset",
      component: CampaignFilters,
      label: t("components:filters:campaign:label"),
      groupLabel: t("components:filters:groups:campaigns"),
      props: {
        ...commonProps,
        additionalLabelFields: ["id"],
        onFocus: undefined,
        onSearch: undefined,
        placeholder: t("components:filters:campaign:placeholder"),
      },
    },
    {
      name: "adset_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:adset:label"),
      props: {
        sanitizeFieldName: "adset",
        additionalLabelFields: ["id"],
        placeholder: t("components:filters:adset:placeholder"),
      },
    },
    {
      name: "ad_ids",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:ad:label"),
      props: {
        sanitizeFieldName: "ads",
        additionalLabelFields: ["id"],
        dropdownPlacement: "topRight",
        ...commonProps,
        placeholder: t("components:filters:ad:placeholder"),
      },
    },
    {
      name: "targetings",
      type: "asset",
      component: DynamicSelectStyledMultiFormWrapper,
      label: t("components:filters:targetings:label"),
      tooltip: t("components:filters:targetings:tooltip"),
      props: {
        sanitizeFieldName: "ads_targeting",
        dropdownPlacement: "topRight",
        ...commonProps,
        placeholder: t("components:filters:targetings:placeholder"),
      },
    },
    // eslint-disable-next-line
  ] as IDynamicFilter<ISelectProps | IFilterRangeProps | PropsWithChildren<any>>[];

  return defaultFilters;
};
