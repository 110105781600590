import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Empty } from "antd";
import PieChartWidgetLabel from "./PieChartWidgetLabel";
import PieChartWidgetLegend from "./PieChartWidgetLegend";
import { Loading, PieChart, IPieChartLabel } from "@bbdevcrew/bb_ui_kit_fe";

import { IPieChartUnit } from "../../sentimentDetails/SentimentDetails.type";

import s from "./PieChartWidget.module.less";

interface IPieChartWidgetProps {
  loading: boolean;
  sizes?: [number, number];
  radiuses?: [number, number];
  chartData: IPieChartUnit[];
  defaultValue?: IPieChartUnit;
  position?: "left" | "center";
  onChartClick?: (event: { payload: { rawName: string } }) => void;
  legendClassName?: string;
  label?: string;
  emptyLabel?: string;
  hasLabel?: boolean;
}

const PieChartWidget: FC<IPieChartWidgetProps> = ({
  loading,
  chartData,
  defaultValue,
  position = "center",
  radiuses,
  sizes = [200, 200],
  onChartClick,
  legendClassName,
  label,
  emptyLabel,
  hasLabel = true,
}) => {
  const { t } = useTranslation();
  const innerRadius = radiuses && radiuses[0] ? radiuses[0] : 65;
  const outerRadius = radiuses && radiuses[1] ? radiuses[1] : 90;
  const filteredChartData = chartData.filter(unit => unit.value !== 0);

  const renderCustomLabel = (labelProps: IPieChartLabel, _: string, value: number) => (
    <PieChartWidgetLabel
      hasLabel={hasLabel}
      value={value}
      color="black"
      labelProps={{ ...labelProps, label }}
    />
  );

  return (
    <>
      {loading ? (
        <div className={s.bbPieChartWidgetLoading}>
          <Loading isLoading />
        </div>
      ) : filteredChartData.length ? (
        <div
          className={classNames(s.bbPieChartWidget, {
            [s.bbPieChartWidgetCentered]: position === "center",
          })}
        >
          <PieChart
            width={sizes[0]}
            height={sizes[1]}
            data={chartData}
            enableCursor="pointer"
            defaultValue={defaultValue}
            customLabel={renderCustomLabel}
            hoverStrokeWidth={10}
            hoverStrokeInnerRadius={innerRadius + 5}
            hoverStrokeOuterRadius={outerRadius - 5}
            pieProps={{
              innerRadius,
              outerRadius,
              stroke: "none",
              nameKey: "name",
              dataKey: "value",
              labelLine: false,
              onClick: onChartClick,
              animationDuration: 500,
              paddingAngle: filteredChartData.length === 1 ? 0 : 5,
            }}
          />
          <PieChartWidgetLegend chartData={chartData} className={legendClassName} />
        </div>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          className={s.bbPieChartWidgetEmpty}
          description={emptyLabel || t("components:comments:tableEmpty")}
          imageStyle={{
            height: 40,
          }}
        />
      )}
    </>
  );
};

export default PieChartWidget;
