import React, { FC } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Col, Row } from "antd";
import SentimentsTrend from "./sentimentCard/SentimentsCard";
import YTNotAvailableChip from "../../_common/ytNotAvailableChip";

import s from "./SentimentsCards.module.less";

import { IFilters } from "@store/filters/types";
import { meSelector } from "@store/me/selectors";
import { useYTSelected } from "@utils/useYTselected";
import { IPerformanceUnit } from "../../_common/performanceCard/types";
import { IExplorerOverviewSentiments } from "@store/dashboard/types";

interface ISentimentsCardsProps {
  loading: boolean;
  filters: IFilters;
  data: IExplorerOverviewSentiments[];
  handleSentimentCardClick?: (type: string) => void;
}

const SentimentsCards: FC<ISentimentsCardsProps> = ({
  filters,
  data,
  loading,
  handleSentimentCardClick,
}) => {
  const { t } = useTranslation();

  const me = useSelector(meSelector);

  const isYTOnlyAvailable =
    me?.client_data?.platform_types.length === 1 &&
    me.client_data.platform_types[0].id === "youtube";
  const isYTFilterSelected = useYTSelected(filters.platform_types || []);

  const tooltipProps = {
    title: t("components:sentimentsTrends:tooltips:percentageTooltip"),
    style: { width: 360 },
  };

  const YTNotAvailableSuffix = (
    <span className={s.bbYTNotAvailableSuffix}>
      <YTNotAvailableChip className={s.bbYTChipSmall} />
    </span>
  );

  const getSentiment = (type: string) => data.find(({ id }) => id === type) as IPerformanceUnit;

  return (
    <Col xs={24} className={s.bbSentimentCardsContainerCol}>
      <div className={s.bbSentimentCardsContainer} data-cy="sentiment-trends-container">
        <Row gutter={[20, 20]}>
          <SentimentsTrend
            type="total"
            isLoading={loading}
            data={getSentiment("total")}
            fullWidth={isYTOnlyAvailable}
            percentageTooltipProps={tooltipProps}
            suffix={isYTFilterSelected ? <span className={s.bbEmptySuffix}></span> : null}
          />
          {!isYTOnlyAvailable && (
            <>
              <SentimentsTrend
                type="positive"
                isLoading={loading}
                data={getSentiment("positive")}
                onClick={handleSentimentCardClick}
                percentageTooltipProps={tooltipProps}
                suffix={isYTFilterSelected ? YTNotAvailableSuffix : null}
                data-stonly-target="intelligence-overview__top-boxes--positive"
              />
              <SentimentsTrend
                type="negative"
                isLoading={loading}
                data={getSentiment("negative")}
                onClick={handleSentimentCardClick}
                percentageTooltipProps={tooltipProps}
                suffix={isYTFilterSelected ? YTNotAvailableSuffix : null}
              />
              <SentimentsTrend
                type="neutral"
                isLoading={loading}
                data={getSentiment("neutral")}
                onClick={handleSentimentCardClick}
                percentageTooltipProps={tooltipProps}
                suffix={isYTFilterSelected ? YTNotAvailableSuffix : null}
              />
            </>
          )}
        </Row>
      </div>
    </Col>
  );
};

export default SentimentsCards;
