import cn from "classnames/bind";
import { useTranslation } from "react-i18next";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Button, ChevronRightIcon } from "@bbdevcrew/bb_ui_kit_fe";
import { AssetTabsWrapper } from "../assetTabsWrapper/AssetTabsWrapper";

import {
  selectedAssetsSelector,
  postedOnboardingPlatformTypeAssetsSelector,
  postingOnboardingPlatformTypeAssetsSelector,
} from "@store/onboardingPlatformTypes/selectors";
import { postOnboardingPlatformTypeAssetsAction } from "@store/onboardingPlatformTypes/actions";
import { ITTSignupStep2Props } from "./TTSignup.types";

import footerStyle from "../signupSteps/footer/Footer.module.less";

const cx = cn.bind(footerStyle);

export const TTSignupStep2: FC<ITTSignupStep2Props> = ({ onFinish }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isWebhookEnabled, setIsWebhookEnabled] = useState(true);

  const postingAssets = useSelector(postingOnboardingPlatformTypeAssetsSelector);
  const postedAssets = useSelector(postedOnboardingPlatformTypeAssetsSelector);
  const allSelectedAssets = useSelector(selectedAssetsSelector);

  useEffect(() => {
    if (postedAssets) onFinish();
  }, [postedAssets, onFinish]);

  const onInternalFinish = () => {
    dispatch(postOnboardingPlatformTypeAssetsAction(allSelectedAssets, isWebhookEnabled));
  };

  const setInternalWHEnabled = (enabled: boolean) => {
    setIsWebhookEnabled(enabled);
  };

  return (
    <div>
      <AssetTabsWrapper
        setWebhookEnabled={setInternalWHEnabled}
        title={t("components:ttAppCenter:step2:title")}
      />
      <div className={cx(footerStyle.bbStepFooter, footerStyle.bbStepFooterTT)}>
        <Button
          _size="md"
          _type="primary"
          onClick={onInternalFinish}
          loading={postingAssets}
          disabled={postingAssets}
        >
          {t("generic:continue")}
          <ChevronRightIcon />
        </Button>
      </div>
    </div>
  );
};
