import { ajax } from "rxjs/ajax";
import { Observable } from "rxjs";
import { isOfType } from "typesafe-actions";
import { StateObservable } from "redux-observable";
import { switchMap, map, filter, catchError } from "rxjs/operators";

import { Actions, ICompareProject, ICompareProjectAggregation } from "./types";
import { RootState } from "../";
import {
  deleteCompareProjectFailure,
  deleteCompareProjectSuccess,
  getCompareProjectAggregationsFailure,
  getCompareProjectAggregationsSuccess,
  getCompareProjectsFailure,
  getCompareProjectsSuccess,
  postCompareProjectFailure,
  postCompareProjectSuccess,
  editCompareProjectSuccess,
  editCompareProjectFailure,
} from "./actions";
import {
  DELETE_COMPARE_PROJECT,
  GET_COMPARE_PROJECTS,
  POST_COMPARE_PROJECT,
  GET_COMPARE_PROJECT_AGGREGATIONS,
  EDIT_COMPARE_PROJECT,
} from "./actionTypes";

import { compareProjects } from "@utils/paths";
import { getHeaders } from "@utils/headers";
import { handleError } from "@utils/apiErrorHandler";

// The edited project needs to be sent without the id
const getProjectProps = (project: ICompareProject) => {
  const projectProps = { ...project };
  delete projectProps.id;
  return projectProps;
};

export const getCompareProjects = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_COMPARE_PROJECTS)),
    switchMap(() => {
      return ajax
        .get<{ items: ICompareProject[] }>(
          compareProjects,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCompareProjectsSuccess(data.items)),
          catchError(e => handleError(e, getCompareProjectsFailure)),
        );
    }),
  );

export const postCompareProject = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(POST_COMPARE_PROJECT)),
    switchMap(a => {
      return ajax
        .post<ICompareProject>(
          compareProjects,
          a.payload,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => postCompareProjectSuccess(data)),
          catchError(e => handleError(e, postCompareProjectFailure)),
        );
    }),
  );

export const editCompareProject = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(EDIT_COMPARE_PROJECT)),
    switchMap(a => {
      return ajax
        .put<ICompareProject>(
          `${compareProjects}/${a.payload.id}`,
          getProjectProps(a.payload),
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => editCompareProjectSuccess(data)),
          catchError(e => handleError(e, editCompareProjectFailure)),
        );
    }),
  );

export const deleteCompareProject = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(DELETE_COMPARE_PROJECT)),
    switchMap(a => {
      return ajax
        .delete(
          `${compareProjects}/${a.payload}`,
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(() => deleteCompareProjectSuccess(a.payload)),
          catchError(e => handleError(e, deleteCompareProjectFailure)),
        );
    }),
  );

export const getCompareProjectAggregations = (
  action$: Observable<Actions>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    filter(isOfType(GET_COMPARE_PROJECT_AGGREGATIONS)),
    switchMap(a => {
      return ajax
        .post<{ items: ICompareProjectAggregation[] }>(
          `${compareProjects}/${a.payload.id}/aggregations`,
          {
            filters: a.payload.filters,
          },
          getHeaders({
            Authorization: state$.value.auth.session.accessToken.jwtToken,
          }),
        )
        .pipe(
          map(e => e.response),
          map(data => getCompareProjectAggregationsSuccess(data.items)),
          catchError(e => handleError(e, getCompareProjectAggregationsFailure)),
        );
    }),
  );
