import { useCallback } from "react";
import { useSelector } from "react-redux";

import { meSelector } from "@store/me/selectors";

export type AppFeature = "engage" | "publish" | "workflow";
export type Permission = "FULL_ACCESS" | "MODERATOR" | "CONTRIBUTOR" | "VIEW_ONLY";

export default function usePermissions() {
  const me = useSelector(meSelector);

  const hasPermission = useCallback(
    (feature: AppFeature, permission: Permission) => {
      const permissionKey = `ROLE_${feature.toUpperCase()}_${permission}`;
      return !!(me?.[`role_${feature}`] === permissionKey);
    },
    [me],
  );

  return {
    hasPermission,

    // engage permissions
    canModerate: hasPermission("engage", "FULL_ACCESS") || hasPermission("engage", "MODERATOR"),

    // publish permission
    canCreatePost:
      hasPermission("publish", "FULL_ACCESS") || hasPermission("publish", "CONTRIBUTOR"),
    canPublishPost: hasPermission("publish", "FULL_ACCESS"),

    // workflows permissions
    manageWorkflows: hasPermission("workflow", "FULL_ACCESS"),
  };
}
